import {connect} from 'react-redux';
import React, {Component} from 'react';
import ShipmentTracker from './ShipmentTracker'
import * as actions from '../../../redux/actions/googleActions';

//add props from reducer here
const mapStateToProps = store =>
({
    shipmentTrackerData:            store.shipmentTrackerReducer.shipmentTrackerData,
    isShipmentTrackerLoading:       store.shipmentTrackerReducer.isShipmentTrackerLoading,
    shipTrackModalShow:             store.shipmentTrackerReducer.shipTrackModalShow,
    shipTrackModalData:             store.shipmentTrackerReducer.shipTrackModalData,
    shipTrackModalRow:              store.shipmentTrackerReducer.shipTrackModalRow,

    currentUserEmail:             store.userServicesReducer.currentUserEmail,
    userSignInData:                 store.userServicesReducer.userSignInData
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getShipmentTrackerData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) =>
    {
        dispatch( actions.getShipmentTrackerData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) )
    },

    openShipModal:(_db,  _email, _jobid, _row ) =>
    {
        dispatch( actions.showShipmentTrackerModalGetComments( _db, _email, _jobid, _row ) )
    },

    hideShipmentTrackerModal:() =>
    {
        dispatch( actions.hideShipmentTrackerModal( null ) )
    },

    postShipModalComment:( _db, _email, _jobid, _comment, _type ) =>
    {
        dispatch( actions.postShipModalComment( _db, _email, _jobid, _comment, _type ) )
    },

    modalToggleHotShip:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleHotShip( _db, _email, _jobid, _bool ) )
    },

    modalToggleInternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleInternalAction( _db, _email, _jobid, _bool ) )
    },

    modalToggleExternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleExternalAction( _db, _email, _jobid, _bool ) )
    }



});


class ShipmentTrackerFilteredContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{
        // this.props.getShipmentTrackerData( this.props.currentUserEmail );

        // //check if dataset empty
        // if (this.props.shipmentTrackerData.length === 0 || typeof(this.props.shipmentTrackerData) === 'undefined' || this.props.shipmentTrackerData === null) {
        //     // this.props.getStatCardInTransit( this.props.currentUserEmail );

        //     this.props.getShipmentTrackerData( this.props.currentUserEmail );
        // }


	}
	
    render()
    {
    	return (
            <ShipmentTracker 
            shipmentTrackerData={ this.props.shipmentTrackerData }
            isShipmentTrackerLoading={ this.props.isShipmentTrackerLoading }
            shipTrackModalShow={this.props.shipTrackModalShow}
            openShipModal={this.props.openShipModal}
            hideShipmentTrackerModal={this.props.hideShipmentTrackerModal}
            shipTrackModalData={this.props.shipTrackModalData}
            shipTrackModalRow={this.props.shipTrackModalRow}
            postShipModalComment={this.props.postShipModalComment}
            modalToggleHotShip={this.props.modalToggleHotShip}
            modalToggleInternalAction={this.props.modalToggleInternalAction}
            modalToggleExternalAction={this.props.modalToggleExternalAction}
         
            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}
            getShipmentTrackerData={this.props.getShipmentTrackerData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShipmentTrackerFilteredContainer);




