import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';


import StatCardRowContainer from '../../../components/ComponentsGoogle/statsCard/StatsCardRowContainer';
import AlertKpiTrendContainer from '../../../components/ComponentsGoogle/AlertKpiTrend/AlertKpiTrendContainer';
import ShipmentTrackerContainer from '../../../components/ComponentsGoogle/ShipmentTracker/ShipmentTrackerContainer';
import ShipmentScheduleGraphContainer from '../../../components/ComponentsGoogle/ShipmentScheduleGraph/ShipmentScheduleGraphContainer';
import ShipmentPieContainer from '../../../components/ComponentsGoogle/ShipmentPie/ShipmentPieContainer';
// import ShipmentHistoryContainer from '../../components/ShipmentHistory/ShipmentHistoryContainer';
import ShipmentHistoryBarContainer from '../../../components/ComponentsGoogle/ShipmentHistory/ShipmentHistoryBarContainer';
// import CustomTableContainer from '../../components/CustomTable/CustomTableContainer';
// import PendingUserAccountsContainer from '../../components/PendingUserAccounts/PendingUserAccountsContainer';
// import MilestoneAlertsContainer from '../../components/MilestoneAlerts/MilestoneAlertsContainer';
// import DashHomeFilterContainer from '../../../components/ComponentsGoogle/DashHomeFilter/DashHomeFilterContainer';


// import Test from '../../components/Test/Test';
import GoogleMapTrackerContainer from '../../../components/ComponentsGoogle/GoogleMap/GoogleMapTrackerContainer';
import FilterMainGoogle from '../../../components/ComponentsGoogle/FilterMainGoogle/FilterMainGoogle';
import {appSettings} from '../../../app/appSettings'

import ChartDemurrageContainer from '../../../components/ComponentsGoogle/ChartDemurrage/ChartDemurrageContainer'

import ChartPendingSailingContainer from '../../../components/ComponentsGoogle/ChartPendingSailing/ChartPendingSailingContainer'

export default class dashHome extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

            //Mobile View
            handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
            
            // check width for mobile device
            componentWillMount() {
                window.addEventListener('resize', this.handleWindowSizeChange);
              }
            // make sure to remove the listener
            // when the component is not mounted anymore
            componentWillUnmount() {
            window.removeEventListener('resize', this.handleWindowSizeChange);
            }      

   
    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>

            <FilterMainGoogle isMobile={isMobile}/>



            <AnimatedView>

                <StatCardRowContainer />

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                <ShipmentPieContainer />
                </div>
                <div className="col-md-6">
                <AlertKpiTrendContainer />    
                
                </div>
                
            </div>

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                <ShipmentScheduleGraphContainer />
                </div>
                <div className="col-md-6">
                <ShipmentHistoryBarContainer />    
                
                </div>
                
            </div>

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                    <ChartPendingSailingContainer isMobile={isMobile}/>  
                </div>
                <div className="col-md-6">
                    <ChartDemurrageContainer isMobile={isMobile}/>  
                </div>
            </div>

            <div style = {{ height: '600px', width: '100%', position: 'relative', marginRight: '30px' , marginBottom: '40px'}} >
                <GoogleMapTrackerContainer />
            </div>

            </AnimatedView>
            </div>

        );
    }
}

// export default dashHome;



export class googleDashHome extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

            //Mobile View
            handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
            
            // check width for mobile device
            componentWillMount() {
                window.addEventListener('resize', this.handleWindowSizeChange);
              }
            // make sure to remove the listener
            // when the component is not mounted anymore
            componentWillUnmount() {
            window.removeEventListener('resize', this.handleWindowSizeChange);
            }      


    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(

            <div>

            <FilterMainGoogle isMobile={isMobile}/>


            <AnimatedView>

                

                {/* <DashHomeFilterContainer /> */}

                <StatCardRowContainer />

                <div
                className="row"
                style={{marginBottom: '5px'}}>
                    <div className="col-md-6">
                        <ShipmentScheduleGraphContainer />
                    </div>
                    <div className="col-md-6">
                        <ShipmentHistoryBarContainer />    
                    </div>
                </div>

                <div
                    className="row"
                    style={{marginBottom: '5px'}}>
                    <div className="col-md-6">
                        <ChartPendingSailingContainer isMobile={isMobile}/>  
                    </div>
                    <div className="col-md-6">
                        <ChartDemurrageContainer isMobile={isMobile}/>  
                    </div>
                </div>

                <div style = {{ height: '600px', width: '100%', position: 'relative', marginRight: '30px' , marginBottom: '40px'}} >
                    <GoogleMapTrackerContainer />
                </div>
            </AnimatedView>

            </div>
        );
    }
}



export class salesDashHome extends PureComponent {
       constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

            //Mobile View
            handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
            
            // check width for mobile device
            componentWillMount() {
                window.addEventListener('resize', this.handleWindowSizeChange);
              }
            // make sure to remove the listener
            // when the component is not mounted anymore
            componentWillUnmount() {
            window.removeEventListener('resize', this.handleWindowSizeChange);
            }      

            
    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(

            <div>

            <FilterMainGoogle isMobile={isMobile}/>

            <AnimatedView>

            
                <ShipmentTrackerContainer />

                <div style = {{ height: '600px', width: '100%', position: 'relative', marginRight: '30px' , marginBottom: '40px'}} >
                    <GoogleMapTrackerContainer />
                </div>

            </AnimatedView>
            </div>
        );
    }
}

