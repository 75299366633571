import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import * as moment from 'moment'
import ReChartLine from '../../ComponentsGeneral/ReCharts/ReChartLine'
import randomColor from 'randomcolor' 
import queryString from 'query-string';
import {appSettings} from '../../../app/appSettings'

  class ChartShipToPort extends PureComponent {
    constructor( props )
    {
      super( props );
    }


    
    onClick = (line, lineKey) => {

    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null
    var paramShipToCountry = null


    const values = queryString.parse(this.props.location.search)


    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }
    if(typeof(values.shiptocountry) !== 'undefined' && values.shiptocountry != null ){
        paramShipToCountry = values.shiptocountry
    }



      // var dataKey = "'" + line.dataKey + "'"

      var dateValue = moment(line.payload.Date).format('YYYY-MM-DD')

      var endDate = moment(dateValue, "YYYY-MM-DD").add(1, 'days');

      var endDateValue =  moment(endDate).format('YYYY-MM-DD') 

      this.props.zuGetMawbDetails( appSettings.zuDB, this.props.currentUserEmail, "'" + dateValue + "'", "'" + endDateValue + "'", paramDateType, paramNotDeparted, null, paramPortEntry, null, null, null, null, null, null, null, paramShipToCountry );



    }

    // onClick = (line, lineKey) => {

    //   var dataKey = "'" + line.dataKey + "'"

    //   var dateValue = moment(line.payload.Date).format('YYYY-MM-DD')

    //   var new_date = moment(dateValue, "YYYY-MM-DD").add(1, 'days');

    //   var endDateValue = "'" + moment(new_date).format('YYYY-MM-DD') + "'"
 
    //   this.props.history.push({
    //     pathname: 'zulily/shiplist',
    //     search: 'startdt=' + "'" + dateValue + "'" + '&' +
    //     'enddt=' + endDateValue + '&' +
    //     'datetype=' + null + '&' +
    //     'notdeparted=' + null + '&' +
    //     'portexit=' + null + '&' +
    //     'portentry=' + null + '&' +
    //     'finalcarrier=' + null  + '&' +
    //     'mawb=' + null  + '&' +
    //     'mc=' + null  + '&' +
    //     'hawb=' + null  + '&' +
    //     'filter=' + dataKey
    //     }); 
  
    // }

    render() {

        let shipToPortDiv;
        if (this.props.isDailyShipPortDataLoading) {
          shipToPortDiv=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Package Ship to Port Percentage'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        }          
        else if (typeof(this.props.DailyShipPortData) !== 'undefined' && this.props.DailyShipPortData != null) {


      var columnList = [];

      // get the results (useful data) somewhere
      var results = this.props.DailyShipPortData;
      // you can loop through all, assuming that each result set is the same. 
      if (results.length > 0) { 
          // iterating through the results array
          for(var i = 0; i < results.length; i++) {
              // get i-th object in the results array  
              var columnsIn = results[i];
              // loop through every key in the object
              for(var key in columnsIn){
                  if(key != 'x' ){
                  columnList.indexOf(key) === -1 ? columnList.push(key) : console.log("");
                  }
              } 
          }
      }


      var startingList = columnList
      // startingList
      var portColumns = [];
      // get the results (useful data) somewhere
      var resultsColumns = startingList;
      // you can loop through all, assuming that each result set is the same. 
      if (resultsColumns.length > 0) { 
          // iterating through the results array
          for(var iL = 0; iL < resultsColumns.length; iL++) {
              // get i-th object in the results array  
              var columnsInLane = resultsColumns[iL];
                    //want to skip the x-axis column
                    if(columnsInLane === 'Date'){
  
                    }
                    //define colors for common values
                    else  if(columnsInLane === 'JFK'){
                      portColumns.indexOf(iL) === -1 ? portColumns.push(        
                        { lineKey: columnsInLane , 
                          'stroke':'#82ca9d'   },
                        ) 
                        : console.log("");
                    }
                    else  if(columnsInLane === 'LAX'){
                      portColumns.indexOf(iL) === -1 ? portColumns.push(        
                        { lineKey: columnsInLane , 
                          'stroke':'#413ea0'   },
                        ) 
                        : console.log("");
                    }
                    else  if(columnsInLane === 'ORD'){
                      portColumns.indexOf(iL) === -1 ? portColumns.push(        
                        { lineKey: columnsInLane , 
                          'stroke':'#f43e1f'   },
                        ) 
                        : console.log("");
                    }
                    //assign random color for all other values
                    else  {
                      portColumns.indexOf(iL) === -1 ? portColumns.push(        
                        { lineKey: columnsInLane , 
                          'stroke':randomColor({luminosity: 'dark',format: 'rgba', alpha: 0.4})   },
                        ) 
                        : console.log("");
                    }
          }
      }


            shipToPortDiv =
                <section className="panel">
                <header className="panel-heading">
                {'Package Ship to Port Percentage'}
                </header>
                    <ReChartLine 
                      data={this.props.DailyShipPortData} 
                      dataKey='Date'
                      lineKeys= { portColumns }
                      onClick={{ onClick: (line) => this.onClick(line) }}
                   />
                </section>
        }


      return(
        <div>
            {shipToPortDiv}
        </div>
      );

    }
  }
  

  export default withRouter(ChartShipToPort);
  
