import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
// import PendingUserAccountsContainer from '../../../components/PendingUserAccounts/PendingUserAccountsContainer';

import PendingUsersContainer  from '../../../components/ComponentsUser/PendingUsers/PendingUserContainer';
import UserManagementContainer from '../../../components/ComponentsUser/UserManagement/UserManagementContainer';
import AdminUserManagementContainer from '../../../components/ComponentsUser/UserManagement/AdminUserManagementContainer';
import {appSettings} from '../../../app/appSettings'

class userManagementView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }     


    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <AnimatedView>

                <PendingUsersContainer isMobile={isMobile}/>

                <UserManagementContainer isMobile={isMobile}/>
                
                {/* <AdminUserManagementContainer isMobile={isMobile}/> */}

            </AnimatedView>

        );
    }
}

export default userManagementView;
