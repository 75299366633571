import {connect} from 'react-redux';
import React, {Component} from 'react';
import StatsCardRow from './StatsCardRow'
import * as actions from '../../../redux/actions/googleActions';
// import {appSettings} from '../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

const mapStateToProps = store =>
({
    statCardRowData:            store.statCardRowReducer.statCardRowData,
    isStatCardRowLoading:       store.statCardRowReducer.isStatCardRowLoading,
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:             store.userServicesReducer.currentUserEmail
});

const mapDispatchToProps = dispatch =>
({
    getStatCardRowData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
    {
        dispatch( actions.getStatCardRowData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) )
    },

    getStatCardInTransit:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getStatCardInTransit( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },

    getStatCardParam:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getStatCardParam( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },


    
});


class StatCardRowContainer extends Component
{
	constructor(props)
	{
        super(props);

	}
	
	// componentDidMount()
	// {


    //     this.props.getStatCardRowData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null);
    // }



        
    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }

    handleUrlParam = () => {
        var paramStartDT = null
        var paramEndDT = null
        var paramDateType = null
        var paramInTransit = 'O'
        var paramMode = null
        var paramCarrier = null
        var paramFilter = null
        var paramFilterValue = null
      
        const values = queryString.parse(this.props.location.search)
    
        if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
            paramStartDT = values.startdt
        }
        if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
            paramEndDT = values.enddt
        }
        if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
            paramDateType = values.datetype
        }
        if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
            paramInTransit = values.intransit
        }
        if(typeof(values.mode) !== 'undefined' && values.mode != null ){
            paramMode = values.mode
        }
        if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
            paramCarrier = values.carrier
        }
        if(typeof(values.filter) !== 'undefined' && values.filter != null ){
            paramFilter = values.filter
        }
        if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
            paramFilterValue = values.filterValue
        }
    
        this.props.getStatCardRowData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );
        
    }
  



    render()
    {
    	return (

            <StatsCardRow 
            statCardRowData={ this.props.statCardRowData }
            isStatCardRowLoading={ this.props.isStatCardRowLoading }
            getStatCardInTransit={ this.props.getStatCardInTransit }
            getStatCardParam={ this.props.getStatCardParam }
            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatCardRowContainer));



