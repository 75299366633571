import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import * as moment from 'moment'
import ReChartLine from '../../ComponentsGeneral/ReCharts/ReChartLine'


  const lineKeyArray = [{'lineKey': 'Max Manifest to Flight Arrival', 'stroke': '#f43e1f'},
                        {'lineKey': 'Avg Manifest to Flight Arrival', 'stroke': '#413ea0'}
                        // {'lineKey': 'Avg Manifest to Actual Flight Arrival', 'stroke': '#ff9900'}


                        // {'lineKey': 'AvgOTDtoMC', 'stroke': '#82ca8d'}
                    ]




  class ChartOTDtoEstArrival extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    onClick = (line, lineKey) => {

      var dataKey = "'" + line.dataKey + "'"

      var dateValue = moment(line.payload.Date).format('YYYY-MM-DD')

      var new_date = moment(dateValue, "YYYY-MM-DD").add(1, 'days');

      var endDateValue = "'" + moment(new_date).format('YYYY-MM-DD') + "'"
 
      this.props.history.push({
        pathname: 'zulily/shiplist',
        search: 'startdt=' + "'" + dateValue + "'" + '&' +
        'enddt=' + endDateValue + '&' +
        'datetype=' + null + '&' +
        'notdeparted=' + null + '&' +
        'portexit=' + null + '&' +
        'portentry=' + null + '&' +
        'shiptocountry=' + null + '&' +
        'finalcarrier=' + null  + '&' +
        'mawb=' + null  + '&' +
        'mc=' + null  + '&' +
        'hawb=' + null  + '&' +
        'filter=' + dataKey

        }); 
  
    }

    render() {

      let otdToMC;
      if (this.props.isOTDtoFlightArrivalDataLoading) {
        otdToMC=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Manifest Date to Flight Arrival (Days)'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      }  
      else if (typeof(this.props.OTDtoFlightArrivalData) !== 'undefined' && this.props.OTDtoFlightArrivalData != null) {

          otdToMC =

              <section className="panel">
              <header className="panel-heading">
              {'Manifest Date to Flight Arrival (Days)'}
              </header>

                  <ReChartLine 
                      data={this.props.OTDtoFlightArrivalData} 
                      dataKey='Date'
                      lineKeys= { lineKeyArray }
                      onClick={{ onClick: (line) => this.onClick(line) }}
                  />

              </section>
        
      }

      return(
        <div>
            {otdToMC}
        </div>
      );

    }
  }
  
  export default withRouter(ChartOTDtoEstArrival);
  
