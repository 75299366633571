import * as types from '../constants/actionsUserTypes';
import {appSettings} from '../../app/appSettings'

const initialState = {
        isAppSigningInLoading: false,
        userSignInData: [],
        currentUserEmail: '',

        isGetPageConfigLoading: false,
        pageConfigData: [],

        // userSettingsLanguage: 'English'

        userSignUpLoading: false,
        userSignUpComplete: false,

        isPendingUserRequestsLoading: true,
        pendingUserRequestsData: [],

        userAccountData: [],
        isUserAccountsLoading: true,
};

function checkStatusCode( res )
{
        if(res === "200"){ return true }
        else { return false }
}

function actionOnError( proc )
{
        console.log('DB connection error')

        // if (appSettings.devEnvironment === true) {
        //         window.alert("Please reload and try again.")
        // } 
        // else {
        //         // window.alert("Please reload and try again.")

        // }
}


function saveArrayToLocalStorage(keyID, arrayToSave)
{
        try{
                for (let key in arrayToSave) {
                  // save to localStorage
                  localStorage.setItem( `${keyID}.`+key, JSON.stringify(arrayToSave[key]));
                }
              }
              catch(e){
                console.log('error', e)
              }
}

function saveArrayAsStringToLocalStorage(keyID, arrayToSave)
{
        try{
                localStorage.setItem(keyID, JSON.stringify(arrayToSave));
              }
              catch(e){
                console.log('error', e)
              }
}

function saveStringToLocalStorage(key, stringToSave)
{
        try{
                localStorage.setItem(key, stringToSave)
              }
              catch(e){
                console.log('error', e)
              }

}

export const ErrorReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.APP_SIGNIN_ERROR:
                        actionOnError()
                        return state;

        case types.USER_ADD_ERROR:
                        actionOnError()
                        return state;
        default:
                return state;
	}
};



export const SignOutReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:      
                localStorage.clear();
                state = initialState;
                return state;
      
        default:
                return state;
	}
};


export const UserServicesReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state

        case types.APP_SIGNIN:
                return Object.assign({}, state, {
                        isAppSigningInLoading: true
                });
        case types.APP_SIGNIN_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError( action.payload )
                        return state;
                }
                var newSignIndata = JSON.parse(action.payload.body)
                if(newSignIndata.data[0].GroupName === null) {
                        window.alert("Your account is new and pending approval.")
                        localStorage.clear();
                        window.location.reload();  
                        return initialState
                }
                if(newSignIndata.data[0].Active != true) {
                        window.alert("Your account is not active.")
                        localStorage.clear();
                        window.location.reload();  
                        return initialState
                }

                //log user out if they do not have access to use product as specified in app settings
                var parseNewProdData  = newSignIndata.data[0].ProductName
                var parsedProdData = JSON.parse(parseNewProdData)
                try{
                        if(parsedProdData.filter( item => item.ProductName === appSettings.product ).length === 0 ){
                                window.alert("Your account is not active.")
                                localStorage.clear();
                                window.location.reload();  
                                return initialState
                        }
                }
                catch(e)
                {
                        window.alert("Your account is not active.")
                        localStorage.clear();
                        window.location.reload();  
                        return initialState
                }



                return Object.assign({}, state, {
                    userSignInData: newSignIndata.data[0],
                    currentUserEmail: newSignIndata.data[0].EmailAddr,
                    isAppSigningInLoading: false
                });



        case types.GET_PAGE_CONFIG:
                return Object.assign({}, state, {
                        isGetPageConfigLoading: true
                });
        case types.COMPLETE_PAGE_CONFIG:   
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newPageConfigData = JSON.parse(action.payload.body);
                var parseNewPageConfigData  = newPageConfigData.data[0].PageConfig
                var parseNewUserIDConfigData = JSON.parse(parseNewPageConfigData)
                var configDataToSave = parseNewUserIDConfigData.UserID[0]


                /////////////save ProductName
                var prodData = configDataToSave["ProductName"]
                var newArrayProdName = []
                try{

                        for (let key in prodData) {
                                newArrayProdName.push({label: prodData[key]["ProductName"], value: prodData[key]["ProductName"] , id: prodData[key]["ProductID"]})
                        }
                        saveArrayAsStringToLocalStorage("PageConfig.ProductName", newArrayProdName)
                        }
                catch(e){
                console.log('error', e)
                }


                /////////////save LanguageName
                var userSettingData = configDataToSave["UserSettings"]
                // var newArrayUserSettings = []
                // var userSettingsLanguage;
                try{

                        for (let key in userSettingData) {
                                // console.log('key', key)
                                // console.log('userSettingData', userSettingData)   



                                // saveArrayAsStringToLocalStorage("PageConfig.UserSettings.Language", userSettingData[key]["LanguageName"])

                                // userSettingsLanguage

                                // userSettingsLanguage = userSettingData[key]["LanguageName"]

                                // newArrayUserSettings.push({label: "LanguageName", value: userSettingData[key]["LanguageName"] })


                                // saveStringToLocalStorage("")

                                saveStringToLocalStorage("PageConfig.UserSettings.Language", userSettingData[key]["LanguageName"] )

                                // window.location.reload(); 
                                
                        }
                        // saveArrayAsStringToLocalStorage("PageConfig.UserSettings", newArrayUserSettings)
                        }
                catch(e){
                        saveStringToLocalStorage("PageConfig.UserSettings.Language", "English")

                        // window.location.reload(); 
                }

                                


                /////////////save CustomerName
                var CustomerData = configDataToSave["CustomerName"]
                var newArrayCustName = []
                try{

                        //this filters customers to be shown according to the app settings customer/product matrix
                        for (let key in CustomerData) {                             
                                if(  appSettings.customerProductMatrix.filter(x => x.customer.includes(CustomerData[key]["CustomerName"]) && x.product.includes(appSettings.product)).length > 0 ){
                                        newArrayCustName.push({label: CustomerData[key]["CustomerName"], value: CustomerData[key]["CustomerName"], id: CustomerData[key]["CustomerID"], admin: CustomerData[key]["Admin"] })
                                }
                        }
                        saveArrayAsStringToLocalStorage("PageConfig.CustomerName", newArrayCustName)
                        }
                catch(e){
                console.log('error', e)
                }



                
                /////////////save CustomerBusinessUnitName
                var CustomerBusData = configDataToSave["CustomerBusinessUnitName"]
                try{
                        //this filters customers to be shown according to the app settings customer/product matrix
                        for (let key in CustomerBusData) {      
                                var newArrayCustBusName = []
                                if(  appSettings.customerProductMatrix.filter(x => x.customer.includes(CustomerBusData[key]["ProductName"]) && x.product.includes(appSettings.product)).length > 0 ){

                                        var prodCustBus = CustomerBusData[key]["ProductCustomerBusinessUnitName"]
                                        for (let key in prodCustBus) {    
                                                newArrayCustBusName.push({label: prodCustBus[key]["CustomerBusinessUnitName"], value: prodCustBus[key]["CustomerBusinessUnitName"]     , id: prodCustBus[key]["CustomerBusinessUnitID"] })
                                        }
                                }
                                if(newArrayCustBusName.length > 0 ){
                                        saveArrayAsStringToLocalStorage("PageConfig.CustomerBusinessUnit." + CustomerBusData[key]["ProductName"], newArrayCustBusName)
                                }
                        }
                        }
                catch(e){
                console.log('error', e)
                }




                /////////////save ZUShipToAirport
                try{
                        var ZUShipToAirport = configDataToSave["ZUShipToAirport"]
                        var newArrayZUShipToAirport = []
                        try{
                                for (let key in ZUShipToAirport) {
                                        newArrayZUShipToAirport.push({label: ZUShipToAirport[key]["ShipToAirport"], value: ZUShipToAirport[key]["ShipToAirport"] })
                                }
                                saveArrayAsStringToLocalStorage("PageConfig.ZUShipToAirport", newArrayZUShipToAirport)
                                }
                        catch(e){
                        console.log('error', e)
                        }

                }
                catch(e){
                        // console.log('no ZUShipToAirport', e)
                }


                /////////////save GBShipToAirport
                try{
                        var GBShipToAirport = configDataToSave["GBShipToAirport"]
                        var newArrayGBShipToAirport = []
                        try{
                                for (let key in GBShipToAirport) {
                                        newArrayGBShipToAirport.push({label: GBShipToAirport[key]["ShipToAirport"], value: GBShipToAirport[key]["ShipToAirport"] })
                                }
                                saveArrayAsStringToLocalStorage("PageConfig.GBShipToAirport", newArrayGBShipToAirport)
                                }
                        catch(e){
                        console.log('error', e)
                        }

                }
                catch(e){
                        console.log('no GBShipToAirport', e)
                }

                /////////////save GBCustomerCode
                try{
                        var GBCustomerCode = configDataToSave["GBCustomerCode"]
                        var newArrayGBCustomerCode = []
                        try{
                                for (let key in GBCustomerCode) {
                                        newArrayGBCustomerCode.push({label: GBCustomerCode[key]["CustomerCode"], value: GBCustomerCode[key]["CustomerCode"] })
                                }
                                saveArrayAsStringToLocalStorage("PageConfig.GBCustomerCode", newArrayGBCustomerCode)
                                }
                        catch(e){
                        console.log('error', e)
                        }

                }
                catch(e){
                        console.log('no GBCustomerCode', e)
                }    
                
                
                return Object.assign({}, state, {
                        pageConfigData: configDataToSave,
                        isGetPageConfigLoading: false
        });   


        case types.USER_ADD_POST:
                return Object.assign({}, state, {
                        userSignUpLoading: true
                });


        case types.USER_ADD_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return Object.assign({}, state, {
                        userSignUpLoading: false
                });
        }
        else{
                // window.alert("Your request has been submitted, please wait for email notification of admin approval.")
                var newshipgraph = JSON.parse(action.payload.body)
                var newshipgrapharray = [newshipgraph.data]
                var msg = newshipgrapharray[0]
        
             
                if(msg[0].msg==='Email Address already in the system'){
                       
                        // window.alert("This email has a pending request or an existing account.")
                        return Object.assign({}, state, {
                                userSignUpLoading: false
                        });
                }

                else{
                        return Object.assign({}, state, {
                                userSignUpLoading: false,
                                userSignUpComplete: true
                        });
                }
        }

        case types.PENDING_USER_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newPendingUserdata = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        pendingUserRequestsData: newPendingUserdata.data,
                        isPendingUserRequestsLoading: false
                });

        case types.USER_UPDATE_POST:
                return Object.assign({}, state, {
                        isPendingUserRequestsLoading: true
                });

        case types.USER_ACCOUNT_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newUserdata = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        userAccountData: newUserdata.data,
                        isUserAccountsLoading: false
                });



        case types.USER_DELETE_COMPLETE:
                return Object.assign({}, state, {
                        isUserAccountsLoading: true
                });


        default:
                return state;
	}
};





