import {connect} from 'react-redux';
import React, {Component} from 'react';
import GBDtlHawbDetails from './GBDtlHawbDetails'
import * as actions from '../../../../redux/actions/gbActions';
import {appSettings} from '../../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';


const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    // isIBCDtlDataLoading:                store.gbDataReducer.isIBCDtlDataLoading,
    // IBCDtlData:                         store.gbDataReducer.IBCDtlData,

    isMawbDataLoading:                  store.gbDataReducer.isMawbDataLoading,
    MawbDetailData:                     store.gbDataReducer.MawbDetailData,

    isHawbDataLoading:                  store.gbDataReducer.isHawbDataLoading,
    HawbDetailData:                     store.gbDataReducer.HawbDetailData,

    isHawbMCDataLoading:                store.gbDataReducer.isHawbMCDataLoading,
    HawbMCDetailData:                   store.gbDataReducer.HawbMCDetailData,
    
    isUpsMiDataLoading:                 store.gbDataReducer.isUpsMiDataLoading,
    UpsMiData:                          store.gbDataReducer.UpsMiData,

    isJobEventsDataLoading:             store.gbDataReducer.isJobEventsDataLoading,
    JobEventsData:                      store.gbDataReducer.JobEventsData,

});

const mapDispatchToProps = dispatch =>
({

    // gbGetIBCInboundDtl:( _db, _email, _mawb, _mc, _hawb ) =>
    // {
    //     dispatch( actions.gbGetIBCInboundDtl( _db, _email,  _mawb, _mc, _hawb ) )
    // },



    gbGetMawbDetails:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _customercode, _shipmenttype ) =>
    {
        dispatch( actions.gbGetMawbDetails( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter,_filterValue, _customercode, _shipmenttype ) )
    },

    gbGetHawbDetails:( _db, _email, _mawb, _mc, _hawb, _trackingno ) =>
    {
        dispatch( actions.gbGetHawbDetails( _db, _email,  _mawb, _mc, _hawb, _trackingno ) )
    },

    gbGetHawbDetailsMC:( _db, _email, _mawb, _mc, _hawb, _trackingno ) =>
    {
        dispatch( actions.gbGetHawbDetailsMC( _db, _email,  _mawb, _mc, _hawb, _trackingno ) )
    },

    gbGetUpsMI:( _db, _email, _trackingno ) =>
    {
        dispatch( actions.gbGetUpsMI( _db, _email, _trackingno ) )
    },

    gbGetJobEvents:( _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter) =>
    {
        dispatch( actions.gbGetJobEvents(  _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter ) )
    },

    gbGetMissingUpsEventsAPI:( _db, _email, _trackingNumber) =>
    {
        dispatch( actions.gbGetMissingUpsEventsAPI(  _db, _email, _trackingNumber ) )
    },
    
});


class GBDtlHawbDetailsContainer extends Component
{
	constructor(props)
	{
		super(props);
	}

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
        var paramStartDT = null
        var paramEndDT = null
        var paramDateType = null
        var paramNotDeparted = null
        var paramPortExit = null
        var paramPortEntry = null
        var paramFinalCarrier = null

        var paramMawb = null
        var paramMC = null
        var paramHawb = null
        var paramTrackingNo = null
        var paramFilter = null
        var paramFilterValue = null

        const values = queryString.parse(this.props.location.search)

        if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
            paramStartDT = values.startdt
        }
        if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
            paramEndDT = values.enddt
        }
        if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
            paramDateType = values.datetype
        }
        if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
            paramNotDeparted = values.notdeparted
        }
        if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
            paramPortExit = values.portexit
        }
        if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
            paramPortEntry = values.portentry
        }
        if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
            paramFinalCarrier = values.finalcarrier
        }

        if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
            paramMawb = values.mawb
        }
        if(typeof(values.mc) !== 'undefined' && values.mc != null ){
            paramMC = values.mc
        }
        if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
            paramHawb = values.hawb
        }
        if(typeof(values.trackingno) !== 'undefined' && values.trackingno != null ){
            paramTrackingNo = values.trackingno
        }
        if(typeof(values.filter) !== 'undefined' && values.filter != null ){
            paramFilter = values.filter
        }
        if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
            paramFilterValue = values.filterValue
        }

        this.props.gbGetMawbDetails( appSettings.gbDB, this.props.currentUserEmail, null, null, null, null, null, null, null, null, paramTrackingNo, null, null, 'search', null, null );
        this.props.gbGetHawbDetails( appSettings.gbDB, this.props.currentUserEmail, null, null, null, paramTrackingNo ); //need to search only using tracking no because proc returns different results for params
        this.props.gbGetHawbDetailsMC( appSettings.gbDB, this.props.currentUserEmail, null, paramMC, null, paramTrackingNo ); //need to search only using tracking no because proc returns different results for params
        // this.props.gbGetIBCInboundDtl( appSettings.gbDB, this.props.currentUserEmail, null, null, paramTrackingNo );
        this.props.gbGetUpsMI( appSettings.gbDB, this.props.currentUserEmail, paramTrackingNo );
        this.props.gbGetJobEvents( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramMawb, paramMC, paramHawb, paramFilter );


    }

	
	
    render()
    {

    	return (

            <GBDtlHawbDetails

                routeCustomer='gaobo'

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                // isIBCDtlDataLoading={this.props.isIBCDtlDataLoading}
                // IBCDtlData={this.props.IBCDtlData}

                isMawbDataLoading={this.props.isMawbDataLoading}
                MawbDetailData={this.props.MawbDetailData}

                isHawbDataLoading={this.props.isHawbDataLoading}
                HawbDetailData={this.props.HawbDetailData}
           
                isHawbMCDataLoading={this.props.isHawbMCDataLoading}
                HawbMCDetailData={this.props.HawbMCDetailData}

                isUpsMiDataLoading={this.props.isUpsMiDataLoading}
                UpsMiData={this.props.UpsMiData}

                isJobEventsDataLoading={this.props.isJobEventsDataLoading}
                JobEventsData={this.props.JobEventsData}
                    
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GBDtlHawbDetailsContainer));



