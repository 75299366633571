import custLogo       from '../resources/img/graph-12.png';
import smallFaTruck        from '../resources/img/fa_truck.png'; 
import svgAirplaneGreen      from '../resources/img/planeGreen.svg';
import svgAirplaneGrey      from '../resources/img/planeGrey.svg';
import svgShip              from '../resources/img/ship.svg';
import svgExclamation       from '../resources/img/exclamation-triangle.svg'

export const awsCognitoConfig = {

    cognitoLogin: {
        Auth: {
            region: 'us-east-1',
            IdentityPoolId: '',
            UserPoolId: 'us-east-1_tevei1CYE',
            ClientId: '4ihtqr14j03vctp700da3o6031'
        }
    },
};


export const appConfig = {

    APP_NAME: 'dashboard',

    smallFaTruck: smallFaTruck,

    svgAirplaneGreen: svgAirplaneGreen,
    svgAirplaneGrey: svgAirplaneGrey,
    svgShip: svgShip,
    svgExclamation: svgExclamation,

    customerLogo: custLogo,

    //Colors
    colors: {
        red: '#F05050',
        blue: '#23b7e5',
        yellow: '#F8C471',
        green: '#27C24C',
        violet: '#7266ba',
        darkblue: '#34495E',
        lightgrey: '#ABB2B9',
        darkgrey: '#262626'
    },

    googleMapAPI: {
        Map_API_Key: 'AIzaSyAPsFxsOv5qL8zElH-BFVIuwz1G-Ijf7sE'
    },


    //API
    API: {
        data: {

            //Dashboard Run Stored Proc API:
            API_RunStoredProc: 'https://y81t0jebi5.execute-api.us-east-1.amazonaws.com/prod/dashboard-run_stored_proc_function',

            
 
            //User API's:
            API_GetUser: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getuser',
            API_UserAdd: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-useradd',
            API_GetPendingUserAccounts: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getpendinguseraccounts',
            API_GetUserAccounts: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getuseraccounts',
            API_UserUpdate: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-userupdate',
            API_PostUserPermissions: 'https://mp5jybucfh.execute-api.us-east-1.amazonaws.com/Prod/user-postuserpermissions',

            API_GetPageConfigUsers: 'https://mp5jybucfh.execute-api.us-east-1.amazonaws.com/Prod/user-getpageconfigusers',



            //ZU APIs:

            API_Dashboard_ZuGetEmailFileURL: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-mbol-email-files-create-url',

            API_Dashboard_GetStatCardValues: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getstatcardvalues',
            API_Dashboard_GetOTDtoMC: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getotdtomc',
            API_Dashboard_GetOTDtoFlightDepart: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getotdtoflightdepart',
            API_Dashboard_GetOTDtoEstArrival: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getotdtoestarrival',
            API_Dashboard_GetOTDtoThreeAvgs: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getotdtothreeavgs',
            API_Dashboard_GetMawbDetails:  'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getmawbdetails',
            API_Dashboard_GetMCDetails:  'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getmcdetails',
            API_Dashboard_GetHawbDetails:  'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-gethawbdetails',
            API_Dashboard_GetAgingChart:  'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getagingchart',
            API_Dashboard_GetDimWeightChart: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getdimweightchart',
            API_Dashboard_GetJobEvents: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getjobevents',
            API_Dashboard_GetIBCInboundDtl: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getibcinbounddtl',
            API_Dashboard_GetHazMat: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-gethazmat',
            API_Dashboard_GetChartPendingSailing: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getchartpendingsailing',
            API_Dashboard_GetChartDemurrage: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getchartdemurrage',
            API_Dashboard_GetUpsMI: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getupsmi',
            API_Dashboard_GetDailyShipmentsToPorts: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getdailyshipmentstoports',
            API_Dashboard_GetChartAvgCustomClear: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-dashboard-getchartavgcustomclear',

            API_DS_UPS_NeuralNet: 'https://2cky71rk88.execute-api.us-east-1.amazonaws.com/prod/zu-ds-ups-neuralnet', 


            //Gao Bo APIs:
            GB_API_Dashboard_GetMawbDetails: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getmawbdetails',
            GB_API_Dashboard_GetChartReportingTransitMetrics: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartreportingtransitmetrics',
            GB_API_Dashboard_GetChartOriginFinalMileToDelivery: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartoriginfinalmiletodelivery',
            GB_API_Dashboard_GetDayOfWeekMetrics: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getdayofweekmetrics',
            GB_API_Dashboard_GetDataExport: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getdataexport',
            GB_API_Dashboard_GetChartManifestToDelivery: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartmanifesttodelivery',
            GB_API_GetMissingUpsEvents: '',
            GB_API_Dashboard_GetChartInWhsToFirstScan: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-gb-dashboard-getchartinwhstofirstscan',
            GB_API_Dashboard_GetChartInWhsToDelivery: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartinwhstodelivery',
            GB_API_Dashboard_GetChartDailyScanCount: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-Dashboard_GetChartDailyScanCount',

            GB_API_Dashboard_GetChartInWhsToFlightDepart: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartinwhstoflightdepart',
            GB_API_Dashboard_GetChartInWhsToFreightRecovery: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartinwhstofreightrecovery',
            GB_API_Dashboard_GetChartFlightDepartToFirstScan: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getchartflightdeparttofirstscan',

            GB_API_Dashboard_GetBulkHawbDetails: 'https://2id59vfp0a.execute-api.us-east-1.amazonaws.com/prod/gb-dashboard-getbulkhawbdetails',



            //Google APIs:
            API_GetShipmentTracker: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getshipmenttrackerdata', //returns main page data list of shipment (unless specific shipment is specified)
            API_GetShipSchedPlot: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getshipschedplot',
            API_GetStatCard: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getstatcardvalues',
            API_GetJobDataByJobNumber: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getjobdatabyjobnumber', //returns job events (shipment milestones)
            API_GetShipCommentsByJobID: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getshipcommentsbyjobid',
            API_GetShipHistoryBar: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getshiphistorybar',
            API_GetMilestoneAlerts_Omni_All: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getmilestonealerts-omni-all', //proc is actually GetAlerts
            API_GetPivotTableData: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getpivottabledata',
            API_GetNetworkReporting: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getnetworkreporting',
            API_GetJobsReferenceNumbers: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getjobsreferencenumbers',
            API_GetJobsLegs: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getjobslegs',
            API_GetKpiOverview: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getkpioverview',
            API_GetKpiDetails: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getkpidetails',
            API_GetAlertKpiTrend: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getalertkpitrend',
            API_GetKpiLanePerformance: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getkpilaneperformance',
            API_PostShipmentSubscribe: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-postshipmentsubscribe',
            API_PostShipmentComment: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-postshipmentcomment',
            API_PostOmniAction: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-postomniaction',
            API_PostGoogleAction: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-postgoogleaction',
            API_PostHotShipment: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-posthotshipment',
            API_PostServiceIssue: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-postserviceissue',
            API_GetServiceIssues: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getserviceissues',
            API_GetShipmentGPS: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getshipmentgps',
            API_GetChartDemurrage: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getchartdemurrage',
            API_GetChartPendingSailing: 'https://d0k17hf71f.execute-api.us-east-1.amazonaws.com/prod/dash-getchartpendingsailing',


            
            //UPS APIs:
            API_ups_api_label_creation: 'https://zh0lq4h26g.execute-api.us-east-1.amazonaws.com/prod/ups-api-label-creation',
        

        }
    }

    


};



