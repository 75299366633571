import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import {
    Table,
    TableBody
  }                         from '../../../ComponentsGeneral/table';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import StatsCard from '../../../ComponentsGeneral/StatsCard/StatsCard'
import {
  buttonFormatter,
  hawbButtonFormatter,
  gbFlagFormatter
} from '../../../../resources/logic/formatting';
import GBMicomEvents from '../GBDetailComponents/GBMicomEvents';

  class GBMCDetails extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    render() {
      // const { ExportCSVButton } = CSVExport;


      /////////
      const uniqueBy = prop => list => {
        const uniques = {}
        return list.reduce(
            (result, item) => {
                if (uniques[item[prop]]) return result
                uniques[item[prop]] = item
                return [...result, item]
            },
            [],
        )
      }
      
      const uniqueByHAWB = uniqueBy('HAWB')


      const hawbColumns = [
        {

        dataField: 'HAWB',
        text: 'Tracking No',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '40%' , textAlign: 'center' };
        },
        sort: true,
        style: { overflowWrap: "break-word" }
      }, {   

        dataField: 'R_State',
        text: 'Ship-to State',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {

        dataField: 'ParcelWeight',
        text: 'Package Weight (kgs)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'ManifestToFlightDepartActual',
        text: 'Inbd Manifest to Depart',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'ManifestToFreightRecoveryActual',
        text: 'Inbd Manifest to Freight Recovery',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'HazMat',
        text: 'HazMat',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      },
      {
        dataField: 'icons',
        text: 'Flags',
        align: 'center',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '5%' , textAlign: 'center' };
        },
        formatter: gbFlagFormatter
      },
      {
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   

            var paramMC = row.Barcode.trim() 
            var paramTrackingNo = row.HAWB.trim()

              this.props.history.push({
              pathname: 'hawbdetails',
              search: 'mc=\'' + paramMC +'\'' + '&' +
              'trackingno=\'' + paramTrackingNo +'\''
              }); 
            ; }
          }
      }


      
      ]

      const mobileHawbColumns = [{

        dataField: 'HAWB',
        text: 'Tracking No',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        sort: true,
        style: { overflowWrap: "break-word" },
        formatter: hawbButtonFormatter,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   

            var paramMC = row.Barcode.trim() 
            var paramTrackingNo = row.HAWB.trim()

              this.props.history.push({
              pathname: 'hawbdetails',
              search: 'mc=\'' + paramMC +'\'' + '&' +
              'trackingno=\'' + paramTrackingNo +'\''
              }); 
            ; }
          }

        },

      ]


        let mawbDet;
        let statCard;
        if (this.props.isMawbDataLoading) {
          mawbDet=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'MAWB Details'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>

          statCard=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>

        }  
        else if (typeof(this.props.MawbDetailData) !== 'undefined' && this.props.MawbDetailData != null) {
            statCard =

                <section className="panel">
                  <div
                  className="row"
                  style={{marginBottom: '15px'}}>
                    <div className="col-md-4" >
                    <StatsCard
                        statValue={ this.props.MawbDetailData[0].AvgMaxManToDepartActual}
                        statLabel={'Inbd Manifest to Depart (Avg/Max)'}
                        icon={<i className="fa fa-plane" />}
                        backColor={'blue'}
                        isMobile={this.props.isMobile}
                      />
                    </div>
                    <div className="col-md-4" >
                      <StatsCard
                        statValue={this.props.MawbDetailData[0].AvgMaxManToFreightRecoveryActual}
                        statLabel={'Inbd Manifest to Freight Recovery (Avg/Max)'}
                        icon={<i className="fa fa-plane" />}
                        backColor={'blue'}
                        isMobile={this.props.isMobile}
                      />
                    </div>


                    <div className="col-md-4" >
                    <StatsCard
                        statValue={this.props.MawbDetailData[0].ActualDepartToArrival}
                        statLabel={'Depart To Arrival (Days)'}
                        icon={<i className="fa fa-plane" />}
                        backColor={'green'}
                        isMobile={this.props.isMobile}
                      />                            
                    </div>


              </div>

                </section>


            mawbDet =

                <section className="panel">
                    <div className="row" style={{marginBottom: '0px', overflowX: 'hidden!important'}}>
                    <div className="col-md-6">
                    <header className="panel-heading">
                    {'MAWB Details'}
                    </header>

                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important'}}>
                        <Table>
                            <TableBody >

                                <tr key={'1'} >
                                <td>MAWB: <strong>{this.props.MawbDetailData[0].MAWB}</strong></td>
                                </tr>
                                <tr key={'2'} >
                                <td>HAWB: <strong>{this.props.MawbDetailData[0].HAWB}</strong></td>
                                </tr>
                                <tr key={'3'} >
                                <td>Total Containers: <strong>{this.props.MawbDetailData[0].TotContainers}</strong></td>
                                </tr>
                                <tr key={'4'} >
                                <td>Total Packages: <strong>{this.props.MawbDetailData[0].TotPackages}</strong></td>
                                </tr>

                                <tr key={'6'} >
                                <td>Haz Mat Package Count: <strong>{this.props.MawbDetailData[0].HazMatCount}</strong></td>
                                </tr>
                                <tr key={'7'} >
                                <td> &nbsp; </td>
                                </tr>


                            </TableBody>
                        </Table>
                    </div>

                    </div>


                    <div className="col-md-6">
                    <header className="panel-heading">
                    {'Flight Details'}
                    </header>
                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important'}}>
                        <Table>
                            <TableBody >

                                <tr key={'1'} >
                                <td>Airline Carrier: <strong>{this.props.MawbDetailData[0].AirlineCarrier}</strong></td>
                                </tr>
                                <tr key={'2'} >
                                <td>Flight No: <strong>{this.props.MawbDetailData[0].FlightNo}</strong></td>
                                </tr>

                                <tr key={'4'} >
                                <td>Flight Depart: <strong>{this.props.MawbDetailData[0].FlightDepartDT}</strong></td>
                                </tr>
                                <tr key={'5'} >
                                <td>Flight Arrival: <strong>{this.props.MawbDetailData[0].FlightArrivalDT}</strong></td>
                                </tr>
                                <tr key={'6'} >
                                <td>Ship To Airport: <strong>{this.props.MawbDetailData[0].ShipToAirport}</strong></td>
                                </tr>
                                <tr key={'7'} >
                                <td>Ship To Country: <strong>{this.props.MawbDetailData[0].ShipToCountry}</strong></td>
                                </tr>

                            </TableBody>
                        </Table>
                    </div>

                    </div>

                  </div>

                </section>
        }




      let mcDiv;
      if (this.props.isMCDataLoading) {
          mcDiv=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
        }  
        else if (typeof(this.props.MCDetailData) !== 'undefined' && this.props.MCDetailData != null) {

          mcDiv = 
          <section className="panel">
          <header className="panel-heading">
            {'Master Carton'}
          </header>
              <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important'}}>
                  <Table>
                      <TableBody >
                          <tr key={'1'} >
                          <td>Master Carton: <strong>{this.props.MCDetailData[0].Barcode}</strong></td>
                          </tr>
                          <tr key={'2'} >
                          <td>Package Count: <strong>{this.props.MCDetailData[0].CartonCount}</strong></td>
                          </tr>
                          <tr key={'3'} >
                          <td>Sum (Unit Count): <strong>{this.props.MCDetailData[0].SumUnitCount}</strong></td>
                          </tr>
                          <tr key={'4'} >
                          <td>Weight (kgs): <strong>{this.props.MCDetailData[0].Weight}</strong></td>
                          </tr>
                          <tr key={'5'} >
                          <td>Hazmat: <strong>{this.props.MCDetailData[0].HazMat}</strong></td>
                          </tr>
                          <tr key={'6'} >
                            <td className="row" style={{marginLeft: '1px'}}> Flags:   &nbsp; { gbFlagFormatter('x', this.props.MCDetailData[0]) } </td>
                          </tr>

                      </TableBody>
                  </Table>
              </div>
          </section>

        }
        else {
          mcDiv =
          <div></div>
        }     
        

        let hawbList;
        if (this.props.isHawbDataLoading) {
          hawbList=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
        }  
        else if (typeof(this.props.HawbDetailData) !== 'undefined' && this.props.HawbDetailData != null) {

          hawbList = 
          <section className="panel" >
          <header className="panel-heading">
            {'Packages'}
          </header>
              <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important'}}>
                
                <ToolkitProvider
                    keyField="UniqueID"
                    data={ this.props.HawbDetailData }
                    columns= {(this.props.isMobile == true  ) ? mobileHawbColumns  : hawbColumns }
                    >
                    {
                      props => (
                        <div>
                          <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                        </div>
                      )
                    }
                </ToolkitProvider>

              </div>
          </section>

        }
        else {
          hawbList =
          <div></div>
        }  






      return(
        <div>
            {statCard}
            {mcDiv}
            {hawbList}
            {mawbDet}
            <GBMicomEvents  
              isMobile = {this.props.isMobile}
              isDataLoading = {this.props.isJobEventsDataLoading}
              userGroupName = {this.props.userSignInData.GroupName}
              inputData = {this.props.JobEventsData}
            />

            
        </div>
      );

    }
  }
  

  export default withRouter(GBMCDetails);
  
