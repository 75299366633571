import {connect} from 'react-redux';
import React, {Component} from 'react';
import ChartCustomsClearTime from './ChartCustomsClearTime'
import * as actions from '../../../redux/actions/zuActions';
import {appSettings} from '../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';



const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    isAvgCustClearDataLoading:     store.zuDataReducer.isAvgCustClearDataLoading,
    AvgCustClearData:              store.zuDataReducer.AvgCustClearData,


});

const mapDispatchToProps = dispatch =>
({

    zuGetChartAvgCustomClear:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry ) =>
    {
        dispatch( actions.zuGetChartAvgCustomClear( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry ) )
    }
    

});


class ChartCustomsClearTimeContainer extends Component
{
	constructor(props)
	{
		super(props);
		
	}

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null
    var paramShipToCountry = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }
    if(typeof(values.shiptocountry) !== 'undefined' && values.shiptocountry != null ){
        paramShipToCountry = values.shiptocountry
    }

    this.props.zuGetChartAvgCustomClear( appSettings.zuDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramShipToCountry );

    }

	
	
    render()
    {

    	return (

            <ChartCustomsClearTime 

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isAvgCustClearDataLoading={this.props.isAvgCustClearDataLoading}
                AvgCustClearData={this.props.AvgCustClearData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChartCustomsClearTimeContainer));



