
import React, {
    PureComponent
}                         from 'react';


import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';

import KPIContainer from '../../../components/ComponentsGoogle/KPI/KPIContainer'; 
// import CustomTable from '../../components/CustomTable/CustomTable';
// import FilterMainGoogle from '../../components/ComponentsGoogle/FilterMainGoogle/FilterMainGoogle';
import {appSettings} from '../../../app/appSettings'


class kpiView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
    
            width: window.innerWidth,
    
          };
      }
    
        //Mobile View
        handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
        };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
            }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }    


    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;


        return(


            <AnimatedView>

                <KPIContainer />

            </AnimatedView>
            



        );
    }
}

export default kpiView;

