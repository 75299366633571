import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
// import {
//     Table,
//     TableBody
//   }                         from '../../../components/ComponentsGeneral/table';
  import {//valueDeliveryDayFormatter, 
    buttonFormatter,
    hawbButtonFormatter,
    hawbButtonFormatterMobile, 
    afHawbButtonFormatter,
    afHawbButtonFormatterMobile
    // modalButtonFormatter, 
    // shipmentTagFormatter, 
    // shipmentAlertIconFormatter
    // // hotShipmentIcon
  } from '../../../resources/logic/formatting';
  import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import BootstrapTable from 'react-bootstrap-table-next';
  import * as moment from 'moment'
  // import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';

  var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


  class HazMatZU extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    render() {

        const { ExportCSVButton } = CSVExport;

        /////////
        const uniqueBy = prop => list => {
          const uniques = {}
          return list.reduce(
              (result, item) => {
                  if (uniques[item[prop]]) return result
                  uniques[item[prop]] = item
                  return [...result, item]
              },
              [],
          )
        }


        
        const uniqueByMAWB = uniqueBy('MAWB')

        const uniqueByHazMat = uniqueBy('HazMat')

        const uniqueByContainerID = uniqueBy('ContainerID')

        const uniqueByHAWB = uniqueBy('HAWB')

        

        const exportColumns = [
        {
          dataField: 'AFHAWB',
          text: 'HAWB'
        }, 
        {
          dataField: 'MAWB',
          text: 'MAWB'
        }, 
        {
          dataField: 'ContainerID',
          text: 'MC'
        }, 
        {
          dataField: 'HAWB',
          text: 'Package Tracking No'
        }, 
        {
          dataField: 'ParcelWeight',
          text: 'Parcel Weight kgs'
        }, 
        {
          dataField: 'HazMat',
          text: 'Hazmat'
        }, 
        
        ]


        const mawbColumns = [{
          dataField: 'AFHAWB',
          text: 'HAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' , textAlign: 'center' };
          },
          sort: true,
          // formatter: afHawbButtonFormatter,
          // events: {
          //   onClick: (e, column, columnIndex, row, rowIndex) => {   

          //     var paramMawb = row.MAWB.trim() 
          //     var paramHawb = row.AFHAWB.trim()

          //       this.props.history.push({
          //       pathname: 'shipdetails',
          //       search: 'mawb=\'' + paramMawb +'\'' + '&' +
          //       'hawb=\'' + paramHawb +'\''
          //       }); 

          //     ; }
          //   }
        }, 
        {
          dataField: 'MAWB',
          text: 'MAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'HazMatAtMAWB',
          text: 'Hazmat',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '15%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'MAWBCountMCAtMAWB',
          text: 'Count Hazmat MC',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '15%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'MAWBCountPackagesAtMAWB',
          text: 'Count Hazmat Packages',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'MAWBSumParcelWeightAtMAWB',
          text: 'Hazmat Package Weight (kgs)',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '30%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'buttonDetails',
          text: '',
          align: 'center',
          csvExport: false,
          formatter: buttonFormatter,
          headerStyle: (colum, colIndex) => {
            return { width: '10%', textAlign: 'center' };
            },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {   
  
              var paramMawb = row.MAWB.trim() 
              var paramHawb = row.AFHAWB.trim()

                this.props.history.push({
                pathname: 'shipdetails',
                search: 'mawb=\'' + paramMawb +'\'' + '&' +
                'hawb=\'' + paramHawb +'\''
                }); 
              ; }
            }
        }
        ]

        const mobileMawbColumns = [{
          dataField: 'AFHAWB',
          text: 'HAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%' , textAlign: 'center' };
          },
          sort: true,
          formatter: afHawbButtonFormatterMobile,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {   

              var paramMawb = row.MAWB.trim() 
              var paramHawb = row.AFHAWB.trim()

                this.props.history.push({
                pathname: 'shipdetails',
                search: 'mawb=\'' + paramMawb +'\'' + '&' +
                'hawb=\'' + paramHawb +'\''
                }); 

              ; }
            }

        }, {
          dataField: 'MAWB',
          text: 'MAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '40%' , textAlign: 'center' };
          },
          sort: true
        }, 
        {
          dataField: 'HazMatAtMAWB',
          text: 'Hazmat',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '40%' , textAlign: 'center' };
          },
      }
        ]


        
        const expandToMawbByHazMatColumns = [{
          dataField: 'AFHAWB',
          text: 'HAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' , textAlign: 'center' };
          },
          sort: true
        }, 
        {
          dataField: 'HazMat',
          text: 'Hazmat',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'MAWBCountMCByHazmat',
          text: 'Count Hazmat MC',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'MAWBCountPackagesByHazmat',
          text: 'Count Hazmat Packages',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'MAWBSumParcelWeightByHazmat',
          text: 'Hazmat Package Weight (kgs)',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '30%' , textAlign: 'center' };
          },

        }
        ]

        const mobileExpandToMawbByHazMatColumns = [{
          dataField: 'HazMat',
          text: 'Hazmat',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },
          sort: true
        }, 
        {
        dataField: 'ContainerID',
        text: 'MC',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '30%' , textAlign: 'center' };
        },
        sort: true
      }, 
        {
          dataField: 'MAWBCountMCByHazmat',
          text: 'Count Hazmat MC',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '25%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'MAWBCountPackagesByHazmat',
          text: 'Count Hazmat Packages',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '25%' , textAlign: 'center' };
          },

        }
        ]


        const expandToMcColumns = [{
          dataField: 'ContainerID',
          text: 'MC',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '10%' , textAlign: 'center' };
          },
          sort: true
        }, 
        {
          dataField: 'HazMat',
          text: 'Hazmat',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'MCCountPackagesByHazmat',
          text: 'Count Hazmat Packages',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },

        },
        {
          dataField: 'MCSumParcelWeightByHazmat',
          text: 'Hazmat Package Weight (kgs)',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '30%' , textAlign: 'center' };
          },

        }
        ]

        const mobileExpandToMcColumns = [{
          dataField: 'ContainerID',
          text: 'MC',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        }, 
        {
          dataField: 'MCCountPackagesByHazmat',
          text: 'Count Hazmat Packages',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
      }
        ]




        const expandToPackageColumns = [{
          dataField: 'HAWB',
          text: 'Tracking No',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" },
          formatter: hawbButtonFormatter,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {   

              var paramMC = row.ContainerID.trim() 
              var paramTrackingNo = row.HAWB.trim()
  
                this.props.history.push({
                pathname: 'hawbdetails',
                search: 'mc=\'' + paramMC +'\'' + '&' +
                'trackingno=\'' + paramTrackingNo +'\''
                }); 
              ; }
            }


          // dataField: 'HAWB',
          // text: 'Tracking No',
          // align: 'center',
          // headerStyle: (colum, colIndex) => {
          //   return { width: '50%' , textAlign: 'center' };
          // },
          // sort: true
        }, 
        {
          dataField: 'HazMat',
          text: 'Hazmat',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '20%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'ParcelWeight',
          text: 'Package Weight (kgs)',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '30%' , textAlign: 'center' };
          },
        },

        // {
        //   dataField: 'buttonDetails',
        //   text: '',
        //   align: 'center',
        //   csvExport: false,
        //   formatter: buttonFormatter,
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '10%', textAlign: 'center' };
        //     },
        //   events: {
        //     onClick: (e, column, columnIndex, row, rowIndex) => {   
  
        //       var paramMC = row.ContainerID.trim() 
        //       var paramTrackingNo = row.HAWB.trim()
  
        //         this.props.history.push({
        //         pathname: 'hawbdetails',
        //         search: 'mc=\'' + paramMC +'\'' + '&' +
        //         'trackingno=\'' + paramTrackingNo +'\''
        //         }); 
        //       ; }
        //     }
        // }


        ]

        const mobileExpandToPackageColumns = [{
          dataField: 'HAWB',
          text: 'Tracking No',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '90%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" },
          formatter: hawbButtonFormatterMobile,
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {   

              var paramMC = row.ContainerID.trim() 
              var paramTrackingNo = row.HAWB.trim()

                this.props.history.push({
                pathname: 'hawbdetails',
                search: 'mc=\'' + paramMC +'\'' + '&' +
                'trackingno=\'' + paramTrackingNo +'\''
                }); 
              ; }
            }
        }, 
        ]







        let bootStrapTable;
        if (this.props.isHazMatDataLoading) {
          bootStrapTable=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.HazMatData) !== 'undefined' && this.props.HazMatData != null) {


                bootStrapTable =

                <div style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={ this.props.HazMatData }
                    columns= {exportColumns }

                    exportCSV = { {
                      fileName: 'ZUHazmatExport'+ currentDateTime +'.csv'
                      // separator: '|',
                      // ignoreHeader: true,
                      // noAutoBOM: false
                    } }
                    >
                    {
                      props => (
                        <div>
                          {(this.props.isMobile == true  ) ? <div></div>  : <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export Data To CSV</ExportCSVButton> }
                          <hr />
                          <div style={{'display':'none'}}>
                            <BootstrapTable { ...props.baseProps } />
                          </div>
                        </div>
                      )
                    }
                  </ToolkitProvider>


                  <ToolkitProvider
                    keyField="UniqueID"
                    data={ uniqueByMAWB(this.props.HazMatData) }
                    columns= {(this.props.isMobile == true  ) ? mobileMawbColumns  : mawbColumns }
                    exportCSV
                    >
                    {
                      props => (
                        <div>
                          {/* {(this.props.isMobile == true  ) ? <div></div>  : <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> } */}
                          <hr />
                          <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  expandRow={ expandToMawbByHazMat }/>
                        </div>
                      )
                    }
                  </ToolkitProvider>
                  </div>







        }
        else {
          bootStrapTable =
          <div></div>
        }


        const expandToMawbByHazMat = {
          renderer: row => (
            <div style={{marginLeft: '10px', marginRight: '10px'}} >
      
            <div
              className="row">
        
              <div className="col-md-12">
                
                <header className="panel-heading">
                {'HAWB: ' + row.AFHAWB}
              </header>
              <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
        
              <ToolkitProvider
                keyField="HazMat"
                data={ uniqueByHazMat(this.props.HazMatData.filter(x => x.MAWB == row.MAWB)) }
                columns= {(this.props.isMobile == true  ) ? mobileExpandToMawbByHazMatColumns  : expandToMawbByHazMatColumns }
  
  
                exportCSV
              >
                {
                  props => (
                    <div>
                      {/* {(this.props.isMobile == true  ) ? <div></div>  : <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> } */}
                      <hr />
                      <BootstrapTable { ...props.baseProps }  expandRow={ expandToMC } />
                    </div>
                  )
                }
              </ToolkitProvider>
        
              </div>
                      
                </div>
                </div>
  
                <br/>
                </div>
        
          ),
        }



        const expandToMC = {
          renderer: row => (
            <div style={{marginLeft: '10px', marginRight: '10px'}} >
      
            <div
              className="row">
        
              <div className="col-md-12">
                
                <header className="panel-heading">
                {row.HazMat + ': ' + row.AFHAWB  }
              </header>
              <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
        
              <ToolkitProvider
                keyField="UniqueID"
                data={ uniqueByContainerID(this.props.HazMatData.filter(x => x.MAWB == row.MAWB && x.HazMat == row.HazMat)) }
                columns= {(this.props.isMobile == true  ) ? mobileExpandToMcColumns  : expandToMcColumns }
                exportCSV
              >
                {
                  props => (
                    <div>
                      {/* {(this.props.isMobile == true  ) ? <div></div>  : <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> } */}
                      <hr />
                      <BootstrapTable { ...props.baseProps }  expandRow={ expandToPackages } />
                    </div>
                  )
                }
              </ToolkitProvider>
        
              </div>
                      
                </div>
                </div>
  
                <br/>
                </div>
        
          ),
          // onlyOneExpanding: true
        }


        const expandToPackages = {
          renderer: row => (
            <div style={{marginLeft: '10px', marginRight: '10px'}} >
      
            <div
              className="row">
        
              <div className="col-md-12">
                
                <header className="panel-heading">
                {'MC: ' + row.ContainerID}
              </header>
              <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
        
              <ToolkitProvider
                keyField="HAWB"
                data={ uniqueByHAWB(this.props.HazMatData.filter(x => x.MAWB == row.MAWB && x.HazMat == row.HazMat && x.ContainerID == row.ContainerID)) }
                columns= {(this.props.isMobile == true  ) ? mobileExpandToPackageColumns  : expandToPackageColumns }
                exportCSV
              >
                {
                  props => (
                    <div>
                      {/* {(this.props.isMobile == true  ) ? <div></div>  : <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> } */}
                      <hr />
                      <BootstrapTable { ...props.baseProps }   />
                    </div>
                  )
                }
              </ToolkitProvider>
        
              </div>
                      
                </div>
                </div>
  
                <br/>
                </div>
        
          ),
          // onlyOneExpanding: true
        }

      return(
        
        


        <section className="panel">
        <header className="panel-heading">
          { `Hazmat: ${this.props.HazMatData.length} Packages`}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden'}}>

          { bootStrapTable }

        </div>

        </section>


      );

    }
  }
  

  export default withRouter(HazMatZU);
  
