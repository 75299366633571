import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import * as moment from 'moment'
import ReChartBar from '../../ComponentsGeneral/ReCharts/ReChartBar'
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

const barKeyArray = [
  {'barKey': 'Percent', 'fill': '#f43e1f'}
  ]


  class GBMetrics extends PureComponent {
    constructor( props )
    {
      super( props );
    }


    chartColumns(chartType) {
        var chartTitle = '';
        var paramChartTitle = '';

        try{
            paramChartTitle = chartType
          }
          catch(e){
            console.log('error', e)
          }
          
          if (paramChartTitle == 'firstscantodelivery'){
            chartTitle = 'First Scan To Delivery'
          }
          else if (paramChartTitle == 'flightdeparttodelivery'){
            chartTitle = 'Flight Depart To Delivery'
          }
          else if (paramChartTitle == 'recoverytofirstscan'){
            chartTitle = 'Recovery To First Scan'
          }
          else if (paramChartTitle == 'manifesttodelivery'){
            chartTitle = 'Inbound Manifest To Delivery'
          }

        const ChartDetailColumns = [
            {
              dataField: 'Days',
              text: chartTitle + ' Days',
              // text: 'Days',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '50%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'Percent',
              text: 'Percent (%)',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '50%' , textAlign: 'center' };
              },
              sort: true,
            },
            {
              dataField: 'Count',
              text: 'Count',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '50%' , textAlign: 'center' };
              },
              sort: true,
            },
            // {
            //   dataField: 'Total',
            //   text: 'Total',
            //   align: 'center',
            //   headerStyle: (colum, colIndex) => {
            //     return { width: '50%' , textAlign: 'center' };
            //   },
            //   sort: true,
            // },

            ]

        return ChartDetailColumns


  

      }

      


    render() {

        // const { ExportCSVButton } = CSVExport;



            let chartFirstScanToDelivery;
            if (this.props.isFirstScanToDeliveryMetricLoading) {
                chartFirstScanToDelivery=
                  <Loader 
                    type="Plane"
                    color="#00BFFF"
                    height="50"	
                    width="50"
                  /> 
      
              }  
              else if (typeof(this.props.FirstScanToDeliveryMetric) !== 'undefined' && this.props.FirstScanToDeliveryMetric != null) {
      
                if (this.props.FirstScanToDeliveryMetric.length > 0){

                var totalNumberArray;
                var totalNumber = 0;
                var grandTotalArray;
                var grandTotalNumber = 0;


                totalNumberArray = this.props.FirstScanToDeliveryMetric.filter(x => x.Total != '' && x.Total != null)
                try{
                  totalNumber = totalNumberArray[0].Total
                }
                catch(e){}

                grandTotalArray = this.props.FirstScanToDeliveryMetric.filter(x => x.GrandTotal != '' && x.GrandTotal != null)
                try{
                  grandTotalNumber = grandTotalArray[0].GrandTotal
                }
                catch(e){

                }

  
                var chartTitle1 = 'First Scan To Delivery';
                var exportName1 = 'firstscantodelivery'
  
                chartFirstScanToDelivery =
                  <section className="panel">
                  <header className="panel-heading">
                  {`${chartTitle1}`}
                  </header>
                    <ReChartBar 
                        data={this.props.FirstScanToDeliveryMetric} 
                        dataKey='Days'
                        barKeys= { barKeyArray }
                        xLegend='Days'
                        yLegend='Percent'
                    />
                    <div>

                    <ToolkitProvider
                        keyField="Tracking"
                        data={ this.props.FirstScanToDeliveryMetric }
                        columns= {(this.props.isMobile == true  ) ? this.chartColumns(exportName1)  : this.chartColumns(exportName1) }
                        exportCSV = { {
                        fileName: exportName1+'_'+ currentDateTime +'.csv'
                        } }
                        >
                          
                        {
                        props => (
                            <div style={{  display: (this.props.FirstScanToDeliveryMetric.length > 0  ) ? ''  : 'none'}}>
                            {(this.props.isMobile == true  ) ? <div></div>  : 
                            <div>
                            <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={exportName1+'_'+ currentDateTime}>
                                <ExcelSheet data={this.props.FirstScanToDeliveryMetric} name="FirstScanToDelivery">
                                    <ExcelColumn label="Days" value="Days"/>
                                    <ExcelColumn label="Percent" value="Percent"/>
                                    <ExcelColumn label="Count" value="Count"/>
                                </ExcelSheet>
                            </ExcelFile>

                              {/* <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>                       */}
                              <header className="panel-heading">
                                {`Total Packages: ${grandTotalNumber} / Total Packages Delivered: ${totalNumber}`}
                              </header> 
                            </div>
                            }
                            <hr />
                                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                            </div>
                        )
                        }
                    </ToolkitProvider>
                    </div>



            
                  </section>
                }
                else {
                    chartFirstScanToDelivery =
                  <div></div>
                }
      
              }
              else {
                chartFirstScanToDelivery =
                <div></div>
              }
      



              let chartFlightDepartToDelivery;
              if (this.props.isFlightDepartToDeliveryMetricLoading) {
                chartFlightDepartToDelivery=
                    <Loader 
                      type="Plane"
                      color="#00BFFF"
                      height="50"	
                      width="50"
                    /> 
        
                }  
                else if (typeof(this.props.FlightDepartToDeliveryMetric) !== 'undefined' && this.props.FlightDepartToDeliveryMetric != null) {
        
                  if (this.props.FlightDepartToDeliveryMetric.length > 0){

                  var totalNumberArray2;
                  var totalNumber2 = 0;
                  var grandTotalArray2;
                  var grandTotalNumber2 = 0;

                  totalNumberArray2 = this.props.FlightDepartToDeliveryMetric.filter(x => x.Total != '' && x.Total != null)
                  try{
                    totalNumber2 = totalNumberArray2[0].Total
                  }
                  catch(e){}

                  grandTotalArray2 = this.props.FlightDepartToDeliveryMetric.filter(x => x.GrandTotal != '' && x.GrandTotal != null)
                  try{
                    grandTotalNumber2 = grandTotalArray2[0].GrandTotal
                  }
                  catch(e){}

    
                  var chartTitle2 = 'Flight Depart To Delivery';
                  var exportName2 = 'flightdeparttodelivery'
    
                  chartFlightDepartToDelivery =
                    <section className="panel">
                    <header className="panel-heading">
                    {`${chartTitle2}`}
                    </header>
    
                    <ReChartBar 
                        data={this.props.FlightDepartToDeliveryMetric} 
                        dataKey='Days'
                        barKeys= { barKeyArray }
                        xLegend='Days'
                        yLegend='Percent'
                    />
                    <div>
                    <ToolkitProvider
                        keyField="Tracking"
                        data={ this.props.FlightDepartToDeliveryMetric }
                        columns= {(this.props.isMobile == true  ) ? this.chartColumns(exportName2)  : this.chartColumns(exportName2) }
                        exportCSV = { {
                        fileName: exportName2+'_'+ currentDateTime +'.csv'
                        } }
                        >
                        {
                        props => (
                            <div style={{  display: (this.props.FlightDepartToDeliveryMetric.length > 0  ) ? ''  : 'none'}}>
                            {(this.props.isMobile == true  ) ? <div></div>  : 
                            
                            <div>
                              <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={exportName2+'_'+ currentDateTime}>
                                  <ExcelSheet data={this.props.FlightDepartToDeliveryMetric} name="FlightDepartToDelivery">
                                      <ExcelColumn label="Days" value="Days"/>
                                      <ExcelColumn label="Percent" value="Percent"/>
                                      <ExcelColumn label="Count" value="Count"/>
                                  </ExcelSheet>
                              </ExcelFile>
                              
                              {/* <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>  */}
                              <header className="panel-heading">
                              {`Total Packages: ${grandTotalNumber2} / Total Packages Delivered: ${totalNumber2}`}
                              </header> 
                            </div>}
                            <hr />
                                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                            </div>
                        )
                        }
                    </ToolkitProvider>
                    </div>
    
                    </section>
                  }
                  else {
                    chartFlightDepartToDelivery =
                    <div></div>
                  }
        
                }
                else {
                    chartFlightDepartToDelivery =
                  <div></div>
                }


    
                let chartFirstScanToRecovery;
                if (this.props.isFirstScanToRecoveryMetricLoading) {
                  chartFirstScanToRecovery=
                      <Loader 
                        type="Plane"
                        color="#00BFFF"
                        height="50"	
                        width="50"
                      /> 
          
                  }  
                  else if (typeof(this.props.FirstScanToRecoveryMetric) !== 'undefined' && this.props.FirstScanToRecoveryMetric != null) {
          
                    if (this.props.FirstScanToRecoveryMetric.length > 0){

                    var totalNumberArray3;
                    var totalNumber3 = 0;
                    var grandTotalArray3;
                    var grandTotalNumber3 = 0;
    

                    totalNumberArray3 = this.props.FirstScanToRecoveryMetric.filter(x => x.Total != '' && x.Total != null)
                    try{
                      totalNumber3 = totalNumberArray3[0].Total
                    }
                    catch(e){}

                    grandTotalArray3 = this.props.FirstScanToRecoveryMetric.filter(x => x.GrandTotal != '' && x.GrandTotal != null)
                    try{
                      grandTotalNumber3 = grandTotalArray3[0].GrandTotal
                    }
                    catch(e){}
                    

      
                    var chartTitle3 = 'Recovery To First Scan';
                    var exportName3 = 'recoverytofirstscan'
      
                    chartFirstScanToRecovery =
                      <section className="panel">
                      <header className="panel-heading">
                      {`${chartTitle3}`}
                      </header>
      
                      <ReChartBar 
                        data={this.props.FirstScanToRecoveryMetric} 
                        dataKey='Days'
                        barKeys= { barKeyArray }
                        xLegend='Days'
                        yLegend='Percent'
                    />
                    <div>
                    <ToolkitProvider
                        keyField="Tracking"
                        data={ this.props.FirstScanToRecoveryMetric }
                        columns= {(this.props.isMobile == true  ) ? this.chartColumns(exportName3)  : this.chartColumns(exportName3) }
                        exportCSV = { {
                        fileName: exportName3+'_'+ currentDateTime +'.csv'
                        } }
                        >
                        {
                        props => (
                            <div style={{  display: (this.props.FirstScanToRecoveryMetric.length > 0  ) ? ''  : 'none'}}>
                            {(this.props.isMobile == true  ) ? <div></div>  : <div>

                              <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={exportName3+'_'+ currentDateTime}>
                                  <ExcelSheet data={this.props.FirstScanToRecoveryMetric} name="FirstScanToRecovery">
                                      <ExcelColumn label="Days" value="Days"/>
                                      <ExcelColumn label="Percent" value="Percent"/>
                                      <ExcelColumn label="Count" value="Count"/>
                                  </ExcelSheet>
                              </ExcelFile>
                              
                              
                              
                              {/* <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>  */}
                            <header className="panel-heading">
                            {`Total Packages: ${grandTotalNumber3} / First Scan Total: ${totalNumber3}`}
                            </header> 
                          </div>
                          }
                            <hr />
                                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                            </div>
                        )
                        }
                    </ToolkitProvider>
                    </div>
      
                      </section>
                    }
                    else {
                        chartFirstScanToRecovery =
                      <div></div>
                    }
          
                  }
                  else {
                    chartFirstScanToRecovery =
                    <div></div>
                  }




                  let chartManifestToDelivery;
                  if (this.props.isManifestToDeliveryMetricLoading) {
                    chartManifestToDelivery=
                        <Loader 
                          type="Plane"
                          color="#00BFFF"
                          height="50"	
                          width="50"
                        /> 
            
                    }  
                    else if (typeof(this.props.ManifestToDeliveryMetric) !== 'undefined' && this.props.ManifestToDeliveryMetric != null) {
            
                      if (this.props.ManifestToDeliveryMetric.length > 0){
  
                      var totalNumberArray4;
                      var totalNumber4 = 0;
                      var grandTotalArray4;
                      var grandTotalNumber4 = 0;
      

                      totalNumberArray4 = this.props.ManifestToDeliveryMetric.filter(x => x.Total != '' && x.Total != null)
                      try{
                        totalNumber4 = totalNumberArray4[0].Total
                      }
                      catch(e){}

                      grandTotalArray4 = this.props.ManifestToDeliveryMetric.filter(x => x.GrandTotal != '' && x.GrandTotal != null)
                      try{
                        grandTotalNumber4 = grandTotalArray4[0].GrandTotal
                      }
                      catch(e){

                      }
        
                      var chartTitle4 = 'Inbound Manifest To Delivery';
                      var exportName4 = 'manifesttodelivery'
                      var sheetName4 = 'ManifestToDelivery'
        
                      chartManifestToDelivery =
                        <section className="panel">
                        <header className="panel-heading">
                        {`${chartTitle4}`}
                        </header>
        
                        <ReChartBar 
                          data={this.props.ManifestToDeliveryMetric} 
                          dataKey='Days'
                          barKeys= { barKeyArray }
                          xLegend='Days'
                          yLegend='Percent'
                      />
                      <div>
                      <ToolkitProvider
                          keyField="Tracking"
                          data={ this.props.ManifestToDeliveryMetric }
                          columns= {(this.props.isMobile == true  ) ? this.chartColumns(exportName4)  : this.chartColumns(exportName4) }
                          exportCSV = { {
                          fileName: exportName4+'_'+ currentDateTime +'.csv'
                          } }
                          >
                          {
                          props => (
                              <div style={{  display: (this.props.ManifestToDeliveryMetric.length > 0  ) ? ''  : 'none'}}>
                              {(this.props.isMobile == true  ) ? <div></div>  : <div>
  
                                <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={exportName4+'_'+ currentDateTime}>
                                    <ExcelSheet data={this.props.ManifestToDeliveryMetric} name={sheetName4}>
                                        <ExcelColumn label="Days" value="Days"/>
                                        <ExcelColumn label="Percent" value="Percent"/>
                                        <ExcelColumn label="Count" value="Count"/>
                                    </ExcelSheet>
                                </ExcelFile>
                                
                                
                                
                                {/* <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>  */}
                              <header className="panel-heading">
                              {`Total Packages: ${grandTotalNumber4} / Total Packages Delivered: ${totalNumber4}`}
                              </header> 
                            </div>
                            }
                              <hr />
                                  <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                              </div>
                          )
                          }
                      </ToolkitProvider>
                      </div>
        
                        </section>
                      }
                      else {
                          chartManifestToDelivery =
                        <div></div>
                      }
            
                    }
                    else {
                      chartManifestToDelivery =
                      <div></div>
                    }





        


      return(
        <div>

          <section className="panel">
            <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden' }}>
                { chartFirstScanToDelivery }
            </div>
          </section>


          <section className="panel">
            <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden' }}>
                {chartFlightDepartToDelivery}
            </div>
          </section>


          <section className="panel">
            <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden' }}>
                {chartFirstScanToRecovery}
            </div>
          </section>

          <section className="panel">
            <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden' }}>
                {chartManifestToDelivery}
            </div>
          </section>


        </div>
      );

    }
  }
  
  export default withRouter(GBMetrics);
  
