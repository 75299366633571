import React, {
    PureComponent
  }                         from 'react';
import {withRouter} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment'
import DropDownSelectFilter from '../../ComponentsGeneral/Filters/DropDownSelectFilter/DropDownSelectFilter';
import ButtonGeneral from '../../ComponentsGeneral/Buttons/ButtonGeneral';
import DateFilter   from '../../ComponentsGeneral/Filters/DateFilter/DateFilter';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button'
import Sidebar from "react-sidebar";
import SearchInput from '../../ComponentsGeneral/Filters/SearchInput/SearchInput';


const calStartValue = new Date();
calStartValue.setDate( calStartValue.getDate() - 90 );

const calMinDateValue = new Date();
calMinDateValue.setDate( calMinDateValue.getDate() - 90 );

class FilterMainGoogle extends PureComponent {


// Filers:
// Date Range
// Mode
// Carrier
// Lane




    constructor( props )
    {
      super( props );

      this.state = {
        sidebarOpen: false,

        //datepicker state
        // startDate: calStartValue,
        // endDate: new Date(),
        startDate: 'null',
        endDate: 'null',
        calendarDisabled: true,
        datePickerRadio: 'null',


        //radio filters
        radioInTransit: 'null',
        radioMode: 'null',

        //dropdown state
        dropDownCarrierState: '',

        //url param filters
        filterStartDT: calStartValue,
        filterEndDT: 'null',

        // filterStartDT: null,
        // filterEndDT: null,


        // filterRadioDateType: 'Manifest',
        filterCarrierState: '',
        filterInTransit: 'null',
        filterMode: 'null',
        filterDatePickerRadio: 'null',

        //search input box
        searchinput: ''

      };

      //Side Bar Filter menu
      this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

      //Date picker
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);

      //Date picker type
    //   this.setEventDate = this.setEventDate.bind(this);

      //Radio button filters
      this.setInTransit = this.setInTransit.bind(this);
      this.setMode = this.setMode.bind(this);
      this.setDatePickerRadio = this.setDatePickerRadio.bind(this);

      //Drop down select
    //   this.handleDropdownPortExit = this.handleDropdownPortExit.bind(this);
    //   this.handleDropdownPortEntry = this.handleDropdownPortEntry.bind(this);
      this.handleDropdownCarrier = this.handleDropdownCarrier.bind(this);

      //Update url filters
      this.handleFilterStartDT = this.handleFilterStartDT.bind(this);
      this.handleFilterEndDT = this.handleFilterEndDT.bind(this);
    //   this.handleFilterRadioDateType = this.handleFilterRadioDateType.bind(this);
    //   this.handleFilterPortExit = this.handleFilterPortExit.bind(this);
    //   this.handleFilterPortEntry = this.handleFilterPortEntry.bind(this);
      this.handleFilterCarrier = this.handleFilterCarrier.bind(this);
      this.handleFilterRadioInTransit = this.handleFilterRadioInTransit.bind(this);
      this.handleFilterRadioMode = this.handleFilterRadioMode.bind(this);
      this.handleFilterRadioDatePicker = this.handleFilterRadioDatePicker.bind(this);
    }

    //Side Bar filter menu
    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
      }

    //Date picker
    handleChangeStartDate(date) {
        this.setState({
          startDate: date
        });
    }
    handleChangeEndDate(date) {
        this.setState({
          endDate: date
        });
    }
    setDatePickerRadio(event) {

        if(event.target.value == 1 ){
            this.setState({
                calendarDisabled: false,
                datePickerRadio: event.target.value,
                startDate: calStartValue,
                endDate: null,
            });
        }
        else {
            this.setState({
                calendarDisabled: true,
                datePickerRadio: event.target.value,
                // startDate: 'null',
                // endDate: 'null'
            });
        }
        this.setState({
            datePickerRadio: event.target.value
        });
    }
    // setEventDate(event) {
    //     this.setState({ 
    //       radioDateType: event.target.value
    //     });
    // }  

    //Radio Filters
    setInTransit(event) {
        this.setState({ 
          radioInTransit: event.target.value
        });
    }  
    setMode(event) {
        this.setState({ 
          radioMode: event.target.value
        });
    }  

    //Drop down select
    // handleDropdownPortExit(value) {
    //   this.setState({ 
    //     dropDownPortExitState: value
    //   });
    // }
    // handleDropdownPortEntry(value) {
    //   this.setState({ 
    //     dropDownPortEntryState: value
    //   });
    // }
    handleDropdownCarrier(value) {
      this.setState({ 
        dropDownCarrierState: value
      });
    }

    //Update url filters
    handleFilterStartDT(value) {
        this.setState({ 
            filterStartDT: value
        });
    }
    handleFilterEndDT(value) {
        this.setState({ 
            filterEndDT: value
        });
    }
    handleFilterRadioDatePicker(value) {
        this.setState({ 
            filterDatePickerRadio: value
        });
    }

    // handleFilterRadioDateType(value) {
    //     this.setState({ 
    //         filterRadioDateType: value
    //     });
    // }
    // handleFilterPortExit(value) {
    //     this.setState({ 
    //         filterPortExitState: value
    //     });
    // }
    // handleFilterPortEntry(value) {
    //     this.setState({ 
    //         filterPortEntryState: value
    //     });
    // }
    handleFilterCarrier(value) {
        this.setState({ 
            filterCarrierState: value
        });
    }
    handleFilterRadioInTransit(value) {
        this.setState({ 
            filterInTransit: value
        });
    }
    handleFilterRadioMode(value) {
        this.setState({ 
            filterMode: value
        });
    }


    componentDidMount() {
        this.updateParamsFromURL()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.updateParamsFromURL()
        }
    }      

    updateParamsFromURL = () => {

        const StartDT = new Date();
        // var paramStartDT = StartDT.setDate( StartDT.getDate() - 30 );

        const EndDT = new Date();
        // var paramEndDT = EndDT.setDate( EndDT.getDate() );

        var paramStartDT = 'null'
        var paramEndDT = 'null'

        const values = queryString.parse(this.props.location.search)

        // (typeof(this.state.radioInTransit) !== 'undefined' && this.state.radioInTransit != null)
    
        if( typeof(values.startdt) !== 'undefined' && values.startdt != "null"   ){
            paramStartDT = StartDT.setDate( StartDT.getDate() - 30 );
            paramStartDT = new Date(values.startdt)
            // paramStartDT.setDate( new Date(values.startdt) )
            this.handleFilterRadioDatePicker(false)
            this.handleChangeStartDate(paramStartDT)
            this.handleFilterStartDT(paramStartDT)

        }
        else{
            // this.handleChangeStartDate(paramStartDT)
            this.handleFilterRadioDatePicker(true)
            this.handleFilterStartDT(paramStartDT)
        }
        // if( values.enddt != 'null' ){
        if( typeof(values.enddt) !== 'undefined' && values.enddt != 'null' ){
            paramEndDT = EndDT.setDate( EndDT.getDate() );
            paramEndDT = new Date(values.enddt)
            // paramEndDT.setDate( new Date(values.enddt) );
            this.handleFilterRadioDatePicker(false)
            this.handleChangeEndDate(paramEndDT)
            this.handleFilterEndDT(paramEndDT)
        }
        else{
            this.handleFilterRadioDatePicker(true)
            // this.handleChangeEndDate(paramEndDT)
            this.handleFilterEndDT(paramEndDT)
        }
        // if( values.datetype != 'null' ){
        //     this.handleFilterRadioDateType(values.datetype)
        // }
        if( values.intransit != 'undefined' ){
            this.handleFilterRadioInTransit(values.intransit)
        }
        if( values.mode != 'undefined' ){
            this.handleFilterRadioMode(values.mode)
        }

        // this.handleFilterPortExit(values.portexit)
        // this.handleFilterPortEntry(values.portentry)
        this.handleFilterCarrier(values.carrier)

    }

  clickApplyFilter( e ) {
    // var dateTypeFilter = null;
    var inTransitFilter = null;
    var modeFilter = null;
    // var portExitFilter = null;
    // var portEntryFilter = null;
    var carrierFilter = null;


    // if ( typeof(this.state.startDate) !== 'undefined' && this.state.startDate !== null) {
    //     this.handleFilterStartDT(this.state.startDate)
    // }
    // if ( typeof(this.state.endDate) !== 'undefined' && this.state.endDate !== null) {
    //     this.handleFilterEndDT(this.state.endDate)
    // }

    this.handleFilterStartDT(this.state.startDate)
    this.handleFilterEndDT(this.state.endDate)


    // datePickerRadio

    var sDT = null
    var eDT = null

    if (this.state.startDate === null ) {
        sDT = null
    }
    else if(this.state.datePickerRadio != 'null'  ){
        sDT = "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'"
    }
    

    if ( this.state.endDate === null ){
        eDT = null
    }
    else if(this.state.datePickerRadio != 'null'  ){
        eDT = "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'"
    }


    // if(this.state.datePickerRadio != 'null'  ){
    //     sDT = "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'"
    //     eDT = "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'"
    // }
    


    // var sDT = null
    // if(this.state.startDate != 'null'){
    //     sDT = "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'"
    // }

    // var eDT = null
    // if(this.state.endDate != 'null'){
    //     eDT = "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'"
    // }

    // if (typeof(this.state.radioDateType) !== 'undefined' && this.state.radioDateType != null)
    // {
    //     dateTypeFilter = this.state.radioDateType
    //     this.handleFilterRadioDateType(dateTypeFilter)
    // }
    if (typeof(this.state.radioInTransit) !== 'undefined' && this.state.radioInTransit != null)
    {
        inTransitFilter = this.state.radioInTransit
        this.handleFilterRadioInTransit(inTransitFilter)
    }
    if (typeof(this.state.radioMode) !== 'undefined' && this.state.radioMode != null)
    {
        modeFilter = this.state.radioMode
        this.handleFilterRadioMode(modeFilter)
    }
    // if (typeof(this.state.dropDownPortExitState[0]) !== 'undefined' && this.state.dropDownPortExitState[0] != null)
    // {
    //     portExitFilter = this.state.dropDownPortExitState[0].value
    //     this.handleFilterPortExit(portExitFilter)
    // }
    // if (typeof(this.state.dropDownPortEntryState[0]) !== 'undefined' && this.state.dropDownPortEntryState[0] != null)
    // {
    //     portEntryFilter = this.state.dropDownPortEntryState[0].value
    //     this.handleFilterPortEntry(portEntryFilter)
    // }

    
    if (typeof(this.state.dropDownCarrierState[0]) !== 'undefined' && this.state.dropDownCarrierState[0] != null)
    {
        carrierFilter = this.state.dropDownCarrierState[0].value
        this.handleFilterCarrier(carrierFilter)
    }

    this.props.history.push({
      search: 'startdt=' +  sDT  + '&' +
      'enddt=' +  eDT  + '&' +
    //   'datetype=' + dateTypeFilter + '&' +
      'intransit=' + inTransitFilter + '&' +
      'mode=' + modeFilter + '&' +
    //   'portentry=' + portEntryFilter + '&' +
      'carrier=' + carrierFilter 
      }); 

    this.onSetSidebarOpen(false)
    this.updateParamsFromURL()
  }


  //this handles search text input box update state
  onSearchChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });

  }

  //prevent search form input from submitting on enter key
  onSearchKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  //this handles search text input box submit
  onSearchSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { searchinput } = this.state;

    if(searchinput.trim() === "") {
      window.alert("Search Field is empty.");
      return
    }

    else {




      if(this.props.location.pathname === '/home/google') {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        pathname: 'google/shipmenttrackerparams/',
        search: 'search="'  + searchinput.trim() + '"'
        }); 
      }
      else if(this.props.location.pathname === '/home/google/') {
        this.props.history.push({
          pathname: 'google/shipmenttrackerparams/',
          search: 'search="'  + searchinput.trim() + '"'
          }); 
      }
      else if(this.props.location.pathname === '/home/google/shipmenttrackerparams/') {
        this.props.history.push({
          // pathname: 'shipmentdetails/',
          search: 'search="' + searchinput.trim() + '"'
          }); 

        window.location.reload();
      }
      else if(this.props.location.pathname === '/home/google/shipmentdetails/') {
        this.props.history.push({
          pathname: '../shipmenttrackerparams/',
          search: 'search="' + searchinput.trim() + '"'
          }); 

      }
      else if(this.props.location.pathname === '/home/usermanagement' || this.props.location.pathname === '/home/myprofile') {
        this.props.history.push({
          pathname: 'google/shipmenttrackerparams/',
          search: 'search="'  + searchinput.trim() + '"'
          }); 

      }
      else {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        pathname: 'shipmenttrackerparams/',
        search: 'search="' + searchinput.trim() + '"'
        }); 
      }

    }
  }


    render() {
        const {
            searchinput
          } = this.state;

        const selectCarrier = [
            { label: "Omni", value: "Omni" }
        ];


        let datePickerRadio;
        datePickerRadio=
        <div className="sm-st clearfix" >
            <div className="row">  
                <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    <div onChange={this.setDatePickerRadio.bind(this)}>
                        <input type="radio" value="null" name="datepicker" defaultChecked /> All &nbsp;
                        <input type="radio" value="1" name="datepicker"/> Delivery Date &nbsp;
                    </div> 
                </div>
            </div>
        </div>


        // let datePickerStart;
        // datePickerStart=
        // <DateFilter
        //     // labelText = { 'Start:' }
        //     placeholderText = { 'Start Date (RDD)' }
        //     selectedDate = { this.state.startDate }
        //     selectsStartOrEnd = { 'selectsStart' }
        //     startDate = { this.state.startDate }
        //     endDate = { this.state.endDate }
        //     onChange = { this.handleChangeStartDate }
        //     minDate = { calMinDateValue }
        //     disabled = {this.state.calendarDisabled}
        //     isMobile = { this.props.isMobile }
        // />

        // let datePickerEnd; 
        // datePickerEnd=
        // <DateFilter
        //     // labelText = { 'End:' }
        //     placeholderText = { 'End Date (RDD)' }
        //     selectedDate = { this.state.endDate }
        //     selectsStartOrEnd = { 'selectsEnd' }
        //     startDate = { this.state.startDate }
        //     endDate = { this.state.endDate }
        //     onChange = { this.handleChangeEndDate }
        //     minDate = { calMinDateValue }
        //     disabled = {this.state.calendarDisabled}
        //     isMobile = { this.props.isMobile }
        // />


        let datePickerDiv;
        datePickerDiv=

        <div>
        {(() => {
            switch (this.state.calendarDisabled) {
              case false:   return     (
                <div className="sm-st clearfix" >

                <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                    {datePickerRadio}
                </div>

                <div className="row">  
                <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>  
                    <header >
                        {'Delivery Date (Start)'}
                    </header>
                    <DateFilter
            // labelText = { 'Start:' }
            placeholderText = { 'Delivery Date (Start)' }
            selectedDate = { this.state.startDate }
            selectsStartOrEnd = { 'selectsStart' }
            startDate = { this.state.startDate }
            endDate = { this.state.endDate }
            onChange = { this.handleChangeStartDate }
            minDate = { calMinDateValue }
            disabled = {this.state.calendarDisabled}
            isMobile = { this.props.isMobile }
            isClearable = { true }
        />
                </div>
                <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>  
                    <header >
                        {'Delivery Date (End)'}
                    </header>
                    <DateFilter
            // labelText = { 'End:' }
            placeholderText = { 'Delivery Date (End)' }
            selectedDate = { this.state.endDate }
            selectsStartOrEnd = { 'selectsEnd' }
            startDate = { this.state.startDate }
            endDate = { this.state.endDate }
            onChange = { this.handleChangeEndDate }
            minDate = { calMinDateValue }
            disabled = {this.state.calendarDisabled}
            isMobile = { this.props.isMobile }
            isClearable = { true }
        />

                </div>
                </div>
            </div>
              );

              case true: return (
                <div className="sm-st clearfix" >
                <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                    {datePickerRadio}
                </div>
            </div>
              );
              default: return <div></div>;
            }
          })()}
        </div>



        let radioInTransitDiv;
        radioInTransitDiv=
            <div className="sm-st clearfix" >
                <div className="row">  
                    <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                        <div onChange={this.setInTransit.bind(this)}>
                            <input type="radio" value="null" name="intransit" defaultChecked /> All &nbsp;
                            <input type="radio" value="O" name="intransit"/> In Transit &nbsp;
                            <input type="radio" value="C" name="intransit"/> Delivered &nbsp;
                        </div> 
                    </div>
                </div>
            </div>

        let radioModeDiv;
        radioModeDiv=
            <div className="sm-st clearfix" >
                <div className="row">  
                    <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                        <div onChange={this.setMode.bind(this)}>
                            <input type="radio" value="null" name="mode" defaultChecked /> All &nbsp;
                            <input type="radio" value="AIR" name="mode"/> Air &nbsp;
                            <input type="radio" value="OCEAN" name="mode"/> Ocean &nbsp;
                            <input type="radio" value="TRUCKLOAD" name="mode"/> Truckload &nbsp;
                        </div> 
                    </div>
                </div>
            </div>


        let dropDownCarrier;
        dropDownCarrier=
        <DropDownSelectFilter 
            dropDownArray = { selectCarrier } 
            dropDownOnChange = {this.handleDropdownCarrier}
            dropDownPlaceholder = { 'Carrier' }
            isMobile = { this.props.isMobile }
        />



        let buttonApplyFilter;
        buttonApplyFilter=
    //     <Button variant="secondary" size="md" block   onClick={() => this.onSetSidebarOpen(true)} >
    //     Click to Filter
    // </Button>
        <ButtonGeneral 
            buttonVariant = { "success" }
            buttonSize = { "sm" }
            buttonOnClick={(e) => this.clickApplyFilter(e)}
            buttonText = { 'Apply' }
            isMobile = { this.props.isMobile }
        />

        let searchInputDiv;
        searchInputDiv=
        <div className="sm-st clearfix" style={{height: '100px'}}>

                <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    <SearchInput
                        searchInputValue = { searchinput }
                        searchTitle = { 'Search House Bill, PO, ASN' }
                        searchOnChange = { this.onSearchChange }
                        searchOnSubmit = { this.onSearchSubmit }
                        searchOnKeyPress = { this.onSearchKeyPress }
                        isMobile = { this.props.isMobile }
                    />
                </div>

        </div>





        let currentFilter;
        if(this.props.isMobile){
            currentFilter = 
            <div className="row" style={{marginBottom: '0px', fontSize: '0.4rem'}}>
                <div className="col-md-12">
                    <section className="panel">
                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden'}}>              
                            <div className="col-md-12">
                                {/* <td>Start Date (RDD): <strong>{  moment(this.state.filterStartDT).format('MM-DD-YYYY')}</strong></td>       */}
                                <td>Delivery Date (Start): <strong>{ (this.state.filterStartDT == null || this.state.filterStartDT === 'null' ) ? 'All' : moment(this.state.filterStartDT).format('MM-DD-YYYY') }</strong></td>        
                            </div>     
                            <div className="col-md-12">              
                                {/* <td>End Date (RDD): <strong>{  moment(this.state.filterEndDT).format('MM-DD-YYYY')}</strong></td>    */}
                                <td>Delivery Date (End): <strong>{ (this.state.filterEndDT == null || this.state.filterEndDT === 'null' ) ? 'All' : moment(this.state.filterEndDT).format('MM-DD-YYYY') }</strong></td>        
                            </div>     
    
                            <div className="col-md-12">      
                                <td>Mode: <strong>{ (this.state.filterMode == null || this.state.filterMode === 'null' ) ? 'All' : this.state.filterMode  }</strong></td>       
                            </div>   
                            <div className="col-md-12">      
                                <td>In Transit: <strong>{ (this.state.filterInTransit == null || this.state.filterInTransit === 'null' ) ? 'All' : (this.state.filterInTransit === 'O') ? 'In Transit' : 'Delivered' }</strong></td>       
                            </div>    
                            <div className="col-md-12">                                       
                                <td>Carrier: <strong>{ (this.state.filterCarrierState == null || this.state.filterCarrierState === 'null' ) ? 'All' : this.state.filterCarrierState }</strong></td>
                            </div> 
                    </div>
                    </section>
                </div>
            </div>
        }
        else{
            currentFilter = 
            
            <div className="row" style={{marginBottom: '0px', fontSize: '0.9rem'}}>
                <div className="col-md-12">
                    <section className="panel">
                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden'}}>
                        <div className="row" >
                            <div className="col-md-3">
                                {/* <td>Start Date (RDD): <strong>{  moment(this.state.filterStartDT).format('MM-DD-YYYY')}</strong></td>    */}
                                <td>Delivery Date (Start): <strong>{ (this.state.filterStartDT == null || this.state.filterStartDT === 'null' ) ? 'All' : moment(this.state.filterStartDT).format('MM-DD-YYYY') }</strong></td>            
                            </div>     
                            <div className="col-md-3">              
                                {/* <td>End Date (RDD): <strong>{  moment(this.state.filterEndDT).format('MM-DD-YYYY')}</strong></td>    */}
                                <td>Delivery Date (End): <strong>{ (this.state.filterEndDT == null || this.state.filterEndDT === 'null' ) ? 'All' : moment(this.state.filterEndDT).format('MM-DD-YYYY') }</strong></td>        
                            </div>     

                            <div className="col-md-2">      
                            <td>Mode: <strong>{ (this.state.filterMode == null || this.state.filterMode === 'null' ) ? 'All' : this.state.filterMode  }</strong></td> 
                            </div>      
                            <div className="col-md-2">      
                                <td>In Transit: <strong>{ (this.state.filterInTransit == null || this.state.filterInTransit === 'null' ) ? 'All' : (this.state.filterInTransit === 'O') ? 'In Transit' : 'Delivered' }</strong></td>       
                            </div>   
                            <div className="col-md-2">                                       
                                <td>Carrier: <strong>{ (this.state.filterCarrierState == null || this.state.filterCarrierState === 'null' ) ? 'All' : this.state.filterCarrierState }</strong></td>
                            </div>         
                        </div>
                    </div>
                    </section>
                </div>
            </div>

        }




        let filterDiv;
        if(this.props.isMobile){
            filterDiv = 
            <div style={{padding: '0px'}}>  
            <div className="col-md-12"  style={{padding: '1px'}}>
                {datePickerDiv}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {dropDownCarrier}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {radioModeDiv}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {radioInTransitDiv}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {buttonApplyFilter}
            </div>

            <div className="col-md-12"  style={{ paddingTop: '5px'}}>
                {searchInputDiv}
            </div>

        </div>
        }
        else{
            filterDiv = 
                <div style={{padding: '15px'}}>  
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {datePickerDiv}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {dropDownCarrier}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {radioModeDiv}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {radioInTransitDiv}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {buttonApplyFilter}
                    </div>

                    <div className="col-md-12"  style={{ padding: '15px', paddingTop: '40px'}}>
                        {searchInputDiv}
                    </div>
                    
                </div>

        }


        return(

            <div style={{paddingTop: "22px", paddingRight: "15px", paddingLeft: "15px" }}>
            <section className="panel">
                <div //className="panel-body table-responsive"
                    style={{backgroundColor: '#dcdcdc'}}
                >

                        <Sidebar
                            sidebar={ filterDiv }
                            open={this.state.sidebarOpen}
                            onSetOpen={this.onSetSidebarOpen}
                            styles={{ sidebar: { background: '#dcdcdc', padding: '45px' } }}
                            >


                            <section className="panel" style={{ paddingRight: "17px", paddingLeft: "13px" }}>
                                <div style={{marginLeft: "auto",  marginRight: "auto", zIndex: "1", position: "relative"}}>
                                <Button variant="secondary" size="md" block   onClick={() => this.onSetSidebarOpen(true)} >
                                    Click to Filter/Search
                                </Button>
                                </div>
                            </section>

                        </Sidebar>

                </div>
            </section>
                {currentFilter}
            </div>


        );
    }
}

  export default withRouter(FilterMainGoogle);
  
