import {connect} from 'react-redux';
import React, {Component} from 'react';
import ShipmentTracker from '../ShipmentTracker/ShipmentTracker'
import * as actions from '../../../redux/actions/googleActions';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';


//add props from reducer here
const mapStateToProps = store =>
({
    filteredShipTrackerData:            store.shipmentTrackerReducer.filteredShipTrackerData,
    isShipmentTrackerLoading:           store.shipmentTrackerReducer.isShipmentTrackerLoading,
    shipTrackModalShow:                 store.shipmentTrackerReducer.shipTrackModalShow,
    shipTrackModalData:                 store.shipmentTrackerReducer.shipTrackModalData,
    shipTrackModalRow:                  store.shipmentTrackerReducer.shipTrackModalRow,

    statCardClickedParam:               store.shipmentTrackerReducer.statCardClickedParam,

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getStatCardInTransit:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getStatCardInTransit( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },

    getStatCardParam:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getStatCardParam( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },

    openShipModal:( _db, _email, _jobid, _row ) =>
    {
        dispatch( actions.showShipmentTrackerModalGetComments( _db, _email, _jobid, _row ) )
    },

    hideShipmentTrackerModal:() =>
    {
        dispatch( actions.hideShipmentTrackerModal( null ) )
    },

    postShipModalComment:( _db, _email, _jobid, _comment, _type ) =>
    {
        dispatch( actions.postShipModalComment( _db, _email, _jobid, _comment, _type ) )
    },

    modalToggleHotShip:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleHotShip( _db, _email, _jobid, _bool ) )
    },

    modalToggleInternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleInternalAction( _db, _email, _jobid, _bool ) )
    },

    modalToggleExternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleExternalAction( _db, _email, _jobid, _bool ) )
    },


});


class StatsCardShipTrackContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	



    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    // var paramInTransit = 'O'
    var paramInTransit = null
    var paramMode = null
    var paramCarrier = null
    var paramFilter = null
    var paramFilterValue = null
    var paramStatCardInput = null
  
    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
        paramInTransit = values.intransit
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
        paramCarrier = values.carrier
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }
    if(typeof(values.statCardInput) !== 'undefined' && values.statCardInput != null ){
        paramStatCardInput = values.statCardInput
    }

    this.props.getStatCardParam( this.props.userSignInData.DBName, this.props.currentUserEmail, null, paramStatCardInput, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );

    // getStatCardParam =     ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT )
    // getStatCardInTransit = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT )
    // if( this.props.statCardClickedParam === false ) { 
    //     //check if dataset empty
    //     if (this.props.filteredShipTrackerData.length === 0 || typeof(this.props.filteredShipTrackerData) === 'undefined' || this.props.filteredShipTrackerData === null) {
            
    //         // this.props.getStatCardInTransit( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null, null, null, null, null, null, 'O', null, null, null );
    //         this.props.getStatCardInTransit( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );
    //         //this calls API_GetShipmentTracker
    //     }
    // }

    }







	
    render()
    {
    	return (
            <ShipmentTracker 
            shipmentTrackerData={ this.props.filteredShipTrackerData }
            isShipmentTrackerLoading={ this.props.isShipmentTrackerLoading }
            shipTrackModalShow={this.props.shipTrackModalShow}
            openShipModal={this.props.openShipModal}
            hideShipmentTrackerModal={this.props.hideShipmentTrackerModal}
            shipTrackModalData={this.props.shipTrackModalData}
            shipTrackModalRow={this.props.shipTrackModalRow}
            postShipModalComment={this.props.postShipModalComment}
            modalToggleHotShip={this.props.modalToggleHotShip}
            modalToggleInternalAction={this.props.modalToggleInternalAction}
            modalToggleExternalAction={this.props.modalToggleExternalAction}
  
            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StatsCardShipTrackContainer));



