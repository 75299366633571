import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import ReChartLine from '../../../ComponentsGeneral/ReCharts/ReChartLine'
import {appSettings} from '../../../../app/appSettings'
import * as moment from 'moment'

  const lineKeyArray = [{'lineKey': 'Avg Manifest to Depart', 'stroke': '#8884d8'},
                        {'lineKey': 'Avg Manifest to Recovery', 'stroke': '#82ca9d'},
                        {'lineKey': 'Avg Parcel Hand-off to First Scan', 'stroke': '#f43e1f'},
                        {'lineKey': 'Avg Recovery to Parcel Hand-off', 'stroke': '#F8C471'}

                    ]


  class GBChartReporting extends PureComponent {
    constructor( props )
    {
      super( props );
    }



    onClick = (line, lineKey) => {

      var dataKey = "'" + line.dataKey + "'"

      var dateValue = moment(line.payload.Date).format('YYYY-MM-DD')

      var endDate = moment(dateValue, "YYYY-MM-DD").add(1, 'days');

      var endDateValue =  moment(endDate).format('YYYY-MM-DD') 

      this.props.gbGetMawbDetails( appSettings.gbDB, this.props.currentUserEmail, "'" + dateValue + "'", "'" + endDateValue + "'", null, null, null, null, null, null, null, null, null, dataKey, null, null );


    }



    render() {

        let otdToAvg;
        if (this.props.isChartTransMetricsDataLoading) {
          otdToAvg=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Transit Metrics'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        }  
        else if (typeof(this.props.ChartTransMetricsData) !== 'undefined' && this.props.ChartTransMetricsData != null) {
            otdToAvg =

                <section className="panel">
                <header className="panel-heading">
                {'Transit Metrics'}
                </header>
                    <ReChartLine 
                        data={this.props.ChartTransMetricsData} 
                        dataKey='Date'
                        lineKeys= { lineKeyArray }
                        onClick={{ onClick: (line) => this.onClick(line) }}
                        xLegend='Inbound Manifest Date'
                        yLegend='Days'

                    />

                </section>
        }


      return(
        <div>
            {otdToAvg}
        </div>
      );

    }
  }
  

  export default withRouter(GBChartReporting);
  
