import React      from 'react';
import PropTypes  from 'prop-types';
import cx         from 'classnames';

const HomeMainContent = ({
  children,
  isAnimated,
  isExpanded,
  isMobile,
  languageSelection
}) => (

  <aside className={
    cx({
      'right-side':             true,
      'right-side--top-margin': true,
      'aside-right-animated':   isAnimated,
      'strech':                 isExpanded,
      'ismobile':               isMobile,
      'languageSelection':      languageSelection
    })}>

    { children }
  </aside>
);

HomeMainContent.propTypes = {
  children:   PropTypes.node.isRequired,
  isAnimated: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  isMobile: PropTypes,
  languageSelection: PropTypes
};

HomeMainContent.defaultProps = {
  isAnimated: true,
  isExpanded: false,
  isMobile: false,
  languageSelection: 'English'
};

export default HomeMainContent;

