import * as types from '../constants/zuActionTypes';
import { appSettings } from '../../app/appSettings'


/* ################################### CHARTS ACTIONS ################################### */
export const zuGetOTDtoMC = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ) =>
({
    type: types.OTD_TO_MC_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ
    }
});

export const zuGetOTDtoFlightDepart = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry) =>
({
    type: types.OTD_TO_FLIGHT_DEPART_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        shipToCountry: _shipToCountry
    }
});


export const zuGetDailyShipmentsToPorts = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry) =>
({
    type: types.DAILY_SHIP_PORTS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        shipToCountry: _shipToCountry
    }
});


export const zuGetOTDtoFlightArrival = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry) =>
({
    type: types.OTD_TO_FLIGHT_ARRIVAL_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        shipToCountry: _shipToCountry
    }
});

export const zuGetAgingChart = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _shipToCountry) =>
({
    type: types.AGING_CHART_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        filter: _filter,
        shipToCountry: _shipToCountry
    }
});

export const zuGetDimWeightChart = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _shipToCountry) =>
({
    type: types.DIM_WEIGHT_CHART_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        filter: _filter,
        shipToCountry: _shipToCountry
    }
});


export const zuGetStatCardValues = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry) =>
({
    type: types.STAT_CARD_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        shipToCountry: _shipToCountry
    }
});

export const zuGetOTDtoThreeAvgs = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry) =>
({
    type: types.OTD_TO_THREE_AVGS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        shipToCountry: _shipToCountry
    }
});

export const zuGetMawbDetails = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry) =>
({
    type: types.MAWB_DETAILS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue,
        shipToCountry: _shipToCountry
    }
});

export const zuGetEvolveBrokerageReport = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry) =>
({
    type: types.EVOLVE_BROKERAGE_REPORT_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue,
        shipToCountry: _shipToCountry
    }
});

export const zuGetEvolveBrokerageStatCards = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue) =>
({
    type: types.EVOLVE_BROKERAGE_STAT_CARDS_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue
    }
});

export const zuGetMCDetails = (_db, _email, _mawb, _mc, _hawb) =>
({
    type: types.MC_DETAILS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb
    }
});


export const zuGetHawbDetails = (_db, _email, _mawb, _mc, _hawb, _trackingno) =>
({
    type: types.HAWB_DETAILS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        trackingno: _trackingno
    }
});

export const zuGetHawbDetailsMC = (_db, _email, _mawb, _mc, _hawb, _trackingno) =>
({
    type: types.HAWB_MC_DETAILS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        trackingno: _trackingno
    }
});




export const zuGetJobEvents = (_db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter) =>
({
    type: types.JOB_EVENTS_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter

    }
});

export const zuGetIBCInboundDtl = (_db, _email, _mawb, _mc, _hawb, _afhawb) =>
({
    type: types.IBC_DTL_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        afhawb: _afhawb
    }
});

export const zuGetHazMat = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry) =>
({
    type: types.HAZ_MAT_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue,
        shipToCountry: _shipToCountry
    }
});

export const zuGetChartPendingSailing = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter) =>
({
    type: types.PENDING_SAILING_CHART_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        filter: _filter
    }
});


export const zuGetChartDemurrage = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter) =>
({
    type: types.DEMURRAGE_CHART_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        filter: _filter
    }
});


export const zuGetUpsMI = (_db, _email, _trackingno) =>
({
    type: types.UPS_MI_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        trackingno: _trackingno
    }
});


export const zuGetChartAvgCustomClear = (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry) =>
({
    type: types.AVG_CUST_CLEAR_DATA_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        shipToCountry: _shipToCountry
    }
});


export const zuGetDSUPSNeuralNetScore = (_db, _email, _trackingno) =>
({
    type: types.DS_NEURAL_NET_SCORE_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,
        trackingno: _trackingno
    }
});





export const zuMbolEmailFilenameGet = (_db, _email, _mawb, _filetype) =>
({
    type: types.ZU_MBOL_EMAIL_FILENAME_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        mawb: _mawb,
        filetype: _filetype
    }
});


export const zuMbolEmailUrlGet = (_db, _email, _filename) =>
({
    type: types.ZU_MBOL_EMAIL_URL_GET,
    payload: {
        db: appSettings.zuDB,
        email: _email,

        filename: _filename
    }
});  