import React, { PureComponent } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';
import PropTypes  from 'prop-types';
import {
    ResponsiveContainer,
  } from 'recharts';



//   stackId={`"` + {stackID} + `"`}
// stackId={  (stackID == null  ) ? null  : `${stackID}` }

const ReChartBar = ({
    data,  
    dataKey,    
    barKeys,
    xLegend,
    yLegend,
    onBarClick
    }) => (

        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
            <BarChart
                // width={500}
                // height={300}
                data={data}
                margin={{
                top: 5, right: 35, left: 20, bottom: 25,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKey} label={{ value: xLegend, dy: 20}}/>
                <YAxis label={{ value: yLegend, angle: -90,   dx: -40}} />
                <Tooltip />
                {/* <Legend /> */}
                {
                    barKeys.map(
                        ({barKey, fill, stackID}, idx) => {
                            return (
                                <Bar dataKey={barKey}   fill={fill} onClick={onBarClick}     stackId={`"` + {stackID} + `"`}/>

                            );
                        }
                    )
                }

            </BarChart>

        </ResponsiveContainer>
        </div>
        </div>        

);

ReChartBar.propTypes = {
    data:                   PropTypes.array,
    dataKey:                PropTypes.string,
    barKeys:                PropTypes.array, //line key string, stroke color string
    xLegend:                PropTypes.string,
    yLegend:                PropTypes.string,
    onBarClick:             PropTypes.func
};


export default ReChartBar;



// <Line connectNulls type="monotone" dataKey={lineKey1} stroke="#8884d8" activeDot={{ r: 8 }} />
// <Line connectNulls type="monotone" dataKey={lineKey2} stroke="#82ca9d" />