import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import StatsCard from '../../ComponentsGeneral/StatsCard/StatsCard'

  class GBEvolveStatsCardRow extends PureComponent {
    constructor( props )
    {
      super( props );
    }





    onClickInTransit = () => {
      const values = queryString.parse(this.props.location.search)

      // var paramInTransit = 'intransit'

      this.props.history.push({
        pathname: this.props.routeCustomer + '/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'customercode=' + values.customercode + '&' +
        'shipmenttype=' + values.shipmenttype + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + 'null'

        }); 
        
    }

    // onClickAlertFlightDepart = () => {
    //   const values = queryString.parse(this.props.location.search)

    //   var paramAlertFlightDepart = 'alertflightdepart'

    //   this.props.history.push({
    //     pathname: this.props.routeCustomer + '/shiplist',
    //     search: 'startdt=' + values.startdt + '&' +
    //     'enddt=' + values.enddt + '&' +
    //     'datetype=' + values.datetype + '&' +
    //     'notdeparted=' + values.notdeparted + '&' +
    //     'portexit=' + values.portexit + '&' +
    //     'portentry=' + values.portentry + '&' +
    //     'finalcarrier=' + values.finalcarrier  + '&' +
    //     'filter=' + paramAlertFlightDepart

    //     }); 
        
    // }

    // onClickAlertFlightArrival  = () => {
    //   const values = queryString.parse(this.props.location.search)

    //   var paramAlertFlightArrival = 'alertflightarrival'

    //   this.props.history.push({
    //     pathname: this.props.routeCustomer + '/shiplist',
    //     search: 'startdt=' + values.startdt + '&' +
    //     'enddt=' + values.enddt + '&' +
    //     'datetype=' + values.datetype + '&' +
    //     'notdeparted=' + values.notdeparted + '&' +
    //     'portexit=' + values.portexit + '&' +
    //     'portentry=' + values.portentry + '&' +
    //     'finalcarrier=' + values.finalcarrier  + '&' +
    //     'filter=' + paramAlertFlightArrival

    //     }); 
        
    // }


    onClickCarrierDeliveryAlert  = () => {
      const values = queryString.parse(this.props.location.search)

      var paramCarrierDeliveryAlert = 'carrierdeliveryalert'

      this.props.history.push({
        pathname: this.props.routeCustomer + '/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'customercode=' + values.customercode + '&' +
        'shipmenttype=' + values.shipmenttype + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramCarrierDeliveryAlert

        }); 
        
    }


    onClickCarrierInductionAlert  = () => {
      const values = queryString.parse(this.props.location.search)

      var paramCarrierAlert = 'carrierinductionalert'

      this.props.history.push({
        pathname: this.props.routeCustomer + '/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'customercode=' + values.customercode + '&' +
        'shipmenttype=' + values.shipmenttype + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramCarrierAlert

        }); 
        
    }


    
    onClickRecoveryEventAlert  = () => {
      const values = queryString.parse(this.props.location.search)

      var paramRecoveryAlert = 'recoveryeventalert'

      this.props.history.push({
        pathname: this.props.routeCustomer + '/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'customercode=' + values.customercode + '&' +
        'shipmenttype=' + values.shipmenttype + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramRecoveryAlert

        }); 
        
    }

    onClickHeldPackages  = () => {
      const values = queryString.parse(this.props.location.search)

      var paramHeldPackages = 'heldpackages'

      this.props.history.push({
        pathname: this.props.routeCustomer + '/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'customercode=' + values.customercode + '&' +
        'shipmenttype=' + values.shipmenttype + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramHeldPackages

        }); 
        
    }
    



    onClickReceiptToPortDelivery = () => {
      const values = queryString.parse(this.props.location.search)

      this.props.history.push({
        pathname: this.props.routeCustomer + '/reporting',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'customercode=' + values.customercode + '&' +
        'shipmenttype=' + values.shipmenttype + '&' +
        'finalcarrier=' + values.finalcarrier 
        }); 
        
    }


    

    StatCardColor = ( number, category ) => {
      if( number == '0' ) {
        return 'lightestgrey'
      }
      else if( category == 'Alerts' ) {
        return 'red'
      }

  
    }

    render() {


        if (this.props.isStatCardDataLoading) {
            return(
              <div align="center">
              <section className="panel">
                <header className="panel-heading">
                  {'Stat Cards'}
                </header>
                </section>
                <Loader 
                  type="Plane"
                  color="#00BFFF"
                  height="50"	
                  width="50"
                /> 
              </div>
            )
          }


  
          if (!this.props.isStatCardDataLoading) {
            if (this.props.StatCardData.length > 0) {
              var AvgManifestToDelivery = String(this.props.StatCardData[0].AvgManifestToDelivery) + ' Calendar';
              var AvgOTDtoFlightDepart = String(this.props.StatCardData[0].AvgOTDtoFlightDepart) + ' Days';
              var AvgDepartToRecovery = String(this.props.StatCardData[0].AvgDepartToRecovery) + ' Days';
              // var AvgFreightRecoveryToFinalHandOff = String(this.props.StatCardData[0].AvgFreightRecoveryToFinalHandOff) + ' Days';
              // var AvgFinalMileCarrierHandoffToOriginScan = String(this.props.StatCardData[0].AvgFinalMileCarrierHandoffToOriginScan) + ' Hours';
              var AvgOriginFinalMileCarrierScanToFinalDelivery = String(this.props.StatCardData[0].AvgOriginFinalMileCarrierScanToFinalDelivery) + ' Calendar';
              var InTransit = String(this.props.StatCardData[0].InTransit);
              var AvgPackagePerMC = String(this.props.StatCardData[0].AvgPackagesPerMC);
              var AvgWeightPerPackage = String(this.props.StatCardData[0].AvgWeightPerPackage) + ' lbs';
              var CarrierDeliveryAlert = String(this.props.StatCardData[0].CarrierDeliveryAlert);
              var CarrierInductionAlert = String(this.props.StatCardData[0].CarrierInductionAlert);
              var RecoveryEventAlert = String(this.props.StatCardData[0].RecoveryEventAlert);
              var CountHeldPackages = String(this.props.StatCardData[0].CountHeldPackages);
              var AvgRecoveryToOrigin = String(this.props.StatCardData[0].AvgRecoveryToOrigin) + ' Days';
              var AvgOriginToDeliveryBusDays = String(this.props.StatCardData[0].AvgOriginToDeliveryBusDays) + ' Business';
              var AvgManifestToDeliveryBusDays = String(this.props.StatCardData[0].AvgManifestToDeliveryBusDays) + ' Business';
              var FirstScanToDeliveryDays = AvgOriginToDeliveryBusDays  + '/' + AvgOriginFinalMileCarrierScanToFinalDelivery
              var ManifestToDeliveryDays = AvgManifestToDeliveryBusDays  + '/' + AvgManifestToDelivery
              var TotalPacakges = String(this.props.StatCardData[0].TotalPacakges);

              var AvgInWhsToFlightDepart = String(this.props.StatCardData[0].AvgInWhsToFlightDepart) + ' Days';
              var AvgInWhsToFreightRecovery = String(this.props.StatCardData[0].AvgInWhsToFreightRecovery) + ' Days';
              var AvgFlightDepartToFirstScan = String(this.props.StatCardData[0].AvgFlightDepartToFirstScan) + ' Days';
              var AvgInWhsToFirstScan = String(this.props.StatCardData[0].AvgInWhsToFirstScan) + ' Days';

              var AvgInWhsToDelivery = String(this.props.StatCardData[0].AvgInWhsToDelivery) + ' Days';



            }
            else {
              var AvgManifestToDelivery = 0;
              var AvgOTDtoFlightDepart = 0;
              var AvgDepartToRecovery = 0;
              // var AvgFreightRecoveryToFinalHandOff = 0;
              // var AvgFinalMileCarrierHandoffToOriginScan = 0;
              var InTransit = 0;
              var AvgPackagePerMC = 0;
              var AvgWeightPerPackage = 0;
              var CarrierDeliveryAlert = 0;
              var CountHeldPackages = 0;
              var AvgRecoveryToOrigin = 0;
              var AvgOriginToDeliveryBusDays = 0;
              var FirstScanToDeliveryDays = 0;
              var AvgManifestToDeliveryBusDays = 0;
              var ManifestToDeliveryDays = 0;
              var TotalPacakges = 0;
              var RecoveryEventAlert = 0;
              var AvgInWhsToFlightDepart = 0; 
              var AvgInWhsToFreightRecovery = 0;
              var AvgFlightDepartToFirstScan = 0;
              var AvgInWhsToFirstScan = 0;

              var AvgInWhsToDelivery = 0;

            }
          }


        let statCardAvgManifestToDelivery;
        statCardAvgManifestToDelivery=
            <StatsCard
            statValue={ManifestToDeliveryDays}
            statLabel={'Avg Inbd Manifest to Delivery (Days)'}
            icon={<i className="fa fa-plane" />}
            backColor={'blue'}
            isMobile={this.props.isMobile}
        />  

        let statOTDtoFlightDepart;
        statOTDtoFlightDepart = 
            <StatsCard
              statValue={AvgOTDtoFlightDepart}
              statLabel={'Avg Inbd Manifest to Depart'}
              icon={<i className="fa fa-plane" />}
              backColor={'blue'}
              isMobile={this.props.isMobile}
          />   

          let statAvgDepartToRecovery;
          statAvgDepartToRecovery = 
              <StatsCard
                statValue={AvgDepartToRecovery}
                statLabel={'Avg Depart to Recovery'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
            />   
  


          // let statCardAvgFinalMileCarrierHandoffToOriginScan;
          // statCardAvgFinalMileCarrierHandoffToOriginScan=
          //       <StatsCard
          //         statValue={AvgFinalMileCarrierHandoffToOriginScan}
          //         statLabel={'Avg Parcel Hand-off to First Scan'}
          //         icon={<i className="fa fa-plane" />}
          //         backColor={'blue'}
          //         isMobile={this.props.isMobile}
          //     />  

          let statCardAvgOriginFinalMileCarrierScanToFinalDelivery;
          statCardAvgOriginFinalMileCarrierScanToFinalDelivery=
            <StatsCard
                statValue={FirstScanToDeliveryDays}
                statLabel={'Avg First Scan to Delivery (Days)'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
            />  


        let statCardPkgInTransit;
        statCardPkgInTransit=
            <div style={{cursor: 'pointer'}} onClick={this.onClickInTransit} >
              <StatsCard
                  statValue={InTransit}
                  statLabel={'# Packages in-transit'}
                  icon={<i className="fa fa-truck" />}
                  backColor={'green'}
                  isMobile={this.props.isMobile}
              />
            </div>

        let statCardTotalPackages;
        statCardTotalPackages=
              <StatsCard
                  statValue={TotalPacakges}
                  statLabel={'Total Packages'}
                  icon={<i className="fa fa-gift" />}
                  backColor={'darkblue'}
                  isMobile={this.props.isMobile}
              />
            


        // let statCardPkgsPerMC;
        // statCardPkgsPerMC = 
        //     <StatsCard
        //       statValue={AvgPackagePerMC}
        //       statLabel={'Avg Packages per MC'}
        //       icon={<i className="fa fa-gift" />}
        //       backColor={'darkblue'}
        //       isMobile={this.props.isMobile}
        //   />     
          
          

          let statCardAvgWeightPerPackage;
          statCardAvgWeightPerPackage=
          <StatsCard
              statValue={AvgWeightPerPackage}
              statLabel={'Avg Weight Per Package'}
              icon={<i className="fa fa-balance-scale" />}
              backColor={'lightgrey'}
              isMobile={this.props.isMobile}
          />  


          let statCardCarrierDeliveryAlert;
          statCardCarrierDeliveryAlert=
          <div style={{cursor: 'pointer'}} onClick={this.onClickCarrierDeliveryAlert} >
            <StatsCard
                statValue={CarrierDeliveryAlert}
                statLabel={'Carrier Delivery Alerts'}
                icon={<i className="fa fa-exclamation-triangle" />}
                backColor={this.StatCardColor(CarrierDeliveryAlert, 'Alerts')}
                isMobile={this.props.isMobile}
            />  
          </div>

          let statCardCarrierInductionAlert;
          statCardCarrierInductionAlert=
          <div style={{cursor: 'pointer'}} onClick={this.onClickCarrierInductionAlert} >
            <StatsCard
                statValue={CarrierInductionAlert}
                statLabel={'Carrier Induction Alerts'}
                icon={<i className="fa fa-bolt" />}
                backColor={this.StatCardColor(CarrierInductionAlert, 'Alerts')}
                isMobile={this.props.isMobile}
            />  
          </div>

          let statCardRecoveryEventAlert;
          statCardRecoveryEventAlert=
          <div style={{cursor: 'pointer'}} onClick={this.onClickRecoveryEventAlert} >
            <StatsCard
                statValue={RecoveryEventAlert}
                statLabel={'Recovery Event Alerts'}
                icon={<i className="fa fa-bell" />}
                backColor={this.StatCardColor(RecoveryEventAlert, 'Alerts')}
                isMobile={this.props.isMobile}
            />  
          </div>


          let statCardCountHeldPackages;
          statCardCountHeldPackages=
          <div style={{cursor: 'pointer'}} onClick={this.onClickHeldPackages} >
            <StatsCard
                statValue={CountHeldPackages}
                statLabel={'Held Packages'}
                icon={<i className="fa fa-stop-circle" />}
                backColor={this.StatCardColor(CountHeldPackages, 'Alerts')}
                isMobile={this.props.isMobile}
            />  
          </div>


          let statCardAvgRecoveryToOrigin;
          statCardAvgRecoveryToOrigin=
            <StatsCard
                statValue={AvgRecoveryToOrigin}
                statLabel={'Avg Recovery to First Scan'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
            />
            



            let statCardAvgInWhsToFlightDepart;
            statCardAvgInWhsToFlightDepart=
              <StatsCard
                  statValue={AvgInWhsToFlightDepart}
                  statLabel={'Avg In Warehouse To Flight Depart'}
                  icon={<i className="fa fa-plane" />}
                  backColor={'blue'}
                  isMobile={this.props.isMobile}
              />

            let statCardAvgInWhsToFreightRecovery;
            statCardAvgInWhsToFreightRecovery=
              <StatsCard
                  statValue={AvgInWhsToFreightRecovery}
                  statLabel={'Avg In Warehouse To Freight Recovery'}
                  icon={<i className="fa fa-plane" />}
                  backColor={'blue'}
                  isMobile={this.props.isMobile}
              />

            let statCardAvgFlightDepartToFirstScan;
            statCardAvgFlightDepartToFirstScan=
              <StatsCard
                  statValue={AvgFlightDepartToFirstScan}
                  statLabel={'Avg Flight Depart To First Scan'}
                  icon={<i className="fa fa-plane" />}
                  backColor={'blue'}
                  isMobile={this.props.isMobile}
              />

            let statCardAvgInWhsToFirstScan;
            statCardAvgInWhsToFirstScan=
              <StatsCard
                  statValue={AvgInWhsToFirstScan}
                  statLabel={'Avg In Warehouse To First Scan'}
                  icon={<i className="fa fa-plane" />}
                  backColor={'blue'}
                  isMobile={this.props.isMobile}
              />

            let statCardAvgInWhsToDelivery;
            statCardAvgInWhsToDelivery=
              <StatsCard
                  statValue={AvgInWhsToDelivery}
                  statLabel={'Avg In Warehouse To Delivery'}
                  icon={<i className="fa fa-plane" />}
                  backColor={'blue'}
                  isMobile={this.props.isMobile}
              />



        let statCardRowDiv;
        if(this.props.isMobile){
        statCardRowDiv = 
            <div>
                <div style={{marginBottom: '1px'}}>
                {statCardAvgManifestToDelivery}
                </div>
                <div style={{marginBottom: '1px'}}>
                {statOTDtoFlightDepart}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statAvgDepartToRecovery}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardAvgOriginFinalMileCarrierScanToFinalDelivery}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardAvgRecoveryToOrigin}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardPkgInTransit}
                </div>
                <div style={{marginBottom: '1px'}}>
                {statCardTotalPackages}
                </div>  

                


                <div style={{marginBottom: '1px'}}>
                {statCardRecoveryEventAlert}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardCarrierInductionAlert}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardCarrierDeliveryAlert}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardCountHeldPackages}
                </div>   

                

                


            </div>
            }
        else{
        statCardRowDiv = 
            <div>
                <div
                    className="row"
                    style={{marginBottom: '15px'}}>
                      <div className="col-md-6" >
                        { statCardTotalPackages }
                      </div>
                      <div className="col-md-6" >
                        { statCardPkgInTransit }
                      </div>
                </div>

                <div
                    className="row"
                    style={{marginBottom: '15px'}}>
                      <div className="col-md-4" >
                        { statCardAvgInWhsToFlightDepart }
                      </div>
                      <div className="col-md-4" >
                        { statCardAvgInWhsToFreightRecovery }
                      </div>
                      <div className="col-md-4" >
                        { statCardAvgInWhsToFirstScan }
                      </div>
                </div>

                <div
                    className="row"
                    style={{marginBottom: '15px'}}>
                      <div className="col-md-4" >
                        { statCardAvgInWhsToDelivery }
                      </div>
                      <div className="col-md-4" >
                        { statCardAvgFlightDepartToFirstScan }
                      </div>
                      <div className="col-md-4" >
                        { statCardAvgOriginFinalMileCarrierScanToFinalDelivery }
                      </div>
                </div>










                {/* <div
                    className="row"  
                    style={{marginBottom: '15px'}}>
                </div> */}

                  <div
                    className="row"
                    style={{marginBottom: '15px'}}>

                      <div className="col-md-4" >
                      { statOTDtoFlightDepart }
                      </div>
                      <div className="col-md-4" >
                      { statAvgDepartToRecovery }
                      </div>
                      <div className="col-md-4" >
                      { statCardAvgRecoveryToOrigin }
                      </div>
                  </div>

                  <div
                    className="row"
                    style={{marginBottom: '15px'}}>
                      <div className="col-md-12" >
                        { statCardAvgManifestToDelivery }
                      </div>

                </div>



                  <div
                    className="row"
                    style={{marginBottom: '15px'}}>
                      <div className="col-md-4" >
                      {statCardRecoveryEventAlert}
                      </div>
                      <div className="col-md-4" >
                      {statCardCarrierInductionAlert}
                      </div>
                      <div className="col-md-4" >
                      {statCardCarrierDeliveryAlert}
                      </div>
                  </div>



            </div>
        }


      return(
        <div>
            {statCardRowDiv}
        </div>
      );

    }
  }
  

  export default withRouter(GBEvolveStatsCardRow);
  
