
import { combineReducers } from 'redux'
import { reducer as authReducer } from 'aws-cognito-redux-saga'
import { reducer as formReducer } from 'redux-form'

import { 
  SignOutReducer,
  UserServicesReducer,
  }                                         from './reducerUser';

import ZuChartsReducer                      from './zuReducer';

import GbDataReducer                        from './gbReducer';

import ShipmentScheduleGraphReducer         from './googleReducer';
import { 
  StatCardRowReducer,
  ShipmentHistoryReducer,
  ShipmentPieReducer,
  ShipmentTrackerReducer,
  ShipmentHistoryBarReducer,
  ShipmentDetailsReducer,
  MilestoneAlertReducer,
  ReportingReducer,
  AlertKpiTrendReducer,
  GlobalFilterReducer
  
  // RootReducer
  }                                         from './googleReducer';


import UpsDataReducer                        from './upsReducer';


const reducers = combineReducers({
  auth: authReducer.reducer,
  form: formReducer,

  signOutReducer: SignOutReducer,
  userServicesReducer: UserServicesReducer,

  //ZU reducers
  zuDataReducer: ZuChartsReducer,

  //GB reducers
  gbDataReducer: GbDataReducer,

  //Google reducers
  statCardRowReducer: StatCardRowReducer,
  shipmentScheduleGraphReducer: ShipmentScheduleGraphReducer,
  shipmentHistoryReducer: ShipmentHistoryReducer,
  shipmentPieReducer: ShipmentPieReducer,
  shipmentTrackerReducer: ShipmentTrackerReducer,
  shipmentHistoryBarReducer: ShipmentHistoryBarReducer,
  shipmentDetailsReducer: ShipmentDetailsReducer,
  milestoneAlertReducer: MilestoneAlertReducer,
  reportingReducer: ReportingReducer,
  alertKpiTrendReducer: AlertKpiTrendReducer,
  globalFilterReducer: GlobalFilterReducer,

  //UPS reducers
  upsDataReducer: UpsDataReducer,

})

export default reducers

