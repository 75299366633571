/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';



/* ################################### CHARTS ACTIONS TYPES ################################### */
export const OTD_TO_MC_DATA_GET = 'OTD_TO_MC_DATA_GET';
export const OTD_TO_MC_DATA_COMPLETE = 'OTD_TO_MC_DATA_COMPLETE';
export const OTD_TO_MC_DATA_ERROR = 'OTD_TO_MC_DATA_ERROR';

export const OTD_TO_FLIGHT_DEPART_DATA_GET = 'OTD_TO_FLIGHT_DEPART_DATA_GET';
export const OTD_TO_FLIGHT_DEPART_DATA_COMPLETE = 'OTD_TO_FLIGHT_DEPART_DATA_COMPLETE';
export const OTD_TO_FLIGHT_DEPART_DATA_ERROR = 'OTD_TO_FLIGHT_DEPART_DATA_ERROR';

export const OTD_TO_FLIGHT_ARRIVAL_DATA_GET = 'OTD_TO_FLIGHT_ARRIVAL_DATA_GET';
export const OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE = 'OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE';
export const OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR = 'OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR';

export const AGING_CHART_DATA_GET = 'AGING_CHART_DATA_GET';
export const AGING_CHART_DATA_COMPLETE = 'AGING_CHART_DATA_COMPLETE';
export const AGING_CHART_DATA_ERROR = 'AGING_CHART_DATA_ERROR';

export const DIM_WEIGHT_CHART_DATA_GET = 'DIM_WEIGHT_CHART_DATA_GET';
export const DIM_WEIGHT_CHART_DATA_COMPLETE = 'DIM_WEIGHT_CHART_DATA_COMPLETE';
export const DIM_WEIGHT_CHART_DATA_ERROR = 'DIM_WEIGHT_CHART_DATA_ERROR';

export const STAT_CARD_DATA_GET = 'STAT_CARD_DATA_GET';
export const STAT_CARD_DATA_COMPLETE = 'STAT_CARD_DATA_COMPLETE';
export const STAT_CARD_DATA_ERROR = 'STAT_CARD_DATA_ERROR';

export const OTD_TO_THREE_AVGS_DATA_GET = 'OTD_TO_THREE_AVGS_DATA_GET';
export const OTD_TO_THREE_AVGS_DATA_COMPLETE = 'OTD_TO_THREE_AVGS_DATA_COMPLETE';
export const OTD_TO_THREE_AVGS_DATA_ERROR = 'OTD_TO_THREE_AVGS_DATA_ERROR';

export const MAWB_DETAILS_DATA_GET = 'MAWB_DETAILS_DATA_GET';
export const MAWB_DETAILS_DATA_COMPLETE = 'MAWB_DETAILS_DATA_COMPLETE';
export const MAWB_DETAILS_DATA_ERROR = 'MAWB_DETAILS_DATA_ERROR';

export const MC_DETAILS_DATA_GET = 'MC_DETAILS_DATA_GET';
export const MC_DETAILS_DATA_COMPLETE = 'MC_DETAILS_DATA_COMPLETE';
export const MC_DETAILS_DATA_ERROR = 'MC_DETAILS_DATA_ERROR';

export const HAWB_DETAILS_DATA_GET = 'HAWB_DETAILS_DATA_GET';
export const HAWB_DETAILS_DATA_COMPLETE = 'HAWB_DETAILS_DATA_COMPLETE';
export const HAWB_DETAILS_DATA_ERROR = 'HAWB_DETAILS_DATA_ERROR';

export const HAWB_MC_DETAILS_DATA_GET = 'HAWB_MC_DETAILS_DATA_GET';
export const HAWB_MC_DETAILS_DATA_COMPLETE = 'HAWB_MC_DETAILS_DATA_COMPLETE';
export const HAWB_MC_DETAILS_DATA_ERROR = 'HAWB_MC_DETAILS_DATA_ERROR';

export const JOB_EVENTS_DATA_GET = 'JOB_EVENTS_DATA_GET';
export const JOB_EVENTS_DATA_COMPLETE = 'JOB_EVENTS_DATA_COMPLETE';
export const JOB_EVENTS_DATA_ERROR = 'JOB_EVENTS_DATA_ERROR';

export const IBC_DTL_DATA_GET = 'IBC_DTL_DATA_GET';
export const IBC_DTL_DATA_COMPLETE = 'IBC_DTL_DATA_COMPLETE';
export const IBC_DTL_DATA_ERROR = 'IBC_DTL_DATA_ERROR';

export const HAZ_MAT_DATA_GET = 'HAZ_MAT_DATA_GET';
export const HAZ_MAT_DATA_COMPLETE = 'HAZ_MAT_DATA_COMPLETE';
export const HAZ_MAT_DATA_ERROR = 'HAZ_MAT_DATA_ERROR';

export const PENDING_SAILING_CHART_DATA_GET = 'PENDING_SAILING_CHART_DATA_GET';
export const PENDING_SAILING_CHART_DATA_COMPLETE = 'PENDING_SAILING_CHART_DATA_COMPLETE';
export const PENDING_SAILING_CHART_DATA_ERROR = 'PENDING_SAILING_CHART_DATA_ERROR';

export const DEMURRAGE_CHART_DATA_GET = 'DEMURRAGE_CHART_DATA_GET';
export const DEMURRAGE_CHART_DATA_COMPLETE = 'DEMURRAGE_CHART_DATA_COMPLETE';
export const DEMURRAGE_CHART_DATA_ERROR = 'DEMURRAGE_CHART_DATA_ERROR';

export const UPS_MI_DATA_GET = 'UPS_MI_DATA_GET';
export const UPS_MI_DATA_COMPLETE = 'UPS_MI_DATA_COMPLETE';
export const UPS_MI_DATA_ERROR = 'UPS_MI_DATA_ERROR';

export const DAILY_SHIP_PORTS_DATA_GET = 'DAILY_SHIP_PORTS_DATA_GET';
export const DAILY_SHIP_PORTS_DATA_COMPLETE = 'DAILY_SHIP_PORTS_DATA_COMPLETE';
export const DAILY_SHIP_PORTS_DATA_ERROR = 'DAILY_SHIP_PORTS_DATA_ERROR';

export const AVG_CUST_CLEAR_DATA_GET = 'AVG_CUST_CLEAR_DATA_GET';
export const AVG_CUST_CLEAR_DATA_COMPLETE = 'AVG_CUST_CLEAR_DATA_COMPLETE';
export const AVG_CUST_CLEAR_DATA_ERROR = 'AVG_CUST_CLEAR_DATA_ERROR';

export const DS_NEURAL_NET_SCORE_GET = 'DS_NEURAL_NET_SCORE_GET';
export const DS_NEURAL_NET_SCORE_COMPLETE = 'DS_NEURAL_NET_SCORE_COMPLETE';
export const DS_NEURAL_NET_SCORE_ERROR = 'DS_NEURAL_NET_SCORE_ERROR';

export const EVOLVE_BROKERAGE_REPORT_DATA_GET = 'EVOLVE_BROKERAGE_REPORT_DATA_GET';
export const EVOLVE_BROKERAGE_REPORT_DATA_COMPLETE = 'EVOLVE_BROKERAGE_REPORT_DATA_COMPLETE';
export const EVOLVE_BROKERAGE_REPORT_DATA_ERROR = 'EVOLVE_BROKERAGE_REPORT_DATA_ERROR';

export const EVOLVE_BROKERAGE_STAT_CARDS_GET = 'EVOLVE_BROKERAGE_STAT_CARDS_GET';
export const EVOLVE_BROKERAGE_STAT_CARDS_COMPLETE = 'EVOLVE_BROKERAGE_STAT_CARDS_COMPLETE';
export const EVOLVE_BROKERAGE_STAT_CARDS_ERROR = 'EVOLVE_BROKERAGE_STAT_CARDS_ERROR';

export const ZU_MBOL_EMAIL_FILENAME_GET = 'ZU_MBOL_EMAIL_FILENAME_GET';
export const ZU_MBOL_EMAIL_FILENAME_COMPLETE = 'ZU_MBOL_EMAIL_FILENAME_COMPLETE';
export const ZU_MBOL_EMAIL_FILENAME_ERROR = 'ZU_MBOL_EMAIL_FILENAME_ERROR';

export const ZU_MBOL_EMAIL_URL_GET = 'ZU_MBOL_EMAIL_URL_GET';
export const ZU_MBOL_EMAIL_URL_COMPLETE = 'ZU_MBOL_EMAIL_URL_COMPLETE';
export const ZU_MBOL_EMAIL_URL_ERROR = 'ZU_MBOL_EMAIL_URL_ERROR';