import React, {
  PureComponent
}                         from 'react';
import { appConfig }      from '../../../app/appConfig';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {appSettings} from '../../../app/appSettings'
import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'
import Home from '../../../components/ComponentsGeneral/Home/Home'
import Button from 'react-bootstrap/Button'

const mapStateToProps = store =>
({
  currentUserEmail:               store.userServicesReducer.currentUserEmail,
  userSignInData:                 store.userServicesReducer.userSignInData,

  isAppSigningInLoading:          store.userServicesReducer.isAppSigningInLoading,
  isGetPageConfigLoading:         store.userServicesReducer.isGetPageConfigLoading

});

const mapDispatchToProps = dispatch =>
({
    signOut: () => {
      dispatch(reducer.signOut( { global: true } ))
    },
    appSignOut: () => {
      dispatch( actions.appSignOut() )
    },

    // getPageConfig:( currentUserEmail, filter ) =>
    // {
    //     dispatch( actions.getPageConfig( currentUserEmail, filter ) )
    // },

});

class HomeView extends PureComponent {
  constructor(props) {
      super(props);
      this.state = {
        warningTime: appSettings.warningTimeLogout,  
        signoutTime: appSettings.signoutTimeLogout,
        width: window.innerWidth,
        isSideMenuCollapsed: false,
        navSelection: 'Select Customer',
        nav: '',
        languageSelection: 'English'
      };
      this.dropDownOnChange = this.dropDownOnChange.bind(this);
    }

    

    componentWillMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
  

    componentDidMount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
      this.setTimeout();

      this.getLocalStorageLanguageSelection()

      this.getLocalStorageNavSelection() //this will preserve menu selection



        
      // let pageConfigData = localStorage.getItem('PageConfig.NavigationSelectionOptions');
      // this.setState({ 
      //   pageConfigState: pageConfigData 
      // });

    }


    componentWillUnmount() {
      this.events = [
        'load',
        'mousemove',
        'mousedown',
        'click',
        'scroll',
        'keypress'
      ];

      for (var i in this.events) {
        window.addEventListener(this.events[i], this.resetTimeout);
      }
      this.setTimeout();
      
      this.clearTimeoutFunc()

      window.removeEventListener('resize', this.handleWindowSizeChange);
    }


    componentDidUpdate(prevProps) {
      this.getLocalStorageLanguageSelection()
      
      if(this.state.width <= appSettings.mobileWidth){
        if (this.props.location.pathname !== prevProps.location.pathname) {
          this.setState({ isSideMenuCollapsed: true });            
        }
      }

      if (prevProps.location.search !== this.props.location.search) {
        try{
          this.getLocalStorageNavSelection()
        }
        catch(e){
          
        }
      }

    }

    onClickSideMenu = () => {
      if(this.state.isSideMenuCollapsed){
        this.setState({ isSideMenuCollapsed: false });
      }
      else{
        this.setState({ isSideMenuCollapsed: true });
      }        
    }

    getLocalStorageLanguageSelection() {
      let languageSelectionValue;
      try{
        languageSelectionValue = localStorage.getItem('PageConfig.UserSettings.Language');
        this.setState({
          languageSelection: languageSelectionValue
        })
      }
      catch(e){
        this.setState({
          languageSelection: "English"
        })
      }

    }




    getLocalStorageNavSelection() {
      let navMenuValue = localStorage.getItem('navMenu');
      if(navMenuValue != null && navMenuValue != undefined){
        this.setState({
          navSelection: navMenuValue
        })
      }
    }

    // renderGetPageConfig() {
    //   try{
    //     let pageConfigData = localStorage.getItem('PageConfig.NavigationSelectionOptions');
    //     this.setState({ 
    //       pageConfigState: pageConfigData 
    //     });
    //   }
    //   catch(e){
    //     console.log('render get config error', e)
    //     this.props.getPageConfig( this.props.currentUserEmail, 'signin')
    //   }

    // }

    saveNavSelectionToLocalStorage(navMenuToSave) {
      try{
        localStorage.setItem('navMenu', navMenuToSave)
      }
      catch(e){
        console.log('error', e)
      }
    }

    dropDownOnChange(value) {
      this.setState({ 
        navSelection: value[0].value
      });
      this.saveNavSelectionToLocalStorage(value[0].value)
    }

    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };

    clearTimeoutFunc = () => {
      if (this.warnTimeout) clearTimeout(this.warnTimeout);

      if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
    };

    setTimeout = () => {
      this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
      this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
      this.clearTimeoutFunc();
      this.setTimeout();
    };

    warn = () => {
      // window.alert("You will be logged out automatically in 1 minute")
      console.log('You will be logged out automatically in 1 minute.');
    };

    logout = () => {
      // Send a logout request to the API
      console.log('Auto Logout');

      this.props.signOut()
      this.props.appSignOut()
      this.props.history.push('/signin')
      window.location.reload();
    };

    destroy = () => {
      this.props.history.push('/signin')
    };

    signOut = () => {
      this.props.history.push('/signin')
    };
  
  state = {
      appLogo:          appSettings.logo,
      appName:          appConfig.APP_NAME,
      connectionStatus: appConfig.CONNECTION_STATUS,
      helloWord:        appConfig.HELLO_WORD
  };

  render() {
      const { width, isSideMenuCollapsed } = this.state;
      const isMobile = width <= appSettings.mobileWidth;



      let homeViewDiv;
      if (this.props.isGetPageConfigLoading || this.props.isAppSigningInLoading) {
        homeViewDiv=
          <div></div>
      }
      else{

        homeViewDiv=
        <div>
          <div  className="verticalMenuButton"  onClick={() => this.onClickSideMenu()} >
            <Button variant="light" size="sm"  title="Hide/Show Menu"   >
                      <i className="fa fa-bars"></i>
            </Button>
          </div>
          <Home
              email={this.props.userSignInData.EmailAddr}         
              firstName={this.props.userSignInData.FirstName}
              lastName={this.props.userSignInData.LastName}       
              pendingApproval={this.props.userSignInData.PendingApproval}  
              admin={this.props.userSignInData.Admin}
              group={this.props.userSignInData.GroupName}      
              businessUnit={this.props.userSignInData.BusinessUnitName}
              isMobile={isMobile} 
              sideMenuIsCollapsed={isSideMenuCollapsed} 
              navSelection={this.state.navSelection}
              dropDownOnChange={this.dropDownOnChange}
              languageSelection={this.state.languageSelection}

          />
          </div>
      }

      return(
       

          <div>
          <div  className="verticalMenuButton"  onClick={() => this.onClickSideMenu()} >
            <Button variant="light" size="sm"  title="Hide/Show Menu"   >
                      <i className="fa fa-bars"></i>
            </Button>
          </div>
          <Home
              email={this.props.userSignInData.EmailAddr}         
              firstName={this.props.userSignInData.FirstName}
              lastName={this.props.userSignInData.LastName}       
              pendingApproval={this.props.userSignInData.PendingApproval}  
              admin={this.props.userSignInData.Admin}
              group={this.props.userSignInData.GroupName}      
              businessUnit={this.props.userSignInData.BusinessUnitName}
              isMobile={isMobile} 
              sideMenuIsCollapsed={isSideMenuCollapsed} 
              navSelection={this.state.navSelection}
              dropDownOnChange={this.dropDownOnChange}
              languageSelection={this.state.languageSelection}
          />
          </div>

        

      );
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeView));































// import React, {
//     PureComponent
//   }                         from 'react';
//   import {withRouter} from 'react-router-dom';
  
  
//   class HomeView extends PureComponent {
//     constructor(props) {
//         super(props);
  
//       }
  
  
  
//     render() {
  
      
  
  
//         return(
//           <div>
  
  
//               Home View
  
  
  
//             </div>
  
//         );
//     }
//   }
  
    
//   export default (withRouter(HomeView));
  