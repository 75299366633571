import * as types from '../constants/upsActionTypes';

import {appSettings} from '../../app/appSettings'

export const upsLabelCreation = ( _param ) =>
( {
    type:    types.UPS_LABEL_CREATION_GET,
    payload: {
        param: _param
    }
} );  

