import React, {
    PureComponent
  }                         from 'react';
  import Loader from 'react-loader-spinner'

  import {withRouter} from 'react-router-dom';

  class SignInUserAuth extends PureComponent {
    constructor( props )
    {
      super( props );
    }



      componentDidMount() {

        this.props.history.push('/home')


      //   if (this.props.userSignInData.Active === true ) {

      //       this.props.history.push('/home')
      //       // this.props.history.push('/home')
      //   }

      //   else{
      //       // window.alert("Please signin again.")
      //       this.props.history.push('/signin')
      //   }
      }



    render() {

      return(
        
            <div align="center">
            <section className="panel">
              <header className="panel-heading">
                {}
              </header>
              </section>
              <Loader 
                type="Plane"
                color="#00BFFF"
                height="50"	
                width="50"
            /> 
            </div>

      );
    }
  }
  

  export default withRouter(SignInUserAuth);
  
  