////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

////local imports
import {
  Table,
  TableBody
}                         from '../../ComponentsGeneral/table'
import {//valueDeliveryDayFormatter, 
    // buttonApproveAccount, 
    // buttonRejectAccount, 
  // hotShipmentIcon
} from '../../../resources/logic/formatting';
import Button from 'react-bootstrap/Button'

import {milestoneAlertSeverityFormatter, 
  milestoneAlertDetailsSeverityFormatter,
  milestoneCustomerAlertFormatter
} from '../../../resources/logic/formatting';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


import {
  ResponsiveContainer,
} from 'recharts';
import {appSettings} from '../../../app/appSettings'


  class MilestoneAlerts extends PureComponent {

    constructor( props )
    {
      super( props );
      this.state = {
        width: window.innerWidth,
      };
  
    }

    state = {
      comment: '' //this is to stay in local state
    }; 


    componentWillMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
   
    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
   
    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };
  
    
      detailsClick( row  ) {
        var paramhousebill = row.HouseBill
        var paramjobid = row.JobID
        // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row, paramhousebill);
        // this.props.getShipmentTrackerDataByJobID(this.props.currentUserEmail, paramhousebill);
        this.props.history.push({
        pathname: 'shipmentdetails/',
        search: 'housebill=' + paramhousebill
        // state: {
        //   details:  row
        //   }
        })
      }

      onChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
      }

      //prevent search form input from submitting on enter key
      onKeyPress(event) {
        if (event.which === 13 /* Enter */) {
          event.preventDefault();
        }
      }

      onClickMilestoneComment = (e, jobID) => {
        e.preventDefault();
        const { comment } = this.state;
        if(comment.trim() === "") {
          window.alert("Comment is empty.");
          return
        }
        else {
          this.props.postShipModalComment( this.props.userSignInData.DBName, this.props.currentUserEmail, jobID,  comment.trim(), 2);
  
          //this is local state for comment
          const newState = Object.assign({}, this.state, {
            comment: ""
          });
          // store the new state object in the component's state
          this.setState(newState);
        }


        window.alert("Your comment has been added.")
        // get our form data out of state
        // const { comment } = this.state;
  
        // if(comment.trim() === "") {
        //   window.alert("Comment is empty.");
        //   return
        // }
        // else {
        //   this.props.postShipModalComment( this.props.currentUserEmail, this.props.shipTrackModalRow.JobID,  comment.trim());
  
        //   //this is local state for comment
        //   const newState = Object.assign({}, this.state, {
        //     comment: ""
        //   });
        //   // store the new state object in the component's state
        //   this.setState(newState);
        // }
      }

    
    render() {
      const { ExportCSVButton } = CSVExport;
      const {
        comment
      } = this.state;
      const { width } = this.state;
      const isMobile = width <= appSettings.mobileWidth;

      const expandRow = {
        renderer: row => (
          
          <div style={{cursor: 'default', marginLeft: '10px', marginRight: '10px'}} >
    
          <Button variant="primary" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.detailsClick(row, e)}>
          Click for Shipment Details
          </Button>
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Shipment Alerts: ' + row.HouseBill}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>



            <form
              className="sidebar-form"
              onKeyPress={this.onKeyPress}
              >
              <div className="input-group">
                <input
                  type="text"
                  name="comment"
                  className="form-control"
                  placeholder="Add new comment..."
                  value={comment}
                  onChange={this.onChange}
                />
                <span className="input-group-btn">
                  <button
                  // onClick={this.onClickMilestoneComment(row.JobID)}
                  onClick={(e) => this.onClickMilestoneComment(e, row.JobID)}
                  name="comment"
                  id="comment-btn"
                  title="Click to submit comment"
                  className="btn btn-flat">
                    <i className="fa fa-plus-circle" style={{   
                          fontSize: '20px',
                          cursor: "pointer"
                          }}></i>
                  </button>
                </span>
              </div>             
            </form>

            <ToolkitProvider
              keyField="UniqueID"
              data={ this.props.milestoneAlertData.filter(x => x.JobID === row.JobID) }
              columns={ milestoneExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps } />
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }

      /////////
      const uniqueBy = prop => list => {
        const uniques = {}
        return list.reduce(
            (result, item) => {
                if (uniques[item[prop]]) return result
                uniques[item[prop]] = item
                return [...result, item]
            },
            [],
        )
    }
    
    const uniqueById = uniqueBy('JobID')



    const mobileMilestoneAlertColumns = [{

    },{
      dataField: 'Max_Alert_Category',
      text: 'Alert Severity',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '50%' , textAlign: 'center' };
      },
      formatter: milestoneAlertSeverityFormatter,
      sort: true
    },{
      dataField: 'IssueIndex',
      text: 'Issue Index',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '50%' , textAlign: 'center' };
      },
      sort: true
    }
    ]

//need to have unique id key
        
      const desktopMilestoneAlertColumns = [{
        dataField: 'HouseBill',
        text: 'House Bill',
        // align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'CurrentShipmentStatus',
        text: 'Current Status',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'CurrentShipmentStatusDateTime',
        text: 'Current Status Datetime',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        sort: true
      },{
      //   dataField: 'STAKES',
      //   text: 'Stake',
      //   align: 'center',
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '10%' , textAlign: 'center' };
      //   },
      //   sort: true,
      // },{
      //   dataField: 'EstimatedDeliveryDate',
      //   text: 'Stake Target Date',
      //   align: 'center',
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '15%' , textAlign: 'center' };
      //   },
      //   sort: true,
      // },{
        dataField: 'Max_Alert_Category',
        text: 'Alert Severity',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        formatter: milestoneAlertSeverityFormatter,
        sort: true
      },{
        dataField: 'IssueIndex',
        text: 'Issue Index',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '5%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'Max_Hours_Into_Alert',
        text: 'Oldest Alert (Hrs)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '9%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'Max_Customer_Alert',
        text: 'Customer Alert',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        formatter: milestoneCustomerAlertFormatter,
        sort: true
      },{
        dataField: 'Min_Hours_Until_Customer_Alert',
        text: 'Hours Until Customer Alert',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // formatter: milestoneCustomerAlertFormatter
        sort: true
      }
      ]


      const mobileMilestoneExpandColumns = [{
        dataField: 'MilestoneAlert',
        text: 'Milestone Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'Alert_Category',
        text: 'Severity',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        formatter: milestoneAlertDetailsSeverityFormatter,
        sort: true
      }
      ]


      const desktopMilestoneExpandColumns = [{
        dataField: 'MilestoneAlert',
        text: 'Milestone Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'STAKES',
        text: 'Stake',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'StakeTargetDate',
        text: 'Stake Target Date',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true,
      },{
        dataField: 'Alert_Category',
        text: 'Severity',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        formatter: milestoneAlertDetailsSeverityFormatter,
        sort: true
      },{
        dataField: 'Hours_Into_Alert',
        text: 'Hrs into Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'Next_Alert',
        text: 'Next Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'Hours_Until_Next_Alert',
        text: 'Hrs Until Next Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      },  
      ]

      let milestoneAlertColumns;
      if(isMobile){
        milestoneAlertColumns = mobileMilestoneAlertColumns
      }
      else{
        milestoneAlertColumns = desktopMilestoneAlertColumns
      }



      let milestoneExpandColumns;
      if(isMobile){
        milestoneExpandColumns = mobileMilestoneExpandColumns
      }
      else{
        milestoneExpandColumns = desktopMilestoneExpandColumns
      }

      


      let bootStrapTable;
      if (this.props.isMilestoneAlertLoading) {
        bootStrapTable=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.milestoneAlertData) !== 'undefined' && this.props.milestoneAlertData != null) {

        bootStrapTable =
        <Table>
        <BootstrapTable 
        keyField='UniqueID' 
        // data={ this.props.milestoneAlertData } 
        data={ uniqueById(this.props.milestoneAlertData) } 
        columns={ milestoneAlertColumns } 
        expandRow={ expandRow }
        // rowEvents={ rowEvents }
        />
      </Table>
      } else {
        bootStrapTable = <div></div>;
      }





      let milestoneTrend;
      if (this.props.isAlertKpiTrendLoading) {
        milestoneTrend=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Milestone Alerts Trend'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        
      }
      else if (typeof(this.props.alertKpiTrendData) !== 'undefined' && this.props.alertKpiTrendData != null) {


        milestoneTrend=
        <section className="panel">
        <header className="panel-heading">
          {'Milestone Alerts Trend'}
        </header>
        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
              <LineChart //width={730} height={250} 
                  data={ this.props.alertKpiTrendData }
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Milestone Alerts" stroke="#413ea0" strokeWidth={2}/>
              </LineChart>
        </ResponsiveContainer>
        </div>
        </div>
            </section>
      }

 

      return(
        <div>

          { milestoneTrend }


          <section className="panel">





            <header className="panel-heading">
              {'Milestone Alerts'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>

              { bootStrapTable }

            </div>

          </section>
          </div>
      );
    }
  }

  export default withRouter(MilestoneAlerts);
  