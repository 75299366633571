import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import {
    Table,
    TableBody
  }                         from '../../../ComponentsGeneral/table';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import StatsCard from '../../../ComponentsGeneral/StatsCard/StatsCard'
import {
  buttonFormatter,
  gbFlagFormatter
} from '../../../../resources/logic/formatting';
import GBMicomEvents from '../GBDetailComponents/GBMicomEvents';
import ReactExport from "react-export-excel";
import * as moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")



  class GBMawbDetails extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    render() {
      const { ExportCSVButton } = CSVExport;


      /////////
      const uniqueBy = prop => list => {
        const uniques = {}
        return list.reduce(
            (result, item) => {
                if (uniques[item[prop]]) return result
                uniques[item[prop]] = item
                return [...result, item]
            },
            [],
        )
      }
      
      const uniqueByHAWB = uniqueBy('HAWB')
      

      const mcColumns = [
        {
        dataField: 'Barcode',
        text: 'Master Carton #',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'CartonCount',
        text: 'Package Count',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'SumUnitCount',
        text: 'Sum (Unit Count)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'Weight',
        text: 'Weight (kgs)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
      //   dataField: 'AvgManifestToFlightDepartSched',
      //   text: 'Avg Manifest to Depart (Sched)',
      //   align: 'center',
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '25%' , textAlign: 'center' };
      //   },
      //   sort: true
      // }, {
        dataField: 'AvgMaxManToDepartActual',
        text: 'Inbd Manifest to Depart (Avg/Max)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, 
      // {

      //     dataField: 'HazMat',
      //     text: 'HazMat',
      //     align: 'center',
      //     headerStyle: (colum, colIndex) => {
      //       return { width: '20%' , textAlign: 'center' };
      //     },
      //     sort: true


      // },
      {
        dataField: 'icons',
        text: 'Flags',
        align: 'center',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        formatter: gbFlagFormatter
      },


      {
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   

            var paramMC = row.Barcode.trim() 
            var paramMAWB = this.props.MawbDetailData[0].MAWB

              this.props.history.push({
              pathname: 'mcdetails',
              search: 'mc=\'' + paramMC +'\'' + '&' +
              'mawb=\'' + paramMAWB +'\''
              }); 
            ; }
          }
      }

      ]

      const mobileMCColumns = [
        {
        dataField: 'Barcode',
        text: 'Master Carton #',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '33%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'CartonCount',
        text: 'Package Count',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '33%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'SumUnitCount',
        text: 'Sum (Unit Count)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '33%' , textAlign: 'center' };
        },
        sort: true
      },
      {
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   

            var paramMC = row.Barcode.trim() 
            var paramMAWB = this.props.MawbDetailData[0].MAWB

              this.props.history.push({
              pathname: 'mcdetails',
              search: 'mc=\'' + paramMC +'\'' + '&' +
              'mawb=\'' + paramMAWB +'\''
              }); 
            ; }
          }
      }
      ]

      


      const hawbExpandColumns = [
        {

        dataField: 'HAWB',
        text: 'Tracking No',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '40%' , textAlign: 'center' };
        },
        sort: true,
        style: { overflowWrap: "break-word" }
      }, {   

        dataField: 'R_State',
        text: 'Ship-to State',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {

        dataField: 'ParcelWeight',
        text: 'Package Weight (kgs)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'ManifestToFlightDepartActual',
        text: 'Inbd Manifest to Depart',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'ManifestToFreightRecoveryActual',
        text: 'Inbd Manifest to Freight Recovery',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, 
      // {
      //   dataField: 'HazMat',
      //   text: 'HazMat',
      //   align: 'center',
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '15%' , textAlign: 'center' };
      //   },
      //   sort: true
      // },
      {
        dataField: 'icons',
        text: 'Flags',
        align: 'center',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        formatter: gbFlagFormatter
      },
      {
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   

            var paramMC = row.Barcode.trim() 
            var paramTrackingNo = row.HAWB.trim()

              this.props.history.push({
              pathname: 'hawbdetails',
              search: 'mc=\'' + paramMC +'\'' + '&' +
              'trackingno=\'' + paramTrackingNo +'\''
              }); 
            ; }
          }
      }


      
      ]

      const mobileHawbExpandColumns = [
        {
        dataField: 'HAWB',
        text: 'Tracking No',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '80%' , textAlign: 'center' };
        },
        sort: true,
        style: { overflowWrap: "break-word" }
      }, {

        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '20%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   

            var paramMC = row.Barcode.trim() 
            var paramTrackingNo = row.HAWB.trim()

              this.props.history.push({
              pathname: 'hawbdetails',
              search: 'mc=\'' + paramMC +'\'' + '&' +
              'trackingno=\'' + paramTrackingNo +'\''
              }); 
            ; }
          }
      }
      ]






      const expandMCRow = {
        renderer: row => (
          <div style={{marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Shipments: ' + row.Barcode}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
      
            <ToolkitProvider
              keyField="UniqueID"

              data={ uniqueByHAWB(this.props.HawbDetailData.filter(x => x.Barcode == row.Barcode)) }

              columns= {(this.props.isMobile == true  ) ? mobileHawbExpandColumns  : hawbExpandColumns }


              exportCSV
            >


              {
                props => (
                  <div>
                    {/* <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> */}
                    <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'packages_'+ currentDateTime}>
                                <ExcelSheet data={ uniqueByHAWB(this.props.HawbDetailData.filter(x => x.Barcode == row.Barcode)) } name="packages">
                                <ExcelColumn label="Tracking No" value="HAWB"/>
                                <ExcelColumn label="Ship-to State" value="R_State"/>
                                <ExcelColumn label="Package Weight (kgs)" value="ParcelWeight"/>
                                <ExcelColumn label="Inbd Manifest to Depart" value="ManifestToFlightDepartActual"/>
                                <ExcelColumn label="Inbd Manifest to Freight Recovery" value="ManifestToFreightRecoveryActual"/>
                                </ExcelSheet>
                            </ExcelFile>
                    <hr />
                    <BootstrapTable { ...props.baseProps } 
                    // expandRow={ expandPackageRow }  
                    />
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }





        let mawbDet;
        if (this.props.isMawbDataLoading) {
          mawbDet=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'MAWB Details'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        }  
        else if (typeof(this.props.MawbDetailData) !== 'undefined' && this.props.MawbDetailData != null) {
            mawbDet =

                <section className="panel">

                  <div
                  className="row"
                  style={{marginBottom: '15px'}}>




                    <div className="col-md-3" >
                    <StatsCard
                        statValue={ this.props.MawbDetailData[0].AvgMaxManToDepartActual}
                        statLabel={'Inbd Manifest to Depart (Avg/Max)'}
                        icon={<i className="fa fa-plane" />}
                        backColor={'blue'}
                        isMobile={this.props.isMobile}
                      />
                    </div>
                    <div className="col-md-3" >
                      <StatsCard
                        statValue={this.props.MawbDetailData[0].AvgMaxManToFreightRecoveryActual}
                        statLabel={'Inbd Manifest to Freight Recovery (Avg/Max)'}
                        icon={<i className="fa fa-plane" />}
                        backColor={'blue'}
                        isMobile={this.props.isMobile}
                      />
                    </div>


                    <div className="col-md-6" >
                    <StatsCard
                        statValue={this.props.MawbDetailData[0].ActualDepartToArrival}
                        statLabel={'Depart To Arrival (Days)'}
                        icon={<i className="fa fa-plane" />}
                        backColor={'green'}
                        isMobile={this.props.isMobile}
                      />                            
                    </div>


              </div>

                    <div className="row" style={{marginBottom: '0px', overflowX: 'hidden!important'}}>
                    <div className="col-md-6">
                    <header className="panel-heading">
                    {'MAWB Details'}
                    </header>

                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important'}}>
                        <Table>
                            <TableBody >

                                <tr key={'1'} >
                                <td>MAWB: <strong>{this.props.MawbDetailData[0].MAWB}</strong></td>
                                </tr>
                                <tr key={'2'} >
                                <td>HAWB: <strong>{this.props.MawbDetailData[0].HAWB}</strong></td>
                                </tr>
                                <tr key={'3'} >
                                <td>Total Containers: <strong>{this.props.MawbDetailData[0].TotContainers}</strong></td>
                                </tr>
                                <tr key={'4'} >
                                <td>Total Packages: <strong>{this.props.MawbDetailData[0].TotPackages}</strong></td>
                                </tr>
                                <tr key={'6'} >
                                <td>Haz Mat Package Count: <strong>{this.props.MawbDetailData[0].HazMatCount}</strong></td>
                                </tr>
                                <tr key={'7'} >
                                <td className="row" style={{marginLeft: '1px'}}> Flags:   &nbsp; { gbFlagFormatter('x', this.props.MawbDetailData[0]) } </td>
                                </tr>


                            </TableBody>
                        </Table>
                    </div>

                    </div>


                    <div className="col-md-6">
                    <header className="panel-heading">
                    {'Flight Details'}
                    </header>
                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important'}}>
                        <Table>
                            <TableBody >

                                <tr key={'1'} >
                                <td>Airline Carrier: <strong>{this.props.MawbDetailData[0].AirlineCarrier}</strong></td>
                                </tr>
                                <tr key={'2'} >
                                <td>Flight No: <strong>{this.props.MawbDetailData[0].FlightNo}</strong></td>
                                </tr>



                                <tr key={'4'} >
                                <td>Flight Depart: <strong>{this.props.MawbDetailData[0].FlightDepartDT}</strong></td>
                                </tr>


                                <tr key={'5'} >
                                <td>Flight Arrival: <strong>{this.props.MawbDetailData[0].FlightArrivalDT}</strong></td>
                                </tr>

                                <tr key={'6'} >
                                <td>Ship To Airport: <strong>{this.props.MawbDetailData[0].ShipToAirport}</strong></td>
                                </tr>
                                <tr key={'7'} >
                                <td>Ship To Country: <strong>{this.props.MawbDetailData[0].ShipToCountry}</strong></td>
                                </tr>

                            </TableBody>
                        </Table>
                    </div>

                    </div>

                  </div>

                </section>
        }







      let mcDiv;
      if (this.props.isMCDataLoading) {
          mcDiv=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.MCDetailData) !== 'undefined' && this.props.MCDetailData != null) {
          let mc;


            //mobile view
              if(this.props.isMobile){
                mc =
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={ this.props.MCDetailData }
                    columns={ mobileMCColumns }
                    >
                    {
                      props => (
                        <div>
                          <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } expandRow={ expandMCRow } />
                        </div>
                      )
                    }
                  </ToolkitProvider>
              }
              //desktop view
              else{
                mc =

                  <ToolkitProvider
                    keyField="UniqueID"
                    data={ this.props.MCDetailData }
                    columns={ mcColumns }
                    exportCSV
                    >
                    {
                      props => (
                        <div>
                            <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'MC_'+ currentDateTime}>
                                <ExcelSheet data={this.props.MCDetailData} name="MC">
                                <ExcelColumn label="Master Carton" value="Barcode"/>
                                <ExcelColumn label="Package Count" value="CartonCount"/>
                                <ExcelColumn label="Sum (Unit Count)" value="SumUnitCount"/>
                                <ExcelColumn label="Weight (kgs)" value="Weight"/>
                                <ExcelColumn label="Inbd Manifest to Depart (Avg/Max)" value="AvgMaxManToDepartActual"/>
                                </ExcelSheet>
                            </ExcelFile>
                          {/* <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> */}
                          <hr />
                          <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  expandRow={ expandMCRow } />
                        </div>
                      )
                    }
                  </ToolkitProvider>
              }

              
              mcDiv = 
              <section className="panel">
              <header className="panel-heading">
                {'Master Cartons'}
              </header>
              <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5', cursor: 'pointer', overflowX:'hidden'}}>
    
                { mc }
    
              </div>
              </section>



        }
        else {
          mcDiv =
          <div></div>
        }     






      return(
        <div>
            {mawbDet}

            <GBMicomEvents  
              isMobile = {this.props.isMobile}
              isDataLoading = {this.props.isJobEventsDataLoading}
              userGroupName = {this.props.userSignInData.GroupName}
              inputData = {this.props.JobEventsData}
            />

            {mcDiv}
        </div>
      );

    }
  }
  

  export default withRouter(GBMawbDetails);
  
