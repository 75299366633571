import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import DatePicker from "react-datepicker"; //https://reactdatepicker.com/

const DateFilter = ({
    labelText,
    placeholderText,
    selectedDate,
    selectsStartOrEnd,
    startDate,
    endDate,
    onChange,
    minDate,
    disabled,
    isMobile,
    isClearable
    }) => (

        <div>
        {(() => {
            switch (isMobile) {

              case false:   return     (

                <div style={{marginLeft: "auto",  marginRight: "auto", overflow: "visible"}}>  
                        {labelText} <DatePicker
                            placeholderText={placeholderText}
                            selected={selectedDate}
                            {...selectsStartOrEnd}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onChange}
                            minDate={minDate}
                            disabled={disabled}
                            isClearable={isClearable}
                        />
                </div>

              );
              case true: return (

                <div style={{marginLeft: "auto",  marginRight: "auto", overflow: "visible"}}>  
                        {labelText} <DatePicker
                            placeholderText={placeholderText}
                            selected={selectedDate}
                            {...selectsStartOrEnd}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onChange}
                            minDate={minDate}
                            disabled={disabled}
                            isClearable={isClearable}
                        />
                </div>

              );
              default: return <div style={{marginLeft: "auto",  marginRight: "auto", overflow: "visible"}}>  
                                      {labelText} <DatePicker
                                          placeholderText={placeholderText}
                                          selected={selectedDate}
                                          {...selectsStartOrEnd}
                                          startDate={startDate}
                                          endDate={endDate}
                                          onChange={onChange}
                                          minDate={minDate}
                                          disabled={disabled}
                                          isClearable={isClearable}
                                      />
                              </div>;
            }
          })()}
        </div>

);

DateFilter.propTypes = {
    labelText:                      PropTypes.string, //"Start:" or "End:"
    placeholderText:                PropTypes.string,
    selectedDate:                   PropTypes.instanceOf(Date),
    selectsStartOrEnd:              PropTypes.string, //"selectsStart" or "selectsEnd"
    startDate:                      PropTypes.instanceOf(Date),
    endDate:                        PropTypes.instanceOf(Date),
    onChange:                       PropTypes.func,
    minDate:                        PropTypes.string,
    disabled:                       PropTypes.bool,
    isMobile:                       PropTypes.bool,
    isClearable:                    PropTypes.bool
};

export default DateFilter;

