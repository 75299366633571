import {connect} from 'react-redux';
import React, {Component} from 'react';
import ServiceIssues from './ServiceIssues'
import * as actions from '../../../redux/actions/googleActions';

//add props from reducer here
const mapStateToProps = store =>
({
    // kpiOverviewData:                store.reportingReducer.kpiOverviewData,
    // isKPIOverviewLoading:           store.reportingReducer.isKPIOverviewLoading,

    // kpiDetailData:                  store.reportingReducer.kpiDetailData,
    // isKPIDetailLoading:             store.reportingReducer.isKPIDetailLoading,
    // isKPILaneLoading:               store.reportingReducer.isKPILaneLoading,
    // kpiCarrierCalc:                 store.reportingReducer.kpiCarrierCalc,
    // laneCalc:                       store.reportingReducer.laneCalc,

    // isAlertKpiTrendLoading:         store.alertKpiTrendReducer.isAlertKpiTrendLoading,
    // alertKpiTrendData:              store.alertKpiTrendReducer.alertKpiTrendData,

    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail,

    // openServiceIssueData: [],
    // isOpenServiceIssueLoading: true,

    openServiceIssueData:               store.shipmentDetailsReducer.openServiceIssueData,
    isOpenServiceIssueLoading:          store.shipmentDetailsReducer.isOpenServiceIssueLoading,
    
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    // getKpiOverviewData:( _db, _email, _startDT, _endDT, _forwarder, _mode  ) => //get KPI overview data
    // {
    //     dispatch( actions.getKpiOverviewData( _db, _email, _startDT, _endDT, _forwarder, _mode ) )
    // },
    

    getShipmentTrackerDataByJobID:(  _db, _email, _housebill ) =>  //open ship details page
    {
        dispatch( actions.getShipmentTrackerDataByJobID( _db, _email, _housebill ) )
    },


    getShipmentSearchData:( _db, _email, _housebill ) =>
    {
        dispatch( actions.getShipmentSearchData( _db, _email, _housebill ) )
    },

    // getAlertKpiTrendData:( _db, _email, _mode, _forwarder, _service, _lane ) =>
    // {
    //     dispatch( actions.getAlertKpiTrendData( _db, _email, _mode, _forwarder, _service, _lane ) )
    // },

    postServiceIssueFromView:( _db, _email, _jobid, _serviceissueid ) =>
    {
        dispatch( actions.postServiceIssueFromView( _db, _email, _jobid, _serviceissueid ) )
    },

    getOpenServiceIssues:( _db, _email, _jobid, _housebill ) =>
    {
        dispatch( actions.getOpenServiceIssues( _db, _email, _jobid, _housebill ) )
    },


});


class ServiceIssuesContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{

        this.props.getOpenServiceIssues ( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null )


        // this.props.getKpiOverviewData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null );

        // this.props.getAlertKpiTrendData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null );

	}
	
    render()
    {
    	return (
            <ServiceIssues 
            // kpiOverviewData={ this.props.kpiOverviewData }
            // isKPIOverviewLoading={ this.props.isKPIOverviewLoading }
            // kpiDetailData={ this.props.kpiDetailData }
            // isKPIDetailLoading={ this.props.isKPIDetailLoading }
            // kpiCarrierCalc={ this.props.kpiCarrierCalc }
            // laneCalc={ this.props.laneCalc }
            // isKPILaneLoading={ this.props.isKPILaneLoading }
            
            // getKpiOverviewData={ this.props.getKpiOverviewData }



         

            getShipmentTrackerDataByJobID={this.props.getShipmentTrackerDataByJobID}

            // isAlertKpiTrendLoading={ this.props.isAlertKpiTrendLoading }
            // alertKpiTrendData={ this.props.alertKpiTrendData }
            // getAlertKpiTrendData={this.props.getAlertKpiTrendData}



            openServiceIssueData={ this.props.openServiceIssueData }
            isOpenServiceIssueLoading={ this.props.isOpenServiceIssueLoading }


            userSignInData={ this.props.userSignInData }
            currentUserEmail={this.props.currentUserEmail}

            getShipmentSearchData={this.props.getShipmentSearchData}
            postServiceIssueFromView={this.props.postServiceIssueFromView}
            getOpenServiceIssues={this.props.getOpenServiceIssues}


            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceIssuesContainer);





































// import {connect} from 'react-redux';
// import React, {Component} from 'react';
// import ServiceIssues from './ServiceIssues'
// import * as actions from '../../../redux/actions/googleActions';


// //add props from reducer here
// const mapStateToProps = store =>
// ({



//     currentUserEmail:             store.userServicesReducer.currentUserEmail
// });

// //add actions here
// const mapDispatchToProps = dispatch =>
// ({



// });


// class ServiceIssuesContainer extends Component
// {
// 	constructor(props)
// 	{
// 		super(props);
// 	}
	
// 	componentDidMount()
// 	{
        
// 	}
	
//     render()
//     {
//     	return (
//             <ServiceIssues 

//             currentUserEmail={this.props.currentUserEmail}
//             />
//         )
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ServiceIssuesContainer);




