//TODO make dynamic

////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import validator from 'validator'
////local imports
import {
  Table,
  TableBody
}                         from '../../ComponentsGeneral/table'
import {
    buttonDeleteAccount,
    userManagementAdmin,
    trueOrFalse,
    userManagementAdminMobile
} from '../../../resources/logic/formatting';
import Button from 'react-bootstrap/Button'
// import Amplify from "@aws-amplify/core";
// import Auth from '@aws-amplify/auth';
// import { awsCogConfig } from '../../app/awsCogConfig'



const required = value => (value ? undefined : 'Required')
const email = value =>
  validator.isEmail(value) ? undefined : 'Not Valid Email'

const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));


  class UserManagement extends PureComponent {
    constructor( props )
    {
      super( props );
      this.state = {
        addUserOpen: false,
        
        addUserFirst: '',
        addUserLast: '',
        addUserEmail: '',
        addUserAdmin: null,
        addUserBusinessUnitName: null,
        addUserGroupName: null,
        addUserProdGoogle: null,
        addUserProdZU: null,
        addUsercbu1: null,
        addUsercbu2: null,
        addUsercbu4: null,
        addUsercbu5: null,
        addUsercbu6: null,
        addUsercbu7: null


      }

      this.onSetAddUserOpen = this.onSetAddUserOpen.bind(this);


      // this.onSetaddUserFirst = this.onSetaddUserFirst.bind(this);
      // this.onSetaddUserLast = this.onSetaddUserLast.bind(this);
      // this.onSetaddUserEmail = this.onSetaddUserEmail.bind(this);
      this.onSetaddUserAdmin = this.onSetaddUserAdmin.bind(this);
      this.onSetaddUserBusinessUnitName = this.onSetaddUserBusinessUnitName.bind(this);
      this.onSetaddUserGroupName = this.onSetaddUserGroupName.bind(this);
      this.onSetaddUserProdGoogle = this.onSetaddUserProdGoogle.bind(this);
      this.onSetaddUserProdZU = this.onSetaddUserProdZU.bind(this);
      this.onSetaddUsercbu1 = this.onSetaddUsercbu1.bind(this);
      this.onSetaddUsercbu2 = this.onSetaddUsercbu2.bind(this);
      this.onSetaddUsercbu4 = this.onSetaddUsercbu4.bind(this);
      this.onSetaddUsercbu5 = this.onSetaddUsercbu5.bind(this);
      this.onSetaddUsercbu6 = this.onSetaddUsercbu6.bind(this);
      this.onSetaddUsercbu7 = this.onSetaddUsercbu7.bind(this);
    }

    onSetAddUserOpen(open) {
      this.setState({ addUserOpen: open });
    }
    
    onChange = (e) => {
      e.preventDefault();
      this.setState({ [e.target.name]: e.target.value });
  
    }


    // onSetaddUserFirst(value) {
    //   this.setState({ addUserFirst: value });
    // }
    // onSetaddUserLast(value) {
    //   this.setState({ addUserLast: value });
    // }
    // onSetaddUserEmail(value) {
    //   this.setState({ addUserEmail: value });
    // }
    onSetaddUserAdmin(event) {
      this.setState({ addUserAdmin: event.target.value });
    }
    onSetaddUserBusinessUnitName(event) {
      this.setState({ addUserBusinessUnitName: event.target.value });
    }
    onSetaddUserGroupName(event) {
      this.setState({ addUserGroupName: event.target.value });
    }
    onSetaddUserProdGoogle(event) {
      this.setState({ addUserProdGoogle: event.target.value });
    }
    onSetaddUserProdZU(event) {
      this.setState({ addUserProdZU: event.target.value });
    }
    onSetaddUsercbu1(event) {
      this.setState({ addUsercbu1: event.target.value });
    }
    onSetaddUsercbu2(event) {
      this.setState({ addUsercbu2: event.target.value });
    }
    onSetaddUsercbu4(event) {
      this.setState({ addUsercbu4: event.target.value });
    }
    onSetaddUsercbu5(event) {
      this.setState({ addUsercbu5: event.target.value });
    }
    onSetaddUsercbu6(event) {
      this.setState({ addUsercbu6: event.target.value });
    }
    onSetaddUsercbu7(event) {
      this.setState({ addUsercbu7: event.target.value });
    }


  
    onClickEditPermissions = (permission, permValue, emailToUpdate) => {
      var newPermValue = null;

      if(permValue === true){
        newPermValue = false
      }
      else if(permValue === false){
        newPermValue = true 
      }
      else if(permValue === 1){
        newPermValue = 0
      }
      else if(permValue === 0){
        newPermValue = 1
      }

      if(permission === 'GroupName'){
        if (window.confirm('Update User to ' + permValue + "?"))  {
          this.props.postUserPermissions(this.props.currentUserEmail, emailToUpdate, permission, permValue)
        }
        return;
      }
      if(permission === 'BusinessUnitName'){
        if (window.confirm('Update User to ' + permValue + "?"))  {
          this.props.postUserPermissions(this.props.currentUserEmail, emailToUpdate, permission, permValue)
        }
        return;
      }
      if(permission === 'Admin'){
        if (window.confirm('Update Admin Rights?'))  {
          this.props.postUserPermissions(this.props.currentUserEmail, emailToUpdate, permission, newPermValue)
        }
        return;
      }

      this.props.postUserPermissions(this.props.currentUserEmail, emailToUpdate, permission, newPermValue)

    }


    addUser = () => {
      this.onSetAddUserOpen(true)
    }

    async  waitUserAdd (){
      await waitFor(2400);
    }

    cancelNewUser = () => {
      this.onSetAddUserOpen(false)
    }
    
    saveNewUser = () => {

      // //validate values
      // this.state.addUserFirst,
      //   this.state.addUserLast,
      //   this.state.addUserEmail,
      //   this.state.addUserAdmin,
      //   this.state.addUserBusinessUnitName,
      //   this.state.addUserGroupName,
      //   this.state.addUserProdGoogle,
      //   this.state.addUserProdZU,
      //   this.state.addUsercbu1,
      //   this.state.addUsercbu2,
      //   this.state.addUsercbu4,
      //   this.state.addUsercbu5,
      //   this.state.addUsercbu6,
      //   this.state.addUsercbu7
      // )

      if(this.state.addUserEmail === '' || this.state.addUserAdmin === null ){
        window.confirm('Please complete all values')
        return
      }


        //add new user
        this.props.addNewUser(this.state.addUserFirst, this.state.addUserLast, this.state.addUserEmail.toLowerCase(), 1, this.state.addUserAdmin, 0 )

        this.waitUserAdd();
        this.waitUserAdd();
        this.waitUserAdd();
        //configure user permissions
        // this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'Admin', this.state.addUserAdmin)
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'GroupName', this.state.addUserGroupName)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'BusinessUnitName', this.state.addUserBusinessUnitName)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'ProdZU', this.state.addUserProdZU)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'ProdGoogle', this.state.addUserProdGoogle)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'cbu1', this.state.addUsercbu1)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'cbu2', this.state.addUsercbu2)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'cbu4', this.state.addUsercbu4)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'cbu5', this.state.addUsercbu5)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'cbu6', this.state.addUsercbu6)
        this.waitUserAdd();
        this.props.postUserPermissions(this.props.currentUserEmail, this.state.addUserEmail.toLowerCase(), 'cbu7', this.state.addUsercbu7)
        this.waitUserAdd();
        

        this.onSetAddUserOpen(false)

        this.waitUserAdd();
        this.waitUserAdd();
        this.waitUserAdd();
        this.props.getUserAccounts( this.props.currentUserEmail );



    }


  //   onRemoveAccount() {
  //     Auth
  //         .currentAuthenticatedUser()
  //         .then((user: CognitoUser) => new Promise((resolve, reject) => {
  //             user.deleteUser(error => {
  //                 if (error) {
  //                     return reject(error);
  //                 }
  //                 if (this.props.onSessionChange) {               
  //                     this.props.onSessionChange();
  //                 }
  //                 document.location.href = "/login";
                  
  //                 resolve();
  //             });
  //         }))
  //         .catch(this.onError);
  // }

  
  // deleteUser() {
  //   Amplify.configure({
  //     Auth: {
  //       mandatorySignIn: true,
  //       region: appConfig.cognitoLogin.Auth.region,
  //       userPoolId: appConfig.cognitoLogin.Auth.UserPoolId,
  //       identityPoolId: appConfig.cognitoLogin.Auth.IdentityPoolId,
  //       userPoolWebClientId: appConfig.cognitoLogin.Auth.ClientId
  //     }});

  //     Auth.deleteUser({
  //       username: email,
  //       password: passw,
  //       attributes: {
  //         email: email
  //       }
  //     })
  //     .then(data => console.log(data),
  //     this.props.postDeleteUser(row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 ))
  //     .catch(err => console.log(err));
  // }




    render() {
      const {
        addUserFirst,
        addUserLast,
        addUserEmail,
      } = this.state;



        const uniqueBy = prop => list => {
            const uniques = {}
            return list.reduce(
                (result, item) => {
                    if (uniques[item[prop]]) return result
                    uniques[item[prop]] = item
                    return [...result, item]
                },
                [],
            )
            }
            
        const uniqueByUserID = uniqueBy('UserID')



      const userAccountColumns = [{
        dataField: 'GroupName',
        text: 'Group',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'FirstName',
        text: 'First Name',
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'LastName',
        text: 'Last Name',
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'EmailAddr',
        text: 'Email',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '21%' , textAlign: 'center' };
        },
        sort: true
    },{
        dataField: 'Admin',
        text: 'Admin',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        formatter: userManagementAdmin,
        sort: true

      },{
        dataField: 'LastSignedInDT',
        text: 'Last Login',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'buttonDelete',
        text: '',
        align: 'center',
        formatter: buttonDeleteAccount,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            if (window.confirm('Delete User Account?')) 
              {
                // Amplify.configure({
                //   Auth: {
                //     mandatorySignIn: true,
                //     region: awsCogConfig.cognitoLogin.Auth.region,
                //     userPoolId: awsCogConfig.cognitoLogin.Auth.UserPoolId,
                //     identityPoolId: awsCogConfig.cognitoLogin.Auth.IdentityPoolId,
                //     userPoolWebClientId: awsCogConfig.cognitoLogin.Auth.ClientId
                //   }});
            
                //   Auth.deleteUser({
                //     username: row.EmailAddr,
                //     // password: passw,
                //     attributes: {
                //       email: row.EmailAddr
                //     }
                //   })
                //   .then(data => console.log(data),
                //   this.props.postDeleteUser(row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 ))
                //   .catch(err => console.log(err));



                this.props.postDeleteUser(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 )



              }



          }
      }
      }
      ]




      const userAccountColumnsMobile = [{
        dataField: 'GroupName',
        text: 'Group',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        sort: true
    }, {
        dataField: 'EmailAddr',
        text: 'Email',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '21%' , textAlign: 'center' };
        },
        formatter: userManagementAdminMobile,
        sort: true
      // },{

      //   dataField: 'buttonDelete',
      //   text: '',
      //   align: 'center',
      //   formatter: buttonDeleteAccount,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '10%', textAlign: 'center' };
      //     },
      //   events: {
      //     onClick: (e, column, columnIndex, row, rowIndex) => { 
      //       if (window.confirm('Delete User Account?')) 
      //         {
      //           this.props.postDeleteUser(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 )
      //         }
      //     }
      // }
      }
      ]



      const expandUserRow = {
        renderer: row => (
          <div style={{marginLeft: '10px', marginRight: '10px', cursor: 'default'}} >
    
          <div
            className="row">
      
        <div className="col-md-12">
          <section className="panel">
          <header className="panel-heading">
          {'Permissions: ' + row.EmailAddr}
        </header>            
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5', overflowX: 'hidden'}}>
          <Table>
          <TableBody>
            <tr key={'2'} >
              <td>Admin: <strong style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('Admin', row.Admin, row.EmailAddr)} >{trueOrFalse(row.Admin)}</strong></td>
            </tr>
            <tr key={'3'} >
              <td>Organization: <strong style={{ }}  >{row.GroupName}</strong>
            
              {(() => {
                switch (this.props.userSignInData.GroupName) {
                  case 'OMNI':   return     (
                    <div>

                      <tr>
                      &nbsp; &nbsp; &nbsp;  Update: &nbsp;
                      <input type="radio" value="OMNI" name="Organization" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('GroupName', 'OMNI', row.EmailAddr)}/> OMNI &nbsp;
                      <input type="radio" value="Google" name="Organization" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('GroupName', 'Google', row.EmailAddr)}/> Google &nbsp;
                      <input type="radio" value="Zulily" name="Organization" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('GroupName', 'Zulily', row.EmailAddr)}/> Zulily &nbsp;
                      </tr>

                    </div>
                  );
                  case 'Demo':   return     (
                    <div>

                      <tr>
                      &nbsp; &nbsp; &nbsp;  Update: &nbsp;
                      <input type="radio" value="OMNI" name="Organization" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('GroupName', 'OMNI', row.EmailAddr)}/> Evolve &nbsp;
                      <input type="radio" value="Google" name="Organization" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('GroupName', 'Google', row.EmailAddr)}/> Inbound &nbsp;
                      <input type="radio" value="Zulily" name="Organization" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('GroupName', 'Zulily', row.EmailAddr)}/> D2C &nbsp;
                      </tr>

                    </div>
                  );
                  case 'Google':   return    (
                    <div>
                  </div>
                  )            
                  
                  case 'Zulily':   return    (
                    <div>
                  </div>
                  )      
                  default: return ;
                }
              })()}

              </td>

            </tr>
            <tr key={'4'} >
              <td>Business Unit: <strong style={{ }}  >{row.BusinessUnitName}</strong>
              
              <tr>
              &nbsp; &nbsp; &nbsp;   Update: &nbsp;
              <input type="radio" value="Operations" name="BusinessUnitName" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('BusinessUnitName', 'Operations', row.EmailAddr)}/> Operations &nbsp;
              <input type="radio" value="Sales" name="BusinessUnitName" style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('BusinessUnitName', 'Sales', row.EmailAddr)}/> Sales &nbsp;
              </tr>

              </td>
            </tr>

            <tr key={'5'} >
              {(() => {
                  switch (this.props.userSignInData.GroupName) {
                    case 'Demo':   return     (
                      <td>D2C Dashboard: <strong style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('ProdZU', row.ProdZU, row.EmailAddr)} >{trueOrFalse(row.ProdZU)}</strong></td>
                    );
                    default: return (
                      <td>Zulily Dashboard: <strong style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('ProdZU', row.ProdZU, row.EmailAddr)} >{trueOrFalse(row.ProdZU)}</strong></td>
                    );
                  }
                })()}
            </tr>

            <tr key={'6'} >
            {(() => {
                  switch (this.props.userSignInData.GroupName) {
                    case 'Demo':   return     (
                      <td>Inbound Dashboard: <strong style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('ProdGoogle', row.ProdGoogle, row.EmailAddr)} >{trueOrFalse(row.ProdGoogle)}</strong></td>
                    );
                    default: return (
                      <td>Google Dashboard: <strong style={{cursor: 'pointer'}} onClick={(e) => this.onClickEditPermissions('ProdGoogle', row.ProdGoogle, row.EmailAddr)} >{trueOrFalse(row.ProdGoogle)}</strong></td>
                    );
                  }
                })()}
            </tr>

            <tr key={'7'} >
              {(() => {
                switch (row.ProdGoogle) {
                  case 1:   return     (

                    <div>
                      {(() => {
                        switch (this.props.userSignInData.GroupName) {
                          case 'Demo':   return     (
                        
                            <div>
                            <tr>
                            <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu13', row.cbu1, row.EmailAddr)} > TEST DEMO - DCBYPASS:   &nbsp; { trueOrFalse(row.cbu1) } </td>
                            </tr>
                            <tr>
                            <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu8', row.cbu2, row.EmailAddr)} > DEMO - DCBYPASS:   &nbsp; { trueOrFalse(row.cbu2) } </td>
                            </tr>
                            <tr>
                            <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu9', row.cbu4, row.EmailAddr)} > DEMO PRE-PRODUCTION:   &nbsp; { trueOrFalse(row.cbu4) } </td>
                            </tr>
                            <tr>
                            <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu11', row.cbu5, row.EmailAddr)} > DEMO, LLC:   &nbsp; { trueOrFalse(row.cbu5) } </td>
                            </tr>
                            <tr>
                            <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu12', row.cbu6, row.EmailAddr)} > DEMO- RL:   &nbsp; { trueOrFalse(row.cbu6) } </td>
                            </tr>
                            <tr>
                            <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu10', row.cbu7, row.EmailAddr)} > DEMO, INC.:   &nbsp; { trueOrFalse(row.cbu7) } </td>
                            </tr>
                          </div>
                    );

                          default: return (
                          <div>
                          <tr>
                          <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu1', row.cbu1, row.EmailAddr)} > TEST GOOGLE - DCBYPASS:   &nbsp; { trueOrFalse(row.cbu1) } </td>
                          </tr>
                          <tr>
                          <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu2', row.cbu2, row.EmailAddr)} > GOOGLE - DCBYPASS:   &nbsp; { trueOrFalse(row.cbu2) } </td>
                          </tr>
                          <tr>
                          <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu4', row.cbu4, row.EmailAddr)} > GOOGLE PRE-PRODUCTION:   &nbsp; { trueOrFalse(row.cbu4) } </td>
                          </tr>
                          <tr>
                          <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu5', row.cbu5, row.EmailAddr)} > GOOGLE, LLC:   &nbsp; { trueOrFalse(row.cbu5) } </td>
                          </tr>
                          <tr>
                          <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu6', row.cbu6, row.EmailAddr)} > GOOGLE- RL:   &nbsp; { trueOrFalse(row.cbu6) } </td>
                          </tr>
                          <tr>
                          <td className="row" style={{ paddingLeft: '80px', cursor: 'pointer' }} onClick={(e) => this.onClickEditPermissions('cbu7', row.cbu7, row.EmailAddr)} > NEST LABS, INC.:   &nbsp; { trueOrFalse(row.cbu7) } </td>
                          </tr>
                        </div>
                          )
                        ;
                        }
                      })()}   

                    </div>


                  );
                  case 0:   return                  

                  default: return ;
                }
              })()}
            </tr>

          </TableBody>
          </Table>
        </div>
      </section>
    </div>

              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: false
      }


      let addUserDiv;
      if(this.state.addUserOpen === true){
        addUserDiv=
        <div style={{marginLeft: '10px', marginRight: '10px', cursor: 'default'}} >
        <div
          className="row">
          <div className="col-md-12">
            <section className="panel">
            <header className="panel-heading">
            {'Add new user'}
          </header>            
          <div className="panel-body table-responsive"
          style={{backgroundColor: '#FFF', overflowX: 'hidden'}}>
            <Table>
            <TableBody>

              <tr key={'1'} style={{ textAlign:'center'}}>       
                <input
                    type="text"
                    name="addUserFirst"
                    className="form-control"
                    placeholder='First Name'
                    value={addUserFirst}
                    onChange={this.onChange}
                    // validate={[required]}
                    style={{ marginLeft: '15px', marginTop: '15px', width: '400px'}}
                  />
              </tr>

              <br/>

              <tr key={'2'} >       
                <input
                    type="text"
                    name="addUserLast"
                    className="form-control"
                    placeholder='Last Name'
                    value={addUserLast}
                    onChange={this.onChange}
                    // validate={[required]}
                    style={{ marginLeft: '15px', marginRight: '15px', width: '400px'}}
                  />
              </tr>

              <br/>

              <tr key={'3'} >       
                <input
                    type="email"
                    name="addUserEmail"
                    className="form-control"
                    placeholder='Email Address'
                    value={addUserEmail}
                    onChange={this.onChange}
                    // validate={[required, email]}
                    style={{ marginLeft: '15px', marginRight: '15px', width: '400px'}}
                  />
              </tr>

              <br/>


              <tr key={'4'} >
                <td>Admin: <strong style={{cursor: 'pointer'}}  ></strong>
                <tr>
                <div onChange={this.onSetaddUserAdmin.bind(this)}>
                  <input type="radio" value='false' name="addAdmin" style={{cursor: 'pointer'}}  /> False &nbsp;
                  <input type="radio" value='true' name="addAdmin"style={{cursor: 'pointer'}} /> True &nbsp;
                </div> 
                </tr>
                </td>
              </tr>

              <tr key={'5'} >
                <td>Organization: <strong style={{cursor: 'pointer'}}  ></strong>
                <tr>

                {(() => {
                switch (this.props.userSignInData.GroupName) {
                  case 'OMNI' || 'Demo':   return     (
                    <div>

                      <tr>
                      <div onChange={this.onSetaddUserGroupName.bind(this)}>
                      <input type="radio" value="OMNI" name="addOrganization" style={{cursor: 'pointer'}}   /> OMNI &nbsp;
                      <input type="radio" value="Google" name="addOrganization" style={{cursor: 'pointer'}} /> Google &nbsp;
                      <input type="radio" value="Zulily" name="addOrganization" style={{cursor: 'pointer'}} /> Zulily &nbsp;
                      </div>
                      </tr>

                    </div>
                  );
                  case 'Google':   return    (
                    <div onChange={this.onSetaddUserGroupName.bind(this)}>
                      <input type="radio" value="Google" name="addOrganization" style={{cursor: 'pointer'}}   /> Google &nbsp;
                  </div>
                  )            
                  
                  case 'Zulily':   return    (
                    <div onChange={this.onSetaddUserGroupName.bind(this)}>
                      <input type="radio" value="Zulily" name="addOrganization" style={{cursor: 'pointer'}}   /> Zulily &nbsp;
                  </div>
                  )      
                  default: return ;
                }
              })()}
                </tr>
                </td>
              </tr>

              <tr key={'6'} >
                <td>Business Unit: <strong style={{cursor: 'pointer'}}  ></strong>
                <tr>
                <div onChange={this.onSetaddUserBusinessUnitName.bind(this)}>
                  <input type="radio" value="Operations" name="addBU" style={{cursor: 'pointer'}}  /> Operations &nbsp;
                  <input type="radio" value="Sales" name="addBU"style={{cursor: 'pointer'}} /> Sales &nbsp;
                </div> 
                </tr>
                </td>
              </tr>


              {(() => {
                switch (this.props.userSignInData.GroupName) {
                  case 'OMNI' :   return     (
                    <div>

                      <tr key={'7'} >
                      <td>Zulily Dashboard: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUserProdZU.bind(this)}>
                        <input type="radio" value="0" name="addZuDash" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addZuDash"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'8'} >
                      <td>Google Dashboard: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUserProdGoogle.bind(this)}>
                        <input type="radio" value="0" name="addGoogleDash" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addGoogleDash"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    </div>
                  );
                  case 'Demo':   return     (
                    <div>

                      <tr key={'7'} >
                      <td>D2C Dashboard: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUserProdZU.bind(this)}>
                        <input type="radio" value="0" name="addZuDash" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addZuDash"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'8'} >
                      <td>Inbound Dashboard: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUserProdGoogle.bind(this)}>
                        <input type="radio" value="0" name="addGoogleDash" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addGoogleDash"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    </div>
                  );
                  case 'Google':   return    (
                    <tr key={'8'} >
                      <td>Google Dashboard: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUserProdGoogle.bind(this)}>
                        <input type="radio" value="0" name="addGoogleDash" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addGoogleDash"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>
                  )            
                  
                  case 'Zulily':   return    (
                    <tr key={'7'} >
                      <td>Zulily Dashboard: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUserProdZU.bind(this)}>
                        <input type="radio" value="0" name="addZuDash" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addZuDash"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>
                  )      
                  default: return ;
                }
              })()}



              {(() => {
                switch (this.props.userSignInData.GroupName) {
                  case 'OMNI':   return     (
                    <div>

                    <tr key={'8'} >
                      <td>TEST GOOGLE - DCBYPASS: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu1.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu1" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu1"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'9'} >
                      <td>GOOGLE - DCBYPASS: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu2.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu2" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu2"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'10'} >
                      <td>GOOGLE PRE-PRODUCTION: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu4.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu4" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu4"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'11'} >
                      <td>GOOGLE, LLC: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu5.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu5" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu5"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'12'} >
                      <td>GOOGLE- RL: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu6.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu6" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu6"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'13'} >
                      <td>NEST LABS, INC.: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu7.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu7" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu7"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>


                    </div>
                  );
                  case 'Demo':   return     (
                    <div>

                    <tr key={'8'} >
                      <td>TEST DEMO - DCBYPASS: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu1.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu13" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu13"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'9'} >
                      <td>DEMO - DCBYPASS: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu2.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu8" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu8"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'10'} >
                      <td>DEMO PRE-PRODUCTION: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu4.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu9" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu9"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'11'} >
                      <td>DEMO, LLC: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu5.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu11" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu11"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'12'} >
                      <td>DEMO- RL: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu6.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu12" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu12"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                    <tr key={'13'} >
                      <td>DEMO, INC.: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu7.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu10" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu10"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>


                    </div>
                  );
                  case 'Google':   return    (

                    <tr key={'9'} >
                      <td>GOOGLE - DCBYPASS: <strong style={{cursor: 'pointer'}}  ></strong>
                      <tr>
                      <div onChange={this.onSetaddUsercbu2.bind(this)}>
                        <input type="radio" value="0" name="addUsercbu2" style={{cursor: 'pointer'}}  /> False &nbsp;
                        <input type="radio" value="1" name="addUsercbu2"style={{cursor: 'pointer'}} /> True &nbsp;
                      </div> 
                      </tr>
                      </td>
                    </tr>

                  )            
                  
                  case 'Zulily':   return    (
                    <div >
                  </div>
                  )      
                  default: return ;
                }
              })()}




<div className="row" style={{marginLeft: "auto",  marginRight: "auto"}}>
                <div className="col-md-6" > 
                  <Button variant="success" size="sm" block   onClick={this.saveNewUser} >
                    Save New User
                  </Button>

                </div>
                <div className="col-md-6" > 
                  <Button variant="secondary" size="sm" block   onClick={this.cancelNewUser} >
                    Cancel
                  </Button>
                </div>
              </div>

            </TableBody>
            </Table>
          </div>
        </section>
      </div>

            </div>

            <br/>
            </div>

      }
      else{
        addUserDiv=
        <div></div>

      }
      



      let bootStrapTable;
      if(this.props.userSignInData.Admin === false) {
        bootStrapTable=
        <div align="center">
        <section className="panel">
        <header className="panel-heading">
              {'You do not have Admin Rights to View'}
            </header>
          </section>

        </div>
      } 

      else if (this.props.isUserAccountsLoading) {
        bootStrapTable=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.userAccountData) !== 'undefined' && this.props.userAccountData != null) {
        // var list2 = JSON.parse(JSON.stringify(this.props.userAccountData));
        // list2.forEach(function(x){
        //     delete x.ProductName;
        //     delete x.BusinessUnitName;
        //     delete x.CustomerBusinessUnitName;
        
        // });

            //mobile view
            if(this.props.isMobile){
                bootStrapTable =
                <div className="mobile-text-size">
                <Table >
                    <BootstrapTable 
                    keyField='EmailAddr' 
                    data={ this.props.userAccountData } 
                    // data={ uniqueByUserID(list2) } 
        

        
                    columns={ userAccountColumnsMobile } 

                    expandRow={ expandUserRow }
                    />
                </Table>
                </div>
              }
              //desktop view
              else{
                bootStrapTable =
                <Table>
                    <BootstrapTable 
                    keyField='EmailAddr' 
                    data={ this.props.userAccountData } 
                    // data={ uniqueByUserID(list2) } 
        
                    columns={ userAccountColumns } 

                    expandRow={ expandUserRow }


                    />
                </Table>
              }


      } else {
        bootStrapTable = <div></div>;
      }

      return(
          <section className="panel">
            <header className="panel-heading">
              {'User Accounts'}
            </header>
            <div style={{marginLeft: "auto",  marginRight: "auto"}}>
              <Button variant="success" size="sm" block   onClick={this.addUser} >
                Add New User
              </Button>
              {addUserDiv}
            </div>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>

              { bootStrapTable }

            </div>


          </section>

      );
    }
  }

  export default withRouter(UserManagement);
  