////npm imports
import React, {
  PureComponent
}                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DatePicker from "react-datepicker"; //https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select"; //https://sanusart.github.io/react-dropdown-select/
import * as moment from 'moment'
import randomColor from 'randomcolor' 

import queryString from 'query-string';

////local imports

// import {//valueDeliveryDayFormatter, 
//     // buttonApproveAccount, 
//     // buttonRejectAccount, 
//   // hotShipmentIcon
// } from '../../resources/logic/formatting';
import Button from 'react-bootstrap/Button'

import {KPIValueFormatter, 
buttonFormatter,
PercentFormatter,
lateDeliveryNumberFormatter,
airTransitTimeFormatter,
oceanTransitTimeFormatter,
generalModeTransitTimeFormatter
// milestoneAlertDetailsSeverityFormatter,
// milestoneCustomerAlertFormatter
} from '../../../resources/logic/formatting';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


import {
  ResponsiveContainer,
} from 'recharts';
// import { start } from 'repl';




const calStartValue = new Date();
calStartValue.setDate( calStartValue.getDate() - 180 );


class NetworkReporting extends PureComponent {




  constructor( props )
  {
    super( props );
    this.tooltip = null;
    this.tooltipLane = null;
    this.tooltipValue = null;

    this.state = {
      startDate: calStartValue,
      // startDate: moment().format('YYYY-MM-DD'),
      endDate: new Date(),
      // endDate: moment().format('YYYY-MM-DD'),
      // radioModeType: '',
      dropDownValueMeasure: '',
      dropDownValueMode: null,
      // dropDownService: '',
      // dropDownLane: '',
      dropDownValueNetwork: '',
      calendarActive: true,
      measureFilter: 1,
      networkFilter: 1
      // modeSelectDisabled: false

    };
    this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
    this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
    this.handleDropdownChangeMeasure = this.handleDropdownChangeMeasure.bind(this);
    this.handleDropdownChangeMode = this.handleDropdownChangeMode.bind(this);
    this.handleDropdownChangeNetwork = this.handleDropdownChangeNetwork.bind(this);

    this.handleChangeNetworkFilter = this.handleChangeNetworkFilter.bind(this);
    this.handleChangeMeasureFilter = this.handleChangeMeasureFilter.bind(this);

    // this.handleDropdownChangeService = this.handleDropdownChangeService.bind(this);
    // this.handleDropdownChangeLane = this.handleDropdownChangeLane.bind(this);
    this.setEventDate = this.setEventDate.bind(this);
  }


  componentDidMount() {

    //<PrivateRoute exact path="/home/reporting/:startdt?/:enddt?/:mode?/:network?/:measure?" component={reportingView} />
    const values = queryString.parse(this.props.location.search)

      // // url is 'https://www.example.com/search?id=123&type=4';
      // let url = this.props.location.search;
      // let params = queryString.parse(url);


    // if(typeof(values.startdt) !== 'undefined' && values.startdt != null){
    //   this.handleChangeStartDate( values.startdt )
    // }
    // if(typeof(values.enddt) !== 'undefined' && values.enddt != null){
    //   this.handleChangeEndDate( values.enddt )
    // }
    // [0].value
    // (this.state.dropDownValueMode[0].value == 'AIR'){
    //   [
    //     { label: "Air", value: "AIR" },
    //     { label: "Ocean", value: "OCEAN" }
    //   ];



    if(typeof(values.mode) !== 'undefined' && values.mode != null){
      // this.handleDropdownChangeMode( values.mode )  

      this.setState({ 
        dropDownValueMode: [ {label: values.mode, value: values.mode} ]
      });


    }
    if(typeof(values.network) !== 'undefined' && values.network != null ){
      // this.handleDropdownChangeNetwork( values.network )
      this.handleChangeNetworkFilter( values.network )
    }
    if(typeof(values.measure) !== 'undefined' && values.measure != null ){
      // this.handleDropdownChangeMeasure( values.measure )
      this.handleChangeMeasureFilter( values.measure )
    }


  }

  componentDidUpdate(prevProps, prevState) {

    if (prevProps.location.search !== this.props.location.search) {

      this.updateFromParam()

    }
  }


  handleChangeStartDate(date) {
    this.setState({
      startDate: date
    });
  }
  handleChangeEndDate(date) {
    this.setState({
      endDate: date
    });
  }
  handleDropdownChangeMeasure(value) {
    this.setState({ 
      dropDownValueMeasure: value
    });
  }
  handleDropdownChangeMode(value) {
    this.setState({ 
      dropDownValueMode: value
    });
  }
  handleDropdownChangeNetwork(value) {
    // var newModeFilter = false
    // if(typeof(value[0]) !== 'undefined' && value[0] != null) {

    //   if (value[0].value == 1) {
    //     newModeFilter = true

    //   }
    //   else {
    //     newModeFilter = false
    //   }
    // }
      this.setState({ 
        dropDownValueNetwork: value
        // modeSelectDisabled: newModeFilter
      });
    }
  handleChangeNetworkFilter(value) {
    this.setState({ 
      networkFilter: value
    });
  }
  handleChangeMeasureFilter(value) {
    this.setState({ 
      measureFilter: value
    });
  }

  setEventDate(event) {
    if(event.target.value === 'intransit') {
      this.setState({ 
        calendarActive: true
      });
    }
    else {
      this.setState({ 
        calendarActive: false
      });
    }

    this.setState({ 
      radioDateType: event.target.value
    });
  }

  updateFromParam()  {

////////////////////////////////////////////////////////////////////////////////////////////      
    //use props not state
    // https://stackoverflow.com/questions/38974006/dropdown-react-router-and-back-button
////////////////////////////////////////////////////////////////////////////////////////////

    var paramStartDT = null
    var paramEndDT = null
    var paramMode = null
    var paramNetwork = 1
    var paramMeasure = 1


    //<PrivateRoute exact path="/home/reporting/:startdt?/:enddt?/:mode?/:network?/:measure?" component={reportingView} />
    const values = queryString.parse(this.props.location.search)

      // // url is 'https://www.example.com/search?id=123&type=4';
      // let url = this.props.location.search;
      // let params = queryString.parse(url);


    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.network) !== 'undefined' && values.network != null ){
        paramNetwork = values.network
    }
    if(typeof(values.measure) !== 'undefined' && values.measure != null ){
        paramMeasure = values.measure
    }

    this.props.getNetworkReportingData( this.props.userSignInData.DBName, this.props.currentUserEmail, paramStartDT, paramEndDT, paramNetwork, paramMeasure, paramMode );

  }


  clickApplyFilter( e ) {

    var modeFilter = null;
    var updateMeasure = 1;
    var updateNetwork = 1;

    if (typeof(this.state.dropDownValueMeasure[0]) !== 'undefined' && this.state.dropDownValueMeasure[0] != null)
    {

      // measureFilter = this.state.dropDownValueMeasure[0].value
      updateMeasure = this.state.dropDownValueMeasure[0].value
      
      this.handleChangeMeasureFilter(updateMeasure)

      // this.setState({ 
      //   measureFilter: this.state.dropDownValueMeasure[0].value
      // });

    }

    if (typeof(this.state.dropDownValueNetwork[0]) !== 'undefined' && this.state.dropDownValueNetwork[0] != null)
    {

      updateNetwork = this.state.dropDownValueNetwork[0].value
      // networkFilter = this.state.dropDownValueNetwork[0].value
      this.handleChangeNetworkFilter(updateNetwork)
      // this.setState({ 
      //   networkFilter: this.state.dropDownValueNetwork[0].value
      // });
  
    }

    if (typeof(this.state.dropDownValueMode[0]) !== 'undefined' && this.state.dropDownValueMode[0] != null)
    {

      modeFilter = this.state.dropDownValueMode[0].value
      
    }


    this.props.history.push({
      search: 'startdt=' + "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'" + '&' +
      'enddt=' + "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'" + '&' +
      'mode=' + modeFilter + '&' +
      'network=' + updateNetwork + '&' +
      'measure=' + updateMeasure 
      }); 


      // // url is 'https://www.example.com/search?id=123&type=4';
      // let url = this.props.location.search;
      // let params = queryString.parse(url);




// //////////
//       // this.props.getNetworkReportingData( this.props.currentUserEmail, "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'", "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'", updateNetwork, updateMeasure, modeFilter )
//       if(this.props.location.pathname === "/home/reporting") {
//         // this.props.openShipDetails(paramjobid, row);
//         this.props.history.push({
//         pathname: 'reporting/',
//         search: 'startdt=' + "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'" + '/?' +
//         'enddt=' + "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'" + '/?' +
//         'mode=' + modeFilter + '/?' +
//         'network=' + updateNetwork + '/?' +
//         'measure=' + updateMeasure 
//         }); 
//       }

//       // search: 'housebill=' + row.HouseBill

//       else{
//         this.props.history.push({
//           search: 'startdt=' + "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'" + '&' +
//           'enddt=' + "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'" + '&' +
//           'mode=' + modeFilter + '&' +
//           'network=' + updateNetwork + '&' +
//           'measure=' + updateMeasure 
//           }); 

//       }
// ///////////////




  }







  customMouseOver = (e, name, value) => {

    // let x = Math.round(e.cx);
    // let y = Math.round(e.cy);
    this.tooltip.style.opacity = "1";
    // this.tooltip.style.transform = `translate(${x - 120}px, ${y - 140}px)`;
    this.tooltip.style.transform = `translate(10px,-80px)`;
    this.tooltipLane.innerHTML = name;
    this.tooltipValue.innerHTML = e.payload[name];


  };

  over = e => {
    this.tooltip.style.opacity = "0";
  };



  // _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid


  onClickModeTransitTime = (e, column, columnIndex, row, rowIndex, mode) => {

    var dt = new Date(row.Date);
    dt.setDate( dt.getDate() + 6 );

    var endDT = moment(dt).format('YYYY-MM-DD')
    var startDT = moment(row.Date).format('YYYY-MM-DD')

    var stateStartDT = moment(this.state.startDate).format('YYYY-MM-DD')
    if (stateStartDT > startDT) {
      startDT = stateStartDT
    }
    var stateEndDT = moment(this.state.endDate).format('YYYY-MM-DD')
    if (stateEndDT < endDT) {
      endDT = stateEndDT
    }

    this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null , null, null , null, "'" + mode + "'", null, null, null, null, null, "'" + startDT + "'" , "'" + endDT + "'", null, null, null, null, null, null  )

    this.props.history.push({
      pathname: 'shipmenttrackerfiltered'
      }); 
  
  }

  onClickLaneTransitTime = (e, column, columnIndex, row, rowIndex, mode) => {


    var dt = new Date(row.Date);
    var endDateMoment = moment(dt); 
    endDateMoment.add(1, 'months');

    var endDT = moment(endDateMoment).format('YYYY-MM-DD')
    var startDT = moment(row.Date).format('YYYY-MM-DD')

    var stateStartDT = moment(this.state.startDate).format('YYYY-MM-DD')
    if (stateStartDT > startDT) {
      startDT = stateStartDT
    }
    var stateEndDT = moment(this.state.endDate).format('YYYY-MM-DD')
    if (stateEndDT < endDT) {
      endDT = stateEndDT
    }

    this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null , null, null , null,   mode , null, null, "'" + column.text + "'", null, null, "'" + startDT + "'" , "'" + endDT + "'" , null, null, null ,null, null, null )

    this.props.history.push({
      pathname: 'shipmenttrackerfiltered'
      }); 
  
  }

  onClickLaneLateDeliveries = (e, column, columnIndex, row, rowIndex, mode) => {


    var modeInput = null

    if (mode === 1) {
      modeInput = 'AIR'
    }
    if ( mode === 2 ) {
      modeInput = 'OCEAN'
    }

    var dt = new Date(row.Date);
    var endDateMoment = moment(dt); 
    endDateMoment.add(1, 'months');

    var endDT = moment(endDateMoment).format('YYYY-MM-DD')
    var startDT = moment(row.Date).format('YYYY-MM-DD')

    var stateStartDT = moment(this.state.startDate).format('YYYY-MM-DD')
    if (stateStartDT > startDT) {
      startDT = stateStartDT
    }
    var stateEndDT = moment(this.state.endDate).format('YYYY-MM-DD')
    if (stateEndDT < endDT) {
      endDT = stateEndDT
    }

    this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'lateDelivered' , null, null , null,   modeInput , null, null, "'" + column.text + "'", null, null, "'" + startDT + "'" , "'" + endDT + "'", null, null, null, null, null, null  )

    this.props.history.push({
      pathname: 'shipmenttrackerfiltered'
      }); 
  
  }

  onClickModeLateDeliveries = (e, column, columnIndex, row, rowIndex, mode) => {

    var dt = new Date(row.Date);
    dt.setDate( dt.getDate() + 6 );

    var endDT = moment(dt).format('YYYY-MM-DD')
    var startDT = moment(row.Date).format('YYYY-MM-DD')

    var stateStartDT = moment(this.state.startDate).format('YYYY-MM-DD')
    if (stateStartDT > startDT) {
      startDT = stateStartDT
    }
    var stateEndDT = moment(this.state.endDate).format('YYYY-MM-DD')
    if (stateEndDT < endDT) {
      endDT = stateEndDT
    }
    
    this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'lateDelivered' ,null, null, null , "'" + mode + "'", null, null, null, null, null, "'" + startDT + "'" , "'" + endDT + "'", null, null, null, null, null, null )

    this.props.history.push({
      pathname: 'shipmenttrackerfiltered'
      }); 
  
  }


  onClickServiceIssues = (e, column, columnIndex, row, rowIndex, mode) => {

    var dt = new Date(row.Date);
    dt.setDate( dt.getDate() + 6 );

    var endDT = moment(dt).format('YYYY-MM-DD')
    var startDT = moment(row.Date).format('YYYY-MM-DD')

    var stateStartDT = moment(this.state.startDate).format('YYYY-MM-DD')
    if (stateStartDT > startDT) {
      startDT = stateStartDT
    }
    var stateEndDT = moment(this.state.endDate).format('YYYY-MM-DD')
    if (stateEndDT < endDT) {
      endDT = stateEndDT
    }
    
    this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'lateDelivered' ,null, null, null ,null, null, null, null, null, null, "'" + startDT + "'" , "'" + endDT + "'",null, null, null, null, null, null )

    this.props.history.push({
      pathname: 'shipmenttrackerfiltered'
      }); 
  
  }


  render() {
    const { ExportCSVButton } = CSVExport;

    var color = randomColor();

    const uniqueBy = prop => list => {
    const uniques = {}
    return list.reduce(
        (result, item) => {
            if (uniques[item[prop]]) return result
            uniques[item[prop]] = item
            return [...result, item]
        },
        [],
    )
  }




    const selectMode = [
      { label: "Air", value: "AIR" },
      { label: "Ocean", value: "OCEAN" }
    ];
  
    const selectNetwork = [
      { label: "Mode", value: "1" },
      { label: "Lane", value: "2" },
      // { label: "Forwarder", value: "3" },
      // { label: "Service", value: "4" }
    ];

    const selectMeasure = [
      { label: "Transit Time", value: "1" },
      { label: "Late Deliveries", value: "2" },
      { label: "Service Issues", value: "3" }
      // { label: "Milestone Alerts ", value: "3" },
      // { label: "Network Performance", value: "4" },
      // { label: "Late Pick Up", value: "5" },
      // { label: "Transit Time to SLA", value: "6" }
    ];

    // network 1 measure 1 
    const transitByModeColumns = [
      {
      dataField: 'Date',
      text: 'Week',
      // align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '30%' , textAlign: 'center' };
      },
      // sort: true
    }, {
      dataField: 'Avg Air Transit Time',
      text: 'Avg Air Transit Time (Days)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
        formatter: airTransitTimeFormatter,
      // sort: true,
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.onClickModeTransitTime(e, column, columnIndex, row, rowIndex, 'AIR')
        }}
    }, {
      dataField: 'Avg Ocean Transit Time',
      text: 'Avg Ocean Transit Time (Days)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
        formatter: oceanTransitTimeFormatter,
      // sort: true
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.onClickModeTransitTime(e, column, columnIndex, row, rowIndex, 'OCEAN')
        }}
    }
    ]

    // network 1 measure 2
    const lateDeliveriesByModeColumns = [
      {
      dataField: 'Date',
      text: 'Week',
      // align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '30%' , textAlign: 'center' };
      },
      // sort: true
    }, {
      dataField: 'Air Late Deliveries',
      text: 'Air Late Deliveries (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      events: {

        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.onClickModeLateDeliveries(e, column, columnIndex, row, rowIndex, 'AIR')
        }}
      // sort: true
    }, {
      dataField: 'Ocean Late Deliveries',
      text: 'Ocean Late Deliveries (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      events: {

        onClick: (e, column, columnIndex, row, rowIndex) => {
          this.onClickModeLateDeliveries(e, column, columnIndex, row, rowIndex, 'OCEAN')
        }}
      // sort: true
    }
    ]



    // network 1 measure 2
    const serviceIssuesColumns = [
      {
      dataField: 'Date',
      text: 'Week',
      // align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '30%' , textAlign: 'center' };
      },
      
    }, {
      dataField: 'Carrier',
      text: 'Carrier (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      // events: {

      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     this.onClickServiceIssues(e, column, columnIndex, row, rowIndex, null)
      //   }}
      
    }, {
      dataField: 'Vendor',
      text: 'Vendor (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      // events: {

      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     this.onClickServiceIssues(e, column, columnIndex, row, rowIndex, null)
      //   }}
      
    }, {
      dataField: 'Document',
      text: 'Document (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      // events: {

      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     this.onClickServiceIssues(e, column, columnIndex, row, rowIndex, null)
      //   }}
    
    }, {
      dataField: 'Weather Delay',
      text: 'Weather Delay (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      // events: {

      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     this.onClickServiceIssues(e, column, columnIndex, row, rowIndex, null)
      //   }}
      
    }, {
      dataField: 'Labor Dispute',
      text: 'Labor Dispute (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      // events: {

      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     this.onClickServiceIssues(e, column, columnIndex, row, rowIndex, null)
      //   }}
     
    }, {
      dataField: 'Unclassified',
      text: 'Unclassified (Count)',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '35%' , textAlign: 'center' };
      },
      formatter: lateDeliveryNumberFormatter,
      // events: {

      //   onClick: (e, column, columnIndex, row, rowIndex) => {
      //     this.onClickServiceIssues(e, column, columnIndex, row, rowIndex, null)
      //   }}
      
    }
    ]



    if (this.state.networkFilter == 2){
      var columnList = [];

      // get the results (useful data) somewhere
      var results = this.props.networkReportData;
      // you can loop through all, assuming that each result set is the same. 
      if (results.length > 0) { 
          // iterating through the results array
          for(var i = 0; i < results.length; i++) {
              // get i-th object in the results array  
              var columnsIn = results[i];
              // loop through every key in the object
              for(var key in columnsIn){
                  if(key != 'x' ){
                  columnList.indexOf(key) === -1 ? columnList.push(key) : console.log("");
                  }
              } 
          }
      }

      var startingList = columnList
      // startingList
      var laneColumns = [];
      // get the results (useful data) somewhere
      var resultsColumns = startingList;
      // you can loop through all, assuming that each result set is the same. 
      if (resultsColumns.length > 0) { 
          // iterating through the results array
          for(var iL = 0; iL < resultsColumns.length; iL++) {
              // get i-th object in the results array  
              var columnsInLane = resultsColumns[iL];

              var modeValue = 0
              if(typeof(this.state.dropDownValueMode[0]) !== 'undefined' && this.state.dropDownValueMode[0] != null){
                if (this.state.dropDownValueMode[0].value == 'AIR'){
                  modeValue = 1
                }
                else if (this.state.dropDownValueMode[0].value == 'OCEAN'){
                  modeValue = 2
                }
              }
                    if(columnsInLane === 'Date'){
                    laneColumns.indexOf(iL) === -1 ? laneColumns.push(        
                      { dataField: columnsInLane , text: 'Month' , headerStyle: (colum, colIndex) => { return { width: '110px' , textAlign: 'center' }; }, },
                      ) 
                      : console.log("");
                    }
                    //Lane: this creates columns for lane and late delieveries //onClickLaneLateDeliveries
                    else if(this.state.measureFilter == 2) {
                      laneColumns.indexOf(iL) === -1 ? laneColumns.push(        
                        { dataField: columnsInLane , 
                          text: columnsInLane, 
                          formatter: lateDeliveryNumberFormatter, 
                          align: 'center' , 
                          events: {
                            onClick: (e, column, columnIndex, row, rowIndex) => {
                              this.onClickLaneLateDeliveries(e, column, columnIndex, row, rowIndex, modeValue  )
                            }},
                          headerStyle: (colum, colIndex) => { return { textAlign: 'center' }; }, },
                        ) 
                        : console.log("");
                    }
                    //Lane: this creates columns for lane and transit time when mode is air
                    else if (this.state.measureFilter == 1 && modeValue == 1) {
                      laneColumns.indexOf(iL) === -1 ? laneColumns.push(        
                        { dataField: columnsInLane , 
                          text: columnsInLane, 
                          formatter: airTransitTimeFormatter ,
                          align: 'center', 
                          events: {
                            onClick: (e, column, columnIndex, row, rowIndex) => {
                              this.onClickLaneTransitTime(e, column, columnIndex, row, rowIndex, 'AIR' )
                            }},
                          headerStyle: (colum, colIndex) => { return { textAlign: 'center' }; }, },
                        ) 
                        : console.log("");
                      }
                    //Lane: this creates columns for lane and transit time when mode is ocean
                    else if (this.state.measureFilter == 1 && modeValue == 2){
                      laneColumns.indexOf(iL) === -1 ? laneColumns.push(        
                        { dataField: columnsInLane , 
                          text: columnsInLane, 
                          formatter: oceanTransitTimeFormatter ,
                          align: 'center', 
                          events: {
                            onClick: (e, column, columnIndex, row, rowIndex) => {
                              this.onClickLaneTransitTime(e, column, columnIndex, row, rowIndex, 'OCEAN' )
                            }},
                          headerStyle: (colum, colIndex) => { return { textAlign: 'center' }; }, },
                        ) 
                        : console.log("");
                      }
                    //Lane: this creates columns for lane and transit time when mode is not selected  
                    else{
                    laneColumns.indexOf(iL) === -1 ? laneColumns.push(        
                      { dataField: columnsInLane , 
                        text: columnsInLane, 
                        formatter: generalModeTransitTimeFormatter ,
                        align: 'center', 
                        events: {
                          onClick: (e, column, columnIndex, row, rowIndex) => {
                            this.onClickLaneTransitTime(e, column, columnIndex, row, rowIndex, null )
                          }},
                        headerStyle: (colum, colIndex) => { return { textAlign: 'center' }; }, },
                      ) 
                      : console.log("");
                    }
          }
      }
      var listRemoved = [];
      listRemoved = columnList.splice(0, 1);
    }


    // https://github.com/recharts/recharts/issues/421  dynamically create line chart


    let networkReport;
    if (this.props.isNetworkReportingLoading) {
      networkReport=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Network Report'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      
    }
    else if ( (typeof(this.props.networkReportData) !== 'undefined' && this.props.networkReportData != null)  ) {
//Mode and transit time; network = 1 measure = 1 
      if (this.props.networkReportData.length != 0  && this.state.networkFilter == 1 && this.state.measureFilter == 1 )   {
      networkReport=
      <div>
      <section className="panel">
      <header className="panel-heading">
        {'Average Transit Time by Mode (Days)'}
      </header>
      <div style={{height: 400, background: "#FFF"}}>
      <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <LineChart //width={730} height={250} 
                data={ this.props.networkReportData }
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line connectNulls type="monotone" dataKey="Avg Air Transit Time" stroke="#413ea0" strokeWidth={2}/>
                <Line connectNulls type="monotone" dataKey="Avg Ocean Transit Time" stroke="#82ca9d" strokeWidth={2}/>
            </LineChart>
      </ResponsiveContainer>
      </div>
      </div>
      </section>

      <section className="panel">
      <header className="panel-heading">
        {'Average Transit Time by Mode'}
      </header>
      <div className="panel-body table-responsive"
      style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
                <ToolkitProvider
                keyField="Date"
                // data={ this.props.kpiOverviewData }
                data={ this.props.networkReportData }
                columns={ transitByModeColumns }
                exportCSV
              >
                {
                  props => (
                    <div>
                      <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                      <hr />
                      <BootstrapTable { ...props.baseProps }  />
                    </div>
                  )
                }
              </ToolkitProvider>
      </div>
      </section>

      </div>

      }

//Mode and late deliveries
      else if (this.props.networkReportData.length != 0  && this.state.networkFilter == 1 && this.state.measureFilter == 2 )   {
        networkReport=
        <div>
        <section className="panel">
        <header className="panel-heading">
          {'Late Deliveries by Mode (Count)'}
        </header>
        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <LineChart //width={730} height={250} 
                  data={ this.props.networkReportData }
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line connectNulls type="monotone" dataKey="Air Late Deliveries" stroke="#413ea0" strokeWidth={2}/>
                  <Line connectNulls type="monotone" dataKey="Ocean Late Deliveries" stroke="#82ca9d" strokeWidth={2}/>
              </LineChart>
        </ResponsiveContainer>
        </div>
        </div>
        </section>

        <section className="panel">
        <header className="panel-heading">
          {'Late Deliveries by Mode'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
                  <ToolkitProvider
                  keyField="Date"
                  // data={ this.props.kpiOverviewData }
                  data={ this.props.networkReportData }
                  columns={ lateDeliveriesByModeColumns }
                  exportCSV
                  
                >
                  {
                    props => (
                      <div>
                        <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                        <hr />
                        <BootstrapTable { ...props.baseProps }  />
                      </div>
                    )
                  }
                </ToolkitProvider>
        </div>
        </section>

        </div>

        }



//Lane and transit time; network = 2 measure = 1 
      else if (this.props.networkReportData.length != 0  && this.state.networkFilter == 2 && this.state.measureFilter == 1 )   {

        networkReport=
        <div>
        <section className="panel">
        <header className="panel-heading">
          {'Average Transit Time by Lane (Days)'}  
        </header>
        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <LineChart //width={730} height={250} 
                  data={ this.props.networkReportData }
                  margin={{ top: 5, right: 30, left: 70, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip  />
                  {
                    columnList.map((id) => {
                      return (<Line type="monotone" connectNulls dataKey={`${id}`} stroke={randomColor({luminosity: 'dark',format: 'rgba', alpha: 0.5})}    
                      activeDot={{
                        onMouseOver: e => this.customMouseOver(e, id),
                        onMouseLeave: this.over
                      }}                        
                      />)

                    })
                  }
              </LineChart>
        </ResponsiveContainer>

        <div className="ui-chart-tooltip" ref={ref => (this.tooltip = ref)} >
                <div className="ui-chart-tooltip-content">
                    <div className="row" style={{padding: '15px', alignItems: 'center'}}>  
                    <div ref={ref => (this.tooltipLane = ref)}  className="value" />  &nbsp;
                    <div ref={ref => (this.tooltipValue = ref)} className="value" />
                    </div>
                </div>
        </div>

        </div>
        </div>
            </section>
              <section className="panel">
              <header className="panel-heading">
                {'Average Transit Time by Lane (Days)'}
              </header>
              <div className="panel-body table-responsive"
              style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
                        <ToolkitProvider
                        keyField="Date"
                        // data={ this.props.kpiOverviewData }
                        data={ this.props.networkReportData }
                        columns={ laneColumns }
                        exportCSV
                      >
                        {
                          props => (
                            <div>
                              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                              <hr />
                              <BootstrapTable { ...props.baseProps }  />
                            </div>
                          )
                        }
                      </ToolkitProvider>
              </div>
              </section>

            </div>
        }

//Lane and late deliveries; network = 2 measure = 2
        else if (this.props.networkReportData.length != 0  && this.state.networkFilter == 2 && this.state.measureFilter == 2 )   {

          networkReport=
          <div>
          <section className="panel">
          <header className="panel-heading">
            {'Late Deliveries by Lane (Count)'}  
          </header>
          <div style={{height: 400, background: "#FFF"}}>
          <div style={{ width: '100%', height: 400 }}>
          <ResponsiveContainer>
            <LineChart //width={730} height={250} 
                    data={ this.props.networkReportData }
                    margin={{ top: 5, right: 30, left: 70, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="Date" />
                    <YAxis />
                    <Tooltip  />
                    {
                      columnList.map((id) => {
                        return (<Line type="monotone" connectNulls dataKey={`${id}`} stroke={randomColor({luminosity: 'dark',format: 'rgba', alpha: 0.5})}   
                        activeDot={{
                          onMouseOver: e => this.customMouseOver(e, id),
                          onMouseLeave: this.over
                        }}                        
                        />)

                      })
                    }
                </LineChart>
          </ResponsiveContainer>

          <div className="ui-chart-tooltip" ref={ref => (this.tooltip = ref)} >
                  <div className="ui-chart-tooltip-content">
                      <div className="row" style={{padding: '15px', alignItems: 'center'}}>  
                      <div ref={ref => (this.tooltipLane = ref)}  className="value" />  &nbsp;
                      <div ref={ref => (this.tooltipValue = ref)} className="value" />
                      </div>
                  </div>
          </div>

          </div>
          </div>
              </section>
                <section className="panel">
                <header className="panel-heading">
                  {'Late Deliveries by Lane (Count)'}
                </header>
                <div className="panel-body table-responsive"
                style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
                          <ToolkitProvider
                          keyField="Date"
                          // data={ this.props.kpiOverviewData }
                          data={ this.props.networkReportData }
                          columns={ laneColumns }
                          exportCSV
                        >
                          {
                            props => (
                              <div>
                                <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                                <hr />
                                <BootstrapTable { ...props.baseProps }  />
                              </div>
                            )
                          }
                        </ToolkitProvider>
                </div>
                </section>

              </div>
          }




        //Service Issues: measure = 3
        // else if (this.props.networkReportData.length != 0  && this.state.networkFilter == 2 && this.state.measureFilter == 3 )   {
        else if (this.props.networkReportData.length != 0  && this.state.measureFilter == 3 )   {
            networkReport=
            <div>
            <section className="panel">
            <header className="panel-heading">
              {'Service Issues'}
            </header>
            <div style={{height: 400, background: "#FFF"}}>
            <div style={{ width: '100%', height: 400 }}>
            <ResponsiveContainer>
              <LineChart //width={730} height={250} 
                      data={ this.props.networkReportData }
                      margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Date" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line connectNulls type="monotone" dataKey="Carrier" stroke="#413ea0" strokeWidth={2}/>
                      <Line connectNulls type="monotone" dataKey="Vendor" stroke="#82ca9d" strokeWidth={2}/>
                      <Line connectNulls type="monotone" dataKey="Document" stroke="#ff9d00" strokeWidth={2}/>
                      <Line connectNulls type="monotone" dataKey="Weather Delay" stroke="#00eaff" strokeWidth={2}/>
                      <Line connectNulls type="monotone" dataKey="Labor Dispute" stroke="#c7007b" strokeWidth={2}/>
                      <Line connectNulls type="monotone" dataKey="Unclassified" stroke="#8e8e91" strokeWidth={2}/>
                  </LineChart>
            </ResponsiveContainer>
            </div>
            </div>
            </section>




    
            <section className="panel">
            <header className="panel-heading">
              {'Service Issues'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>
                      <ToolkitProvider
                      keyField="Date"
                      // data={ this.props.kpiOverviewData }
                      data={ this.props.networkReportData }
                      columns={ serviceIssuesColumns }
                      exportCSV
                      
                    >
                      {
                        props => (
                          <div>
                            <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                            <hr />
                            <BootstrapTable { ...props.baseProps }  />
                          </div>
                        )
                      }
                    </ToolkitProvider>
            </div>
            </section>
    
            </div>
    
            }







      else if (this.state.measureFilter > 3 )   {
          networkReport=
          <section className="panel">
          <header className="panel-heading">
            {'Network Report'}
          </header>
          <div style={{height: 400, background: "#FFF"}}>
          <div style={{ width: '100%', height: 400 }}>
          &nbsp;  Under Development - Future Report
          </div>
          </div>
              </section>
      }

      else {
          networkReport=
          <section className="panel">
          <header className="panel-heading">
            {'Network Report'}
          </header>
          <div style={{height: 400, background: "#FFF"}}>
          <div style={{ width: '100%', height: 400 }}>
         &nbsp; No data
          </div>
          </div>
              </section>
      }
    }
    else {
      networkReport=
      <section className="panel">
      <header className="panel-heading">
        {'Network Report'}
      </header>
      <div style={{height: 400, background: "#FFF"}}>
      <div style={{ width: '100%', height: 400 }}>
     &nbsp; No data
      </div>
      </div>
          </section>

    }

    return(
      <div>
      <section className="panel">
      <header className="panel-heading">
        {'Filters'}
      </header>

      <div //className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}
          >

      <div className="row" style={{padding: '15px', alignItems: 'center'}}>  

      <div className="col-md-5"  >
      <div className="sm-st clearfix" style={{height: '100%'}}>
          <div className="row">  
          <div style={{marginLeft: "auto",  marginRight: "auto"}}>  

                  Start: <DatePicker
                      placeholderText="Select Start Date"
                      selected={this.state.startDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChangeStartDate}
                  />
          </div>
          <div style={{marginLeft: "auto",  marginRight: "auto"}}>  

                  End: <DatePicker
                      placeholderText="Select End Date"
                      selected={this.state.endDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.handleChangeEndDate}
                      minDate={this.state.startDate}
                  />
          </div>
          </div>
      </div>
      </div>


      <div className="col-md-2"  >
            <div className="sm-st clearfix" style={{height: '100%'}}>
              <div className="row">  
              <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    <Select 
                      options={ selectMode } 
                      clearable
                      separator
                      placeholder={ 'Mode...'}
                      // value={{ label: "Air & Ocean", value: null }}
                      onChange={this.handleDropdownChangeMode}
                    />
                    </div>
              </div>
            </div>
            </div>

      
      
      <div className="col-md-2"  >
      <div className="sm-st clearfix" style={{height: '100%'}}>
          <div className="row">  
          <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>

              <Select 
                  options={ selectNetwork } 
                  clearable
                  separator
                  placeholder={ 'Network...'}
                  onChange={this.handleDropdownChangeNetwork}
              />
              </div>
          </div>
      </div>
      </div>


      <div className="col-md-2"  >
      <div className="sm-st clearfix" style={{height: '100%'}}>
          <div className="row">  
          <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>

              <Select 
                  options={ selectMeasure } 
                  clearable
                  separator
                  placeholder={ 'Measure...'}
                  onChange={this.handleDropdownChangeMeasure}
              />
              </div>
          </div>
      </div>
      </div>

      <div className="col-md-1"  >
      <div className="sm-st clearfix" style={{height: '100%'}}>
          <div className="row">  
          <div style={{backgroundColor: '#FFFFFF',  marginLeft: "auto",  marginRight: "auto" }}>

              <Button variant="success" size="sm" block   onClick={(e) => this.clickApplyFilter(e)}>
                  Apply
              </Button>

          </div>
          </div>
      </div>
      </div>

      </div>
      </div>
      </section>

          { networkReport }

      </div>
    );
  }
}

export default withRouter(NetworkReporting);







