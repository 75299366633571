import React from 'react'
import PropTypes from 'prop-types'
import './App.css'

import HomeView from './views/ViewsGeneral/HomeView/HomeView'
import SignIn from './components/ComponentsUser/SignIn'
// import Landing from './components/ComponentsUser/Landing'
import ResetPassword from './components/ComponentsUser/ResetPassword'
import ChangePassword from './components/ComponentsUser/ChangePassword'
import ForceChangePassword from './components/ComponentsUser/ForceChangePassword'
import Header from './components/ComponentsUser/Header'
import Footer from './components/ComponentsGeneral/Footer/Footer'
import Auth from './components/ComponentsUser/Auth'
import signInAuth         from './views/ViewsUser/signInAuth/signInAuth';
import PrivateRoute from './components/ComponentsUser/PrivateRoute'
import userSignUpView    from './views/ViewsUser/userSignUp/userSignUpView';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { state } from 'aws-cognito-redux-saga'



class App extends React.Component {
  static propTypes = {
    auth: PropTypes.object
  }

  constructor(props) {
    super(props)

  }

  render() {
    const { auth } = this.props

    return (

      <div className="app">
      <div className="screen">
        <Auth />
        <Header />
        <Switch>

          <PrivateRoute path="/home" component={HomeView} />

          <PrivateRoute path="/auth" component={signInAuth} />

          <Route path="/signin" component={SignIn} />
          <Route path="/signup" component={userSignUpView} />
          <Route path="/resetpassword" component={ResetPassword} />
          <Route path="/changepassword" component={ChangePassword} />
          <Route
            path="/forcechangepassword"
            component={ForceChangePassword}
          />


          <Route exact
            path="/"
            render={() =>                 
              auth.isSignedIn === state.AUTH_SUCCESS ? ( 
                <Redirect to="/auth" />
              ) : (
                <Redirect to="/signin" />
              )
            }
          />




        </Switch>
        <Footer />
      </div>
    </div>

  )
}
}

export default App