
// import React from 'react'
import PropTypes from 'prop-types'
// import {Route} from 'react-router-dom'
// import {Redirect} from 'react-router-dom'
import { state } from 'aws-cognito-redux-saga'
import React from 'react'
import {
  withRouter,
  Switch,
  Route,
  Redirect,
  BrowserRouter as Router
} from 'react-router-dom'
// import { Auth } from 'aws-amplify'
import Auth from '@aws-amplify/auth';
import { awsCognitoConfig } from '../../../app/appConfig'
import Amplify from "@aws-amplify/core";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";


const waitFor = delay => new Promise(resolve => setTimeout(resolve, delay));





class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  }

  async  waitSignin (){

    await waitFor(1200);

    this.setState({ loaded: true, isAuthenticated: true })
  }


  componentDidMount() {

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: awsCognitoConfig.cognitoLogin.Auth.region,
        userPoolId: awsCognitoConfig.cognitoLogin.Auth.UserPoolId,
        identityPoolId: awsCognitoConfig.cognitoLogin.Auth.IdentityPoolId,
        userPoolWebClientId: awsCognitoConfig.cognitoLogin.Auth.ClientId
      }});
    this.authenticate()
    this.unlisten = this.props.history.listen(() => {
      Auth.currentAuthenticatedUser()
        .then(user => {

          // var useremail = user.signInUserSession.idToken.payload.email

          // this.setUser( user.signInUserSession.idToken.payload.email )
          // this.setState({ currentUser: useremail  })
          // this.props.appSignIn( useremail )
          // waitSignin();

        }
        )
        .catch(() => {
          if (this.state.isAuthenticated) this.setState({ isAuthenticated: false })

          if(this.state.isAuthenticated === false){
            this.navigateToSignin()
          }

        })
    });
    

  }


  componentWillUnmount() {
    this.unlisten()
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
    .then(user => {

      var useremail = user.signInUserSession.idToken.payload.email


      this.props.appSignIn( useremail )
      this.waitSignin();

      // this.setState({ loaded: true, isAuthenticated: true })
    }
    )


      // .then(() => {
      //   this.setState({ loaded: true, isAuthenticated: true })
      // })
      .catch(() => this.props.history.push('/auth'))



  }

  navigateToSignin() {
    this.props.history.push('/signin')
  }


  render() {
    const { component: Component, ...rest } = this.props
    const { loaded , isAuthenticated} = this.state
    if (!loaded) return null





    return (
      <Route
        render={() => {
          return isAuthenticated  ? (
            <this.props.component />
          ) : (
            <Redirect to="/signin" />
          )
        }}
      />
    )
  }
}



export default withRouter(PrivateRoute)







// import React from 'react'
// import PropTypes from 'prop-types'
// import Route from 'react-router-dom/Route'
// import Redirect from 'react-router-dom/Redirect'
// import { state } from 'aws-cognito-redux-saga'

// // import { state } from 'aws-cognito-redux-saga'

// class PrivateRoute extends React.Component {
//   static propTypes = {
//     auth: PropTypes.object
//   }

//   render() {
//     let { auth } = this.props



//     return (
//       <Route
//         render={() => {
//           return auth.isSignedIn === state.AUTH_SUCCESS ? (
//             <this.props.component />
//           ) : (
//             <Redirect to="/signin" />
//           )
//         }}
//       />
//     )
//   }
// }

// export default PrivateRoute
