/* ################################### SIGNIN ACTIONS TYPES ################################### */
export const APP_SIGNIN = 'APP_SIGNIN';
export const APP_SIGNIN_COMPLETE = 'APP_SIGNIN_COMPLETE';
export const APP_SIGNIN_ERROR = 'APP_SIGNIN_ERROR';

export const GET_PAGE_CONFIG = 'GET_PAGE_CONFIG';
export const COMPLETE_PAGE_CONFIG = 'COMPLETE_PAGE_CONFIG';
export const ERROR_PAGE_CONFIG = 'ERROR_PAGE_CONFIG';

/* ################################### SIGN OUT ACTIONS TYPES ################################### */
export const SIGN_OUT = 'SIGN_OUT';


/* ################################### ADD USER ACTIONS TYPES ################################### */
export const USER_ADD_POST = 'USER_ADD_POST';
export const USER_ADD_COMPLETE = 'USER_ADD_COMPLETE';
export const USER_ADD_ERROR = 'USER_ADD_ERROR';

export const PENDING_USER_GET = 'PENDING_USER_GET';
export const PENDING_USER_COMPLETE = 'PENDING_USER_COMPLETE';
export const PENDING_USER_ERROR = 'PENDING_USER_ERROR';

export const USER_UPDATE_POST = 'USER_UPDATE_POST';
export const USER_UPDATE_COMPLETE = 'USER_UPDATE_COMPLETE';
export const USER_UPDATE_ERROR = 'USER_UPDATE_ERROR';

export const USER_ACCOUNT_GET = 'USER_ACCOUNT_GET';
export const USER_ACCOUNT_COMPLETE = 'USER_ACCOUNT_COMPLETE';
export const USER_ACCOUNT_ERROR = 'USER_ACCOUNT_ERROR';

export const USER_DELETE_POST = 'USER_DELETE_POST';
export const USER_DELETE_COMPLETE = 'USER_DELETE_COMPLETE';
export const USER_DELETE_ERROR = 'USER_DELETE_ERROR';

export const USER_PERMISSIONS_POST = 'USER_PERMISSIONS_POST';
export const USER_PERMISSIONS_COMPLETE = 'USER_PERMISSIONS_COMPLETE';
export const USER_PERMISSIONS_ERROR = 'USER_PERMISSIONS_ERROR';


/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';
