import { connect } from 'react-redux';
import React, { Component } from 'react';
import MawbDetailsZU from './MawbDetailsZU'
import * as actions from '../../../../redux/actions/zuActions';
import { appSettings } from '../../../../app/appSettings'
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';


const mapStateToProps = store =>
({

    currentUserEmail: store.userServicesReducer.currentUserEmail,
    userSignInData: store.userServicesReducer.userSignInData,

    isMawbDataLoading: store.zuDataReducer.isMawbDataLoading,
    MawbDetailData: store.zuDataReducer.MawbDetailData,

    isMCDataLoading: store.zuDataReducer.isMCDataLoading,
    MCDetailData: store.zuDataReducer.MCDetailData,

    isHawbDataLoading: store.zuDataReducer.isHawbDataLoading,
    HawbDetailData: store.zuDataReducer.HawbDetailData,

    isJobEventsDataLoading: store.zuDataReducer.isJobEventsDataLoading,
    JobEventsData: store.zuDataReducer.JobEventsData,

    isIBCDtlDataLoading: store.zuDataReducer.isIBCDtlDataLoading,
    IBCDtlData: store.zuDataReducer.IBCDtlData,

    isZuMbolEmailFilenameDataLoading: store.zuDataReducer.isZuMbolEmailFilenameDataLoading,
    ZuMbolEmailFilenameData: store.zuDataReducer.ZuMbolEmailFilenameData,

    isZuMbolEmailUrlLoading: store.zuDataReducer.isZuMbolEmailUrlLoading,
    ZuMbolEmailUrlData: store.zuDataReducer.ZuMbolEmailUrlData

});

const mapDispatchToProps = dispatch =>
({
    zuGetIBCInboundDtl: (_db, _email, _mawb, _mc, _hawb, _afhawb) => {
        dispatch(actions.zuGetIBCInboundDtl(_db, _email, _mawb, _mc, _hawb, _afhawb))
    },


    zuGetMawbDetails: (_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry) => {
        dispatch(actions.zuGetMawbDetails(_db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry))
    },

    zuGetMCDetails: (_db, _email, _mawb, _mc, _hawb) => {
        dispatch(actions.zuGetMCDetails(_db, _email, _mawb, _mc, _hawb))
    },

    zuGetHawbDetails: (_db, _email, _mawb, _mc, _hawb, _trackingno) => {
        dispatch(actions.zuGetHawbDetails(_db, _email, _mawb, _mc, _hawb, _trackingno))
    },

    zuGetJobEvents: (_db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter) => {
        dispatch(actions.zuGetJobEvents(_db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter))
    },

    zuMbolEmailFilenameGet: (_db, _email, _mawb, _filetype) => {
        dispatch(actions.zuMbolEmailFilenameGet(_db, _email, _mawb, _filetype))
    },

    zuMbolEmailUrlGet: (_db, _email, _filename) => {
        dispatch(actions.zuMbolEmailUrlGet(_db, _email, _filename))
    },

});


class MawbDetailsZUContainer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }

    handleUrlParam = () => {
        var paramStartDT = null
        var paramEndDT = null
        var paramDateType = null
        var paramNotDeparted = null
        var paramPortExit = null
        var paramPortEntry = null
        var paramFinalCarrier = null

        var paramMawb = null
        var paramMC = null
        var paramHawb = null
        var paramFilter = null
        var paramFilterValue = null
        var paramShipToCountry = null

        const values = queryString.parse(this.props.location.search)

        if (typeof (values.startdt) !== 'undefined' && values.startdt != null) {
            paramStartDT = values.startdt
        }
        if (typeof (values.enddt) !== 'undefined' && values.enddt != null) {
            paramEndDT = values.enddt
        }
        if (typeof (values.datetype) !== 'undefined' && values.datetype != null) {
            paramDateType = values.datetype
        }
        if (typeof (values.notdeparted) !== 'undefined' && values.notdeparted != null) {
            paramNotDeparted = values.notdeparted
        }
        if (typeof (values.portexit) !== 'undefined' && values.portexit != null) {
            paramPortExit = values.portexit
        }
        if (typeof (values.portentry) !== 'undefined' && values.portentry != null) {
            paramPortEntry = values.portentry
        }
        if (typeof (values.finalcarrier) !== 'undefined' && values.finalcarrier != null) {
            paramFinalCarrier = values.finalcarrier
        }

        if (typeof (values.mawb) !== 'undefined' && values.mawb != null) {
            paramMawb = values.mawb
        }
        if (typeof (values.mc) !== 'undefined' && values.mc != null) {
            paramMC = values.mc
        }
        if (typeof (values.hawb) !== 'undefined' && values.hawb != null) {
            paramHawb = values.hawb
        }
        if (typeof (values.filter) !== 'undefined' && values.filter != null) {
            paramFilter = values.filter
        }
        if (typeof (values.filterValue) !== 'undefined' && values.filterValue != null) {
            paramFilterValue = values.filterValue
        }
        if (typeof (values.shiptocountry) !== 'undefined' && values.shiptocountry != null) {
            paramShipToCountry = values.shiptocountry
        }


        this.props.zuGetIBCInboundDtl(appSettings.zuDB, this.props.currentUserEmail, paramMawb, paramMC, null, paramHawb);

        this.props.zuGetMawbDetails(appSettings.zuDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, paramFilterValue, paramShipToCountry);

        this.props.zuGetMCDetails(appSettings.zuDB, this.props.currentUserEmail, paramMawb, paramMC, paramHawb);

        this.props.zuGetJobEvents(appSettings.zuDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramMawb, paramMC, paramHawb, paramFilter);

        this.props.zuGetHawbDetails(appSettings.zuDB, this.props.currentUserEmail, paramMawb, paramMC, paramHawb, null);

        this.props.zuMbolEmailFilenameGet(appSettings.zuDB, this.props.currentUserEmail, paramMawb, null);

    }



    render() {

        return (

            <MawbDetailsZU

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isIBCDtlDataLoading={this.props.isIBCDtlDataLoading}
                IBCDtlData={this.props.IBCDtlData}

                isMawbDataLoading={this.props.isMawbDataLoading}
                MawbDetailData={this.props.MawbDetailData}

                isMCDataLoading={this.props.isMCDataLoading}
                MCDetailData={this.props.MCDetailData}

                isHawbDataLoading={this.props.isHawbDataLoading}
                HawbDetailData={this.props.HawbDetailData}

                isJobEventsDataLoading={this.props.isJobEventsDataLoading}
                JobEventsData={this.props.JobEventsData}


                isZuMbolEmailFilenameDataLoading={this.props.isZuMbolEmailFilenameDataLoading}
                ZuMbolEmailFilenameData={this.props.ZuMbolEmailFilenameData}


                isZuMbolEmailUrlLoading={this.props.isZuMbolEmailUrlLoading}
                ZuMbolEmailUrlData={this.props.ZuMbolEmailUrlData}
                zuMbolEmailUrlGet={this.props.zuMbolEmailUrlGet}
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MawbDetailsZUContainer));



