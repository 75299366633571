import React, {
    PureComponent
  }                         from 'react';
import StatsCard  from './StatsCard';
import {appConfig} from '../../../app/appConfig'
import {withRouter} from 'react-router-dom';
import Loader from 'react-loader-spinner'
import {appSettings} from '../../../app/appSettings'

class StatsCardRow extends PureComponent {
  constructor( props )
  {
    super( props );
    this.state = {
      width: window.innerWidth,
    };

  }

  componentWillMount() {
    window.addEventListener('resize', this.handleWindowSizeChange);
  }
 
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }
 
  handleWindowSizeChange = () => {
    this.setState({ width: window.innerWidth });
  };


  onClickInTransit = () => {
    //load ship tracker data with in transit only
    // this.props.getStatCardInTransit( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null, null, null, null, null, null, 'O', null, null, null );

    if(this.props.location.pathname !== "/home/google/statscards") {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        // pathname: 'google/statscards'
        pathname: 'google/statscards',
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=null'
        }); 
      }
    else{
      this.props.history.push({
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=null'
        }); 

      }
  }
  

  onClickHotShip = () => {
    //load ship tracker data with in transit only
    // this.props.getStatCardParam(this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'hot', null, null, null, null, null, null, null, 'O', null, null, null);

    // if(this.props.location.pathname !== "/home/google/statscards") {
    //     // this.props.openShipDetails(paramjobid, row);
    //     this.props.history.push({
    //     pathname: 'google/statscards?startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null'

    //     // pathname: 'google/statscards'
    //     }); 
    //   }

    if(this.props.location.pathname !== "/home/google/statscards") {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        // pathname: 'google/statscards'
        pathname: 'google/statscards',
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=hot'
        }); 
      }
    else{
      this.props.history.push({
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=hot'
        }); 

      }

  }

  onClickOmni = () => {
    //load ship tracker data with in transit only
    // this.props.getStatCardParam(this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'omni', null, null, null, null, null, null, null, 'O', null, null, null);

    // if(this.props.location.pathname !== "/home/google/statscards") {
    //     // this.props.openShipDetails(paramjobid, row);
    //     this.props.history.push({
    //     pathname: 'google/statscards'
    //     }); 
    //   }

    if(this.props.location.pathname !== "/home/google/statscards") {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        // pathname: 'google/statscards'
        pathname: 'google/statscards',
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=omni'
        }); 
      }
    else{
      this.props.history.push({
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=omni'
        }); 

      }


  }

  onClickGoogle = () => {
    //load ship tracker data with in transit only
    // this.props.getStatCardParam( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'google', null, null, null, null, null, null, null, 'O', null, null, null);

    // if(this.props.location.pathname !== "/home/google/statscards") {
    //     // this.props.openShipDetails(paramjobid, row);
    //     this.props.history.push({
    //     pathname: 'google/statscards'
    //     }); 
    //   }

    if(this.props.location.pathname !== "/home/google/statscards") {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        // pathname: 'google/statscards'
        pathname: 'google/statscards',
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=google'
        }); 
      }
    else{
      this.props.history.push({
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=google'
        }); 

      }


  }

  onClickSubscriptions = () => {
    //load ship tracker data with in transit only
    // this.props.getStatCardParam( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'subscribed', null, null, null, null, null, null, null, 'O', null, null, null);

    // if(this.props.location.pathname !== "/home/google/statscards") {
    //     // this.props.openShipDetails(paramjobid, row);
    //     this.props.history.push({
    //     pathname: 'google/statscards'
    //     }); 
    //   }

    if(this.props.location.pathname !== "/home/google/statscards") {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        // pathname: 'google/statscards'
        pathname: 'google/statscards',
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=subscribed'
        }); 
      }
    else{
      this.props.history.push({
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=subscribed'
        }); 

      }

  }

  onShipLate = () => {
    //load ship tracker data with in transit only
    // this.props.getStatCardParam( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'late', null, null, null, null, null, null, null, 'O', null, null, null);

    // if(this.props.location.pathname !== "/home/google/statscards") {
    //     // this.props.openShipDetails(paramjobid, row);
    //     this.props.history.push({
    //     pathname: 'google/statscards'
    //     }); 
    //   }

    if(this.props.location.pathname !== "/home/google/statscards") {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        // pathname: 'google/statscards'
        pathname: 'google/statscards',
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=late'
        }); 
      }
    else{
      this.props.history.push({
        search: 'startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=late'
        }); 

      }



  }


  //   if(this.props.location.pathname !== "/home/statscards") {
  //     this.props.history.push({
  //     pathname: 'home/shipmenttracker'
  //     }); 
  //   }
  //   else {
  //     this.props.history.push({
  //       pathname: 'shipmenttracker'
  //     }); 
  //   }


  // }
// /home/reporting?startdt=null&enddt=null&mode=null&network=1&measure=1


  onClickNetHealth = () => {
    if(this.props.location.pathname !== "/home/google/statscards") {
      this.props.history.push({
      pathname: 'google/reporting',
      search: 'reporting?startdt=null&enddt=null&mode=null&network=1&measure=1'
      }); 
    }
    else {
      this.props.history.push({
        pathname: 'reporting',
        search: 'reporting?startdt=null&enddt=null&mode=null&network=1&measure=1'
      }); 
    }

  }

  onClickNetHealthShipTracker = () => {
    if(this.props.location.pathname !== "/home/google/statscards") {
      this.props.history.push({
      pathname: 'google/shipmenttracker?startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null'
      }); 
    }
    else {
      this.props.history.push({
        pathname: 'shipmenttracker'
      }); 
    }

  }

  

  onClickKPI = () => {
    if(this.props.location.pathname !== "/home/google/statscards") {
      this.props.history.push({
      pathname: 'google/kpi'
      }); 
    }
    else {
      this.props.history.push({
        pathname: 'kpi'
      }); 
    }
  }

  onClickAlerts = () => {
    if(this.props.location.pathname !== "/home/google/statscards") {
      this.props.history.push({
      pathname: 'google/milestonealerts'
      }); 
    }
    else {
      this.props.history.push({
        pathname: 'milestonealerts'
      }); 
    }
  }


  PercentColor = ( value ) => {
    if(value >= 89 ) {
      return 'green'
    }
    else if(value < 89 && value >= 75 ) {
      return 'yellow'
    }
    else {
      return 'red'
    }

  }

  ShipmentNumberColor = ( value ) => {
    if(value >= 4 ) {
      return 'red'
    }
    else if(value < 4 && value >= 1 ) {
      return 'yellow'
    }
    else {
      return 'green'
    }

  }

  StatCardColor = ( number, category ) => {
    if( number === 0 ) {
      return 'grey'
    }
    else if( category === 'In Transit' ) {
      return 'green'
    }
    else if( category === 'Hot Shipments' ) {
      return 'red'
    }
    else if( category === appSettings.customer.Stat_Card_Internal ) {
      return 'yellow'
    }
    else if( category === appSettings.customer.Stat_Card_External ) {
      return 'blue'
    }

  }

    render() {
      const { width } = this.state;
      const isMobile = width <= appSettings.mobileWidth;


        if (this.props.isStatCardRowLoading) {
          return(
            <div align="center">
            <section className="panel">
              <header className="panel-heading">
                {'Stat Cards'}
              </header>
              </section>
              <Loader 
                type="Plane"
                color="#00BFFF"
                height="50"	
                width="50"
              /> 
            </div>
          )
        }

        if (!this.props.isStatCardRowLoading) {
          if (this.props.statCardRowData.length > 0) {
            var In_Transit = String(this.props.statCardRowData[0].In_Transit);
            var Hot_Shipments = String(this.props.statCardRowData[0].Hot_Shipments);
            var Omni_Action = String(this.props.statCardRowData[0].Omni_Action);
            var Google_Action = String(this.props.statCardRowData[0].Google_Action);
            var Subscriptions = String(this.props.statCardRowData[0].Subscriptions);

            var Health = String(this.props.statCardRowData[0].NetworkHealth);
            var LateShip = String(this.props.statCardRowData[0].LateShipment);
            // var KPIMissed = String(this.props.statCardRowData[0].KPIMissed);
            var ShipAlerts = String(this.props.statCardRowData[0].MilestoneAlert);
          }
          else {
            var In_Transit = 0;
            var Hot_Shipments = 0;
            var Omni_Action = 0;
            var Google_Action = 0;
            var Subscriptions = 0;

            var Health = 0;
            var LateShip = 0;
            var KPIMissed = 0;
            var ShipAlerts = 0;
          }
        }


  

// stat card divs        
        // let EDIRefreshDiv;
        // EDIRefreshDiv = 
//         <div className="col-md-3"  >
//         <div style={{cursor: 'pointer'}} >
//             <StatsCard
//                 statValue={parseFloat(Math.round(10 + Math.random() * (15 - 10) * 100) / 100 + 10).toFixed(1) + ' Mins'}
//                 // statValue={parseFloat(Math.round(Math.random() * (15 - 10) + 10)).toFixed(2) + ' Mins'}


//                 statLabel={'EDI refresh time'}
//                 icon={<i className="fa fa-tachometer" />}
//                 backColor={'green'}
//             />
//             </div>
//         </div>


        let networkHealthDiv;

        if(this.props.userSignInData.GroupName ==='OMNI'  || this.props.userSignInData.GroupName ==='Demo' ) {
        networkHealthDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickNetHealth}>
          <StatsCard
              statValue={Health + '%'}
              statLabel={'Network Health'}
              icon={<i className="fa fa-thermometer-full" />}
              backColor={this.PercentColor(Health)}
          />
          </div>
        }
        else if(this.props.userSignInData.GroupName ==='Google') {
          networkHealthDiv = 
            <div style={{cursor: 'pointer'}} onClick={this.onClickNetHealthShipTracker}>
            <StatsCard
                statValue={Health + '%'}
                statLabel={'Network Health'}
                icon={<i className="fa fa-thermometer-full" />}
                backColor={this.PercentColor(Health)}
            />
            </div>
          }

        let lateShipmentsDiv;
        lateShipmentsDiv =   
          <div style={{cursor: 'pointer'}} onClick={this.onShipLate}>
          <StatsCard
              statValue={LateShip}
              statLabel={'Late Shipments'}
              icon={<i className="fa fa-hourglass" />}
              backColor={this.ShipmentNumberColor(LateShip)}
          />
          </div>

        let milestoneAlertsDiv;
        milestoneAlertsDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickAlerts}>
          <StatsCard
              statValue={ShipAlerts}
              statLabel={'Shipments with Milestone Alerts'}
              icon={<i className="fa fa-bell" />}
              backColor={this.ShipmentNumberColor(ShipAlerts)}
          />
          </div>

        let subscriptionsDiv;
        subscriptionsDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickSubscriptions}>
          <StatsCard
              statValue={Subscriptions}
              statLabel={'Subscriptions'}
              icon={<i className="fa fa-envelope-open" />}
              backColor={'green'}
          />
          </div>

        let inTransitDiv;
        inTransitDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickInTransit}>
          <StatsCard
              statValue={In_Transit}
              statLabel={'In Transit'}
              icon={<i className="fa fa-truck" />}
              backColor={this.StatCardColor(In_Transit, 'In Transit')}
          />
          </div>

        let hotShipmentsDiv;
        hotShipmentsDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickHotShip}>
          <StatsCard
              statValue={Hot_Shipments}
              statLabel={'Hot Shipments'}
              icon={<i className="fa fa-exclamation-triangle" />}
              backColor={'red'}
          />
          </div>

        let omniActionDiv;
        omniActionDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickOmni}>
          <StatsCard
              statValue={Omni_Action}
              statLabel={appSettings.customer.Stat_Card_Internal}
              icon={<i className="fa fa-flag" />}
              backColor={'yellow'}
          />
          </div>
          
        let googleActionDiv;
        googleActionDiv = 
          <div style={{cursor: 'pointer'}} onClick={this.onClickGoogle}>
          <StatsCard
              statValue={Google_Action}
              statLabel={appSettings.customer.Stat_Card_External}
              icon={<i className="fa fa-flag" />}
              backColor={'blue'}
          />
          </div>

// stat card row based on user:
        let statCardRowDiv;

        if(this.props.userSignInData.GroupName ==='OMNI'  || this.props.userSignInData.GroupName ==='Demo' ) {
          if(isMobile){
            statCardRowDiv = 
            <div>
                <div style={{marginBottom: '1px'}}>
                {networkHealthDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {lateShipmentsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {milestoneAlertsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {subscriptionsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {inTransitDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {hotShipmentsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {omniActionDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                {googleActionDiv}
                </div>
            </div>
          }
          else{
            statCardRowDiv = 
            <div>
            <div
              className="row"
              style={{marginBottom: '5px'}}>
              <div className="col-md-3" >
                {networkHealthDiv}
              </div>
              <div className="col-md-3" >
                {lateShipmentsDiv}
              </div>
              <div className="col-md-3"  >
                {milestoneAlertsDiv}
              </div>
              <div className="col-md-3"  >
                {subscriptionsDiv}
              </div>
            </div>
            <br/>
              <div
              className="row"
              style={{marginBottom: '5px'}}>
              <div className="col-md-3" >
                {inTransitDiv}
              </div>
              <div className="col-md-3" >
                {hotShipmentsDiv}
              </div>
              <div className="col-md-3"  >
                {omniActionDiv}
              </div>
              <div className="col-md-3"  >
                {googleActionDiv}
              </div>
            </div>
            </div>
          }

          

         
        }

        else if(this.props.userSignInData.GroupName ==='Google') {
          if(isMobile){
            statCardRowDiv = 
              <div>          
                <div style={{marginBottom: '1px'}}>
                  {networkHealthDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                  {lateShipmentsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                  {subscriptionsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                  {inTransitDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                  {hotShipmentsDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                  {omniActionDiv}
                </div>
                <div style={{marginBottom: '1px'}}>
                  {googleActionDiv}
                </div>
              </div>
          }
          else{
              statCardRowDiv = 
              <div>
              <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-3" >
                  {networkHealthDiv}
                </div>
                <div className="col-md-3" >
                  {lateShipmentsDiv}
                </div>
    
                <div className="col-md-6"  >
                  {subscriptionsDiv}
                </div>
              </div>
              <br/>
                <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-3" >
                  {inTransitDiv}
                </div>
                <div className="col-md-3" >
                  {hotShipmentsDiv}
                </div>
                <div className="col-md-3"  >
                  {omniActionDiv}
                </div>
                <div className="col-md-3"  >
                  {googleActionDiv}
                </div>
              </div>
              </div>
          }

          
        }



        return(

          <div>
            {statCardRowDiv}
          </div>

);
}
}


export default withRouter(StatsCardRow);



// //KPI card
// <div className="col-md-3"  >
// <div style={{cursor: 'pointer'}} onClick={this.onClickKPI}>
//     <StatsCard
//         statValue={KPIMissed}
//         statLabel={'Shipments with KPIs Missed'}
//         icon={<i className="fa fa-tachometer" />}
//         backColor={this.ShipmentNumberColor(KPIMissed)}
//     />
//     </div>
// </div>