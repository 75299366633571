// this is the detailed view from clicking a row in the shipment tracker table

import ShipmentDetailsContainer from '../../../components/ComponentsGoogle/ShipmentDetails/ShipmentDetailsContainer';
import GoogleMapDetailContainer from '../../../components/ComponentsGoogle/GoogleMap/GoogleMapDetailContainer';
import React                   from 'react';
import AnimatedView from '../../../components/ComponentsGeneral/animatedView/AnimatedView';

  class ShipmentTrackerDetails extends React.Component {
    render() {
    
      return(
      
        
        <AnimatedView>
          <ShipmentDetailsContainer />
          <div style = {{ height: '500px', width: '100%', position: 'relative', marginRight: '30px', marginBottom: '40px' }} >
              <GoogleMapDetailContainer />
          </div>
        </AnimatedView>
      

      );
    }
  }
  
  export default ShipmentTrackerDetails;
  