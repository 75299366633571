import {connect} from 'react-redux';
import React, {Component} from 'react';
import GBMawbDetails from './GBMawbDetails'
import * as actions from '../../../../redux/actions/gbActions';
import {appSettings} from '../../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';


const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    isMawbDataLoading:                  store.gbDataReducer.isMawbDataLoading,
    MawbDetailData:                     store.gbDataReducer.MawbDetailData,

    isMCDataLoading:                    store.gbDataReducer.isMCDataLoading,
    MCDetailData:                       store.gbDataReducer.MCDetailData,

    isHawbDataLoading:                  store.gbDataReducer.isHawbDataLoading,
    HawbDetailData:                     store.gbDataReducer.HawbDetailData,

    isJobEventsDataLoading:             store.gbDataReducer.isJobEventsDataLoading,
    JobEventsData:                      store.gbDataReducer.JobEventsData,

});

const mapDispatchToProps = dispatch =>
({

    gbGetMawbDetails:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _customercode, _shipmenttype ) =>
    {
        dispatch( actions.gbGetMawbDetails( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter,_filterValue, _customercode, _shipmenttype ) )
    },

    gbGetMCDetails:( _db, _email, _mawb, _mc, _hawb ) =>
    {
        dispatch( actions.gbGetMCDetails( _db, _email,  _mawb, _mc, _hawb ) )
    },

    gbGetHawbDetails:( _db, _email, _mawb, _mc, _hawb, _trackingno ) =>
    {
        dispatch( actions.gbGetHawbDetails( _db, _email,  _mawb, _mc, _hawb, _trackingno ) )
    },

    gbGetJobEvents:( _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter) =>
    {
        dispatch( actions.gbGetJobEvents(  _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter ) )
    },





    
});


class GBMawbDetailsContainer extends Component
{
	constructor(props)
	{
		super(props);
	}

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null

    var paramMawb = null
    var paramMC = null
    var paramHawb = null
    var paramFilter = null
    var paramFilterValue = null
    var paramCustomerCode = null
    var paramShipmentType = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }

    if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
        paramMawb = values.mawb
    }
    if(typeof(values.mc) !== 'undefined' && values.mc != null ){
        paramMC = values.mc
    }
    if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
        paramHawb = values.hawb
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }
    if(typeof(values.customercode) !== 'undefined' && values.customercode != null ){
        paramCustomerCode = values.customercode
    }
    if(typeof(values.shipmenttype) !== 'undefined' && values.shipmenttype != null ){
        paramShipmentType = values.shipmenttype
    }

    this.props.gbGetMawbDetails( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, paramFilterValue, paramCustomerCode, paramShipmentType );

    this.props.gbGetMCDetails( appSettings.gbDB, this.props.currentUserEmail, paramMawb, paramMC, paramHawb );

    this.props.gbGetJobEvents( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramMawb, paramMC, paramHawb, paramFilter );
    
    this.props.gbGetHawbDetails( appSettings.gbDB, this.props.currentUserEmail, paramMawb, paramMC, paramHawb, null );

    }

	
	
    render()
    {

    	return (
            
            <GBMawbDetails

                routeCustomer='gaobo'
                
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isMawbDataLoading={this.props.isMawbDataLoading}
                MawbDetailData={this.props.MawbDetailData}

                isMCDataLoading={this.props.isMCDataLoading}
                MCDetailData={this.props.MCDetailData}

                isHawbDataLoading={this.props.isHawbDataLoading}
                HawbDetailData={this.props.HawbDetailData}
           
                isJobEventsDataLoading={this.props.isJobEventsDataLoading}
                JobEventsData={this.props.JobEventsData}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GBMawbDetailsContainer));



