import React, {
    PureComponent
}                         from 'react';

import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import GoogleMapTrackerContainer       from '../../../components/ComponentsGoogle/GoogleMap/GoogleMapTrackerContainer';


class mapShipTrackerView extends PureComponent {



    render() {

        return(
            <AnimatedView>

                <GoogleMapTrackerContainer />

            </AnimatedView>



        );
    }
}

export default mapShipTrackerView;

