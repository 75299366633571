import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';

import Sharedtextarea from "../../ComponentsGeneral/Text/Textarea";
import Button from 'react-bootstrap/Button'


class UPSLabelCreation extends PureComponent {
    constructor() {
      super();
      this.state = {
        // name: "React",
        input: '',
        jsonError: 'null'

      };
      this.handleChange = this.handleChange.bind(this);
      this.handleChangeJsonError = this.handleChangeJsonError.bind(this);
    }

    componentDidMount() {
      var initialstring = JSON.stringify(this.successjson)
      this.setState({ 
        input: initialstring
      });
    }
  
    handleChange(event) {
    //   console.log(event.target.value);
      this.setState({ 
        input: event.target.value
      });
    }
    handleChangeJsonError(event) {

        this.setState({ 
          jsonError: event
        });
  
      }


    successjson = {
      "Description": "Ship WS test",
    
       "USPSEndorsement": "1",
  
       "PackageID": "123",
      
  
      "Package": {
        "Description": "Box",
        "Dimensions": {
          "Height": "2",
          "Length": "7",
          "UnitOfMeasurement": {
            "Code": "IN",
            "Description": "Inches"
          },
          "Width": "5"
        },
        "PackageWeight": {
          "UnitOfMeasurement": {
            "Code": "LBS",
            "Description": "Pounds"
          },
          "Weight": "8"
        },
        "Packaging": {
          "Code": "63",
          "Description": "Box"
        }
      },
      "PaymentInformation": {
        "ShipmentCharge": {
          "BillShipper": {
            "AccountNumber": "9X63R0"
          },
          "Type": "01"
        }
      },
      "Service": {
        "Code": "M4",
        "Description": "Expedited MaiI Innovations"
      },
      "ShipFrom": {
        "Address": {
          "AddressLine": "12833 Chadron Ave",
          "City": "Hawthorn",
          "CountryCode": "US",
          "PostalCode": "90250",
          "StateProvinceCode": "CA"
        },
        "AttentionName": "Company",
        "FaxNumber": "",
        "Name": "Tofba International Inc"
      },
      "ShipTo": {
        "Address": {
          "AddressLine": "14112 82nd PL NE",
          "City": "Kirkland",
          "CountryCode": "US",
          "PostalCode": "98034",
          "StateProvinceCode": "WA"
        },
        "AttentionName": "",
        "Name": "Bill Coleman",
        "Phone": {
          "Number": "2532171565"
        }
      },
      "Shipper": {
        "Address": {
          "AddressLine": "718 S. Isis Ave #B",
          "City": "Inglewood",
          "CountryCode": "US",
          "PostalCode": "90301",
          "StateProvinceCode": "CA"
        },
        "AttentionName": "",
        "FaxNumber": "",
        "Name": "Micom CHB Inc",
        "Phone": {
          "Extension": "1",
          "Number": "18007706536"
        },
        "ShipperNumber": "9X63R0",
        "TaxIdentificationNumber": ""
  
      }
    }
  
  
  
  
    

    createLabelClick = () => {
      this.handleChangeJsonError('null')


      // console.log(JSON.parse(JSON.stringify(questionGlobal)));

      



      try{
        // console.log('state', this.state.input)
        // console.log('successjson', this.successjson)
        this.props.upsLabelCreation( JSON.parse(this.state.input))
      }
      catch(e){
        // console.log('error e', e.message)

        // console.error(e); 


        this.handleChangeJsonError(e.message)
      }
      
      // this.props.upsLabelCreation( this.successjson )




        // var paramMawb = this.props.MawbDetailData[0].MAWB
        // var paramHawb = this.props.MawbDetailData[0].HAWB
        
        //   this.props.history.push({
        //   pathname: 'shipdetails',
        //   search: 'mawb=\'' + paramMawb +'\'' + '&' +
        //   'hawb=\'' + paramHawb +'\''
        //   }); 


  
      }

    defaultjson = {
      "Description": "Ship WS test",
    
       "USPSEndorsement": "1",
  
       "PackageID": "123",
      
  
      "Package": {
        "Description": "Box",
        "Dimensions": {
          "Height": "2",
          "Length": "7",
          "UnitOfMeasurement": {
            "Code": "IN",
            "Description": "Inches"
          },
          "Width": "5"
        },
        "PackageWeight": {
          "UnitOfMeasurement": {
            "Code": "LBS",
            "Description": "Pounds"
          },
          "Weight": "8"
        },
        "Packaging": {
          "Code": "63",
          "Description": "Box"
        }
      },
      "PaymentInformation": {
        "ShipmentCharge": {
          "BillShipper": {
            "AccountNumber": "9X63R0"
          },
          "Type": "01"
        }
      },
      "Service": {
        "Code": "M4",
        "Description": "Expedited MaiI Innovations"
      },
      "ShipFrom": {
        "Address": {
          "AddressLine": "12833 Chadron Ave",
          "City": "Hawthorn",
          "CountryCode": "US",
          "PostalCode": "90250",
          "StateProvinceCode": "CA"
        },
        "AttentionName": "Company",
        "FaxNumber": "",
        "Name": "Tofba International Inc"
      },
      "ShipTo": {
        "Address": {
          "AddressLine": "14112 82nd PL NE",
          "City": "Kirkland",
          "CountryCode": "US",
          "PostalCode": "98034",
          "StateProvinceCode": "WA"
        },
        "AttentionName": "",
        "Name": "Bill Coleman",
        "Phone": {
          "Number": "2532171565"
        }
      },
      "Shipper": {
        "Address": {
          "AddressLine": "718 S. Isis Ave #B",
          "City": "Inglewood",
          "CountryCode": "US",
          "PostalCode": "90301",
          "StateProvinceCode": "CA"
        },
        "AttentionName": "",
        "FaxNumber": "",
        "Name": "Micom CHB Inc",
        "Phone": {
          "Extension": "1",
          "Number": "18007706536"
        },
        "ShipperNumber": "9X63R0",
        "TaxIdentificationNumber": ""
  
      }
    }
  
  
  
  
      
  
    render() {

      // console.log('error', this.props.LabelCreationErrorCode)


      let labelCreate;
      if (this.props.isLabelCreationLoading) {
            labelCreate=
            <div align="center">
            <section className="panel">
            </section>
            <Loader 
                type="Plane"
                color="#00BFFF"
                height="50"	
                width="50"
            /> 
            </div>
      }  
      else if ( this.state.jsonError != 'null' ){
          console.log('LabelCreationText', this.state.jsonError)
          labelCreate=
          <div align="center">
          <section className="panel">
              <panel className="panel">
                  {this.state.jsonError}
              </panel>
          </section>
          </div>

      }
      else if (this.props.LabelCreationErrorCode) {
            console.log('LabelCreationText', this.props.LabelCreationText)
            labelCreate=
            <div align="center">
            <section className="panel">
                <panel className="panel">
                    {this.props.LabelCreationText}
                </panel>
            </section>
            </div>
      }
      else if (typeof(this.props.LabelCreationText) !== 'undefined' && this.props.LabelCreationText != null) {
            labelCreate =
            <div align="center">
              <img src={"data:image/png;base64," +  this.props.LabelCreationText} />
            <section className="panel">
                <header className="panel-heading">
                </header>
            </section>
            </div>
        
      }




      return (
        <div>
            <div>
                {labelCreate}
            </div>
            <div>
                <Button variant="success" size="sm" block   onClick={this.createLabelClick} >
                Create Label
                </Button>
            </div>
            <div>
            <Sharedtextarea 
                handleChange={this.handleChange} 
                // defaultValue={JSON.stringify(this.successjson)}
                defaultValue={JSON.stringify(this.successjson, undefined, 4)}
            />
            </div>
        </div>
      );
    }
  }
  


  export default withRouter(UPSLabelCreation);

