////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DatePicker from "react-datepicker"; //https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select"; //https://sanusart.github.io/react-dropdown-select/
import * as moment from 'moment'

////local imports

// import {//valueDeliveryDayFormatter, 
//     // buttonApproveAccount, 
//     // buttonRejectAccount, 
//   // hotShipmentIcon
// } from '../../resources/logic/formatting';
import Button from 'react-bootstrap/Button'

import {KPIValueFormatter, 
  buttonFormatter,
  PercentFormatter
  // milestoneAlertDetailsSeverityFormatter,
  // milestoneCustomerAlertFormatter
} from '../../../resources/logic/formatting';


const calStartValue = new Date();
calStartValue.setDate( calStartValue.getDate() - 31 );

// const today = new Date();
// today.setDate( today.getDate());
// today.moment().format('YYYY-MM-DD')


// var currentDateTime = moment().format("_YYYY_MMM_DDD-HH_mm")
// var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ssZ")
var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")



  class DataExport extends PureComponent {




    constructor( props )
    {
      super( props );

      this.state = {
        startDate: calStartValue,
        // startDate: moment().format('YYYY-MM-DD'),
        endDate: new Date(),
        // endDate: moment().format('YYYY-MM-DD'),
        radioDateType: 'intransit',
        dropDownValueForwarder: '',
        dropDownValueMode: '',
        dropDownService: '',
        dropDownLane: '',
        calendarActive: true

      };
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
      this.handleDropdownChangeForwarder = this.handleDropdownChangeForwarder.bind(this);
      this.handleDropdownChangeMode = this.handleDropdownChangeMode.bind(this);

      this.handleDropdownChangeService = this.handleDropdownChangeService.bind(this);
      this.handleDropdownChangeLane = this.handleDropdownChangeLane.bind(this);
      this.setEventDate = this.setEventDate.bind(this);
    }

    handleChangeStartDate(date) {
      this.setState({
        startDate: date
      });
    }
  
    handleChangeEndDate(date) {
      this.setState({
        endDate: date
      });
    }

    handleDropdownChangeForwarder(value) {
      this.setState({ 
        dropDownValueForwarder: value
      });
    }

    handleDropdownChangeMode(value) {
      this.setState({ 
        dropDownValueMode: value
      });
    }

    handleDropdownChangeService(value) {
      this.setState({ 
        dropDownService: value
      });
    }

    handleDropdownChangeLane(value) {
      this.setState({ 
        dropDownLane: value
      });
    }

    setEventDate(event) {

      if(event.target.value === 'intransit') {
        this.setState({ 
          calendarActive: true
        });
      }

      else {
        this.setState({ 
          calendarActive: false
        });
      }

     
      this.setState({ 
        radioDateType: event.target.value
      });
      

    }


    clickApplyFilter( e ) {
      var forwarderFilter = null;
      var modeFilter = null;
      var serviceFilter = null;
      var laneFilter = null;
      var dateTypeFilter = 'intransit';

      if (typeof(this.state.dropDownValueForwarder[0]) !== 'undefined' && this.state.dropDownValueForwarder[0] != null)
      {
       
        forwarderFilter = this.state.dropDownValueForwarder[0].value
      }

      if (typeof(this.state.dropDownValueMode[0]) !== 'undefined' && this.state.dropDownValueMode[0] != null)
      {
        
        modeFilter = "'" + this.state.dropDownValueMode[0].value + "'"
        
      }

      if (typeof(this.state.dropDownService[0]) !== 'undefined' && this.state.dropDownService[0] != null)
      {
       
        serviceFilter = "'" + this.state.dropDownService[0].value + "'"
        
      }

      if (typeof(this.state.dropDownLane[0]) !== 'undefined' && this.state.dropDownLane[0] != null)
      {
    
        laneFilter = "'" + this.state.dropDownLane[0].value + "'"
        
      }

      if (typeof(this.state.radioDateType) !== 'undefined' && this.state.radioDateType != null)
      {
      
        dateTypeFilter = "'" + this.state.radioDateType + "'"
        
      }

       
      this.props.getPivotTableData( this.props.userSignInData.DBName, this.props.currentUserEmail, "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'", "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'", dateTypeFilter, modeFilter, forwarderFilter, serviceFilter, laneFilter)


    }




    render() {
      const { ExportCSVButton } = CSVExport;

      const selectMode = [
        { label: "Air", value: "AIR" },
        { label: "Ocean", value: "OCEAN" }
      ];

      const selectForwarder = [
        { label: "Omni", value: "OMNI" }
      ];

      const selectService = [
        { label: "A1", value: "A1" },
        { label: "A5", value: "A5" },
        { label: "AI", value: "AI" },
        { label: "BA", value: "BA" },
        { label: "BO", value: "BO" },
        { label: "ND", value: "ND" },
        { label: "O1", value: "O1" },
        { label: "O6", value: "O6" },
        { label: "SM", value: "SM" }
      ];


      const selectLane = [
        { label: "AU-PL", value: "AU-PL" },
        { label: "CA-FR", value: "CA-FR" },
        { label: "CA-HK", value: "CA-HK" },
        { label: "CA-NL", value: "CA-NL" },
        { label: "CN-IN", value: "CN-IN" },
        { label: "CN-JP", value: "CN-JP" },
        { label: "CN-US", value: "CN-US" },
        { label: "FR-US", value: "FR-US" },
        { label: "KR-US", value: "KR-US" },
        { label: "SZ-US", value: "SZ-US" },
        { label: "TW-IN", value: "TW-IN" },
        { label: "TW-JP", value: "TW-JP" },
        { label: "TW-PL", value: "TW-PL" },
        { label: "TW-SG", value: "TW-SG" },
        { label: "TW-US", value: "TW-US" },
        { label: "US-AU", value: "US-AU" },
        { label: "US-CN", value: "US-CN" },
        { label: "US-FR", value: "US-FR" },
        { label: "US-GB", value: "US-GB" },
        { label: "US-HK", value: "US-HK" },
        { label: "US-JP", value: "US-JP" },
        { label: "US-NL", value: "US-NL" },
        { label: "US-SG", value: "US-SG" },
        { label: "US-TW", value: "US-TW" },
        { label: "US-US", value: "US-US" }
      ];
      


      const dataExportColumns = [
        {
        dataField: 'HouseBill',
        text: 'HouseBill'
        }, {
        dataField: 'CurrentShipmentStatus',
        text: 'CurrentShipmentStatus'
        }, {
        dataField: 'CurrentShipmentStatusDateTime',
        text: 'CurrentShipmentStatusDT'
    }, {
        dataField: 'PercentComplete',
        text: 'PercentComplete'
    }, {
        dataField: 'Mode',
        text: 'Mode'
    }, {
        dataField: 'Lane',
        text: 'Lane'
    }, {
        dataField: 'PackageType',
        text: 'PackageType'
    }, {
        dataField: 'ServiceLevel',
        text: 'ServiceLevel'
    }, {
        dataField: 'Carrier',
        text: 'Carrier'
    }, {
        dataField: 'PODSignedBy',
        text: 'PODSignedBy'
    }, {
        dataField: 'PackageCount',
        text: 'PackageCount'
    }, {
        dataField: 'ShipperRefNumber',
        text: 'ShipperRefNumber'
    }, {
        dataField: 'DeliveryDaysRemaining',
        text: 'DeliveryDaysRemaining'
    }, {
        dataField: 'PickupApptDateTime',
        text: 'PickupApptDateTime'
    }, {
        dataField: 'DeliveryAppointmentDateTimeApptDateTime',
        text: 'DeliveryAppointmentDateTimeApptDT'
    }, {
        dataField: 'ScheduledDeliveryDate',
        text: 'ScheduledDeliveryDate'
    }, {
        dataField: 'EstimatedDeliveryDate',
        text: 'EstimatedDeliveryDate'
    }, {
        dataField: 'SCHDate',
        text: 'RequiredDeliveryDate'
    }, {
        dataField: 'EstimatedDepartureDate',
        text: 'EstimatedDepartureDate'
    }, {
        dataField: 'Value',
        text: 'Gross Weight'
    }, {
        dataField: 'Units',
        text: 'Units'
    }, {
        dataField: 'SF_AddressLine',
        text: 'SF_AddressLine'
    }, {
        dataField: 'SF_City',
        text: 'SF_City'
    }, {
        dataField: 'SF_StateProvinceCode',
        text: 'SF_StateProvinceCode'
    }, {
        dataField: 'SF_PostalCode',
        text: 'SF_PostalCode'
    }, {
        dataField: 'SF_CountryCode',
        text: 'SF_CountryCode'
    }, {
        dataField: 'ST_AddressLine',
        text: 'ST_AddressLine'
    }, {
        dataField: 'ST_City',
        text: 'ST_City'
    }, {
        dataField: 'ST_StateProvinceCode',
        text: 'ST_StateProvinceCode'
    }, {
        dataField: 'ST_PostalCode',
        text: 'ST_PostalCode'
    }, {
        dataField: 'ST_CountryCode',
        text: 'ST_CountryCode'
    }, {
        dataField: 'OmniYellowAlert',
        text: 'OmniYellowAlert'
    }, {
        dataField: 'OmniRedAlert',
        text: 'OmniRedAlert'
    }, {
        dataField: 'CustomerYellowAlert',
        text: 'CustomerYellowAlert'
    }, {
        dataField: 'CustomerRedAlert',
        text: 'CustomerRedAlert'
    }, {
        dataField: 'KPIMissed',
        text: 'KPIMissed'
    }, {
        dataField: 'Status',
        text: 'Status'
    }, {
        dataField: 'HotShipment',
        text: 'HotShipment'
    }, {
        dataField: 'OmniAction',
        text: 'OmniAction'
    }, {
        dataField: 'GoogleAction',
        text: 'GoogleAction'
    }, {
        dataField: 'HasComment',
        text: 'HasComment'

        }
      ]


      let datePickerDiv;
      datePickerDiv = 
        <div className="sm-st clearfix" style={{margin: '5px', marginLeft: '15px'}}>
        <div className="row">  
          <div style={{margin: '5px', marginLeft: '15px'}}>  
              <header >
                {'Start Date'}
              </header>
                <DatePicker
                    placeholderText="Select Start Date"
                    selected={this.state.startDate}
                    selectsStart
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={this.handleChangeStartDate}
                    disabled={this.state.calendarActive}
                />
          </div>
          <div style={{margin: '5px', marginRight: '25px'}}>  
              <header >
                {'End Date'}
              </header>
                <DatePicker
                    placeholderText="Select End Date"
                    selected={this.state.endDate}
                    selectsEnd
                    startDate={this.state.startDate}
                    endDate={this.state.endDate}
                    onChange={this.handleChangeEndDate}
                    minDate={this.state.startDate}
                    disabled={this.state.calendarActive}
                />
          </div>
        </div>
        <div onChange={this.setEventDate.bind(this)}>
          <input type="radio" value="booked" name="eventdate"/> Booked &nbsp;
          <input type="radio" value="shipped" name="eventdate"/> Shipped &nbsp;
          <input type="radio" value="delivered" name="eventdate" /> Delivered &nbsp;
          <input type="radio" value="intransit" name="eventdate" defaultChecked /> In Transit &nbsp;
        </div>
      </div>

      let selectModeDiv;
      selectModeDiv = 
          <div className="sm-st clearfix" style={{margin: '5px'}}>
          <div className="row">  
          <div style={{backgroundColor: '#FFFFFF', margin: '5px'}}>
                  <header>
                    {'Mode'}
                  </header>
                <Select 
                  options={ selectMode } 
                  clearable
                  separator
                  placeholder={ 'Mode...'}
                  onChange={this.handleDropdownChangeMode}
                />
                </div>
          </div>
        </div>

      let selectForwarderDiv;
      selectForwarderDiv = 
          <div className="sm-st clearfix" style={{margin: '5px'}}>
          <div className="row">  
          <div style={{backgroundColor: '#FFFFFF', margin: '5px'}}>
                <header>
                    {'Forwarder'}
                  </header>
                <Select 
                  options={ selectForwarder } 
                  clearable
                  separator
                  placeholder={ 'Forwarder...'}
                  onChange={this.handleDropdownChangeForwarder}
                />
                </div>
          </div>
        </div>

      let selectServiceDiv;
      selectServiceDiv = 
          <div className="sm-st clearfix" style={{margin: '5px'}}>
          <div className="row">  
          <div style={{backgroundColor: '#FFFFFF', margin: '5px'}}>
                  <header>
                    {'Service'}
                  </header>
                <Select 
                  options={ selectService } 
                  clearable
                  separator
                  placeholder={ 'Service...'}
                  onChange={this.handleDropdownChangeService}
                />
                </div>
          </div>
        </div>

      let selectLaneDiv;
      selectLaneDiv = 
        <div className="sm-st clearfix" style={{margin: '5px'}}>
        <div className="row">  
        <div style={{backgroundColor: '#FFFFFF', margin: '5px'}}>
                <header>
                  {'Lane'}
                </header>
              <Select 
                options={ selectLane } 
                clearable
                separator
                placeholder={ 'Lane...'}
                onChange={this.handleDropdownChangeLane}
              />
              </div>
        </div>
      </div>


      let filterRowDiv;
      if(this.props.userSignInData.GroupName ==='OMNI' || this.props.userSignInData.GroupName ==='Demo' ) {
        filterRowDiv = 
        <div className="row" style={{padding: '15px'}}>  

          {datePickerDiv}

          {selectModeDiv}

          {selectForwarderDiv}

          {selectServiceDiv}

          {selectLaneDiv}

        </div>
      }

      else if(this.props.userSignInData.GroupName ==='Google') {
        filterRowDiv = 
        <div className="row" style={{padding: '15px'}}>  

          {datePickerDiv}

          {selectModeDiv}

          {selectLaneDiv}

        </div>
      }


      let bootStrapTableExport;
      if (this.props.isPivotTableLoading) {
        bootStrapTableExport=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.pivotTableData) !== 'undefined' && this.props.pivotTableData != null) {
        
        bootStrapTableExport =

        <div className="panel-body table-responsive"
        style={{backgroundColor: '#FFFFFF', cursor: 'default', width: '6000px'}}>
        <header className="panel-heading">
        &nbsp; { `Data Preview: ${this.props.pivotTableData.length} rows`}
        </header>
        <ToolkitProvider
        keyField="UniqueID"
        // data={ this.props.kpiOverviewData }
        data={ this.props.pivotTableData }
        columns={ dataExportColumns }
        exportCSV = { {
          fileName: 'DashboardExport'+ currentDateTime +'.csv'
          // separator: '|',
          // ignoreHeader: true,
          // noAutoBOM: false
        } }
      >
        {
          props => (
            <div style={{fontSize: 10}}>
              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
              <hr />
              <BootstrapTable { ...props.baseProps }  pagination={ paginationFactory() } />
            </div>
          )
        }
      </ToolkitProvider>

      </div>

      } else {
        bootStrapTableExport = <div></div>;
      }

      return(
        <div>
        <section className="panel">
        <header className="panel-heading">
          {'Filters'}
        </header>

        <div //className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}
            >

            {filterRowDiv}
          

        <div style={{paddingBottom: '25px', paddingLeft: '15px', paddingRight: '15px'}}>

        <Button variant="success" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.clickApplyFilter(e)}>
        Apply Filter
          </Button>


        </div>

          </div>

      </section>


            { bootStrapTableExport }


          

          </div>
      );
    }
  }

  export default withRouter(DataExport);
  









