import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import {appSettings} from '../../../app/appSettings'

import GBDtlHawbDetailsContainer from '../../../components/ComponentsGB/GBShipmentDetails/GBDtlHawbDetails/GBDtlHawbDetailsContainer'

export default class gbDtlHawbDetailView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }


        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>


            <AnimatedView>
                
            <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 
            <GBDtlHawbDetailsContainer isMobile={isMobile}/>
            </div>

            </AnimatedView>
            </div>




                


        );
    }
}
