import {connect} from 'react-redux';
import React, {Component} from 'react';
import GoogleMapTracker from './GoogleMapTracker'
import * as actions from '../../../redux/actions/googleActions';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

//add props from reducer here
const mapStateToProps = store =>
({
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail,

    shipmentGPSData:                store.shipmentTrackerReducer.shipmentGPSData,
    isShipmentGPSLoading:           store.shipmentTrackerReducer.isShipmentGPSLoading,
    
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getShipmentGPSData:( _db, _email, _housebill, _jobid, _mode, _forwarder, _service, _lane, _param, _startDT, _endDT, _jobStatus ) =>
    {
        dispatch( actions.getShipmentGPSData( _db, _email, _housebill, _jobid, _mode, _forwarder, _service, _lane, _param, _startDT, _endDT, _jobStatus ) )
    },

});


class GoogleMapDetailContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{
        const values = queryString.parse(this.props.location.search)

        this.props.getShipmentGPSData( this.props.userSignInData.DBName, this.props.currentUserEmail, values.housebill, null, null, null, null, null, null, null, null, null );
	}
	
    render()
    {
    	return (
            <GoogleMapTracker
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isShipmentGPSLoading={this.props.isShipmentGPSLoading}
                shipmentGPSData={this.props.shipmentGPSData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GoogleMapDetailContainer));

