import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import FilterMainZU         from '../../../components/ComponentsZU/FilterMainZU/FilterMainZU';
import ChartReportingZUContainer    from '../../../components/ComponentsZU/Reporting/ChartReportingZU/ChartReportingZUContainer';
import ShipmentListZUContainer    from '../../../components/ComponentsZU/ShipmentListZU/ShipmentListZUContainer';
import {appSettings} from '../../../app/appSettings'
import ChartShipToPortContainer from '../../../components/ComponentsZU/ChartShipToPort/ChartShipToPortContainer'

export default class zuReportingView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        UNSAFE_componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>
                
                <FilterMainZU isMobile={isMobile}/>

                <AnimatedView>
                <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 


                <div className="row" >
                    <div className="col-md-6">
                    <ChartReportingZUContainer isMobile={isMobile}/>
                    </div>
                    <div className="col-md-6">
                    <ChartShipToPortContainer isMobile={isMobile}/>
                    </div>

                </div>

                    <ShipmentListZUContainer isMobile={isMobile}/>

                </div>
                </AnimatedView>

            </div>

        );
    }
}
