import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';

const SearchInput = ({
    searchInputValue,            
    searchTitle,                
    searchOnChange,             
    searchOnSubmit, 
    searchOnKeyPress,              
    isMobile
    }) => (

        <div>
        {(() => {
            switch (isMobile) {
              case false:   return     (    //NOT MOBILE
                    <form
                        className="sidebar-form"
                        onKeyPress={searchOnKeyPress}
                        >
                        <div className="input-group" title= { searchTitle } >
                        <input
                            type="text"
                            name="searchinput"
                            className="form-control"
                            placeholder={searchTitle}
                            value={searchInputValue}
                            onChange={searchOnChange}
                
                            // style={ {fontSize: 15} }
                            />
                        <span className="input-group-btn">
                            <button
                            onClick={searchOnSubmit}
                            name="search"
                            id="search-btn"
                            className="btn btn-flat">
                            <i className="fa fa-search"></i>
                            </button>
                        </span>
                        </div>
                    </form>

              );

              case true: return (   //IS MOBILE
    
                <form
                    className="sidebar-form"
                    onKeyPress={searchOnKeyPress}
                    >
                    <div className="input-group" title= { searchTitle } >
                    <input
                        type="text"
                        name="searchinput"
                        className="form-control"
                        placeholder={searchTitle}
                        value={searchInputValue}
                        onChange={searchOnChange}
            
                        style={ {fontSize: 11} }
                        />
                    <span className="input-group-btn">
                        <button
                        onClick={searchOnSubmit}
                        name="search"
                        id="search-btn"
                        className="btn btn-flat">
                        <i className="fa fa-search"></i>
                        </button>
                    </span>
                    </div>
                </form>





              );
              default: return <div></div>;
            }
          })()}
        </div>







);

SearchInput.propTypes = {
    searchInputValue:               PropTypes.string,
    searchTitle:                    PropTypes.string, //EX: "Search by House Bill, PO, ASN" 
    searchOnChange:                 PropTypes.func,
    searchOnSubmit:                 PropTypes.func,
    searchOnKeyPress:               PropTypes.func,
    isMobile:                       PropTypes.bool
};


export default SearchInput;

