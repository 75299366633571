////npm imports
import React, {
    PureComponent
} from 'react';
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withRouter } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment'

import DropDownSelectFilter from '../../ComponentsGeneral/Filters/DropDownSelectFilter/DropDownSelectFilter';
import ButtonGeneral from '../../ComponentsGeneral/Buttons/ButtonGeneral';
import DateFilter from '../../ComponentsGeneral/Filters/DateFilter/DateFilter';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button'
import Sidebar from "react-sidebar";
import SearchInput from '../../ComponentsGeneral/Filters/SearchInput/SearchInput';
// import {
//     Table,
//     TableBody
//   }                         from '../../../components/ComponentsGeneral/table';



const calStartValue = new Date();
calStartValue.setDate(calStartValue.getDate() - 30);

const calMinDateValue = new Date();
calMinDateValue.setDate(calMinDateValue.getDate() - 90);


class FilterMainZU extends PureComponent {




    constructor(props) {
        super(props);

        this.state = {
            sidebarOpen: false,

            selectPortEntry: [{ label: "", value: "" }],

            //datepicker state
            startDate: 'null',
            endDate: 'null',
            calendarActive: true,
            calendarDisabled: true,
            datePickerRadio: 'null',
            radioDateType: 'null',

            //radio filters
            radioNotDeparted: 'null',
            radioInTransit: 'intransit',

            //dropdown state
            dropDownPortExitState: '',
            dropDownPortEntryState: '',
            dropDownFinalCarrierState: '',
            dropDownShipToCountry: '',


            //url param filters
            filterStartDT: calStartValue,
            filterEndDT: 'null',
            filterRadioDateType: 'null',
            filterPortExitState: '',
            filterPortEntryState: '',
            filterFinalCarrierState: '',
            filterNotDeparted: 'null',
            filterInTransit: 'intransit',
            filterDatePickerRadio: 'null',
            filterShipToCountry: '',

            //search input box
            searchinput: ''

        };

        //Side Bar Filter menu
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

        //Date picker
        this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
        this.handleChangeEndDate = this.handleChangeEndDate.bind(this);

        //Date picker type
        this.setEventDate = this.setEventDate.bind(this);

        //Radio button filters
        this.setNotDeparted = this.setNotDeparted.bind(this);
        this.setInTransit = this.setInTransit.bind(this);
        this.setDatePickerRadio = this.setDatePickerRadio.bind(this);
        //Drop down select
        this.handleDropdownPortExit = this.handleDropdownPortExit.bind(this);
        this.handleDropdownPortEntry = this.handleDropdownPortEntry.bind(this);
        this.handleDropdownFinalCarrier = this.handleDropdownFinalCarrier.bind(this);
        this.handleDropdownShipToCountry = this.handleDropdownShipToCountry.bind(this);

        //Update url filters
        this.handleFilterStartDT = this.handleFilterStartDT.bind(this);
        this.handleFilterEndDT = this.handleFilterEndDT.bind(this);
        this.handleFilterRadioDateType = this.handleFilterRadioDateType.bind(this);
        this.handleFilterPortExit = this.handleFilterPortExit.bind(this);
        this.handleFilterPortEntry = this.handleFilterPortEntry.bind(this);
        this.handleFilterFinalCarrier = this.handleFilterFinalCarrier.bind(this);
        this.handleFilterRadioNotDeparted = this.handleFilterRadioNotDeparted.bind(this);
        this.handleFilterRadioInTransit = this.handleFilterRadioInTransit.bind(this);
        this.handleFilterRadioDatePicker = this.handleFilterRadioDatePicker.bind(this);
        this.handleFilterShipToCountry = this.handleFilterShipToCountry.bind(this);

    }

    //Side Bar filter menu
    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    //Date picker
    handleChangeStartDate(date) {
        this.setState({
            startDate: date
        });
    }
    handleChangeEndDate(date) {
        this.setState({
            endDate: date
        });
    }
    setDatePickerRadio(event) {

        if (event.target.value == 1) {
            this.setState({
                calendarDisabled: false,
                datePickerRadio: event.target.value,
                startDate: calStartValue,
                endDate: null,
                radioDateType: 'Manifest',
                filterRadioDateType: 'Manifest',
            });
        }
        else {
            this.setState({
                calendarDisabled: true,
                datePickerRadio: event.target.value,
                radioDateType: null,
                filterRadioDateType: null
                // startDate: 'null',
                // endDate: 'null'
            });
        }
        this.setState({
            datePickerRadio: event.target.value
        });
    }

    setEventDate(event) {
        this.setState({
            radioDateType: event.target.value
        });
    }

    //Radio Filters
    setNotDeparted(event) {
        this.setState({
            radioNotDeparted: event.target.value
        });
    }
    setInTransit(event) {
        this.setState({
            radioInTransit: event.target.value
        });
    }
    handleFilterRadioDatePicker(value) {
        this.setState({
            filterDatePickerRadio: value
        });
    }

    //Drop down select
    handleDropdownPortExit(value) {
        this.setState({
            dropDownPortExitState: value
        });
    }
    handleDropdownPortEntry(value) {
        this.setState({
            dropDownPortEntryState: value
        });
    }
    handleDropdownShipToCountry(value) {
        this.setState({
            dropDownShipToCountry: value
        });
    }
    handleDropdownFinalCarrier(value) {
        this.setState({
            dropDownFinalCarrierState: value
        });
    }

    //Update url filters
    handleFilterStartDT(value) {
        this.setState({
            filterStartDT: value
        });
    }
    handleFilterEndDT(value) {
        this.setState({
            filterEndDT: value
        });
    }
    handleFilterRadioDateType(value) {
        this.setState({
            filterRadioDateType: value
        });
    }
    handleFilterPortExit(value) {
        this.setState({
            filterPortExitState: value
        });
    }
    handleFilterPortEntry(value) {
        this.setState({
            filterPortEntryState: value
        });
    }
    handleFilterFinalCarrier(value) {
        this.setState({
            filterFinalCarrierState: value
        });
    }
    handleFilterRadioNotDeparted(value) {
        this.setState({
            filterNotDeparted: value
        });
    }
    handleFilterRadioInTransit(value) {
        this.setState({
            filterInTransit: value
        });
    }
    handleFilterShipToCountry(value) {
        this.setState({
            filterShipToCountry: value
        });
    }


    componentDidMount() {
        this.updateParamsFromURL()

        var getPortEntry = []
        try {
            getPortEntry = JSON.parse(localStorage.getItem('PageConfig.ZUShipToAirport'));
            this.setState({
                selectPortEntry: getPortEntry
            });

        }
        catch (e) {
            console.log('page config empty')

        }





    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.updateParamsFromURL()
        }
    }

    updateParamsFromURL = () => {
        // var paramStartDT = new Date()
        // var paramEndDT = new Date()

        const StartDT = new Date();
        // var paramStartDT = StartDT.setDate( StartDT.getDate() - 30 );

        const EndDT = new Date();
        // var paramEndDT = EndDT.setDate( EndDT.getDate() );

        var paramStartDT = 'null'
        var paramEndDT = 'null'

        const values = queryString.parse(this.props.location.search)

        if (typeof (values.startdt) !== 'undefined' && values.startdt != "null") {
            paramStartDT = StartDT.setDate(StartDT.getDate() - 30);
            paramStartDT = new Date(values.startdt)
            // paramStartDT.setDate( new Date(values.startdt) )
            this.handleFilterRadioDatePicker(false)
            this.handleChangeStartDate(paramStartDT)
            this.handleFilterStartDT(paramStartDT)

        }
        else {
            // this.handleChangeStartDate(paramStartDT)
            this.handleFilterRadioDatePicker(true)
            this.handleFilterStartDT(paramStartDT)
        }
        // if( values.enddt != 'null' ){
        if (typeof (values.enddt) !== 'undefined' && values.enddt != 'null') {
            paramEndDT = EndDT.setDate(EndDT.getDate());
            paramEndDT = new Date(values.enddt)
            // paramEndDT.setDate( new Date(values.enddt) );
            this.handleFilterRadioDatePicker(false)
            this.handleChangeEndDate(paramEndDT)
            this.handleFilterEndDT(paramEndDT)
        }
        else {
            this.handleFilterRadioDatePicker(true)
            // this.handleChangeEndDate(paramEndDT)
            this.handleFilterEndDT(paramEndDT)
        }
        // if( values.startdt != 'null' ){
        //     paramStartDT = new Date(values.startdt)
        //     this.handleChangeStartDate(paramStartDT)
        //     this.handleFilterStartDT(paramStartDT)
        // }
        // if( values.enddt != 'null' ){
        //     paramEndDT = new Date(values.enddt)
        //     this.handleChangeEndDate(paramEndDT)
        //     this.handleFilterEndDT(paramEndDT)
        // }
        if (values.datetype != 'null') {
            this.handleFilterRadioDateType(values.datetype)
        }
        if (values.notdeparted != 'undefined') {
            this.handleFilterRadioNotDeparted(values.notdeparted)
        }
        if (values.filter != 'undefined') {
            this.handleFilterRadioInTransit(values.filter)
        }


        this.handleFilterPortExit(values.portexit)
        this.handleFilterPortEntry(values.portentry)
        this.handleFilterFinalCarrier(values.finalcarrier)
        this.handleFilterShipToCountry(values.shiptocountry)

    }

    clickApplyFilter(e) {
        var dateTypeFilter = null;
        var notDepartedFilter = null;
        var portExitFilter = null;
        var portEntryFilter = null;
        var finalCarrierFilter = null;
        var inTransitFilter = null;
        var shipToCountryFilter = null;

        this.handleFilterStartDT(this.state.startDate)
        this.handleFilterEndDT(this.state.endDate)

        var sDT = null
        var eDT = null

        if (this.state.startDate === null) {
            sDT = null
        }
        else if (this.state.datePickerRadio != 'null') {
            sDT = "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'"
        }


        if (this.state.endDate === null) {
            eDT = null
        }
        else if (this.state.datePickerRadio != 'null') {
            eDT = "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'"
        }



        if (typeof (this.state.radioDateType) !== 'undefined' && this.state.radioDateType != null) {
            dateTypeFilter = this.state.radioDateType
            this.handleFilterRadioDateType(dateTypeFilter)
        }
        if (typeof (this.state.radioNotDeparted) !== 'undefined' && this.state.radioNotDeparted != null) {
            notDepartedFilter = this.state.radioNotDeparted
            this.handleFilterRadioNotDeparted(notDepartedFilter)
        }
        if (typeof (this.state.radioInTransit) !== 'undefined' && this.state.radioInTransit != null) {
            inTransitFilter = this.state.radioInTransit
            this.handleFilterRadioInTransit(inTransitFilter)
        }
        if (typeof (this.state.dropDownPortExitState[0]) !== 'undefined' && this.state.dropDownPortExitState[0] != null) {
            portExitFilter = this.state.dropDownPortExitState[0].value
            this.handleFilterPortExit(portExitFilter)
        }
        if (typeof (this.state.dropDownPortEntryState[0]) !== 'undefined' && this.state.dropDownPortEntryState[0] != null) {
            portEntryFilter = this.state.dropDownPortEntryState[0].value
            this.handleFilterPortEntry(portEntryFilter)
        }
        if (typeof (this.state.dropDownFinalCarrierState[0]) !== 'undefined' && this.state.dropDownFinalCarrierState[0] != null) {
            finalCarrierFilter = this.state.dropDownFinalCarrierState[0].value
            this.handleFilterFinalCarrier(finalCarrierFilter)
        }
        if (typeof (this.state.dropDownShipToCountry[0]) !== 'undefined' && this.state.dropDownShipToCountry[0] != null) {
            shipToCountryFilter = this.state.dropDownShipToCountry[0].value
            this.handleFilterShipToCountry(shipToCountryFilter)
        }
        // var sDT = null
        // var eDT = null

        // if (this.state.startDate === null ) {
        //     sDT = null
        // }
        // else {
        //     sDT = "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'"
        // }


        // if ( this.state.endDate === null ){
        //     eDT = null
        // }
        // else {
        //     eDT = "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'"
        // }


        this.props.history.push({
            search: 'startdt=' + sDT + '&' +
                'enddt=' + eDT + '&' +
                'datetype=' + dateTypeFilter + '&' +
                'notdeparted=' + notDepartedFilter + '&' +
                'portexit=' + portExitFilter + '&' +
                'portentry=' + portEntryFilter + '&' +
                'shiptocountry=' + shipToCountryFilter + '&' +
                'finalcarrier=' + finalCarrierFilter + '&' +
                'filter=' + inTransitFilter
        });

        this.onSetSidebarOpen(false)
        this.updateParamsFromURL()
    }





    //this handles search text input box update state
    onSearchChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });

    }

    //prevent search form input from submitting on enter key
    onSearchKeyPress(event) {
        if (event.which === 13 /* Enter */) {
            event.preventDefault();
        }
    }

    //this handles search text input box submit
    onSearchSubmit = (e) => {
        e.preventDefault();
        // get our form data out of state
        const { searchinput } = this.state;

        if (searchinput.trim() === "") {
            window.alert("Search Field is empty.");
            return
        }

        else {

            this.onSetSidebarOpen(false)

            var searchString = searchinput.trim().replace(/-/g, '')


            if (this.props.location.pathname === '/home/zulily') {
                // this.props.openShipDetails(paramjobid, row);
                this.props.history.push({
                    pathname: 'zulily/shiplist',
                    search: 'startdt=null' + '&' +
                        'enddt=null' + '&' +
                        'datetype=null' + '&' +
                        'notdeparted=null' + '&' +
                        'portexit=null' + '&' +
                        'portentry=null' + '&' +
                        'finalcarrier=null' + '&' +
                        'mawb=\'' + searchString.trim() + '\'' + '&' +
                        'filter=search' + '&' +
                        'shiptocountry=null'
                });
            }
            else if (this.props.location.pathname === '/home/zulily/shiplist/') {
                this.props.history.push({
                    // pathname: 'shipmentdetails/',
                    search: 'startdt=null' + '&' +
                        'enddt=null' + '&' +
                        'datetype=null' + '&' +
                        'notdeparted=null' + '&' +
                        'portexit=null' + '&' +
                        'portentry=null' + '&' +
                        'finalcarrier=null' + '&' +
                        'mawb=\'' + searchString.trim() + '\'' + '&' +
                        'filter=search' + '&' +
                        'shiptocountry=null'
                });
                window.location.reload();
            }


            else if (this.props.location.pathname === '/home/demo') {
                // this.props.openShipDetails(paramjobid, row);
                this.props.history.push({
                    pathname: 'zulily/shiplist',
                    search: 'startdt=null' + '&' +
                        'enddt=null' + '&' +
                        'datetype=null' + '&' +
                        'notdeparted=null' + '&' +
                        'portexit=null' + '&' +
                        'portentry=null' + '&' +
                        'finalcarrier=null' + '&' +
                        'mawb=\'' + searchString.trim() + '\'' + '&' +
                        'filter=search' + '&' +
                        'shiptocountry=null'
                });
            }
            else if (this.props.location.pathname === '/home/demo/shiplist/') {
                this.props.history.push({
                    // pathname: 'shipmentdetails/',
                    search: 'startdt=null' + '&' +
                        'enddt=null' + '&' +
                        'datetype=null' + '&' +
                        'notdeparted=null' + '&' +
                        'portexit=null' + '&' +
                        'portentry=null' + '&' +
                        'finalcarrier=null' + '&' +
                        'mawb=\'' + searchString.trim() + '\'' + '&' +
                        'filter=search' + '&' +
                        'shiptocountry=null'
                });
                window.location.reload();
            }

            //   else if(this.props.location.pathname === '/home/zulily/shipmentdetails/') {
            //     this.props.history.push({
            //       pathname: '../shiplist/',
            //       search: 'startdt=null' +  '&' +
            //       'enddt=null'  + '&' +
            //       'datetype=null'  + '&' +
            //       'notdeparted=null'  + '&' +
            //       'portexit=null'  + '&' +
            //       'portentry=null'  + '&' +
            //       'finalcarrier=null'   + '&' +
            //       'mawb=\'' + searchString.trim() +'\'' + '&' +
            //       'filter=null' 
            //       }); 


            //   }
            else {
                // this.props.openShipDetails(paramjobid, row);
                this.props.history.push({
                    pathname: 'shiplist',
                    search: 'startdt=null' + '&' +
                        'enddt=null' + '&' +
                        'datetype=null' + '&' +
                        'notdeparted=null' + '&' +
                        'portexit=null' + '&' +
                        'portentry=null' + '&' +
                        'finalcarrier=null' + '&' +
                        'mawb=\'' + searchString.trim() + '\'' + '&' +
                        'filter=search' + '&' +
                        'shiptocountry=null'
                });
            }




        }
    }


    render() {
        const {
            searchinput
        } = this.state;

        const shipToCountryArray = [
            { label: "US", value: "US" },
            { label: "CA", value: "CA" },
        ];

        // const selectPortEntry = JSON.parse(localStorage.getItem('PageConfig.ZUShipToAirport'));

        let datePickerRadio;
        datePickerRadio =
            <div className="sm-st clearfix"  >
                <div className="row">
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft: "auto", marginRight: "auto" }}>
                        <div onChange={this.setDatePickerRadio.bind(this)}>
                            <input type="radio" value="null" name="datepicker" defaultChecked /> All &nbsp;
                            <input type="radio" value="1" name="datepicker" /> Date Range &nbsp;
                        </div>
                    </div>
                </div>
            </div>

        // let datePickerStart;
        // datePickerStart=
        // <DateFilter
        //     // labelText = { 'Start:' }
        //     placeholderText = { 'Start Date' }
        //     selectedDate = { this.state.startDate }
        //     selectsStartOrEnd = { 'selectsStart' }
        //     startDate = { this.state.startDate }
        //     endDate = { this.state.endDate }
        //     onChange = { this.handleChangeStartDate }
        //     minDate = { calMinDateValue }
        //     disabled = { false }
        //     isMobile = { this.props.isMobile }
        //     isClearable = { false }
        // />

        // let datePickerEnd; 
        // datePickerEnd=
        // <DateFilter
        //     // labelText = { 'End:' }
        //     placeholderText = { 'End Date' }
        //     selectedDate = { this.state.endDate }
        //     selectsStartOrEnd = { 'selectsEnd' }
        //     startDate = { this.state.startDate }
        //     endDate = { this.state.endDate }
        //     onChange = { this.handleChangeEndDate }
        //     minDate = { calMinDateValue }
        //     disabled = { false }
        //     isMobile = { this.props.isMobile }
        //     isClearable = { false }
        // />


        // let datePickerDiv;
        // datePickerDiv=
        // <div className="sm-st clearfix" >
        //     <div className="row">  
        //     <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>  
        //         <header >
        //             {'Start Date'}
        //         </header>
        //             {datePickerStart}
        //     </div>
        //     <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>  
        //         <header >
        //             {'End Date'}
        //         </header>
        //             {datePickerEnd}
        //     </div>
        //     </div>
        //     <div onChange={this.setEventDate.bind(this)}>
        //                     <input type="radio" value="Manifest" name="eventdate" defaultChecked /> Manifest &nbsp;
        //                     <input type="radio" value="Departure" name="eventdate"/> Departure &nbsp;
        //                     <input type="radio" value="Arrival" name="eventdate" /> Arrival&nbsp;
        //     </div> 

        // </div>










        let datePickerDiv;
        datePickerDiv =

            <div>
                {(() => {
                    switch (this.state.calendarDisabled) {
                        case false: return (
                            <div className="sm-st clearfix" >

                                <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                                    {datePickerRadio}
                                </div>

                                <div className="row">
                                    <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>
                                        <header >
                                            {'Start Date'}
                                        </header>
                                        <DateFilter
                                            // labelText = { 'Start:' }
                                            placeholderText={'Start Date'}
                                            selectedDate={this.state.startDate}
                                            selectsStartOrEnd={'selectsStart'}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleChangeStartDate}
                                            minDate={calMinDateValue}
                                            disabled={this.state.calendarDisabled}
                                            isMobile={this.props.isMobile}
                                            isClearable={true}
                                        />
                                    </div>
                                    <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>
                                        <header >
                                            {'End Date'}
                                        </header>
                                        <DateFilter
                                            // labelText = { 'End:' }
                                            placeholderText={'End Date'}
                                            selectedDate={this.state.endDate}
                                            selectsStartOrEnd={'selectsEnd'}
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleChangeEndDate}
                                            minDate={calMinDateValue}
                                            disabled={this.state.calendarDisabled}
                                            isMobile={this.props.isMobile}
                                            isClearable={true}
                                        />

                                    </div>
                                </div>
                                <div onChange={this.setEventDate.bind(this)}>
                                    <input type="radio" value="Manifest" name="eventdate" defaultChecked /> Manifest &nbsp;
                                    <input type="radio" value="Departure" name="eventdate" /> Departure &nbsp;
                                    <input type="radio" value="Arrival" name="eventdate" /> Arrival &nbsp;
                                    <input type="radio" value="PickUp" name="eventdate" /> Pick-Up &nbsp;
                                </div>
                            </div>
                        );

                        case true: return (
                            <div className="sm-st clearfix" >
                                <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                                    {datePickerRadio}
                                </div>
                            </div>
                        );
                        default: return <div></div>;
                    }
                })()}
            </div>













        let radioNotDepartedDiv;
        radioNotDepartedDiv =
            <div className="sm-st clearfix" >
                <div className="row">
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft: "auto", marginRight: "auto" }}>
                        <div onChange={this.setNotDeparted.bind(this)}>
                            <input type="radio" value="null" name="notdeparted" defaultChecked /> All &nbsp;
                            <input type="radio" value="1" name="notdeparted" /> Not Departed &nbsp;
                        </div>
                    </div>
                </div>
            </div>


        let radioInTransitDiv;
        radioInTransitDiv =
            <div className="sm-st clearfix" >
                <div className="row">
                    <div style={{ backgroundColor: '#FFFFFF', marginLeft: "auto", marginRight: "auto" }}>
                        <div onChange={this.setInTransit.bind(this)}>
                            <input type="radio" value="null" name="intransit" /> All &nbsp;
                            <input type="radio" value="intransit" name="intransit" defaultChecked /> In Transit &nbsp;
                            <input type="radio" value="delivered" name="intransit" /> Delivered &nbsp;
                        </div>
                    </div>
                </div>
            </div>




        let dropDownPortExit;
        dropDownPortExit =
            <DropDownSelectFilter
                dropDownArray={this.state.selectPortEntry}
                dropDownOnChange={this.handleDropdownPortExit}
                dropDownPlaceholder={'Port of Exit'}
                isMobile={this.props.isMobile}
            />

        let dropDownPortEntry;
        dropDownPortEntry =
            <DropDownSelectFilter
                dropDownArray={this.state.selectPortEntry}
                dropDownOnChange={this.handleDropdownPortEntry}
                dropDownPlaceholder={'Port of Entry'}
                isMobile={this.props.isMobile}
            />

        let dropDownShipToCountry;
        dropDownShipToCountry =
            <DropDownSelectFilter
                dropDownArray={shipToCountryArray}
                dropDownOnChange={this.handleDropdownShipToCountry}
                dropDownPlaceholder={'Ship to Country'}
                isMobile={this.props.isMobile}
            />

        let dropDownFinalMile;
        dropDownFinalMile =
            <DropDownSelectFilter
                dropDownArray={this.state.selectPortEntry}
                dropDownOnChange={this.handleDropdownFinalCarrier}
                dropDownPlaceholder={'Final Mile Carrier'}
                isMobile={this.props.isMobile}
            />

        let buttonApplyFilter;
        buttonApplyFilter =
            //     <Button variant="secondary" size="md" block   onClick={() => this.onSetSidebarOpen(true)} >
            //     Click to Filter
            // </Button>
            <ButtonGeneral
                buttonVariant={"success"}
                buttonSize={"sm"}
                buttonOnClick={(e) => this.clickApplyFilter(e)}
                buttonText={'Apply'}
                isMobile={this.props.isMobile}
            />

        let searchInputDiv;
        searchInputDiv =
            <div className="sm-st clearfix" style={{ height: '100px' }}>

                <div style={{ backgroundColor: '#FFFFFF', marginLeft: "auto", marginRight: "auto" }}>
                    <SearchInput
                        searchInputValue={searchinput}
                        searchTitle={'Search MAWB, HAWB, MC, Tracking'}
                        searchOnChange={this.onSearchChange}
                        searchOnSubmit={this.onSearchSubmit}
                        searchOnKeyPress={this.onSearchKeyPress}
                        isMobile={this.props.isMobile}
                    />
                </div>

            </div>





        let currentFilter;
        if (this.props.isMobile) {
            currentFilter =
                <div className="row" style={{ marginBottom: '0px', fontSize: '0.4rem' }}>
                    <div className="col-md-12">
                        <section className="panel">
                            <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden' }}>
                                <div className="col-md-12">
                                    <td>Start Date: <strong>{(this.state.filterStartDT == null || this.state.filterStartDT === 'null') ? 'All' : moment(this.state.filterStartDT).format('MM-DD-YYYY')}</strong></td>
                                </div>
                                <div className="col-md-12">
                                    <td>End Date: <strong>{(this.state.filterEndDT == null || this.state.filterEndDT === 'null') ? 'All' : moment(this.state.filterEndDT).format('MM-DD-YYYY')}</strong></td>
                                </div>
                                <div className="col-md-12">
                                    <td>Date Range Type: <strong>{(this.state.filterRadioDateType == null || this.state.filterRadioDateType === 'null') ? 'All' : this.state.filterRadioDateType}</strong></td>
                                </div>
                                <div className="col-md-12">
                                    <td>Port Entry: <strong>{this.state.filterPortEntryState === 'null' ? 'All' : this.state.filterPortEntryState}</strong></td>
                                </div>
                                <div className="col-md-12">
                                    <td>Ship to Country: <strong>{this.state.filterShipToCountry === 'null' ? 'All' : this.state.filterShipToCountry}</strong></td>
                                </div>
                                <div className="col-md-12">
                                    <td>Departure Status: <strong>{(this.state.filterNotDeparted == null || this.state.filterNotDeparted === 'null') ? 'All' : 'Not Departed'}</strong></td>
                                </div>
                                <div className="col-md-12">
                                    <td>In Transit: <strong>{(this.state.filterInTransit == null || this.state.filterInTransit === 'null') ? 'All' : (this.state.filterInTransit === 'intransit') ? 'In Transit' : 'Delivered'}</strong></td>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
        }
        else {
            currentFilter =

                <div className="row" style={{ marginBottom: '0px', fontSize: '0.9rem' }}>
                    <div className="col-md-12">
                        <section className="panel">
                            <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden' }}>
                                <div className="row" >
                                    <div className="col-md-3">
                                        <td>Start Date: <strong>{(this.state.filterStartDT == null || this.state.filterStartDT === 'null') ? 'All' : moment(this.state.filterStartDT).format('MM-DD-YYYY')}</strong></td>
                                    </div>

                                    <div className="col-md-3">
                                        <td>Date Range Type: <strong>{(this.state.filterRadioDateType == null || this.state.filterRadioDateType === 'null') ? 'All' : this.state.filterRadioDateType}</strong></td>
                                    </div>

                                    <div className="col-md-3">
                                        <td>Port Entry: <strong>{this.state.filterPortEntryState === 'null' ? 'All' : this.state.filterPortEntryState}</strong></td>
                                    </div>

                                    <div className="col-md-3">
                                        <td>Departure Status: <strong>{(this.state.filterNotDeparted == null || this.state.filterNotDeparted === 'null') ? 'All' : 'Not Departed'}</strong></td>
                                    </div>

                                </div>
                                <div className="row" >
                                    <div className="col-md-6">
                                        <td>End Date: <strong>{(this.state.filterEndDT == null || this.state.filterEndDT === 'null') ? 'All' : moment(this.state.filterEndDT).format('MM-DD-YYYY')}</strong></td>
                                    </div>

                                    <div className="col-md-3">
                                        <td>Ship to Country: <strong>{this.state.filterShipToCountry === 'null' ? 'All' : this.state.filterShipToCountry}</strong></td>
                                    </div>
                                    <div className="col-md-3">
                                        <td>In Transit: <strong>{(this.state.filterInTransit == null || this.state.filterInTransit === 'null') ? 'All' : (this.state.filterInTransit === 'intransit') ? 'In Transit' : 'Delivered'}</strong></td>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
        }




        let filterDiv;
        if (this.props.isMobile) {
            filterDiv =
                <div className="mobile-text-size" style={{ padding: '0px', zIndex: '2000!important' }}>
                    <div className="col-md-12" style={{ padding: '1px' }}>
                        {datePickerDiv}
                    </div>
                    <div className="col-md-12" style={{ padding: '1px' }}>
                        {dropDownPortEntry}
                    </div>

                    <div className="col-md-12" style={{ padding: '1px' }}>
                        {dropDownShipToCountry}
                    </div>
                    <div className="col-md-12" style={{ padding: '1px' }}>
                        {radioNotDepartedDiv}
                    </div>
                    <div className="col-md-12" style={{ padding: '1px' }}>
                        {radioInTransitDiv}
                    </div>
                    <div className="col-md-12" style={{ padding: '1px' }}>
                        {buttonApplyFilter}
                    </div>

                    <div className="col-md-12" style={{ paddingTop: '5px' }}>
                        {searchInputDiv}
                    </div>

                </div>
        }
        else {
            filterDiv =
                <div style={{ padding: '15px', zIndex: '2000!important' }}>
                    <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                        {datePickerDiv}
                    </div>
                    <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                        {dropDownPortEntry}
                    </div>
                    <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                        {dropDownShipToCountry}
                    </div>


                    <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                        {radioNotDepartedDiv}
                    </div>
                    <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                        {radioInTransitDiv}
                    </div>
                    <div className="col-md-12" style={{ padding: '5px 15px 5px 15px' }}>
                        {buttonApplyFilter}
                    </div>

                    <div className="col-md-12" style={{ padding: '15px', paddingTop: '40px' }}>
                        {searchInputDiv}
                    </div>

                </div>

        }


        let filterSideDiv;
        if (this.state.sidebarOpen) {
            filterSideDiv = <Sidebar
                sidebar={filterDiv}
                open={this.state.sidebarOpen}
                onSetOpen={this.onSetSidebarOpen}
                styles={{ sidebar: { background: '#dcdcdc', padding: '45px' } }}
            />
        }
        else {
            filterSideDiv = <div></div>
        }

        return (

            <div style={{ paddingTop: "22px", paddingRight: "15px", paddingLeft: "15px" }}>
                <section className="panel">
                    <div //className="panel-body table-responsive"
                        style={{ backgroundColor: '#dcdcdc' }}
                    >

                        {filterSideDiv}



                        <section className="panel" style={{ paddingRight: "17px", paddingLeft: "13px" }}>
                            <div style={{ marginLeft: "auto", marginRight: "auto", zIndex: "1", position: "relative" }}>
                                <Button variant="secondary" size="md" block onClick={() => this.onSetSidebarOpen(true)} >
                                    Click to Filter/Search
                                </Button>
                            </div>
                        </section>



                    </div>
                </section>
                {currentFilter}
            </div>


        );
    }
}

export default withRouter(FilterMainZU);

