export const languageDropDownMenu = [
    {"label":"English","value":"English","id":1},
    {"label":"Mandarin","value":"Mandarin","id":2}
]






export function languageTranslation( phrase, language ){
    var convertedPhrase;
    try{
        convertedPhrase = phrases[phrase][language]
    }
    catch(e){
        convertedPhrase = phrase
    }
    return convertedPhrase
}



const phrases = {

    D2CDashboard: { English: 'D2C Dashboard', Mandarin: '查询概览' },
    ShipmentList: { English: 'Shipment List', Mandarin: '货物清单' },
    Reporting: { English: 'Reporting', Mandarin: '生成报告' },
    Metrics: { English: 'Metrics', Mandarin: '衡量指标' },
    DataExport: { English: 'Data Export', Mandarin: '数据输出' },
    User: { English: 'User', Mandarin: '使用者' },
    UserManagement: { English: 'User Management', Mandarin: '使用者管理' },
    MyProfile: { English: 'My Profile', Mandarin: '我的资料' },
    UPSLabelCreation: { English: 'UPS Label Creation', Mandarin: 'UPS标签生成' },
    ClicktoFilterSearch: { English: 'Click to Filter/Search', Mandarin: '点击以过滤/搜索' },
    StartDate: { English: 'Start Date', Mandarin: '开始日期' },
    EndDate: { English: 'End Date', Mandarin: '结束日期' },
    Status: { English: 'Status', Mandarin: '状态' },
    PortofEntry: { English: 'Port of Entry', Mandarin: '落地港口' },
    DateRangeType: { English: 'Date Range Type', Mandarin: '筛选条件' },
    Customer: { English: 'Customer', Mandarin: '客户' },
    AverageFirstScantoDeliveryDays: { English: 'Avg First Scan to Delivery (Days)', Mandarin: '第一扫描到派送(平均天数)' },
    NoPackagesinTransit: { English: '# Packages in Transit', Mandarin: '转运中包裹数量' },
    AverageInWarehousetoFlightDepart: { English: 'Avg In Warehouse to Flight Depart', Mandarin: '入库到起飞(平均天数)' },
    AverageInWarehousetoFreightRecovery: { English: 'Avg In Warehouse to Freight Recovery', Mandarin: '入库到提取(平均天数)' },
    AverageInWarehousetoFirstScanDays: { English: 'Avg In Warehouse to First Scan (Days)', Mandarin: '入库到首次扫描(平均天数)' },
    AverageInWarehousetoDelivery: { English: 'Avg In Warehouse to Delivery', Mandarin: '入库到派送(平均天数)' },
    AverageFlightDeparttoFirstScan: { English: 'Avg Flight Depart to First Scan', Mandarin: '起飞到首次扫描(平均天数)' },
    InWarehousetoFirstScan: { English: 'In Warehouse to First Scan', Mandarin: '入库到第一扫描' },
    InWarehousetoDelivery: { English: 'In Warehouse to Delivery', Mandarin: '入库到派送' },
    InWarehousetoFlightDeparture: { English: 'In Warehouse to Flight Departure', Mandarin: '入库到起飞' },
    InWarehousetoFreightRecovery: { English: 'In Warehouse to Freight Recovery', Mandarin: '入库到提取' },
    FlightDeparturetoFirstScan: { English: 'Flight Departure to First Scan', Mandarin: '起飞到首次扫描' },
    FirstScantoDelivery: { English: 'First Scan to Delivery', Mandarin: '第一扫描到派送' },
    TotalPackages: { English: 'Total Packages', Mandarin: '包裹总数量' },
    Days: { English: 'Days', Mandarin: '天数' },
    DailyScanCount: { English: 'Daily Scan Count', Mandarin: '单日扫描件数' },
    ScanCount: { English: 'Scan Count', Mandarin: '扫描件数' },
    All: { English: 'All', Mandarin: '所有' },
    DateRange: { English: 'Date Range', Mandarin: '日期范围' },
    CustomerCode: { English: 'Customer Code', Mandarin: '客户代码' },
    NotDeparted: { English: 'Not Departed', Mandarin: '航班未起飞' },
    InTransit: { English: 'In Transit', Mandarin: '转运中' },
    Handedoff: { English: 'Handed off', Mandarin: '已交货' },
    Apply: { English: 'Apply', Mandarin: '开始' },
    SearchMAWBHAWBMCTrackingNumber: { English: 'Search MAWB, HAWB, MC, Tracking Number', Mandarin: '搜索主单/分单/纸箱号/追踪号' }
}






