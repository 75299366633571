import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import * as moment from 'moment'
import ReChartLine from '../../ComponentsGeneral/ReCharts/ReChartLine'
// import MMDDYYFormatter from '../../../resources/functions/DateTimeFunction'
import {languageTranslation} from '../../../app/languageSettings'


  const lineKeyArray = [   {'lineKey': 'Average', 'stroke': '#413ea0'}
                        // {'lineKey': 'Avg Manifest to Actual Flight Arrival', 'stroke': '#ff9900'}


                        // {'lineKey': 'AvgOTDtoMC', 'stroke': '#82ca8d'}
                    ]




  class GBChartOriginFinalMileToDelivery extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    onClick = (line, lineKey) => {

      // console.log('formatter', MMDDYYFormatter(line.payload.Date))

      // console.log('line', line)


      var dataKey = "'" + line.dataKey + "'"

      var dateValue = moment(line.payload.Date).format('YYYY-MM-DD')

      // var dateValue = MMDDYYFormatter(line.payload.Date)


      var new_date = moment(dateValue, "YYYY-MM-DD").add(1, 'days');

      var endDateValue = "'" + moment(new_date).format('YYYY-MM-DD') + "'"
 
      this.props.history.push({
        pathname: this.props.routeCustomer + '/shiplist',
        search: 'startdt=' + "'" + dateValue + "'" + '&' +
        'enddt=' + endDateValue + '&' +
        'datetype=' + 'Delivery' + '&' +
        'notdeparted=' + null + '&' +
        'portexit=' + null + '&' +
        'portentry=' + null + '&' +
        'customercode=' + null + '&' +
        'shipmenttype=' + null + '&' +
        'finalcarrier=' + null  + '&' +
        'mawb=' + null  + '&' +
        'mc=' + null  + '&' +
        'hawb=' + null  + '&' +
        'filter=' + 'origintodelivery'

        }); 
  
    }

    render() {

      const languageSelection = this.props.languageSelection
      var FirstScantoDeliveryLanguage =  languageTranslation( 'FirstScantoDelivery', languageSelection )


      let otdToMC;
      if (this.props.isChartOriginFinalMileToDeliveryLoading) {
        otdToMC=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {FirstScantoDeliveryLanguage}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      }  
      else if (typeof(this.props.ChartOriginFinalMileToDeliveryData) !== 'undefined' && this.props.ChartOriginFinalMileToDeliveryData != null) {

          otdToMC =

              <section className="panel">
              <header className="panel-heading">
              {FirstScantoDeliveryLanguage}
              </header>

                  <ReChartLine 
                      data={this.props.ChartOriginFinalMileToDeliveryData} 
                      dataKey='Date'
                      lineKeys= { lineKeyArray }
                      onClick={{ onClick: (line) => this.onClick(line) }}
                      xLegend='Delivery Date'
                      yLegend='Days'
                  />

              </section>
        
      }

      return(
        <div>
            {otdToMC}
        </div>
      );

    }
  }
  
  export default withRouter(GBChartOriginFinalMileToDelivery);
  
