import React from 'react';
// import PivotTableUI from 'react-pivottable/PivotTableUI';
// import 'react-pivottable/pivottable.css';
// import TableRenderers from 'react-pivottable/TableRenderers';
// import Plot from 'react-plotly.js';
// import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
// import Loader from 'react-loader-spinner'
import ModalFlagCard from '../Modals/ModalFlagCards';
import {appConfig} from '../../../app/appConfig'
import {
    Table,
    // TableBody
  }                         from '../../ComponentsGeneral/table'
import BootstrapTable from 'react-bootstrap-table-next';
// create Plotly renderers via dependency injection
// const PlotlyRenderers = createPlotlyRenderers(Plot);

// see documentation for supported input formats
// const data = [['attribute', 'attribute2'], ['value1', 'value2']];

const shipCommentsColumns = [{
    dataField: 'CommentDate',
    text: 'Date',
    headerStyle: (colum, colIndex) => {
      return { textAlign: 'center' };
    },
    sort: true
  }, {
    dataField: 'User',
    text: 'User',
    headerStyle: (colum, colIndex) => {
      return { textAlign: 'center' };
    },
    sort: true
  }, {
    dataField: 'Comment',
    text: 'Comment',
    headerStyle: (colum, colIndex) => {
      return {  textAlign: 'center' };
    }
  }
  ]


class AcknowledgeAction extends React.Component {
    constructor(props) {
        super(props);
        this.state = props;
    }

    onClickMilestoneComment = (e) => {
        e.preventDefault();
        window.alert("Your comment has been added.")
        // get our form data out of state
        // const { comment } = this.state;
  
        // if(comment.trim() === "") {
        //   window.alert("Comment is empty.");
        //   return
        // }
        // else {
        //   this.props.postShipModalComment( this.props.currentUserEmail, this.props.shipTrackModalRow.JobID,  comment.trim());
  
        //   //this is local state for comment
        //   const newState = Object.assign({}, this.state, {
        //     comment: ""
        //   });
        //   // store the new state object in the component's state
        //   this.setState(newState);
        // }
      }


    render() {




      let acknowledgeForm;
      //render modal only if show is true, otherwise do not render

        acknowledgeForm = 
        <div>
                      

            <span>
            <div
            className="row"
            style={{marginBottom: '5px'}}>

                    <div className="col-md-4" > 
                    <section className="panel">
                    <header className="panel-heading" style={{textAlign: 'center'}}>
                      Reject Issue
                    </header>
                    <div className="panel-body table-responsive" title="Click to reject this issue"
                    style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                      <div style={{align:'center'}} onClick={(e) => { 
                        
                                       (window.confirm('Are you sure you want to reject this issue?')) 
                                      
                                    } }
                                    > 
                      <ModalFlagCard 
                      color={appConfig.colors.red} 
                      icon={'fa fa-exclamation-triangle'}/>                                    
                        </div>
                    </div>
                    </section>
                    </div>


                    <div className="col-md-4" > 
                <section className="panel">
                <header className="panel-heading" style={{textAlign: 'center'}}>
                 Acknowledge
                </header>
                <div className="panel-body table-responsive" title={`Click to put issue into work` }
                style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                  <div style={{align:'center'}} onClick={(e) => { 
                                       (window.confirm(`Are you sure you want to put this issue in work?`)) 
                                      } }
                                    > 
                  <ModalFlagCard 
                  color={appConfig.colors.blue} 
                  icon={'fa fa-wrench'}/>                                    
                    </div>
                </div>
                </section>
                </div>

                  <div className="col-md-4" > 
                <section className="panel">
                <header className="panel-heading" style={{textAlign: 'center'}}>
                  Resolve Issue
                </header>
                <div className="panel-body table-responsive" title={`Click to resolve` }
                style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                  <div style={{align:'center'}} onClick={(e) => { 
                                      (window.confirm(`Are you sure you want to resolve this issue?`)) 
                                      
                                    } }
                                    > 
                  <ModalFlagCard 
                  color={appConfig.colors.green} 
                  icon={'fa fa-check-square-o'}/>   
                  </div>
                </div>
                </section>
                </div>

              

              </div>
            </span>

            <form
              className="sidebar-form"
              onKeyPress={this.onKeyPress}
              >
              <div className="input-group">
                <input
                  type="text"
                  name="comment"
                  className="form-control"
                  placeholder="Add new comment..."
                //   value={comment}
                  onChange={this.onChange}
                />
                <span className="input-group-btn">
                  <button
                  
                  onClick={this.onClickMilestoneComment}
                  name="comment"
                  id="comment-btn"
                  title="Click to submit comment"
                  className="btn btn-flat">
                    <i className="fa fa-plus-circle" style={{   
                          fontSize: '20px',
                          cursor: "pointer"
                          }}></i>
                  </button>
                </span>
              </div>             
            </form>

            <section className="panel">
            <header className="panel-heading">
              {'Action History'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
            <Table>
              <BootstrapTable 
              keyField='CommentDate' 
              data={  
                [{"JobID":64,"CommentDate":"2019-08-05 13:22:39","User":"vizdashdemo@gmail.com","Comment":"Flagged as Omni Action"}]
               } 
              columns={ shipCommentsColumns } 
              // expandRow={ expandRow }
              // rowEvents={ rowEvents }
              />
            </Table>
            </div>
            </section>

            </div>

      


        return (

          <section className="panel">
          <header className="panel-heading">
            {'Acknowledge Action: 3821060'}
          </header>
          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>

            { acknowledgeForm }

          </div>
          </section>




        );
    }
}

export default AcknowledgeAction;
