
import React, {
    PureComponent
}                         from 'react';


import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';

import AcknowledgeActionContainer from '../../../components/ComponentsGoogle/AcknowledgeAction/AcknowledgeActionContainer'; 
// import CustomTable from '../../components/CustomTable/CustomTable';


class serviceIssuesView extends PureComponent {



    render() {

        return(
            <AnimatedView>

                <AcknowledgeActionContainer />

            </AnimatedView>



        );
    }
}

export default serviceIssuesView;

