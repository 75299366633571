import * as types from '../constants/upsActionTypes';
import {appSettings} from '../../app/appSettings'

const initialState = {
        isLabelCreationLoading: false,
        LabelCreationErrorCode: false,
        LabelCreationText: ''

};

function checkStatusCode( res )
{
        if(res === "200"){ return true }
        else { return false }
}

function actionOnError( proc )
{
        console.log('DB connection error')
}



export const UpsDataReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state;


        case types.UPS_LABEL_CREATION_GET:   
        return Object.assign({}, state, {
            isLabelCreationLoading: true,
            LabelCreationErrorCode: false,
            LabelCreationText: ''
        });       
        case types.UPS_LABEL_CREATION_COMPLETE:  
        if(!checkStatusCode(action.payload.statusCode)) {
            if (typeof(action.payload.errorMessage) !== 'undefined' ) {

                var newLabelCreationError = action.payload.errorMessage;
                // actionOnError()
                return Object.assign({}, state, {
                    isLabelCreationLoading: false,
                    LabelCreationErrorCode: true,
                    LabelCreationText: newLabelCreationError
                });   
            }
            else{
            var newLabelCreationError = action.payload.body;
            // actionOnError()
            return Object.assign({}, state, {
                isLabelCreationLoading: false,
                LabelCreationErrorCode: true,
                LabelCreationText: newLabelCreationError
            });   
        }
        }
        else{

                var newLabelCreationData = action.payload.body;
                return Object.assign({}, state, {
                    isLabelCreationLoading: false,
                    LabelCreationErrorCode: false,
                    LabelCreationText: newLabelCreationData
                });  
        }




        default:
                return state;
	}
};

export default UpsDataReducer;
