import evolveLogoIMG        from '../resources/img/evolveLogoPngWords.png' //evolve
import smallEvolveLogoIMG   from '../resources/img/evolveLogoPng.png' //evolve

import omniLogoIMG        from '../resources/img/omni_logo_horizontal.png';  //omni
import omniSmallLogoIMG        from '../resources/img/omni_mark.png';  //omni

//This is list of dashboard products
// var Test = 'Test'
// var Google = 'Google'
// var Zulily = 'Zulily'
var D2C = 'D2C'     //this is the D2C dashboard (https://evolvevisibility.com/)
var Omni = 'Omni'   //this dashboard will show Google and Zulily customers (https://clearview.omnilogistics.com/)
var Demo = 'Demo'   //this uses the Evolve_OMNI Database for demo data and shows Zu customer (https://dca28j31h3nbv.cloudfront.net/)

//If new customer or product added, add here and to Main routes
//use this to determine which customers can be seen in each dashboard product
var _customerProductMatrix = [

    {"customer":["Evolve"],     "product":[                         ]},
    {"customer":["Gao Bo"],     "product":[         "D2C"           ]},
    {"customer":["Google"],     "product":["Demo",  "D2C", "Omni"   ]},
    {"customer":["Omni"],       "product":["Demo"                   ]},
    {"customer":["Zulily"],     "product":["Demo",  "D2C", "Omni"   ]},
    {"customer":["Demo"],       "product":["Demo",  "D2C", "Omni"   ]},
    
    ]


//Check demo site database

/////////////////Modify This Section for each deploy/////////////////////////////
/////////////////////////////////////////////////////////////////////////////////

//1 //Set product this will deploy to:
var _product = D2C

//2 //////****** CHANGE INDEX.HTML PUBLIC ICON  */

//3 //Set environment prod or dev:
// var _environment = 'dev'
var _environment = 'prod'


/////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////





//initiate local variables
var _userDB = 'Evolve_DashBoard_Users'
var _zuDB = 'Evolve_ZU' 
var _gbDB = 'Evolve_GBLE'
var _googleDB = 'Evolve_Dashboard_Google'

var _devEnvironment
var _loggerDisabled
var _autoReloadIdleTime
var _warningTimeLogout
var _signoutTimeLogout

var _fullSizeLogo
var _smallLogo
// var _customer

var _customer = {
    Footer_CopyWrite: '2023 - All Rights Reserved - Evolve Supply Chain Solutions, LLC',
    Carrier_Name: 'Carrier',
    Stat_Card_Internal: 'Carrier Action',
    Stat_Card_External: 'Customer Action',
    Milestone_Yellow: 'Carrier Yellow',
    Milestone_Red:'Carrier Red',
    Customer1: 'Inbound',
    Customer2: 'D2C'
}


//These will export settings based on environment

if(_environment === 'dev'){
    _loggerDisabled = false
    _devEnvironment = true
    _autoReloadIdleTime = 1000 * 60 * 30 //30 minutes 
    _warningTimeLogout =  1000 * 60 * 20 //20 minutes 
    _signoutTimeLogout =  1000 * 60 * 21 //20 minutes 
}

else if(_environment === 'prod'){
    _loggerDisabled = true
    _devEnvironment = false
    _autoReloadIdleTime = 1000 * 60 * 10 //5 minutes
    _warningTimeLogout =  1000 * 60 * 20
    _signoutTimeLogout =  1000 * 60 * 21  //logout after 20 minutes
}



//These will export settings based on product
if(_product === Demo){
    _fullSizeLogo = evolveLogoIMG
    _smallLogo = smallEvolveLogoIMG

    _googleDB = 'Evolve_Dashboard_OMNI'

    _customer = {
        Footer_CopyWrite: '2023 - All Rights Reserved - Evolve Supply Chain Solutions, LLC',
        Carrier_Name: 'Carrier',
        Stat_Card_Internal: 'Carrier Action',
        Stat_Card_External: 'Customer Action',
        Milestone_Yellow: 'Carrier Yellow',
        Milestone_Red:'Carrier Red',
        Customer1: 'Inbound',
        Customer2: 'D2C'
    }
}

else if(_product === D2C){
    _fullSizeLogo = evolveLogoIMG
    _smallLogo = smallEvolveLogoIMG

    _customer = {
        Footer_CopyWrite: '2023 - All Rights Reserved - Evolve Supply Chain Solutions, LLC',
        Carrier_Name: 'Carrier',
        Stat_Card_Internal: 'Carrier Action',
        Stat_Card_External: 'Customer Action',
        Milestone_Yellow: 'Carrier Yellow',
        Milestone_Red:'Carrier Red',
        Customer1: 'Inbound',
        Customer2: 'D2C'
    }
}

else if(_product === Omni){
    _fullSizeLogo = omniLogoIMG
    _smallLogo = omniSmallLogoIMG
    
    _customer = {
        Footer_CopyWrite: '2023 - All Rights Reserved - Omni Logistics, LLC',
        Carrier_Name: 'Omni',
        Stat_Card_Internal: 'Omni Action',
        Stat_Card_External: 'Customer Action',
        Milestone_Yellow: 'Omni Yellow',
        Milestone_Red:'Omni Red',
        Customer1: 'Google',
        Customer2: 'Zulily'
    }
}





//Export settings here
export const appSettings = {
    environment: _environment,
    product: _product,
    mobileWidth: 850,

    userDB: _userDB,
    zuDB: _zuDB,
    gbDB: _gbDB,
    googleDB: _googleDB,


    devEnvironment: _devEnvironment,
    loggerDisabled: _loggerDisabled,
    autoReloadIdleTime: _autoReloadIdleTime,
    warningTimeLogout:  _warningTimeLogout,
    signoutTimeLogout:  _signoutTimeLogout,

    logo: _fullSizeLogo,
    smallLogo: _smallLogo,
    customer : _customer,

    colorPalatte: {

        darkest: '#262626',
        secondaryDark: '#3f3f3f',
        lighter: '#dcdcdc',
        whitish: '#f5f5f5',
        white: '#FFF'

    },

    customerProductMatrix: _customerProductMatrix

}


