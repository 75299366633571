import React, {
    PureComponent
    }                                       from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow, Polyline } from 'google-maps-react';

import {appConfig}                      from '../../../app/appConfig'
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import Button from 'react-bootstrap/Button' 
import ReactDOM from "react-dom";
import GoogleMapDetailTable from './GoogleMapDetailTable/GoogleMapDetailTable'

      class GoogleMapTracker extends PureComponent {
        constructor( props )
        {
          super( props );

        }
        state = {
          showingInfoWindow: false,
          activeMarker: {},
          selectedHouseBill: {},
          selectedCurrentShipStatus: {},
          selectedMode: {},
          selectedShipment: {}
        };

        onMarkerClick = (props, marker, e) =>
        this.setState({
          selectedShipment: props,
          activeMarker: marker,
          showingInfoWindow: true
        });

        onClose = props => {
          if (this.state.showingInfoWindow) {
            this.setState({
              showingInfoWindow: false,
              activeMarker: null,
              selectedShipment: {}
            });
          }
        };


        
        displayMarkers = () => {
          return this.props.shipmentGPSData.map((shipmentGPSData, index) => {
            return <Marker 
 
            key={index} id={index} position={{
             lat: shipmentGPSData.Latitude ,
             lng: shipmentGPSData.Longitude + index * 0.00005  //temp fix for overlapping markers
           }}


           onClick={this.onMarkerClick} 
           houseBill={shipmentGPSData.HouseBill} 
          //  imei={shipmentGPSData.} 
           currentStatus={shipmentGPSData.CurrentShipmentStatus} 
           mode={shipmentGPSData.Mode} 
           perc={shipmentGPSData.PercentComplete} 
           reportTime={shipmentGPSData.TimeOfReport}

           BatteryPercent={shipmentGPSData.BatteryPercent}
           IsShockExceeded={shipmentGPSData.IsShockExceeded}
           TemperatureF={shipmentGPSData.TemperatureF}
           />
          })
        }

        displayMarkersDetailView = () => {
          return this.props.shipmentGPSData.map((shipmentGPSData, index) => {
            return <Marker 
 
            key={index} id={index} position={{
             lat: shipmentGPSData.Latitude ,
             lng: shipmentGPSData.Longitude
           }}

            icon={(() => {
              switch (shipmentGPSData.IsShockExceeded) {
                case 'True':    return     (
                  {  url: appConfig.svgExclamation, // url
                    scaledSize: new this.props.google.maps.Size(35, 35), // scaled size
                  }
                  );
                case 'False': 
                  switch (shipmentGPSData.latest) {
                    case 'Y':                  
                        switch (shipmentGPSData.Mode) {
                          case 'AIR':   return     (
                            {  url: appConfig.svgAirplaneGreen, // url
                              scaledSize: new this.props.google.maps.Size(38, 38), // scaled size
                            }
                            );
                          case 'OCEAN': return (
                            {  url: appConfig.svgShip, // url
                              scaledSize: new this.props.google.maps.Size(38, 38), // scaled size
                            }
                          );
                          default: return {};
                        }
                    case 'N':                   
                        switch (shipmentGPSData.Mode) {
                          case 'AIR':   return     (      
                            {  url: appConfig.svgAirplaneGrey, // url
                              scaledSize: new this.props.google.maps.Size(20, 20), // scaled size
                            }
                            );          
                          case 'OCEAN': return (
                            {  url: appConfig.svgShip, // url
                              scaledSize: new this.props.google.maps.Size(20, 20), // scaled size
                            }
                          );
                          default: return {};
                        }
                    default: return {};

                  }
                default: return {};
              }


            })()}
             


            

            // icon={(() => {
            //   switch (shipmentGPSData.Mode) {
            //     case 'AIR':   return     (

            //     {  url: appConfig.svgAirplane, // url
            //       scaledSize: new this.props.google.maps.Size(30, 30), // scaled size
            //     }
            //     );
    
            //     case 'OCEAN': return (
            //       {  url: appConfig.svgShip, // url
            //         scaledSize: new this.props.google.maps.Size(30, 30), // scaled size
            //       }
            //     );
            //     default: return "";
            //   }
            // })()}
            


          //  icon={{
          //   url: appConfig.smallFaTruck, //"/path/to/custom_icon.png",
          //   anchor: new this.props.google.maps.Point(shipmentGPSData.Latitude,shipmentGPSData.Longitude),
          //   scaledSize: new this.props.google.maps.Size(30,30)
          // }}
           
           onClick={this.onMarkerClick} 
           houseBill={shipmentGPSData.HouseBill} 
          //  imei={shipmentGPSData.} 
           currentStatus={shipmentGPSData.CurrentShipmentStatus} 
           mode={shipmentGPSData.Mode} 
           perc={shipmentGPSData.PercentComplete} 
           reportTime={shipmentGPSData.TimeOfReport}

           BatteryPercent={shipmentGPSData.BatteryPercent}
           IsShockExceeded={shipmentGPSData.IsShockExceeded}
           TemperatureF={shipmentGPSData.TemperatureF}

            latitude={shipmentGPSData.Latitude}
            longitude={shipmentGPSData.Longitude}
            IsMotionDetected={shipmentGPSData.IsMotionDetected} 


           />
          })
        }



        // {(() => {
        //   switch (this.props.shipTrackModalRow.HotShipment) {
        //     case 1:   return     (
        //       <div className="col-md-4" > 
        //       <section className="panel">
        //       <header className="panel-heading" style={{textAlign: 'center'}}>
        //         Hot Shipment
        //       </header>
        //       <div className="panel-body table-responsive" title="Click to resolve Hot Shipment"
        //       style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
        //         <div style={{align:'center'}} onClick={(e) => { 
        //                         if (window.confirm('Are you sure you want to resolve this Hot Shipment?')) 
        //                         this.props.modalToggleHotShip( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID, 0 ) } }
        //                       > 
        //         <ModalFlagCard 
        //         color={appConfig.colors.red} 
        //         icon={'fa fa-exclamation-triangle'}/>                                    
        //           </div>
        //       </div>
        //       </section>
        //       </div>
        //     );

        //     case 0: return (
        //       <div className="col-md-4" > 
        //       <section className="panel">
        //       <header className="panel-heading" style={{textAlign: 'center', color: '#dcdcdc'}}>
        //         Hot Shipment
        //       </header>
        //       <div className="panel-body table-responsive" title="Click to flag as Hot Shipment" 
        //       style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
        //         <div style={{align:'center'}} onClick={(e) => { 
        //                         if (window.confirm('Are you sure you want to make this a Hot Shipment?')) 
        //                       this.props.modalToggleHotShip( this.props.userSignInData.DBName, this.props.currentUserEmail,this.props.shipTrackModalRow.JobID, 1 ) } }
        //                       > 
        //         <ModalFlagCard 
        //         color={'#eee'} 
        //         icon={'fa fa-exclamation-triangle'}/>                                    
        //           </div>
        //       </div>
        //       </section>
        //       </div>
        //     );
        //     default: return "";
        //   }
        // })()}


        // data={ this.props.milestoneAlertData.filter(x => x.JobID === row.JobID) }


        returnPoints = () => {
          return this.props.shipmentGPSData.map((shipmentGPSData, index) => {
            return{
              lat: shipmentGPSData.Latitude,
              lng: shipmentGPSData.Longitude
            }
           
          
          })
        }

        returnLatestPoint = () => {
          return this.props.shipmentGPSData.filter(x => x.latest === 'Y').map((shipmentGPSData, index) => {
            return{
              lat: shipmentGPSData.Latitude,
              lng: shipmentGPSData.Longitude
            }
          })
        }
        

        onClickMarkerNavigate(e, housebill) {
          this.props.history.push({
            pathname: '/home/google/shipmentdetails/',
            search: 'housebill=' + housebill
            })
        }


        adjustMap(mapProps, map, points) {
          const {google, markers} = mapProps;

          // const pointsLength = points.length


          const bounds = new google.maps.LatLngBounds();
          points.forEach(marker => {
            const {lat, lng} = marker;
            
            bounds.extend(new google.maps.LatLng(lat, lng));
          
            // if(pointsLength === 1) {
              bounds.extend(new google.maps.LatLng(lat + 0.08 , lng + 0.08));
              bounds.extend(new google.maps.LatLng(lat - 0.08 , lng - 0.08));
            // }

          });

          map.fitBounds(bounds);

        }


        //this takes latest point to reframe detail view map
        adjustDetailMap(mapProps, map, points) {
          const {google, markers} = mapProps;

          // const pointsLength = points.length


          const bounds = new google.maps.LatLngBounds();
          points.forEach(marker => {
            const {lat, lng} = marker;
            
            bounds.extend(new google.maps.LatLng(lat, lng));
          
            // if(pointsLength === 1) {
              bounds.extend(new google.maps.LatLng(lat + 0.02 , lng + 0.02));
              bounds.extend(new google.maps.LatLng(lat - 0.02 , lng - 0.02));
            // }

          });

          map.fitBounds(bounds);

        }

        onInfoWindowOpen(props, e) {
          let detailButton;
          if(this.props.location.pathname !== "/home/google/shipmentdetails/") {
            detailButton=
            <Button variant="success" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.onClickMarkerNavigate(e, this.state.selectedShipment.houseBill)}>
              See Details 
            </Button> 
          }
          else{
            detailButton=
              <div></div>
          }
          ReactDOM.render(React.Children.only(detailButton), document.getElementById("iwc"));

        }

        // _insertButton = (parentThis, map) => {
        //   let controlDiv = document.createElement('div');
        //   map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
        // }

        // drawShipPath = (points) => {
        //   return this.props.shipmentGPSData.map((shipmentGPSData, index) => {

        //     if(this.props.location.pathname !== "/home/shipmentdetails/") {

        //     return <Polyline
        //             path={points}
        //             geodesic={true}
        //             options={{
        //                 strokeColor: "#ff2527",
        //                 strokeOpacity: 0.75,
        //                 strokeWeight: 2,
        //                 // icons: [
        //                 //     {
        //                 //         icon: lineSymbol,
        //                 //         offset: "0",
        //                 //         repeat: "20px"
        //                 //     }
        //                 // ]
        //             }}
        //         />

        //   }
        //   else{
        //     return 
        //   }


        //   })
        // }


        // drawShipPath(points) {
        //   if(this.props.location.pathname !== "/home/shipmentdetails/") {
        //     return <Polyline
        //         path={points}
        //         geodesic={true}
        //         options={{
        //             strokeColor: "#ff2527",
        //             strokeOpacity: 0.75,
        //             strokeWeight: 2,
        //             // icons: [
        //             //     {
        //             //         icon: lineSymbol,
        //             //         offset: "0",
        //             //         repeat: "20px"
        //             //     }
        //             // ]
        //         }}
        //     />
        //     }
        //   else{
        //     return <div></div>
        //   }
        // }


        render() {
        const mapStyles = {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            };


        let mapComponent;
        if (this.props.isShipmentGPSLoading) {
          mapComponent=
            <section className="panel">
            <header className="panel-heading">
              {'Shipment Map'}
            </header>
            <div align="center">
            <section className="panel">
              </section>
              <Loader 
                type="Plane"
                color="#00BFFF"
                height="50"	
                width="50"
              /> 
            </div>
            </section>

        }
        else if (typeof(this.props.shipmentGPSData) !== 'undefined' && this.props.shipmentGPSData != null) {
          if(this.props.shipmentGPSData.length === 0) {
            mapComponent =
            <section className="panel"  >
            <header className="panel-heading">
              {'Shipment Map'}
            </header>
            <div className="panel-body table-responsive"
                style={{backgroundColor: '#FFF', height: '400px'}}>

                  <i className="fa fa-globe" style={{color:`${appConfig.colors.red}`}} title="No Location Data For This Shipment"> &nbsp;</i>
                  No Location Data For Shipment
                  
              <Map
                google={this.props.google}
                zoom={5}
                style={mapStyles}
                initialCenter={{ lat: 36.128, lng: -94.633}}
              >
              </Map>

              </div>
              </section>

          }

          else {
          var points = this.returnPoints()

          var latestPoint = this.returnLatestPoint()


          let infoViewDetailsPage;
          if(this.props.userSignInData.GroupName ==='OMNI' || this.props.userSignInData.GroupName ==='Demo') {
            infoViewDetailsPage = 
              <div >
                <div>
                <h5>House Bill: {this.state.selectedShipment.houseBill}</h5>
                </div>
                <div>
                <h6>Date Time: {this.state.selectedShipment.reportTime}</h6>
                </div>
                <div>
                <h6>Battery Percent: {this.state.selectedShipment.BatteryPercent}</h6>
                </div>
                <div>
                <h6>Is Shock Exceeded: {this.state.selectedShipment.IsShockExceeded}</h6>
                </div>
                <div>
                <h6>Temperature (F): {this.state.selectedShipment.TemperatureF}</h6>
                </div>
              </div>
          }

          else if(this.props.userSignInData.GroupName ==='Google') {
            infoViewDetailsPage = 
              <div >
                <div>
                <h5>House Bill: {this.state.selectedShipment.houseBill}</h5>
                </div>
                <div>
                <h6>Date Time: {this.state.selectedShipment.reportTime}</h6>
                </div>
              </div>
          }


          // let detailViewTable;
          // if(typeof(this.state.selectedShipment.houseBill) !== 'undefined' || this.state.selectedShipment.houseBill != null){
          //   detailViewTable = 
          //   <GoogleMapDetailTable             
          //     HouseBill={this.state.selectedShipment.houseBill}               
          //     TimeOfReport={this.state.selectedShipment.reportTime}            
          //     Latitude={this.state.selectedShipment.latitude}                           
          //     Longitude={this.state.selectedShipment.longitude}                     
          //     BatteryPercent={this.state.selectedShipment.BatteryPercent}                 
          //     IsMotionDetected={this.state.selectedShipment.IsMotionDetected}                
          //     IsShockExceeded={this.state.selectedShipment.IsShockExceeded}            
          //     TemperatureF={this.state.selectedShipment.TemperatureF}        
          //   />
          // }
          // else{
          //   detailViewTable = 
          //   <div></div>
          // }


          let shipPathPoints;
          if(this.props.location.pathname !== "/home/google/shipmentdetails/") {
            shipPathPoints = []
            // if multiple shipments
            mapComponent =
            <section className="panel"  >
            <header className="panel-heading">
              {'Shipment Map'}
            </header>
              <Map
                google={this.props.google}
                zoom={2}
                style={mapStyles}
                ref="resultMap"
                onReady={(mapProps, map) => this.adjustMap(mapProps, map, points)}
              > 
              {this.displayMarkers()}
              <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
                onClose={this.onClose}
                onOpen={e => {
                  this.onInfoWindowOpen(this.props, e);
                }}
              >
                <div >
                  <div>
                  <h5>House Bill: {this.state.selectedShipment.houseBill}</h5>
                  </div>
                  <div>
                  <h6>Current Status: {this.state.selectedShipment.currentStatus}</h6>
                  </div>
                  <div>
                  <h6>Mode: {this.state.selectedShipment.mode}</h6>
                  </div>
                  <div>
                  <h6>Progress: {this.state.selectedShipment.perc}% </h6>
                  </div>
                </div>
                <div id="iwc" />
              </InfoWindow>
              </Map>
              <div>
              </div>       
            </section>
            }

          else{
            shipPathPoints = points
            // if details page:
            mapComponent =
            <div >
            {/* {detailViewTable} */}
            <section className="panel"  >
            <header className="panel-heading">
              {'Shipment Map'}
            </header>
              <Map
                google={this.props.google}
                zoom={2}
                style={mapStyles}
                ref="resultMap"
                onReady={(mapProps, map) => this.adjustDetailMap(mapProps, map, latestPoint)}
                // controls={this._insertButton}
              > 
                            {/* <Button variant="success" size="sm"   style={{marginTop: '0px', position: 'absolute', zIndex: '99999'}} onClick={(mapProps, map) => this.adjustDetailMap(mapProps, map, latestPoint)}>
              Zoom to latest 
            </Button>  */}

              {this.displayMarkersDetailView()}
              <Polyline
                path={shipPathPoints}
                geodesic={false}
                options={{
                    strokeColor: "#ff2527",
                    strokeOpacity: 0.75,
                    strokeWeight: 2,
                }}
              />
              <InfoWindow
                marker={this.state.activeMarker}
                visible={this.state.showingInfoWindow}
                onClose={this.onClose}
                onOpen={e => {
                  this.onInfoWindowOpen(this.props, e);
                }}
              >
                {infoViewDetailsPage}

                <div id="iwc" />
              </InfoWindow>


              </Map>
              <div>
              </div>
              
            </section>

            </div>
          }
      






          }
        }
  
          return(
    
            <div>
              {mapComponent}
            </div>
    
          );
        }
      }
      
    
      export default withRouter(GoogleApiWrapper({
        apiKey: appConfig.googleMapAPI.Map_API_Key
      })(GoogleMapTracker));
    
    
