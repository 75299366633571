import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import StatsCard from '../../ComponentsGeneral/StatsCard/StatsCard'

  class StatsCardRowZU extends PureComponent {
    constructor( props )
    {
      super( props );
    }





    onClickInTransit = () => {
      const values = queryString.parse(this.props.location.search)

      var paramInTransit = 'intransit'

      this.props.history.push({
        pathname: 'zulily/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'shiptocountry=' + values.shiptocountry + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramInTransit

        }); 
        
    }

    onClickAlertFlightDepart = () => {
      const values = queryString.parse(this.props.location.search)

      var paramAlertFlightDepart = 'alertflightdepart'

      this.props.history.push({
        pathname: 'zulily/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'shiptocountry=' + values.shiptocountry + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramAlertFlightDepart

        }); 
        
    }

    onClickAlertFlightArrival  = () => {
      const values = queryString.parse(this.props.location.search)

      var paramAlertFlightArrival = 'alertflightarrival'

      this.props.history.push({
        pathname: 'zulily/shiplist',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'shiptocountry=' + values.shiptocountry + '&' +
        'finalcarrier=' + values.finalcarrier  + '&' +
        'filter=' + paramAlertFlightArrival

        }); 
        
    }




    onClickReceiptToPortDelivery = () => {
      const values = queryString.parse(this.props.location.search)

      this.props.history.push({
        pathname: 'zulily/reporting',
        search: 'startdt=' + values.startdt + '&' +
        'enddt=' + values.enddt + '&' +
        'datetype=' + values.datetype + '&' +
        'notdeparted=' + values.notdeparted + '&' +
        'portexit=' + values.portexit + '&' +
        'portentry=' + values.portentry + '&' +
        'shiptocountry=' + values.shiptocountry + '&' +
        'finalcarrier=' + values.finalcarrier 
        }); 
        
    }

    StatCardColor = ( number, category ) => {
      if( number == '0' ) {
        return 'lightestgrey'
      }
      else if( category == 'Alerts' ) {
        return 'red'
      }

  
    }

    render() {


        if (this.props.isStatCardDataLoading) {
            return(
              <div align="center">
              <section className="panel">
                <header className="panel-heading">
                  {'Stat Cards'}
                </header>
                </section>
                <Loader 
                  type="Plane"
                  color="#00BFFF"
                  height="50"	
                  width="50"
                /> 
              </div>
            )
          }


  
          if (!this.props.isStatCardDataLoading) {
            if (this.props.StatCardData.length > 0) {
              var InTransit = String(this.props.StatCardData[0].InTransit);

              var AvgMCPerMAWB = String(this.props.StatCardData[0].AvgMCPerMAWB);
              var AvgPackagesPerMAWB = String(this.props.StatCardData[0].AvgPackagesPerMAWB);
              var AvgPackagePerMC = String(this.props.StatCardData[0].AvgPackagesPerMC);
              var AvgOTDtoFlightDepart = String(this.props.StatCardData[0].AvgOTDtoFlightDepart) + ' Days';
              var AvgOTDtoFlightArrival = String(this.props.StatCardData[0].AvgOTDtoFlightArrival) + ' Days';
              var AvgWeightPerMC = String(this.props.StatCardData[0].AvgWeightPerMC) + ' lbs';
              var AvgWeightPerPackage = String(this.props.StatCardData[0].AvgWeightPerPackage) + ' lbs';
              var DimWeightFactor = String(this.props.StatCardData[0].DimWeightFactor);

              var AlertFlightDepart = String(this.props.StatCardData[0].AlertFlightDepart);
              var AlertFlightArrival = String(this.props.StatCardData[0].AlertFlightArrival);
              

            }
            else {
              var InTransit = 0;

              var AvgMCPerMAWB = 0;
              var AvgPackagesPerMAWB = 0;
              var AvgPackagePerMC = 0;
              var AvgOTDtoFlightDepart = 0;
              var AvgOTDtoFlightArrival = 0;
              var AvgWeightPerMC = 0;
              var AvgWeightPerPackage = 0;
              var DimWeightFactor = 0;
              var AlertFlightDepart = 0;
              var AlertFlightArrival = 0;
            }
          }

        let statCardPkgInTransit;
        statCardPkgInTransit=
            <div style={{cursor: 'pointer'}} onClick={this.onClickInTransit} >
              <StatsCard
                  statValue={InTransit}
                  statLabel={'# Packages in-transit'}
                  icon={<i className="fa fa-truck" />}
                  backColor={'green'}
                  isMobile={this.props.isMobile}
              />
            </div>


        let statCardMCPerMAWB;
        statCardMCPerMAWB=
            <StatsCard
                statValue={AvgMCPerMAWB}
                statLabel={'Avg MC per MAWB'}
                icon={<i className="fa fa-cube" />}
                backColor={'darkblue'}
                isMobile={this.props.isMobile}
            />

        let statCardPkgsPerMAWB;
        statCardPkgsPerMAWB=
            <StatsCard
                statValue={AvgPackagesPerMAWB}
                statLabel={'Avg Packages per MAWB'}
                icon={<i className="fa fa-gift" />}
                backColor={'darkblue'}
                isMobile={this.props.isMobile}
            />

        let statCardPkgsPerMC;
        statCardPkgsPerMC = 
            <StatsCard
              statValue={AvgPackagePerMC}
              statLabel={'Avg Packages per MC'}
              icon={<i className="fa fa-gift" />}
              backColor={'darkblue'}
              isMobile={this.props.isMobile}
          />     
          
          
          let statOTDtoFlightDepart;
          statOTDtoFlightDepart = 
              <StatsCard
                statValue={AvgOTDtoFlightDepart}
                statLabel={'Avg Manifest to Flight Depart'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
            />   

          let statOTDtoFlightArrival;
          statOTDtoFlightArrival = 
              <StatsCard
                statValue={AvgOTDtoFlightArrival}
                statLabel={'Avg Manifest to Flight Arrival'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
            />  


          let statCardAvgWeightPerMC;
          statCardAvgWeightPerMC=
          <StatsCard
              statValue={AvgWeightPerMC}
              statLabel={'Avg Weight Per MC'}
              icon={<i className="fa fa-balance-scale" />}
              backColor={'lightgrey'}
              isMobile={this.props.isMobile}
          />  

          let statCardAvgWeightPerPackage;
          statCardAvgWeightPerPackage=
          <StatsCard
              statValue={AvgWeightPerPackage}
              statLabel={'Avg Weight Per Package'}
              icon={<i className="fa fa-balance-scale" />}
              backColor={'lightgrey'}
              isMobile={this.props.isMobile}
          />  

          let statCardDimWeightFactor;
          statCardDimWeightFactor=
          <StatsCard
              statValue={DimWeightFactor}
              statLabel={'Dim Weight Factor'}
              icon={<i className="fa fa-balance-scale" />}
              backColor={'lightgrey'}
              isMobile={this.props.isMobile}
          />  

          let statCardAlertFlightDepart;
          statCardAlertFlightDepart=
          <div style={{cursor: 'pointer'}} onClick={this.onClickAlertFlightDepart} >
            <StatsCard
                statValue={AlertFlightDepart}
                statLabel={'Flight Depart Alerts'}
                icon={<i className="fa fa-exclamation-triangle" />}
                backColor={this.StatCardColor(AlertFlightDepart, 'Alerts')}
                isMobile={this.props.isMobile}
            />  
          </div>

          let statCardAlertFlightArrival;
          statCardAlertFlightArrival=
          <div style={{cursor: 'pointer'}} onClick={this.onClickAlertFlightArrival} >
            <StatsCard
                statValue={AlertFlightArrival}
                statLabel={'Flight Arrival Alerts'}
                icon={<i className="fa fa-exclamation-triangle" />}
                backColor={this.StatCardColor(AlertFlightArrival, 'Alerts')}
                isMobile={this.props.isMobile}
            />  
          </div>

        let statCardRowDiv;
        if(this.props.isMobile){
        statCardRowDiv = 
            <div>
                <div style={{marginBottom: '1px'}}>
                {statCardPkgInTransit}
                </div>


                <div style={{marginBottom: '1px'}}>
                {statCardPkgsPerMC}
                </div>

                <div style={{marginBottom: '1px'}}>
                {statOTDtoFlightDepart}
                </div>          
                <div style={{marginBottom: '1px'}}>
                {statOTDtoFlightArrival}
                </div>      
                <div style={{marginBottom: '1px'}}>
                {statCardDimWeightFactor}
                </div>      
         
                <div style={{marginBottom: '1px'}}>
                {statCardAvgWeightPerPackage}
                </div>      
                <div style={{marginBottom: '1px'}}>
                {statCardAlertFlightDepart}
                </div>   
                <div style={{marginBottom: '1px'}}>
                {statCardAlertFlightArrival}
                </div>   



            </div>
            }
        else{
        statCardRowDiv = 
            <div>
                <div
                    className="row"
                    style={{marginBottom: '15px'}}>
                      <div className="col-md-3" >
                      {statCardPkgInTransit}
                      </div>

                      <div className="col-md-3" >
                      {statCardPkgsPerMC}
                      </div>

                      <div className="col-md-3" >
                      {statCardDimWeightFactor}
                      </div>

                      <div className="col-md-3" >
                      {statCardAvgWeightPerPackage}
                      </div>  


                </div>

                <div
                    className="row"  
                    style={{marginBottom: '15px'}}>

    
                </div>

                <div
                    className="row"  
                    style={{marginBottom: '5px'}}>
                      <div className="col-md-3" >
                      {statOTDtoFlightDepart}
                      </div>
                      <div className="col-md-3" >
                      {statOTDtoFlightArrival}
                      </div>
                      <div className="col-md-3" >
                      {statCardAlertFlightDepart}
                      </div>
                      <div className="col-md-3" >
                      {statCardAlertFlightArrival}
                      </div>


   
                </div>



            </div>
        }


      return(
        <div>
            {statCardRowDiv}
        </div>
      );

    }
  }
  

  export default withRouter(StatsCardRowZU);
  
