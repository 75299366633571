import {connect} from 'react-redux';
import React, {Component} from 'react';
import KPI from './KPI'
import * as actions from '../../../redux/actions/googleActions';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

//add props from reducer here
const mapStateToProps = store =>
({
    kpiOverviewData:                store.reportingReducer.kpiOverviewData,
    isKPIOverviewLoading:           store.reportingReducer.isKPIOverviewLoading,

    kpiDetailData:                  store.reportingReducer.kpiDetailData,
    isKPIDetailLoading:             store.reportingReducer.isKPIDetailLoading,
    isKPILaneLoading:               store.reportingReducer.isKPILaneLoading,
    kpiCarrierCalc:                 store.reportingReducer.kpiCarrierCalc,
    laneCalc:                       store.reportingReducer.laneCalc,

    isAlertKpiTrendLoading:         store.alertKpiTrendReducer.isAlertKpiTrendLoading,
    alertKpiTrendData:              store.alertKpiTrendReducer.alertKpiTrendData,


    serviceIssueKpiData:            store.reportingReducer.serviceIssueKpiData,
    isServiceIssueKpiDataLoading:   store.reportingReducer.isServiceIssueKpiDataLoading,
    serviceIssueKpiCalc:            store.reportingReducer.serviceIssueKpiCalc,


    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail
    
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getKpiOverviewData:( _db, _email, _startDT, _endDT, _forwarder, _mode  ) => //get KPI overview data
    {
        dispatch( actions.getKpiOverviewData( _db, _email, _startDT, _endDT, _forwarder, _mode ) )
    },
    


    getShipmentTrackerDataByJobID:( _db,  _email, _housebill ) =>  //open ship details page
    {
        dispatch( actions.getShipmentTrackerDataByJobID( _db, _email, _housebill ) )
    },


    getShipmentSearchData:( _db, _email, _housebill ) =>
    {
        dispatch( actions.getShipmentSearchData( _db, _email, _housebill ) )
    },

    getAlertKpiTrendData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getAlertKpiTrendData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },


    getServiceIssueKpiData:( _db, _email, _jobid, _housebill, _startDT, _endDT, _dateType, _mode, _forwarder, _service, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) =>
    {
        dispatch( actions.getServiceIssueKpiData( _db, _email, _jobid, _housebill, _startDT, _endDT, _dateType, _mode, _forwarder, _service, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) )
    },


});


class KPIContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	


    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    

    


    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramInTransit = 'O'
    var paramMode = null
    var paramCarrier = null
    var paramFilter = null
    var paramFilterValue = null
    var paramView = null
  



    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
        paramInTransit = values.intransit
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
        paramCarrier = values.carrier
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }
    if(typeof(values.view) !== 'undefined' && values.view != null ){
        paramView = values.view
    }



    this.props.getAlertKpiTrendData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );
    
    this.props.getKpiOverviewData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null );

    this.props.getServiceIssueKpiData(  this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, paramStartDT, paramEndDT, paramDateType, paramMode, null, null, null, null, null, paramFilter, paramFilterValue, paramView    )


    }
	
    render()
    {
    	return (
            <KPI 
            kpiOverviewData={ this.props.kpiOverviewData }
            isKPIOverviewLoading={ this.props.isKPIOverviewLoading }
            kpiDetailData={ this.props.kpiDetailData }
            isKPIDetailLoading={ this.props.isKPIDetailLoading }
            kpiCarrierCalc={ this.props.kpiCarrierCalc }
            laneCalc={ this.props.laneCalc }
            isKPILaneLoading={ this.props.isKPILaneLoading }
            
            getKpiOverviewData={ this.props.getKpiOverviewData }


        
            getShipmentTrackerDataByJobID={this.props.getShipmentTrackerDataByJobID}

            isAlertKpiTrendLoading={ this.props.isAlertKpiTrendLoading }
            alertKpiTrendData={ this.props.alertKpiTrendData }
            getAlertKpiTrendData={this.props.getAlertKpiTrendData}

            serviceIssueKpiData={this.props.serviceIssueKpiData}
            isServiceIssueKpiDataLoading={this.props.isServiceIssueKpiDataLoading}
            serviceIssueKpiCalc={this.props.serviceIssueKpiCalc}

            userSignInData={ this.props.userSignInData }
            currentUserEmail={this.props.currentUserEmail}

            getShipmentSearchData={this.props.getShipmentSearchData}


            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(KPIContainer));



