import * as types from '../constants/actionsUserTypes';

/* ################################### SIGNIN ACTIONS ################################### */
// gets data for shipment history
export const appSignIn = ( _email ) =>
( {
    type:    types.APP_SIGNIN,
    payload: {
        email: _email
    }
} );

export const getPageConfig = ( _email, _filter ) =>
( {
    type:    types.GET_PAGE_CONFIG,
    payload: {
        email: _email,
        filter: _filter
    }
} );


/* ################################### SIGNOUT - CLEAR CACHE ACTIONS ################################### */
export function appSignOut( ){
    return {
       type: types.RESET_REDUX_STATE
   };
}


 

/* ################################### NEW USER SIGNUP ACTIONS ################################### */
// create new user requet
export const addNewUser = ( _firstname, _lastname, _email, _active, _admin, _pendingApproval  ) =>
( {
    type:    types.USER_ADD_POST,
    payload: {
        firstname: _firstname,
        lastname: _lastname,
        email: _email,
        active: _active,
        admin: _admin,
        pendingApproval: _pendingApproval
    }
} );

// get pending user requests
export const getPendingUserAccounts = ( _email ) =>
( {
    type:    types.PENDING_USER_GET,
    payload: {
        email: _email
    }
} );

// get pending user requests
export const postUserUpdate = ( _email, _emailToUpdate, _firstname, _lastname, _active, _admin, _pending ) =>
( {
    type:    types.USER_UPDATE_POST,
    payload: {
        email: _email,
        emailToUpdate: _emailToUpdate,
        firstname: _firstname,
        lastname: _lastname,
        active: _active,
        admin: _admin,
        pending: _pending
    }
} );

// get pending user requests
export const getUserAccounts = ( _email ) =>
( {
    type:    types.USER_ACCOUNT_GET,
    payload: {
        email: _email
    }
} );

export const postDeleteUser = ( _email, _emailToDelete, _firstname, _lastname, _active, _admin, _pending ) =>
( {
    type:    types.USER_DELETE_POST,
    payload: {
        email: _email,
        emailToDelete: _emailToDelete,
        firstname: _firstname,
        lastname: _lastname,
        active: _active,
        admin: _admin,
        pending: _pending
    }
} );

export const postUserPermissions = ( _email, _emailToUpdate, _permission, _newPermValue ) =>
( {
    type:    types.USER_PERMISSIONS_POST,
    payload: {
        email: _email,
        emailToUpdate: _emailToUpdate,
        permission: _permission,
        newPermValue: _newPermValue
    }
} );
