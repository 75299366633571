import { connect } from 'react-redux'

import { reducer } from 'aws-cognito-redux-saga'

import NewUserSignUpContainer from './NewUserSignUp'
import * as actions from '../../../redux/actions/actionsUser';

const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}

const mapDispatchToProps = dispatch => {
  return {
    init: () => {
      dispatch(reducer.init())
    },
    signUp: (username, password) => {
      dispatch(reducer.signUp(username, password))
    },
    addNewUser:( _firstname, _lastname, _email, _active, _admin, _pendingApproval  ) =>
    {
        dispatch( actions.addNewUser( _firstname, _lastname, _email, _active, _admin, _pendingApproval ) )
    }
  }
}



export default connect(mapStatetoProps, mapDispatchToProps)(NewUserSignUpContainer)








// import {connect} from 'react-redux';
// import React, {Component} from 'react';
// import NewUserSignUp from './NewUserSignUp'
// import * as actions from '../../actions/actions';



// const mapStateToProps = store =>
// ({
//     // userServicesReducer
//     // shipmentHistoryBar:          store.shipmentHistoryBarReducer.shipmentHistoryBar,
//     userSignUpLoading:      store.userServicesReducer.userSignUpLoading,
//     userSignUpComplete:     store.userServicesReducer.userSignUpComplete
// });

// const mapDispatchToProps = dispatch =>
// ({

//     addNewUser:( _firstname, _lastname, _email ) =>
//     {
//         dispatch( actions.addNewUser( _firstname, _lastname, _email ) )
//     },
    
// });


// class NewUserSignUpContainer extends Component
// {
// 	constructor(props)
// 	{
// 		super(props);
		
// 	}
	
// 	componentDidMount()
// 	{
//         // this.props.getHistoryBarData();
// 	}
	
	
//     render()
//     {


		
//     	return (

//             <NewUserSignUp 
//                 addNewUser={this.props.addNewUser}
//                 userSignUpLoading={this.props.userSignUpLoading}
//                 userSignUpComplete={this.props.userSignUpComplete}
//             />

//         )
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(NewUserSignUpContainer);




