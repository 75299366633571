import {connect} from 'react-redux';
import React, {Component} from 'react';
import ChartShipToPort from './ChartShipToPort'
import * as actions from '../../../redux/actions/zuActions';
import {appSettings} from '../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';



const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    isDailyShipPortDataLoading:         store.zuDataReducer.isDailyShipPortDataLoading,
    DailyShipPortData:                  store.zuDataReducer.DailyShipPortData,


});

const mapDispatchToProps = dispatch =>
({

    zuGetDailyShipmentsToPorts:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry ) =>
    {
        dispatch( actions.zuGetDailyShipmentsToPorts( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _shipToCountry ) )
    },

    zuGetMawbDetails:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry ) =>
    {
        dispatch( actions.zuGetMawbDetails( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry ) )
    }
    

});


class ChartShipToPortContainer extends Component
{
	constructor(props)
	{
		super(props);
		
	}

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null
    var paramShipToCountry = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }
    if(typeof(values.shiptocountry) !== 'undefined' && values.shiptocountry != null ){
        paramShipToCountry = values.shiptocountry
    }


    this.props.zuGetDailyShipmentsToPorts( appSettings.zuDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramShipToCountry );

    }

	
	
    render()
    {

    	return (

            <ChartShipToPort 

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isDailyShipPortDataLoading={this.props.isDailyShipPortDataLoading}
                DailyShipPortData={this.props.DailyShipPortData}


                zuGetMawbDetails={this.props.zuGetMawbDetails}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ChartShipToPortContainer));



