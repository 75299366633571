import * as types from '../constants/actionsUserTypes';
import * as zutypes from '../constants/zuActionTypes';
import * as gbtypes from '../constants/gbActionTypes';
import * as googletypes from '../constants/googleActionTypes';
import * as upstypes from '../constants/upsActionTypes';
import axios from 'axios';
import async from 'async';
import {appConfig, awsCognitoConfig} from '../../app/appConfig'
import {appSettings} from '../../app/appSettings'
import Logger from '../logger/Logger';

import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";


const poolData = {UserPoolId: awsCognitoConfig.cognitoLogin.Auth.UserPoolId, ClientId: awsCognitoConfig.cognitoLogin.Auth.ClientId};
const userPool = new AmazonCognitoIdentity.CognitoUserPool(poolData);
const cognitoUser = userPool.getCurrentUser();

const getCall = ( _call ) =>
{
	return ( callback ) =>
	{
		var jwtToken = ''

		if(_call.onComplete === 'USER_ADD_COMPLETE') {
			jwtToken = ''
		}
		else{
		var yourIdToken
		if(cognitoUser === null) {window.location.reload();}
		cognitoUser.getSession(function(err, data) {  
			if (err) {
			  // Prompt the user to reauthenticate by hand...
			//   window.alert("Session expired, please reload page.")
				console.log('Session expired, auto reload page')
				window.location.reload(); 
			} else {

			  const cognitoUserSession = data;
			  yourIdToken = cognitoUserSession.getIdToken().jwtToken;
			//   const yourAccessToken = cognitoUserSession.getAccessToken().jwtToken;
			}
		  });
		jwtToken = yourIdToken
		}

		axios({
			method: _call.type,
			url: `${_call.endpoint}`,
			crossDomain : true,
			headers: {
				"Access-Control-Allow-Origin": "*",
				'Authorization' : jwtToken
				},
			// data: {"param": _call.paramvalue}
			data: { "proc": _call.proc, "param": _call.paramvalue }
		})
		.then(( res ) =>
		{
			Logger.log({
				id:   Logger.LOGGER_IDS.SERVICES,
				type: Logger.types.LOG
			}, 'CALL COMPLETE: ', _call.onComplete, res, _call.saveData );
			
			_call.next({
				type:    _call.onComplete,
				payload: res.data,
				data:    _call.data || null,
				saveData:_call.saveData || null
			});
			
			callback( null, _call.onComplete, _call.data );
		})
		.catch(( error ) =>
		{
			Logger.log({
				id:   Logger.LOGGER_IDS.SERVICES,
				type: Logger.types.LOG
			}, 'CALL ERROR: ', _call.onError, error, _call.saveData );
			
			axios({
				method: _call.type,
				url: `${_call.endpoint}`,
				crossDomain : true,
				headers: {
					"Access-Control-Allow-Origin": "*",
					'Authorization' : jwtToken
					},
				// data: {"param": _call.paramvalue}
				data: { "proc": _call.proc, "param": _call.paramvalue }
			})
			.then(( res ) =>
			{
				Logger.log({
					id:   Logger.LOGGER_IDS.SERVICES,
					type: Logger.types.LOG
				}, 'CALL COMPLETE: ', _call.onComplete, res, _call.saveData );
				
				_call.next({
					type:    _call.onComplete,
					payload: res.data,
					data:    _call.data || null,
					saveData:_call.saveData || null
				});
				
				callback( null, _call.onComplete, _call.data );
			})
			.catch(( error ) =>
			{
				Logger.log({
					id:   Logger.LOGGER_IDS.SERVICES,
					type: Logger.types.LOG
				}, 'CALL ERROR: ', _call.onError, error, _call.saveData );
				
				_call.next({
					type:    _call.onError,
					payload: error,
					data:    _call.data || null,
					saveData:_call.saveData || null
				});
				
				callback( null, _call.onError );
			});
		});
	};
};

const getData = ( _calls ) =>
{
	if( !_calls || !_calls.data )
	{
		return null;
	}
	
	const calls = [];
	
	_calls.data.forEach(( call ) =>
	{
		calls.push( getCall( call ));
	});
	
	let callType = _calls.type || 'series';
	async[ callType ]( calls, null );
};



const dataService = store => next => action =>
{

	next( action );

	switch( action.type )
	{





////////////////////////////////////////////////////////////USER SERVICES///////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		case types.APP_SIGNIN:
			getData( 
			{
				type: 'series',
				data: [

					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetUser,
						next,
						onError:    types.APP_SIGNIN_ERROR,
						onComplete: types.APP_SIGNIN_COMPLETE,
						paramvalue:	appSettings.userDB + ",'" + action.payload.email + "'"
					}
				]
			}
			)
			break;



		case types.GET_PAGE_CONFIG:

			getData( 
			{
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetPageConfigUsers,
						next,
						onError:    types.ERROR_PAGE_CONFIG,
						onComplete: types.COMPLETE_PAGE_CONFIG,
						paramvalue:	appSettings.userDB + ",'" + action.payload.email + "'," + action.payload.filter 
					}
				]
			}
			)
			break;

		case types.USER_ADD_POST:
            getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_UserAdd,
						next,
						onError:    types.USER_ADD_ERROR,
						onComplete: types.USER_ADD_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "', '" + action.payload.firstname + "', '" + action.payload.lastname + "', " + action.payload.active + "," + action.payload.admin + "," + action.payload.pendingApproval 
					}
				]
			}
			)
			break;

		case types.PENDING_USER_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetPendingUserAccounts,
						next,
						onError:    types.PENDING_USER_ERROR,
						onComplete: types.PENDING_USER_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "'"
					}
				]
			}
			)
			break;
			
		case types.USER_UPDATE_POST:

			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_UserUpdate,
						next,
						onError:    types.USER_UPDATE_ERROR,
						onComplete: types.USER_UPDATE_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "', '" + action.payload.emailToUpdate + "', '" + action.payload.firstname + "', '" + action.payload.lastname + "', " + action.payload.active + ", " + action.payload.admin + ", " + action.payload.pending
					},
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetPendingUserAccounts,
						next,
						onError:    types.PENDING_USER_ERROR,
						onComplete: types.PENDING_USER_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "'"
					},
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetUserAccounts,
						next,
						onError:    types.USER_ACCOUNT_ERROR,
						onComplete: types.USER_ACCOUNT_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "'"
					}
						
					
				]
			}
			)
			break;


		case types.USER_PERMISSIONS_POST:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_PostUserPermissions,
						next,
						onError:    types.USER_PERMISSIONS_ERROR,
						onComplete: types.USER_PERMISSIONS_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "', '" + action.payload.emailToUpdate + "', " + action.payload.permission + ", " + action.payload.newPermValue 
					},
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetUserAccounts,
						next,
						onError:    types.USER_ACCOUNT_ERROR,
						onComplete: types.USER_ACCOUNT_COMPLETE,
						paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "'"
					}
						
					
				]
			}
			)
			break;	
			
			case types.USER_ACCOUNT_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetUserAccounts,
							next,
							onError:    types.USER_ACCOUNT_ERROR,
							onComplete: types.USER_ACCOUNT_COMPLETE,
							paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "'"
						}
					]
				}
				)
				break;
	
			case types.USER_DELETE_POST:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_UserUpdate,
							next,
							onError:    types.USER_DELETE_ERROR,
							onComplete: types.USER_DELETE_COMPLETE,
							paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "', '" + action.payload.emailToDelete + "', '" + action.payload.firstname + "', '" + action.payload.lastname + "', " + action.payload.active + ", " + action.payload.admin + ", " + action.payload.pending 
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetUserAccounts,
							next,
							onError:    types.USER_ACCOUNT_ERROR,
							onComplete: types.USER_ACCOUNT_COMPLETE,
							paramvalue:	appSettings.userDB + "," + "'" + action.payload.email + "'"
						}
					]
				}
				)
				break;

			






////////////////////////////////////////////////////////////ZU SERVICES/////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		case zutypes.OTD_TO_MC_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoMC,
						next,
						onError:    zutypes.OTD_TO_MC_DATA_ERROR,
						onComplete: zutypes.OTD_TO_MC_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ
					}
				]
			}
			)	
			break;	

		case zutypes.OTD_TO_FLIGHT_DEPART_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoFlightDepart,
						next,
						onError:    zutypes.OTD_TO_FLIGHT_DEPART_DATA_ERROR,
						onComplete: zutypes.OTD_TO_FLIGHT_DEPART_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;		
			
		case zutypes.OTD_TO_FLIGHT_ARRIVAL_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoEstArrival,
						next,
						onError:    zutypes.OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR,
						onComplete: zutypes.OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	

		case zutypes.DAILY_SHIP_PORTS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetDailyShipmentsToPorts,
						next,
						onError:    zutypes.DAILY_SHIP_PORTS_DATA_ERROR,
						onComplete: zutypes.DAILY_SHIP_PORTS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	

		case zutypes.AGING_CHART_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetAgingChart,
						next,
						onError:    zutypes.AGING_CHART_DATA_ERROR,
						onComplete: zutypes.AGING_CHART_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	


		case zutypes.DIM_WEIGHT_CHART_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetDimWeightChart,
						next,
						onError:    zutypes.DIM_WEIGHT_CHART_DATA_ERROR,
						onComplete: zutypes.DIM_WEIGHT_CHART_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;			

		case zutypes.STAT_CARD_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetStatCardValues,
						next,
						onError:    zutypes.STAT_CARD_DATA_ERROR,
						onComplete: zutypes.STAT_CARD_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	

		case zutypes.OTD_TO_THREE_AVGS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoThreeAvgs,
						next,
						onError:    zutypes.OTD_TO_THREE_AVGS_DATA_ERROR,
						onComplete: zutypes.OTD_TO_THREE_AVGS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	


		case zutypes.MAWB_DETAILS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetMawbDetails,
						next,
						onError:    zutypes.MAWB_DETAILS_DATA_ERROR,
						onComplete: zutypes.MAWB_DETAILS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	

		case zutypes.MC_DETAILS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetMCDetails,
						next,
						onError:    zutypes.MC_DETAILS_DATA_ERROR,
						onComplete: zutypes.MC_DETAILS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb
					}
				]
			}
			)	
			break;	

		case zutypes.HAWB_DETAILS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetHawbDetails,
						next,
						onError:    zutypes.HAWB_DETAILS_DATA_ERROR,
						onComplete: zutypes.HAWB_DETAILS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb + ", " + action.payload.trackingno    
					}
				]
			}
			)	
			break;	

		
		case zutypes.HAWB_MC_DETAILS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetHawbDetails,
						next,
						onError:    zutypes.HAWB_MC_DETAILS_DATA_ERROR,
						onComplete: zutypes.HAWB_MC_DETAILS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb + ", " + action.payload.trackingno    
					}
				]
			}
			)	
			break;	

		case zutypes.JOB_EVENTS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetJobEvents,
						next,
						onError:    zutypes.JOB_EVENTS_DATA_ERROR,
						onComplete: zutypes.JOB_EVENTS_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.mawb + ", " + action.payload.mc + ", " + action.payload.hawb + ", " + action.payload.filter
					}
				]
			}
			)	
			break;	

		case zutypes.IBC_DTL_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetIBCInboundDtl,
						next,
						onError:    zutypes.IBC_DTL_DATA_ERROR,
						onComplete: zutypes.IBC_DTL_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb + ", " + action.payload.afhawb
					}
				]
			}
			)	
			break;	



		case zutypes.HAZ_MAT_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetHazMat,
						next,
						onError:    zutypes.HAZ_MAT_DATA_ERROR,
						onComplete: zutypes.HAZ_MAT_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue
					}
				]
			}
			)	
			break;	



		case zutypes.PENDING_SAILING_CHART_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetChartPendingSailing,
						next,
						onError:    zutypes.PENDING_SAILING_CHART_DATA_ERROR,
						onComplete: zutypes.PENDING_SAILING_CHART_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter
					}
				]
			}
			)	
			break;	

		case zutypes.DEMURRAGE_CHART_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetChartDemurrage,
						next,
						onError:    zutypes.DEMURRAGE_CHART_DATA_ERROR,
						onComplete: zutypes.DEMURRAGE_CHART_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter
					}
				]
			}
			)	
			break;	



		case zutypes.UPS_MI_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetUpsMI,
						next,
						onError:    zutypes.UPS_MI_DATA_ERROR,
						onComplete: zutypes.UPS_MI_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " +  action.payload.trackingno    
					}
				]
			}
			)	
			break;	

		case zutypes.AVG_CUST_CLEAR_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetChartAvgCustomClear,
						next,
						onError:    zutypes.AVG_CUST_CLEAR_DATA_ERROR,
						onComplete: zutypes.AVG_CUST_CLEAR_DATA_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	

		case zutypes.DS_NEURAL_NET_SCORE_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_DS_UPS_NeuralNet,
						next,
						onError:    zutypes.DS_NEURAL_NET_SCORE_ERROR,
						onComplete: zutypes.DS_NEURAL_NET_SCORE_COMPLETE,
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " +  action.payload.trackingno    
					}
				]
			}
			)	
			break;	







////////////////////////////////////////////////////////////ZU RUN PROC API SERVICES/////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

		case zutypes.EVOLVE_BROKERAGE_REPORT_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_RunStoredProc,
						next,
						onError:    zutypes.EVOLVE_BROKERAGE_REPORT_DATA_ERROR,
						onComplete: zutypes.EVOLVE_BROKERAGE_REPORT_DATA_COMPLETE,
						proc: 'GetEvolveBrokerageReport', //Don't type "Dashboard"
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue + ", " + action.payload.shipToCountry
					}
				]
			}
			)	
			break;	

		case zutypes.EVOLVE_BROKERAGE_STAT_CARDS_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_RunStoredProc,
						next,
						onError:    zutypes.EVOLVE_BROKERAGE_STAT_CARDS_ERROR,
						onComplete: zutypes.EVOLVE_BROKERAGE_STAT_CARDS_COMPLETE,
						proc: 'GetEvolveBrokerageStatCards', //Don't type "Dashboard"
						paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue
					}
				]
			}
			)	
			break;	


			case zutypes.ZU_MBOL_EMAIL_FILENAME_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_RunStoredProc,
							next,
							onError:    zutypes.ZU_MBOL_EMAIL_FILENAME_ERROR,
							onComplete: zutypes.ZU_MBOL_EMAIL_FILENAME_COMPLETE,
							proc: 'ZuMbolEmailFilenameGet', //Don't type "Dashboard"
							paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.mawb  + ", " + action.payload.filetype
						}
					]
				}
				)	
				break;	
	

				case zutypes.ZU_MBOL_EMAIL_URL_GET:
					getData( {
						type: 'series',
						data: [
							{
								type:       'post',
								endpoint:   appConfig.API.data.API_Dashboard_ZuGetEmailFileURL,  ////***************This is specific to get URL****************
								next,
								onError:    zutypes.ZU_MBOL_EMAIL_URL_ERROR,
								onComplete: zutypes.ZU_MBOL_EMAIL_URL_COMPLETE,
								proc: 'ZuMbolEmailFilenameGet', //Don't type "Dashboard"
								// paramvalue:	appSettings.zuDB + "," + "'" + action.payload.email + "', " + action.payload.filename
								paramvalue:	action.payload.filename
							}
						]
					}
					)	
					break;	
		

////////////////////////////////////////////////////////////GB SERVICES/////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
		case gbtypes.GB_MAWB_DETAILS_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_Dashboard_GetMawbDetails,
						next,
						onError:    gbtypes.GB_MAWB_DETAILS_DATA_ERROR,
						onComplete: gbtypes.GB_MAWB_DETAILS_DATA_COMPLETE,
						paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
					}
				]
			}
			)	
			break;	


			case gbtypes.GB_OTD_TO_MC_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoMC,
							next,
							onError:    gbtypes.GB_OTD_TO_MC_DATA_ERROR,
							onComplete: gbtypes.GB_OTD_TO_MC_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_OTD_TO_FLIGHT_DEPART_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoFlightDepart,
							next,
							onError:    gbtypes.GB_OTD_TO_FLIGHT_DEPART_DATA_ERROR,
							onComplete: gbtypes.GB_OTD_TO_FLIGHT_DEPART_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;		
				
			case gbtypes.GB_OTD_TO_FLIGHT_ARRIVAL_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoEstArrival,
							next,
							onError:    gbtypes.GB_OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR,
							onComplete: gbtypes.GB_OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartOriginFinalMileToDelivery,
							next,
							onError:    gbtypes.GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_ERROR,
							onComplete: gbtypes.GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

	
			case gbtypes.GB_DAILY_SHIP_PORTS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetDailyShipmentsToPorts,
							next,
							onError:    gbtypes.GB_DAILY_SHIP_PORTS_DATA_ERROR,
							onComplete: gbtypes.GB_DAILY_SHIP_PORTS_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_AGING_CHART_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetAgingChart,
							next,
							onError:    gbtypes.GB_AGING_CHART_DATA_ERROR,
							onComplete: gbtypes.GB_AGING_CHART_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
	
			case gbtypes.GB_DIM_WEIGHT_CHART_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetDimWeightChart,
							next,
							onError:    gbtypes.GB_DIM_WEIGHT_CHART_DATA_ERROR,
							onComplete: gbtypes.GB_DIM_WEIGHT_CHART_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;			
	
			case gbtypes.GB_STAT_CARD_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetStatCardValues,
							next,
							onError:    gbtypes.GB_STAT_CARD_DATA_ERROR,
							onComplete: gbtypes.GB_STAT_CARD_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_OTD_TO_THREE_AVGS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetOTDtoThreeAvgs,
							next,
							onError:    gbtypes.GB_OTD_TO_THREE_AVGS_DATA_ERROR,
							onComplete: gbtypes.GB_OTD_TO_THREE_AVGS_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_CHART_TRANS_METRICS_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartReportingTransitMetrics,
							next,
							onError:    gbtypes.GB_CHART_TRANS_METRICS_ERROR,
							onComplete: gbtypes.GB_CHART_TRANS_METRICS_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
				
				

			case gbtypes.GB_HAWB_REPORTING_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetHawbDetails,
							next,
							onError:    gbtypes.GB_HAWB_REPORTING_DATA_ERROR,
							onComplete: gbtypes.GB_HAWB_REPORTING_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb + ", " + action.payload.trackingno    + ", " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.filter+ ", " + action.payload.filterValue + ", "  + action.payload.notDeparted+ ", "  + action.payload.inTransit + ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
							
						}
					]
				}
				)	
				break;				

	
			case gbtypes.GB_MC_DETAILS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetMCDetails,
							next,
							onError:    gbtypes.GB_MC_DETAILS_DATA_ERROR,
							onComplete: gbtypes.GB_MC_DETAILS_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_HAWB_DETAILS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetHawbDetails,
							next,
							onError:    gbtypes.GB_HAWB_DETAILS_DATA_ERROR,
							onComplete: gbtypes.GB_HAWB_DETAILS_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb + ", " + action.payload.trackingno     
						}
					]
				}
				)	
				break;	
	
			
			case gbtypes.GB_HAWB_MC_DETAILS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetHawbDetails,
							next,
							onError:    gbtypes.GB_HAWB_MC_DETAILS_DATA_ERROR,
							onComplete: gbtypes.GB_HAWB_MC_DETAILS_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb + ", " + action.payload.trackingno     
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_JOB_EVENTS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetJobEvents,
							next,
							onError:    gbtypes.GB_JOB_EVENTS_DATA_ERROR,
							onComplete: gbtypes.GB_JOB_EVENTS_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.mawb + ", " + action.payload.mc + ", " + action.payload.hawb + ", " + action.payload.filter
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_IBC_DTL_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetIBCInboundDtl,
							next,
							onError:    gbtypes.GB_IBC_DTL_DATA_ERROR,
							onComplete: gbtypes.GB_IBC_DTL_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb
						}
					]
				}
				)	
				break;	
	
	
	
			case gbtypes.GB_HAZ_MAT_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetHazMat,
							next,
							onError:    gbtypes.GB_HAZ_MAT_DATA_ERROR,
							onComplete: gbtypes.GB_HAZ_MAT_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue + ", " + action.payload.customercode + ", " + action.payload.shipmenttype + ", " + action.payload.shipToCountry
						}
					]
				}
				)	
				break;	
	
	
	
			case gbtypes.GB_PENDING_SAILING_CHART_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetChartPendingSailing,
							next,
							onError:    gbtypes.GB_PENDING_SAILING_CHART_DATA_ERROR,
							onComplete: gbtypes.GB_PENDING_SAILING_CHART_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_DEMURRAGE_CHART_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetChartDemurrage,
							next,
							onError:    gbtypes.GB_DEMURRAGE_CHART_DATA_ERROR,
							onComplete: gbtypes.GB_DEMURRAGE_CHART_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_UPS_MI_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetUpsMI,
							next,
							onError:    gbtypes.GB_UPS_MI_DATA_ERROR,
							onComplete: gbtypes.GB_UPS_MI_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " +  action.payload.trackingno    
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_AVG_CUST_CLEAR_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_Dashboard_GetChartAvgCustomClear,
							next,
							onError:    gbtypes.GB_AVG_CUST_CLEAR_DATA_ERROR,
							onComplete: gbtypes.GB_AVG_CUST_CLEAR_DATA_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_DAY_OF_WEEK_METRICS_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDayOfWeekMetrics,
							next,
							onError:    gbtypes.GB_DAY_OF_WEEK_METRICS_ERROR,
							onComplete: gbtypes.GB_DAY_OF_WEEK_METRICS_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.filter + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_DATA_EXPORT_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_DATA_EXPORT_ERROR,
							onComplete: gbtypes.GB_DATA_EXPORT_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	




			case gbtypes.GB_CHART_DATA_EXPORT_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_CHART_DATA_EXPORT_ERROR,
							onComplete: gbtypes.GB_CHART_DATA_EXPORT_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_CHART_DETAIL_DATA_EXPORT_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_CHART_DETAIL_DATA_EXPORT_ERROR,
							onComplete: gbtypes.GB_CHART_DETAIL_DATA_EXPORT_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_FIRST_SCAN_TO_DELIVERY_METRIC_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_FIRST_SCAN_TO_DELIVERY_METRIC_ERROR,
							onComplete: gbtypes.GB_FIRST_SCAN_TO_DELIVERY_METRIC_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_ERROR,
							onComplete: gbtypes.GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_FIRST_SCAN_TO_RECOVERY_METRIC_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_FIRST_SCAN_TO_RECOVERY_METRIC_ERROR,
							onComplete: gbtypes.GB_FIRST_SCAN_TO_RECOVERY_METRIC_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_MANIFEST_TO_DELIVERY_METRIC_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_MANIFEST_TO_DELIVERY_METRIC_ERROR,
							onComplete: gbtypes.GB_MANIFEST_TO_DELIVERY_METRIC_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_CHART_MANIFEST_TO_DELIVERY_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartManifestToDelivery,
							next,
							onError:    gbtypes.GB_CHART_MANIFEST_TO_DELIVERY_ERROR,
							onComplete: gbtypes.GB_CHART_MANIFEST_TO_DELIVERY_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_DATA_REPORTS_EXPORT_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetDataExport,
							next,
							onError:    gbtypes.GB_DATA_REPORTS_EXPORT_ERROR,
							onComplete: gbtypes.GB_DATA_REPORTS_EXPORT_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ+ ", " + action.payload.mawb+ ", " + action.payload.mc+ ", " + action.payload.hawb+ ", " + action.payload.filter+ ", " + action.payload.filterValue+ ", " + action.payload.inTransit + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_MISSING_UPS_EVENTS_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_GetMissingUpsEvents,
							next,
							onError:    gbtypes.GB_MISSING_UPS_EVENTS_ERROR,
							onComplete: gbtypes.GB_MISSING_UPS_EVENTS_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.trackingNumber  
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_CHART_INWHS_TO_DELIVERY_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartInWhsToDelivery,
							next,
							onError:    gbtypes.GB_CHART_INWHS_TO_DELIVERY_ERROR,
							onComplete: gbtypes.GB_CHART_INWHS_TO_DELIVERY_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	



			case gbtypes.GB_CHART_INWHS_TO_FIRST_SCAN_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartInWhsToFirstScan,
							next,
							onError:    gbtypes.GB_CHART_INWHS_TO_FIRST_SCAN_ERROR,
							onComplete: gbtypes.GB_CHART_INWHS_TO_FIRST_SCAN_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	


			case gbtypes.GB_CHART_DAILY_SCAN_COUNT_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartDailyScanCount,
							next,
							onError:    gbtypes.GB_CHART_DAILY_SCAN_COUNT_ERROR,
							onComplete: gbtypes.GB_CHART_DAILY_SCAN_COUNT_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	



			case gbtypes.GB_CHART_INWHS_TO_FLIGHT_DEPART_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartInWhsToFlightDepart,
							next,
							onError:    gbtypes.GB_CHART_INWHS_TO_FLIGHT_DEPART_ERROR,
							onComplete: gbtypes.GB_CHART_INWHS_TO_FLIGHT_DEPART_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;					

			case gbtypes.GB_CHART_INWHS_TO_FREIGHT_RECOVERY_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartInWhsToFreightRecovery,
							next,
							onError:    gbtypes.GB_CHART_INWHS_TO_FREIGHT_RECOVERY_ERROR,
							onComplete: gbtypes.GB_CHART_INWHS_TO_FREIGHT_RECOVERY_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	

			case gbtypes.GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetChartFlightDepartToFirstScan,
							next,
							onError:    gbtypes.GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_ERROR,
							onComplete: gbtypes.GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.notDeparted+ ", " + action.payload.portExit+ ", " + action.payload.portEntry+ ", " + action.payload.finalMileCarrier+ ", " + action.payload.brokerageServ + ", " + action.payload.customercode + ", " + action.payload.shipmenttype
						}
					]
				}
				)	
				break;	
	
			case gbtypes.GB_BULK_HAWB_DETAILS_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.GB_API_Dashboard_GetBulkHawbDetails,
							next,
							onError:    gbtypes.GB_BULK_HAWB_DETAILS_ERROR,
							onComplete: gbtypes.GB_BULK_HAWB_DETAILS_COMPLETE,
							paramvalue:	appSettings.gbDB + "," + "'" + action.payload.email + "', " + action.payload.trackingNoString  
						}
					]
				}
				)	
				break;	
		
	
	








////////////////////////////////////////////////////////////GOOGLE SERVICES/////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


			case googletypes.STAT_CARD_ROW_DATA_GET:

				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetStatCard,
							next,
							onError:    googletypes.STAT_CARD_ROW_DATA_ERROR,
							onComplete: googletypes.STAT_CARD_ROW_DATA_COMPLETE,
							paramvalue: appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;

			case googletypes.STAT_CARD_IN_TRANSIT_DATA_GET:

				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.STAT_CARD_IN_TRANSIT_DATA_ERROR,
							onComplete: googletypes.STAT_CARD_IN_TRANSIT_DATA_COMPLETE,
							// paramvalue:	"'" + action.payload.email + "'"
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;

			case googletypes.STAT_CARD_PARAM_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							// endpoint:   appConfig.API.data.API_GetShipmentTrackerFilterParam,
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.STAT_CARD_PARAM_DATA_ERROR,
							onComplete: googletypes.STAT_CARD_PARAM_DATA_COMPLETE,
							// paramvalue:	"'" + action.payload.email + "', " + action.payload.paramvalue
							// paramvalue:	"'" + action.payload.email + "', null, '" + action.payload.paramvalue + "'"
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;			

			case googletypes.SHIPMENT_SCHEDULE_GRAPH_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipSchedPlot,
							next,
							onError:    googletypes.SHIPMENT_SCHEDULE_GRAPH_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_SCHEDULE_GRAPH_DATA_COMPLETE,
							paramvalue: appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;
				
			case googletypes.SHIPMENT_HISTORY_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentHistoryGraph,
							next,
							onError:    googletypes.SHIPMENT_HISTORY_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_HISTORY_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						}
					]
				}
				)
				break;

			case googletypes.SHIPMENT_HISTORY_BAR_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipHistoryBar,
							next,
							onError:    googletypes.SHIPMENT_HISTORY_BAR_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_HISTORY_BAR_DATA_COMPLETE,
							paramvalue: appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;

			case googletypes.ALERT_KPI_TREND_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetAlertKpiTrend,
						next,
						onError:    googletypes.ALERT_KPI_TREND_DATA_ERROR,
						onComplete: googletypes.ALERT_KPI_TREND_DATA_COMPLETE, 
						paramvalue: appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
					}
				]
			}
			)
			break;


			case googletypes.NETWORK_REPORTING_DATA_GET:
			getData( {
				type: 'series',
				data: [
					{
						type:       'post',
						endpoint:   appConfig.API.data.API_GetNetworkReporting,
						next,
						onError:    googletypes.NETWORK_REPORTING_DATA_ERROR,
						onComplete: googletypes.NETWORK_REPORTING_DATA_COMPLETE,
						paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " +  action.payload.startDT  + ", " +  action.payload.endDT  + ", " +  action.payload.network  + ", " +  action.payload.measure + ", " +  action.payload.mode  
					}
				]
			}
			)
			break;

			case googletypes.SHIPMENT_PIE_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentPie,
							next,
							onError:    googletypes.SHIPMENT_PIE_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_PIE_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						}
					]
				}
				)	
				break;

			case googletypes.MILESTONE_ALERT_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetMilestoneAlerts_Omni_All,
							next,
							onError:    googletypes.MILESTONE_ALERT_DATA_ERROR,
							onComplete: googletypes.MILESTONE_ALERT_DATA_COMPLETE,
							paramvalue:   appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)	
				break;

			case googletypes.SHIPMENT_SUBSCRIBE_POST:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostShipmentSubscribe,
							next,
							onError:    googletypes.SHIPMENT_SUBSCRIBE_ERROR,
							onComplete: googletypes.SHIPMENT_SUBSCRIBE_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " +  action.payload.jobid + ", " +  action.payload.active  
						}
					]
				}
				)	
				break;




			case googletypes.SHIPMENT_SEARCH_DATA_GET:
				var param = appSettings.googleDB + "," + "'" + action.payload.email + "', '" + action.payload.housebill + "'";

				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_SEARCH_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_SEARCH_DATA_COMPLETE,
							paramvalue:	param
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetMilestoneAlerts_Omni_All,
							next,
							onError:    googletypes.SHIPMENT_DETAILS_ALERTS_ERROR,
							onComplete: googletypes.SHIPMENT_DETAILS_ALERTS_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', '', '" + action.payload.housebill + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetJobDataByJobNumber,
							next,
							onError:    googletypes.SHIPMENT_DETAILS_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_DETAILS_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', '', '" + action.payload.housebill + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetServiceIssues,
							next,
							onError:    googletypes.SERVICE_ISSUES_ERROR,
							onComplete: googletypes.SERVICE_ISSUES_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + null + ", " + action.payload.housebill
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipCommentsByJobID, //need to search with housebill
							next,
							onError:    googletypes.SHIPMENT_DETAILS_COMMENTS_ERROR,
							onComplete: googletypes.SHIPMENT_DETAILS_COMMENTS_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', '', '" + action.payload.housebill + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetJobsReferenceNumbers, 
							next,
							onError:    googletypes.JOBS_REF_NUMBERS_ERROR,
							onComplete: googletypes.JOBS_REF_NUMBERS_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetJobsLegs, 
							next,
							onError:    googletypes.JOBS_LEGS_ERROR,
							onComplete: googletypes.JOBS_LEGS_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill
						},
					]
				}
				)	
				break;

			case googletypes.OPEN_SERVICE_ISSUES_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetServiceIssues,
							next,
							onError:    googletypes.OPEN_SERVICE_ISSUES_ERROR,
							onComplete: googletypes.OPEN_SERVICE_ISSUES_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.jobid + ", " + action.payload.housebill
						}
					]
				}
				)	
				break;




			case googletypes.POST_SERVICE_ISSUE_FROM_VIEW:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostServiceIssue,
							next,
							onError:    googletypes.POST_SERVICE_ISSUE_ERROR,
							onComplete: googletypes.POST_SERVICE_ISSUE_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.jobid + ", " + action.payload.serviceissueid
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetServiceIssues,
							next,
							onError:    googletypes.OPEN_SERVICE_ISSUES_ERROR,
							onComplete: googletypes.OPEN_SERVICE_ISSUES_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', null, null " 
						}
					]
				}
				)	
				break;			

			case googletypes.POST_SERVICE_ISSUE:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostServiceIssue,
							next,
							onError:    googletypes.POST_SERVICE_ISSUE_ERROR,
							onComplete: googletypes.POST_SERVICE_ISSUE_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.jobid + ", " + action.payload.serviceissueid
						}
					]
				}
				)	
				break;

			case googletypes.SHIPMENT_TRACKER_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT + ", " + action.payload.customerName + ", " + action.payload.customerBusUnit + ", " + action.payload.projectCode + ", " + action.payload.filter + ", " + action.payload.filterValue + ", " + action.payload.view
						}
					]
				}
				)	
				break;

			case googletypes.SHIPMENT_GPS_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentGPS,
							next,
							onError:    googletypes.SHIPMENT_GPS_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_GPS_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.jobid + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane + ", " + action.payload.param + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.jobStatus
						}
					]
				}
				)	
				break;

				
			case googletypes.PIVOT_TABLE_DATA_GET: 
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetPivotTableData,
							next,
							onError:    googletypes.PIVOT_TABLE_DATA_ERROR,
							onComplete: googletypes.PIVOT_TABLE_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.startDT   + ", " + action.payload.endDT   + ", " +  action.payload.dateType  + ", " 
								+  action.payload.mode  + ", " +  action.payload.forwarder  + ", " +  action.payload.service  + ", " +  action.payload.lane  
							
							
						}
					]
				}
				)	
				break;	

			case googletypes.KPI_OVERVIEW_DATA_GET: 
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetKpiOverview,
							next,
							onError:    googletypes.KPI_OVERVIEW_DATA_ERROR,
							onComplete: googletypes.KPI_OVERVIEW_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.forwarder  + ", " + action.payload.mode 
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetKpiLanePerformance,
							next,
							onError:    googletypes.KPI_LANE_DATA_ERROR,
							onComplete: googletypes.KPI_LANE_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.forwarder  + ", " + action.payload.mode 
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetKpiDetails,
							next,
							onError:    googletypes.KPI_DETAIL_DATA_ERROR,
							onComplete: googletypes.KPI_DETAIL_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.forwarder  + ", " + action.payload.mode
						}
					]
				}
				)	
				break;	


			case googletypes.SERVICE_ISSUE_KPI_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetServiceIssues,
							next,
							onError:    googletypes.SERVICE_ISSUE_KPI_DATA_ERROR,
							onComplete: googletypes.SERVICE_ISSUE_KPI_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.jobid + ", " + action.payload.housebill + ", " + action.payload.startDT  + ", " + action.payload.endDT + ", " + action.payload.dateType + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.customerName + ", " + action.payload.customerBusUnit + ", " + action.payload.projectCode + ", " + action.payload.filter + ", " + action.payload.filterValue + ", " + action.payload.view
						}
					]
				}
				)	
				break;




			case googletypes.SHIPMENT_TRACKER_JOBID_DATA_GET:
				var param =	 appSettings.googleDB + "," + "'" + action.payload.email + "', '" + action.payload.housebill + "'";

				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_JOBID_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_JOBID_DATA_COMPLETE,
							paramvalue:	param
						}
					]
				}
				)	
				break;

			//get ship comments
			case googletypes.SHIPMENT_TRACKER_MODAL_SHOW_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipCommentsByJobID,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_MODAL_SHOW_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.jobid
						}
					]
				}
				)	
				break;		

			case googletypes.SHIPMENT_MODAL_COMMENT_POST:
				var param = appSettings.googleDB + "," + "'" + action.payload.jobid + "', '" + action.payload.email + "', '" + action.payload.comment + "'," + action.payload.type  ;
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostShipmentComment,
							next,
							onError:    googletypes.SHIPMENT_MODAL_COMMENT_ERROR,
							onComplete: googletypes.SHIPMENT_MODAL_COMMENT_COMPLETE,
							paramvalue:	param
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						}
					]
				}
				)	
				break;		

			case googletypes.SHIPMENT_MODAL_HOT_SHIP_POST:
				var param = appSettings.googleDB + "," + "'" + action.payload.jobid + "', '" + action.payload.email + "', '" + action.payload.bool + "'" ;
				getData(
					{
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostHotShipment,
							next,
							onError:    googletypes.SHIPMENT_MODAL_HOT_SHIP_ERROR,
							onComplete: googletypes.SHIPMENT_MODAL_HOT_SHIP_COMPLETE,
							paramvalue:	param
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipCommentsByJobID,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_MODAL_SHOW_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', '" + action.payload.jobid + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetStatCard,
							next,
							onError:    googletypes.STAT_CARD_ROW_DATA_ERROR,
							onComplete: googletypes.STAT_CARD_ROW_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						}
					]
				});
				break;	

			case googletypes.SHIPMENT_MODAL_INTERNAL_ACTION_POST:
				var param = appSettings.googleDB + "," + "'" + action.payload.jobid + "', '" + action.payload.email + "', '" + action.payload.bool + "'" ;
				getData(
					{
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostOmniAction,
							next,
							onError:    googletypes.SHIPMENT_MODAL_INTERNAL_ACTION_ERROR,
							onComplete: googletypes.SHIPMENT_MODAL_INTERNAL_ACTION_COMPLETE,
							paramvalue:	param
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipCommentsByJobID,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_MODAL_SHOW_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', '" + action.payload.jobid + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetStatCard,
							next,
							onError:    googletypes.STAT_CARD_ROW_DATA_ERROR,
							onComplete: googletypes.STAT_CARD_ROW_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						}
					]
				});
				break;	

			case googletypes.SHIPMENT_MODAL_EXTERNAL_ACTION_POST:
				var param = appSettings.googleDB + "," + "'" + action.payload.jobid + "', '" + action.payload.email + "', '" + action.payload.bool + "'" ;
				getData(
					{
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_PostGoogleAction,
							next,
							onError:    googletypes.SHIPMENT_MODAL_EXTERNAL_ACTION_ERROR,
							onComplete: googletypes.SHIPMENT_MODAL_EXTERNAL_ACTION_COMPLETE,
							paramvalue:	param
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipCommentsByJobID,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_MODAL_SHOW_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "', '" + action.payload.jobid + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetShipmentTracker,
							next,
							onError:    googletypes.SHIPMENT_TRACKER_DATA_ERROR,
							onComplete: googletypes.SHIPMENT_TRACKER_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						},
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetStatCard,
							next,
							onError:    googletypes.STAT_CARD_ROW_DATA_ERROR,
							onComplete: googletypes.STAT_CARD_ROW_DATA_COMPLETE,
							paramvalue:	appSettings.googleDB + "," + "'" + action.payload.email + "'"
						}
					]
				});
				break;	



			case googletypes.DEMURRAGE_GO_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetChartDemurrage,
							next,
							onError:    googletypes.DEMURRAGE_GO_DATA_ERROR,
							onComplete: googletypes.DEMURRAGE_GO_DATA_COMPLETE,
							paramvalue: appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;


			case googletypes.PENDING_SAILING_GO_DATA_GET:
				getData( {
					type: 'series',
					data: [
						{
							type:       'post',
							endpoint:   appConfig.API.data.API_GetChartPendingSailing,
							next,
							onError:    googletypes.PENDING_SAILING_GO_DATA_ERROR,
							onComplete: googletypes.PENDING_SAILING_GO_DATA_COMPLETE,
							paramvalue: appSettings.googleDB + "," + "'" + action.payload.email + "', " + action.payload.housebill + ", " + action.payload.statCardInput + ", " + action.payload.alertMilestoneInput + ", " + action.payload.startDT + ", " + action.payload.endDT + ", " + action.payload.mode + ", " + action.payload.forwarder + ", " + action.payload.service + ", " + action.payload.lane+ ", " + action.payload.jobStatus+ ", " + action.payload.jobid + ", " + action.payload.startActualDelDT + ", " + action.payload.endActualDelDT
						}
					]
				}
				)
				break;



////////////////////////////////////////////////////////////UPS SERVICES/////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
case upstypes.UPS_LABEL_CREATION_GET:
	getData( {
		type: 'series',
		data: [
			{
				type:       'post',
				endpoint:   appConfig.API.data.API_ups_api_label_creation,
				next,
				onError:    upstypes.UPS_LABEL_CREATION_ERROR,
				onComplete: upstypes.UPS_LABEL_CREATION_COMPLETE,
				paramvalue:	action.payload.param 
			}
		]
	}
	)	
	break;	







		default:
			break;
	}
};

export default dataService;  