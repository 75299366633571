import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import GBFilterMain         from '../../../components/ComponentsGB/GBFilterMain/GBFilterMain';
import {appSettings} from '../../../app/appSettings'
import GBMetricsContainer from '../../../components/ComponentsGB/GBMetrics/GBMetricsContainer'

export default class gbMetricsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,
            languageSelection: 'English'

          };
      }

      componentDidMount() {

        this.getLocalStorageLanguageSelection()

    }

    getLocalStorageLanguageSelection() {
        let languageSelectionValue;
        try{
          languageSelectionValue = localStorage.getItem('PageConfig.UserSettings.Language');
          this.setState({
            languageSelection: languageSelectionValue
          })
        }
        catch(e){
          this.setState({
            languageSelection: "English"
          })
        }
  
      }

        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        UNSAFE_componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>
                
                <GBFilterMain isMobile={isMobile}  languageSelection={this.state.languageSelection}/>

                <AnimatedView>
                <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 

                    <GBMetricsContainer isMobile={isMobile}/>

                </div>


                </AnimatedView>

            </div>

        );
    }
}
