import React      from 'react';
import PropTypes  from 'prop-types';

const StatsCard = ({
  statValue,
  statLabel,
  icon,
  backColor,
  isMobile
}) => (

    <div>
    {(() => {
        switch (isMobile) {
          case true:   return     (
            <div className="sm-st-mobile clearfix" >
                <span className={ `sm-st-icon-mobile st-${backColor}` }>
                { icon }
                </span>
                <div className="sm-st-info-mobile">
                <span>
                    { statValue }
                </span>
                <div>
                    { statLabel }
                </div>
                </div>
            </div>
          );

          case false: return (
            <div className="sm-st clearfix">
                <span className={ `sm-st-icon st-${backColor}` }>
                { icon }
                </span>
                <div className="sm-st-info">
                <span>
                    { statValue }
                </span>
                <div>
                    { statLabel }
                </div>
                </div>
            </div>
          );
          default: return   <div className="sm-st clearfix">
                                <span className={ `sm-st-icon st-${backColor}` }>
                                    { icon }
                                </span>
                                <div className="sm-st-info">
                                    <span>
                                    { statValue }
                                    </span>
                                    <div>
                                    { statLabel }
                                    </div>
                                </div>
                            </div>;
        }
      })()}
    </div>

);

StatsCard.propTypes = {
  statValue:  PropTypes.string,
  statLabel:  PropTypes.string,
  icon:       PropTypes.node,
  backColor:  PropTypes.oneOf([
    'red',
    'blue',
    'violet',
    'green',
    'yellow',
    'grey',
    'darkblue'
  ]),
  isMobile:  PropTypes.bool
};

StatsCard.defaultProps = {
  statValue:  '0',
  statLabel:  '',
  icon:       (<i className="fa fa-check-square-o"></i>),
  backColor:  'blue',
  isMobile:   false
};

export default StatsCard;
