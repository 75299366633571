
import React, {
    PureComponent
}                         from 'react';


import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';


import NewUserSignUpContainer from '../../../components/ComponentsUser/NewUserSignUp/NewUserSignUpContainer';

class userSignUpView extends PureComponent {



    render() {

        return(
            <AnimatedView>

                <NewUserSignUpContainer />

            </AnimatedView>



        );
    }
}

export default userSignUpView;

