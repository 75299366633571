import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import Button from 'react-bootstrap/Button'

const ButtonGeneral = ({
    buttonVariant,
    buttonSize,
    buttonOnClick,
    buttonText,
    isMobile
    }) => (

        <div>
        {(() => {
            switch (isMobile) {
              case false:   return     (
                <div className="sm-st clearfix" style={{height: '100%'}}>
                   
                    <div style={{backgroundColor: '#FFFFFF',  marginLeft: "auto",  marginRight: "auto"}}>
                        <Button variant={buttonVariant} size={buttonSize} block  style={{marginTop: '5px'}} onClick={buttonOnClick}>
                            {buttonText}
                        </Button>           
                    </div>
                    
                </div>
              );

              case true: return (
                <div className="sm-st clearfix" style={{height: '100%'}}>
                  
                    <div style={{backgroundColor: '#FFFFFF',  marginLeft: "auto",  marginRight: "auto"}}>
                        <Button variant={buttonVariant} size={buttonSize} block  style={{marginTop: '5px'}} onClick={buttonOnClick} >
                            {buttonText}
                        </Button>        
                    </div>
                
                </div>
              );
              default: return <div></div>;
            }
          })()}
        </div>

);

ButtonGeneral.propTypes = {
    buttonVariant:                  PropTypes.string, //"success" "warning"
    buttonSize:                     PropTypes.string, //"sm"
    buttonOnClick:                  PropTypes.func,
    buttonText:                     PropTypes.string,
    isMobile:                       PropTypes.bool
};

export default ButtonGeneral;

