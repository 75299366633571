import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
// import { ResponsiveBar } from '@nivo/bar'
import {withRouter} from 'react-router-dom';

import {
  BarChart, Bar, Brush, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import {
  ResponsiveContainer,
} from 'recharts';
import * as moment from 'moment'


  class ShipmentScheduleGraph extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    // onClickAlerts = (data, event) => {


    //   // this.props.getShipmentTrackerData( this.props.currentUserEmail, null, null, "'" + data.label + "'")

    //   // this.props.history.push({
    //   //   pathname: 'home/shipmenttrackerfiltered'
    //   //   }); 

    // }

    onClick = (bar, i) => {


      var start = new Date();
      start.setDate( start.getDate() - 90 );

      // var start = new Date( getDate() - 90 )

      var sDT = new Date(bar.RemainingDays);
      var eDT = new Date(bar.RemainingDays);
      // eDT.setDate( eDT.getDate() + 6 );//use 6 if grouped by week
      eDT.setDate( eDT.getDate() + 1 );//grouped by day


      if (start > sDT){
        sDT = start

      }

      var startDT = moment(sDT).format('YYYY-MM-DD')
      var endDT = moment(eDT).format('YYYY-MM-DD')

      this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, "'" + i + "'", null, "'" + startDT + "'", "'" + endDT + "'", this.props.modeGlobalFilter, this.props.forwarderGlobalFilter, this.props.serviceGlobalFilter, this.props.laneGlobalFilter, null, null, null, null, null, null, null, null, null, null)

      this.props.history.push({
        pathname: 'google/shipmenttrackerfiltered'
        }); 

    }


    


    render() {

      let shipGraph;
      if (this.props.isShipScheduleGraphLoading) {
        shipGraph=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Shipment Schedule (Delivery Date)'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      }
      else if (this.props.shipmentScheduleGraph.length === 0 ) {
        shipGraph =

        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Shipment Schedule (Delivery Date)'}
          </header>
          </section>
          <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
          No data to display
          </div>
        </div>



      }

      else if (typeof(this.props.shipmentScheduleGraph) !== 'undefined' && this.props.shipmentScheduleGraph != null) {
        shipGraph =



        <section className="panel">
        <header className="panel-heading">
          {'Shipment Schedule (Delivery Date)'}
        </header>
          <div style={{height: 400, background: "#FFF"}}>
            <div style={{ width: '100%', height: 400 }}>
              <ResponsiveContainer>
                <BarChart
                // onClick={this.onClick}
                // onClick={(bar, data, event, index, key, payload) => this.onClick(bar, data, event, index, key, payload)}
                // width={500}
                // height={300}
                data={this.props.shipmentScheduleGraph}
                margin={{
                  top: 5, right: 30, left: 20, bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="RemainingDays" />
                <YAxis allowDecimals={false}/>
                <Tooltip />
                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
                <ReferenceLine y={0} stroke="#000" />
                
                <Bar dataKey="Late" stackId="a" fill="#f43e1f" onClick={(bar, i) => this.onClick(bar, 'late')}/>

                <Bar dataKey="On Schedule" stackId="a" fill="#37ba30" onClick={(bar, i) => this.onClick(bar, 'onSchedule')}/>
              </BarChart>
              </ResponsiveContainer>
          </div>
        </div>
      </section>
      }

      // <Bar dataKey="Delivering By Tomorrow" stackId="a" fill="#f1e15b" onClick={(bar, i) => this.onClick(bar, 'atRisk')}/>

      // { <Brush dataKey="RemainingDays" height={30} stroke="#8884d8" /> }
      
      else {
        shipGraph = <div></div>;
      }




      return(
       
         <div>
          {shipGraph}
          </div>
      );
    }
  }
  

  
  // export default ShipmentScheduleGraph;
  export default withRouter(ShipmentScheduleGraph);
  

//   //old nivo bar 



//   <section className="panel">
//   <header className="panel-heading">
//     {'Shipment Schedule'}
//   </header>
//   <div style={{height: 400, background: "#FFF"}}>

//     <ResponsiveBar
//     data={this.props.shipmentScheduleGraph}
//     keys={[
//         "Late", "At Risk", "On Schedule"
//     ]}
//     onClick={(data, event) => this.onClickAlerts(data, event)}
//     indexBy="RemainingDays"
//     margin={{
//         "top": 50,
//         "right": 150,
//         "bottom": 85,
//         "left": 60
//     }}
//     padding={0.3}
//     colors={{
//         "scheme": "nivo"
//     }}
//     defs={[
//         {
//             "id": "red",
//             "type": "patternLines",
//             "background": "inherit",
//             "color": "#f43e1f",
//             "rotation": -45,
//             "lineWidth": 20,
//             "spacing": 10
//         },
//         {
//             "id": "yellow",
//             "type": "patternLines",
//             "background": "inherit",
//             "color": "#f1e15b",
//             "rotation": -45,
//             "lineWidth": 20,
//             "spacing": 10
//         },
//         {
//           "id": "green",
//           "type": "patternLines",
//           "background": "inherit",
//           "color": "#37ba30",
//           "rotation": -45,
//           "lineWidth": 20,
//           "spacing": 10
//       }
//     ]}
//     fill={[
//         {
//             "match": {
//                 "id": "On Schedule"
//             },
//             "id": "green"
//         },
//         {
//             "match": {
//                 "id": "At Risk"
//             },
//             "id": "yellow"
//         },
//         {
//           "match": {
//               "id": "Late"
//           },
//           "id": "red"
//       }
//     ]}
//     borderColor={{
//         "from": "color",
//         "modifiers": [
//             [
//                 "darker",
//                 1.6
//             ]
//         ]
//     }}

//     xScale={{
//       type: 'linear',
//       min: 'auto',
//       max: 'auto',
//     }}
//     axisBottom={{
//         "tickSize": 5,
//         "tickPadding": 5,
//         "tickRotation": -45,
//         "legend": "Week",
//         "legendPosition": "middle",
//         "legendOffset": 70
//     }}
//     axisLeft={{
//         "tickSize": 5,
//         "tickPadding": 5,
//         "tickRotation": 0,
//         "legend": "Count",
//         "legendPosition": "middle",
//         "legendOffset": -40
//     }}
//     labelSkipWidth={12}
//     labelSkipHeight={12}
//     labelTextColor="black"
//     legends={[
//         {
//             "dataFrom": "keys",
//             "anchor": "bottom-right",
//             "direction": "column",
//             "justify": false,
//             "translateX": 120,
//             "translateY": 0,
//             "itemsSpacing": 2,
//             "itemWidth": 100,
//             "itemHeight": 20,
//             "itemDirection": "left-to-right",
//             "itemOpacity": 0.85,
//             "symbolSize": 20,
//             "effects": [
//                 {
//                     "on": "hover",
//                     "style": {
//                         "itemOpacity": 1
//                     }
//                 }
//             ]
//         } 
//     ]}
//     animate={true}
//     motionStiffness={90}
//     motionDamping={15}
// />

// </div>
//         </section>