import {connect} from 'react-redux';
import React, {Component} from 'react';
import SignInUserAuth from './SignInUserAuth'
// import * as actions from '../../actions/actions';



const mapStateToProps = store =>
({
    userSignInData: store.userServicesReducer.userSignInData,
    currentUserEmail: store.userServicesReducer.currentUserEmail

});

const mapDispatchToProps = dispatch =>
({

    // appSignIn:( currentUserEmail ) =>
    // {
    //     dispatch( actions.appSignIn( currentUserEmail ) )
    // }

});


class SignInUserAuthContainer extends Component
{
	constructor(props)
	{
		super(props);
		
	}
	

	
	
    render()
    {

    	return (

            <SignInUserAuth 
            userSignInData={ this.props.userSignInData }
            // appSignIn={ this.props.appSignIn }
            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInUserAuthContainer);




