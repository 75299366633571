import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';


import ReChartBar from '../../ComponentsGeneral/ReCharts/ReChartBar'

const barKeyArray = [
    {'barKey': 'Count', 'fill': '#f43e1f'}
    ]


class ChartAging extends PureComponent {
    constructor( props )
    {
      super( props );
    }


    onClickChart = (bar) => {

      this.props.history.push({
        pathname: 'zulily/shiplist',
        search: 'startdt=' + null + '&' +
        'enddt=' + null + '&' +
        'datetype=' + null + '&' +
        'notdeparted=' + null + '&' +
        'portexit=' + null + '&' +
        'portentry=' + null + '&' +
        'shiptocountry=' + null + '&' +
        'finalcarrier=' + null  + '&' +
        'mawb=' + null  + '&' +
        'mc=' + null  + '&' +
        'hawb=' + null  + '&' +
        'filter=' + 'aging' + '&' +
        'filterValue=' + bar.Days

        }); 
  
    }


    render() {

      let agingChart;
      if (this.props.isAgingChartDataLoading) {
        agingChart=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Aging - Packages w/o Flight Departure'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      }  
      else if (typeof(this.props.AgingChartData) !== 'undefined' && this.props.AgingChartData != null) {
        // if( this.props.AgingChartData.length === 0 ){
        //   agingChart =
        //   <div></div>
        // }
        // else{
          agingChart =

              <section className="panel">
              <header className="panel-heading">
              {'Aging - Packages w/o Flight Departure'}
              </header>

                <ReChartBar 
                    data={this.props.AgingChartData} 
                    dataKey='Days'
                    barKeys= { barKeyArray }
                    xLegend='Days'
                    yLegend='Count'
                    // onBarClick={{ onClick: (bar, i) => this.onClickChart(bar, 'Days') }}

                    // onBarClick={{ onClick: (bar, i) => console.log('click') }}

                    // onBarClick={{onClick: () => console.log('click') }}

                    // onBarClick = {console.log('click')}

                    // onClick={(bar, i) => this.onClickChart(bar, 'On Time')}

                    // onBarClick={(bar, i) => console.log('On Time')} //works

                    onBarClick={(bar, i) => this.onClickChart(bar)}


                    // onClick={{ onClick: (bar, i) => this.onClickChart(bar, 'On Time') }}

                    // onClick={(bar, i) => this.onClickOnTime(bar, 'On Time')}  //from direct chart build
                    // onClick={{ onClick: (line) => this.onClick(line) }}       //from line chart with component


                />

              </section>
        // }

      }

      return(
        <div>
            {agingChart}
        </div>
      );

    }
  }
  

  export default withRouter(ChartAging);
  
