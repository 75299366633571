import React, {
    PureComponent
  }                         from 'react';
  import Loader from 'react-loader-spinner'
  import { ResponsivePie } from '@nivo/pie'
  // import { tooltip } from '@nivo/tooltip'
  import {withRouter} from 'react-router-dom';
  import {appConfig} from '../../../app/appConfig'  
  import {appSettings} from '../../../app/appSettings'

// import {
//   PieChart, Pie, Sector, Cell,
// } from 'recharts';

// import {
//   ResponsiveContainer,
// } from 'recharts';



  class ShipmentPie extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    // onClickAlerts = () => {
    //   this.props.history.push({
    //   pathname: 'home/milestonealerts'
    //   }); 
        
    // }

    onPieClick = (index, data) => {

  }


    onClickAlerts = (data, event) => {

      this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, "'" + data.label + "'", null, null, null, null, null, null, null, null, null, null, null, null, null, null, null, null)

      this.props.history.push({
        pathname: 'google/shipmenttrackerfiltered'
        }); 

    }

    render() {


      if (this.props.isMilestoneAlertLoading) {
        return(
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Milestone Alerts Status'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        )
      }

      else if (this.props.alertPieData.length === 0 ) {
        return(
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Milestone Alerts Status'}
          </header>
          
          <div className="panel-body table-responsive"
            style={{backgroundColor: '#FFF', height: '400px'}}>


              <i className="fa fa-check-square-o" style={{color:`${appConfig.colors.green}`}} title="No milestone alerts"> &nbsp;</i>
              No Milestone Alerts


          </div>
          </section>
        </div>
        )


      }



      return(
        
        <section className="panel">
            <header className="panel-heading">
              {'Milestone Alerts Status'}
            </header>
        <div style={{height: 400, background: "#FFF", cursor: "pointer"}} 
        //onClick={this.onClickAlerts}
        >





        <ResponsivePie
            data={this.props.alertPieData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'nivo' }}
            borderWidth={0}
            // borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            borderColor="black"
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            // radialLabelsLinkColor={{ from: 'color' }}
            radialLabelsLinkColor="black"
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            onClick={(data, event) => this.onClickAlerts(data, event)}
            defs={[
                {
                  id: 'red',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#f43e1f',
                  rotation: -45,
                  lineWidth: 30,
                  spacing: 1
                },
                {
                    id: 'yellow',
                    type: 'patternLines',
                    background: 'inherit',
                    color: '#f1e15b',
                    rotation: -45,
                    lineWidth: 30,
                    spacing: 1
                },
                {
                  id: 'green',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#37ba30',
                  rotation: -45,
                  lineWidth: 30,
                  spacing: 1
              },
              {
                id: 'red-stripe',
                type: 'patternLines',
                background: 'inherit',
                color: '#f43e1f',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
              },
              {
                  id: 'yellow-stripe',
                  type: 'patternLines',
                  background: 'inherit',
                  color: '#f1e15b',
                  rotation: -45,
                  lineWidth: 6,
                  spacing: 10
              },
            ]}
            fill={[
                {
                    match: {
                        id: `${appSettings.customer.Milestone_Red}`
                    },
                    id: 'red',
                    label: 'test'
                },
                {
                    match: {
                        id: `${appSettings.customer.Milestone_Yellow}`
                    },
                    id: 'yellow'
                },
                {
                  match: {
                      id: 'Customer Red'
                  },
                  id: 'red-stripe'
                },
                {
                  match: {
                      id: 'Customer Yellow'
                  },
                  id: 'yellow-stripe'
                }
            ]}
            legends={[
              {
                  anchor: 'bottom',
                  direction: 'row',
                  translateY: 56,
                  itemWidth: 100,
                  itemHeight: 18,
                  itemTextColor: '#999',
                  symbolSize: 18,
                  symbolShape: 'circle',
                  effects: [
                      {
                          on: 'hover',
                          style: {
                              itemTextColor: '#000'
                          }
                      }
                  ]
              }
          ]}
        />


      </div>
              </section>

      );
    }
  }
  

  export default withRouter(ShipmentPie);
  
  

  // //recharts
  // <div style={{ width: '100%', height: 400 }}>
  //     <ResponsiveContainer>
  //     <PieChart width={400} height={400}>
  //       <Pie data={this.props.alertPieData} dataKey="value" cx={200} cy={200} innerRadius={70} outerRadius={90} onClick={this.onPieClick} fill="#82ca9d" label />
  //     </PieChart>



  //     </ResponsiveContainer>
  //     </div>