
/* ################################### GLOBAL FILTER ACTIONS TYPES ################################### */
export const SET_GLOBAL_FILTER = 'SET_GLOBAL_FILTER';

/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';


/* ################################### STAT CARD ROW ACTIONS TYPES ################################### */
export const STAT_CARD_ROW_DATA_GET = 'STAT_CARD_ROW_DATA_GET';
export const STAT_CARD_ROW_DATA_COMPLETE = 'STAT_CARD_ROW_DATA_COMPLETE';
export const STAT_CARD_ROW_DATA_ERROR = 'STAT_CARD_ROW_DATA_ERROR';

export const STAT_CARD_IN_TRANSIT_DATA_GET = 'STAT_CARD_IN_TRANSIT_DATA_GET';
export const STAT_CARD_IN_TRANSIT_DATA_COMPLETE = 'STAT_CARD_IN_TRANSIT_DATA_COMPLETE';
export const STAT_CARD_IN_TRANSIT_DATA_ERROR = 'STAT_CARD_IN_TRANSIT_DATA_ERROR';

export const STAT_CARD_PARAM_DATA_GET = 'STAT_CARD_PARAM_DATA_GET';
export const STAT_CARD_PARAM_DATA_COMPLETE = 'STAT_CARD_PARAM_DATA_COMPLETE';
export const STAT_CARD_PARAM_DATA_ERROR = 'STAT_CARD_PARAM_DATA_ERROR';


/* ################################### SHIPMENT SCHEDULE GRAPH ACTIONS TYPES ################################### */
export const SHIPMENT_SCHEDULE_GRAPH_DATA_GET = 'SHIPMENT_SCHEDULE_GRAPH_DATA_GET';
export const SHIPMENT_SCHEDULE_GRAPH_DATA_COMPLETE = 'SHIPMENT_SCHEDULE_GRAPH_DATA_COMPLETE';
export const SHIPMENT_SCHEDULE_GRAPH_DATA_ERROR = 'SHIPMENT_SCHEDULE_GRAPH_DATA_ERROR';

/* ################################### SHIPMENT HISTORY ACTIONS TYPES ################################### */
export const SHIPMENT_HISTORY_DATA_GET = 'SHIPMENT_HISTORY_DATA_GET';
export const SHIPMENT_HISTORY_DATA_COMPLETE = 'SHIPMENT_HISTORY_DATA_COMPLETE';
export const SHIPMENT_HISTORY_DATA_ERROR = 'SHIPMENT_HISTORY_DATA_ERROR';

/* ################################### SHIPMENT HISTORY BAR ACTIONS TYPES ################################### */
export const SHIPMENT_HISTORY_BAR_DATA_GET = 'SHIPMENT_HISTORY_BAR_DATA_GET';
export const SHIPMENT_HISTORY_BAR_DATA_COMPLETE = 'SHIPMENT_HISTORY_BAR_DATA_COMPLETE';
export const SHIPMENT_HISTORY_BAR_DATA_ERROR = 'SHIPMENT_HISTORY_BAR_DATA_ERROR';



/* ################################### GOOGLE CHARTS ACTIONS TYPES ################################### */
export const DEMURRAGE_GO_DATA_GET = 'DEMURRAGE_GO_DATA_GET';
export const DEMURRAGE_GO_DATA_COMPLETE = 'DEMURRAGE_GO_DATA_COMPLETE';
export const DEMURRAGE_GO_DATA_ERROR = 'DEMURRAGE_GO_DATA_ERROR';

export const PENDING_SAILING_GO_DATA_GET = 'PENDING_SAILING_GO_DATA_GET';
export const PENDING_SAILING_GO_DATA_COMPLETE = 'PENDING_SAILING_GO_DATA_COMPLETE';
export const PENDING_SAILING_GO_DATA_ERROR = 'PENDING_SAILING_GO_DATA_ERROR';



/* ################################### ALERT KPI TREND ACTIONS TYPES ################################### */
export const ALERT_KPI_TREND_DATA_GET = 'ALERT_KPI_TREND_DATA_GET';
export const ALERT_KPI_TREND_DATA_COMPLETE = 'ALERT_KPI_TREND_DATA_COMPLETE';
export const ALERT_KPI_TREND_DATA_ERROR = 'ALERT_KPI_TREND_DATA_ERROR';

/* ################################### NETWORK REPORTING ACTIONS TYPES ################################### */
export const NETWORK_REPORTING_DATA_GET = 'NETWORK_REPORTING_DATA_GET';
export const NETWORK_REPORTING_DATA_COMPLETE = 'NETWORK_REPORTING_DATA_COMPLETE';
export const NETWORK_REPORTING_DATA_ERROR = 'NETWORK_REPORTING_DATA_ERROR';

/* ################################### SHIPMENT PIE ACTIONS TYPES ################################### */
export const SHIPMENT_PIE_DATA_GET = 'SHIPMENT_PIE_DATA_GET';
export const SHIPMENT_PIE_DATA_COMPLETE = 'SHIPMENT_PIE_DATA_COMPLETE';
export const SHIPMENT_PIE_DATA_ERROR = 'SHIPMENT_PIE_DATA_ERROR';

/* ################################### MILESTONE ALERT ACTIONS TYPES ################################### */
export const MILESTONE_ALERT_DATA_GET = 'MILESTONE_ALERT_DATA_GET';
export const MILESTONE_ALERT_DATA_COMPLETE = 'MILESTONE_ALERT_DATA_COMPLETE';
export const MILESTONE_ALERT_DATA_ERROR = 'MILESTONE_ALERT_DATA_ERROR';

/* ################################### SHIPMENT DETAILS ACTIONS TYPES ################################### */
export const SHIPMENT_DETAILS_DATA_GET = 'SHIPMENT_DETAILS_DATA_GET';
export const SHIPMENT_DETAILS_DATA_COMPLETE = 'SHIPMENT_DETAILS_DATA_COMPLETE';
export const SHIPMENT_DETAILS_DATA_ERROR = 'SHIPMENT_DETAILS_DATA_ERROR';

export const SHIPMENT_DETAILS_COMMENTS_GET = 'SHIPMENT_DETAILS_COMMENTS_GET';
export const SHIPMENT_DETAILS_COMMENTS_COMPLETE = 'SHIPMENT_DETAILS_COMMENTS_COMPLETE';
export const SHIPMENT_DETAILS_COMMENTS_ERROR = 'SHIPMENT_DETAILS_COMMENTS_ERROR';

export const SERVICE_ISSUES_GET = 'SERVICE_ISSUES_GET';
export const SERVICE_ISSUES_COMPLETE = 'SERVICE_ISSUES_COMPLETE';
export const SERVICE_ISSUES_ERROR = 'SERVICE_ISSUES_ERROR';

export const SERVICE_ISSUE_KPI_DATA_GET = 'SERVICE_ISSUE_KPI_DATA_GET';
export const SERVICE_ISSUE_KPI_DATA_COMPLETE = 'SERVICE_ISSUE_KPI_DATA_COMPLETE';
export const SERVICE_ISSUE_KPI_DATA_ERROR = 'SERVICE_ISSUE_KPI_DATA_ERROR';

export const OPEN_SERVICE_ISSUES_GET = 'OPEN_SERVICE_ISSUES_GET';
export const OPEN_SERVICE_ISSUES_COMPLETE = 'OPEN_SERVICE_ISSUES_COMPLETE';
export const OPEN_SERVICE_ISSUES_ERROR = 'OPEN_SERVICE_ISSUES_ERROR';

export const POST_SERVICE_ISSUE_FROM_VIEW = 'POST_SERVICE_ISSUE_FROM_VIEW';
export const POST_SERVICE_ISSUE_FROM_VIEW_COMPLETE = 'POST_SERVICE_ISSUE_FROM_VIEW_COMPLETE';
export const POST_SERVICE_ISSUE_FROM_VIEW_ERROR = 'POST_SERVICE_ISSUE_FROM_VIEW_ERROR';

export const POST_SERVICE_ISSUE = 'POST_SERVICE_ISSUE';
export const POST_SERVICE_ISSUE_COMPLETE = 'POST_SERVICE_ISSUE_COMPLETE';
export const POST_SERVICE_ISSUE_ERROR = 'POST_SERVICE_ISSUE_ERROR';

export const SHIPMENT_DETAILS_ALERTS_GET = 'SHIPMENT_DETAILS_ALERTS_GET';
export const SHIPMENT_DETAILS_ALERTS_COMPLETE = 'SHIPMENT_DETAILS_ALERTS_COMPLETE';
export const SHIPMENT_DETAILS_ALERTS_ERROR = 'SHIPMENT_DETAILS_ALERTS_ERROR';

export const JOBS_LEGS_GET = 'JOBS_LEGS_GET';
export const JOBS_LEGS_COMPLETE = 'JOBS_LEGS_COMPLETE';
export const JOBS_LEGS_ERROR = 'JOBS_LEGS_ERROR';

export const JOBS_REF_NUMBERS_GET = 'JOBS_REF_NUMBERS_GET';
export const JOBS_REF_NUMBERS_COMPLETE = 'JOBS_REF_NUMBERS_COMPLETE';
export const JOBS_REF_NUMBERS_ERROR = 'JOBS_REF_NUMBERS_ERROR';

export const SHIPMENT_SUBSCRIBE_POST = 'SHIPMENT_SUBSCRIBE_POST';
export const SHIPMENT_SUBSCRIBE_COMPLETE = 'SHIPMENT_SUBSCRIBE_COMPLETE';
export const SHIPMENT_SUBSCRIBE_ERROR = 'SHIPMENT_SUBSCRIBE_ERROR';

/* ################################### REPORTING ACTIONS TYPES ################################### */
export const PIVOT_TABLE_DATA_GET = 'PIVOT_TABLE_DATA_GET';
export const PIVOT_TABLE_DATA_COMPLETE = 'PIVOT_TABLE_DATA_COMPLETE';
export const PIVOT_TABLE_DATA_ERROR = 'PIVOT_TABLE_DATA_ERROR';

/* ################################### KPI ACTIONS TYPES ################################### */
export const KPI_OVERVIEW_DATA_GET = 'KPI_OVERVIEW_DATA_GET';
export const KPI_OVERVIEW_DATA_COMPLETE = 'KPI_OVERVIEW_DATA_COMPLETE';
export const KPI_OVERVIEW_DATA_ERROR = 'KPI_OVERVIEW_DATA_ERROR';

export const KPI_DETAIL_DATA_COMPLETE = 'KPI_DETAIL_DATA_COMPLETE';
export const KPI_DETAIL_DATA_ERROR = 'KPI_DETAIL_DATA_ERROR';

export const KPI_LANE_DATA_COMPLETE = 'KPI_LANE_DATA_COMPLETE';
export const KPI_LANE_DATA_ERROR = 'KPI_LANE_DATA_ERROR';

/* ################################### SHIPMENT SEARCH ACTIONS TYPES ################################### */
export const SHIPMENT_SEARCH_DATA_GET = 'SHIPMENT_SEARCH_DATA_GET';
export const SHIPMENT_SEARCH_DATA_COMPLETE = 'SHIPMENT_SEARCH_DATA_COMPLETE';
export const SHIPMENT_SEARCH_DATA_ERROR = 'SHIPMENT_SEARCH_DATA_ERROR';

export const SHIPMENT_SEARCH_DETAIL_DATA_COMPLETE = 'SHIPMENT_SEARCH_DETAIL_DATA_COMPLETE';
export const SHIPMENT_SEARCH_DETAIL_DATA_ERROR = 'SHIPMENT_SEARCH_DETAIL_DATA_ERROR';



/* ################################### SHIPMENT TRACKER ACTIONS TYPES ################################### */
export const SHIPMENT_TRACKER_DATA_GET = 'SHIPMENT_TRACKER_DATA_GET';
export const SHIPMENT_TRACKER_DATA_COMPLETE = 'SHIPMENT_TRACKER_DATA_COMPLETE';
export const SHIPMENT_TRACKER_DATA_ERROR = 'SHIPMENT_TRACKER_DATA_ERROR';

export const SHIPMENT_GPS_DATA_GET = 'SHIPMENT_GPS_DATA_GET';
export const SHIPMENT_GPS_DATA_COMPLETE = 'SHIPMENT_GPS_DATA_COMPLETE';
export const SHIPMENT_GPS_DATA_ERROR = 'SHIPMENT_GPS_DATA_ERROR';

export const SHIPMENT_TRACKER_JOBID_DATA_GET = 'SHIPMENT_TRACKER_JOBID_DATA_GET';
export const SHIPMENT_TRACKER_JOBID_DATA_COMPLETE = 'SHIPMENT_TRACKER_JOBID_DATA_COMPLETE';
export const SHIPMENT_TRACKER_JOBID_DATA_ERROR = 'SHIPMENT_TRACKER_JOBID_DATA_ERROR';

//Hide shipment tracker action modal
export const SHIPMENT_TRACKER_MODAL_HIDE = 'SHIPMENT_TRACKER_MODAL_HIDE';
//Show shipment tracker action modal and get associated data
export const SHIPMENT_TRACKER_MODAL_SHOW_GET = 'SHIPMENT_TRACKER_MODAL_SHOW_GET';
export const SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE = 'SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE';
export const SHIPMENT_TRACKER_MODAL_SHOW_ERROR = 'SHIPMENT_TRACKER_MODAL_SHOW_ERROR';
//Post comments in shipment modal
export const SHIPMENT_MODAL_COMMENT_POST = 'SHIPMENT_MODAL_COMMENT_POST';
export const SHIPMENT_MODAL_COMMENT_COMPLETE = 'SHIPMENT_MODAL_COMMENT_COMPLETE';
export const SHIPMENT_MODAL_COMMENT_ERROR = 'SHIPMENT_MODAL_COMMENT_ERROR';
//Toggle Hot shipment attribute in modal
export const SHIPMENT_MODAL_HOT_SHIP_POST = 'SHIPMENT_MODAL_HOT_SHIP_POST';
export const SHIPMENT_MODAL_HOT_SHIP_COMPLETE = 'SHIPMENT_MODAL_HOT_SHIP_COMPLETE';
export const SHIPMENT_MODAL_HOT_SHIP_ERROR = 'SHIPMENT_MODAL_HOT_SHIP_ERROR';
//Toggle Internal action attribute in modal
export const SHIPMENT_MODAL_INTERNAL_ACTION_POST = 'SHIPMENT_MODAL_INTERNAL_ACTION_POST';
export const SHIPMENT_MODAL_INTERNAL_ACTION_COMPLETE = 'SHIPMENT_MODAL_INTERNAL_ACTION_COMPLETE';
export const SHIPMENT_MODAL_INTERNAL_ACTION_ERROR = 'SHIPMENT_MODAL_INTERNAL_ACTION_ERROR';
//Toggle External action attribute in modal
export const SHIPMENT_MODAL_EXTERNAL_ACTION_POST = 'SHIPMENT_MODAL_EXTERNAL_ACTION_POST';
export const SHIPMENT_MODAL_EXTERNAL_ACTION_COMPLETE = 'SHIPMENT_MODAL_EXTERNAL_ACTION_COMPLETE';
export const SHIPMENT_MODAL_EXTERNAL_ACTION_ERROR = 'SHIPMENT_MODAL_EXTERNAL_ACTION_ERROR';