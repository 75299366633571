import {connect} from 'react-redux';
import React, {Component} from 'react';
import UPSLabelCreation from './UPSLabelCreation'
import * as actions from '../../../redux/actions/upsActions';
import {appSettings} from '../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';




const mapStateToProps = store =>
({

    currentUserEmail:                               store.userServicesReducer.currentUserEmail,
    userSignInData:                                 store.userServicesReducer.userSignInData,

    isLabelCreationLoading:                         store.upsDataReducer.isLabelCreationLoading,
    LabelCreationErrorCode:                         store.upsDataReducer.LabelCreationErrorCode,
    LabelCreationText:                              store.upsDataReducer.LabelCreationText
    
    // isLabelCreationLoading: true,
    // LabelCreationErrorCode: false,
    // LabelCreationText: []
    
});

const mapDispatchToProps = dispatch =>
({

    upsLabelCreation:( _param ) =>
    {
        dispatch( actions.upsLabelCreation( _param) )
    }

});


class UPSLabelCreationContainer extends Component
{
	constructor(props)
	{
        super(props);	
    }
    
    componentDidMount() {

    }




    render()
    {

    	return (

            <UPSLabelCreation 
                routeCustomer='evolve'

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isLabelCreationLoading={this.props.isLabelCreationLoading}
                LabelCreationErrorCode={this.props.LabelCreationErrorCode}
                LabelCreationText={this.props.LabelCreationText}

                upsLabelCreation={this.props.upsLabelCreation}
            />


        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UPSLabelCreationContainer));



