import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
import {
  Table,
  TableBody
} from '../../../../components/ComponentsGeneral/table';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import StatsCard from '../../../ComponentsGeneral/StatsCard/StatsCard';

import OmniEventsZU from '../DetailComponents/OmniEventsZU';
import GaugeChart from 'react-gauge-chart'
import { appConfig } from '../../../../app/appConfig';


class DtlHawbDetailsZU extends PureComponent {
  constructor(props) {
    super(props);
  }

  onClickNavMAWB = () => {

    var paramMawb = this.props.MawbDetailData[0].MAWB
    var paramHawb = this.props.MawbDetailData[0].HAWB

    this.props.history.push({
      pathname: 'shipdetails',
      search: 'mawb=\'' + paramMawb + '\'' + '&' +
        'hawb=\'' + paramHawb + '\''
    });

  }


  render() {

    const ibcColumns = [{
      dataField: 'DateTime',
      text: 'Date',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '25%', textAlign: 'center' };
      }
    }, {
      dataField: 'UTC_DT',
      text: 'UTC Date',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '25%', textAlign: 'center' };
      }
    }, {
      dataField: 'UsPortOfEntry',
      text: 'US Port of Entry',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '20%', textAlign: 'center' };
      }
    }, {

      dataField: 'DispCodeDesc',
      text: 'Description',
      sort: false,
      align: 'left',
      headerStyle: (colum, colIndex) => {
        return { width: '55%', textAlign: 'left' };
      }
    }
    ]

    const mobileIbcColumns = [{
      dataField: 'DateTime',
      text: 'Date',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '40%', textAlign: 'center' };
      }
    }, {
      dataField: 'UTC_DT',
      text: 'UTC Date',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '40%', textAlign: 'center' };
      }
    }, {
      dataField: 'DispCodeDesc',
      text: 'Description',
      sort: false,
      align: 'left',
      headerStyle: (colum, colIndex) => {
        return { width: '60%', textAlign: 'left' };
      }
    }
    ]


    const upsMiColumns = [{
      dataField: 'Date',
      text: 'Date',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '25%', textAlign: 'center' };
      }
    }, {
      dataField: 'Location',
      text: 'Location',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '30%', textAlign: 'center' };
      }
    }, {
      dataField: 'Activity',
      text: 'Activity',
      sort: false,
      align: 'left',
      headerStyle: (colum, colIndex) => {
        return { width: '45%', textAlign: 'left' };
      }
    }
    ]

    const mobileUpsMiColumns = [{
      dataField: 'Date',
      text: 'Date',
      sort: false,
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '40%', textAlign: 'center' };
      }
    }, {
      dataField: 'Activity',
      text: 'Activity',
      sort: false,
      align: 'left',
      headerStyle: (colum, colIndex) => {
        return { width: '60%', textAlign: 'left' };
      }
    }
    ]


    const hawbColumns = [
      {
        dataField: 'P_Desc',
        text: 'Product Detail',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '32%', textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'P_ItemUnitValue',
        text: 'Value',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'P_UnitWeight',
        text: 'Unit Weight (kgs)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '18%', textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'P_Quantity',
        text: 'Unit Quantity',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '15%', textAlign: 'center' };
        },
        sort: true
      }
    ]

    const mobileHawbColumns = [
      {
        dataField: 'P_Desc',
        text: 'Product Detail',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '60%', textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'P_UnitWeight',
        text: 'Unit Weight (kgs)',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '40%', textAlign: 'center' };
        },
        sort: true
      }
    ]




    // let NNScore;
    // if (this.props.isUpsNNScoreLoading) {
    //   NNScore=
    //     <Loader 
    //       type="Plane"
    //       color="#00BFFF"
    //       height="50"	
    //       width="50"
    //     /> 
    // }  
    // else if (typeof(this.props.UpsNNScore) !== 'undefined' && this.props.UpsNNScore != null) {

    //   NNScore = 
    //   <section className="panel" style={{backgroundColor: appConfig.colors.darkgrey, textAlign: '-webkit-center'}}>
    //   <header className="panel-heading">
    //     {'At Risk Score'}
    //   </header>

    //       <div className="panel-body table-responsive" style={{backgroundColor: appConfig.colors.darkgrey, overflowX: 'hidden!important', width: '30%'}}>

    //       <GaugeChart id="gauge-chart-NNScore" 
    //       percent={this.props.UpsNNScore} 
    //       />

    //       </div>
    //   </section>

    // }
    // else {
    //   NNScore =
    //   <div></div>
    // }  




    let ibcEvents;
    if (this.props.isIBCDtlDataLoading) {
      ibcEvents =
        <Loader
          type="Plane"
          color="#00BFFF"
          height="50"
          width="50"
        />
    }
    else if (typeof (this.props.IBCDtlData) !== 'undefined' && this.props.IBCDtlData != null) {
      //mobile view
      if (this.props.isMobile) {
        ibcEvents =
          <section className="panel">
            <div className="row" style={{ marginBottom: '0px', overflowX: 'hidden!important', fontSize: '0.5rem' }}>
              <div className="col-md-12">
                <header className="panel-heading">
                  {'Broker Events'}
                </header>
                <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={this.props.IBCDtlData}
                    columns={mobileIbcColumns}
                  >
                    {
                      props => (
                        <div>
                          <BootstrapTable {...props.baseProps} />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </section>
      }
      //desktop view
      else {
        ibcEvents =
          <section className="panel">
            <div className="row" style={{ marginBottom: '0px', overflowX: 'hidden!important' }}>
              <div className="col-md-12">
                <header className="panel-heading">
                  {'Broker Events'}
                </header>
                <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={this.props.IBCDtlData}
                    columns={ibcColumns}
                  >
                    {
                      props => (
                        <div>
                          <BootstrapTable {...props.baseProps} />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </section>
      }
    }
    else {
      ibcEvents =
        <div></div>
    }


    let upsMiEvents;
    if (this.props.isUpsMiDataLoading) {
      upsMiEvents =
        <Loader
          type="Plane"
          color="#00BFFF"
          height="50"
          width="50"
        />
    }
    else if (typeof (this.props.UpsMiData) !== 'undefined' && this.props.UpsMiData != null) {

      upsMiEvents =
        <section className="panel" >
          <header className="panel-heading">
            {'UPS MI Events'}
          </header>
          <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important' }}>

            <ToolkitProvider
              keyField="UniqueID"
              data={this.props.UpsMiData}
              columns={(this.props.isMobile == true) ? mobileUpsMiColumns : upsMiColumns}
            >
              {
                props => (
                  <div>
                    <BootstrapTable {...props.baseProps} />
                  </div>
                )
              }
            </ToolkitProvider>

          </div>
        </section>

    }
    else {
      upsMiEvents =
        <div></div>
    }



    let hawbDiv;
    if (this.props.isHawbDataLoading) {
      hawbDiv =
        <Loader
          type="Plane"
          color="#00BFFF"
          height="50"
          width="50"
        />
    }
    else if (typeof (this.props.HawbDetailData) !== 'undefined' && this.props.HawbDetailData != null) {
      //mobile view
      if (this.props.isMobile) {
        hawbDiv =
          <section className="panel">
            <div className="row" style={{ marginBottom: '0px', overflowX: 'hidden!important', fontSize: '0.5rem' }}>
              <div className="col-md-12">
                <header className="panel-heading">
                  {'Package Content'}
                </header>
                <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={this.props.HawbDetailData}
                    columns={mobileHawbColumns}
                  >
                    {
                      props => (
                        <div>
                          <BootstrapTable {...props.baseProps} />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </section>
      }
      //desktop view
      else {
        hawbDiv =
          <section className="panel">
            <div className="row" style={{ marginBottom: '0px', overflowX: 'hidden!important' }}>
              <div className="col-md-12">
                <header className="panel-heading">
                  {'Package Content'}
                </header>
                <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={this.props.HawbDetailData}
                    columns={hawbColumns}
                  >
                    {
                      props => (
                        <div>
                          <BootstrapTable {...props.baseProps} />
                        </div>
                      )
                    }
                  </ToolkitProvider>
                </div>
              </div>
            </div>
          </section>
      }
    }
    else {
      hawbDiv =
        <div></div>
    }






    let mawbDet;
    let statCards;
    if (this.props.isMawbDataLoading) {
      statCards =
        <div align="center">
          <section className="panel">
          </section>
          <Loader
            type="Plane"
            color="#00BFFF"
            height="50"
            width="50"
          />
        </div>
      mawbDet =
        <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'MAWB Details'}
            </header>
          </section>
          <Loader
            type="Plane"
            color="#00BFFF"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.MawbDetailData) !== 'undefined' && this.props.MawbDetailData != null) {

      statCards =

        <section className="panel">
          <div
            className="row"
            style={{ marginBottom: '15px' }}>

            <div className="col-md-4" >
              <StatsCard
                statValue={this.props.MawbDetailData[0].ManifestToFlightDepartActual}
                statLabel={'Manifest to Depart (Days)'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
              />
            </div>
            <div className="col-md-4" >
              <StatsCard
                statValue={this.props.MawbDetailData[0].ManifestToFlightArrivalActual}
                statLabel={'Manifest to Arrival (Days)'}
                icon={<i className="fa fa-plane" />}
                backColor={'blue'}
                isMobile={this.props.isMobile}
              />
            </div>
            <div className="col-md-4" >
              <StatsCard
                statValue={this.props.MawbDetailData[0].ActualDepartToArrival}
                statLabel={'Depart To Arrival (Days)'}
                icon={<i className="fa fa-plane" />}
                backColor={'green'}
                isMobile={this.props.isMobile}
              />
            </div>
          </div>
        </section>


      mawbDet =
        <section className="panel">

          <div className="row" style={{ marginBottom: '0px', overflowX: 'hidden!important', fontSize: (this.props.isMobile == true) ? '0.5rem' : '1rem' }}>
            <div className="col-md-6">
              <header className="panel-heading">
                {'MAWB Details'}
              </header>

              <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                <Table>
                  <TableBody >
                    <tr key={'1'} >
                      <td style={{ cursor: 'pointer', color: 'blue' }} onClick={this.onClickNavMAWB}>MAWB: <strong>{this.props.MawbDetailData[0].MAWB}</strong></td>
                    </tr>
                    <tr key={'2'} >
                      <td>HAWB: <strong>{this.props.MawbDetailData[0].HAWB}</strong></td>
                    </tr>
                    <tr key={'3'} >
                      <td>Total Containers: <strong>{this.props.MawbDetailData[0].TotContainers}</strong></td>
                    </tr>
                    <tr key={'4'} >
                      <td>Total Packages: <strong>{this.props.MawbDetailData[0].TotPackages}</strong></td>
                    </tr>
                    <tr key={'5'} >
                      <td>Gross Weight (kgs): <strong>{this.props.MawbDetailData[0].GrossWeight}</strong></td>
                    </tr>
                    <tr key={'6'} >
                      <td>Billable Weight (kgs): <strong>{this.props.MawbDetailData[0].BillableWeight}</strong></td>
                    </tr>



                  </TableBody>
                </Table>
              </div>

            </div>


            <div className="col-md-6">
              <header className="panel-heading">
                {'Flight Details'}
              </header>
              <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                <Table>
                  <TableBody >

                    <tr key={'1'} >
                      <td>Airline Carrier: <strong>{this.props.MawbDetailData[0].AirlineCarrier}</strong></td>
                    </tr>
                    <tr key={'2'} >
                      <td>Flight No: <strong>{this.props.MawbDetailData[0].FlightNo}</strong></td>
                    </tr>

                    <tr key={'3'} >
                      <td>Picked Up: <strong>{this.props.MawbDetailData[0].PUP}</strong></td>
                    </tr>

                    <tr key={'4'} >
                      <td>Picked Up UTC: <strong>{this.props.MawbDetailData[0].PUP_UTC}</strong></td>
                    </tr>


                    <tr key={'5'} >
                      <td>Flight Depart: <strong>{this.props.MawbDetailData[0].COB}</strong></td>
                    </tr>

                    <tr key={'6'} >
                      <td>Flight Depart UTC: <strong>{this.props.MawbDetailData[0].COB_UTC}</strong></td>
                    </tr>

                    <tr key={'7'} >
                      <td>Flight Arrival: <strong>{this.props.MawbDetailData[0].AAD}</strong></td>
                    </tr>

                    <tr key={'8'} >
                      <td>Flight Arrival UTC: <strong>{this.props.MawbDetailData[0].AAD_UTC}</strong></td>
                    </tr>

                    <tr key={'9'} >
                      <td>Ship To Airport: <strong>{this.props.MawbDetailData[0].ShipToAirport}</strong></td>
                    </tr>
                    <tr key={'10'} >
                      <td>Ship To Country: <strong>{this.props.MawbDetailData[0].ShipToCountry}</strong></td>
                    </tr>

                  </TableBody>
                </Table>
              </div>

            </div>

          </div>

        </section>
    }





    let hawbMCDiv;
    if (this.props.isHawbMCDataLoading) {
      hawbMCDiv =
        <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Package Details'}
            </header>
          </section>
          <Loader
            type="Plane"
            color="#00BFFF"
            height="50"
            width="50"
          />
        </div>
    }
    else if (typeof (this.props.HawbMCDetailData) !== 'undefined' && this.props.HawbMCDetailData != null) {
      hawbMCDiv =

        <section className="panel">

          <div className="row" style={{ marginBottom: '0px', overflowX: 'hidden!important', fontSize: (this.props.isMobile == true) ? '0.5rem' : '1rem' }}>
            <div className="col-md-12">
              <header className="panel-heading">
                {'Package Details'}
              </header>

              <div className="panel-body table-responsive" style={{ backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important' }}>
                <Table>
                  <TableBody >
                    <tr key={'1'} >
                      <td>Tracking No: <strong>{this.props.HawbMCDetailData[0].HAWB}</strong></td>
                    </tr>
                    <tr key={'2'} >
                      <td>Order No: <strong>{this.props.HawbMCDetailData[0].OrderNo}</strong></td>
                    </tr>
                    <tr key={'4'} >
                      <td>Barcode: <strong>{this.props.HawbMCDetailData[0].Barcode}</strong></td>
                    </tr>
                    <tr key={'5'} >
                      <td>Ship-to State: <strong>{this.props.HawbMCDetailData[0].R_State}</strong></td>
                    </tr>
                    <tr key={'6'} >
                      <td>Ship-to Zip: <strong>{this.props.HawbMCDetailData[0].R_PostCode}</strong></td>
                    </tr>
                    <tr key={'7'} >
                      <td>Parcel Weight (kgs): <strong>{this.props.HawbMCDetailData[0].ParcelWeight}</strong></td>
                    </tr>
                    <tr key={'8'} >
                      <td>Haz Mat: <strong>{this.props.HawbMCDetailData[0].HazMat}</strong></td>
                    </tr>


                  </TableBody>
                </Table>
              </div>

            </div>

          </div>

        </section>
    }







    return (
      <div>
        {/* {NNScore} */}
        {statCards}
        {hawbMCDiv}
        <OmniEventsZU
          isMobile={this.props.isMobile}
          isDataLoading={this.props.isJobEventsDataLoading}
          userGroupName={this.props.userSignInData.GroupName}
          inputData={this.props.JobEventsData}
        />
        {ibcEvents}
        {upsMiEvents}

        {hawbDiv}
        {mawbDet}
      </div>
    );

  }
}


export default withRouter(DtlHawbDetailsZU);

