import React, {
  PureComponent
} from 'react';
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom';
// import {
//     Table,
//     TableBody
//   }                         from '../../../components/ComponentsGeneral/table';
import {//valueDeliveryDayFormatter, 
  buttonFormatter,
  mawbButtonFormatterMobile
  // modalButtonFormatter, 
  // shipmentTagFormatter, 
  // shipmentAlertIconFormatter
  // // hotShipmentIcon
} from '../../../resources/logic/formatting';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
// import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';
import ReactExport from "react-export-excel";
import * as moment from 'moment'
import { Button } from 'rsuite';


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")



class ShipmentListZU extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // Table Check Box
      checkedKeys: [],


    };
    // Table Sort
    this.handleSortColumn = this.handleSortColumn.bind(this);


  }

  componentDidMount() {
    this.loadSortFromStorage()
  }




  // Table Sort
  saveSortTosessionStorage(sortColumn, sortType) {
    try {
      sessionStorage.setItem('sortcolumn', sortColumn)
    }
    catch (e) { }
    try {
      sessionStorage.setItem('sortType', sortType)
    }
    catch (e) { }
    try {
      sessionStorage.setItem('sortURLPathname', this.props.location.pathname)
    }
    catch (e) { }
  }
  loadSortFromStorage() {
    var sortColumn = null
    var sortType = null
    var pathName = null
    try {
      sortColumn = sessionStorage.getItem('sortcolumn')
    }
    catch (e) { }
    try {
      sortType = sessionStorage.getItem('sortType')
    }
    catch (e) { }
    try {
      pathName = sessionStorage.getItem('sortURLPathname')
    }
    catch (e) { }
    if (pathName === this.props.location.pathname) {
      console.log('load sort')
      this.handleSortColumn(sortColumn, sortType)
    }
  }
  handleSortColumn(sortColumn, sortType) {
    this.setState({
      loading: true
    });
    this.saveSortTosessionStorage(sortColumn, sortType)
    setTimeout(() => {
      this.setState({
        sortColumn,
        sortType,
        loading: false
      });
    }, 100);
  }




  render() {

    const { ExportCSVButton } = CSVExport;

    const mawbColumns = [{
      dataField: 'MAWB',
      text: 'MAWB',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '11%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
    }, {
      dataField: 'HAWB',
      text: 'HAWB',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '9%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
      // }, {

      //   dataField: 'AirlineCarrier',
      //   text: 'Carrier',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '8%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'FlightNo',
      text: 'Flight No',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'ShipToAirport',
      text: 'Ship To Airport',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'ShipToCountry',
      text: 'Ship To Country',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'PUP',
      text: 'Picked Up',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'PUP_UTC',
      text: 'Picked Up UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },



    {
      dataField: 'FlightDepartDT',
      text: 'Est. Flight Depart',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'FlightDepartDT_UTC',
      text: 'Est. Flight Depart UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },


    {
      dataField: 'COB',
      text: 'Flight Depart',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'COB_UTC',
      text: 'Flight Depart UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AAD',
      text: 'Flight Arrival',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AAD_UTC',
      text: 'Flight Arrival UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AvgMaxManToDepartActual',
      text: 'Manifest to Depart (Avg/Max)',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AvgMaxManToArrivalActual',
      text: 'Manifest to Arrival (Avg/Max)',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {


      dataField: 'buttonDetails',
      text: '',
      align: 'center',
      formatter: buttonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

          var paramMawb = row.MAWB.trim()
          var paramHawb = row.HAWB.trim()

          this.props.history.push({
            pathname: 'shipdetails',
            search: 'mawb=\'' + paramMawb + '\'' + '&' +
              'hawb=\'' + paramHawb + '\''
          });

          ;
        }
      }
    }
    ]

    const mobileMawbColumns = [
      {

        dataField: 'MAWB',
        text: 'MAWB',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '60%', textAlign: 'center' };
        },
        sort: true,
        style: { overflowWrap: "break-word" },
        formatter: mawbButtonFormatterMobile,
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {

            var paramMawb = row.MAWB.trim()

            this.props.history.push({
              pathname: 'shipdetails',
              search: 'mawb=\'' + paramMawb + '\''
            });


            ;
          }
        }

      },

      //   {
      //   dataField: 'MAWB',
      //   text: 'MAWB',
      //   align: 'center',
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '60%' , textAlign: 'center' };
      //   },
      //   sort: true
      // }, 
      {
        dataField: 'HAWB',
        text: 'HAWB',
        align: 'center',
        style: { overflowWrap: "break-word" },
        headerStyle: (colum, colIndex) => {
          return { width: '40%', textAlign: 'center' };
        },
        sort: true
        // }, {
        //   dataField: 'buttonDetails',
        //   text: '',
        //   align: 'center',
        //   formatter: buttonFormatter,
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '40%', textAlign: 'center' };
        //     },
        //   events: {
        //     onClick: (e, column, columnIndex, row, rowIndex) => {   

        //       var paramMawb =  row.MAWB.trim() 

        //         this.props.history.push({
        //         pathname: 'shipdetails',
        //         search: 'mawb=\'' + paramMawb +'\''
        //         }); 


        //       ; }
        //     }
      }
    ]





    const mcColumns = [{
      dataField: 'SearchInput',
      text: 'MC',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '11%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
    }, {
      dataField: 'HAWB',
      text: 'HAWB',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '9%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
      // }, {

      //   dataField: 'AirlineCarrier',
      //   text: 'Carrier',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '8%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'FlightNo',
      text: 'Flight No',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'ShipToAirport',
      text: 'Ship To Airport',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'PUP',
      text: 'Picked Up',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'PUP_UTC',
      text: 'Picked Up UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'COB',
      text: 'Flight Depart',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'COB_UTC',
      text: 'Flight Depart UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AAD',
      text: 'Flight Arrival',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AAD_UTC',
      text: 'Flight Arrival UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      //   dataField: 'AvgMaxManToDepartActual',
      //   text: 'Manifest to Depart (Avg/Max)',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '15%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentTagFormatter
      // },
      // {
      //   dataField: 'AvgMaxManToArrivalActual',
      //   text: 'Manifest to Arrival (Avg/Max)',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '15%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentTagFormatter
      // },
      // {


      dataField: 'buttonDetails',
      text: '',
      align: 'center',
      formatter: buttonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

          var paramMawb = row.MAWB.trim()
          var paramMC = row.SearchInput.trim()

          this.props.history.push({
            pathname: 'mcdetails',
            search: 'mawb=\'' + paramMawb + '\'' + '&' +
              'mc=\'' + paramMC + '\''
          });

          ;
        }
      }
    }
    ]

    const mobileMcColumns = [{
      dataField: 'SearchInput',
      text: 'MC',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '60%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: 'buttonDetails',
      text: '',
      align: 'center',
      formatter: buttonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '40%', textAlign: 'center' };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

          var paramMawb = row.MAWB.trim()
          var paramMC = row.SearchInput.trim()

          this.props.history.push({
            pathname: 'mcdetails',
            search: 'mawb=\'' + paramMawb + '\'' + '&' +
              'mc=\'' + paramMC + '\''
          });


          ;
        }
      }
    }
    ]



    const hawbColumns = [{
      dataField: 'SearchInput',
      text: 'Tracking No',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '30%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
      // }, {
      //   dataField: 'HAWB',
      //   text: 'HAWB',
      //   align: 'center',
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '9%' , textAlign: 'center' };
      //   },
      //   sort: true
      // }, {

      //   dataField: 'AirlineCarrier',
      //   text: 'Carrier',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '8%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'FlightNo',
      text: 'Flight No',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'ShipToAirport',
      text: 'Ship To Airport',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      // formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'PUP',
      text: 'Picked Up',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'PUP_UTC',
      text: 'Picked Up UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'COB',
      text: 'Flight Depart',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'COB_UTC',
      text: 'Flight Depart UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AAD',
      text: 'Flight Arrival',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      dataField: 'AAD_UTC',
      text: 'Flight Arrival UTC',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%', textAlign: 'center' };
      },
      // formatter: shipmentTagFormatter
    },
    {
      //   dataField: 'AvgMaxManToDepartActual',
      //   text: 'Manifest to Depart (Avg/Max)',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '15%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentTagFormatter
      // },
      // {
      //   dataField: 'AvgMaxManToArrivalActual',
      //   text: 'Manifest to Arrival (Avg/Max)',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '15%' , textAlign: 'center' };
      //   },
      //   // formatter: shipmentTagFormatter
      // },
      // {


      dataField: 'buttonDetails',
      text: '',
      align: 'center',
      formatter: buttonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '10%', textAlign: 'center' };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

          var paramMC = row.ContainerID.trim()
          var paramTrackingNo = row.SearchInput.trim()

          this.props.history.push({
            pathname: 'hawbdetails',
            search: 'mc=\'' + paramMC + '\'' + '&' +
              'trackingno=\'' + paramTrackingNo + '\''
          });

          ;
        }
      }
    }
    ]

    const mobileHawbColumns = [{
      dataField: 'SearchInput',
      text: 'Tracking No',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '60%', textAlign: 'center' };
      },
      sort: true,
      style: { overflowWrap: "break-word" }
    },
    {
      dataField: 'buttonDetails',
      text: '',
      align: 'center',
      formatter: buttonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '40%', textAlign: 'center' };
      },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {

          var paramMC = row.ContainerID.trim()
          var paramTrackingNo = row.SearchInput.trim()

          this.props.history.push({
            pathname: 'hawbdetails',
            search: 'mc=\'' + paramMC + '\'' + '&' +
              'trackingno=\'' + paramTrackingNo + '\''
          });


          ;
        }
      }
    }
    ]



    let bootStrapTable;
    if (this.props.isMawbDataLoading) {
      bootStrapTable =
        <Loader
          type="Plane"
          color="#00BFFF"
          height="50"
          width="50"
        />

    }
    else if (typeof (this.props.MawbDetailData) !== 'undefined' && this.props.MawbDetailData != null) {

      var MAWBdata = this.props.MawbDetailData.filter(x => x.ResultType === 'MAWB' || x.ResultType == 'AFHAWB' || x.ResultType == null)
      var MCdata = this.props.MawbDetailData.filter(x => x.ResultType === 'MC')
      var DetailHAWB = this.props.MawbDetailData.filter(x => x.ResultType === 'DETAILHAWB')

      bootStrapTable =
        <div>
          <ToolkitProvider
            keyField="UniqueID"
            // data={ this.props.MawbDetailData }
            // data={ this.props.MawbDetailData.filter(x => x.ResultType !== 'AFHAWB' && x.ResultType !== 'MC' && x.ResultType !== 'DETAILHAWB' && x.ResultType !== 'NOTFOUND'  ) }
            data={MAWBdata}
            columns={(this.props.isMobile == true) ? mobileMawbColumns : mawbColumns}
            exportCSV
          >
            {
              props => (
                <div style={{ display: (MAWBdata.length > 0) ? '' : 'none' }}>
                  {(this.props.isMobile == true) ? <div></div> :
                    // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                    // <ExcelFile element={<button style={{'margin': '15px'}}>Export MAWB</button>} filename={'mawb_'+ currentDateTime}>
                    <ExcelFile element={<Button style={{ 'margin': '15px' }}>Export Excel</Button>} filename={'mawb_' + currentDateTime}>
                      <ExcelSheet data={MAWBdata} name="mawb">
                        <ExcelColumn label="MAWB" value="MAWB" />
                        <ExcelColumn label="HAWB" value="HAWB" />
                        <ExcelColumn label="Flight No" value="FlightNo" />
                        <ExcelColumn label="Ship To Airport" value="ShipToAirport" />
                        <ExcelColumn label="Ship To Country" value="ShipToCountry" />
                        <ExcelColumn label="Picked Up" value="PUP" />
                        <ExcelColumn label="Picked Up UTC" value="PUP_UTC" />

                        <ExcelColumn label="Est. Flight Depart DT" value="FlightDepartDT" />
                        <ExcelColumn label="Est. Flight Depart DT UTC" value="FlightDepartDT_UTC" />

                        <ExcelColumn label="Flight Depart DT" value="COB" />
                        <ExcelColumn label="Flight Depart DT UTC" value="COB_UTC" />
                        <ExcelColumn label="Flight Arrival" value="AAD" />
                        <ExcelColumn label="Flight Arrival UTC" value="AAD_UTC" />
                        <ExcelColumn label="Manifest to Depart AvgMax" value="AvgMaxManToDepartActual" />
                        <ExcelColumn label="Manifest to Arrival AvgMax" value="AvgMaxManToArrivalActual" />
                        <ExcelColumn label="Mawb Unit Count" value="MawbUnitCount" />

                        <ExcelColumn label="MI Carton" value="TotContainers" />
                        <ExcelColumn label="MI Parcel" value="TotPackages" />
                        <ExcelColumn label="Packages/Carton" value="Packages/Carton" />
                        <ExcelColumn label="G.W." value="GrossWeight" />
                        <ExcelColumn label="MAWB KILOS" value="BillableWeight" />
                        <ExcelColumn label="DIM Weight" value="DIM Weight" />
                        <ExcelColumn label="Booked Date" value="COB" />
                        <ExcelColumn label="Booked Date UTC" value="COB_UTC" />
                        <ExcelColumn label="Arrival Date" value="AAD" />
                        <ExcelColumn label="Arrival Date UTC" value="AAD_UTC" />
                        <ExcelColumn label="Pick-up to Departure" value="Pick-up to Departure" />
                        <ExcelColumn label="Recover Date" value="Recover Date" />
                        <ExcelColumn label="Recover Date UTC" value="Recover Date UTC" />
                        <ExcelColumn label="Handoff Date" value="Handoff Date" />
                        <ExcelColumn label="Handoff Date UTC" value="Handoff Date UTC" />
                        <ExcelColumn label="Recover to Handoff" value="Recover to Handoff" />
                        <ExcelColumn label="Mawb Unit Count" value="MawbUnitCount" />

                      </ExcelSheet>
                    </ExcelFile>



                  }
                  <hr />
                  <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
                </div>
              )
            }
          </ToolkitProvider>

          <ToolkitProvider
            keyField="UniqueID"
            // data={ this.props.MawbDetailData }
            // data={ this.props.MawbDetailData.filter(x => x.ResultType !== 'AFHAWB' && x.ResultType !== 'MC' && x.ResultType !== 'DETAILHAWB' && x.ResultType !== 'NOTFOUND'  ) }
            data={MCdata}
            columns={(this.props.isMobile == true) ? mobileMcColumns : mcColumns}
            exportCSV
          >
            {
              props => (
                <div style={{ display: (MCdata.length > 0) ? '' : 'none' }}>
                  {(this.props.isMobile == true) ? <div></div> : <ExportCSVButton {...props.csvProps} style={{ 'marginTop': '15px', 'marginLeft': '15px' }} >Export CSV</ExportCSVButton>}
                  <hr />
                  <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
                </div>
              )
            }
          </ToolkitProvider>

          <ToolkitProvider
            keyField="UniqueID"
            // data={ this.props.MawbDetailData }
            // data={ this.props.MawbDetailData.filter(x => x.ResultType !== 'AFHAWB' && x.ResultType !== 'MC' && x.ResultType !== 'DETAILHAWB' && x.ResultType !== 'NOTFOUND'  ) }
            data={DetailHAWB}
            columns={(this.props.isMobile == true) ? mobileHawbColumns : hawbColumns}
            exportCSV
          >
            {
              props => (
                <div style={{ display: (DetailHAWB.length > 0) ? '' : 'none' }}>
                  {(this.props.isMobile == true) ? <div></div> : <ExportCSVButton {...props.csvProps} style={{ 'marginTop': '15px', 'marginLeft': '15px' }} >Export CSV</ExportCSVButton>}
                  <hr />
                  <BootstrapTable {...props.baseProps} pagination={paginationFactory()} />
                </div>
              )
            }
          </ToolkitProvider>

        </div>



    }
    else {
      bootStrapTable =
        <div></div>
    }

    return (

      <div>


        <section className="panel">
          <header className="panel-heading">
            {`Shipment List: ${this.props.MawbDetailData.length} rows`}
          </header>
          <div className="panel-body table-responsive"
            style={{ backgroundColor: '#f5f5f5', cursor: 'default', overflowX: 'hidden' }}>

            {bootStrapTable}

          </div>

        </section>

      </div>
    );

  }
}


export default withRouter(ShipmentListZU);

