////npm imports
import React, {
    PureComponent
  }                         from 'react';
import {withRouter} from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import * as moment from 'moment'
import DropDownSelectFilter from '../../ComponentsGeneral/Filters/DropDownSelectFilter/DropDownSelectFilter';
import ButtonGeneral from '../../ComponentsGeneral/Buttons/ButtonGeneral';
import DateFilter   from '../../ComponentsGeneral/Filters/DateFilter/DateFilter';
import queryString from 'query-string';
import Button from 'react-bootstrap/Button'
import Sidebar from "react-sidebar";
import SearchInput from '../../ComponentsGeneral/Filters/SearchInput/SearchInput';
import {languageTranslation} from '../../../app/languageSettings'
import { format } from 'date-fns'

//original
// const calStartValue = new Date();
// calStartValue.setDate( calStartValue.getDate() - 30 );

// const calMinDateValue = new Date();
// calMinDateValue.setDate( calMinDateValue.getDate() - 90 );


var nowDT = moment()
var nowStartDT = moment(nowDT).subtract(30, 'days')
var minStartDT = moment(nowDT).subtract(90, 'days')

var calStartValue = moment(nowStartDT).toDate()
var calMinDateValue = moment(minStartDT).toDate()



class GBFilterMain extends PureComponent {




    constructor( props )
    {
      super( props );

      this.state = {
        sidebarOpen: false,

        selectPortEntry: [{ label: "", value: "" }],
        selectCustCode: [{ label: "", value: "" }],

        selectShipmentType: [{ label: "Air", value: "A" }, { label: "Ocean", value: "O" }, { label: "Courier", value: "C" }],

        // //datepicker state
        // startDate: 'null',
        // endDate: 'null',
        // calendarActive: true,
        // calendarDisabled: true,
        // datePickerRadio: 'null',
        // radioDateType: 'null',

        //calenar
        startDate: calStartValue,
        endDate: null,
        calendarActive: true,
        calendarDisabled: false,
        datePickerRadio: 1,
        radioDateType: 'Manifest',

        //radio filters
        radioNotDeparted: 'null',
        radioInTransit: 'intransit',

        //dropdown state
        dropDownPortExitState: '',
        dropDownPortEntryState: '',
        dropDownCustCodeState: '',
        dropDownShipmentTypeState: '',
        dropDownFinalCarrierState: '',

        //url param filters
        filterStartDT: calStartValue,
        filterEndDT: 'null',
        filterRadioDateType: 'Manifest',
        filterPortExitState: '',
        filterPortEntryState: '',
        filterFinalCarrierState: '',
        filterNotDeparted: 'null',
        filterInTransit: 'intransit',
        filterDatePickerRadio: 'null',
        filterCustCodeState: '',
        filterShipmentTypeState: '',
        //search input box
        searchinput: ''

      };

      //Side Bar Filter menu
      this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);

      //Date picker
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);

      //Date picker type
      this.setEventDate = this.setEventDate.bind(this);

      //Radio button filters
      this.setNotDeparted = this.setNotDeparted.bind(this);
      this.setInTransit = this.setInTransit.bind(this);
      this.setDatePickerRadio = this.setDatePickerRadio.bind(this);
      //Drop down select
      this.handleDropdownPortExit = this.handleDropdownPortExit.bind(this);
      this.handleDropdownPortEntry = this.handleDropdownPortEntry.bind(this);
      this.handleDropdownCustCode = this.handleDropdownCustCode.bind(this);
      this.handleDropdownFinalCarrier = this.handleDropdownFinalCarrier.bind(this);
      this.handleDropdownShipmentType = this.handleDropdownShipmentType.bind(this);

      //Update url filters
      this.handleFilterStartDT = this.handleFilterStartDT.bind(this);
      this.handleFilterEndDT = this.handleFilterEndDT.bind(this);
      this.handleFilterRadioDateType = this.handleFilterRadioDateType.bind(this);
      this.handleFilterPortExit = this.handleFilterPortExit.bind(this);
      this.handleFilterPortEntry = this.handleFilterPortEntry.bind(this);
      this.handleFilterCustCode = this.handleFilterCustCode.bind(this);
      this.handleFilterFinalCarrier = this.handleFilterFinalCarrier.bind(this);
      this.handleFilterRadioNotDeparted = this.handleFilterRadioNotDeparted.bind(this);
      this.handleFilterRadioInTransit = this.handleFilterRadioInTransit.bind(this);
      this.handleFilterRadioDatePicker = this.handleFilterRadioDatePicker.bind(this);
      this.handleFilterShipmentType = this.handleFilterShipmentType.bind(this);
    }

    //Side Bar filter menu
    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
      }

    //Date picker
    handleChangeStartDate(date) {
        this.setState({
            // startDate: moment(date).format('MM-DD-YYYY')
            startDate: date
        });
    }
    handleChangeEndDate(date) {
        this.setState({
        //   endDate: moment(date).format('MM-DD-YYYY')
          endDate: date
        });
    }
    setDatePickerRadio(event) {
        if(event.target.value == 1 ){
            this.setState({
                calendarDisabled: false,
                datePickerRadio: event.target.value,
                startDate: calStartValue,
                endDate: null,
                radioDateType: 'Manifest',
                filterRadioDateType: 'Manifest',
            });
        }
        else {
            this.setState({
                calendarDisabled: true,
                datePickerRadio: event.target.value,
                radioDateType: null,
                filterRadioDateType: null
                // startDate: 'null',
                // endDate: 'null'
            });
        }
        this.setState({
            datePickerRadio: event.target.value
        });
    }

    setEventDate(event) {
        this.setState({ 
          radioDateType: event.target.value
        });
    }  

    //Radio Filters
    setNotDeparted(event) {
        this.setState({ 
          radioNotDeparted: event.target.value
        });
    }  
    setInTransit(event) {
        this.setState({ 
          radioInTransit: event.target.value
        });
    }  
    handleFilterRadioDatePicker(value) {
        this.setState({ 
            filterDatePickerRadio: value
        });
    }

    //Drop down select
    handleDropdownPortExit(value) {
      this.setState({ 
        dropDownPortExitState: value
      });
    }
    handleDropdownPortEntry(value) {
      this.setState({ 
        dropDownPortEntryState: value
      });
    }
    handleDropdownCustCode(value) {
        this.setState({ 
          dropDownCustCodeState: value
        });
      }
    handleDropdownShipmentType(value) {
    this.setState({ 
        dropDownShipmentTypeState: value
    });
    }

    handleDropdownFinalCarrier(value) {
      this.setState({ 
        dropDownFinalCarrierState: value
      });
    }

    //Update url filters
    handleFilterStartDT(value) {
        this.setState({ 
            // filterStartDT: moment(value).format('MM-DD-YYYY')
            filterStartDT: value
        });
    }
    handleFilterEndDT(value) {
        this.setState({ 
            // filterEndDT: moment(value).format('MM-DD-YYYY')
            filterEndDT: value
        });
    }
    handleFilterRadioDateType(value) {
        this.setState({ 
            filterRadioDateType: value
        });
    }
    handleFilterPortExit(value) {
        this.setState({ 
            filterPortExitState: value
        });
    }
    handleFilterPortEntry(value) {
        this.setState({ 
            filterPortEntryState: value
        });
    }
    handleFilterCustCode(value) {
        this.setState({ 
            filterCustCodeState: value
        });
    }
    handleFilterShipmentType(value) {
        this.setState({ 
            filterShipmentTypeState: value
        });
    }
    handleFilterFinalCarrier(value) {
        this.setState({ 
            filterFinalCarrierState: value
        });
    }
    handleFilterRadioNotDeparted(value) {
        this.setState({ 
            filterNotDeparted: value
        });
    }
    handleFilterRadioInTransit(value) {
        this.setState({ 
            filterInTransit: value
        });
    }

    

    componentDidMount() {
        this.updateParamsFromURL()

        var getPortEntry = []
        try{
            getPortEntry = JSON.parse(localStorage.getItem('PageConfig.GBShipToAirport'));
            this.setState({
                selectPortEntry: getPortEntry
              });
            
        }
        catch(e){
            console.log('page config empty')

        }

        var getCustomerCode = []
        try{
            getCustomerCode = JSON.parse(localStorage.getItem('PageConfig.GBCustomerCode'));
            this.setState({
                selectCustCode: getCustomerCode
              });
            
        }
        catch(e){
            console.log('page config empty')

        }
        
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.updateParamsFromURL()
        }
    }      

    updateParamsFromURL = () => {
        var paramStartDT = 'null'
        var paramEndDT = 'null'

        const values = queryString.parse(this.props.location.search)
    
        if( typeof(values.startdt) !== 'undefined' && values.startdt != "null"   ){
            // var dateValueStartUTC = dateValueStart.toUTCString()
            // var dateValueStartUTC = moment(dateValueStart).utc().format();
            // paramStartDT = StartDT.setDate( StartDT.getDate() - 30 );
            // console.log('startdt', values.startdt) //startdt '2020-06-01'
            // paramStartDT = new Date(values.startdt)
            

            var dateValueStart
            try{
                dateValueStart = moment(values.startdt).toDate()
                paramStartDT = dateValueStart
            } 
            catch(e){
                console.log('error dateValueStart')
            }
                
        
            this.handleFilterRadioDatePicker(false)
            this.handleChangeStartDate(paramStartDT)
            this.handleFilterStartDT(paramStartDT)

        }
        else{
            this.handleFilterRadioDatePicker(false)
            this.handleFilterStartDT(calStartValue)
        }
        if( typeof(values.enddt) !== 'undefined' && values.enddt != 'null' ){
            // paramEndDT = EndDT.setDate( EndDT.getDate() );
            // paramEndDT = new Date(values.enddt)
            try{
                var dateValueEnd = moment(values.enddt).toDate()
                paramEndDT = dateValueEnd
            }
            catch(e){
  
            }


            this.handleFilterRadioDatePicker(false)
            this.handleChangeEndDate(paramEndDT)
            this.handleFilterEndDT(paramEndDT)
        }
        else{
            this.handleFilterRadioDatePicker(true)
            this.handleFilterEndDT(paramEndDT)
        }

        if( values.datetype != 'null' ){
            this.handleFilterRadioDateType(values.datetype)
        }
        if( values.notdeparted != 'undefined' ){
            this.handleFilterRadioNotDeparted(values.notdeparted)
        }
        if( values.filter != 'undefined' ){
            this.handleFilterRadioInTransit(values.filter)
        }


        this.handleFilterPortExit(values.portexit)
        this.handleFilterPortEntry(values.portentry)
        this.handleFilterCustCode(values.customercode)
        this.handleFilterShipmentType(values.shipmenttype)
        this.handleFilterFinalCarrier(values.finalcarrier)

    }

  clickApplyFilter( e ) {
    var dateTypeFilter = null;
    var notDepartedFilter = null;
    var portExitFilter = null;
    var portEntryFilter = null;
    var finalCarrierFilter = null;
    var inTransitFilter = null;
    var custCodeFilter = null;
    var shipmentTypeFilter = null;
    
    this.handleFilterStartDT(this.state.startDate)
    this.handleFilterEndDT(this.state.endDate)

    var sDT = null
    var eDT = null

    if (this.state.startDate === null ) {
        sDT = null
    }
    else if(this.state.datePickerRadio != 'null'  ){
        try{
            sDT = "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'"
        }
        catch(e){

        }
    }
    

    if ( this.state.endDate === null ){
        eDT = null
    }
    else if(this.state.datePickerRadio != 'null'  ){
        try{
            eDT = "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'"
        }
        catch(e){

        }
    }


    if (typeof(this.state.radioDateType) !== 'undefined' && this.state.radioDateType != null)
    {
        dateTypeFilter = this.state.radioDateType
        this.handleFilterRadioDateType(dateTypeFilter)
    }
    if (typeof(this.state.radioNotDeparted) !== 'undefined' && this.state.radioNotDeparted != null)
    {
        notDepartedFilter = this.state.radioNotDeparted
        this.handleFilterRadioNotDeparted(notDepartedFilter)
    }
    // if (typeof(this.state.radioInTransit) !== 'undefined' && this.state.radioInTransit != null)
    // {
    //     inTransitFilter = this.state.radioInTransit
    //     this.handleFilterRadioInTransit(inTransitFilter)
    // }
    if (typeof(this.state.dropDownPortExitState[0]) !== 'undefined' && this.state.dropDownPortExitState[0] != null)
    {
        portExitFilter = this.state.dropDownPortExitState[0].value
        this.handleFilterPortExit(portExitFilter)
    }
    if (typeof(this.state.dropDownPortEntryState[0]) !== 'undefined' && this.state.dropDownPortEntryState[0] != null)
    {
        portEntryFilter = this.state.dropDownPortEntryState[0].value
        this.handleFilterPortEntry(portEntryFilter)
    }
    if (typeof(this.state.dropDownCustCodeState[0]) !== 'undefined' && this.state.dropDownCustCodeState[0] != null)
    {
        custCodeFilter = this.state.dropDownCustCodeState[0].value
        this.handleFilterCustCode(custCodeFilter)
    }


    if (typeof(this.state.dropDownShipmentTypeState[0]) !== 'undefined' && this.state.dropDownShipmentTypeState[0] != null)
    {
        shipmentTypeFilter = this.state.dropDownShipmentTypeState[0].value
        this.handleFilterShipmentType(shipmentTypeFilter)
    }
   



    if (typeof(this.state.dropDownFinalCarrierState[0]) !== 'undefined' && this.state.dropDownFinalCarrierState[0] != null)
    {
        finalCarrierFilter = this.state.dropDownFinalCarrierState[0].value
        this.handleFilterFinalCarrier(finalCarrierFilter)
    }


    this.props.history.push({
      search: 'startdt=' + sDT + '&' +
      'enddt=' + eDT + '&' +
      'datetype=' + dateTypeFilter + '&' +
      'notdeparted=' + notDepartedFilter + '&' +
      'portexit=' + portExitFilter + '&' +
      'portentry=' + portEntryFilter + '&' +
      'finalcarrier=' + finalCarrierFilter  + '&' +
    //   'filter=' + inTransitFilter + '&' +
      'customercode=' + custCodeFilter   + '&' +
      'shipmenttype=' + shipmentTypeFilter 

      }); 

    this.onSetSidebarOpen(false)
    this.updateParamsFromURL()
  }





  //this handles search text input box update state
  onSearchChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });

  }

  //prevent search form input from submitting on enter key
  onSearchKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }

  //this handles search text input box submit
  onSearchSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { searchinput } = this.state;

    if(searchinput.trim() === "") {
      window.alert("Search Field is empty.");
      return
    }

    else {

        this.onSetSidebarOpen(false)


        var searchString = searchinput.trim().replace(/-/g,'')
        

      if(this.props.location.pathname === '/home/gaobo') {
        // this.props.openShipDetails(paramjobid, row);
        this.props.history.push({
        pathname: 'gaobo/shiplist',
        search: 'startdt=null' +  '&' +
        'enddt=null'  + '&' +
        'datetype=null'  + '&' +
        'notdeparted=null'  + '&' +
        'portexit=null'  + '&' +
        'portentry=null'  + '&' +
        'customercode=null'  + '&' +
        'finalcarrier=null'   + '&' +
        'mawb=\'' + searchString +'\'' + '&' +
        'filter=search'
        }); 
      }
      else if(this.props.location.pathname === '/home/gaobo/shiplist/') {
        this.props.history.push({
          search: 'startdt=null' +  '&' +
          'enddt=null'  + '&' +
          'datetype=null'  + '&' +
          'notdeparted=null'  + '&' +
          'portexit=null'  + '&' +
          'portentry=null'  + '&' +
          'customercode=null'  + '&' +
          'finalcarrier=null'   + '&' +
          'mawb=\'' + searchString +'\'' + '&' +
          'filter=search' 
          }); 

        

        window.location.reload();
      }

      else {
        this.props.history.push({
        pathname: 'shiplist',
        search: 'startdt=null' +  '&' +
        'enddt=null'  + '&' +
        'datetype=null'  + '&' +
        'notdeparted=null'  + '&' +
        'portexit=null'  + '&' +
        'portentry=null'  + '&' +
        'customercode=null'  + '&' +
        'finalcarrier=null'   + '&' +
        'mawb=\'' + searchString +'\'' + '&' +
        'filter=search' 
        }); 
      }


    

    }
  }


    render() {
        const {
            searchinput
          } = this.state;

            const languageSelection = this.props.languageSelection
            var clickToFilterLanguage =  languageTranslation( 'ClicktoFilterSearch', languageSelection )
            var StartDateLanguage =  languageTranslation( 'StartDate', languageSelection )
            var EndDateLanguage =  languageTranslation( 'EndDate', languageSelection )
            var StatusLanguage =  languageTranslation( 'Status', languageSelection )
            var PortofEntryLanguage =  languageTranslation( 'PortofEntry', languageSelection )
            var DateRangeTypeLanguage =  languageTranslation( 'DateRangeType', languageSelection )
            var CustomerLanguage =  languageTranslation( 'Customer', languageSelection )

            var AllLanguage =  languageTranslation( 'All', languageSelection )
            var DateRangeLanguage =  languageTranslation( 'DateRange', languageSelection )
            var CustomerCodeLanguage =  languageTranslation( 'CustomerCode', languageSelection )
            var NotDepartedLanguage =  languageTranslation( 'NotDeparted', languageSelection )
            var InTransitLanguage =  languageTranslation( 'InTransit', languageSelection )
            var HandedoffLanguage =  languageTranslation( 'Handedoff', languageSelection )
            var ApplyLanguage =  languageTranslation( 'Apply', languageSelection )
            var SearchMAWBHAWBMCTrackingNumberLanguage =  languageTranslation( 'SearchMAWBHAWBMCTrackingNumber', languageSelection )


          var displayStartDT = ''
          try{
            displayStartDT = moment(this.state.filterStartDT).format('MM-DD-YYYY')
          }
          catch(e){

          }
          
          var displayEndDT = ''
          try{
            displayEndDT = moment(this.state.filterEndDT).format('MM-DD-YYYY')
          }
          catch(e){

          }


        let datePickerRadio;
        datePickerRadio=
        <div className="sm-st clearfix"  >
            <div className="row">  
                <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    <div onChange={this.setDatePickerRadio.bind(this)}>
                        {/* <input type="radio" value="null" name="datepicker" defaultChecked /> All &nbsp; */}
                        <input type="radio" value="1" name="datepicker"/> {DateRangeLanguage} &nbsp;
                    </div> 
                </div>
            </div>
        </div>


let datePickerDiv;
datePickerDiv=

    <div>
    {(() => {
        switch (this.state.calendarDisabled) {
        case false:   return     (
            <div className="sm-st clearfix" >

            {/* <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                {datePickerRadio}
            </div> */}

            <div className="row">  
            <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>  
                <header >
                    {StartDateLanguage}
                </header>
                <DateFilter
                    // labelText = { 'Start:' }
                    placeholderText = { StartDateLanguage }
                    selectedDate = { this.state.startDate }
                    selectsStartOrEnd = { 'selectsStart' }
                    startDate = { this.state.startDate }
                    endDate = { this.state.endDate }
                    onChange = { this.handleChangeStartDate }
                    minDate = { calMinDateValue }
                    disabled = {this.state.calendarDisabled}
                    isMobile = { this.props.isMobile }
                    isClearable = { true }
                />
                        </div>
                        <div className="col-md-6" style={{ margin: '3px 0 3px 0' }}>  
                            <header >
                                {EndDateLanguage}
                            </header>
                <DateFilter
                    // labelText = { 'End:' }
                    placeholderText = { EndDateLanguage }
                    selectedDate = { this.state.endDate }
                    selectsStartOrEnd = { 'selectsEnd' }
                    startDate = { this.state.startDate }
                    endDate = { this.state.endDate }
                    onChange = { this.handleChangeEndDate }
                    minDate = { calMinDateValue }
                    disabled = {this.state.calendarDisabled}
                    isMobile = { this.props.isMobile }
                    isClearable = { true }
                />  

        </div>
        </div>
        <div onChange={this.setEventDate.bind(this)}>
                            <input type="radio" value="Manifest" name="eventdate" defaultChecked /> Inbound Manifest &nbsp;
                            <input type="radio" value="Departure" name="eventdate"/> Departure &nbsp;
                            <input type="radio" value="Arrival" name="eventdate" /> Arrival &nbsp;
            </div> 
    </div>
      );

      case true: return (
        <div className="sm-st clearfix" >
        {/* <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
            {datePickerRadio}
        </div> */}
    </div>
      );
      default: return <div></div>;
    }
  })()}
</div>













        let radioNotDepartedDiv;
        radioNotDepartedDiv=
            <div className="sm-st clearfix" >
                <div className="row">  
                    <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                        <div onChange={this.setNotDeparted.bind(this)}>
                            <div className="row" style={{alignItems: "baseline" }}>
                                <input type="radio" value="null" name="status" defaultChecked /> &nbsp; {AllLanguage} 
                            </div>
                            <div className="row" style={{alignItems: "baseline" }}>
                                <input type="radio" value="1" name="status"/>  &nbsp; {NotDepartedLanguage}
                            </div>
                            <div className="row" style={{alignItems: "baseline" }}>
                                <input type="radio" value="2" name="status"/>  &nbsp; {InTransitLanguage}
                            </div>
                            <div className="row" style={{alignItems: "baseline" }}>                                
                                <input type="radio" value="3" name="status"/>  &nbsp; Delivered
                            </div>
                        </div> 
                    </div>
                </div>
            </div>


        // let radioInTransitDiv;
        // radioInTransitDiv=
        //     <div className="sm-st clearfix" >
        //         <div className="row">  
        //             <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
        //                 <div onChange={this.setInTransit.bind(this)}>
        //                     <input type="radio" value="null" name="intransit" /> All &nbsp;
        //                     <input type="radio" value="intransit" name="intransit" defaultChecked /> In Transit &nbsp;
        //                     <input type="radio" value="delivered" name="intransit"/> Delivered &nbsp;
        //                 </div> 
        //             </div>
        //         </div>
        //     </div>




        let dropDownPortExit;
        dropDownPortExit=
        <DropDownSelectFilter 
            dropDownArray = { this.state.selectPortEntry } 
            dropDownOnChange = {this.handleDropdownPortExit}
            dropDownPlaceholder = { 'Port of Exit' }
            isMobile = { this.props.isMobile }
        />

        let dropDownPortEntry;
        dropDownPortEntry=
        <DropDownSelectFilter 
            dropDownArray = { this.state.selectPortEntry } 
            dropDownOnChange = {this.handleDropdownPortEntry}
            dropDownPlaceholder = { PortofEntryLanguage }
            isMobile = { this.props.isMobile }
        />

        let dropDownShipmentType;
        dropDownShipmentType=
        <DropDownSelectFilter 
            dropDownArray = { this.state.selectShipmentType } 
            dropDownOnChange = {this.handleDropdownShipmentType}
            dropDownPlaceholder = { 'Shipment Type' }
            isMobile = { this.props.isMobile }
        />

        let dropCustCode;
        dropCustCode=
        <DropDownSelectFilter 
            dropDownArray = { this.state.selectCustCode } 
            dropDownOnChange = {this.handleDropdownCustCode}
            dropDownPlaceholder = { CustomerCodeLanguage }
            isMobile = { this.props.isMobile }
        />

        let dropDownFinalMile;
        dropDownFinalMile=
        <DropDownSelectFilter 
            dropDownArray = { this.state.selectPortEntry } 
            dropDownOnChange = {this.handleDropdownFinalCarrier}
            dropDownPlaceholder = { 'Final Mile Carrier' }
            isMobile = { this.props.isMobile }
        />

        let buttonApplyFilter;
        buttonApplyFilter=
    //     <Button variant="secondary" size="md" block   onClick={() => this.onSetSidebarOpen(true)} >
    //     Click to Filter
    // </Button>
        <ButtonGeneral 
            buttonVariant = { "success" }
            buttonSize = { "sm" }
            buttonOnClick={(e) => this.clickApplyFilter(e)}
            buttonText = { ApplyLanguage }
            isMobile = { this.props.isMobile }
        />

        let searchInputDiv;
        searchInputDiv=
        <div className="sm-st clearfix" style={{height: '100px'}}>

                <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    <SearchInput
                        searchInputValue = { searchinput }
                        searchTitle = { SearchMAWBHAWBMCTrackingNumberLanguage }
                        searchOnChange = { this.onSearchChange }
                        searchOnSubmit = { this.onSearchSubmit }
                        searchOnKeyPress = { this.onSearchKeyPress }
                        isMobile = { this.props.isMobile }
                    />
                </div>

        </div>





        let currentFilter;
        if(this.props.isMobile){
            currentFilter = 
            <div className="row" style={{marginBottom: '0px', fontSize: '0.4rem'}}>
                <div className="col-md-12">
                    <section className="panel">
                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden'}}>              
                            <div className="col-md-12">   
                                {/* <td>Start Date: <strong>{ (this.state.filterStartDT == null || this.state.filterStartDT === 'null' ) ? AllLanguage : displayStartDT }</strong></td>         */}
                                <td>{StartDateLanguage}: <strong>{ displayStartDT }</strong></td>   
                            </div>     
                            <div className="col-md-12">              
                                <td>{EndDateLanguage}: <strong>{ (this.state.filterEndDT == null || this.state.filterEndDT === 'null' ) ? AllLanguage : displayEndDT }</strong></td>        
                            </div>   
                            <div className="col-md-12">                                       
                                <td>{DateRangeTypeLanguage}: <strong>{ (this.state.filterRadioDateType == null || this.state.filterRadioDateType === 'null' ) ? AllLanguage : this.state.filterRadioDateType }</strong></td>       
                            </div>     
                            <div className="col-md-12">      
                                <td>{StatusLanguage}: <strong>{ (this.state.filterNotDeparted == null || this.state.filterNotDeparted === 'null' ) ? AllLanguage : (this.state.filterNotDeparted === 1) ? NotDepartedLanguage : (this.state.filterNotDeparted === 2) ? InTransitLanguage : (this.state.filterNotDeparted === 3) ? 'Delivered' : ''}</strong></td>       
                            </div>    
                            <div className="col-md-12">      
                                <td>{PortofEntryLanguage}: <strong>{ this.state.filterPortEntryState === 'null' ? AllLanguage : this.state.filterPortEntryState }</strong></td>       
                            </div>     
                            <div className="col-md-12">      
                                <td>{CustomerLanguage}: <strong>{ this.state.filterCustCodeState === 'null' ? AllLanguage : this.state.filterCustCodeState }</strong></td>       
                            </div>    
                            <div className="col-md-12">      
                                <td>{'Shipment Type'}: <strong>{ this.state.filterShipmentTypeState === 'null' ? AllLanguage : ( this.state.filterShipmentTypeState === 'A') ? 'Air' : (this.state.filterShipmentTypeState === 'O') ? 'Ocean' : 'Courier' }</strong></td>       
                            </div>  





                    </div>
                    </section>
                </div>
            </div>
        }
        else{
            currentFilter = 
            
            <div className="row" style={{marginBottom: '0px', fontSize: '0.9rem'}}>
                <div className="col-md-12">
                    <section className="panel">
                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden'}}>
                        <div className="row" >
                            <div className="col-md-6">   
                                {/* <td>Start Date: <strong>{ (this.state.filterStartDT == null || this.state.filterStartDT === 'null' ) ? AllLanguage : displayStartDT }</strong></td>         */}
                                <td>{StartDateLanguage}: <strong>{ displayStartDT }</strong></td> 
                            </div>    
                            <div className="col-md-6">      
                                <td>{StatusLanguage}: <strong>{ (this.state.filterNotDeparted == null || this.state.filterNotDeparted === 'null' ) ? AllLanguage : (this.state.filterNotDeparted == 1) ? 'Not Departed' : (this.state.filterNotDeparted == 2) ? InTransitLanguage : (this.state.filterNotDeparted == 3) ? 'Delivered' : AllLanguage}</strong></td>       
                            </div>    

 
     
                        </div>
                        <div className="row" >
                            <div className="col-md-6">              
                                <td>{EndDateLanguage}: <strong>{ (this.state.filterEndDT == null || this.state.filterEndDT === 'null' ) ? AllLanguage : displayEndDT }</strong></td>        
                            </div>  
                            <div className="col-md-6">      
                                <td>{PortofEntryLanguage}: <strong>{ this.state.filterPortEntryState === 'null' ? AllLanguage : this.state.filterPortEntryState }</strong></td>       
                            </div> 




                        </div>
                        <div className="row" >
                            <div className="col-md-6">                                       
                                <td>{DateRangeTypeLanguage}: <strong>{ (this.state.filterRadioDateType == null || this.state.filterRadioDateType === 'null' ) ? AllLanguage : this.state.filterRadioDateType }</strong></td>       
                            </div>  
                            <div className="col-md-6">      
                                <td>{CustomerLanguage}: <strong>{ this.state.filterCustCodeState === 'null' ? AllLanguage : this.state.filterCustCodeState }</strong></td>       
                            </div>  
                        </div>

                
                        <div className="row" >
                            <div className="col-md-6">                           
                            <td>{'Shipment Type'}: <strong>{ this.state.filterShipmentTypeState === 'null' ? AllLanguage : ( this.state.filterShipmentTypeState === 'A') ? 'Air' : (this.state.filterShipmentTypeState === 'O') ? 'Ocean' : 'Courier' }</strong></td>                  

                            </div>  

                        </div>



                    </div>
                    </section>
                </div>
            </div>
        }




        let filterDiv;
        if(this.props.isMobile){
            filterDiv = 
            <div className="mobile-text-size" style={{padding: '0px' , zIndex: '2000!important'}}>  
            <div className="col-md-12"  style={{padding: '1px'}}>
                {datePickerDiv}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {dropDownPortEntry}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {dropCustCode}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {dropDownShipmentType}
            </div>
            <div className="col-md-12"  style={{padding: '1px'}}>
                {radioNotDepartedDiv}
            </div>

            <div className="col-md-12"  style={{padding: '1px'}}>
                {buttonApplyFilter}
            </div>

            <div className="col-md-12"  style={{ paddingTop: '5px'}}>
                {searchInputDiv}
            </div>

        </div>
        }
        else{
            filterDiv = 
                <div style={{padding: '15px' , zIndex: '2000!important'}}>  
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {datePickerDiv}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {dropDownPortEntry}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {dropCustCode}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {dropDownShipmentType}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {radioNotDepartedDiv}
                    </div>
                    <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {buttonApplyFilter}
                    </div>

                    <div className="col-md-12"  style={{ padding: '15px', paddingTop: '40px'}}>
                        {searchInputDiv}
                    </div>
                    
                </div>

        }


        return(

            <div style={{paddingTop: "22px", paddingRight: "15px", paddingLeft: "15px" }}>
            <section className="panel">
                <div //className="panel-body table-responsive"
                    style={{backgroundColor: '#dcdcdc'}}
                >

                        <Sidebar
                            sidebar={ filterDiv }
                            open={this.state.sidebarOpen}
                            onSetOpen={this.onSetSidebarOpen}
                            styles={{ sidebar: { background: '#dcdcdc', padding: '45px' } }}
                            >


                            <section className="panel" style={{ paddingRight: "17px", paddingLeft: "13px" }}>
                                <div style={{marginLeft: "auto",  marginRight: "auto", zIndex: "1", position: "relative"}}>
                                <Button variant="secondary" size="md" block   onClick={() => this.onSetSidebarOpen(true)} >
                                    {clickToFilterLanguage}
                                </Button>
                                </div>
                            </section>

                        </Sidebar>

                </div>
            </section>
                {currentFilter}
            </div>


        );
    }
}

  export default withRouter(GBFilterMain);
  
