import React, { Component } from "react";

class Sharedtextarea extends Component {
  constructor() {
    super();
    this.state = {
      name: "React"
    };
  }


  render() {
    return (
      <div >
        <textarea name="body"
            onChange={this.props.handleChange}
            defaultValue={this.props.defaultValue} 
            style={{width: '-webkit-fill-available', height: '500px' }}/>
      </div>
    );
  }
}


// '-webkit-fill-available'


//   render() {
//     return (
//       <div>
//         <label>Enter value : </label>
//         <input type="textare" 
//           name="textValue"
//           onChange={this.props.handleChange}
//         />
//       </div>
//     );
//   }
// }

export default Sharedtextarea;





// var TextInput = React.createClass({
//     getInitialState: function(){
//         return {currentValue: this.props.children}
//     },
//     handleChange: function(event){
//       //handler
//     },
//     render: function(){
//         return (

//         )
//     }
// });