//TODO update this form

////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';

////local imports
import {
  Table,
  // TableBody
}                         from '../../ComponentsGeneral/table'
// import { awsCognitoConfig } from '../../../app/appConfig'
// import deliveryDayFormatter from '../../../resources/logic/formatting' ;
import {//valueDeliveryDayFormatter, 
    buttonApproveAccount, 
    buttonRejectAccount, 
  // hotShipmentIcon
} from '../../../resources/logic/formatting';



// import { Auth } from 'aws-amplify-react';

// import Amplify from "aws-amplify";

// import Amplify from "@aws-amplify/core";
// import Auth from '@aws-amplify/auth';

// import { Auth } from '@aws-amplify/core';

  class PendingUsers extends PureComponent {

    constructor( props )
    {
      super( props );
    }
    // componentWillMount() {
    //     this.props.init()
    //   }


    // import { Auth } from 'aws-amplify';





    // signUp = async ( email ) => {
    //     try {
    //       const success = await Auth.signUp( email.toLowerCase(), 'tempPassword' )

    //     //   this.setState({ showConfirmationForm: true })
    //     } catch (err) {

    //     }
    //   }

    render() {
      const pendingAccountColumns = [{
        dataField: 'FirstName',
        text: 'First Name',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'LastName',
        text: 'Last Name',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'EmailAddr',
        text: 'Email',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'AuditCreatedDT',
        text: 'Account Request Date',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      },
      {
        dataField: 'buttonApprove',
        text: '',
        align: 'center',
        formatter: buttonApproveAccount,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   
            // this.props.postUserUpdate(row.EmailAddr, row.FirstName, row.LastName, 1, 0, 0 ); //uncomment once AWS signup works
            // this.props.signUp( row.EmailAddr.toLowerCase(), 'tempPassword' )
            // this.signUp(row.EmailAddr)
            // this.props.signUp( row.EmailAddr.toLowerCase(), 'tempPassword' )

            // var email = row.EmailAddr.toLowerCase()
            // var passw = 'tempPassword1'


            this.props.postUserUpdate(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 1, 0, 0 )
            
            // Amplify.configure({
            //   Auth: {
            //     mandatorySignIn: true,
            //     region: awsCogConfig.cognitoLogin.Auth.region,
            //     userPoolId: awsCogConfig.cognitoLogin.Auth.UserPoolId,
            //     identityPoolId: awsCogConfig.cognitoLogin.Auth.IdentityPoolId,
            //     userPoolWebClientId: awsCogConfig.cognitoLogin.Auth.ClientId
            //   }});

            //   Auth.signUp({
            //     username: email,
            //     password: passw,
            //     attributes: {
            //       email: email
            //     }
            //   })
            //   .then(

            //   this.props.postUserUpdate(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 1, 0, 0 ))
            //   .catch(err => console.log(err));




              



              
        }
          }
      },{
        dataField: 'buttonReject',
        text: '',
        align: 'center',
        formatter: buttonRejectAccount,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            this.props.postUserUpdate(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 );
          }
      }
      }
      ]


      let bootStrapTable;
      if(this.props.userSignInData.Admin === false) {
        bootStrapTable=
        <div align="center">
        <section className="panel">
        <header className="panel-heading">
              {'You do not have Admin Rights to View'}
            </header>
          </section>

        </div>
      } 

      else if (this.props.isPendingUserRequestsLoading) {
        bootStrapTable=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.pendingUserRequestsData) !== 'undefined' && this.props.pendingUserRequestsData != null) {
        bootStrapTable =
        <Table>
        <BootstrapTable 
        keyField='EmailAddr' 
        data={ this.props.pendingUserRequestsData } 
        columns={ pendingAccountColumns } 
        // expandRow={ expandRow }
        // rowEvents={ rowEvents }
        />
      </Table>
      } else {
        bootStrapTable = <div></div>;
      }

 

      return(
          <section className="panel">
            <header className="panel-heading">
              {'Pending User Accounts'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>

              { bootStrapTable }

            </div>

          </section>

      );
    }
  }

  // export default StatusTableData;
  export default withRouter(PendingUsers);
  