import {connect} from 'react-redux';
import React, {Component} from 'react';
import ShipmentScheduleGraph from './ShipmentScheduleGraph'
import * as actions from '../../../redux/actions/googleActions';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

const mapStateToProps = store =>
({
    shipmentScheduleGraph:              store.shipmentScheduleGraphReducer.shipmentScheduleGraph,
    isShipScheduleGraphLoading:         store.shipmentScheduleGraphReducer.isShipScheduleGraphLoading,
    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                 store.userServicesReducer.userSignInData,
    //GLOBAL FILTER PARAMS
    modeGlobalFilter:                   store.globalFilterReducer.modeGlobalFilter,
    laneGlobalFilter:                   store.globalFilterReducer.laneGlobalFilter,
    forwarderGlobalFilter:              store.globalFilterReducer.forwarderGlobalFilter,
    serviceGlobalFilter:                store.globalFilterReducer.serviceGlobalFilter,
    startDTGlobalFilter:                store.globalFilterReducer.startDTGlobalFilter,
    endDTGlobalFilter:                  store.globalFilterReducer.endDTGlobalFilter,
    jobStatusGlobalFilter:              store.globalFilterReducer.jobStatusGlobalFilter


});

const mapDispatchToProps = dispatch =>
({

    getShipScheduleGraphData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getShipScheduleGraphData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },
    
    getShipmentTrackerData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) =>
    {
        dispatch( actions.getShipmentTrackerData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) )
    },


});


class ShipmentScheduleGraphContainer extends Component
{
	constructor(props)
	{
		super(props);
		
	}
	

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramInTransit = 'O'
    var paramMode = null
    var paramCarrier = null
    var paramFilter = null
    var paramFilterValue = null
  
    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
        paramInTransit = values.intransit
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
        paramCarrier = values.carrier
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }

    this.props.getShipScheduleGraphData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );
    
    }
	
	
    render()
    {

    	return (

            <ShipmentScheduleGraph 
                shipmentScheduleGraph={ this.props.shipmentScheduleGraph }
                isShipScheduleGraphLoading={ this.props.isShipScheduleGraphLoading }
                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                getShipmentTrackerData={this.props.getShipmentTrackerData}


                
                //GLOBAL FILTER PARAMS
                modeGlobalFilter={this.props.modeGlobalFilter}
                laneGlobalFilter={this.props.laneGlobalFilter}
                forwarderGlobalFilter={this.props.forwarderGlobalFilter}
                serviceGlobalFilter={this.props.serviceGlobalFilter}
                startDTGlobalFilter={this.props.startDTGlobalFilter}
                endDTGlobalFilter={this.props.endDTGlobalFilter}
                jobStatusGlobalFilter={this.props.jobStatusGlobalFilter}

            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentScheduleGraphContainer));


