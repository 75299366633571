import React      from 'react';
import PropTypes  from 'prop-types';

const ModalFlagCard = ({
  color,
  icon
}) => (
    <i className={ `${icon}` }
        style={{   
            width: '60px',
            height: '60px',
            display: 'inline-block',
            lineHeight: '60px',
            textAlign: 'center',
            fontSize: '30px',
            background:  `${color}` ,
            borderRadius: '5px',
            float: 'center',
            verticalAlign: 'center',
            margin: '15px',
            color: '#fff',
            cursor: "pointer"
            }}
    ></i> 
);

ModalFlagCard.propTypes = {
    color:  PropTypes.string,
    icon:       PropTypes.string
};

ModalFlagCard.defaultProps = {
    color: '#eee',
    icon: 'fa fa-flag'
};

export default ModalFlagCard;
