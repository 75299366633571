import React, { PureComponent } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import PropTypes  from 'prop-types';
import {
    ResponsiveContainer,
  } from 'recharts';

const ReChartLine = ({
    data,  
    dataKey,    
    lineKeys,
    onClick,
    xLegend,
    yLegend
    }) => (

        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
            <LineChart
                // width={500}
                // height={300}
                data={data}
                margin={{
                top: 10, right: 30, left: 20, bottom: 25,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKey} label={{ value: xLegend, dy: 20}}/>
                <YAxis label={{ value: yLegend, angle: -90,   dx: -40}}/>
                <Tooltip />
                {/* <Legend verticalAlign="top" /> */}
                {
                    lineKeys.map(
                        ({lineKey, stroke}, idx) => {
                            return (
                                <Line connectNulls type="monotone" dataKey={lineKey} stroke={stroke} activeDot={onClick} />
                            );
                        }
                    )
                }

            </LineChart>

        </ResponsiveContainer>
        </div>
        </div>        

);

ReChartLine.propTypes = {
    data:                   PropTypes.array,
    dataKey:                PropTypes.string,
    lineKeys:               PropTypes.array, //line key string, stroke color string
    onClick:                PropTypes.func,
    xLegend:                PropTypes.string,
    yLegend:                PropTypes.string
};


export default ReChartLine;



// <Line connectNulls type="monotone" dataKey={lineKey1} stroke="#8884d8" activeDot={{ r: 8 }} />
// <Line connectNulls type="monotone" dataKey={lineKey2} stroke="#82ca9d" />