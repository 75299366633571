import {connect} from 'react-redux';
import React, {Component} from 'react';
import ShipmentDetails from './ShipmentDetails';
// import * as actions from '../../../redux/actions/googleActions';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';
import * as actions from '../../../redux/actions/googleActions';


const mapStateToProps = store =>
({
    shipmentDetailsRowData:         store.shipmentDetailsReducer.shipmentDetailsRowData,
    shipmentDetails:                store.shipmentDetailsReducer.shipmentDetails,
    isShipmentDetailsLoading:       store.shipmentDetailsReducer.isShipmentDetailsLoading,
    shipCommentsData:               store.shipmentDetailsReducer.shipCommentsData,
    isShipCommentsLoading:          store.shipmentDetailsReducer.isShipCommentsLoading,
    detailsMileStoneAlertsData:     store.shipmentDetailsReducer.detailsMileStoneAlertsData,

    serviceIssueData:               store.shipmentDetailsReducer.serviceIssueData,
    isServiceIssueLoading:          store.shipmentDetailsReducer.isServiceIssueLoading,

    shipTrackModalShow:             store.shipmentTrackerReducer.shipTrackModalShow,

    isJobsLegsLoading:              store.shipmentDetailsReducer.isJobsLegsLoading,  
    JobsLegsData:                   store.shipmentDetailsReducer.JobsLegsData,
    isJobsRefNumLoading:            store.shipmentDetailsReducer.isJobsRefNumLoading,       
    JobsRefNumData:                 store.shipmentDetailsReducer.JobsRefNumData,

    // shipTrackModalData:             store.shipmentTrackerReducer.shipTrackModalData,
    // shipTrackModalRow:              store.shipmentTrackerReducer.shipTrackModalRow,



    currentUserEmail:               store.userServicesReducer.currentUserEmail,
    userSignInData:                 store.userServicesReducer.userSignInData
});


//add actions here
const mapDispatchToProps = dispatch =>
({

    getShipmentSearchData:( _db, _email, _housebill ) =>
    {
        dispatch( actions.getShipmentSearchData( _db, _email, _housebill ) )
    },

    openShipModal:( _db, _email, _jobid, _row ) =>
    {
        dispatch( actions.showShipmentTrackerModalGetComments( _db, _email, _jobid, _row ) )
    },

    shipmentSubscribe:( _db, _email, _jobid, _active ) =>
    {
        dispatch( actions.shipmentSubscribe( _db, _email, _jobid, _active ) )
    },

    postServiceIssue:( _db, _email, _jobid, _serviceissueid ) =>
    {
        dispatch( actions.postServiceIssue( _db, _email, _jobid, _serviceissueid ) )
    },

    
});

class ShipmentDetailsContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
    

	componentDidMount(){

        const values = queryString.parse(this.props.location.search)

        this.props.getShipmentSearchData( this.props.userSignInData.DBName, this.props.currentUserEmail, values.housebill );


    }
    render()
    {
    	return (

            <ShipmentDetails 
            shipmentDetailsRowData={ this.props.shipmentDetailsRowData }
            shipmentDetails={ this.props.shipmentDetails }
            isShipmentDetailsLoading={ this.props.isShipmentDetailsLoading }
            shipCommentsData={ this.props.shipCommentsData }
            isShipCommentsLoading={ this.props.isShipCommentsLoading }
            detailsMileStoneAlertsData={ this.props.detailsMileStoneAlertsData }
            openShipModal={this.props.openShipModal}
            shipTrackModalShow={this.props.shipTrackModalShow}
            currentUserEmail={this.props.currentUserEmail}
            shipmentSubscribe={this.props.shipmentSubscribe}

            isJobsLegsLoading={this.props.isJobsLegsLoading}   
            JobsLegsData={this.props.JobsLegsData}
            isJobsRefNumLoading={this.props.isJobsRefNumLoading}     
            JobsRefNumData={this.props.JobsRefNumData}

            serviceIssueData={this.props.serviceIssueData}
            isServiceIssueLoading={this.props.isServiceIssueLoading}
            postServiceIssue={this.props.postServiceIssue}
            userSignInData={this.props.userSignInData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentDetailsContainer));
