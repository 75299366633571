import React      from 'react';
import PropTypes  from 'prop-types';
import HomeMenu         from './HomeMenu/HomeMenu';
import HomeMainContent  from './HomeMainContent/HomeMainContent';
import {MainRoutes, NavigationLinks}      from '../../../routes/MainRoutes'

const Home = ({
                sideMenuIsCollapsed,
                email,             
                firstName,
                lastName,              
                pendingApproval,     
                admin,              
                group,    
                businessUnit,           
                isMobile, 
                navSelection,
                dropDownOnChange,
                languageSelection       
                   }) => (

    <div className="wrapper row-offcanvas row-offcanvas-left">
    <HomeMenu
              isAnimated={true}
            //   isCollapsed={isMobile === false ? false : true}   //is menu hidden or shown  
              currentView={true}
              sideMenu={NavigationLinks( {pendingApproval, admin, group, businessUnit, navSelection, languageSelection} )}
              helloWord={'true'}
              connectionStatus={'true'}
              userIsConnected={'true'}
              username={'test'}
              isCollapsed={sideMenuIsCollapsed}
              isMobile={isMobile}
              navSelection={navSelection}
              admin={admin}
              group={group}
              businessUnit={businessUnit}
              dropDownOnChange={dropDownOnChange}
              languageSelection={languageSelection}
    />
    <HomeMainContent isAnimated={true} isExpanded={sideMenuIsCollapsed} isMobile={isMobile} languageSelection={languageSelection}>
        <MainRoutes 
          pendingApproval={pendingApproval}
          admin={admin}
          group={group}
          businessUnit={businessUnit}

          navSelection={navSelection}
          languageSelection={languageSelection}
          />
    </HomeMainContent>
    </div>

);

Home.propTypes = {
    sideMenuIsCollapsed:    PropTypes.bool,

    email:                  PropTypes.string, 
    firstName:              PropTypes.string,
    lastName:               PropTypes.string,
    pendingApproval:        PropTypes.bool,     //if user account is pending approval
    admin:                  PropTypes.bool,     //if user has admin rights
    group:                  PropTypes.string,   //this is organizaton (Omni, google)
    businessUnit:           PropTypes.string,   //this is role in organization (operations, sales)
    isMobile:               PropTypes.bool,
    navSelection:           PropTypes.string,
    dropDownOnChange:       PropTypes.func,
    languageSelection:      PropTypes.string

};


export default Home;
