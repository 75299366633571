import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
// import {
//     Table,
//     TableBody
//   }                         from '../../../components/ComponentsGeneral/table';
  import {//valueDeliveryDayFormatter, 
    buttonFormatter, 
    mawbButtonFormatterMobile
    // modalButtonFormatter, 
    // shipmentTagFormatter, 
    // shipmentAlertIconFormatter
    // // hotShipmentIcon
  } from '../../../resources/logic/formatting';
  import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import BootstrapTable from 'react-bootstrap-table-next';
  // import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';
  import Button from 'react-bootstrap/Button'
  import queryString from 'query-string';
  import {appSettings} from '../../../app/appSettings'
  import * as moment from 'moment'
  import ReChartBar from '../../ComponentsGeneral/ReCharts/ReChartBar'
  import ReactExport from "react-export-excel";

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")

  const barKeyArray = [
    {'barKey': 'Percent', 'fill': '#f43e1f'}
    ]



  class GBDataExport extends PureComponent {
    constructor( props )
    {
      super( props );
      this.state = {
        chartType: '',
        searchinput: '',
        bulksearchinput: ''
      }
      this.handleSetChartType = this.handleSetChartType.bind(this);
    }


    handleSetChartType(chartType) {
      this.setState({
        chartType: chartType
      });
    }


    //this handles search text input box update state
    onSearchChange = (e) => {
      e.preventDefault();
      this.setState({ [e.target.name]: e.target.value });

    }


    //prevent search form input from submitting on enter key
    onSearchKeyPress(event) {
      if (event.which === 13 /* Enter */) {
        event.preventDefault();
      }
    }



    convertChartParam() {
      var chartTitle = '';
      var paramChartTitle = '';

      try{
        paramChartTitle = this.state.chartType
      }
      catch(e){
        console.log('error', e)
      }
      
      if (paramChartTitle == 'firstscantodelivery'){
        chartTitle = 'First Scan To Delivery'
      }
      else if (paramChartTitle == 'flightdeparttodelivery'){
        chartTitle = 'Flight Depart To Delivery'
      }

      else if (paramChartTitle == 'recoverytofirstscan'){
        chartTitle = 'Recovery To First Scan'
      }
      return chartTitle
    }


    // MicomEvents
    // UpsEvents
    // Package
    // MAWB


  clickPreviewData( e, dataType ) {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null

    var paramMawb = null
    var paramMC = null
    var paramHawb = null
    var paramFilter = null
    var paramFilterValue = null
    var paramCustomerCode = null
    var paramShipmentType = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }

    // if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
    //     paramMawb = values.mawb
    // }

    if(typeof(this.state.searchinput) !== 'undefined' && this.state.searchinput != null && this.state.searchinput !== ''){

      var searchString = this.state.searchinput.trim().replace(/-/g,'')

      paramMawb = "'" + searchString + "'"
    }


    if(typeof(values.mc) !== 'undefined' && values.mc != null ){
        paramMC = values.mc
    }
    if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
        paramHawb = values.hawb
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }
    if(typeof(values.customercode) !== 'undefined' && values.customercode != null ){
      paramCustomerCode = values.customercode
    }
    if(typeof(values.shipmenttype) !== 'undefined' && values.shipmenttype != null ){
      paramShipmentType = values.shipmenttype
    }


    this.props.gbGetDataExport( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, dataType, null, paramCustomerCode, paramShipmentType );

    
  }


  clickPreviewBulkTrackingReport( e ) {
      var paramTrackingNoString = null

      try{
        var trackingNoString = this.state.bulksearchinput.trim().replace(/-/g,'')
        paramTrackingNoString = "'" + trackingNoString + "'"
      }
      catch(e){
        console.log('error', e)
      }

      this.props.gbGetBulkHawbDetails( appSettings.gbDB, this.props.currentUserEmail, paramTrackingNoString );

  }



  clickPreviewReportData( e, dataType ) {


    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null

    var paramMawb = null
    var paramMC = null
    var paramHawb = null
    var paramFilter = null
    var paramFilterValue = null
    var paramCustomerCode = null
    var paramShipmentType = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }

    // if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
    //     paramMawb = values.mawb
    // }
    if(typeof(this.state.searchinput) !== 'undefined' && this.state.searchinput != null && this.state.searchinput !== ''){

      var searchString = this.state.searchinput.trim().replace(/-/g,'')

      paramMawb = "'" + searchString + "'"


    }

    if(typeof(values.mc) !== 'undefined' && values.mc != null ){
        paramMC = values.mc
    }
    if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
        paramHawb = values.hawb
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }

    


    if(typeof(values.customercode) !== 'undefined' && values.customercode != null ){
      paramCustomerCode = values.customercode
      }
      if(typeof(values.shipmenttype) !== 'undefined' && values.shipmenttype != null ){
        paramShipmentType = values.shipmenttype
    }


    this.props.gbGetDataReportsExport( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, dataType, null, paramCustomerCode, paramShipmentType );

    
  }




  // clickPreviewChartData( e, dataType ) {
  //   var chartDetails = null

  //   try{
  //     // chartDetails = dataType + 'data'
  //     chartDetails = dataType + 'percent'
  //   }
  //   catch(e){
  //     console.log('error', e)
  //   }
    

  //   var paramStartDT = null
  //   var paramEndDT = null
  //   var paramDateType = null
  //   var paramNotDeparted = null
  //   var paramPortExit = null
  //   var paramPortEntry = null
  //   var paramFinalCarrier = null

  //   var paramMawb = null
  //   var paramMC = null
  //   var paramHawb = null
  //   var paramFilter = null
  //   var paramFilterValue = null
  //   var paramCustomerCode = null

  //   const values = queryString.parse(this.props.location.search)

  //   if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
  //       paramStartDT = values.startdt
  //   }
  //   if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
  //       paramEndDT = values.enddt
  //   }
  //   if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
  //       paramDateType = values.datetype
  //   }
  //   if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
  //       paramNotDeparted = values.notdeparted
  //   }
  //   if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
  //       paramPortExit = values.portexit
  //   }
  //   if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
  //       paramPortEntry = values.portentry
  //   }
  //   if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
  //       paramFinalCarrier = values.finalcarrier
  //   }

  //   if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
  //       paramMawb = values.mawb
  //   }
  //   if(typeof(values.mc) !== 'undefined' && values.mc != null ){
  //       paramMC = values.mc
  //   }
  //   if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
  //       paramHawb = values.hawb
  //   }
  //   if(typeof(values.filter) !== 'undefined' && values.filter != null ){
  //       paramFilter = values.filter
  //   }
  //   if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
  //       paramFilterValue = values.filterValue
  //   }
  //   if(typeof(values.customercode) !== 'undefined' && values.customercode != null ){
  //     paramCustomerCode = values.customercode
  // }


  //   this.handleSetChartType(dataType)
  
  //   // this.props.gbGetChartDataExport( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, dataType, null, paramCustomerCode );

  //   this.props.gbGetChartDetailDataExport( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, chartDetails, null, paramCustomerCode );


  // }



    render() {
      const {
        searchinput,
        bulksearchinput
      } = this.state;




      const MawbColumns = [
        {
          dataField: 'MAWB',
          text: 'MAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'HAWB',
          text: 'HAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'AirlineCarrier',
          text: 'AirlineCarrier',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'FlightNo',
          text: 'FlightNo',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'ShipToAirport',
          text: 'ShipToAirport',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'ShipToCountry',
          text: 'ShipToCountry',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'FlightDepartDT',
          text: 'FlightDepartDT',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'FlightArrivalDT',
          text: 'FlightArrivalDT',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'TotContainers',
          text: 'TotContainers',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'TotPackages',
          text: 'TotPackages',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'TotWeight',
          text: 'TotWeight',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'Freight_Recovery_To_Hand_Off_hrs',
          text: 'Freight_Recovery_To_Hand_Off_hrs',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        }

        ]



      const PackageColumns = [
        {
          dataField: 'HAWB',
          text: 'Tracking Number',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'MAWB',
          text: 'MAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'ContainerID',
          text: 'MC',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '30%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },

        {
          dataField: 'OrderNo',
          text: 'OrderNo',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'R_State',
          text: 'R_State',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'R_PostCode',
          text: 'R_PostCode',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'R_CountryCode',
          text: 'R_CountryCode',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        // {
        //   dataField: 'P_ItemUnitValue',
        //   text: 'P_ItemUnitValue',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'P_UnitWeight',
        //   text: 'P_UnitWeight',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'P_Quantity',
        //   text: 'P_Quantity',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'P_Desc',
        //   text: 'P_Desc',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },

        // {
        //   dataField: 'ParcelWeight',
        //   text: 'ParcelWeight',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        {
          dataField: 'DestinationAirport',
          text: 'DestinationAirport',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'InboundFileDT',
          text: 'InboundFileDT',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        // {
        //   dataField: 'HazMat',
        //   text: 'HazMat',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        {
          dataField: 'CustomerCode',
          text: 'CustomerCode',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        // {
        //   dataField: 'InboundFileDT_To_FlightDepartDT_hrs',
        //   text: 'InboundFileDT_To_FlightDepartDT_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'InboundFileDT_To_FlightArrivalDT_hrs',
        //   text: 'InboundFileDT_To_FlightArrivalDT_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Alert_Carrier_VS_DT',
        //   text: 'Alert_Carrier_VS_DT',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Final_Mile_Scan_To_Final_Delivery_hrs',
        //   text: 'Final_Mile_Scan_To_Final_Delivery_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Manifest_To_Freight_Recovery_hrs',
        //   text: 'Manifest_To_Freight_Recovery_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Hand_Off_To_Origin_hrs',
        //   text: 'Hand_Off_To_Origin_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Depart_To_Recovery_hrs',
        //   text: 'Depart_To_Recovery_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Carrier_Induction_Alert_DT',
        //   text: 'Carrier_Induction_Alert_DT',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Manifest_To_Delivery_hrs',
        //   text: 'Manifest_To_Delivery_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Recovery_To_Origin_hrs',
        //   text: 'Recovery_To_Origin_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Final_Mile_Scan_To_Final_Delivery_bus_days',
        //   text: 'Final_Mile_Scan_To_Final_Delivery_bus_days',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Flight_Depart_To_Origin_hrs',
        //   text: 'Flight_Depart_To_Origin_hrs',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // },
        // {
        //   dataField: 'Manifest_To_Delivery_bus_days',
        //   text: 'Manifest_To_Delivery_bus_days',
        //   align: 'center',
        //   headerStyle: (colum, colIndex) => {
        //     return { width: '50%' , textAlign: 'center' };
        //   },
        //   sort: true
        // }

        ]

        
        


      const UpsColumns = [
        {
          dataField: 'MAWB',
          text: 'MAWB',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'HAWB',
          text: 'Tracking Number',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'OrderNo',
          text: 'OrderNo',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true,
          style: { overflowWrap: "break-word" }
        },
        {
          dataField: 'Date',
          text: 'Date',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'Location',
          text: 'Location',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        },
        {
          dataField: 'Activity',
          text: 'Activity',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        }

        ]
        

        const MicomColumns = [
          {
            dataField: 'MAWB',
            text: 'MAWB',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" }
          },
          {
            dataField: 'Date',
            text: 'Date',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },
            sort: true
          },
          {
            dataField: 'Status',
            text: 'Status',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },
            sort: true
          }
          ]


          const ChartReportColumns = [
            {
              dataField: 'CustomerCode',
              text: 'Customer Code',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '8%' , textAlign: 'center' };
              },
              sort: true,
            },
            {
              dataField: 'MAWB',
              text: 'MAWB',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'Tracking_No',
              text: 'Tracking No',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '27%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'Updated_Tracking_No',
              text: 'Updated Tracking No',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '27%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'InWhseScanDT',
              text: 'In Warehouse Scan DT',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'Freight_Recovery',
              text: 'Freight Recovery',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'First_Scan_UPS',
              text: 'First Scan UPS',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'UPS_Delivered',
              text: 'UPS Delivered',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'Latest_UPS_Scan',
              text: 'Latest UPS Scan',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '15%' , textAlign: 'center' };
              },
              sort: true
            }
            ]

          const BulkChartReportColumns = [
            {
              dataField: 'HAWB',
              text: 'Tracking No',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '25%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'MAWB',
              text: 'MAWB',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '20%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'OrderNo',
              text: 'Order No',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'ContainerID',
              text: 'Barcode',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '15%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" }
            },
            {
              dataField: 'CustomerCode',
              text: 'Customer Code',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'R_State',
              text: 'Ship-to State',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'R_PostCode',
              text: 'Ship-to Zip',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            {
              dataField: 'ParcelWeight',
              text: 'Parcel Weight (kgs)',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '10%' , textAlign: 'center' };
              },
              sort: true
            },
            // {
            //   dataField: 'HazMat',
            //   text: 'Haz Mat',
            //   align: 'center',
            //   headerStyle: (colum, colIndex) => {
            //     return { width: '10%' , textAlign: 'center' };
            //   },
            //   sort: true
            // },
            // {
            //   dataField: 'InWhseScanDT',
            //   text: 'In Warehouse Scan DT',
            //   align: 'center',
            //   headerStyle: (colum, colIndex) => {
            //     return { width: '10%' , textAlign: 'center' };
            //   },
            //   sort: true
            // }
            ]
      // const ChartDetailColumns = [
      //   {
      //     dataField: 'Tracking',
      //     text: 'Tracking Number',
      //     align: 'center',
      //     headerStyle: (colum, colIndex) => {
      //       return { width: '50%' , textAlign: 'center' };
      //     },
      //     sort: true
      //   },
      //   {
      //     dataField: 'MAWB',
      //     text: 'MAWB',
      //     align: 'center',
      //     headerStyle: (colum, colIndex) => {
      //       return { width: '50%' , textAlign: 'center' };
      //     },
      //     sort: true,
      //   },

      //   {
      //     dataField: 'Days',
      //     text: this.convertChartParam() + ' Days',
      //     // text: 'Days',
      //     align: 'center',
      //     headerStyle: (colum, colIndex) => {
      //       return { width: '50%' , textAlign: 'center' };
      //     },
      //     sort: true
      //   }

      //   ]

        // const ChartDetailColumns = [
        //   // {
        //   //   dataField: 'Days',
        //   //   text: 'Days',
        //   //   align: 'center',
        //   //   headerStyle: (colum, colIndex) => {
        //   //     return { width: '50%' , textAlign: 'center' };
        //   //   },
        //   //   sort: true
        //   // },
        //   {
        //     dataField: 'Days',
        //     text: this.convertChartParam() + ' Days',
        //     // text: 'Days',
        //     align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //       return { width: '50%' , textAlign: 'center' };
        //     },
        //     sort: true
        //   },
        //   {
        //     dataField: 'Percent',
        //     text: 'Percent (%)',
        //     align: 'center',
        //     headerStyle: (colum, colIndex) => {
        //       return { width: '50%' , textAlign: 'center' };
        //     },
        //     sort: true,
        //   },
  

  
        //   ]


          
        let reportSearchInput;
        reportSearchInput = 
        <form
            className="sidebar-form"
            onKeyPress={this.onSearchKeyPress}
            >
            <div className="input-group" title= { "MAWB" } >
            <input
                type="text"
                name="searchinput"
                className="form-control"
                placeholder={ "MAWB" }
                value={ searchinput }
                onChange={this.onSearchChange}

                // style={ {fontSize: 15} }
                />
            {/* <span className="input-group-btn">
                <button
                onClick={searchOnSubmit}
                name="search"
                id="search-btn"
                className="btn btn-flat">
                <i className="fa fa-search"></i>
                </button>
            </span> */}
            </div>
        </form>



        let bulkTrackingNoInput;
        bulkTrackingNoInput = 
        <form
            className="sidebar-form"
            onKeyPress={this.onSearchKeyPress}
            >
            <div className="input-group" title= { "Tracking No" } >
            <input
                type="text"
                name="bulksearchinput"
                className="form-control"
                // placeholder={ "TrackingNo1, TrackingNo2, TrackingNo3, ..." }
                placeholder={ "Enter up to 25 Tracking Numbers separated by a comma" }
                value={ bulksearchinput }
                onChange={this.onSearchChange}
                />
            </div>
        </form>
    


        let loadButtonsReports;
        loadButtonsReports = 
        <div className="sm-st clearfix" style={{height: '100%'}}>

          <div className="row" >
              <div className="col-md-3">              
              <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewReportData(e, 'trackingreport')} >
                  {'Load Tracking Report'}
              </Button>   
              </div>  

              <div className="col-md-3">              
              <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewReportData(e, 'inductionalertreport')} >
                  {'Load Induction Alert Report'}
              </Button>   
              </div>  

              <div className="col-md-3">              
              <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewReportData(e, 'deliveryalertreport')} >
                  {'Load Delivery Alert Report'}
              </Button>   
              </div>  

          </div>
        </div>

        let loadButtonsBulkTrakcingNoReports;
        loadButtonsBulkTrakcingNoReports = 
        <div className="sm-st clearfix" style={{height: '100%'}}>

          <div className="row" >
              <div className="col-md-3">              
              <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewBulkTrackingReport(e)} >
                  {'Load Bulk Tracking Report'}
              </Button>   
              </div>  


          </div>
        </div>

        

          let loadButtons;
          loadButtons = 
          <div className="sm-st clearfix" style={{height: '100%'}}>

            <div className="row" >

                <div className="col-md-3">              
                <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewData(e, 'MAWB')} >
                    {'Load MAWB'}
                </Button>   
                </div>  

                <div className="col-md-3">      
                <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewData(e, 'Package')} >
                    {'Load Package'}
                </Button>      
                </div> 

                <div className="col-md-3">      
                <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewData(e, 'UpsEvents')} >
                    {'Load UPS MI Events'}
                </Button>      
                </div> 

                <div className="col-md-3">      
                <Button variant={"success"} size={"sm"} block  style={{marginTop: '5px'}} onClick={(e) => this.clickPreviewData(e, 'MicomEvents')} >
                    {'Load Customs Broker Events'}
                </Button>    
                </div> 
            </div>
        </div>




        let bootStrapTable;
        if (this.props.isDataExportLoading) {
          bootStrapTable=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.DataExport) !== 'undefined' && this.props.DataExport != null) {

          if (this.props.DataExport.length > 0){

          var MAWBdata = this.props.DataExport.filter(x => x.ResultType === 'MAWB' )
          var PackageData = this.props.DataExport.filter(x => x.ResultType === 'Package'  )
          var UPSData = this.props.DataExport.filter(x => x.ResultType === 'UpsEvents'  )
          var MicomData = this.props.DataExport.filter(x => x.ResultType === 'MicomEvents'  )

          bootStrapTable =
            <div>
              <ToolkitProvider
                keyField="UniqueID"
                data={ MAWBdata }
                columns= {(this.props.isMobile == true  ) ? MawbColumns  : MawbColumns }
                exportCSV = { {
                  fileName: 'MAWB_'+ currentDateTime +'.csv'
                } }
                >
                {
                  props => (
                    <div style={{  display: (MAWBdata.length > 0  ) ? ''  : 'none'}}>
                    <header className="panel-heading">
                      { `MAWB`}
                    </header>
                      {(this.props.isMobile == true  ) ? <div></div>  : 
                        <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'MAWB_'+ currentDateTime}>
                            <ExcelSheet data={MAWBdata} name="MAWB">
                                <ExcelColumn label="MAWB" value="MAWB"/>
                                <ExcelColumn label="HAWB" value="HAWB"/>
                                <ExcelColumn label="AirlineCarrier" value="AirlineCarrier"/>
                                <ExcelColumn label="FlightNo" value="FlightNo"/>
                                <ExcelColumn label="ShipToAirport" value="ShipToAirport"/>
                                <ExcelColumn label="ShipToCountry" value="ShipToCountry"/>
                                <ExcelColumn label="FlightDepartDT" value="FlightDepartDT"/>
                                <ExcelColumn label="FlightArrivalDT" value="FlightArrivalDT"/>
                                <ExcelColumn label="TotContainers" value="TotContainers"/>
                                <ExcelColumn label="TotPackages" value="TotPackages"/>
                                <ExcelColumn label="TotWeight" value="TotWeight"/>
                                <ExcelColumn label="Freight Recovery To Hand Off hrs" value="Freight_Recovery_To_Hand_Off_hrs"/>
                            </ExcelSheet>
                        </ExcelFile>
                      
                      // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                      }
                      <hr />
                        <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                    </div>
                  )
                }
              </ToolkitProvider>

              <ToolkitProvider
              keyField="UniqueID"
              data={ PackageData }
              columns= {(this.props.isMobile == true  ) ? PackageColumns  : PackageColumns }
              exportCSV = { {
                fileName: 'Pacakge_'+ currentDateTime +'.csv'
              } }
              >
              {
                props => (
                  <div style={{  display: (PackageData.length > 0  ) ? ''  : 'none'}}>
                    <header className="panel-heading">
                      { `Package`}
                    </header>
                    {(this.props.isMobile == true  ) ? <div></div>  : 
                    <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'Pacakge_'+ currentDateTime}>
                        <ExcelSheet data={PackageData} name="Package">
                            <ExcelColumn label="TrackingNumber" value="HAWB"/>
                            <ExcelColumn label="MAWB" value="MAWB"/>
                            <ExcelColumn label="MC" value="ContainerID"/>             
                            <ExcelColumn label="OrderNo" value="OrderNo"/>
                            <ExcelColumn label="R_State" value="R_State"/>
                            <ExcelColumn label="R_PostCode" value="R_PostCode"/>
                            <ExcelColumn label="R_CountryCode" value="R_CountryCode"/>
                            <ExcelColumn label="DestinationAirport" value="DestinationAirport"/>
                            <ExcelColumn label="InboundFileDT" value="InboundFileDT"/>
                            <ExcelColumn label="CustomerCode" value="CustomerCode"/>
                        </ExcelSheet>
                    </ExcelFile>
                    
                    // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                    }
                    <hr />
                      <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                  </div>
                )
              }
              </ToolkitProvider>

              <ToolkitProvider
              keyField="UniqueID"
              data={ UPSData }
              columns= {(this.props.isMobile == true  ) ? UpsColumns  : UpsColumns }
              exportCSV = { {
                fileName: 'UPS_Events_'+ currentDateTime +'.csv'
              } }
              >
              {
                props => (
                  <div style={{  display: (UPSData.length > 0  ) ? ''  : 'none'}}>
                    <header className="panel-heading">
                      { `UPS MI Events`}
                    </header>
                    {(this.props.isMobile == true  ) ? <div></div>  : 
                    <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'UPS_Events_'+ currentDateTime}>
                        <ExcelSheet data={UPSData} name="UPSEvents">
                            <ExcelColumn label="MAWB" value="MAWB"/>
                            <ExcelColumn label="Tracking Number" value="HAWB"/>
                            <ExcelColumn label="OrderNo" value="OrderNo"/>
                            <ExcelColumn label="Date" value="Date"/>
                            <ExcelColumn label="Location" value="Location"/>
                            <ExcelColumn label="Activity" value="Activity"/>                            
                        </ExcelSheet>
                    </ExcelFile>
                

                    // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                    }
                    <hr />
                      <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                  </div>
                )
              }
              </ToolkitProvider>

              <ToolkitProvider
              keyField="UniqueID"
              data={ MicomData }
              columns= {(this.props.isMobile == true  ) ? MicomColumns  : MicomColumns }
              exportCSV = { {
                fileName: 'Customs_Broker_Events_'+ currentDateTime +'.csv'
              } }
              >
              {
                props => (
                  <div style={{  display: (MicomData.length > 0  ) ? ''  : 'none'}}>
                    <header className="panel-heading">
                      { `Customs Broker Events`}
                    </header>
                    {(this.props.isMobile == true  ) ? <div></div>  : 
                    <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'Customs_Broker_Events_'+ currentDateTime}>
                        <ExcelSheet data={MicomData} name="CustomsBrokerEvents">
                            <ExcelColumn label="MAWB" value="MAWB"/>
                            <ExcelColumn label="Date" value="Date"/>
                            <ExcelColumn label="Status" value="Status"/>
                        </ExcelSheet>
                    </ExcelFile>
                    
                    // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                     }
                    <hr />
                      <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                  </div>
                )
              }
              </ToolkitProvider>

              </div>




          }
          else {
            bootStrapTable =
            <div></div>
          }

        }
        else {
          bootStrapTable =
          <div></div>
        }


        let bootStrapTableReports;
        if (this.props.isDataReportsExportLoading) {
          bootStrapTableReports=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.DataReportsExport) !== 'undefined' && this.props.DataReportsExport != null) {

          if (this.props.DataReportsExport.length > 0){

          var chartTitle = 'Tracking Report';

          var exportName = 'tracking_report'

          bootStrapTableReports =
            <div>
              <ToolkitProvider
                keyField="Tracking"
                data={ this.props.DataReportsExport }
                columns= {(this.props.isMobile == true  ) ? ChartReportColumns  : ChartReportColumns }
                exportCSV = { {
                  fileName: exportName+'_'+ currentDateTime +'.csv'
                } }
                >
                {
                  props => (
                    <div style={{  display: (this.props.DataReportsExport.length > 0  ) ? ''  : 'none'}}>
                    <header className="panel-heading">
                    {`${chartTitle}`}
                    </header>
                      {(this.props.isMobile == true  ) ? <div></div>  : 
                      <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'Tracking_Report'+ currentDateTime}>
                          <ExcelSheet data={this.props.DataReportsExport} name="TrackingReport">
                              <ExcelColumn label="Customer Code" value="CustomerCode"/>
                              <ExcelColumn label="MAWB" value="MAWB"/>
                              <ExcelColumn label="Tracking No" value="Tracking_No"/>
                              <ExcelColumn label="Updated Tracking No" value="Updated_Tracking_No"/>
                              <ExcelColumn label="In Warehouse Scan DT" value="InWhseScanDT"/>
                              <ExcelColumn label="Freight Recovery" value="Freight_Recovery"/>
                              <ExcelColumn label="First Scan UPS" value="First_Scan_UPS"/>
                              <ExcelColumn label="UPS Delivered" value="UPS_Delivered"/>
                              <ExcelColumn label="Latest UPScan" value="Latest_UPS_Scan"/>
                          </ExcelSheet>
                      </ExcelFile>                      
                      
                      // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                       }
                      <hr />
                        <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                    </div>
                  )
                }
              </ToolkitProvider>
              </div>

          }
          else {
            bootStrapTableReports =
            <div></div>
          }
        }
        else {
          bootStrapTableReports =
          <div></div>
        }




        let bootStrapTableBulkReports;
        if (this.props.isBulkHawbDetailsLoading) {
          bootStrapTableBulkReports=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.bulkPackageData) !== 'undefined' && this.props.bulkPackageData != null) {

          console.log('this.props.bulkPackageData', this.props.bulkPackageData)

          if (this.props.bulkPackageData.length > 0){

          var chartTitle = 'Bulk Tracking Report';

          var exportName = 'bulk_tracking_report'

          bootStrapTableBulkReports =
            <div>
              <ToolkitProvider
                keyField="UniqueID"
                data={ this.props.bulkPackageData }
                columns= {(this.props.isMobile == true  ) ? BulkChartReportColumns  : BulkChartReportColumns }
                exportCSV = { {
                  fileName: exportName+'_'+ currentDateTime +'.csv'
                } }
                >
                {
                  props => (
                    <div style={{  display: (this.props.bulkPackageData.length > 0  ) ? ''  : 'none'}}>
                    <header className="panel-heading">
                    {`${chartTitle}`}
                    </header>
                      {(this.props.isMobile == true  ) ? <div></div>  : 
                      <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'Bulk_Tracking_Report'+ currentDateTime}>

                          <ExcelSheet data={this.props.bulkPackageData} name="Package">
                              <ExcelColumn label="MAWB" value="MAWB"/>
                              <ExcelColumn label="Order No" value="OrderNo"/>
                              <ExcelColumn label="Barcode" value="ContainerID"/>
                              <ExcelColumn label="Tracking No" value="HAWB"/>
                              <ExcelColumn label="Description" value="P_Desc"/>
                              <ExcelColumn label="Ship-to State" value="R_State"/>
                              <ExcelColumn label="Ship-to Zip" value="R_PostCode"/>
                              <ExcelColumn label="Ship-to Country" value="R_CountryCode"/>
                              <ExcelColumn label="Value" value="P_ItemUnitValue"/>
                              {/* <ExcelColumn label="Parcel Weight" value="ParcelWeight"/> */}
                              <ExcelColumn label="Quantity" value="P_Quantity"/>
                              <ExcelColumn label="HazMat" value="HazMat"/>
                              <ExcelColumn label="Customer Code" value="CustomerCode"/>
                              <ExcelColumn label="Manifest Date" value="ManifestDate"/>
                              <ExcelColumn label="Unit Weight" value="P_UnitWeight"/>
                              <ExcelColumn label="ManifestToFlightDepartActual" value="ManifestToFlightDepartActual"/>
                              <ExcelColumn label="ManifestToFlightArrivalActual" value="ManifestToFlightArrivalActual"/>
                              <ExcelColumn label="ManifestToFreightRecoveryActual" value="ManifestToFreightRecoveryActual"/>
                              {/* <ExcelColumn label="Held" value="Held"/>
                              <ExcelColumn label="Carrier_Induction_Alert_DT" value="Carrier_Induction_Alert_DT"/>
                              <ExcelColumn label="Alert_Carrier_VS_DT" value="Alert_Carrier_VS_DT"/> */}
                          </ExcelSheet>
                          <ExcelSheet data={this.props.bulkMAWBData} name="MAWB">
                              <ExcelColumn label="MAWB" value="MAWB"/>
                              <ExcelColumn label="Flight No" value="FlightNo"/>
                              <ExcelColumn label="Ship To Airport" value="ShipToAirport"/>
                              <ExcelColumn label="Ship To Country" value="ShipToCountry"/>
                              <ExcelColumn label="Airline Carrier" value="AirlineCarrier"/>
                              <ExcelColumn label="Flight Depart DT" value="FlightDepartDT"/>
                              <ExcelColumn label="Flight Arrival DT" value="FlightArrivalDT"/>
                              <ExcelColumn label="Tot Containers" value="TotContainers"/>
                              <ExcelColumn label="Tot Packages" value="TotPackages"/>
                              <ExcelColumn label="Tot Weight" value="TotWeight"/>
                          </ExcelSheet>
                          <ExcelSheet data={this.props.bulkUpsData} name="UpsMIEvents">
                              <ExcelColumn label="Tracking No" value="trackingStrings"/>
                              <ExcelColumn label="Date" value="Date"/>
                              <ExcelColumn label="Location" value="Location"/>
                              <ExcelColumn label="Activity" value="Activity"/>
                          </ExcelSheet>
                          <ExcelSheet data={this.props.bulkMicomData} name="CustomsBrokerEvents">
                              <ExcelColumn label="MAWB" value="MAWB"/>
                              <ExcelColumn label="Date" value="DT"/>
                              <ExcelColumn label="Status" value="Status"/>
                          </ExcelSheet>
                      </ExcelFile>                      
                      
                      // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                       }
                      <hr />
                        <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                    </div>
                  )
                }
              </ToolkitProvider>
              </div>

          }
          else {
            bootStrapTableBulkReports =
            <div></div>
          }
        }
        else {
          bootStrapTableBulkReports =
          <div></div>
        }




      return(
        
        

        <div>

          <section className="panel">
            <header className="panel-heading">
              { 'Reports Export' } {reportSearchInput}
            </header>

              { loadButtonsReports }

            <header className="panel-heading">
              { `Row Count: ${this.props.DataReportsExport.length}`}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden', fontSize:'0.6rem' }}>

              { bootStrapTableReports }

            </div>
          </section>



          <section className="panel">
            <header className="panel-heading">
              { 'Raw Data Export' }
            </header>

              { loadButtons }

            <header className="panel-heading">
              { `Row Count: ${this.props.DataExport.length}`}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden', fontSize:'0.6rem' }}>

              { bootStrapTable }

            </div>
          </section>



          <section className="panel">
            <header className="panel-heading">
              { 'Bulk Tracking Number Export' } {bulkTrackingNoInput}
            </header>

              { loadButtonsBulkTrakcingNoReports }

            <header className="panel-heading">
              {/* { `Row Count: ${this.props.BulkHawbDetailsData.length}`} */}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden', fontSize:'0.6rem' }}>

              { bootStrapTableBulkReports }

            </div>
          </section>



        </div>

      );

    }
  }
  

  export default withRouter(GBDataExport);
  

