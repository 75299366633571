import {connect} from 'react-redux';
import React, {Component} from 'react';
import ActionModal from './ActionModal'
import * as actions from '../../../redux/actions/googleActions';

//add props from reducer here
const mapStateToProps = store =>
({
    // shipmentTrackerData:            store.shipmentTrackerReducer.shipmentTrackerData,
    // isShipmentTrackerLoading:       store.shipmentTrackerReducer.isShipmentTrackerLoading,
    shipTrackModalShow:             store.shipmentTrackerReducer.shipTrackModalShow,
    shipTrackModalData:             store.shipmentTrackerReducer.shipTrackModalData,
    shipTrackModalRow:              store.shipmentTrackerReducer.shipTrackModalRow,
    // shipCommentsData:               store.shipmentTrackerReducer.shipCommentsData,
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:             store.userServicesReducer.currentUserEmail
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    // getShipmentTrackerData:( _email, _housebill, _statCardInput, _alertMilestoneInput ) =>
    // {
    //     dispatch( actions.getShipmentTrackerData( _email, _housebill, _statCardInput, _alertMilestoneInput ) )
    // },

    openShipModal:( _db, _email, _jobid, _row ) =>
    {
        dispatch( actions.showShipmentTrackerModalGetComments( _db, _email, _jobid, _row ) )
    },

    hideShipmentTrackerModal:() =>
    {
        dispatch( actions.hideShipmentTrackerModal( null ) )
    },

    postShipModalComment:( _db, _email, _jobid, _comment, _type ) =>
    {
        dispatch( actions.postShipModalComment( _db, _email, _jobid, _comment, _type ) )
    },

    modalToggleHotShip:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleHotShip( _db, _email, _jobid, _bool ) )
    },

    modalToggleInternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleInternalAction( _db, _email, _jobid, _bool ) )
    },

    modalToggleExternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleExternalAction( _db, _email, _jobid, _bool ) )
    },



});


class ActionModalContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
    render()
    {
    	return (
            <ActionModal 
            // shipmentTrackerData={ this.props.shipmentTrackerData }
            // isShipmentTrackerLoading={ this.props.isShipmentTrackerLoading }
            shipTrackModalShow={this.props.shipTrackModalShow}
            openShipModal={this.props.openShipModal}
            hideShipmentTrackerModal={this.props.hideShipmentTrackerModal}
            shipTrackModalData={this.props.shipTrackModalData}
            shipTrackModalRow={this.props.shipTrackModalRow}
            postShipModalComment={this.props.postShipModalComment}
            modalToggleHotShip={this.props.modalToggleHotShip}
            modalToggleInternalAction={this.props.modalToggleInternalAction}
            modalToggleExternalAction={this.props.modalToggleExternalAction}
            userSignInData={this.props.userSignInData}
            currentUserEmail={this.props.currentUserEmail}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionModalContainer);




