import {connect} from 'react-redux';
import React, {Component} from 'react';
import AcknowledgeAction from './AcknowledgeAction'
import * as actions from '../../../redux/actions/googleActions';


//add props from reducer here
const mapStateToProps = store =>
({


    userSignInData:               store.userServicesReducer.userSignInData,
    currentUserEmail:             store.userServicesReducer.currentUserEmail
});

//add actions here
const mapDispatchToProps = dispatch =>
({



});


class AcknowledgeActionContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{
        
	}
	
    render()
    {
    	return (
            <AcknowledgeAction 

            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AcknowledgeActionContainer);




