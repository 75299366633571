import { connect } from 'react-redux'

import PrivateRouteComponent from './PrivateRoute'


// import { reducer } from 'aws-cognito-redux-saga'
// import SignInComponent from './SignIn'
import * as actions from '../../../redux/actions/actionsUser';




const mapStatetoProps = state => {
  return {
    auth: state.auth
  }
}



const mapDispatchToProps = dispatch => {
  return {

    appSignIn:( currentUserEmail ) =>
    {
        dispatch( actions.appSignIn( currentUserEmail ) )
    },
    // setUser:( email ) =>
    // {
    //     dispatch( actions.setUser( email ) )
    // }

  }
}




export default connect(mapStatetoProps, mapDispatchToProps)(
  PrivateRouteComponent
)
















// import { connect } from 'react-redux'

// import PrivateRouteComponent from './PrivateRoute'

// const mapStatetoProps = state => {
//   return {
//     auth: state.auth
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {}
// }

// export default connect(mapStatetoProps, mapDispatchToProps)(
//   PrivateRouteComponent
// )
