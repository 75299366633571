import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
// import { ResponsiveBar } from '@nivo/bar'


import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import {withRouter} from 'react-router-dom';
import {
  ResponsiveContainer,
} from 'recharts';


  class AlertKpiTrend extends PureComponent {
    constructor( props )
    {
      super( props );
    }


    // onClick = () => {
    //   const { index, key, payload } = this.props;
    //   // you can do anything with the key/payload


    // }


    onClick = (x) => {


      // this.props.getShipmentTrackerData( this.props.currentUserEmail, null, null, "'" + data.label + "'")

      // this.props.history.push({
      //   pathname: 'home/shipmenttrackerfiltered'
      //   }); 

      if(x === 'kpi') {
      this.props.history.push({
        pathname: 'google/kpi'
        }); 

      }
      else if(x === 'milestone'){
      this.props.history.push({
        pathname: 'google/milestonealerts'
        }); 

      }


    }

    render() {

      let milestoneTrend;
      if (this.props.isAlertKpiTrendLoading) {
        milestoneTrend=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Milestone Alert and KPI Trend'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        
      }
      else if (typeof(this.props.alertKpiTrendData) !== 'undefined' || this.props.alertKpiTrendData != null) {
        milestoneTrend=
        <section className="panel">
        <header className="panel-heading">
          {'Milestone Alert and KPI Trend'}
        </header>
        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
          <LineChart //width={730} height={250} 
                  data={ this.props.alertKpiTrendData }
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Milestone Alerts" stroke="#413ea0" strokeWidth={2} activeDot={{ onClick: () => this.onClick( 'milestone') }}/>
                  <Line type="monotone" dataKey="Missed KPI" stroke="#82ca9d" strokeWidth={2} activeDot={{ onClick: () => this.onClick( 'kpi') }}/>
              </LineChart>
        </ResponsiveContainer>
        </div>
        </div>
            </section>
      }


      return(

        <div>
          { milestoneTrend }
        </div>
        
      );
    }
  }
  
  export default withRouter(AlertKpiTrend);
  // export default AlertKpiTrend;
  



// //ADD THIS TO KPI JOB:
// 	SELECT 
// 		j.JobID,
// 		'3' as [kpiID],
// 		j.[EstimatedDeliveryDate] AS [TargetDate],
// 		DATEDIFF(HH, j.[EstimatedDeliveryDate], GETDATE())/24.0 as [ActualValue], 
// 		CONCAT( ja.SF_CountryCode, '-', ja.ST_CountryCode) AS [Lane],
// 		CASE WHEN GETDATE() > j.[EstimatedDeliveryDate] THEN 0
// 			ELSE 1 END as [MetKPI]

// 	FROM [dbo].[Jobs] as j (nolock)
//     JOIN [dbo].[Jobs_Status] as e (nolock)
// 		ON e.[JobID] = j.[JobID]
// 	JOIN [dbo].[Jobs_Addresses] as ja (nolock)
// 		ON e.JobID = ja.JobID 
// 	WHERE 
// 		e.[Status] = 'O'
//         AND GETDATE() > j.[EstimatedDeliveryDate]

