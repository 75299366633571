import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import FilterMainZU         from '../../../components/ComponentsZU/FilterMainZU/FilterMainZU';
import HazMatZUContainer    from '../../../components/ComponentsZU/HazMatZU/HazMatContainerZU';

import {appSettings} from '../../../app/appSettings'


export default class zuHazMatView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        UNSAFE_componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>
                
                <FilterMainZU isMobile={isMobile}/>

                <AnimatedView>

                    <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 

                    <HazMatZUContainer isMobile={isMobile} />

                    </div>

                </AnimatedView>

            </div>

        );
    }
}
