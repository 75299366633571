import * as types from '../constants/googleActionTypes';


/* ################################### STAT CARD ROW ACTIONS ################################### */

export const getStatCardRowData = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.STAT_CARD_ROW_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );


/* ################################### GLOBAL FILTER ACTIONS ################################### */
// gets data for stat cards
export const setGlobalFilter = ( _mode, _lane, _forwarder, _service, _startDT, _endDT, _jobStatus ) =>
	( {
		type:    types.SET_GLOBAL_FILTER,
        payload: {
            mode: _mode,
            lane: _lane,
            forwarder: _forwarder,
            service: _service,
            startDT: _startDT,
            endDT: _endDT,
            jobStatus: _jobStatus
        }
    } );

 
// fills shipment tracker with in transit data from stat card click
export const getStatCardInTransit = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
( {
    type:    types.STAT_CARD_IN_TRANSIT_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );

// fills shipment tracker with hot shipment data from stat card click
export const getStatCardParam = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
( {
    type:    types.STAT_CARD_PARAM_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );




/* ################################### SHIPMENT SCHEDULE GRAPH ACTIONS ################################### */

export const getShipScheduleGraphData = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.SHIPMENT_SCHEDULE_GRAPH_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );
    
/* ################################### SHIPMENT HISTORY ACTIONS ################################### */
// gets data for shipment history
export const getHistoryData = ( _db, _email ) =>
	( {
		type:    types.SHIPMENT_HISTORY_DATA_GET,
        payload: {
            db: _db,
            email: _email
        }
    } );

/* ################################### SHIPMENT HISTORY BAR ACTIONS ################################### */
// gets data for shipment history
// export const getHistoryBarData = ( _db, _email, _mode, _forwarder, _service, _lane ) =>
// ( {
//     type:    types.SHIPMENT_HISTORY_BAR_DATA_GET,
//     payload: {
//         db: _db,
//         email: _email,
//         mode: _mode,
//         forwarder: _forwarder,
//         service: _service,
//         lane: _lane
//     }
// } );
export const getHistoryBarData = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.SHIPMENT_HISTORY_BAR_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );

/* ################################### NETWORK REPORTING ACTIONS ################################### */
// gets data for shipment history
export const getNetworkReportingData = ( _db, _email, _startDT, _endDT, _network, _measure, _mode ) =>
( {
    type:    types.NETWORK_REPORTING_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        network: _network,
        measure: _measure,
        mode: _mode
    }
} );



/* ################################### ALERT KPI TREND ACTIONS ################################### */
// gets data for shipment history
// export const getAlertKpiTrendData = ( _db, _email, _mode, _forwarder, _service, _lane ) =>
// ( {
//     type:    types.ALERT_KPI_TREND_DATA_GET,
//     payload: {
//         db: _db,
//         email: _email,
//         mode: _mode,
//         forwarder: _forwarder,
//         service: _service,
//         lane: _lane
//     }
// } );


export const getAlertKpiTrendData = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.ALERT_KPI_TREND_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );



/* ################################### SHIPMENT PIE ACTIONS ################################### */
// gets data for shipment history
export const getShipmentPieData = ( _db, _email ) =>
	( {
		type:    types.SHIPMENT_PIE_DATA_GET,
        payload: {
            db: _db,
            email: _email
        }
    } );

/* ################################### MILESTONE ALERT ACTIONS ################################### */
// gets data for shipment history
// export const getMilestoneAlertData = ( _db, _email, _jobid, _housebill, _mode, _forwarder, _service, _lane ) =>
// ( {
//     type:    types.MILESTONE_ALERT_DATA_GET,
//     payload: {
//         db: _db,
//         email: _email,
//         jobid: _jobid,
//         housebill: _housebill,
//         mode: _mode,
//         forwarder: _forwarder,
//         service: _service,
//         lane: _lane
//     }
// } );   



export const getMilestoneAlertData = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.MILESTONE_ALERT_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );



// /* ################################### SHIPMENT DETAILS ACTIONS ################################### */
// // gets data for shipment history when details button clicked from shipment tracker
// export const getShipmentDetailsData = ( _db, _email, _jobid, _row, _housebill ) =>
// ( {
//     type:    types.SHIPMENT_DETAILS_DATA_GET,
//     payload: {
//         db: _db,
//         email: _email,
//         jobid: _jobid,
//         row: _row,
//         housebill: _housebill
//     }
// } );

/* ################################### SHIPMENT SEARCH ACTIONS ################################### */
// gets data for shipment history when search button clicked 
export const getShipmentSearchData = ( _db, _email, _housebill ) =>
( {
    type:    types.SHIPMENT_SEARCH_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill
    }
} );


/* ################################### POST SERVICE ISSUE ACTIONS ################################### */
// gets data for shipment history when search button clicked 
export const getOpenServiceIssues = ( _db, _email, _jobid, _housebill ) =>
( {
    type:    types.OPEN_SERVICE_ISSUES_GET,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        housebill: _housebill
    }
} );


/* ################################### POST SERVICE ISSUE ACTIONS ################################### */
// gets data for shipment history when search button clicked 
export const postServiceIssue = ( _db, _email, _jobid, _serviceissueid ) =>
( {
    type:    types.POST_SERVICE_ISSUE,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        serviceissueid: _serviceissueid
    }
} );

/* ################################### POST SERVICE ISSUE ACTIONS ################################### */
// gets data for shipment history when search button clicked 
export const postServiceIssueFromView = ( _db, _email, _jobid, _serviceissueid ) =>
( {
    type:    types.POST_SERVICE_ISSUE_FROM_VIEW,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        serviceissueid: _serviceissueid
    }
} );

/* ################################### KPI DATA ################################### */
// gets data for pivot table
export const getKpiOverviewData = ( _db, _email, _startDT, _endDT, _forwarder, _mode ) =>
( {
    type:    types.KPI_OVERVIEW_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        startDT: _startDT, 
        endDT: _endDT, 
        forwarder: _forwarder, 
        mode: _mode

    }
} );

/* ################################### SERVICE ISSUE KPI DATA ################################### */
// gets data for pivot table
export const getServiceIssueKpiData = ( _db, _email, _jobid, _housebill, _startDT, _endDT, _dateType, _mode, _forwarder, _service, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view) =>
( {
    type:    types.SERVICE_ISSUE_KPI_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        housebill: _housebill,
        startDT: _startDT, 
        endDT: _endDT, 
        dateType: _dateType,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        customerName: _customerName,
        customerBusUnit: _customerBusUnit, 
        projectCode: _projectCode,
        filter: _filter,
        filterValue: _filterValue,
        view: _view
    }
} );


/* ################################### CUSTOM REPORTING PIVOT TABLE DATA ################################### */
// gets data for pivot table
export const getPivotTableData = ( _db, _email, _startDT, _endDT, _dateType, _mode, _forwarder, _service, _lane ) =>
( {
    type:    types.PIVOT_TABLE_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType, 
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane
    }
} );


    
/* ################################### SHIPMENT Tracker ACTIONS ################################### */
// gets data for shipment tracker
export const getShipmentTrackerData = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view  ) =>
( {
    type:    types.SHIPMENT_TRACKER_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT,

        customerName: _customerName, 
        customerBusUnit: _customerBusUnit, 
        projectCode: _projectCode, 
        filter: _filter, 
        filterValue: _filterValue, 
        view: _view

    }
} );



// gets data for shipment tracker by JobID
export const getShipmentTrackerDataByJobID = ( _db, _email, _housebill ) =>
( {
    type:    types.SHIPMENT_TRACKER_JOBID_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill
    }
} );

// hide shipment tracker modal
export const hideShipmentTrackerModal = (  ) =>
( {
    type:    types.SHIPMENT_TRACKER_MODAL_HIDE,
    payload: null
} );

// show shipment tracker modal
export const showShipmentTrackerModalGetComments = ( _db, _email, _jobid, _row ) =>
( {
    type:    types.SHIPMENT_TRACKER_MODAL_SHOW_GET,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        row: _row
    }
} );

// post shipment comment
export const postShipModalComment = ( _db, _email, _jobid, _comment, _type ) =>
( {
    type:    types.SHIPMENT_MODAL_COMMENT_POST,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        comment: _comment,
        type: _type
    }
} );

// modal toggle hot shipment
export const modalToggleHotShip = ( _db, _email, _jobid, _bool ) =>
( {
    type:    types.SHIPMENT_MODAL_HOT_SHIP_POST,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        bool: _bool
    }
} );

// modal toggle internal action
export const modalToggleInternalAction = ( _db, _email, _jobid, _bool ) =>
( {
    type:    types.SHIPMENT_MODAL_INTERNAL_ACTION_POST,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        bool: _bool
    }
} );

// modal toggle external action
export const modalToggleExternalAction = ( _db, _email, _jobid, _bool ) =>
( {
    type:    types.SHIPMENT_MODAL_EXTERNAL_ACTION_POST,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        bool: _bool
    }
} );

export const shipmentSubscribe = ( _db, _email, _jobid, _active ) =>
( {
    type:    types.SHIPMENT_SUBSCRIBE_POST,
    payload: {
        db: _db,
        email: _email,
        jobid: _jobid,
        active: _active
    }
} );


/* ################################### SHIPMENT GPS ACTIONS ################################### */
// gets data for shipment tracker
export const getShipmentGPSData = ( _db, _email, _housebill, _jobid, _mode, _forwarder, _service, _lane, _param, _startDT, _endDT, _jobStatus ) =>
( {
    type:    types.SHIPMENT_GPS_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        jobid: _jobid,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        param: _param,
        startDT: _startDT,
        endDT: _endDT,
        jobStatus: _jobStatus
    }
} );



export const getChartDemurrage = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.DEMURRAGE_GO_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );

export const getChartPendingSailing = ( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT  ) =>
( {
    type:    types.PENDING_SAILING_GO_DATA_GET,
    payload: {
        db: _db,
        email: _email,
        housebill: _housebill,
        statCardInput: _statCardInput,
        alertMilestoneInput: _alertMilestoneInput,
        startDT: _startDT,
        endDT: _endDT,
        mode: _mode,
        forwarder: _forwarder,
        service: _service,
        lane: _lane,
        jobStatus: _jobStatus,
        jobid: _jobid,
        startActualDelDT: _startActualDelDT,
        endActualDelDT: _endActualDelDT
    }
} );