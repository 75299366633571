import React, {
    PureComponent
    }                                       from 'react';
import { Map, Marker, GoogleApiWrapper, InfoWindow, Polyline } from 'google-maps-react';

import {appConfig}                      from '../../../app/appConfig'
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import Button from 'react-bootstrap/Button' 
import ReactDOM from "react-dom";
// import Geocode from "react-geocode";



//         //https://www.npmjs.com/package/react-geocode
//         // // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
//         Geocode.setApiKey(appConfig.googleMapAPI.Map_API_Key);
        
//         // set response language. Defaults to english.
//         Geocode.setLanguage("en");
        
//         // set response region. Its optional.
//         // A Geocoding request with region=es (Spain) will return the Spanish city.
//         Geocode.setRegion("es");
        
//         // Enable or disable logs. Its optional.
//         Geocode.enableDebug();
        
//         // Get address from latidude & longitude.
//         Geocode.fromLatLng("48.8583701", "2.2922926").then(
//         response => {
//             const address = response.results[0].formatted_address;
//             console.log(address);
//         },
//         error => {
//             console.error(error);
//         }
//         );
        
//         // Get latidude & longitude from address.
//         Geocode.fromAddress("Eiffel Tower").then(
//         response => {
//             const { lat, lng } = response.results[0].geometry.location;
//             console.log(lat, lng);
//         },
//         error => {
//             console.error(error);
//         }
//         );






      class MapDestinationsGoogle extends PureComponent {
        constructor( props )
        {
          super( props );
        }
        state = {
          showingInfoWindow: false,
          activeMarker: {},
          selectedHouseBill: {},
          selectedCurrentShipStatus: {},
          selectedMode: {},
          selectedShipment: {}
        };




        // //https://medium.com/@sarahsweat/using-the-google-maps-api-with-react-9694a475f00a
        // componentWillReceiveProps(nextProps) {
        //     console.log("old props", this.props)
        //     console.log("new props", nextProps)

        //     if (nextProps.users.length > this.props.users.length){
        //         let zipcodes = nextProps.users.map(user => user.zip)
        //         console.log("next props > old props", zipcodes)
        //         zipcodes.forEach((code) => {
        //             fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${code}&key`)
        //             .then(data => data.json())
        //             .then(data => this.setState({
        //                 latLongs: [...this.state.latLongs, data.results[0].geometry.location]
        //             }))
        //         })
        //     }
        // }





        onClickMarkerNavigate(e, housebill) {
          this.props.history.push({
            pathname: '/home/google/shipmentdetails/',
            search: 'housebill=' + housebill
            })
        }






        render() {

        // // Get latidude & longitude from address.
        // Geocode.fromAddress("Eiffel Tower").then(
        //     response => {
        //         const { lat, lng } = response.results[0].geometry.location;
        //         console.log(lat, lng);
        //     },
        //     error => {
        //         console.error(error);
        //     }
        //     );


        //             // Get latidude & longitude from address.
        // Geocode.fromAddress("92109").then(
        //   response => {
        //       const { lat, lng } = response.results[0].geometry.location;
        //       console.log(lat, lng);
        //   },
        //   error => {
        //       console.error(error);
        //   }
        //   );

            



        const mapStyles = {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            };


        let mapComponent;
        if (this.props.isShipmentTrackerLoading) {
          mapComponent=
            <section className="panel">
            <header className="panel-heading">
              {'Shipment Map'}
            </header>
            <div align="center">
            <section className="panel">
              </section>
              <Loader 
                type="Plane"
                color="#00BFFF"
                height="50"	
                width="50"
              /> 
            </div>
            </section>

        }
        else if (typeof(this.props.shipmentTrackerData) !== 'undefined' && this.props.shipmentTrackerData != null) {
          if(this.props.shipmentTrackerData.length === 0) {
            mapComponent =
            <section className="panel"  >
            <header className="panel-heading">
              {'Shipment Map'}
            </header>
            <div className="panel-body table-responsive"
                style={{backgroundColor: '#FFF', height: '400px'}}>

                  <i className="fa fa-globe" style={{color:`${appConfig.colors.red}`}} title="No Location Data For This Shipment"> &nbsp;</i>
                  No Location Data For Shipment
                  
              <Map
                google={this.props.google}
                zoom={5}
                style={mapStyles}
                initialCenter={{ lat: 36.128, lng: -94.633}}
              >
              </Map>

              </div>
              </section>

          }

          else {
          mapComponent =
          <div >

          <section className="panel"  >
          <header className="panel-heading">
            {'Shipment Map'}
          </header>
            <Map
              google={this.props.google}
              zoom={2}
              style={mapStyles}
              ref="resultMap"
            //   onReady={(mapProps, map) => this.adjustDetailMap(mapProps, map, latestPoint)}
            > 
            {/* {this.displayMarkersDetailView()} */}
            <Polyline
            //   path={shipPathPoints}
              geodesic={false}
              options={{
                  strokeColor: "#ff2527",
                  strokeOpacity: 0.75,
                  strokeWeight: 2,
              }}
            />
            {/* <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
              onClose={this.onClose}
              onOpen={e => {
                this.onInfoWindowOpen(this.props, e);
              }}
            >
              {infoViewDetailsPage}
              <div id="iwc" />
            </InfoWindow> */}
            </Map>
            <div>
            </div>
          </section>
          </div>


          }
        }




    return(
    
            <div>
              {mapComponent}
            </div>
    
          );
          
        }
      }
      
    
      export default withRouter(GoogleApiWrapper({
        apiKey: appConfig.googleMapAPI.Map_API_Key
      })(MapDestinationsGoogle));
    
    
