import {connect} from 'react-redux';
import React, {Component} from 'react';
import MyProfile from './MyProfile'
import * as actions from '../../../redux/actions/actionsUser';
import { reducer } from 'aws-cognito-redux-saga'

//add props from reducer here
const mapStateToProps = store =>
({
    isUserAccountsLoading:          store.userServicesReducer.isUserAccountsLoading,
    userAccountData:                store.userServicesReducer.userAccountData,



    userSignInData:             store.userServicesReducer.userSignInData,
    currentUserEmail:             store.userServicesReducer.currentUserEmail
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getUserAccounts:( _email ) =>
    {
        dispatch( actions.getUserAccounts( _email ) )
    },

    postDeleteUser:(_email, _emailToDelete, _firstname, _lastname, _active, _admin, _pending ) =>
    {
        dispatch( actions.postDeleteUser( _email, _emailToDelete, _firstname, _lastname, _active, _admin, _pending ) )
    },

    //change this to AWS delete user account
    //look at HeaderContainer
    deleteUser: (username, password) => 
    {
        dispatch( reducer.signUp(username, password) )
    }

});

class MyProfileContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{
        
        this.props.getUserAccounts( this.props.currentUserEmail );
        
	}
	
    render()
    {
    	return (
            <MyProfile 
            isUserAccountsLoading={ this.props.isUserAccountsLoading }
            userAccountData={ this.props.userAccountData }
            languageSelection={this.props.languageSelection}
            postDeleteUser={ this.props.postDeleteUser }
            deleteUser={ this.props.deleteUser }
            userSignInData={ this.props.userSignInData }
            currentUserEmail={this.props.currentUserEmail}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyProfileContainer);




