import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';


import ReChartBarCustomColor from '../../ComponentsGeneral/ReCharts/ReChartBarCustomColor'

const barKeyArray = [
    {'barKey': 'Demurrage', 'stackID': 'a'},
    {'barKey': 'Free Time', 'stackID': 'a'}
    ]

  // const barKeyArray = [
  //   {'barKey': 'Demurrage', 'fill': '#f43e1f', 'stackID': 'a'},
  //   {'barKey': 'Free Time', 'fill': '#37ba30', 'stackID': 'a'}
  //   ]

    // fill={(data[2]['Days'] > 1  ) ? '#fff'  : '#ffff' } 

// const colorSelector = (
//       value,
//       trueValue,
//       index,
//       selectedIndex,
//   ) => {
//       if (trueValue >= 2000) {
//           return appColors.modalPositiveLargeValue
//       }
//       if (trueValue <= -2000) {
//           return appColors.modalNegativeLargeValue
//       }
//       if (index !== selectedIndex) {
//           return value > 0
//               ? appColors.modalPositiveGraphValue
//               : appColors.modalNegativeGraphValue
//       }
  
//       return value > 0
//           ? appColors.modalPositiveGraphValueSelected
//           : appColors.modalNegativeGraphValueSelected
//   }    

class ChartDemurrage extends PureComponent {
    constructor( props )
    {
      super( props );
    }


    onClickChart = (bar) => {

      this.props.history.push({
        pathname: 'google/shipmenttracker',
        search: 'startdt=' + null + '&' +
        'enddt=' + null + '&' +
        'datetype=' + null + '&' +
        'intransit=' + null + '&' +
        'mode=' + null + '&' +
        'carrier=' + null + '&' +
        'filter=' + 'demurrage' + '&' +
        'filterValue=' + bar.Days

        }); 
  
    }




    render() {


      

      let agingChart;
      if (this.props.isDemurrageDataLoading) {
        agingChart=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Demurrage Report'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      }  
      else if (typeof(this.props.DemurrageData) !== 'undefined' && this.props.DemurrageData != null) {
        // if( this.props.DemurrageData.length === 0 ){
        //   agingChart =
        //   <div></div>
        // }
        // else{

          agingChart =

              <section className="panel">
              <header className="panel-heading">
              {'Demurrage Report'}
              </header>

                <ReChartBarCustomColor 
                    data={this.props.DemurrageData} 
                    dataKey='Days'
                    barKeys= { barKeyArray }
                    xLegend='Days'
                    // yLegend='Count'

                    onBarClick={(bar, i) => this.onClickChart(bar)}
                    colorMaxThreshold='3'
                    colorMidThreshold='2'
                    colorMinThreshold='0'
                    colorMax='#f43e1f'
                    colorMid='#f1e15b'
                    colorMin='#37ba30'


                />

              </section>
        // }

      }

      return(
        <div>
            {agingChart}
        </div>
      );

    }
  }
  

  export default withRouter(ChartDemurrage);
  
