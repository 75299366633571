import React, {
    PureComponent
}                         from 'react';

import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import {appSettings} from '../../../app/appSettings'
import MyProfileContainer from '../../../components/ComponentsUser/MyProfile/MyProfileContainer';

class myProfileView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,
            languageSelection: 'English'

          };
      }

        componentDidMount() {

            this.getLocalStorageLanguageSelection()

        }

        getLocalStorageLanguageSelection() {
            let languageSelectionValue;
            try{
              languageSelectionValue = localStorage.getItem('PageConfig.UserSettings.Language');
              this.setState({
                languageSelection: languageSelectionValue
              })
            }
            catch(e){
              this.setState({
                languageSelection: "English"
              })
            }


      
          }
        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      
          
    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;




        return(
            <AnimatedView>

                <MyProfileContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>

            </AnimatedView>

        );
    }
}

export default myProfileView;
