import {connect} from 'react-redux';
import React, {Component} from 'react';
import DataExport from './DataExport'
import * as actions from '../../../redux/actions/googleActions';

//add props from reducer here
const mapStateToProps = store =>
({

    pivotTableData:                 store.reportingReducer.pivotTableData,
    isPivotTableLoading:            store.reportingReducer.isPivotTableLoading,
    

    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail
    
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getPivotTableData:( _db, _email, _startDT, _endDT, _dateType, _mode, _forwarder, _service, _lane ) =>
    {
        dispatch( actions.getPivotTableData( _db, _email, _startDT, _endDT, _dateType, _mode, _forwarder, _service, _lane ) )
    },





});


class DataExportContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	// componentDidMount()
	// {
    //     this.props.getShipmentTrackerData( this.props.currentUserEmail, null, null, null );
	// }
	
    render()
    {
    	return (
            <DataExport 
            pivotTableData={ this.props.pivotTableData }
            isPivotTableLoading={ this.props.isPivotTableLoading }
            
            userSignInData={ this.props.userSignInData }
            currentUserEmail={this.props.currentUserEmail}

            getPivotTableData={this.props.getPivotTableData}


            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DataExportContainer);




