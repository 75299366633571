
import React, {
    PureComponent
}                         from 'react';


import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';


import ShipmentTrackerContainer from '../../../components/ComponentsGoogle/ShipmentTracker/ShipmentTrackerContainer';
import FilterMainGoogle from '../../../components/ComponentsGoogle/FilterMainGoogle/FilterMainGoogle';

import {appSettings} from '../../../app/appSettings'

import MapDestinationsGoogleContainer from '../../../components/ComponentsGoogle/MapDestinationsGoogle/MapDestinationsGoogleContainer'
// import CustomTableContainer from '../../components/CustomTable/CustomTableContainer'; 
// import CustomTable from '../../components/CustomTable/CustomTable';


class shipmentTrackerView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

        //Mobile View
        handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
        };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
            }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }    


    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;


        return(
            <div>

            <FilterMainGoogle isMobile={isMobile}/>

            <AnimatedView>

                <ShipmentTrackerContainer />

                
                <div style = {{ height: '600px', width: '100%', position: 'relative', marginRight: '30px' , marginBottom: '40px'}} >
                    <MapDestinationsGoogleContainer isMobile={isMobile}/>
                </div>


            </AnimatedView>

            </div>


        );
    }
}

export default shipmentTrackerView;

