import {connect} from 'react-redux';
import React, {Component} from 'react';
import NetworkReporting from './NetworkReporting'
import * as actions from '../../../redux/actions/googleActions';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';


//add props from reducer here
const mapStateToProps = store =>
({

    networkReportData:              store.reportingReducer.networkReportData,
    isNetworkReportingLoading:      store.reportingReducer.isNetworkReportingLoading,
    

    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:               store.userServicesReducer.currentUserEmail
    
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getNetworkReportingData:( _db, _email, _startDT, _endDT, _network, _measure, _mode  ) =>
    {
        dispatch( actions.getNetworkReportingData( _db, _email, _startDT, _endDT, _network, _measure, _mode  ) )
    },

    getShipmentTrackerData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) =>
    {
        dispatch( actions.getShipmentTrackerData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) )
    },
    
});


class NetworkReportingContainer extends Component
{
	constructor(props)
	{
		super(props);
	}
	
	componentDidMount()
	{
        var paramStartDT = null
        var paramEndDT = null
        var paramMode = null
        var paramNetwork = null
        var paramMeasure = null


        //<PrivateRoute exact path="/home/reporting/:startdt?/:enddt?/:mode?/:network?/:measure?" component={reportingView} />
        const values = queryString.parse(this.props.location.search)


        if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
            paramStartDT = values.startdt
        }
        if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
            paramEndDT = values.enddt
        }
        if(typeof(values.mode) !== 'undefined' && values.mode != null ){
            paramMode = values.mode
        }
        if(typeof(values.network) !== 'undefined' && values.network != null ){
            paramNetwork = values.network
        }
        if(typeof(values.measure) !== 'undefined' && values.measure != null ){
            paramMeasure = values.measure
        }


        // this.props.getNetworkReportingData( this.props.currentUserEmail, null, null, null, null, null );

        this.props.getNetworkReportingData( this.props.userSignInData.DBName, this.props.currentUserEmail, paramStartDT, paramEndDT, paramNetwork, paramMeasure, paramMode );
        // this.props.getNetworkReportingData( this.props.currentUserEmail, null, null, null, null, null );
	}
	
    render()
    {
    	return (
            <NetworkReporting 
            networkReportData={ this.props.networkReportData }
            isNetworkReportingLoading={ this.props.isNetworkReportingLoading }
            
            userSignInData={ this.props.userSignInData }
            currentUserEmail={this.props.currentUserEmail}

            getNetworkReportingData={this.props.getNetworkReportingData}


            getShipmentTrackerData={this.props.getShipmentTrackerData}

            />
        )
    }
}

// export default connect(mapStateToProps, mapDispatchToProps)(NetworkReportingContainer);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(NetworkReportingContainer));





















































// import {connect} from 'react-redux';
// import React, {Component} from 'react';
// import NetworkReporting from './NetworkReporting'
// import * as actions from '../../../redux/actions/googleActions';

// //add props from reducer here
// const mapStateToProps = store =>
// ({

//     networkReportData:              store.reportingReducer.networkReportData,
//     isNetworkReportingLoading:      store.reportingReducer.isNetworkReportingLoading,
    

//     userSignInData:                 store.userServicesReducer.userSignInData,
//     currentUserEmail:               store.userServicesReducer.currentUserEmail
    
// });

// //add actions here
// const mapDispatchToProps = dispatch =>
// ({

//     getNetworkReportingData:( _email, _startDT, _endDT, _network, _measure, _mode  ) =>
//     {
//         dispatch( actions.getNetworkReportingData( _email, _startDT, _endDT, _network, _measure, _mode  ) )
//     },

//     getShipmentTrackerData:( _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
//     {
//         dispatch( actions.getShipmentTrackerData( _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
//     },
    



// });


// class NetworkReportingContainer extends Component
// {
// 	constructor(props)
// 	{
// 		super(props);
// 	}
	
// 	componentDidMount()
// 	{


//         // if(typeof(this.props.networkReportData[0]) !== 'undefined' && this.props.networkReportData[0] != null)
//         // {

//         // }

//         // if (this.props.networkReportData.length === 0){
//         //     this.props.getNetworkReportingData( this.props.currentUserEmail, null, null, null, null, null );
//         // }

//         this.props.getNetworkReportingData( this.props.currentUserEmail, null, null, null, null, null );
// 	}
	
//     render()
//     {
//     	return (
//             <NetworkReporting 
//             networkReportData={ this.props.networkReportData }
//             isNetworkReportingLoading={ this.props.isNetworkReportingLoading }
            
//             userSignInData={ this.props.userSignInData }
//             currentUserEmail={this.props.currentUserEmail}

//             getNetworkReportingData={this.props.getNetworkReportingData}


//             getShipmentTrackerData={this.props.getShipmentTrackerData}

//             />
//         )
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(NetworkReportingContainer);




