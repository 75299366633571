import React      from 'react';
import PropTypes  from 'prop-types';
import cx         from 'classnames';
import Menu       from './menu/Menu';
import Horloge    from './Horloge/Horloge';
import {appConfig} from '../../../../app/appConfig'
// import {navigationSelectionOptions} from '../../../../app/appSettings'
import Select from "react-dropdown-select"; 

import {navMenu} from '../../../../routes/MainRoutes'

const HomeMenu = ({
                       currentView,
                       sideMenu,
                       isAnimated,
                       isCollapsed,
                       isMobile,
                       dropDownOnChange,
                       admin, 
                       group, 
                       businessUnit,
                       navSelection,
                       languageSelection
                   }) => (
    <aside
        className={
            cx({
                'no-print':          true,
                'left-side':         true,
                'aside-left--fixed': true,
                'sidebar-offcanvas': true,
                'sidebar-animated':  isAnimated,
                'collapse-left':     isCollapsed
            })
        }
        // add overflow to left sidebar:
        style={{
            height:   '100%',
            overflow: 'auto',
            position: 'fixed',
            zIndex: '10000'
        }}
    >
        <section className="sidebar">
            <br/>

            <div className="left-header">

            <a href="#/home"
                >
                <img  src={appConfig.customerLogo}/>
            </a>

            </div>
            <br/>
            <Horloge />


            <div className="sm-st clearfix" style={{backgroundColor: '#3F414D', height: '100x'}}>
 
                    <div style={{backgroundColor: '#FFFFFF'}}> 
                        <Select 
                            options={ navMenu(admin, group, businessUnit) } 
                            // clearable
                            separator
                            placeholder={ navSelection }
                            onChange={ dropDownOnChange }
                            // defaultValue={{ label: navSelection, value: navSelection }}
                            // value={{ label: navSelection, value: navSelection }}
                            
                        />
                    </div>

            </div>


            {
                sideMenu.map(
                    ({id, group, menus}, menuIdx) => {

                        return (
                            <Menu
                                key={menuIdx}
                                initialCollapseState={false} //{menuIdx === 0 ? false : null}
                                headerTitle={group}
                                headerBackColor="#3f414d"
                                activeView={currentView}
                                views={menus}
                                id={id}
                            />
                        );
                    }
                )
            }


{/* 
<div className="sm-st clearfix" style={{backgroundColor: '#3F414D', height: '100x'}}>
 
 <div style={{backgroundColor: '#FFFFFF'}}> 
     <Select 
         options={ navMenu(admin, group, businessUnit) } 
         // clearable
         separator
         placeholder={ navSelection }
         onChange={ dropDownOnChange }
         // defaultValue={{ label: navSelection, value: navSelection }}
         // value={{ label: navSelection, value: navSelection }}
         
     />
 </div>

</div> */}
            
        </section>
    </aside>
);

HomeMenu.propTypes = {
    isAnimated:   PropTypes.bool,
    isCollapsed:  PropTypes.bool,
    sideMenu:     PropTypes.arrayOf(
        PropTypes.shape({
            id:     PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
            group:  PropTypes.string.isRequired,
            menus:  PropTypes.arrayOf(
                PropTypes.shape({
                    name:       PropTypes.string.isRequired,
                    linkTo:     PropTypes.string.isRequired,
                    faIconName: PropTypes.string.isRequired
                })
            )
        })
    ).isRequired,
    currentView:      PropTypes.string,
    isMobile:         PropTypes.bool,
    dropDownOnChange: PropTypes.func,
    admin:                  PropTypes.bool,     //if user has admin rights
    group:                  PropTypes.string,   //this is organizaton (Omni, google)
    businessUnit:           PropTypes.string,   //this is role in organization (operations, sales)
    navSelection:           PropTypes.string,
    languageSelection:      PropTypes.string

};

HomeMenu.defaultProps = {
    isAnimated:   false,
    isCollapsed:  false
};

export default HomeMenu;





// <SearchForm

//                 // this.props.history.push({
//                 //         pathname: 'home/shipmenttrackerdetails',
//                 //         state: {
//                 //           details: data
//                 //         }
//                 //       });

                
//                 //   onSearchSubmit={(value) => {
//                 //     this.props.history.push({
//                 //     pathname: 'home/searchresults',
//                 //     state: {
//                 //         details: value
//                 //     }
//                 //     });
//                 // }}

//             />
