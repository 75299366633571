/* ################################### SIGNOUT ACTIONS TYPES ################################### */
export const RESET_REDUX_STATE = 'RESET_REDUX_STATE';



/* ################################### CHARTS ACTIONS TYPES ################################### */
export const GB_MAWB_DETAILS_DATA_GET = 'GB_MAWB_DETAILS_DATA_GET';
export const GB_MAWB_DETAILS_DATA_COMPLETE = 'GB_MAWB_DETAILS_DATA_COMPLETE';
export const GB_MAWB_DETAILS_DATA_ERROR = 'GB_MAWB_DETAILS_DATA_ERROR';


export const GB_OTD_TO_MC_DATA_GET = 'GB_OTD_TO_MC_DATA_GET';
export const GB_OTD_TO_MC_DATA_COMPLETE = 'GB_OTD_TO_MC_DATA_COMPLETE';
export const GB_OTD_TO_MC_DATA_ERROR = 'GB_OTD_TO_MC_DATA_ERROR';

export const GB_OTD_TO_FLIGHT_DEPART_DATA_GET = 'GB_OTD_TO_FLIGHT_DEPART_DATA_GET';
export const GB_OTD_TO_FLIGHT_DEPART_DATA_COMPLETE = 'GB_OTD_TO_FLIGHT_DEPART_DATA_COMPLETE';
export const GB_OTD_TO_FLIGHT_DEPART_DATA_ERROR = 'GB_OTD_TO_FLIGHT_DEPART_DATA_ERROR';

export const GB_OTD_TO_FLIGHT_ARRIVAL_DATA_GET = 'GB_OTD_TO_FLIGHT_ARRIVAL_DATA_GET';
export const GB_OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE = 'GB_OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE';
export const GB_OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR = 'GB_OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR';

export const GB_AGING_CHART_DATA_GET = 'GB_AGING_CHART_DATA_GET';
export const GB_AGING_CHART_DATA_COMPLETE = 'GB_AGING_CHART_DATA_COMPLETE';
export const GB_AGING_CHART_DATA_ERROR = 'GB_AGING_CHART_DATA_ERROR';

export const GB_DIM_WEIGHT_CHART_DATA_GET = 'GB_DIM_WEIGHT_CHART_DATA_GET';
export const GB_DIM_WEIGHT_CHART_DATA_COMPLETE = 'GB_DIM_WEIGHT_CHART_DATA_COMPLETE';
export const GB_DIM_WEIGHT_CHART_DATA_ERROR = 'GB_DIM_WEIGHT_CHART_DATA_ERROR';

export const GB_STAT_CARD_DATA_GET = 'GB_STAT_CARD_DATA_GET';
export const GB_STAT_CARD_DATA_COMPLETE = 'GB_STAT_CARD_DATA_COMPLETE';
export const GB_STAT_CARD_DATA_ERROR = 'GB_STAT_CARD_DATA_ERROR';

export const GB_OTD_TO_THREE_AVGS_DATA_GET = 'GB_OTD_TO_THREE_AVGS_DATA_GET';
export const GB_OTD_TO_THREE_AVGS_DATA_COMPLETE = 'GB_OTD_TO_THREE_AVGS_DATA_COMPLETE';
export const GB_OTD_TO_THREE_AVGS_DATA_ERROR = 'GB_OTD_TO_THREE_AVGS_DATA_ERROR';

export const GB_GB_MAWB_DETAILS_DATA_GET = 'GB_GB_MAWB_DETAILS_DATA_GET';
export const GB_GB_MAWB_DETAILS_DATA_COMPLETE = 'GB_GB_MAWB_DETAILS_DATA_COMPLETE';
export const GB_GB_MAWB_DETAILS_DATA_ERROR = 'GB_GB_MAWB_DETAILS_DATA_ERROR';

export const GB_MC_DETAILS_DATA_GET = 'GB_MC_DETAILS_DATA_GET';
export const GB_MC_DETAILS_DATA_COMPLETE = 'GB_MC_DETAILS_DATA_COMPLETE';
export const GB_MC_DETAILS_DATA_ERROR = 'GB_MC_DETAILS_DATA_ERROR';

export const GB_HAWB_DETAILS_DATA_GET = 'GB_HAWB_DETAILS_DATA_GET';
export const GB_HAWB_DETAILS_DATA_COMPLETE = 'GB_HAWB_DETAILS_DATA_COMPLETE';
export const GB_HAWB_DETAILS_DATA_ERROR = 'GB_HAWB_DETAILS_DATA_ERROR';

export const GB_HAWB_MC_DETAILS_DATA_GET = 'GB_HAWB_MC_DETAILS_DATA_GET';
export const GB_HAWB_MC_DETAILS_DATA_COMPLETE = 'GB_HAWB_MC_DETAILS_DATA_COMPLETE';
export const GB_HAWB_MC_DETAILS_DATA_ERROR = 'GB_HAWB_MC_DETAILS_DATA_ERROR';

export const GB_JOB_EVENTS_DATA_GET = 'GB_JOB_EVENTS_DATA_GET';
export const GB_JOB_EVENTS_DATA_COMPLETE = 'GB_JOB_EVENTS_DATA_COMPLETE';
export const GB_JOB_EVENTS_DATA_ERROR = 'GB_JOB_EVENTS_DATA_ERROR';

export const GB_IBC_DTL_DATA_GET = 'GB_IBC_DTL_DATA_GET';
export const GB_IBC_DTL_DATA_COMPLETE = 'GB_IBC_DTL_DATA_COMPLETE';
export const GB_IBC_DTL_DATA_ERROR = 'GB_IBC_DTL_DATA_ERROR';

export const GB_HAZ_MAT_DATA_GET = 'GB_HAZ_MAT_DATA_GET';
export const GB_HAZ_MAT_DATA_COMPLETE = 'GB_HAZ_MAT_DATA_COMPLETE';
export const GB_HAZ_MAT_DATA_ERROR = 'GB_HAZ_MAT_DATA_ERROR';

export const GB_PENDING_SAILING_CHART_DATA_GET = 'GB_PENDING_SAILING_CHART_DATA_GET';
export const GB_PENDING_SAILING_CHART_DATA_COMPLETE = 'GB_PENDING_SAILING_CHART_DATA_COMPLETE';
export const GB_PENDING_SAILING_CHART_DATA_ERROR = 'GB_PENDING_SAILING_CHART_DATA_ERROR';

export const GB_DEMURRAGE_CHART_DATA_GET = 'GB_DEMURRAGE_CHART_DATA_GET';
export const GB_DEMURRAGE_CHART_DATA_COMPLETE = 'GB_DEMURRAGE_CHART_DATA_COMPLETE';
export const GB_DEMURRAGE_CHART_DATA_ERROR = 'GB_DEMURRAGE_CHART_DATA_ERROR';

export const GB_UPS_MI_DATA_GET = 'GB_UPS_MI_DATA_GET';
export const GB_UPS_MI_DATA_COMPLETE = 'GB_UPS_MI_DATA_COMPLETE';
export const GB_UPS_MI_DATA_ERROR = 'GB_UPS_MI_DATA_ERROR';

export const GB_DAILY_SHIP_PORTS_DATA_GET = 'GB_DAILY_SHIP_PORTS_DATA_GET';
export const GB_DAILY_SHIP_PORTS_DATA_COMPLETE = 'GB_DAILY_SHIP_PORTS_DATA_COMPLETE';
export const GB_DAILY_SHIP_PORTS_DATA_ERROR = 'GB_DAILY_SHIP_PORTS_DATA_ERROR';

export const GB_AVG_CUST_CLEAR_DATA_GET = 'GB_AVG_CUST_CLEAR_DATA_GET';
export const GB_AVG_CUST_CLEAR_DATA_COMPLETE = 'GB_AVG_CUST_CLEAR_DATA_COMPLETE';
export const GB_AVG_CUST_CLEAR_DATA_ERROR = 'GB_AVG_CUST_CLEAR_DATA_ERROR';



export const GB_CHART_TRANS_METRICS_GET = 'GB_CHART_TRANS_METRICS_GET';
export const GB_CHART_TRANS_METRICS_COMPLETE = 'GB_CHART_TRANS_METRICS_COMPLETE';
export const GB_CHART_TRANS_METRICS_ERROR = 'GB_CHART_TRANS_METRICS_ERROR';

export const GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_GET = 'GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_GET';
export const GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_COMPLETE = 'GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_COMPLETE';
export const GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_ERROR = 'GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_ERROR';

export const GB_HAWB_REPORTING_DATA_GET = 'GB_HAWB_REPORTING_DATA_GET';
export const GB_HAWB_REPORTING_DATA_COMPLETE = 'GB_HAWB_REPORTING_DATA_COMPLETE';
export const GB_HAWB_REPORTING_DATA_ERROR = 'GB_HAWB_REPORTING_DATA_ERROR';

export const GB_DAY_OF_WEEK_METRICS_GET = 'GB_DAY_OF_WEEK_METRICS_GET';
export const GB_DAY_OF_WEEK_METRICS_COMPLETE = 'GB_DAY_OF_WEEK_METRICS_COMPLETE';
export const GB_DAY_OF_WEEK_METRICS_ERROR = 'GB_DAY_OF_WEEK_METRICS_ERROR';

export const GB_DATA_EXPORT_GET = 'GB_DATA_EXPORT_GET';
export const GB_DATA_EXPORT_COMPLETE = 'GB_DATA_EXPORT_COMPLETE';
export const GB_DATA_EXPORT_ERROR = 'GB_DATA_EXPORT_ERROR';

export const GB_RESET_DATA_EXPORT = 'GB_RESET_DATA_EXPORT';

export const GB_CHART_DATA_EXPORT_GET = 'GB_CHART_DATA_EXPORT_GET';
export const GB_CHART_DATA_EXPORT_COMPLETE = 'GB_CHART_DATA_EXPORT_COMPLETE';
export const GB_CHART_DATA_EXPORT_ERROR = 'GB_CHART_DATA_EXPORT_ERROR';

export const GB_CHART_DETAIL_DATA_EXPORT_GET = 'GB_CHART_DETAIL_DATA_EXPORT_GET';
export const GB_CHART_DETAIL_DATA_EXPORT_COMPLETE = 'GB_CHART_DETAIL_DATA_EXPORT_COMPLETE';
export const GB_CHART_DETAIL_DATA_EXPORT_ERROR = 'GB_CHART_DETAIL_DATA_EXPORT_ERROR';


export const GB_FIRST_SCAN_TO_DELIVERY_METRIC_GET = 'GB_FIRST_SCAN_TO_DELIVERY_METRIC_GET';
export const GB_FIRST_SCAN_TO_DELIVERY_METRIC_COMPLETE = 'GB_FIRST_SCAN_TO_DELIVERY_METRIC_COMPLETE';
export const GB_FIRST_SCAN_TO_DELIVERY_METRIC_ERROR = 'GB_FIRST_SCAN_TO_DELIVERY_METRIC_ERROR';


export const GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_GET = 'GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_GET';
export const GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_COMPLETE = 'GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_COMPLETE';
export const GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_ERROR = 'GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_ERROR';


export const GB_FIRST_SCAN_TO_RECOVERY_METRIC_GET = 'GB_FIRST_SCAN_TO_RECOVERY_METRIC_GET';
export const GB_FIRST_SCAN_TO_RECOVERY_METRIC_COMPLETE = 'GB_FIRST_SCAN_TO_RECOVERY_METRIC_COMPLETE';
export const GB_FIRST_SCAN_TO_RECOVERY_METRIC_ERROR = 'GB_FIRST_SCAN_TO_RECOVERY_METRIC_ERROR';


export const GB_MANIFEST_TO_DELIVERY_METRIC_GET = 'GB_MANIFEST_TO_DELIVERY_METRIC_GET';
export const GB_MANIFEST_TO_DELIVERY_METRIC_COMPLETE = 'GB_MANIFEST_TO_DELIVERY_METRIC_COMPLETE';
export const GB_MANIFEST_TO_DELIVERY_METRIC_ERROR = 'GB_MANIFEST_TO_DELIVERY_METRIC_ERROR';

export const GB_CHART_MANIFEST_TO_DELIVERY_GET = 'GB_CHART_MANIFEST_TO_DELIVERY_GET';
export const GB_CHART_MANIFEST_TO_DELIVERY_COMPLETE = 'GB_CHART_MANIFEST_TO_DELIVERY_COMPLETE';
export const GB_CHART_MANIFEST_TO_DELIVERY_ERROR = 'GB_CHART_MANIFEST_TO_DELIVERY_ERROR';

export const GB_DATA_REPORTS_EXPORT_GET = 'GB_DATA_REPORTS_EXPORT_GET';
export const GB_DATA_REPORTS_EXPORT_COMPLETE = 'GB_DATA_REPORTS_EXPORT_COMPLETE';
export const GB_DATA_REPORTS_EXPORT_ERROR = 'GB_DATA_REPORTS_EXPORT_ERROR';

export const GB_MISSING_UPS_EVENTS_GET = 'GB_MISSING_UPS_EVENTS_GET';
export const GB_MISSING_UPS_EVENTS_COMPLETE = 'GB_MISSING_UPS_EVENTS_COMPLETE';
export const GB_MISSING_UPS_EVENTS_ERROR = 'GB_MISSING_UPS_EVENTS_ERROR';

export const GB_CHART_INWHS_TO_DELIVERY_GET = 'GB_CHART_INWHS_TO_DELIVERY_GET';
export const GB_CHART_INWHS_TO_DELIVERY_COMPLETE = 'GB_CHART_INWHS_TO_DELIVERY_COMPLETE';
export const GB_CHART_INWHS_TO_DELIVERY_ERROR = 'GB_CHART_INWHS_TO_DELIVERY_ERROR';

export const GB_CHART_INWHS_TO_FIRST_SCAN_GET = 'GB_CHART_INWHS_TO_FIRST_SCAN_GET';
export const GB_CHART_INWHS_TO_FIRST_SCAN_COMPLETE = 'GB_CHART_INWHS_TO_FIRST_SCAN_COMPLETE';
export const GB_CHART_INWHS_TO_FIRST_SCAN_ERROR = 'GB_CHART_INWHS_TO_FIRST_SCAN_ERROR';

export const GB_CHART_DAILY_SCAN_COUNT_GET = 'GB_CHART_DAILY_SCAN_COUNT_GET';
export const GB_CHART_DAILY_SCAN_COUNT_COMPLETE = 'GB_CHART_DAILY_SCAN_COUNT_COMPLETE';
export const GB_CHART_DAILY_SCAN_COUNT_ERROR = 'GB_CHART_DAILY_SCAN_COUNT_ERROR';

export const GB_CHART_INWHS_TO_FLIGHT_DEPART_GET = 'GB_CHART_INWHS_TO_FLIGHT_DEPART_GET';
export const GB_CHART_INWHS_TO_FLIGHT_DEPART_COMPLETE = 'GB_CHART_INWHS_TO_FLIGHT_DEPART_COMPLETE';
export const GB_CHART_INWHS_TO_FLIGHT_DEPART_ERROR = 'GB_CHART_INWHS_TO_FLIGHT_DEPART_ERROR';

export const GB_CHART_INWHS_TO_FREIGHT_RECOVERY_GET = 'GB_CHART_INWHS_TO_FREIGHT_RECOVERY_GET';
export const GB_CHART_INWHS_TO_FREIGHT_RECOVERY_COMPLETE = 'GB_CHART_INWHS_TO_FREIGHT_RECOVERY_COMPLETE';
export const GB_CHART_INWHS_TO_FREIGHT_RECOVERY_ERROR = 'GB_CHART_INWHS_TO_FREIGHT_RECOVERY_ERROR';

export const GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_GET = 'GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_GET';
export const GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_COMPLETE = 'GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_COMPLETE';
export const GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_ERROR = 'GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_ERROR';

export const GB_BULK_HAWB_DETAILS_GET = 'GB_BULK_HAWB_DETAILS_GET';
export const GB_BULK_HAWB_DETAILS_COMPLETE = 'GB_BULK_HAWB_DETAILS_COMPLETE';
export const GB_BULK_HAWB_DETAILS_ERROR = 'GB_BULK_HAWB_DETAILS_ERROR';




