import React                        from 'react';
import Button from 'react-bootstrap/Button' 
import {appConfig} from '../../app/appConfig'  
import {appSettings} from '../../app/appSettings'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { ConsoleLogger } from '@aws-amplify/core';
// import Select from "react-dropdown-select"; //https://sanusart.github.io/react-dropdown-select/

//dont highlight red if already delivered
  export default function deliveryDayFormatter(cell, row) {

    if(cell === null){
      return (
        <span> { cell } </span>
     )
    }
    else if (row.Status === 'C' && row.DeliveredLate == 1) {
      return (
        <span className="badge badge-danger"> Delivered Late </span>
      );
    }
    else if (row.Status === 'C' && row.DeliveredLate == 0) {
      return (
        <span className="badge badge-success"> Delivered On Time </span>
      );
    }
    else if (row.DeliveryDaysRemaining < 0) {
      return (
        <span className="badge badge-danger"> Late: { Math.abs(cell) }  Days</span>
      );
    }
    else if (row.DeliveryDaysRemaining > 2) {
      return (
        <span className="badge badge-success"> { cell }</span>
      );
    }
    else if ( row.DeliveryDaysRemaining <= 2 && row.DeliveryDaysRemaining >= 0)   {
      return (
        <span className="badge badge-warning"> { cell }</span>
      );
    }
    // else if ( cell == null )   {
    //   return (
    //     <span> { cell } </span>
    //   );
    // }
    else return (
      <span> { cell } </span>
   )

  } 




  export function valueDeliveryDayFormatter(value, status, delivered, actualdeliveryDT) {

        if (status === 'C' && delivered == 1) {
          return (
            <span className="badge badge-danger"> Delivered Late: {actualdeliveryDT} </span>
          )
        }
    
        else if (status === 'C' && delivered == 0) {
          return (
            <span className="badge badge-success"> Delivered On Time: {actualdeliveryDT} </span>
          )
        }
    
        else if (status === 'O' && value < 0) {
          return (
            <span className="badge badge-danger">   Late: { Math.abs(value) }  Days </span>
          );
        }
        else if (status === 'O' && value > 2) {
          return (
            <span className="badge badge-success"> { value }</span>
          );
        }
        else if (status === 'O' && value <= 2 && value >= 0) {
          return(
          <span className="badge badge-warning"> { value }</span>
        );
        }
        else return (
          <span> { value } </span>
        )
      } 

  
  export function shipmentProgressFormatter(cell, row) {

    if(cell === null){
      return
    }
    else if (cell < 25) {
      return (
        <ProgressBar variant="danger" animated now={cell} label={cell+`%`} />
      );
    }
    if (cell > 75) {
      return (
        <ProgressBar variant="success" animated now={cell} label={cell+`%`} />
      );
    }
    else return (
      <ProgressBar variant="warning" animated now={cell} label={cell+`%`} />
    );
  } 

  export function milestoneAlertSeverityFormatter(cell, row) {

    if(cell === null) {
      return
    }
    else if (row.Max_Alert_Category === 'Omni Red') {
      return (
        <span className="badge badge-danger"> { cell }</span>
      );
    }
    if (row.Max_Alert_Category === 'Omni Yellow') {
      return (
        <span className="badge badge-warning"> { cell }</span>
      );
    }
    if (row.Max_Alert_Category === 'Customer Red') {
      return (
        <span className="badge badge-danger"> { cell }</span>
      );
    }
    if (row.Max_Alert_Category === 'Customer Yellow') {
      return (
        <span className="badge badge-warning"> { cell }</span>
      );
    }
  } 

  export function milestoneCustomerAlertFormatter(cell, row) {

    if(cell === null){
      return
    }
    else if (row.Max_Customer_Alert === 'True') {
      return (
        <span className="badge badge-danger"> { cell }</span>
      );
    }
    if (row.Max_Customer_Alert === 'False') {
      return (
        <span className="badge badge-success"> { cell }</span>
      );
    }
  } 


  export function PercentFormatter(cell, row) {
          if(cell === null) {
            return (
              <span >  </span>
            );       
          }
          else if(cell >= 80) {
            return (
              <span className="badge badge-success"> { `${cell}%` }</span>
            );
          }
          else if(cell < 50) {
            return (
              <span className="badge badge-danger"> { `${cell}%` }</span>
            );
          }
          else {
            return (
              <span className="badge badge-warning"> { `${cell}%` }</span>
            );
          }
        }

  export function KPIValueFormatter(cell, row) {

    if(cell === null){
      return
    }
    else if (row.kpiID === 1 || row.kpiID === '1') {
      if (cell > 27) {
        return (
          <span className="badge badge-danger"> { `${cell} Days` }</span>
        );
      }
      else {
        return (
          <span className="badge badge-success"> { `${cell} Days` }</span>
          );
        } 
      }
 
    else if (row.kpiID === 2 || row.kpiID === '2') {
      if(cell >= 80) {
        return (
          <span className="badge badge-success"> { `${cell}%` }</span>
        );
      }
      else if(cell < 50) {
        return (
          <span className="badge badge-danger"> { `${cell}%` }</span>
        );
      }
      else {
        return (
          <span className="badge badge-warning"> { `${cell}%` }</span>
        );
      }
      }
  
    else if (row.kpiID === 3 || row.kpiID === '3') {
      if(cell >= 80) {
        return (
          <span className="badge badge-success"> { `${cell}%` }</span>
        );
      }
      else if(cell < 50) {
        return (
          <span className="badge badge-danger"> { `${cell}%` }</span>
        );
      }
      else {
        return (
          <span className="badge badge-warning"> { `${cell}%` }</span>
        );
      }
      }

    else if (row.kpiID === 4 || row.kpiID === '4') {
      if(cell >= 80) {
        return (
          <span className="badge badge-success"> { `${cell}%` }</span>
        );
      }
      else if(cell < 50) {
        return (
          <span className="badge badge-danger"> { `${cell}%` }</span>
        );
      }
      else {
        return (
          <span className="badge badge-warning"> { `${cell}%` }</span>
        );
      }
      }

      else if (row.kpiID === 5 || row.kpiID === '5' || row.kpiID === 6 || row.kpiID === '6') {
        if(cell >= 80) {
          return (
            <span className="badge badge-success"> { `${cell}%` }</span>
          );
        }
        else if(cell < 50) {
          return (
            <span className="badge badge-danger"> { `${cell}%` }</span>
          );
        }
        else {
          return (
            <span className="badge badge-warning"> { `${cell}%` }</span>
          );
        }
        }
    
    }


  //   if (row.kpiID = 1) {
  //     if (row.Value > 30) {
  //       return (
  //         <span className="badge badge-danger"> { `${cell} Days` }</span>
  //       );
  //     }
  //     else {
  //       return (
  //         <span className="badge badge-success"> { `${cell} Days` }</span>
  //       );
  //     }
  //   }

  //   else if (row.kpiID = 2) {
  //     if (row.Value > 90) {
  //       return (
  //         <span className="badge badge-success"> { `${cell}%`  }  </span>
  //       );
  //     }
  //     else if (row.Value > 75) {
  //       return (
  //         <span className="badge badge-info"> { `${cell}%` }  </span>
  //       );
  //     }
  //     else {
  //       return (
  //         <span className="badge badge-danger"> { `${cell}%` }</span>
  //       );
  //     }
  //   }

  //   else if (row.kpiID = 3) {
  //     if (row.Value > 90) {
  //       return (
  //         <span className="badge badge-success"> { `${cell}%`  }  </span>
  //       );
  //     }
  //     else if (row.Value > 75) {
  //       return (
  //         <span className="badge badge-info"> { `${cell}%` }  </span>
  //       );
  //     }
  //     else {
  //       return (
  //         <span className="badge badge-danger"> { `${cell}%` }</span>
  //       );
  //     }
  //   }

  //   else if (row.kpiID = 4) {
  //     if (row.Value > 90) {
  //       return (
  //         <span className="badge badge-success"> { `${cell}%`  }  </span>
  //       );
  //     }
  //     else if (row.Value > 75) {
  //       return (
  //         <span className="badge badge-info"> { `${cell}%` }  </span>
  //       );
  //     }
  //     else {
  //       return (
  //         <span className="badge badge-danger"> { `${cell}%` }</span>
  //       );
  //     }
  //   }


  // } 


  export function milestoneAlertDetailsSeverityFormatter(cell, row) {

    if(cell === null){
      return
    }
    else if (row.Alert_Category === 'Omni Red') {
      return (
        <span className="badge badge-danger"> { cell }</span>
      );
    }
    else if (row.Alert_Category === 'Omni Yellow') {
      return (
        <span className="badge badge-warning"> { cell }</span>
      );
    }
    else if (row.Alert_Category === 'Customer Red') {
      return (
        <span className="badge badge-danger"> { cell }</span>
      );
    }
    else if (row.Alert_Category === 'Customer Yellow') {
      return (
        <span className="badge badge-warning"> { cell }</span>
      );
    }
  } 
  

  export function shipmentAlertIconFormatter(cell, row) {
    var commentIcon
    var hotIcon 
    var internalIcon
    var externalIcon

    if (row.OmniYellowAlert === 1) {
      // return (
        hotIcon = <i className="fa fa-circle-o" style={{color:`${appConfig.colors.yellow}`}} title="Omni Yellow"> &nbsp;</i>
      // );
    }
    if (row.OmniRedAlert === 1) {
      // return (
        commentIcon = <i className="fa fa-circle-o" style={{color:`${appConfig.colors.red}`}} title="Omni Red"> &nbsp;</i>
      // );
    }
    if (row.CustomerYellowAlert === 1) {
      // return (
        internalIcon = <i className="fa fa-flag" style={{color:`${appConfig.colors.yellow}`}} title="Customer Yellow"> &nbsp;</i>
      // );
    }
    if (row.CustomerRedAlert === 1) {
      // return (
        externalIcon = <i className="fa fa-flag" style={{color:`${appConfig.colors.red}`}} title="Customer Red"> &nbsp;</i>
      // );
    }
    return (
      <div>
        {hotIcon}
        {commentIcon}
        {internalIcon}
        {externalIcon}
      </div>
    )

  } 

  export function shipDetailKPIFormatter(cell,row) {
    if (row.KPIMissed === false) {
      return (
      <div>
      <i className="fa fa-check-square-o" style={{color:`${appConfig.colors.green}`}} title="KPI's met"> &nbsp;</i>
      </div>
      )
    }

    else if (row.KPIMissed === true) {
      return (
      <div>
      <i className="fa fa-exclamation-circle" style={{color:`${appConfig.colors.red}`}} title="KPI's missed"> &nbsp;</i>
      </div>
      )
    }
  }

  export function shipmentTagFormatter(cell, row) {

    var subscribeIcon
    var commentIcon
    var hotIcon 
    var internalIcon
    var externalIcon


    if (row.Subscribed === 1) {
      // return (
        subscribeIcon = <i className="fa fa-envelope-open" style={{color:`${appConfig.colors.green}`}} title="Alerts Subscribed"> &nbsp;</i>
      // );
    }
    if (row.HotShipment === 1) {
      // return (
        hotIcon = <i className="fa fa-exclamation-triangle" style={{color:`${appConfig.colors.red}`}} title="Hot Shipment"> &nbsp;</i>
      // );
    }
    if (row.HasComment === 1) {
      // return (
        commentIcon = <i className="fa fa-comment" style={{color:`${appConfig.colors.violet}`}} title="Shipment has comments"> &nbsp;</i>
      // );
    }
    if (row.OmniAction === 1) {
      // return (
        internalIcon = <i className="fa fa-flag" style={{color:`${appConfig.colors.yellow}`}} title={appSettings.customer.Stat_Card_Internal}> &nbsp;</i>
      // );
    }
    if (row.GoogleAction === 1) {
      // return (
        externalIcon = <i className="fa fa-flag" style={{color:`${appConfig.colors.blue}`}} title={appSettings.customer.Stat_Card_External}> &nbsp;</i>
      // );
    }
    return (
      <div>
        {subscribeIcon}
        {hotIcon}
        {commentIcon}
        {internalIcon}
        {externalIcon}
      </div>
    )

  } 


  export function gbFlagFormatter(cell, row) {
    

    var heldIcon
    var carrierInductionIcon
    var deliveryAlertIcon
    // var commentIcon
    // var hotIcon 
    // var internalIcon
    // var externalIcon


    if (row.Held === 1) {
      // return (
        heldIcon = <i className="fa fa-stop-circle" style={{color:`${appConfig.colors.red}`}} title="Held"> &nbsp;</i>
      // );
    }
    if (row.Alert_Carrier_VS_DT === 1) {
      // return (
        deliveryAlertIcon = <i className="fa fa-exclamation-triangle" style={{color:`${appConfig.colors.red}`}} title="Carrier Delivery Alert"> &nbsp;</i>
      // );
    }
    if (row.Carrier_Induction_Alert_DT === 1) {
      // return (
        carrierInductionIcon = <i className="fa fa-bolt" style={{color:`${appConfig.colors.red}`}} title="Carrier Induction Alert"> &nbsp;</i>
      // );
    }

    // if (row.HotShipment === 1) {
    //   // return (
    //     hotIcon = <i className="fa fa-exclamation-triangle" style={{color:`${appConfig.colors.red}`}} title="Hot Shipment"> &nbsp;</i>
    //   // );
    // }
    // if (row.HasComment === 1) {
    //   // return (
    //     commentIcon = <i className="fa fa-comment" style={{color:`${appConfig.colors.violet}`}} title="Shipment has comments"> &nbsp;</i>
    //   // );
    // }
    // if (row.OmniAction === 1) {
    //   // return (
    //     internalIcon = <i className="fa fa-flag" style={{color:`${appConfig.colors.yellow}`}} title={appSettings.customer.Stat_Card_Internal}> &nbsp;</i>
    //   // );
    // }
    // if (row.GoogleAction === 1) {
    //   // return (
    //     externalIcon = <i className="fa fa-flag" style={{color:`${appConfig.colors.blue}`}} title={appSettings.customer.Stat_Card_External}> &nbsp;</i>
    //   // );
    // }
    return (
      <div>
        {heldIcon}
        {carrierInductionIcon}
        {deliveryAlertIcon}
      </div>
    )
  } 

  export function hotShipmentIcon(cell, row) {
    var hotIcon 

    if (row.HotShipment === 1) {
      // return (
        hotIcon = <i className="fa fa-exclamation-triangle" style={{color:'#F05050'}}></i>
      // );
    }
    return (
      <div>
        {hotIcon}
      </div>
    )

  } 

  export function lateDeliveryNumberFormatter(value) {
    if(value === null) {
      return
    }
    else if(value > 4) {
      return (
        <span className="badge badge-danger"> { value }</span>
      );
    }

    else if(value < 1 ) {
      return (
        <span className="badge badge-success"> { value }</span>
      );
    }
    else return (
      <span className="badge badge-warning"> { value }</span>
    );
  } 


  export function airTransitTimeFormatter(value) {
    if(value === null) {
      return
    }
    else if(value > 10) {
      return (
        <span className="badge badge-danger"> { value }</span>
      );
    }

    else if(value < 8 ) {
      return (
        <span className="badge badge-success"> { value }</span>
      );
    }
    else return (
      <span className="badge badge-warning"> { value }</span>
    );
  } 

  export function oceanTransitTimeFormatter(value) {
    if(value === null) {
      return
    }
    else if(value > 35) {
      return (
        <span className="badge badge-danger"> { value }</span>
      );
    }

    else if(value < 31 ) {
      return (
        <span className="badge badge-success"> { value }</span>
      );
    }
    else return (
      <span className="badge badge-warning"> { value }</span>
    );
  } 


  export function generalModeTransitTimeFormatter(value) {
    if(value === null) {
      return
    }
    else if(value > 35) {
      return (
        <span className="badge badge-danger"> { value }</span>
      );
    }

    else if(value < 8 ) {
      return (
        <span className="badge badge-success"> { value }</span>
      );
    }
    else return (
      <span className="badge badge-warning"> { value }</span>
    );
  } 

  export function buttonFormatter(e, cell, row){
    return <Button variant="secondary" size="sm" row={row}>Details</Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function mawbButtonFormatterMobile(e, cell, row){
    return <Button variant="secondary" size="sm" row={row} style={{  fontSize: '0.6rem'}} > {cell.MAWB} </Button>
    
  }

  export function afHawbButtonFormatter(e, cell, row){
    return <Button variant="secondary" size="sm" row={row} > {cell.AFHAWB} </Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function afHawbButtonFormatterMobile(e, cell, row){
    return <Button variant="secondary" size="sm" row={row} style={{  fontSize: '0.6rem'}} > {cell.AFHAWB} </Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function hawbButtonFormatter(e, cell, row){
    return <Button variant="secondary" size="sm" row={row} > {cell.HAWB} </Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function hawbButtonFormatterMobile(e, cell, row){
    return <Button variant="secondary" size="sm" row={row} style={{  fontSize: '0.6rem'}} > {cell.HAWB} </Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }



  export function modalButtonFormatter(e, cell, row){
    return <Button variant="warning" size="sm" row={row}>Action</Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function buttonApproveAccount(e, cell, row){
    return <Button variant="success" size="sm" row={row}>Approve</Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function buttonRejectAccount(e, cell, row){
    return <Button variant="danger" size="sm" row={row}>Reject</Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function buttonDeleteAccount(e, cell, row){
    return <Button variant="danger" size="sm" row={row}>Delete Account</Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }

  export function buttonChangePW(e, cell, row){
    return <Button variant="warning" size="sm" row={row}>Change Password</Button>
    
    //<BootstrapButton type="submit"></BootstrapButton>;
  }




export function userManagementAdmin(cell, row) {

  if (row.Admin === true ) {
    return (
      <span className="badge badge-danger"> Admin </span>
    );
  }
  else return (
    <span>  </span>
 )

} 

export function userManagementAdminMobile(cell, row) {

  if (row.Admin === true ) {
    return (
      <span className="badge badge-danger"> {cell} </span>
    );
  }
  else return (
    <span > {cell} </span>
 )

} 




export function trueOrFalse(cell, row) {
  if(cell === null) {
    return (
      <span >  </span>
    );       
  }
  else if(cell === true || cell === 1 ) {
    return (
 
      <i className="fa fa-check-square-o" style={{color:`${appConfig.colors.green}`}} title="True"> &nbsp;</i>
    );
  }
  else if(cell === false || cell === 0) {
    return (
 

      <i className="fa fa-square-o" style={{color:`${appConfig.colors.lightgrey}`}} title="False"> &nbsp;</i>
    );
  }

}








// export function actionModalFormatter(cell, row){
//     return (
//        <AttachmentManager row={row} />
//     );
// }


  

  // //doesn't work with formatter
  // export const vizFormats = {
  //   //shipment table columns
  //   columns : [{
  //     dataField: 'JobNumber',
  //     text: 'Job Number',
  //     sort: true
  //   }, {
  //     dataField: 'HouseBill',
  //     text: 'House Bill',
  //     sort: true
  //   }, {
  //     dataField: 'CurrentShipmentStatus',
  //     text: 'Current Shipment Status',
  //     sort: true
  //   }, {
  //     dataField: 'EstimatedDeliveryDate',
  //     text: 'Estimated Delivery Date',
  //     sort: true
  //   },{
  //     dataField: 'DeliveryDaysRemaining',
  //     text: 'Delivery Days Remaining',
  //     align: 'center',
  //     sort: true,
  //     formatter: deliveryDayFormatter
  //     }
  //   ]
  // };