import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import Select from "react-dropdown-select"; 

const DropDownSelectFilter = ({
    // defaultValue,
    dropDownArray,
    dropDownOnChange,
    dropDownPlaceholder,
    isMobile
    }) => (
        <div>
        {(() => {
            switch (isMobile) {
              case false:   return     (
                <div className="sm-st clearfix" style={{height: '100%'}}>
                    <div className="row">  
                        <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                            <Select 
                            options={ dropDownArray } 
                            clearable
                            separator
                            placeholder={ dropDownPlaceholder }
                            onChange={ dropDownOnChange }
                            />
                        </div>
                    </div>
                </div>
              );

              case true: return (
                <div className="sm-st clearfix" style={{height: '100x'}}>
                    <div className="row">  
                        <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                            <Select 
                            options={ dropDownArray } 
                            clearable
                            separator
                            placeholder={ dropDownPlaceholder }
                            onChange={ dropDownOnChange }
                            />
                        </div>
                    </div>
                </div>
              );
              default: return <div></div>;
            }
          })()}
        </div>







);

DropDownSelectFilter.propTypes = {
    // defaultValue:                   PropTypes.string,
    dropDownArray:                  PropTypes.array,
    dropDownOnChange:               PropTypes.func,
    dropDownPlaceholder:            PropTypes.string,
    isMobile:                       PropTypes.bool
};


export default DropDownSelectFilter;

