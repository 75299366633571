
////npm imports
import React, {
  PureComponent
}                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Modal from 'react-bootstrap/Modal'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import Button from 'react-bootstrap/Button'
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';

////local imports
import {
Table,
// TableBody
}                         from '../../ComponentsGeneral/table'
import {appConfig} from '../../../app/appConfig'
import {appSettings} from '../../../app/appSettings'
import deliveryDayFormatter from '../../../resources/logic/formatting';
import {//valueDeliveryDayFormatter, 
buttonFormatter, 
modalButtonFormatter, 
shipmentTagFormatter, 
shipmentAlertIconFormatter
// hotShipmentIcon
} from '../../../resources/logic/formatting';
import ModalFlagCard from '../Modals/ModalFlagCards';
import 'react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';


const shipCommentsColumns = [{
dataField: 'CommentDate',
text: 'Date',
headerStyle: (colum, colIndex) => {
  return { width: '25%' , textAlign: 'center' };
},
sort: true
}, {
dataField: 'User',
text: 'User',
headerStyle: (colum, colIndex) => {
  return { width: '30%' , textAlign: 'center' };
},
sort: true
}, {
dataField: 'GroupName',
text: 'Group',
headerStyle: (colum, colIndex) => {
  return { width: '15%' , textAlign: 'center' };
}
}, {
dataField: 'Comment',
text: 'Comment',
headerStyle: (colum, colIndex) => {
  return { width: '30%' , textAlign: 'center' };
}
}
]



class ShipmentTrackerParam extends PureComponent {

  constructor( props )
  {
    super( props );
  }

  state = {
    comment: '' //this is to stay in local state
  }; 

  //this is used locally only and state will remain within component and out of redux
  //this handles modal text input box
  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  }

  //this is to remain in local state
  //this handles modal comment text input box submit
  onSubmit = (e) => {
    e.preventDefault();
    // get our form data out of state
    const { comment } = this.state;

    if(comment.trim() === "") {
      window.alert("Comment is empty.");
      return
    }
    else {
      this.props.postShipModalComment( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID,  comment.trim(), 1);

      //this is local state for comment
      const newState = Object.assign({}, this.state, {
        comment: ""
      });
      // store the new state object in the component's state
      this.setState(newState);
    }
  }

  //prevent comment form input from submitting on enter key
  onKeyPress(event) {
    if (event.which === 13 /* Enter */) {
      event.preventDefault();
    }
  }


  render() {
    const { ExportCSVButton } = CSVExport;

    


    const shipmentTrackerColumnsOmni = [{
      dataField: 'HouseBill',
      text: 'House Bill',
      headerStyle: (colum, colIndex) => {
        return { width: '9%' , textAlign: 'center' };
      },
      sort: true
    }, {
      dataField: 'CurrentShipmentStatus',
      text: 'Current Status',
      headerStyle: (colum, colIndex) => {
        return { width: '22%' , textAlign: 'center' };
      },
      sort: true
    }, {
      dataField: 'EstimatedDeliveryDate',
      text: 'Est Delivery',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '10%' , textAlign: 'center' };
      },
      sort: true
    }, {
      dataField: 'Mode',
      text: 'Mode',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '8%' , textAlign: 'center' };
      },
      sort: true
    },{
    //   dataField: 'CustomerName',
    //   text: 'Customer',
    //   align: 'center',
    //   headerStyle: (colum, colIndex) => {
    //     return { width: '11%' , textAlign: 'center' };
    //   },
    //   sort: true
    // },{
      dataField: 'CustomerBusinessUnit',
      text: 'Business Unit',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '11%' , textAlign: 'center' };
      },
      sort: true
    },{
      dataField: 'DeliveryDaysRemaining',
      text: 'Days to Delivery',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '11%' , textAlign: 'center' };
      },
      formatter: deliveryDayFormatter,
      // filter: numberFilter()
    },
    {
      dataField: 'alts',
      text: 'Milestone Alerts',
      align: 'center',
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: '12%' , textAlign: 'center' };
      },
      formatter: shipmentAlertIconFormatter
    },
    {
      dataField: 'icons',
      text: 'Tags',
      align: 'center',
      sort: false,
      headerStyle: (colum, colIndex) => {
        return { width: '10%' , textAlign: 'center' };
      },
      formatter: shipmentTagFormatter
    },{
      dataField: 'buttonDetails',
      text: '',
      align: 'center',
      formatter: buttonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '8%', textAlign: 'center' };
        },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {   
          // todo: trigger details data load here  
          // var paramvalue = this.state.statedata.details.JobNumber;
          var paramjobid = row.JobID

          // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row, row.HouseBill);

          if(this.props.location.pathname === "/home/google/shipmenttracker") {
            // this.props.openShipDetails(paramjobid, row);
            this.props.history.push({
            pathname: 'shipmentdetails/',
            search: 'housebill=' + row.HouseBill
            }); 
          }

          else{
            this.props.history.push({
              pathname: '/home/google/shipmentdetails/',
              search: 'housebill=' + row.HouseBill
              }); 

          }
    
          ; }
        }
    },{
      dataField: 'buttonModal',
      text: '',
      align: 'center',
      formatter: modalButtonFormatter,
      headerStyle: (colum, colIndex) => {
        return { width: '7%', textAlign: 'center' };
        },
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => { 
          var paramvalue = "'" + row.JobID + "'";

          this.props.openShipModal(this.props.userSignInData.DBName, this.props.currentUserEmail, paramvalue, row);
        }
    }
    }
    ]

  



      const shipmentTrackerColumnsGoogle = [{
        dataField: 'HouseBill',
        text: 'House Bill',
        headerStyle: (colum, colIndex) => {
          return { width: '9%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'CurrentShipmentStatus',
        text: 'Current Status',
        headerStyle: (colum, colIndex) => {
          return { width: '22%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'EstimatedDeliveryDate',
        text: 'Estimated Delivery',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '13%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'Mode',
        text: 'Mode',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'DeliveryDaysRemaining',
        text: 'Days to Delivery',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '11%' , textAlign: 'center' };
        },
        formatter: deliveryDayFormatter,
        // filter: numberFilter()
      },
      {
        dataField: 'icons',
        text: 'Tags',
        align: 'center',
        sort: false,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        formatter: shipmentTagFormatter
      },{
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '8%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   
            // todo: trigger details data load here  
            // var paramvalue = this.state.statedata.details.JobNumber;
            var paramjobid = row.JobID

            // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row, row.HouseBill);

            if(this.props.location.pathname === "/home/google/shipmenttracker") {
              // this.props.openShipDetails(paramjobid, row);
              this.props.history.push({
              pathname: 'shipmentdetails/',
              search: 'housebill=' + row.HouseBill
              }); 
            }

            else{
              this.props.history.push({
                pathname: '/home/google/shipmentdetails/',
                search: 'housebill=' + row.HouseBill
                }); 

            }
      
            ; }
          }
      },{
        dataField: 'buttonModal',
        text: '',
        align: 'center',
        formatter: modalButtonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '7%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            var paramvalue = "'" + row.JobID + "'";


            this.props.openShipModal(this.props.userSignInData.DBName, this.props.currentUserEmail, paramvalue, row);
          }
      }
      }
      ]

    

    const {
      comment, //this is to stay in local state
    } = this.state;
    

    let bootStrapTable;
    if (this.props.isShipmentTrackerLoading) {
      bootStrapTable=
        <div align="center">
        <section className="panel">
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
    }
    else if (typeof(this.props.shipmentTrackerData) !== 'undefined' && this.props.shipmentTrackerData != null) {

      if( this.props.shipmentTrackerData[0].UniqueID === 'empty' ){
        bootStrapTable =

        <div align="center">
        <section className="panel">
          </section>
            No Results Found
        </div>

      }


      else if( this.props.userSignInData.GroupName ==='OMNI'  || this.props.userSignInData.GroupName ==='Demo' ){
        bootStrapTable =

        <ToolkitProvider
          keyField="UniqueID"
          data={ this.props.shipmentTrackerData }
          columns={ shipmentTrackerColumnsOmni }
          exportCSV
        >
          {
            props => (
              <div>
                <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                <hr />
                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } filter={ filterFactory() } />
              </div>
            )
          }
        </ToolkitProvider>

      }
      else if( this.props.userSignInData.GroupName ==='Google' ){
        bootStrapTable =

        <ToolkitProvider
          keyField="UniqueID"
          data={ this.props.shipmentTrackerData }
          columns={ shipmentTrackerColumnsGoogle }
          exportCSV
        >
          {
            props => (
              <div>
                <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                <hr />
                <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() } filter={ filterFactory() } />
              </div>
            )
          }
        </ToolkitProvider>

      }  

       



    } else {
      bootStrapTable = <div></div>;
    }


    let modal;
    //render modal only if show is true, otherwise do not render
    if (this.props.shipTrackModalShow === true) {

      modal = 
          <Modal
          size="lg"
          show={this.props.shipTrackModalShow}
          onHide={this.props.hideShipmentTrackerModal}
          style={{
            overlay: {
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              // backgroundColor: '#dcdcdc !important',
              zIndex: 1000
            },
            content: {
              position: 'absolute',
              top: '40px',
              left: '40px',
              right: '40px',
              bottom: '40px',
              border: '1px solid #ccc',
              // background: '#dcdcdc !important',
              overflow: 'auto',
              WebkitOverflowScrolling: 'touch',
              borderRadius: '4px',
              outline: 'none',
              padding: '20px'
            }
          }} 
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Shipment Action (House Bill {this.props.shipTrackModalRow.HouseBill})               
            </Modal.Title>
          </Modal.Header>              
          <Modal.Body style={{backgroundColor: '#dcdcdc'}}>
          <span>
          <div
          className="row"
          style={{marginBottom: '5px'}}>

            {(() => {
              switch (this.props.shipTrackModalRow.HotShipment) {
                case 1:   return     (
                  <div className="col-md-4" > 
                  <section className="panel">
                  <header className="panel-heading" style={{textAlign: 'center'}}>
                    Hot Shipment
                  </header>
                  <div className="panel-body table-responsive" title="Click to resolve Hot Shipment"
                  style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                    <div style={{align:'center'}} onClick={(e) => { 
                                    if (window.confirm('Are you sure you want to resolve this Hot Shipment?')) 
                                    this.props.modalToggleHotShip( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID, 0 ) } }
                                  > 
                    <ModalFlagCard 
                    color={appConfig.colors.red} 
                    icon={'fa fa-exclamation-triangle'}/>                                    
                      </div>
                  </div>
                  </section>
                  </div>
                );

                case 0: return (
                  <div className="col-md-4" > 
                  <section className="panel">
                  <header className="panel-heading" style={{textAlign: 'center', color: '#dcdcdc'}}>
                    Hot Shipment
                  </header>
                  <div className="panel-body table-responsive" title="Click to flag as Hot Shipment" 
                  style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                    <div style={{align:'center'}} onClick={(e) => { 
                                    if (window.confirm('Are you sure you want to make this a Hot Shipment?')) 
                                  this.props.modalToggleHotShip( this.props.userSignInData.DBName, this.props.currentUserEmail,this.props.shipTrackModalRow.JobID, 1 ) } }
                                  > 
                    <ModalFlagCard 
                    color={'#eee'} 
                    icon={'fa fa-exclamation-triangle'}/>                                    
                      </div>
                  </div>
                  </section>
                  </div>
                );
                default: return "";
              }
            })()}

            {(() => {
            switch (this.props.shipTrackModalRow.OmniAction) {
              case 1:   return     (
              <div className="col-md-4" > 
              <section className="panel">
              <header className="panel-heading" style={{textAlign: 'center'}}>
                {appSettings.customer.Stat_Card_Internal}
              </header>
              <div className="panel-body table-responsive" title={`Click to resolve ${appSettings.customer.Stat_Card_Internal}` }
              style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                <div style={{align:'center'}} onClick={(e) => { 
                                    if (window.confirm(`Are you sure you want to resolve this ${appSettings.customer.Stat_Card_Internal}?`)) 
                                    this.props.modalToggleInternalAction( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID, 0 ) } }
                                  > 
                <ModalFlagCard 
                color={appConfig.colors.yellow} 
                icon={'fa fa-flag'}/>                                    
                  </div>
              </div>
              </section>
              </div>
              );
              case 0: return (
                <div className="col-md-4" > 
                <section className="panel">
                <header className="panel-heading" style={{textAlign: 'center', color: '#dcdcdc'}}>
                  {appSettings.customer.Stat_Card_Internal}
                </header>
                <div className="panel-body table-responsive" title={`Click to flag as ${appSettings.customer.Stat_Card_Internal}` }
                style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                  <div style={{align:'center'}} onClick={(e) => { 
                                    if (window.confirm(`Are you sure you want to make this an ${appSettings.customer.Stat_Card_Internal}?`)) 
                                    this.props.modalToggleInternalAction( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID, 1 ) } }
                                  > 
                  <ModalFlagCard 
                  color={'#eee'} 
                  icon={'fa fa-flag'}/>                                    
                    </div>
                </div>
                </section>
                </div>
                );
              default: return "";
              }
            })()}

            {(() => {
            switch (this.props.shipTrackModalRow.GoogleAction) {
              case 1:   return     (
              <div className="col-md-4" > 
              <section className="panel">
              <header className="panel-heading" style={{textAlign: 'center'}}>
                {appSettings.customer.Stat_Card_External}
              </header>
              <div className="panel-body table-responsive" title={`Click to resolve ${appSettings.customer.Stat_Card_External}` }
              style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                <div style={{align:'center'}} onClick={(e) => { 
                                    if (window.confirm(`Are you sure you want to resolve this ${appSettings.customer.Stat_Card_External}?`)) 
                                    this.props.modalToggleExternalAction( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID, 0 ) } }
                                  > 
                <ModalFlagCard 
                color={appConfig.colors.blue} 
                icon={'fa fa-flag'}/>   
                </div>
              </div>
              </section>
              </div>
              );
              case 0: return (
                <div className="col-md-4" > 
                <section className="panel">
                <header className="panel-heading" style={{textAlign: 'center', color: '#dcdcdc'}}>
                  {appSettings.customer.Stat_Card_External}
                </header>
                <div className="panel-body table-responsive" title={`Click to flag as ${appSettings.customer.Stat_Card_External}` }
                style={{textAlign: 'center', backgroundColor: '#fff', cursor: 'pointer'}}>
                  <div style={{align:'center'}} onClick={(e) => { 
                                    if (window.confirm(`Are you sure you want to make this a ${appSettings.customer.Stat_Card_External}?`)) 
                                    this.props.modalToggleExternalAction( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipTrackModalRow.JobID, 1 ) } }
                                  > 
                  <ModalFlagCard 
                  color={'#eee'} 
                  icon={'fa fa-flag'}/>   
                  </div>
                </div>
                </section>
                </div>
                );
                default: return "";
                }
              })()}

            </div>
          </span>

          <form
            className="sidebar-form"
            onKeyPress={this.onKeyPress}
            >
            <div className="input-group">
              <input
                type="text"
                name="comment"
                className="form-control"
                placeholder="Add new comment..."
                value={comment}
                onChange={this.onChange}
              />
              <span className="input-group-btn">
                <button
                onClick={this.onSubmit}
                name="comment"
                id="comment-btn"
                title="Click to submit comment"
                className="btn btn-flat">
                  <i className="fa fa-plus-circle" style={{   
                        fontSize: '20px',
                        cursor: "pointer"
                        }}></i>
                </button>
              </span>
            </div>             
          </form>

          <section className="panel">
          <header className="panel-heading">
            {'Shipment Comments'}
          </header>
          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
          <Table>
            <BootstrapTable 
            keyField='CommentDate' 
            data={ this.props.shipTrackModalData } 
            columns={ shipCommentsColumns } 
            // expandRow={ expandRow }
            // rowEvents={ rowEvents }
            />
          </Table>
          </div>
          </section>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={ this.props.hideShipmentTrackerModal }>
              Close
            </Button>
          </Modal.Footer>
        </Modal>;
    } else {
      modal = <div></div>;
    }

    return(
        <section className="panel">
          <header className="panel-heading">
            {'Search Results'}
          </header>
          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'default', overflowX: 'hidden'}}>

            { bootStrapTable }

          </div>

          { modal }

        </section>

    );
  }
}

// export default StatusTableData;
export default withRouter(ShipmentTrackerParam);


// //original
//   <Table>
//   <BootstrapTable 
//   keyField='JobID' 
//   data={ this.props.shipmentTrackerData } 
//   columns={ shipmentTrackerColumns } 
//   pagination={ paginationFactory() }
//   // expandRow={ expandRow }
//   // rowEvents={ rowEvents }
//   />
// </Table>