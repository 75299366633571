import {connect} from 'react-redux';
import React, {Component} from 'react';
import GBDataExport from './GBDataExport'
import * as actions from '../../../redux/actions/gbActions';
import {appSettings} from '../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';



const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    isDataExportLoading:                store.gbDataReducer.isDataExportLoading,
    DataExport:                         store.gbDataReducer.DataExport,

    gbResetDataExport:                  store.gbDataReducer.gbResetDataExport,

    // isChartDataExportLoading:           store.gbDataReducer.isChartDataExportLoading,
    // ChartDataExport:                    store.gbDataReducer.ChartDataExport,

    // isChartDetailDataExportLoading:     store.gbDataReducer.isChartDetailDataExportLoading,
    // ChartDetailDataExport:              store.gbDataReducer.ChartDetailDataExport,

    isDataReportsExportLoading:         store.gbDataReducer.isDataReportsExportLoading,
    DataReportsExport:                  store.gbDataReducer.DataReportsExport,



    isBulkHawbDetailsLoading:               store.gbDataReducer.isBulkHawbDetailsLoading,
    // BulkHawbDetailsData:                    store.gbDataReducer.BulkHawbDetailsData,

    bulkMAWBData:                           store.gbDataReducer.bulkMAWBData,
    bulkPackageData:                        store.gbDataReducer.bulkPackageData,
    bulkMicomData:                          store.gbDataReducer.bulkMicomData,
    bulkUpsData:                            store.gbDataReducer.bulkUpsData



});

const mapDispatchToProps = dispatch =>
({

    gbGetDataExport:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    {
        dispatch( actions.gbGetDataExport( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) )
    },
    gbResetDataExport:(  ) =>
    {
        dispatch( actions.gbResetDataExport(  ) )
    },
    // gbGetChartDataExport:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    // {
    //     dispatch( actions.gbGetChartDataExport( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) )
    // },
    // gbGetChartDetailDataExport:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    // {
    //     dispatch( actions.gbGetChartDetailDataExport( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) )
    // },

    gbGetDataReportsExport:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    {
        dispatch( actions.gbGetDataReportsExport( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) )
    },

    gbGetBulkHawbDetails:( _db, _email, _trackingNoString ) =>
    {
        dispatch( actions.gbGetBulkHawbDetails( _db, _email, _trackingNoString ) )
    },


    
});


class GBDataExportContainer extends Component
{
	constructor(props)
	{
		super(props);
	}

    componentDidMount() {
        // this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.props.gbResetDataExport()
            // this.handleUrlParam();
        }
    }
    
    // handleUrlParam = () => {
    // var paramStartDT = null
    // var paramEndDT = null
    // var paramDateType = null
    // var paramNotDeparted = null
    // var paramPortExit = null
    // var paramPortEntry = null
    // var paramFinalCarrier = null

    // var paramMawb = null
    // var paramMC = null
    // var paramHawb = null
    // var paramFilter = null
    // var paramFilterValue = null
    // var paramCustomerCode = null

    // const values = queryString.parse(this.props.location.search)

    // if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
    //     paramStartDT = values.startdt
    // }
    // if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
    //     paramEndDT = values.enddt
    // }
    // if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
    //     paramDateType = values.datetype
    // }
    // if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
    //     paramNotDeparted = values.notdeparted
    // }
    // if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
    //     paramPortExit = values.portexit
    // }
    // if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
    //     paramPortEntry = values.portentry
    // }
    // if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
    //     paramFinalCarrier = values.finalcarrier
    // }

    // if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
    //     paramMawb = values.mawb
    // }
    // if(typeof(values.mc) !== 'undefined' && values.mc != null ){
    //     paramMC = values.mc
    // }
    // if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
    //     paramHawb = values.hawb
    // }
    // if(typeof(values.filter) !== 'undefined' && values.filter != null ){
    //     paramFilter = values.filter
    // }
    // if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
    //     paramFilterValue = values.filterValue
    // }
    // if(typeof(values.customercode) !== 'undefined' && values.customercode != null ){
    //     paramCustomerCode = values.customercode
    // }

    // this.props.gbGetDataExport( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, 'MAWB', null,  paramCustomerCode );
    // // this.props.gbGetChartDataExport( appSettings.gbDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramDateType, paramNotDeparted, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramMawb, paramMC, paramHawb, paramFilter, 'MAWB', null,  paramCustomerCode );

    // }

	
	
    render()
    {

    	return (

            <GBDataExport 

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                gbGetDataExport={this.props.gbGetDataExport}
                isDataExportLoading={this.props.isDataExportLoading}
                DataExport={this.props.DataExport}

                // gbGetChartDataExport={this.props.gbGetChartDataExport}
                // isChartDataExportLoading={this.props.isChartDataExportLoading}
                // ChartDataExport={this.props.ChartDataExport}

                // gbGetChartDetailDataExport={this.props.gbGetChartDetailDataExport}
                // isChartDetailDataExportLoading={this.props.isChartDetailDataExportLoading}
                // ChartDetailDataExport={this.props.ChartDetailDataExport}


                gbGetDataReportsExport={this.props.gbGetDataReportsExport}
                isDataReportsExportLoading={this.props.isDataReportsExportLoading}
                DataReportsExport={this.props.DataReportsExport}

                gbGetBulkHawbDetails={this.props.gbGetBulkHawbDetails}
                isBulkHawbDetailsLoading={this.props.isBulkHawbDetailsLoading}
                // BulkHawbDetailsData={this.props.BulkHawbDetailsData}

                bulkMAWBData={this.props.bulkMAWBData}
                bulkMicomData={this.props.bulkMicomData}
                bulkPackageData={this.props.bulkPackageData}
                bulkUpsData={this.props.bulkUpsData}


            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GBDataExportContainer));



