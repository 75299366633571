import {connect} from 'react-redux';
import React, {Component} from 'react';
import DtlHawbDetailsZU from './DtlHawbDetailsZU'
import * as actions from '../../../../redux/actions/zuActions';
import {appSettings} from '../../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';


const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    isIBCDtlDataLoading:                store.zuDataReducer.isIBCDtlDataLoading,
    IBCDtlData:                         store.zuDataReducer.IBCDtlData,

    isMawbDataLoading:                  store.zuDataReducer.isMawbDataLoading,
    MawbDetailData:                     store.zuDataReducer.MawbDetailData,

    isHawbDataLoading:                  store.zuDataReducer.isHawbDataLoading,
    HawbDetailData:                     store.zuDataReducer.HawbDetailData,

    isHawbMCDataLoading:                store.zuDataReducer.isHawbMCDataLoading,
    HawbMCDetailData:                   store.zuDataReducer.HawbMCDetailData,
    
    isUpsMiDataLoading:                 store.zuDataReducer.isUpsMiDataLoading,
    UpsMiData:                          store.zuDataReducer.UpsMiData,

    isJobEventsDataLoading:             store.zuDataReducer.isJobEventsDataLoading,
    JobEventsData:                      store.zuDataReducer.JobEventsData,

    isUpsNNScoreLoading:                store.zuDataReducer.isUpsNNScoreLoading,
    UpsNNScore:                         store.zuDataReducer.UpsNNScore,

});

const mapDispatchToProps = dispatch =>
({

    zuGetIBCInboundDtl:( _db, _email, _mawb, _mc, _hawb, _afhawb  ) =>
    {
        dispatch( actions.zuGetIBCInboundDtl( _db, _email,  _mawb, _mc, _hawb, _afhawb ) )
    },

    zuGetMawbDetails:( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _shipToCountry ) =>
    {
        dispatch( actions.zuGetMawbDetails( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter,_filterValue, _shipToCountry ) )
    },

    zuGetHawbDetails:( _db, _email, _mawb, _mc, _hawb, _trackingno ) =>
    {
        dispatch( actions.zuGetHawbDetails( _db, _email,  _mawb, _mc, _hawb, _trackingno ) )
    },

    zuGetHawbDetailsMC:( _db, _email, _mawb, _mc, _hawb, _trackingno ) =>
    {
        dispatch( actions.zuGetHawbDetailsMC( _db, _email,  _mawb, _mc, _hawb, _trackingno ) )
    },

    zuGetUpsMI:( _db, _email, _trackingno ) =>
    {
        dispatch( actions.zuGetUpsMI( _db, _email, _trackingno ) )
    },

    zuGetJobEvents:( _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter) =>
    {
        dispatch( actions.zuGetJobEvents(  _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter ) )
    },

    zuGetDSUPSNeuralNetScore:( _db, _email, _trackingno ) =>
    {
        dispatch( actions.zuGetDSUPSNeuralNetScore( _db, _email, _trackingno ) )
    },

    
});


class DtlHawbDetailsZUContainer extends Component
{
	constructor(props)
	{
		super(props);
	}

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null

    var paramMawb = null
    var paramMC = null
    var paramHawb = null
    var paramTrackingNo = null
    var paramFilter = null
    var paramFilterValue = null
    var paramShipToCountry = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }

    if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
        paramMawb = values.mawb
    }
    if(typeof(values.mc) !== 'undefined' && values.mc != null ){
        paramMC = values.mc
    }
    if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
        paramHawb = values.hawb
    }
    if(typeof(values.trackingno) !== 'undefined' && values.trackingno != null ){
        paramTrackingNo = values.trackingno
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }
    if(typeof(values.shiptocountry) !== 'undefined' && values.shiptocountry != null ){
        paramShipToCountry = values.shiptocountry
    }

    this.props.zuGetMawbDetails( appSettings.zuDB, this.props.currentUserEmail, null, null, null, null, null, null, null, null, paramTrackingNo, null, null, 'search', null, paramShipToCountry );
    this.props.zuGetHawbDetails( appSettings.zuDB, this.props.currentUserEmail, null, null, null, paramTrackingNo ); //need to search only using tracking no because proc returns different results for params
    this.props.zuGetHawbDetailsMC( appSettings.zuDB, this.props.currentUserEmail, null, paramMC, null, paramTrackingNo ); //need to search only using tracking no because proc returns different results for params
    this.props.zuGetIBCInboundDtl( appSettings.zuDB, this.props.currentUserEmail, null, null, paramTrackingNo, null );
    this.props.zuGetUpsMI( appSettings.zuDB, this.props.currentUserEmail, paramTrackingNo );
    this.props.zuGetJobEvents( appSettings.zuDB, this.props.currentUserEmail, paramStartDT, paramEndDT, paramMawb, paramMC, paramHawb, paramFilter );
    // this.props.zuGetDSUPSNeuralNetScore( appSettings.zuDB, this.props.currentUserEmail, paramTrackingNo );

    }

	
	
    render()
    {

    	return (

            <DtlHawbDetailsZU 

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isIBCDtlDataLoading={this.props.isIBCDtlDataLoading}
                IBCDtlData={this.props.IBCDtlData}

                isMawbDataLoading={this.props.isMawbDataLoading}
                MawbDetailData={this.props.MawbDetailData}

                isHawbDataLoading={this.props.isHawbDataLoading}
                HawbDetailData={this.props.HawbDetailData}
           
                isHawbMCDataLoading={this.props.isHawbMCDataLoading}
                HawbMCDetailData={this.props.HawbMCDetailData}

                isUpsMiDataLoading={this.props.isUpsMiDataLoading}
                UpsMiData={this.props.UpsMiData}

                isJobEventsDataLoading={this.props.isJobEventsDataLoading}
                JobEventsData={this.props.JobEventsData}

                isUpsNNScoreLoading={this.props.isUpsNNScoreLoading}
                UpsNNScore={this.props.UpsNNScore}
                    
            />   
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DtlHawbDetailsZUContainer));



