//TODO: update

////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import Select from "react-dropdown-select"; 
import {languageDropDownMenu} from '../../../app/languageSettings'
import DropDownSelectFilter from '../../ComponentsGeneral/Filters/DropDownSelectFilter/DropDownSelectFilter';


////local imports
import {
  Table
}                         from '../../ComponentsGeneral/table'
import {
    buttonDeleteAccount,
    buttonChangePW
} from '../../../resources/logic/formatting';


  class MyProfile extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    saveLanguageSelectionToLocalStorage(languageSelectionToSave) {
      try{
        localStorage.setItem('PageConfig.UserSettings.Language', languageSelectionToSave)
      }
      catch(e){
        console.log('error', e)
      }
    }

    dropDownOnChange(value) {
 

      try{
        localStorage.setItem('PageConfig.UserSettings.Language', value[0].value)
      }
      catch(e){
        console.log('error', e)
      }

      window.location.reload(); 
      
    }


    render() {

      const userAccountColumns = [{
        dataField: 'FirstName',
        text: 'First Name',
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'LastName',
        text: 'Last Name',
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'EmailAddr',
        text: 'Email',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '21%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'Admin',
        text: 'Admin',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'GroupName',
        text: 'Group Name',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'BusinessUnitName',
        text: 'Business Unit',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      },{
        dataField: 'buttonDelete',
        text: '',
        align: 'center',
        formatter: buttonDeleteAccount,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            if (window.confirm('Delete User Account?')) 
              {
                this.props.postDeleteUser(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 )
              }
          }
      }
      },{
        dataField: 'buttonPW',
        text: '',
        align: 'center',
        formatter: buttonChangePW,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => { 
            if (window.confirm('Change Password?')) 
              {
                this.props.history.push('/home/userchangepassword')
                // this.props.postDeleteUser(this.props.currentUserEmail, row.EmailAddr, row.FirstName, row.LastName, 0, 0, 0 )
              }
          }
      }
      }

      ]

      let bootStrapTable;

      if (this.props.isUserAccountsLoading) {
        bootStrapTable=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.userSignInData) !== 'undefined' && this.props.userSignInData != null) {
        bootStrapTable =
        <Table>
        <BootstrapTable 
        keyField='EmailAddr' 
        data={ [this.props.userSignInData] } 
        columns={ userAccountColumns } 
        />
      </Table>
      } else {
        bootStrapTable = <div></div>;
      }

      let userSettingsDiv;
      userSettingsDiv = 
        <Select 
            options={ languageDropDownMenu } 
            // clearable
            separator
            placeholder={ this.props.languageSelection }
            onChange={ this.dropDownOnChange }
            // defaultValue={{ label: navSelection, value: navSelection }}
            // value={{ label: navSelection, value: navSelection }}
        />


      



      return(
        <div>


        <section className="panel">

          <header className="panel-heading">
            {'My Account'}
          </header>
          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>

            { bootStrapTable }

          </div>


        </section>


        

        <section className="panel">

          <header className="panel-heading">
            {'User Settings'}
          </header>


          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>



            <div style={{padding: '15px' , zIndex: '100000000!important', height: '200px'}}>  
                        <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                            {userSettingsDiv}
                        </div>

                        
                    </div>
            </div>


        </section>







{/* 
        <div style={{paddingTop: "22px", paddingRight: "15px", paddingLeft: "15px" }}>
            <section className="panel">
                <div //className="panel-body table-responsive"
                    style={{backgroundColor: '#ffff'}}
                >
                  </div>
                  <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                        {userSettingsDiv}
            </div>

                  </section>

        
        </div> */}




          {/* <section className="panel">

            <header className="panel-heading">
              {'User Settings'}
            </header>

            <div className="col-md-12"  style={{padding: '5px 15px 5px 15px'}}>
                {userSettingsDiv}
            </div>

          </section> */}


          </div>

      );
    }
  }

  export default withRouter(MyProfile);
  