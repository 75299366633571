import React, { PureComponent } from 'react';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Cell
  } from 'recharts';
import PropTypes  from 'prop-types';
import {
    ResponsiveContainer,
  } from 'recharts';




const ReChartBarCustomColor = ({
    data,  
    dataKey,
    barKeys,
    xLegend,
    yLegend,
    onBarClick,
    colorMaxThreshold,
    colorMidThreshold,
    colorMinThreshold,
    colorMax,
    colorMid,
    colorMin

    }) => (

        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
            <BarChart
                // width={500}
                // height={300}
                data={data}
                margin={{
                top: 5, right: 35, left: 20, bottom: 25,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey={dataKey} label={{ value: xLegend, dy: 20}}/>
                <YAxis label={{ value: yLegend, angle: -90,   dx: -40}} allowDecimals={false}/>
                <Tooltip />
                {/* <Legend /> */}
                {
                    barKeys.map(
                        ({barKey, stackID}, idx) => {
                            return (
                                // <Bar  dataKey={barKey} fill={(data[idx][{dataKey}] < 2  ) ? '#f43e1f'  : '#37ba30' }     onClick={onBarClick}     stackId={`"` + {stackID} + `"`}/>

                                <Bar dataKey={barKey} onClick={onBarClick}     stackId={`"` + {stackID} + `"`} >
                                    {data.map((entry, index) => (
                                        // <Cell fill={entry['Days'] < 10 ? '#f43e1f' : '#37ba30' }/>

                                        <Cell fill={(entry['Days'] > 2  ) ? '#f43e1f'  : (entry['Days'] > 1  ) ? '#f1e15b'  : '#37ba30' }/>  
                                        // {(entry['Days'] > colorMaxThreshold  ) ? {colorMax}  : (entry['Days'] > colorMidThreshold  ) ? {colorMid}  : {colorMin} }



                                    ))}
                                </Bar>

// {(data[idx][{dataKey}] > colorMaxThreshold  ) ? {colorMax}  : (data[idx][{dataKey}] > colorMidThreshold  ) ? {colorMid}  : {colorMin} }


                            );
                        }
                    )
                }

            </BarChart>

        </ResponsiveContainer>
        </div>
        </div>        

);

ReChartBarCustomColor.propTypes = {
    data:                   PropTypes.array,
    dataKey:                PropTypes.string,
    barKeys:                PropTypes.array, //line key string, stroke color string
    xLegend:                PropTypes.string,
    yLegend:                PropTypes.string,
    onBarClick:             PropTypes.func,
    colorMaxThreshold:      PropTypes.number,
    colorMidThreshold:      PropTypes.number,
    colorMinThreshold:      PropTypes.number,
    colorMax:               PropTypes.string,
    colorMid:               PropTypes.string,
    colorMin:               PropTypes.string
};


export default ReChartBarCustomColor;



// <Line connectNulls type="monotone" dataKey={lineKey1} stroke="#8884d8" activeDot={{ r: 8 }} />
// <Line connectNulls type="monotone" dataKey={lineKey2} stroke="#82ca9d" />





