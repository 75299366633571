import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
// import {
//     Table,
//     TableBody
//   }                         from '../../../components/ComponentsGeneral/table';
  import {//valueDeliveryDayFormatter, 
    buttonFormatter, 
    mawbButtonFormatterMobile
    // modalButtonFormatter, 
    // shipmentTagFormatter, 
    // shipmentAlertIconFormatter
    // // hotShipmentIcon
  } from '../../../resources/logic/formatting';
  import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import BootstrapTable from 'react-bootstrap-table-next';
  // import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';
  import StatsCard from '../../ComponentsGeneral/StatsCard/StatsCard'
  import queryString from 'query-string';
  import ReactExport from "react-export-excel";
  import * as moment from 'moment'

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")


  class EvolveBrokerageZU extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    onClickStatCard  = (e, filterParam ) => {
      const values = queryString.parse(this.props.location.search)
      
      var paramStartDT = null
      var paramEndDT = null
      var paramDateType = null
      var paramNotDeparted = null
      var paramPortExit = null
      var paramPortEntry = null
      var paramFinalCarrier = null
      var paramMawb = null
      var paramMC = null
      var paramHawb = null

      var paramFilterValue = null
      var paramShipToCountry = null
  

      if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
          paramStartDT = values.startdt
      }
      if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
          paramEndDT = values.enddt
      }
      if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
          paramDateType = values.datetype
      }
      if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
          paramNotDeparted = values.notdeparted
      }
      if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
          paramPortExit = values.portexit
      }
      if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
          paramPortEntry = values.portentry
      }
      if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
          paramFinalCarrier = values.finalcarrier
      }
  
      if(typeof(values.mawb) !== 'undefined' && values.mawb != null ){
          paramMawb = values.mawb
      }
      if(typeof(values.mc) !== 'undefined' && values.mc != null ){
          paramMC = values.mc
      }
      if(typeof(values.hawb) !== 'undefined' && values.hawb != null ){
          paramHawb = values.hawb
      }
      if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
          paramFilterValue = values.filterValue
      }
      if(typeof(values.shiptocountry) !== 'undefined' && values.shiptocountry != null ){
        paramShipToCountry = values.shiptocountry
    }


      this.props.history.push({
        // pathname: 'zulily/evolvebrokerage',
        search: 'startdt=' + paramStartDT + '&' +
        'enddt=' + paramEndDT + '&' +
        'datetype=' + paramDateType + '&' +
        'notdeparted=' + paramNotDeparted + '&' +
        'portexit=' + paramPortExit + '&' +
        'portentry=' + paramPortEntry + '&' +
        'shiptocountry=' + paramShipToCountry + '&' +
        'finalcarrier=' + paramFinalCarrier  + '&' +
        'mawb=' + paramMawb + '&' +
        'mc=' + paramMC  + '&' +
        'hawb=' + paramHawb  + '&' +
        'filterValue=' + paramFilterValue  + '&' +
        'filter=' + filterParam

        }); 
        
    }



    render() {

        const { ExportCSVButton } = CSVExport;

        const brokerageColumns = [{
            dataField: 'Pickup Date',
            text: 'Pickup Date',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '11%' , textAlign: 'center' };
            },
            sort: true,
          }, {
            dataField: 'Pickup Date UTC',
            text: 'Pickup Date UTC',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '11%' , textAlign: 'center' };
            },
            sort: true,
          }, {
            dataField: 'MAWB',
            text: 'MAWB',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '11%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" }
          },
          {
            dataField: 'HAWB',
            text: 'HAWB',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '9%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" }
          }, {

            dataField: 'Dest',
            text: 'Dest',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '5%' , textAlign: 'center' };
            },
          },
          // {
          //   dataField: 'MI Carton',
          //   text: 'MI Carton',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '8%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'MI Parcel',
          //   text: 'MI Parcel',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '8%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'Packages/Carton',
          //   text: 'Packages / Carton',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '10%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'GW',
          //   text: 'G.W.',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '8%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'MAWB KILOS',
          //   text: 'MAWB KILOS',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '8%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'DIM Weight',
          //   text: 'DIM Weight',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '8%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'Flight Number',
          //   text: 'Flight Number',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '10%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'Booked Date',
          //   text: 'Booked Date',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '10%' , textAlign: 'center' };
          //   },
          // },
          // {
          //   dataField: 'Flown Date',
          //   text: 'Flown Date',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '10%' , textAlign: 'center' };
          //   },
          // },
          {
            dataField: 'Arrival Date',
            text: 'Arrival Date',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
          },
          {
            dataField: 'Arrival Date UTC',
            text: 'Arrival Date UTC',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
          },
          // {
          //   dataField: 'Pick-up to Departure',
          //   text: 'Pick-up to Departure',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '10%' , textAlign: 'center' };
          //   },
          // },
          {
            dataField: 'Recover Date',
            text: 'Recover Date',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
          },
          {
            dataField: 'Recover Date UTC',
            text: 'Recover Date UTC',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
          },
          {
            dataField: 'Handoff Date',
            text: 'Handoff Date',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
          },
          {
            dataField: 'Handoff Date UTC',
            text: 'Handoff Date UTC',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
          },
          // {
          //   dataField: 'Recover to Handoff',
          //   text: 'Recover to Handoff',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '10%' , textAlign: 'center' };
          //   },
          // },
          {


            dataField: 'buttonDetails',
            text: '',
            align: 'center',
            formatter: buttonFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '10%', textAlign: 'center' };
              },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {   

                var paramMawb = row.MAWB.trim() 
                var paramHawb = row.HAWB.trim()

                  this.props.history.push({
                  pathname: 'shipdetails',
                  search: 'mawb=\'' + paramMawb +'\'' + '&' +
                  'hawb=\'' + paramHawb +'\''
                  }); 

                ; }
              }
          }
          ]

          const mobilebrokerageColumns = [
            {
              dataField: 'MAWB',
              text: 'MAWB',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '60%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" },
              formatter: mawbButtonFormatterMobile,
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {   

                  var paramMawb =  row.MAWB.trim() 
  
                    this.props.history.push({
                    pathname: 'shipdetails',
                    search: 'mawb=\'' + paramMawb +'\''
                    }); 
  
  
                  ; }
                }
      
            },
          {
            dataField: 'HAWB',
            text: 'HAWB',
            align: 'center',
            style: { overflowWrap: "break-word" },
            headerStyle: (colum, colIndex) => {
              return { width: '40%' , textAlign: 'center' };
            },
            sort: true
          }
          ]





        let statCardDiv;
        if (this.props.isEvolveBrokerageStatCardLoading) {
          statCardDiv=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            />
        }  
        else if (typeof(this.props.EvolveBrokerageStatCard) !== 'undefined' && this.props.EvolveBrokerageStatCard != null) {
          var PendingRecover = String(this.props.EvolveBrokerageStatCard[0].PendingRecover);
          var RecoverAlerts = String(this.props.EvolveBrokerageStatCard[0].RecoverAlerts);
          var PendingHandoff = String(this.props.EvolveBrokerageStatCard[0].PendingHandoff);
          var HandoffAlerts = String(this.props.EvolveBrokerageStatCard[0].HandoffAlerts);

          let statCardPendingRecover;
          statCardPendingRecover=
              <div style={{cursor: 'pointer'}} onClick={(e) => {  this.onClickStatCard(e, 'PendingRecover')} }>
                <StatsCard
                    statValue={PendingRecover}
                    statLabel={'Pending Recover'}
                    icon={<i className="fa fa-plane" />}
                    backColor={'blue'}
                    isMobile={this.props.isMobile}
                />
              </div>

          let statCardRecoverAlerts;
          statCardRecoverAlerts=
              <div style={{cursor: 'pointer'}} onClick={(e) => {  this.onClickStatCard(e, 'RecoverAlerts')} } >
                <StatsCard
                    statValue={RecoverAlerts}
                    statLabel={'Recover Alerts'}
                    icon={<i className="fa fa-exclamation-triangle" />}
                    backColor={'red'}
                    isMobile={this.props.isMobile}
                />
              </div>

          let statCardPendingHandoff;
          statCardPendingHandoff=
              <div style={{cursor: 'pointer'}} onClick={(e) => {  this.onClickStatCard(e, 'PendingHandoff')} } >
                <StatsCard
                    statValue={PendingHandoff}
                    statLabel={'Pending Handoff'}
                    icon={<i className="fa fa-truck" />}
                    backColor={'blue'}
                    isMobile={this.props.isMobile}
                />
              </div>

          let statCardHandoffAlerts;
          statCardHandoffAlerts=
              <div style={{cursor: 'pointer'}} onClick={(e) => {  this.onClickStatCard(e, 'HandoffAlerts')} } >
                <StatsCard
                    statValue={HandoffAlerts}
                    statLabel={'Handoff Alerts'}
                    icon={<i className="fa fa-exclamation-triangle" />}
                    backColor={'red'}
                    isMobile={this.props.isMobile}
                />
              </div>



if(this.props.isMobile){
  statCardDiv = 
      <div>
          <div style={{marginBottom: '1px'}}>
          {statCardPendingRecover}
          </div>
          <div style={{marginBottom: '1px'}}>
          {statCardRecoverAlerts}
          </div>
          <div style={{marginBottom: '1px'}}>
          {statCardPendingHandoff}
          </div>          
          <div style={{marginBottom: '1px'}}>
          {statCardHandoffAlerts}
          </div>      


      </div>
      }
  else{
    statCardDiv = 
      <div>
          <div
              className="row"
              style={{marginBottom: '15px'}}>
                <div className="col-md-6" >
                {statCardPendingRecover}
                </div>
                <div className="col-md-6" >
                {statCardPendingHandoff}
                </div>
          </div>
          <div
              className="row"  
              style={{marginBottom: '15px'}}>
          </div>
          <div
              className="row"  
              style={{marginBottom: '5px'}}>
                <div className="col-md-6" >
                {statCardRecoverAlerts}
                </div>
                <div className="col-md-6" >
                {statCardHandoffAlerts}
                </div>
          </div>
      </div>
  }



        }
        else {
          statCardDiv =
          <div></div>
        }


        

        let bootStrapTable;
        if (this.props.isEvolveBrokerageDataLoading) {
          bootStrapTable=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.EvolveBrokerageData) !== 'undefined' && this.props.EvolveBrokerageData != null) {
                bootStrapTable =
                <div>
                  <ToolkitProvider
                    keyField="UniqueID"
                    data={ this.props.EvolveBrokerageData }
                    columns= {(this.props.isMobile == true  ) ? mobilebrokerageColumns  : brokerageColumns }
                    exportCSV
                    >
                    {
                      props => (
                        <div style={{  display: (this.props.EvolveBrokerageData.length > 0  ) ? ''  : 'none'}}>
                          {(this.props.isMobile == true  ) ? <div></div>  : 
                            <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'EvolveBrokerage_'+ currentDateTime}>
                              <ExcelSheet data={this.props.EvolveBrokerageData} name="EvolveBrokerage">
                                <ExcelColumn label="Pickup Date" value="Pickup Date"/>
                                <ExcelColumn label="Pickup Date UTC" value="Pickup Date UTC"/>
                                <ExcelColumn label="HAWB" value="HAWB"/>
                                <ExcelColumn label="MAWB" value="MAWB"/>
                                <ExcelColumn label="Dest" value="Dest"/>
                                <ExcelColumn label="MI Carton" value="MI Carton"/>
                                <ExcelColumn label="MI Parcel" value="MI Parcel"/>
                                <ExcelColumn label="Packages/Carton" value="Packages/Carton"/>
                                <ExcelColumn label="G.W." value="GW"/>
                                <ExcelColumn label="MAWB KILOS" value="MAWB KILOS"/>
                                <ExcelColumn label="DIM Weight" value="DIM Weight"/>
                                <ExcelColumn label="Flight Number" value="Flight Number"/>
                                <ExcelColumn label="Booked Date" value="Booked Date"/>
                                <ExcelColumn label="Booked Date UTC" value="Booked Date UTC"/>
                                <ExcelColumn label="Flown Date" value="Flown Date"/>
                                <ExcelColumn label="Flown Date UTC" value="Flown Date UTC"/>
                                <ExcelColumn label="Arrival Date" value="Arrival Date"/>
                                <ExcelColumn label="Arrival Date UTC" value="Arrival Date UTC"/>
                                <ExcelColumn label="Pick-up to Departure" value="Pick-up to Departure"/>
                                <ExcelColumn label="Recover Date" value="Recover Date"/>
                                <ExcelColumn label="Recover Date UTC" value="Recover Date UTC"/>
                                <ExcelColumn label="Handoff Date" value="Handoff Date"/>
                                <ExcelColumn label="Handoff Date UTC" value="Handoff Date UTC"/>
                                <ExcelColumn label="Recover to Handoff" value="Recover to Handoff"/>
                                <ExcelColumn label="Mawb Unit Count" value="MawbUnitCount"/>

                              </ExcelSheet>
                          </ExcelFile>
                          // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                        }
                          <hr />
                            <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                        </div>
                      )
                    }
                  </ToolkitProvider>

                  </div>



        }
        else {
          bootStrapTable =
          <div></div>
        }

      return(
        
        <div>
          {statCardDiv}

        <section className="panel">



        <header className="panel-heading">
          { `Evolve Brokerage: ${this.props.EvolveBrokerageData.length} rows`}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden'}}>

          { bootStrapTable }

        </div>

        </section>
        </div>

      );

    }
  }
  

  export default withRouter(EvolveBrokerageZU);
  
