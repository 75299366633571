import * as types from '../constants/googleActionTypes';
import {appSettings} from '../../app/appSettings'
// import reducers from './index';

const initialState = {
        statCardRowData: [],
        isStatCardRowLoading: true,
        statCardClickedParam: false,

        filteredShipTrackerData: [],
        isFilteredShipmentTrackerLoading: true,

        shipmentScheduleGraph: [],
        isShipScheduleGraphLoading: true,

        shipmentHistory: [],
        isShipHistoryLoading: true,

        shipmentHistoryBar: [],
        isShipHistoryBarLoading: true,

        shipmentPie: [],
        isShipmentPieLoading: true,

        alertPieData: [],
        isAlertPieLoading: true,

        milestoneAlertData: [],
        isMilestoneAlertLoading: true,

        shipmentDetails: [],
        shipmentDetailsRowData: [],
        isShipmentDetailsLoading: true,

        shipmentTrackerData: [],
        isShipmentTrackerLoading: true,
        shipTrackModalShow: false,
        shipTrackModalData: [],
        shipTrackModalRow: [],

        shipmentGPSData: [],
        isShipmentGPSLoading: true,

        pivotTableData: [],
        isPivotTableLoading: false,

        isKPIOverviewLoading: true,
        kpiOverviewData: [],

        isKPILaneLoading: true,
        isKPIDetailLoading: true,
        kpiDetailData: [],
        kpiCarrierCalc:[],
        laneCalc: [],

        serviceIssueKpiData: [],
        isServiceIssueKpiDataLoading: true,
        serviceIssueKpiCalc: [],

        networkReportData: [],
        isNetworkReportingLoading: true,

        shipCommentsData: [],
        isShipCommentsLoading: true,

        isJobsLegsLoading: true,
        JobsLegsData: [],

        isJobsRefNumLoading: true,
        JobsRefNumData: [],

        serviceIssueData: [],
        isServiceIssueLoading: true,

        openServiceIssueData: [],
        isOpenServiceIssueLoading: true,

        detailsMileStoneAlertsData: [],

        isAlertKpiTrendLoading: true,
        alertKpiTrendData: [],

        //GLOBAL FILTER PARAMS
        modeGlobalFilter: null,
        laneGlobalFilter: null,
        forwarderGlobalFilter: null,
        serviceGlobalFilter: null,
        startDTGlobalFilter: null,
        endDTGlobalFilter: null,
        jobStatusGlobalFilter: null,


        isDemurrageDataLoading: true,
        DemurrageData: [],

        isPendingSailingDataLoading: true,
        PendingSailingData: []

};

function checkStatusCode( res )
{
        if(res === "200"){ return true }
        else { return false }
}

function actionOnError( proc )
{
        console.log('DB connection error')

        if (appSettings.devEnvironment === true) {
                // window.alert("Please reload and try again.")
        } 
        else {
                // window.alert("Please reload and try again.")

        }
}





export const ErrorReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{


        case types.STAT_CARD_ROW_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.STAT_CARD_IN_TRANSIT_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.STAT_CARD_PARAM_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_SCHEDULE_GRAPH_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_HISTORY_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_HISTORY_BAR_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.ALERT_KPI_TREND_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.NETWORK_REPORTING_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_PIE_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.MILESTONE_ALERT_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_DETAILS_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_DETAILS_COMMENTS_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_DETAILS_ALERTS_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_SUBSCRIBE_ERROR:
                        actionOnError()
                        return state;


        case types.PIVOT_TABLE_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.KPI_OVERVIEW_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.KPI_DETAIL_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.KPI_LANE_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_SEARCH_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_SEARCH_DETAIL_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_TRACKER_DATA_ERROR:
                        actionOnError()
                        return state;


        case types.SHIPMENT_TRACKER_JOBID_DATA_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_TRACKER_MODAL_SHOW_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_MODAL_COMMENT_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_MODAL_HOT_SHIP_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_MODAL_INTERNAL_ACTION_ERROR:
                        actionOnError()
                        return state;

        case types.SHIPMENT_MODAL_EXTERNAL_ACTION_ERROR:
                        actionOnError()
                        return state;

        default:
                return state;
	}
};




  let defaultState = null;


export const GlobalFilterReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;


        case types.SET_GLOBAL_FILTER:

                return Object.assign({}, state, {
                    modeGlobalFilter: action.payload.mode,
                    laneGlobalFilter: action.payload.lane,
                    forwarderGlobalFilter: action.payload.forwarder,
                    serviceGlobalFilter: action.payload.service,
                    startDTGlobalFilter: action.payload.startDT,
                    endDTGlobalFilter: action.payload.endDT,
                    jobStatusGlobalFilter: action.payload.jobStatus
                });

        default:
                return state;
	}
};

export const StatCardRowReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;

                
                
        case types.STAT_CARD_ROW_DATA_GET:   

        return Object.assign({}, state, {
                isStatCardRowLoading: true
        });       

        case types.STAT_CARD_ROW_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
        }
        var newStatCardData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
                isStatCardRowLoading: false,
                statCardRowData: newStatCardData.data
        });

        

      
        default:
                return state;
	}
};

const ShipmentScheduleGraphReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;
                
        case types.SHIPMENT_SCHEDULE_GRAPH_DATA_GET:
                return Object.assign({}, state, {
                        isShipScheduleGraphLoading: true
                });

        case types.SHIPMENT_SCHEDULE_GRAPH_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                // if (typeof action.payload.body !== 'undefined') {
                //     newshipgraph = JSON.parse(action.payload.body);
                //   }
                var newshipgraph = JSON.parse(action.payload.body)
                //Convert response into array
                var newshipgrapharray = [newshipgraph.data]
                return Object.assign({}, state, {
                    isShipScheduleGraphLoading: false,
                    shipmentScheduleGraph: newshipgrapharray[0]
                });

        default:
                return state;
	}
};
export default ShipmentScheduleGraphReducer;

export const ShipmentHistoryReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;


        case types.SHIPMENT_HISTORY_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                //parse JSON response
                var newShipHistorydata = JSON.parse(action.payload.body)
                //Convert response into array
                var newShipHistorydataArray = [newShipHistorydata.data]
                var shipHistoryArray = [{"id": "Deliveries", "data": newShipHistorydataArray[0]}]
                return Object.assign({}, state, {
                    isShipHistoryLoading: false,
                    shipmentHistory: shipHistoryArray
                });

        default:
                return state;
	}
};

export const ShipmentHistoryBarReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;

                
        case types.SHIPMENT_HISTORY_BAR_DATA_GET:
                return Object.assign({}, state, {
                        isShipHistoryBarLoading: true
                });

        case types.SHIPMENT_HISTORY_BAR_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipHistoryBardata = JSON.parse(action.payload.body)
                return Object.assign({}, state, {
                    isShipHistoryBarLoading: false,
                    shipmentHistoryBar: newShipHistoryBardata.data
                });

        default:
                return state;
	}
};

export const AlertKpiTrendReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;

        case types.ALERT_KPI_TREND_DATA_GET:
                return Object.assign({}, state, {
                        isAlertKpiTrendLoading: true
                });

        case types.ALERT_KPI_TREND_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newAlertKpiTrenddata = JSON.parse(action.payload.body)
                
                return Object.assign({}, state, {
                    isAlertKpiTrendLoading: false,
                    alertKpiTrendData: newAlertKpiTrenddata.data
                });

        default:
                return state;
	}
};


export const ShipmentPieReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;


        case types.SHIPMENT_PIE_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newshipPiedata = JSON.parse(action.payload.body)
                //Convert response into array
                var newshipPiearray = [newshipPiedata.data]

                
                return Object.assign({}, state, {
                    isShipmentPieLoading: false,
                    shipmentPie: newshipPiearray[0]
                });

        default:
                return state;
	}
};

export const MilestoneAlertReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;

                
                
        case types.MILESTONE_ALERT_DATA_GET:
                return Object.assign({}, state, {
                        isMilestoneAlertLoading: true
                });
                        
                        
        case types.MILESTONE_ALERT_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newMilestoneAlertdata = JSON.parse(action.payload.body)

                // let resultAlertPieData = Object.values(newMilestoneAlertdata.data.reduce((c, {Alert_Category}) => {
                //   c[Alert_Category] = c[Alert_Category] || {id: Alert_Category,label: Alert_Category,value: 0};
                //   c[Alert_Category].value++;
                //   return c;
                // }, {}))


                let resultAlertPieData = Object.values(newMilestoneAlertdata.data.reduce((c, {Alert_Category}) => {
                
                        //this changes alert category for demo dashboard to remove 'Omni'
                        var newAlertCategory = Alert_Category
                        if (Alert_Category === 'Omni Red'){
                                newAlertCategory = `${appSettings.customer.Milestone_Red}`
                        }
                        else if (Alert_Category === 'Omni Yellow'){
                                newAlertCategory = `${appSettings.customer.Milestone_Yellow}`
                        }

                  c[Alert_Category] = c[Alert_Category] || {id: newAlertCategory, label: newAlertCategory,value: 0};

                  c[Alert_Category].value++;
                  return c;
                }, {}))



                return Object.assign({}, state, {
                        isMilestoneAlertLoading: false,
                        milestoneAlertData: newMilestoneAlertdata.data,
                        alertPieData: resultAlertPieData
                });

        default:
                return state;
	}
};


export const ShipmentDetailsReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;


                
        case types.SHIPMENT_SUBSCRIBE_POST:
                
                return Object.assign({}, state, {
                        isShipmentDetailsLoading: true
                });

        case types.SHIPMENT_SUBSCRIBE_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newshipDetaildata = JSON.parse(action.payload.body)
              
                return Object.assign({}, state, {
                        isShipmentDetailsLoading: false,
                        shipmentDetailsRowData: newshipDetaildata.data[0]
                });  


        case types.SHIPMENT_DETAILS_DATA_GET:
              
                return Object.assign({}, state, {
                        shipmentDetailsRowData: action.payload.row,
                        isShipmentDetailsLoading: true,
                        isShipCommentsLoading: true,
                        isServiceIssueLoading: true
                });

        case types.SHIPMENT_DETAILS_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }

                var newshipDetaildata = JSON.parse(action.payload.body)
               
                return Object.assign({}, state, {
                        isShipmentDetailsLoading: false,
                        shipmentDetails: newshipDetaildata.data
                });


        case types.SHIPMENT_DETAILS_ALERTS_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }

                var newMilestoneAlertdata = JSON.parse(action.payload.body)
              

                return Object.assign({}, state, {
                        detailsMileStoneAlertsData: newMilestoneAlertdata.data
                });
                        
        case types.SHIPMENT_DETAILS_COMMENTS_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipCommentsdata = JSON.parse(action.payload.body);
              
                return Object.assign({}, state, {
                        shipCommentsData: newShipCommentsdata.data,
                        isShipCommentsLoading: false
                }); 
                
                

        case types.OPEN_SERVICE_ISSUES_GET:
                return Object.assign({}, state, {
                        isOpenServiceIssueLoading: true
                });   

        case types.POST_SERVICE_ISSUE_FROM_VIEW:
                return Object.assign({}, state, {
                        // isOpenServiceIssueLoading: true
                });   
                

        case types.OPEN_SERVICE_ISSUES_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newOpenServiceIssuedata = JSON.parse(action.payload.body);
                
                return Object.assign({}, state, {
                        openServiceIssueData: newOpenServiceIssuedata.data,
                        isOpenServiceIssueLoading: false
                });    

        case types.SERVICE_ISSUES_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newServiceIssuedata = JSON.parse(action.payload.body);
                
                return Object.assign({}, state, {
                        serviceIssueData: newServiceIssuedata.data,
                        isServiceIssueLoading: false
                });    

        case types.POST_SERVICE_ISSUE:
                return Object.assign({}, state, {
                        isServiceIssueLoading: true
                });     
                
        case types.POST_SERVICE_ISSUE_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newServiceIssuedata = JSON.parse(action.payload.body);
                
                return Object.assign({}, state, {
                        serviceIssueData: newServiceIssuedata.data,
                        isServiceIssueLoading: false
                });                    

        case types.SHIPMENT_MODAL_COMMENT_POST:
                return Object.assign({}, state, {
                        isShipmentTrackerLoading: true,
                        isShipCommentsLoading: true
                });
                                
        case types.SHIPMENT_MODAL_COMMENT_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipModalCommentdata = JSON.parse(action.payload.body);
    
                return Object.assign({}, state, {
                        shipTrackModalShow: true,
                        isShipCommentsLoading: false,
                        shipTrackModalData: newShipModalCommentdata.data,
                        shipCommentsData: newShipModalCommentdata.data
                });               
        


        case types.SHIPMENT_SEARCH_DATA_GET:
                return Object.assign({}, state, {
                        isShipmentDetailsLoading: true,
                        isShipCommentsLoading: true,
                        isServiceIssueLoading: true,
                        isShipCommentsLoading: true,
                        isJobsLegsLoading: true,
                        isJobsRefNumLoading: true
                });

        case types.JOBS_LEGS_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newJobsLegsData = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        JobsLegsData: newJobsLegsData.data,
                        isJobsLegsLoading: false
                });            

        case types.JOBS_REF_NUMBERS_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newJobsRefData = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        JobsRefNumData: newJobsRefData.data,
                        isJobsRefNumLoading: false
                });    

        case types.SHIPMENT_SEARCH_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newshipDetaildata = JSON.parse(action.payload.body)
              
                return Object.assign({}, state, {
                        isShipmentDetailsLoading: false,
                        shipmentDetailsRowData: newshipDetaildata.data[0],
                        isShipCommentsLoading: false
                });  

        case types.SHIPMENT_SEARCH_DETAIL_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipTrackerdata = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        shipmentDetails: newShipTrackerdata.data,
                        isShipmentDetailsLoading: false
                });            
                
        case types.SHIPMENT_TRACKER_JOBID_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipTrackerdata = JSON.parse(action.payload.body);
            
                return Object.assign({}, state, {
                        shipmentDetailsRowData: newShipTrackerdata.data[0],
                        isShipmentDetailsLoading: false
                });




        case types.SHIPMENT_TRACKER_MODAL_SHOW_GET:
           
                return Object.assign({}, state, {
                        // shipTrackModalRow: action.payload.row,
                        isShipCommentsLoading: true
                });


        case types.SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipModaldata = JSON.parse(action.payload.body);
               
                return Object.assign({}, state, {
                        // shipTrackModalShow: true,
                        // shipTrackModalData: newShipModaldata.data,
                        shipCommentsData: newShipModaldata.data,
                        isShipCommentsLoading: false

                });

        default:
                return state;
	}
};

export const ReportingReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;

        case types.PIVOT_TABLE_DATA_GET:
                return Object.assign({}, state, {
                        isPivotTableLoading: true
                });

        case types.PIVOT_TABLE_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newPivotTableData = JSON.parse(action.payload.body);
         
                return Object.assign({}, state, {
                        pivotTableData: newPivotTableData.data,
                        isPivotTableLoading: false,
                });


        case types.NETWORK_REPORTING_DATA_GET:
                return Object.assign({}, state, {
                        isNetworkReportingLoading: true
                });

        case types.NETWORK_REPORTING_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newNetworkReportData = JSON.parse(action.payload.body);
           
                return Object.assign({}, state, {
                        networkReportData: newNetworkReportData.data,
                        isNetworkReportingLoading: false,
                });


        case types.KPI_OVERVIEW_DATA_GET:
                return Object.assign({}, state, {
                        isKPIOverviewLoading: true,
                        isKPIDetailLoading: true,
                        isKPILaneLoading: true
                });

        case types.KPI_OVERVIEW_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newKpiData = JSON.parse(action.payload.body);
    
                return Object.assign({}, state, {
                        kpiOverviewData: newKpiData.data,
                        isKPIOverviewLoading: false,
                });

        case types.KPI_LANE_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newKpiData = JSON.parse(action.payload.body);
              
                return Object.assign({}, state, {
                        laneCalc: newKpiData.data,
                        isKPILaneLoading: false,
                });                

        case types.KPI_DETAIL_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newKpiData = JSON.parse(action.payload.body);

                var newCarrierCalc = [];

                newCarrierCalc =  Object.values(newKpiData.data.reduce(function(r, e) {
                          var key = e.kpiID + '|' + e.Carrier;
            
                          if (!r[key]) r[key] = { Carrier: e.Carrier, kpiID: e.kpiID, KPI: e.KPI ,Value: e.Value, Length: 1,  CountMetKPI: e.MetKPI, CalcAvg: e.Value, CalcPerf: e.MetKPI * 100};  
                          else {
                            r[key].Value += e.Value;
                            r[key].Length += 1;
                            r[key].CalcAvg = Math.round(r[key].Value/r[key].Length, 1);
                            r[key].CountMetKPI += e.MetKPI;
                            r[key].CalcPerf = Math.round(r[key].CountMetKPI/r[key].Length * 100, 1);
                  
                            // r[key].instances += e.instances
                          }
                          return r;
                        },
                        {})
                        )
                      
           
                return Object.assign({}, state, {
                        kpiDetailData: newKpiData.data,
                        kpiCarrierCalc: newCarrierCalc,
                        isKPIDetailLoading: false,                        
                });

        case types.SERVICE_ISSUE_KPI_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newServiceIssueKpiData = JSON.parse(action.payload.body);

                var newServiceIssueCalc = [];

                newServiceIssueCalc =  Object.values(newServiceIssueKpiData.data.reduce(function(r, e) {
                          var key = e.ServiceIssue + '|' + e.CurrentClassification;
                        //   console.log('e', e)
            
                          if (!r[key]) r[key] = { ServiceIssue: e.ServiceIssue, CurrentClassification: e.CurrentClassification , Length: 1 , CalcPercentage: Math.round(1/newServiceIssueKpiData.data.length * 100, 1) };  
                          else {
                            r[key].Length += 1;
                            r[key].CalcPercentage = Math.round(r[key].Length/newServiceIssueKpiData.data.length * 100, 1);

                          }
                          return r;
                        },
                        {})
                        )

                
                return Object.assign({}, state, {
                        serviceIssueKpiData: newServiceIssueKpiData.data,
                        isServiceIssueKpiDataLoading: false,
                        serviceIssueKpiCalc: newServiceIssueCalc
                }); 


        case types.PENDING_SAILING_GO_DATA_GET:   
        return Object.assign({}, state, {
                isPendingSailingDataLoading: true
        });       
        case types.PENDING_SAILING_GO_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
        }
        var newPendingSailingData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
                isPendingSailingDataLoading: false,
                PendingSailingData: newPendingSailingData.data
        });   

        case types.DEMURRAGE_GO_DATA_GET:   
        return Object.assign({}, state, {
                isDemurrageDataLoading: true
        });       
        case types.DEMURRAGE_GO_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
        }
        var newDemurrageData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
                isDemurrageDataLoading: false,
                DemurrageData: newDemurrageData.data
        });   






        default:
                return state;
	}
};

export const ShipmentTrackerReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
                state = initialState;
                return state;
                


        case types.SHIPMENT_TRACKER_DATA_GET:
                return Object.assign({}, state, {
                        isShipmentTrackerLoading: true
                });


        case types.SHIPMENT_TRACKER_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipTrackerdata = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        shipmentTrackerData: newShipTrackerdata.data,
                        isShipmentTrackerLoading: false
                });

        case types.SHIPMENT_GPS_DATA_GET:
                return Object.assign({}, state, {
                        isShipmentGPSLoading: true
                });

        case types.SHIPMENT_GPS_DATA_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipGPSdata = JSON.parse(action.payload.body);
                return Object.assign({}, state, {
                        shipmentGPSData: newShipGPSdata.data,
                        isShipmentGPSLoading: false
                });        

        case types.SHIPMENT_TRACKER_JOBID_DATA_GET:
                return Object.assign({}, state, {
                        isShipmentDetailsLoading: true
                });



        case types.SHIPMENT_TRACKER_MODAL_HIDE:
                return Object.assign({}, state, {
                        shipTrackModalShow: false
                });

        case types.SHIPMENT_TRACKER_MODAL_SHOW_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipModaldata = JSON.parse(action.payload.body);
        
                return Object.assign({}, state, {
                        shipTrackModalShow: true,
                        shipTrackModalData: newShipModaldata.data
                        // shipCommentsData: newShipModaldata.data,
                        // isShipCommentsLoading: false

                });

        case types.SHIPMENT_TRACKER_MODAL_SHOW_GET:
   
                return Object.assign({}, state, {
                        shipTrackModalRow: action.payload.row
                        // isShipCommentsLoading: true
                });

        case types.SHIPMENT_MODAL_COMMENT_POST:
                return Object.assign({}, state, {
                        isShipmentTrackerLoading: true,
                        isShipCommentsLoading: true
                });
                                
        case types.SHIPMENT_MODAL_COMMENT_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newShipModalCommentdata = JSON.parse(action.payload.body);
                
                return Object.assign({}, state, {
                        shipTrackModalShow: true,
                        shipTrackModalData: newShipModalCommentdata.data
                });

        case types.SHIPMENT_MODAL_HOT_SHIP_POST:
                return Object.assign({}, state, {
                        isShipmentTrackerLoading: true,
                        isStatCardRowLoading: true,
                        isShipCommentsLoading: true
                });

        case types.SHIPMENT_MODAL_HOT_SHIP_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newshipmodalhot = JSON.parse(action.payload.body)
                var newshipmodalhotarray = [newshipmodalhot.data]
                var boolval = newshipmodalhotarray[0]
                var booltest = Object.values(boolval)[0]
                var newbool;
                if(booltest.Status === false) {
                
                        newbool = 0
                }
                else{
                   
                        newbool = 1
                }
                var modalRow = state.shipTrackModalRow;
                modalRow.HotShipment = newbool;
                return Object.assign({}, state, {
                        shipTrackModalRow: modalRow
                });

        case types.SHIPMENT_MODAL_INTERNAL_ACTION_POST:
                return Object.assign({}, state, {
                        isShipmentTrackerLoading: true,
                        isStatCardRowLoading: true,
                        isShipCommentsLoading: true
                });

        case types.SHIPMENT_MODAL_INTERNAL_ACTION_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newshipmodalint = JSON.parse(action.payload.body)
                var newshipmodalintarray = [newshipmodalint.data]
                var boolvalint = newshipmodalintarray[0]
                var booltestint = Object.values(boolvalint)[0]
                var newboolint;
                if(booltestint.Status === false) {
                 
                        newboolint = 0
                }
                else{
                 
                        newboolint = 1
                }
                var modalRowInt = state.shipTrackModalRow;
                modalRowInt.OmniAction = newboolint;
                return Object.assign({}, state, {
                        shipTrackModalRow: modalRowInt
                });               

        case types.SHIPMENT_MODAL_EXTERNAL_ACTION_POST:
                return Object.assign({}, state, {
                        isShipmentTrackerLoading: true,
                        isStatCardRowLoading: true,
                        isShipCommentsLoading: true
                });
        
        case types.SHIPMENT_MODAL_EXTERNAL_ACTION_COMPLETE:
                if(!checkStatusCode(action.payload.statusCode)) {
                        actionOnError()
                        return state;
                }
                var newshipmodalExt = JSON.parse(action.payload.body)
                var newshipmodalExtarray = [newshipmodalExt.data]
                var boolvalext = newshipmodalExtarray[0]
                var booltestext = Object.values(boolvalext)[0]
                var newboolext;
                if(booltestext.Status === false) {
                    
                        newboolext = 0
                }
                else{
                       
                        newboolext = 1
                }
                var modalRowExt = state.shipTrackModalRow;
                modalRowExt.GoogleAction = newboolext;
                return Object.assign({}, state, {
                        shipTrackModalRow: modalRowExt
                });             


        // these are for shipment tracker in the stat card view
        case types.STAT_CARD_IN_TRANSIT_DATA_GET:
        return Object.assign({}, state, {
                isShipmentTrackerLoading: true
        });

        case types.STAT_CARD_IN_TRANSIT_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
        }
        var newStatCardInTransitData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
                filteredShipTrackerData: newStatCardInTransitData.data,
                isShipmentTrackerLoading: false
        });

        case types.STAT_CARD_PARAM_DATA_GET:
        return Object.assign({}, state, {
                isShipmentTrackerLoading: true,
                statCardClickedParam: true
        });

        case types.STAT_CARD_PARAM_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
        }
        var newStatCardParamData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
                filteredShipTrackerData: newStatCardParamData.data,
                isShipmentTrackerLoading: false,
                statCardClickedParam: false
        });















        default:
                return state;
	}
};











// const combinedReducer = combineReducers({
//         auth: authReducer.reducer,
//         form: formReducer,
      
//         signOutReducer: SignOutReducer,
//         userServicesReducer: UserServicesReducer,
//         statCardRowReducer: StatCardRowReducer,
//         shipmentScheduleGraphReducer: ShipmentScheduleGraphReducer,
//         shipmentHistoryReducer: ShipmentHistoryReducer,
//         shipmentPieReducer: ShipmentPieReducer,
//         shipmentTrackerReducer: ShipmentTrackerReducer,
//         shipmentHistoryBarReducer: ShipmentHistoryBarReducer,
//         shipmentDetailsReducer: ShipmentDetailsReducer,
//         milestoneAlertReducer: MilestoneAlertReducer
//     });
    
// export const RootReducer = (state, action) => {

// 	switch( action.type )
// 	{
//         case types.RESET_REDUX_STATE:
//                 delete state;
//                 return reducers(state, action)
      
//         default:
//                 return state;
// 	}



        // if (action.type === RESET_REDUX_STATE) {
        //     // clear everything but keep the stuff we want to be preserved ..
        //     delete state.something;
        // //     delete state.anotherThing;
        // }
        // return reducers(state, action);
//     }
    



    
// const rootReducer = (state, action) => {
//         if (action.type === 'CLEARSTORE') {
//           state = undefined
//         }

// let defaultState = null;
// export default (state, action) => {
//   switch (action.type) {
// //     case **ON_APP_LOAD**:
// //       // will be assigned or called only once 
// //       defaultState = defaultState || state;
// //       break;
//     case types.SIGN_OUT:
//       // detaching the reference on reset
//       state = _.deepClone(defaultState);
//       return state;
//     default:
//       break;
//   }





              // var newLaneCalc = [];

                // newLaneCalc =  Object.values(newKpiData.data.reduce(function(r, e) {
                //                 var key = e.Lane ;

                //                 // if (!r[key]) r[key] = { Lane: e.Lane, OTPU: , OTDel: ,  TransitSLA: , AveTransAir: ,  AveTransOcean: };  
                //                 if (!r[key] && e.kpiID == 1 && e.Mode == 'AIR')  r[key] = { 
                //                         Lane: e.Lane, 
                //                         OTPULength:  0, OTPUvalue:  0, OTPUavg:  0, 
                //                         OTDelLength: 0, OTDelvalue: 0, OTDelavg: 0,  
                //                         TransitSLAlength: 0, TransitSLAvalue: 0, TransitSLAavg: 0, 
                //                         AveTransAirLength: 1, AveTransAirValue: e.Value, AveTransAirAvg: e.Value,  
                //                         AveTransOceanLength: 0, AveTransOceanValue: 0, AveTransOceanAvg: 0
                //                 };  
                //                 else if (!r[key] && e.kpiID == 1 && e.Mode == 'OCEAN') r[key] = { 
                //                         Lane: e.Lane, 
                //                         OTPULength:  0, OTPUvalue:  0, OTPUavg:  0, 
                //                         OTDelLength: 0, OTDelvalue: 0, OTDelavg: 0,  
                //                         TransitSLAlength: 0, TransitSLAvalue: 0, TransitSLAavg: 0, 
                //                         AveTransAirLength: 0, AveTransAirValue: 0, AveTransAirAvg: 0,  
                //                         AveTransOceanLength: 1, AveTransOceanValue: e.Value, AveTransOceanAvg: e.Value
                //                 }; 
                //                 else if (!r[key] && e.kpiID == 2 ) r[key] = { 
                //                         Lane: e.Lane, 
                //                         OTPULength:  1, OTPUvalue:  e.MetKPI, OTPUavg:  e.MetKPI * 100, 
                //                         OTDelLength: 0, OTDelvalue: 0, OTDelavg: 0,  
                //                         TransitSLAlength: 0, TransitSLAvalue: 0, TransitSLAavg: 0, 
                //                         AveTransAirLength: 0, AveTransAirValue: 0, AveTransAirAvg: 0,  
                //                         AveTransOceanLength: 0, AveTransOceanValue: 0, AveTransOceanAvg: 0
                //                 };                                
                //                 else if (!r[key] && e.kpiID == 3 ) r[key] = { 
                //                         Lane: e.Lane, 
                //                         OTPULength:  0, OTPUvalue:  0, OTPUavg:  0, 
                //                         OTDelLength: 1, OTDelvalue: e.MetKPI, OTDelavg: e.MetKPI * 100,  
                //                         TransitSLAlength: 0, TransitSLAvalue: 0, TransitSLAavg: 0, 
                //                         AveTransAirLength: 0, AveTransAirValue: 0, AveTransAirAvg: 0,  
                //                         AveTransOceanLength: 0, AveTransOceanValue: 0, AveTransOceanAvg: 0
                //                 };  
                //                 else if (!r[key] && e.kpiID == 4 ) r[key] = { 
                //                         Lane: e.Lane, 
                //                         OTPULength:  0, OTPUvalue:  0, OTPUavg:  0, 
                //                         OTDelLength: 0, OTDelvalue: 0, OTDelavg: 0,  
                //                         TransitSLAlength: 1, TransitSLAvalue: e.MetKPI, TransitSLAavg: e.MetKPI * 100, 
                //                         AveTransAirLength: 0, AveTransAirValue: 0, AveTransAirAvg: 0,  
                //                         AveTransOceanLength: 0, AveTransOceanValue: 0, AveTransOceanAvg: 0
                //                 };  
                //                 // else if (!r[key] ) r[key] = {  //this is for those that don't fit
                //                 //         Lane: test, 
                //                 //         OTPULength:  0, OTPUvalue:  0, OTPUavg:  0, 
                //                 //         OTDelLength: 0, OTDelvalue: 0, OTDelavg: 0,  
                //                 //         TransitSLAlength: 0, TransitSLAvalue: 0, TransitSLAavg: 0, 
                //                 //         AveTransAirLength: 0, AveTransAirValue: 0, AveTransAirAvg: 0,  
                //                 //         AveTransOceanLength: 1, AveTransOceanValue: e.Value, AveTransOceanAvg: e.Value
                //                 // };  
                              
                                
                //                 else {
                                        
                //                         if( e.kpiID == 1 && e.Mode == 'AIR' ){
                //            
                //                                 r[key].Lane = e.Lane;

                //                                 r[key].AveTransAirLength += 1;
                //                                 r[key].AveTransAirValue += e.Value;
                //                                 r[key].AveTransAirAvg = Math.round(r[key].AveTransAirValue/r[key].AveTransAirLength, 1);

                //                                 r[key].OTPULength = r[key].OTPULength;
                //                                 r[key].OTPUvalue = r[key].OTPUvalue;
                //                                 r[key].OTPUavg = r[key].OTPUavg;
                //                                 r[key].OTDelLength = r[key].OTDelLength;
                //                                 r[key].OTDelvalue = r[key].OTDelvalue;
                //                                 r[key].OTDelavg = r[key].OTDelavg;
                //                                 r[key].TransitSLAlength  = r[key].TransitSLAlength ;
                //                                 r[key].TransitSLAvalue = r[key].TransitSLAvalue;
                //                                 r[key].TransitSLAavg = r[key].TransitSLAavg;
 
                //                                 r[key].AveTransOceanLength = r[key].AveTransOceanLength;
                //                                 r[key].AveTransOceanValue = r[key].AveTransOceanValue;
                //                                 r[key].AveTransOceanAvg = r[key].AveTransOceanAvg;
                //                         }
                //                         else if( e.kpiID == 1 && e.Mode == 'OCEAN' ){
                //                                 r[key].Lane = e.Lane;
    
                //                                 r[key].AveTransOceanLength += 1;
                //                                 r[key].AveTransOceanValue += e.Value;
                //                                 r[key].AveTransOceanAvg = Math.round(r[key].AveTransOceanValue/r[key].AveTransOceanLength, 1);

                //                                 r[key].OTPULength = r[key].OTPULength;
                //                                 r[key].OTPUvalue = r[key].OTPUvalue;
                //                                 r[key].OTPUavg = r[key].OTPUavg;
                //                                 r[key].OTDelLength = r[key].OTDelLength;
                //                                 r[key].OTDelvalue = r[key].OTDelvalue;
                //                                 r[key].OTDelavg = r[key].OTDelavg;
                //                                 r[key].TransitSLAlength  = r[key].TransitSLAlength ;
                //                                 r[key].TransitSLAvalue = r[key].TransitSLAvalue;
                //                                 r[key].TransitSLAavg = r[key].TransitSLAavg;
                //                                 r[key].AveTransAirLength = r[key].AveTransAirLength;
                //                                 r[key].AveTransAirValue = r[key].AveTransAirValue;
                //                                 r[key].AveTransAirAvg = r[key].AveTransAirAvg;

                //                         }

                //                         else if( e.kpiID == 2 ){
                //                                
                //                                 r[key].Lane = e.Lane;
               
                //                                 r[key].OTPULength += 1;
                //                                 r[key].OTPUvalue += e.MetKPI;
                //                                 r[key].OTPUavg = Math.round(r[key].OTPUvalue/r[key].OTPULength * 100, 1);


                //                                 r[key].OTDelLength = r[key].OTDelLength;
                //                                 r[key].OTDelvalue = r[key].OTDelvalue;
                //                                 r[key].OTDelavg = r[key].OTDelavg;
                //                                 r[key].TransitSLAlength  = r[key].TransitSLAlength ;
                //                                 r[key].TransitSLAvalue = r[key].TransitSLAvalue;
                //                                 r[key].TransitSLAavg = r[key].TransitSLAavg;
                //                                 r[key].AveTransAirLength = r[key].AveTransAirLength;
                //                                 r[key].AveTransAirValue = r[key].AveTransAirValue;
                //                                 r[key].AveTransAirAvg = r[key].AveTransAirAvg;
                //                                 r[key].AveTransOceanLength = r[key].AveTransOceanLength;
                //                                 r[key].AveTransOceanValue = r[key].AveTransOceanValue;
                //                                 r[key].AveTransOceanAvg = r[key].AveTransOceanAvg;
                //                         }

                //                         else if( e.kpiID == 3 ){
                //                                 r[key].Lane = e.Lane;
                                                                       
                //                                 r[key].OTDelLength += 1;
                //                                 r[key].OTDelvalue += e.MetKPI;
                //                                 r[key].OTDelavg = Math.round(r[key].OTDelvalue/r[key].OTDelLength * 100, 1);

                //                                 r[key].OTPULength = r[key].OTPULength;
                //                                 r[key].OTPUvalue = r[key].OTPUvalue;
                //                                 r[key].OTPUavg = r[key].OTPUavg;
 
                //                                 r[key].TransitSLAlength  = r[key].TransitSLAlength ;
                //                                 r[key].TransitSLAvalue = r[key].TransitSLAvalue;
                //                                 r[key].TransitSLAavg = r[key].TransitSLAavg;
                //                                 r[key].AveTransAirLength = r[key].AveTransAirLength;
                //                                 r[key].AveTransAirValue = r[key].AveTransAirValue;
                //                                 r[key].AveTransAirAvg = r[key].AveTransAirAvg;
                //                                 r[key].AveTransOceanLength = r[key].AveTransOceanLength;
                //                                 r[key].AveTransOceanValue = r[key].AveTransOceanValue;
                //                                 r[key].AveTransOceanAvg = r[key].AveTransOceanAvg;
                //                         }

                //                         else if( e.kpiID == 4 ){
                //                              
                //                                 r[key].Lane = e.Lane;
                                  
                //                                 r[key].TransitSLAlength += 1;
                //                                 r[key].TransitSLAvalue += e.MetKPI;
                //                                 r[key].TransitSLAavg = Math.round(r[key].TransitSLAvalue/r[key].TransitSLAlength * 100, 1);

                //                                 r[key].OTPULength = r[key].OTPULength;
                //                                 r[key].OTPUvalue = r[key].OTPUvalue;
                //                                 r[key].OTPUavg = r[key].OTPUavg;
                //                                 r[key].OTDelLength = r[key].OTDelLength;
                //                                 r[key].OTDelvalue = r[key].OTDelvalue;
                //                                 r[key].OTDelavg = r[key].OTDelavg;

                //                                 r[key].AveTransAirLength = r[key].AveTransAirLength;
                //                                 r[key].AveTransAirValue = r[key].AveTransAirValue;
                //                                 r[key].AveTransAirAvg = r[key].AveTransAirAvg;
                //                                 r[key].AveTransOceanLength = r[key].AveTransOceanLength;
                //                                 r[key].AveTransOceanValue = r[key].AveTransOceanValue;
                //                                 r[key].AveTransOceanAvg = r[key].AveTransOceanAvg;
                //                         }   
                                        
                //                         // else {
                //                         //         r[key].Lane = test;
    
                //                         //         r[key].AveTransOceanLength += 1;
                //                         //         r[key].AveTransOceanValue += e.Value;
                //                         //         r[key].AveTransOceanAvg = Math.round(r[key].AveTransOceanValue/r[key].AveTransOceanLength, 1);

                //                         //         r[key].OTPULength = r[key].OTPULength;
                //                         //         r[key].OTPUvalue = r[key].OTPUvalue;
                //                         //         r[key].OTPUavg = r[key].OTPUavg;
                //                         //         r[key].OTDelLength = r[key].OTDelLength;
                //                         //         r[key].OTDelvalue = r[key].OTDelvalue;
                //                         //         r[key].OTDelavg = r[key].OTDelavg;
                //                         //         r[key].TransitSLAlength  = r[key].TransitSLAlength ;
                //                         //         r[key].TransitSLAvalue = r[key].TransitSLAvalue;
                //                         //         r[key].TransitSLAavg = r[key].TransitSLAavg;
                //                         //         r[key].AveTransAirLength = r[key].AveTransAirLength;
                //                         //         r[key].AveTransAirValue = r[key].AveTransAirValue;
                //                         //         r[key].AveTransAirAvg = r[key].AveTransAirAvg;

                //                         // }
                //                 // r[key].instances += e.instances
                //                 }
                //                 return r;
                //         },
                //         {})
                //         )


              