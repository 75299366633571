import React, {
    PureComponent
}                         from 'react';
// import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';


import FlatButton from 'material-ui/FlatButton'
import { Link, withRouter } from 'react-router-dom'


import StatsCard from '../../../components/ComponentsGeneral/StatsCard/StatsCard'

const mapStateToProps = store =>
({
  currentUserEmail:               store.userServicesReducer.currentUserEmail,
  userSignInData:                 store.userServicesReducer.userSignInData,
  userSettingsLanguage:           store.userServicesReducer.userSettingsLanguage
});


class contentHomeView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            nav: ''
          };
      }

    componentDidMount() {
            
        // if(this.props.userSignInData.ProductZu === 1){
        //     this.props.history.push('/home/zulily?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null')
        // }
    }


    saveNavSelectionToLocalStorage(navMenuToSave) {
      try{
        localStorage.setItem('navMenu', navMenuToSave)
      }
      catch(e){
        console.log('error', e)
      }
    }


    homePageCustomerCards  = (e, label) => {
        this.setState({ 
            nav: label
          });

        this.saveNavSelectionToLocalStorage(label)

        var navLabelLower = label.toLowerCase()
        var navLabel =  navLabelLower.replace(/\s/g,'')
        // this.props.history.push('/home/' + navLabel)
        this.props.history.push('/home/' + navLabel + '?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null&customercode=null&intransit=null&mode=null&carrier=null&shipmenttype=null&shiptocountry=null')

      }
    

 

   
    render() {
        var navMenu= []
        navMenu = JSON.parse(localStorage.getItem('PageConfig.CustomerName'))

        let navStat;
        navStat = <div></div>

        if(navMenu.length == 1){

          var navLabelFull = navMenu[0].label

          var navLabelLower = navLabelFull.toLowerCase()
          var navLabel =  navLabelLower.replace(/\s/g,'')

          this.setState({ 
            nav: navLabelFull
          });

        this.saveNavSelectionToLocalStorage(navLabelFull)

          this.props.history.push('/home/' + navLabel + '?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null&customercode=null&intransit=null&mode=null&carrier=null&shipmenttype=null&shiptocountry=null')

        }

        else{

        navStat = navMenu.map(
                ({label}, idx) => {
                    return (
                        <div className="col-md-3" style={{cursor: 'pointer'}} onClick={(e) => this.homePageCustomerCards(e, label)}>
                        <StatsCard
                            statValue=  {label}
                            icon=      {(<i className="fa fa-truck"></i>)}
                            backColor=  {'blue'}
                            isMobile=   {this.props.isMobile}

                        />
                        </div>
                    );
                }
            )
        }
        

        return(

            <div>

                <div
                    className="row"  
                    style={{marginBottom: '15px'}}>
  
                </div>


                <div
                    className="row"  
                    style={{marginLeft:'15px', marginRight:'15px', marginBottom: '5px'}}>
                {navStat}
                </div>



            </div>
            
        );
    }
}

export default connect(mapStateToProps)(withRouter(contentHomeView));

  
  