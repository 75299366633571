import * as types from '../constants/gbActionTypes';

import {appSettings} from '../../app/appSettings'

export const gbGetMawbDetails = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_MAWB_DETAILS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );  


export const gbGetOTDtoMC = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_OTD_TO_MC_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );
    
export const gbGetOTDtoFlightDepart = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_OTD_TO_FLIGHT_DEPART_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );    


export const gbGetDailyShipmentsToPorts = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_DAILY_SHIP_PORTS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} ); 


export const gbGetOTDtoFlightArrival = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_OTD_TO_FLIGHT_ARRIVAL_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  
    
export const gbGetAgingChart = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_AGING_CHART_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            filter: _filter,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  

export const gbGetDimWeightChart = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_DIM_WEIGHT_CHART_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            filter: _filter,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      
        

export const gbGetStatCardValues = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_STAT_CARD_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );        

export const gbGetOTDtoThreeAvgs = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_OTD_TO_THREE_AVGS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );     


export const gbGetMCDetails = ( _db, _email,  _mawb, _mc, _hawb ) =>
( {
    type:    types.GB_MC_DETAILS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb
    }
} );  


export const gbGetHawbDetails = ( _db, _email,  _mawb, _mc, _hawb, _trackingno ) =>
( {
    type:    types.GB_HAWB_DETAILS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        trackingno: _trackingno
    }
} );  

export const gbGetHawbDetailsMC = ( _db, _email,  _mawb, _mc, _hawb, _trackingno ) =>
( {
    type:    types.GB_HAWB_MC_DETAILS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        trackingno: _trackingno
    }
} );  


export const gbGetHawbReportingData = ( _db, _email,  _mawb, _mc, _hawb, _trackingno, _startDT, _endDT, _dateType, _filter, _filterValue, _notDeparted,_inTransit ,_portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype) =>
( {
    type:    types.GB_HAWB_REPORTING_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        trackingno: _trackingno,


        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,

        filter: _filter,
        filterValue: _filterValue,


        notDeparted: _notDeparted,
        inTransit: _inTransit,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    

    }
} ); 





export const gbGetJobEvents = ( _db, _email, _startDT, _endDT, _mawb, _mc, _hawb, _filter) =>
( {
    type:    types.GB_JOB_EVENTS_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter

    }
} );  

export const gbGetIBCInboundDtl = ( _db, _email,  _mawb, _mc, _hawb ) =>
( {
    type:    types.GB_IBC_DTL_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb
    }
} );  

export const gbGetHazMat = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_HAZ_MAT_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );  

export const gbGetChartPendingSailing = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_PENDING_SAILING_CHART_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            filter: _filter,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


export const gbGetChartDemurrage = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_DEMURRAGE_CHART_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        filter: _filter,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );  


export const gbGetUpsMI = ( _db, _email, _trackingno ) =>
( {
    type:    types.GB_UPS_MI_DATA_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,

        trackingno: _trackingno
    }
} );  


export const gbGetChartAvgCustomClear = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_AVG_CUST_CLEAR_DATA_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


    export const gbGetChartReportingTransitMetrics = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_CHART_TRANS_METRICS_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );     



export const gbGetChartOriginFinalMileToDelivery = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


export const gbGetDayOfWeekMetrics = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _filter, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_DAY_OF_WEEK_METRICS_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            filter: _filter,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


export const gbGetDataExport = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_DATA_EXPORT_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


export const gbGetDataReportsExport = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
( {
    type:    types.GB_DATA_REPORTS_EXPORT_GET,
    payload: {
        db: appSettings.gbDB,
        email: _email,
        startDT: _startDT,
        endDT: _endDT,
        dateType: _dateType,
        notDeparted: _notDeparted,
        portExit: _portExit,
        portEntry: _portEntry,
        finalMileCarrier: _finalMileCarrier,
        brokerageServ: _brokerageServ,

        mawb: _mawb,
        mc: _mc,
        hawb: _hawb,
        filter: _filter,
        filterValue: _filterValue,
        inTransit: _inTransit,
        customercode: _customercode, 
        shipmenttype: _shipmenttype
    }
} );     

    export const gbResetDataExport = ( ) =>
    ( {
        type:    types.GB_RESET_DATA_EXPORT,
        payload: {

        }
    } );      
    

    export const gbGetChartDataExport = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_CHART_DATA_EXPORT_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      

    export const gbGetChartDetailDataExport = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_CHART_DETAIL_DATA_EXPORT_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      


    export const gbGetFirstScanToDeliveryMetric = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_FIRST_SCAN_TO_DELIVERY_METRIC_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      

    export const gbGetFlightDepartToDeliveryMetric = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      

    export const gbGetFirstScanToRecoveryMetric = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_FIRST_SCAN_TO_RECOVERY_METRIC_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      

    export const gbGetManifestToDeliveryMetric = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _mawb, _mc, _hawb, _filter, _filterValue, _inTransit, _customercode, _shipmenttype ) =>
    ( {
        type:    types.GB_MANIFEST_TO_DELIVERY_METRIC_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
    
            mawb: _mawb,
            mc: _mc,
            hawb: _hawb,
            filter: _filter,
            filterValue: _filterValue,
            inTransit: _inTransit,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );      



    export const gbGetChartManifestToDelivery = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_MANIFEST_TO_DELIVERY_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  

    export const gbGetMissingUpsEventsAPI = ( _db, _email, _trackingNumber ) =>
	( {
		type:    types.GB_MISSING_UPS_EVENTS_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            trackingNumber: _trackingNumber
        }
    } );  


    export const gbGetChartInWhsToDelivery = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_INWHS_TO_DELIVERY_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  



    export const gbGetChartInWhsToFirstScan = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_INWHS_TO_FIRST_SCAN_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


    export const gbGetGetChartDailyScanCount = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_DAILY_SCAN_COUNT_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  

    export const gbGetChartInWhsToFlightDepart = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_INWHS_TO_FLIGHT_DEPART_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  

    export const gbGetChartInWhsToFreightRecovery = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_INWHS_TO_FREIGHT_RECOVERY_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  

    export const gbGetChartFlightDepartToFirstScan = ( _db, _email, _startDT, _endDT, _dateType, _notDeparted, _portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype ) =>
	( {
		type:    types.GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            startDT: _startDT,
            endDT: _endDT,
            dateType: _dateType,
            notDeparted: _notDeparted,
            portExit: _portExit,
            portEntry: _portEntry,
            finalMileCarrier: _finalMileCarrier,
            brokerageServ: _brokerageServ,
            customercode: _customercode, 
            shipmenttype: _shipmenttype
        }
    } );  


    export const gbGetBulkHawbDetails = ( _db, _email, _trackingNoString ) =>
	( {
		type:    types.GB_BULK_HAWB_DETAILS_GET,
        payload: {
            db: appSettings.gbDB,
            email: _email,
            trackingNoString: _trackingNoString
        }
    } );  