import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import GBFilterMain         from '../../../components/ComponentsGB/GBFilterMain/GBFilterMain';
import GBChartReportingContainer    from '../../../components/ComponentsGB/GBReporting/GBChartReporting/GBChartReportingContainer';
import GBShipmentListContainer    from '../../../components/ComponentsGB/GBShipmentList/GBShipmentListContainer';
import {appSettings} from '../../../app/appSettings'
import GBChartShipToPortContainer from '../../../components/ComponentsGB/GBChartShipToPort/GBChartShipToPortContainer'
import GBShipToStateReportingContainer from '../../../components/ComponentsGB/GBReporting/GBShipToStateReporting/GBShipToStateReportingContainer'
// import GBDayOfWeekMetricContainer from '../../../components/ComponentsGB/GBReporting/GBDayOfWeekMetric/GBDayOfWeekMetricContainer'

export default class gbReportingView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,
            languageSelection: 'English'
          };
      }

      componentDidMount() {

        this.getLocalStorageLanguageSelection()

    }

    getLocalStorageLanguageSelection() {
        let languageSelectionValue;
        try{
          languageSelectionValue = localStorage.getItem('PageConfig.UserSettings.Language');
          this.setState({
            languageSelection: languageSelectionValue
          })
        }
        catch(e){
          this.setState({
            languageSelection: "English"
          })
        }
  
      }


        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        UNSAFE_componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>
                
                <GBFilterMain isMobile={isMobile} languageSelection={this.state.languageSelection}/>

                <AnimatedView>
                <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 


                <div className="row" >
                    <div className="col-md-6">
                    <GBChartReportingContainer isMobile={isMobile}/>
                    </div>
                    <div className="col-md-6">
                    <GBChartShipToPortContainer isMobile={isMobile}/>
                    </div>

                </div>

                    <GBShipmentListContainer isMobile={isMobile}/>

                

                    {/* <GBDayOfWeekMetricContainer isMobile={isMobile}/> */}

                    <GBShipToStateReportingContainer isMobile={isMobile}/>

                </div>


                </AnimatedView>

            </div>

        );
    }
}
