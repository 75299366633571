import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
// import { ResponsiveBar } from '@nivo/bar'
import {withRouter} from 'react-router-dom';
import * as moment from 'moment'

import {
  BarChart, Bar, Brush, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';

import {
  ResponsiveContainer,
} from 'recharts';
import { getDate } from 'date-fns/esm';


  class ShipmentHistoryBar extends PureComponent {
    constructor( props )
    {
      super( props );
    }


    onClickLate = (bar, i) => {

      // moment(bar.Date).format('YYYY-MM-DD')

      var start = new Date();
      start.setDate( start.getDate() - 90 );

      var sDTLate = new Date(bar.Date);
      var eDTLate = new Date(bar.Date);
      eDTLate.setDate( eDTLate.getDate() + 6 );


      if (start > sDTLate){
        sDTLate = start

      }

      var startDTLate = moment(sDTLate).format('YYYY-MM-DD')
      var endDTLate = moment(eDTLate).format('YYYY-MM-DD')

      

      this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'lateDelivered' , null, null, null, this.props.modeGlobalFilter, this.props.forwarderGlobalFilter, this.props.serviceGlobalFilter, this.props.laneGlobalFilter, null, null, "'" + startDTLate + "'", "'" + endDTLate + "'", null, null, null, null, null, null)

      this.props.history.push({
        pathname: 'google/shipmenttrackerfiltered'
        }); 
    }

    onClickOnTime = (bar, i) => {


      var start = new Date();
      start.setDate( start.getDate() - 90 );

      // var start = new Date( getDate() - 90 )

      var sDTOnTime = new Date(bar.Date);
      var eDTOnTime = new Date(bar.Date);
      eDTOnTime.setDate( eDTOnTime.getDate() + 6 );


      if (start > sDTOnTime){
        sDTOnTime = start

      }

      var startDTOnTime = moment(sDTOnTime).format('YYYY-MM-DD')
      var endDTOnTime = moment(eDTOnTime).format('YYYY-MM-DD')


      this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, 'DeliveredOnTime' , null, null, null, this.props.modeGlobalFilter, this.props.forwarderGlobalFilter, this.props.serviceGlobalFilter, this.props.laneGlobalFilter, null, null, "'" + startDTOnTime + "'", "'" + endDTOnTime + "'", null, null, null, null, null, null)

      this.props.history.push({
        pathname: 'google/shipmenttrackerfiltered'
        }); 
    }


    render() {
      if (this.props.isShipHistoryBarLoading) {
        return(
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Shipment Delivery History'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        )
      }

      return(
        
        <section className="panel">
            <header className="panel-heading">
              {'Shipment Delivery History'}
            </header>
        <div style={{height: 400, background: "#FFF"}}>



        <div style={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <BarChart
        // onClick={this.onClick}
        // onClick={(bar, data, event, index, key, payload) => this.onClick(bar, data, event, index, key, payload)}
        // width={500}
        // height={300}
        data={this.props.shipmentHistoryBar}
        margin={{
          top: 5, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="Date" />
        <YAxis allowDecimals={false}/>
        <Tooltip />
        <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px' }} />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="Late" stackId="a" fill="#f43e1f" onClick={(bar, i) => this.onClickLate(bar, 'Late')}/>
        <Bar dataKey="On Time" stackId="a" fill="#37ba30" onClick={(bar, i) => this.onClickOnTime(bar, 'On Time')}/>
      </BarChart>
      </ResponsiveContainer>
      </div>


      </div>
      </section>

      );
    }
  }
  
  export default withRouter(ShipmentHistoryBar);


// <Brush dataKey="Date" height={30} stroke="#8884d8" />



//   ///

//   <ResponsiveBar
//   data={this.props.shipmentHistoryBar}
//   keys={[
//       "Late", "On Time"
//   ]}
//   indexBy="Date"
//   margin={{
//       "top": 50,
//       "right": 150,
//       "bottom": 85,
//       "left": 60
//   }}
//   padding={0.3}
//   colors={{
//       "scheme": "nivo"
//   }}
//   defs={[
//       {
//           "id": "red",
//           "type": "patternLines",
//           "background": "inherit",
//           "color": "#f43e1f",
//           "rotation": -45,
//           "lineWidth": 20,
//           "spacing": 10
//       },
//       {
//           "id": "yellow",
//           "type": "patternLines",
//           "background": "inherit",
//           "color": "#f1e15b",
//           "rotation": -45,
//           "lineWidth": 20,
//           "spacing": 10
//       },
//       {
//         "id": "green",
//         "type": "patternLines",
//         "background": "inherit",
//         "color": "#37ba30",
//         "rotation": -45,
//         "lineWidth": 20,
//         "spacing": 10
//     }
//   ]}
//   fill={[
//       {
//           "match": {
//               "id": "On Time"
//           },
//           "id": "green"
//       },

//       {
//         "match": {
//             "id": "Late"
//         },
//         "id": "red"
//     }
//   ]}
//   borderColor={{
//       "from": "color",
//       "modifiers": [
//           [
//               "darker",
//               1.6
//           ]
//       ]
//   }}

//   xScale={{
//     type: 'linear',
//     min: 'auto',
//     max: 'auto',
//   }}
//   axisBottom={{
//       "tickSize": 5,
//       "tickPadding": 5,
//       "tickRotation": -45,
//       "legend": "Week",
//       "legendPosition": "middle",
//       "legendOffset": 70
//   }}
//   axisLeft={{
//       "tickSize": 5,
//       "tickPadding": 5,
//       "tickRotation": 0,
//       "legend": "Count",
//       "legendPosition": "middle",
//       "legendOffset": -40
//   }}
//   labelSkipWidth={12}
//   labelSkipHeight={12}
//   labelTextColor="black"
//   legends={[
//       {
//           "dataFrom": "keys",
//           "anchor": "bottom-right",
//           "direction": "column",
//           "justify": false,
//           "translateX": 120,
//           "translateY": 0,
//           "itemsSpacing": 2,
//           "itemWidth": 100,
//           "itemHeight": 20,
//           "itemDirection": "left-to-right",
//           "itemOpacity": 0.85,
//           "symbolSize": 20,
//           "effects": [
//               {
//                   "on": "hover",
//                   "style": {
//                       "itemOpacity": 1
//                   }
//               }
//           ]
//       } 
//   ]}
//   // tooltip={function(){}}
//   // isInteractive={false}
//   animate={true}
//   motionStiffness={90}
//   motionDamping={15}
// />