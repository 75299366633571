import {connect} from 'react-redux';
import React, {Component} from 'react';
import MilestoneAlert from './MilestoneAlerts'
import * as actions from '../../../redux/actions/googleActions';

import {withRouter} from 'react-router-dom';
import queryString from 'query-string';


//add props from reducer here
const mapStateToProps = store =>
({
    isMilestoneAlertLoading:   store.milestoneAlertReducer.isMilestoneAlertLoading,
    milestoneAlertData:        store.milestoneAlertReducer.milestoneAlertData,

    isAlertKpiTrendLoading:         store.alertKpiTrendReducer.isAlertKpiTrendLoading,
    alertKpiTrendData:              store.alertKpiTrendReducer.alertKpiTrendData,
    userSignInData:                 store.userServicesReducer.userSignInData,
    currentUserEmail:             store.userServicesReducer.currentUserEmail
    
    // auth: state.auth
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    
    // getMilestoneAlertData:( _db, _email, _jobid, _housebill, _mode, _forwarder, _service, _lane ) =>
    // {
    //     dispatch( actions.getMilestoneAlertData( _db, _email, _jobid, _housebill, _mode, _forwarder, _service, _lane ) )
    // },
    getMilestoneAlertData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getMilestoneAlertData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },



    getShipmentTrackerDataByJobID:(  _db, _email, _housebill ) =>
    {
        dispatch( actions.getShipmentTrackerDataByJobID( _db, _email, _housebill ) )
    },

    getAlertKpiTrendData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) =>
    {
        dispatch( actions.getAlertKpiTrendData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT ) )
    },

    postShipModalComment:( _db, _email, _jobid, _comment, _type ) =>
    {
        dispatch( actions.postShipModalComment( _db, _email, _jobid, _comment, _type ) )
    },



});


class MilestoneAlertsContainer extends Component
{
	constructor(props)
	{
		super(props);
	}

    

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    

    


    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramInTransit = 'O'
    var paramMode = null
    var paramCarrier = null
    var paramFilter = null
    var paramFilterValue = null
  



    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
        paramInTransit = values.intransit
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
        paramCarrier = values.carrier
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }



    this.props.getMilestoneAlertData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );
    
    this.props.getAlertKpiTrendData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null );
	
    }
	
    render()
    {
    	return (
            <MilestoneAlert 
            isMilestoneAlertLoading={ this.props.isMilestoneAlertLoading }
            milestoneAlertData={ this.props.milestoneAlertData }
   
            postShipModalComment={this.props.postShipModalComment}

            isAlertKpiTrendLoading={ this.props.isAlertKpiTrendLoading }
            alertKpiTrendData={ this.props.alertKpiTrendData }

            getShipmentTrackerDataByJobID={this.props.getShipmentTrackerDataByJobID}
            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MilestoneAlertsContainer));



