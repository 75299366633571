import {connect} from 'react-redux';
import React, {Component} from 'react';
import ShipmentTrackerParam from './ShipmentTrackerParam'
import * as actions from '../../../redux/actions/googleActions';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

//add props from reducer here
const mapStateToProps = store =>
({
    shipmentTrackerData:            store.shipmentTrackerReducer.shipmentTrackerData,
    isShipmentTrackerLoading:       store.shipmentTrackerReducer.isShipmentTrackerLoading,
    shipTrackModalShow:             store.shipmentTrackerReducer.shipTrackModalShow,
    shipTrackModalData:             store.shipmentTrackerReducer.shipTrackModalData,
    shipTrackModalRow:              store.shipmentTrackerReducer.shipTrackModalRow,

    currentUserEmail:             store.userServicesReducer.currentUserEmail,
    userSignInData:                 store.userServicesReducer.userSignInData
});

//add actions here
const mapDispatchToProps = dispatch =>
({

    getShipmentTrackerData:( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) =>
    {
        dispatch( actions.getShipmentTrackerData( _db, _email, _housebill, _statCardInput, _alertMilestoneInput, _startDT, _endDT, _mode, _forwarder, _service, _lane, _jobStatus, _jobid, _startActualDelDT, _endActualDelDT, _customerName, _customerBusUnit, _projectCode, _filter, _filterValue, _view ) )
    },

    openShipModal:(_db,  _email, _jobid, _row ) =>
    {
        dispatch( actions.showShipmentTrackerModalGetComments( _db, _email, _jobid, _row ) )
    },

    hideShipmentTrackerModal:() =>
    {
        dispatch( actions.hideShipmentTrackerModal( null ) )
    },

    postShipModalComment:( _db, _email, _jobid, _comment, _type ) =>
    {
        dispatch( actions.postShipModalComment( _db, _email, _jobid, _comment, _type ) )
    },

    modalToggleHotShip:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleHotShip( _db, _email, _jobid, _bool ) )
    },

    modalToggleInternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleInternalAction( _db, _email, _jobid, _bool ) )
    },

    modalToggleExternalAction:( _db, _email, _jobid, _bool ) =>
    {
        dispatch( actions.modalToggleExternalAction( _db, _email, _jobid, _bool ) )
    }



});


class ShipmentTrackerParamContainer extends Component
{
	constructor(props)
	{
		super(props);
    }
    
    
	
    // componentDidMount()
	// {
    //     var housebill = null
    //     // var statCardInput = null
    //     // var alertMilestoneInput = null
    //     // var startDT = null
    //     // var endDT = null
    //     // var mode = null
    //     // var forwarder = null
    //     // var service = null
    //     // var lane = null
    //     // var jobStatus = null
    //     // var jobid = null
    //     // var startActualDelDT = null
    //     // var endActualDelDT = null

    //     //<PrivateRoute exact path="/home/reporting/:startdt?/:enddt?/:mode?/:network?/:measure?" component={reportingView} />
    //     const values = queryString.parse(this.props.location.search)

   
    //     if(typeof(values.search) !== 'undefined' || values.startdt != null ){
    //         housebill = values.search
    //     }
    //     // if(typeof(values.enddt) !== 'undefined' || values.enddt != null ){
    //     //     paramEndDT = values.enddt
    //     // }
    //     // if(typeof(values.mode) !== 'undefined' || values.mode != null ){
    //     //     paramMode = values.mode
    //     // }
    //     // if(typeof(values.network) !== 'undefined' || values.network != null ){
    //     //     paramNetwork = values.network
    //     // }
    //     // if(typeof(values.measure) !== 'undefined' || values.measure != null ){
    //     //     paramMeasure = values.measure
    //     // }


    //     // this.props.getNetworkReportingData( this.props.currentUserEmail, null, null, null, null, null );

    //     // this.props.getNetworkReportingData( this.props.userSignInData.DBName, this.props.currentUserEmail, paramStartDT, paramEndDT, paramNetwork, paramMeasure, paramMode );
    //     // this.props.getNetworkReportingData( this.props.currentUserEmail, null, null, null, null, null );

    //     this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, housebill, null, null, null, null, null, null, null, null, null, null, null, null );

    // }
    




    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    

    


    handleUrlParam = () => {
    var housebill = null

    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramInTransit = null
    var paramMode = null
    var paramCarrier = null
    var paramFilter = null
    var paramFilterValue = null




    const values = queryString.parse(this.props.location.search)


    if(typeof(values.search) !== 'undefined' && values.search != null ){
        housebill = values.search
    }
    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.intransit) !== 'undefined' && values.intransit != null ){
        paramInTransit = values.intransit
    }
    if(typeof(values.mode) !== 'undefined' && values.mode != null ){
        paramMode = values.mode
    }
    if(typeof(values.carrier) !== 'undefined' && values.carrier != null ){
        paramCarrier = values.carrier
    }
    if(typeof(values.filter) !== 'undefined' && values.filter != null ){
        paramFilter = values.filter
    }
    if(typeof(values.filterValue) !== 'undefined' && values.filterValue != null ){
        paramFilterValue = values.filterValue
    }



    this.props.getShipmentTrackerData( this.props.userSignInData.DBName, this.props.currentUserEmail, housebill, null, null, paramStartDT, paramEndDT, paramMode, paramCarrier, null, null, paramInTransit, null, null, null, null, null, null, paramFilter, paramFilterValue, null );
    


    }
	
    render()
    {
    	return (
            <ShipmentTrackerParam 
            shipmentTrackerData={ this.props.shipmentTrackerData }
            isShipmentTrackerLoading={ this.props.isShipmentTrackerLoading }
            shipTrackModalShow={this.props.shipTrackModalShow}
            openShipModal={this.props.openShipModal}
            hideShipmentTrackerModal={this.props.hideShipmentTrackerModal}
            shipTrackModalData={this.props.shipTrackModalData}
            shipTrackModalRow={this.props.shipTrackModalRow}
            postShipModalComment={this.props.postShipModalComment}
            modalToggleHotShip={this.props.modalToggleHotShip}
            modalToggleInternalAction={this.props.modalToggleInternalAction}
            modalToggleExternalAction={this.props.modalToggleExternalAction}
        
            currentUserEmail={this.props.currentUserEmail}
            userSignInData={this.props.userSignInData}
            getShipmentTrackerData={this.props.getShipmentTrackerData}
            />
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShipmentTrackerParamContainer));



