import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import FilterMainZU         from '../../../components/ComponentsZU/FilterMainZU/FilterMainZU';
import EvolveBrokerageZUContainer    from '../../../components/ComponentsZU/EvolveBrokerageZU/EvolveBrokerageZUContainer';
import {appSettings} from '../../../app/appSettings'


export default class zuEvolveBrokerageView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(

            <div>
            <FilterMainZU isMobile={isMobile}/>
        
            <AnimatedView>
                
            <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 
                
                <EvolveBrokerageZUContainer isMobile={isMobile}/>
            </div>

            </AnimatedView>
            </div>

        );
    }
}
