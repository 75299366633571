
// this is the detailed view from clicking a row in the shipment tracker table

import React, {
    PureComponent
  }                         from 'react';
import {
    Table,
    TableBody
  }                         from '../../../components/ComponentsGeneral/table';
import AnimatedView from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import {
  valueDeliveryDayFormatter, 
  shipmentProgressFormatter, 
  milestoneAlertDetailsSeverityFormatter, 
  shipmentTagFormatter,
  shipDetailKPIFormatter} from '../../../resources/logic/formatting';
// import {appConfig} from '../../app/appConfig';
// import ProgressBar from 'react-bootstrap/ProgressBar'
import BootstrapTable from 'react-bootstrap-table-next';
import Loader from 'react-loader-spinner'
import Button from 'react-bootstrap/Button'
import Select from "react-dropdown-select"; //https://sanusart.github.io/react-dropdown-select/
import ActionModalContainer from '../Modals/ActionModalContainer'
import StatsCard  from '../statsCard/StatsCard';
import {appSettings} from '../../../app/appSettings'

const ediTime = parseFloat(Math.round(10 + Math.random() * (15 - 10) * 100) / 100 + 10).toFixed(1) + ' Mins'


  class ShipmentDetails extends React.Component {
    constructor( props )
    {
      super( props );    this.state = {
        width: window.innerWidth,
      };
  
    }

    componentWillMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
   
    // make sure to remove the listener
    // when the component is not mounted anymore
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }
   
    handleWindowSizeChange = () => {
      this.setState({ width: window.innerWidth });
    };
  

    clickUnSubscribeButton = (e) => {
      e.preventDefault();
      this.props.shipmentSubscribe( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipmentDetailsRowData.JobID, 0)
      window.alert("You have unsubscribed.");
    }

    clickSubscribeButton = (e) => {
      e.preventDefault();
      this.props.shipmentSubscribe( this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipmentDetailsRowData.JobID, 1)
      window.alert("You have subscribed.");
    }


    clickActionButton = (e) => {
      e.preventDefault();


      this.props.openShipModal(this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipmentDetailsRowData.JobID, this.props.shipmentDetailsRowData);
 
    }


    dropDownValues(values) {

      if (typeof(values[0]) !== 'undefined' && values[0] != null){
        if (window.confirm('Confirm service issue update.')) 

        this.props.postServiceIssue(this.props.userSignInData.DBName, this.props.currentUserEmail, this.props.shipmentDetailsRowData.JobID, values[0].value )
      }
    }


    render() {

      const { width } = this.state;
      const isMobile = width <= appSettings.mobileWidth;
      
      
      const columns_events = [{
          dataField: 'DT',
          text: 'Date',
          // sort: true
        }, {
          dataField: 'Description',
          text: 'Event',
          // sort: true
        }
      ]

      const mobileColumns_jobsLegs = [
        {
        dataField: 'ArriveDateTime',
        text: 'Arrive DateTime',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        }
      }, {
        dataField: 'AirportOrigin',
        text: 'Airport Origin',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        }
      }, {
        dataField: 'AirportDestination',
        text: 'Airport Destination',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        }
      }
      ]

      const desktopColumns_jobsLegs = [{
        dataField: 'LegSeq',
        text: 'Leg Seq',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'DepartDateTime',
        text: 'Depart DateTime',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'ArriveDateTime',
        text: 'Arrive DateTime',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        }
      }, {
        dataField: 'Carrier',
        text: 'Carrier',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        }
      }, {
        dataField: 'Flight',
        text: 'Flight',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        }
      }, {
        dataField: 'AirportOrigin',
        text: 'Airport Origin',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        }
      }, {
        dataField: 'AirportDestination',
        text: 'Airport Destination',
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        }
      }
      ]

      let columns_jobsLegs;
      if(isMobile){
        columns_jobsLegs = mobileColumns_jobsLegs
      }
      else{
        columns_jobsLegs = desktopColumns_jobsLegs
      }


      const mobileColumns_jobsRefNum = [{

        dataField: 'ReferenceNumber',
        text: 'Ref Number',
        headerStyle: (colum, colIndex) => {
          return { width: '75%' , textAlign: 'center' };
        }
      }, {
        dataField: 'RefType',
        text: 'Ref Type',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        }
      }
      ]

      const desktopColumns_jobsRefNum = [{
        dataField: 'Seq',
        text: 'Seq',
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'RefLabel',
        text: 'Ref Label',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'Office',
        text: 'Office',
        headerStyle: (colum, colIndex) => {
          return { width: '14%' , textAlign: 'center' };
        }
      }, {
        dataField: 'ShipmentNumber',
        text: 'Shipment Number',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        }
      }, {
        dataField: 'ReferenceNumber',
        text: 'Ref Number',
        headerStyle: (colum, colIndex) => {
          return { width: '26%' , textAlign: 'center' };
        }
      }, {
        dataField: 'AddressType',
        text: 'Address Type',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        }
      }, {
        dataField: 'RefType',
        text: 'Ref Type',
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        }
      }
      ]

      let columns_jobsRefNum;
      if(isMobile){
        columns_jobsRefNum = mobileColumns_jobsRefNum
      }
      else{
        columns_jobsRefNum = desktopColumns_jobsRefNum
      }


      const mobileShipCommentsColumns = [{
        dataField: 'CommentDate',
        text: 'Date',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'Comment',
        text: 'Comment',
        headerStyle: (colum, colIndex) => {
          return { width: '75%' , textAlign: 'center' };
        }
      }
      ]

      const desktopShipCommentsColumns = [{
        dataField: 'CommentDate',
        text: 'Date',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'User',
        text: 'User',
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'GroupName',
        text: 'Group',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        }
      }, {
        dataField: 'ShipmentCommentTypeDesc',
        text: 'Comment Type',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        }
      }, {
        dataField: 'Comment',
        text: 'Comment',
        headerStyle: (colum, colIndex) => {
          return { width: '35%' , textAlign: 'center' };
        }
      }
      ]

      let shipCommentsColumns;
      if(isMobile){
        shipCommentsColumns = mobileShipCommentsColumns
      }
      else{
        shipCommentsColumns = desktopShipCommentsColumns
      }

      const serviceIssuesColumns = [{
        dataField: 'Date',
        text: 'Date',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'User',
        text: 'User',
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'GroupName',
        text: 'Group',
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        }
      }, {
        dataField: 'ServiceIssueDesc',
        text: 'Service Issue',
        headerStyle: (colum, colIndex) => {
          return { width: '35%' , textAlign: 'center' };
        }
      }
      ]

      const mobileMilestoneAlertsColumns= [{
        dataField: 'MilestoneAlert',
        text: 'Milestone Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        // sort: true
      },{

        dataField: 'Alert_Category',
        text: 'Severity',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        formatter: milestoneAlertDetailsSeverityFormatter
        // sort: true
      }

      ]

      const desktopMilestoneAlertsColumns= [{
        dataField: 'MilestoneAlert',
        text: 'Milestone Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'STAKES',
        text: 'Stake',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'StakeTargetDate',
        text: 'Stake Targe Date',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Alert_Category',
        text: 'Severity',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        formatter: milestoneAlertDetailsSeverityFormatter
        // sort: true
      },{
        dataField: 'Hours_Into_Alert',
        text: 'Hrs into Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Next_Alert',
        text: 'Next Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Hours_Until_Next_Alert',
        text: 'Hrs Until Next Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      } 
      ]


      let milestoneAlertsColumns;
      if(isMobile){
        milestoneAlertsColumns = mobileMilestoneAlertsColumns
      }
      else{
        milestoneAlertsColumns = desktopMilestoneAlertsColumns
      }


      // let mobileHistoricMilestoneAlertsColumns
      const mobileHistoricMilestoneAlertsColumns= [{
        dataField: 'MilestoneAlert',
        text: 'Milestone Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        // sort: true
      },{

        dataField: 'Alert_Category',
        text: 'Severity',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        formatter: milestoneAlertDetailsSeverityFormatter
        // sort: true
        }
      ]


      // let desktopHistoricMilestoneAlertsColumns
      const desktopHistoricMilestoneAlertsColumns= [{
        dataField: 'MilestoneAlert',
        text: 'Milestone Alert',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'STAKES',
        text: 'Stake',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'StakeTargetDate',
        text: 'Stake Targe Date',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Alert_Category',
        text: 'Severity',
        align: 'center',
        // sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        formatter: milestoneAlertDetailsSeverityFormatter
        // sort: true
      },{
      //   dataField: 'Hours_Into_Alert',
      //   text: 'Hrs Alert Was Active',
      //   align: 'center',
      //   // sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '20%' , textAlign: 'center' };
      //   },
      //   // sort: true
      // },{
      dataField: 'AlertStatus',
      text: 'Alert Status',
      align: 'center',
      // sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '15%' , textAlign: 'center' };
      },
      // sort: true
    },  
      ]


      let historicMilestoneAlertsColumns;
      if(isMobile){
        historicMilestoneAlertsColumns = mobileHistoricMilestoneAlertsColumns
      }
      else{
        historicMilestoneAlertsColumns = desktopHistoricMilestoneAlertsColumns
      }


      const selectIssue = [
        { label: "Carrier", value: "1" },
        { label: "Vendor", value: "2" },
        { label: "Document", value: "3" },
        { label: "Weather Delay", value: "4" },
        { label: "Labor Dispute", value: "5" }
      ];

      let subscribeButton;

      let detailsTable;
      if (this.props.isShipmentDetailsLoading){
        detailsTable=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Shipment Details'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>
      }

      else if (!this.props.isShipmentDetailsLoading){
        
        if (this.props.shipmentDetailsRowData.Subscribed === 0) {
          subscribeButton = 
          <Button variant="primary" size="lg" block  style={{marginTop: '5px'}} onClick={this.clickSubscribeButton} >
                Subscribe for Email Updates
          </Button>
        }
        else if (this.props.shipmentDetailsRowData.Subscribed === 1){
          subscribeButton = 
          <Button variant="warning" size="lg" block  style={{marginTop: '5px'}} onClick={this.clickUnSubscribeButton} >
                Unsubscribe from Email Updates
          </Button>
        }

        detailsTable=
        <div>
        <div
        className="row"
        style={{marginBottom: '0px', overflowX: 'hidden!important'}}>
        <div className="col-md-4">
          <section className="panel">
          <header className="panel-heading">
          {'Shipment Details'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important'}}>
          <Table>
          <TableBody >

            <tr key={'0'} >
              <td>House Bill: <strong>{this.props.shipmentDetailsRowData.HouseBill}</strong></td>
            </tr>
            <tr key={'1'} >
              <td>Mode: <strong>{this.props.shipmentDetailsRowData.Mode}</strong></td>
            </tr>
            <tr key={'2'} >
              <td>PO: <strong>{this.props.shipmentDetailsRowData.PO}</strong></td>
            </tr>
            <tr key={'3'} >
              <td>ASN: <strong>{this.props.shipmentDetailsRowData.ASN}</strong></td>
            </tr>
            <tr key={'4'} >
              <td>Project Code: <strong>{this.props.shipmentDetailsRowData.ProjectCode}</strong></td>
            </tr>
            <tr key={'5'} >
              <td>Handling Count: <strong>{this.props.shipmentDetailsRowData.PackageCount}</strong></td>
            </tr>
            <tr key={'6'} >
              <td>Handling Type: <strong>{this.props.shipmentDetailsRowData.PackageType}</strong></td>
            </tr>
            <tr key={'7'} >
              <td>POD Signed By: <strong>{this.props.shipmentDetailsRowData.PODSignedBy}</strong></td>
            </tr>
            <tr key={'8'} >
              <td>Shipment Weight: <strong>{this.props.shipmentDetailsRowData.Value}</strong></td>
            </tr>
            <tr key={'9'} >
              <td>Weight Units: <strong>{this.props.shipmentDetailsRowData.Units}</strong></td>
            </tr>
            <tr key={'10'} >
              <td className="row" style={{marginLeft: '1px'}}> Tags:   &nbsp; { shipmentTagFormatter('x', this.props.shipmentDetailsRowData) } </td>
            </tr>
            <tr key={'11'} >
              {(() => {
                switch (this.props.userSignInData.GroupName) {
                  case 'OMNI' || 'Demo':   return     (
                    <td className="row" style={{marginLeft: '1px'}}> KPI:   &nbsp; { shipDetailKPIFormatter('x', this.props.shipmentDetailsRowData) } </td>
                  );
                  case 'Google':   return     (
                    <td> &nbsp; </td>
                  );              

                  case 0: return (
                      <td className="row" style={{marginLeft: '1px'}}> KPI:   &nbsp; { shipDetailKPIFormatter('x', this.props.shipmentDetailsRowData) } </td>
                  );
                  default: return ;
                }
              })()}
            </tr>

          </TableBody>
          </Table>
        </div>
              </section>
          </div>
          
          <div className="col-md-8">
          <section className="panel">
          <header className="panel-heading">
          {'Shipment Progress'}
        </header>            
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5'}}>
          <Table>
          <TableBody>
            <tr key={'0'} >
              <td> { shipmentProgressFormatter(this.props.shipmentDetailsRowData.PercentComplete) }  </td>
              <td style={{width: '0.5px', color: '#f5f5f5'}}> { '.'}  </td>
            </tr>
            <tr key={'1'} >
              <td>Delivery Days Remaining: <strong>{valueDeliveryDayFormatter(this.props.shipmentDetailsRowData.DeliveryDaysRemaining, this.props.shipmentDetailsRowData.Status, this.props.shipmentDetailsRowData.DeliveredLate, this.props.shipmentDetailsRowData.ActualDeliveryDate)}</strong></td>
            </tr>
            <tr key={'2'} >
              <td>Carrier Name: <strong>{this.props.shipmentDetailsRowData.SubCarrierName}</strong></td>
            </tr>
            <tr key={'3'} >
              <td>Customer Name: <strong>{this.props.shipmentDetailsRowData.CustomerName}</strong></td>
            </tr>
            <tr key={'4'} >
              <td>Customer Business Unit: <strong>{this.props.shipmentDetailsRowData.CustomerBusinessUnit}</strong></td>
            </tr>
            <tr key={'5'} >
              <td>Lane: <strong>{this.props.shipmentDetailsRowData.Lane}</strong></td>
            </tr>
            <tr key={'6'} >
              <td>Current Shipment Status: <strong>{this.props.shipmentDetailsRowData.CurrentShipmentStatus}</strong></td>
            </tr>
            <tr key={'7'} >
              <td>Current Shipment Status Date: <strong>{this.props.shipmentDetailsRowData.CurrentShipmentStatusDateTime}</strong></td>
            </tr>
            <tr key={'8'} >
              <td>Estimated Delivery Date: <strong>{this.props.shipmentDetailsRowData.EstimatedDeliveryDate}</strong></td>
            </tr>
            <tr key={'9'} >
              <td>Required Delivery Date: <strong>{this.props.shipmentDetailsRowData.ScheduledDeliveryDate}</strong></td>
            </tr>           
            <tr key={'10'} >
              <td>Shipment Date Time: <strong>{this.props.shipmentDetailsRowData.ShipmentDateTime}</strong></td>
            </tr>
            <tr key={'11'} >
              <td>Ready Date Time: <strong>{this.props.shipmentDetailsRowData.ReadyDateTime}</strong></td>
            </tr>
            <tr key={'12'} >
              <td>Delivery Appointment Date: <strong>{this.props.shipmentDetailsRowData.AppointmentDeliveryDate}</strong></td>
            </tr>
          </TableBody>
          </Table>
        </div>
      </section>
    </div>

        </div>

      <div
        className="row"
        style={{marginBottom: '0px'}}>

        <div className="col-md-12">
          <section className="panel">
          <header className="panel-heading">
          {'Address Details'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5'}}>
          <Table>

          <TableBody>

          <tr key={'0'} >
            <td>Ship From Address: <strong>{  this.props.shipmentDetailsRowData.SF_AddressName + ', ' + this.props.shipmentDetailsRowData.SF_AddressLine + ', ' + this.props.shipmentDetailsRowData.SF_City + ', ' + this.props.shipmentDetailsRowData.SF_StateProvinceCode + ' '  + this.props.shipmentDetailsRowData.SF_PostalCode + ', '  + this.props.shipmentDetailsRowData.SF_CountryCode}</strong></td>
          </tr>
          <tr key={'1'} >
            <td>Ship To Address: <strong>{  this.props.shipmentDetailsRowData.ST_AddressName + ', ' + this.props.shipmentDetailsRowData.ST_AddressLine + ', ' + this.props.shipmentDetailsRowData.ST_City + ', ' + this.props.shipmentDetailsRowData.ST_StateProvinceCode + ' '  + this.props.shipmentDetailsRowData.ST_PostalCode + ', '  + this.props.shipmentDetailsRowData.ST_CountryCode}</strong></td>
          </tr>
          
          </TableBody>

          </Table>
        </div>
              </section>
              
          </div>
          </div>


      <section className="panel">
        <header className="panel-heading">
          {'Shipment Events'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5'}}>
          <Table>

            <BootstrapTable 
            keyField='ID' 
            data={ this.props.shipmentDetails } 
            columns={ columns_events } 
            // expandRow={ expandRow }
            // rowEvents={ rowEvents }
            />

          </Table>
        </div>
      </section>
<br/>
      </div>
      }

      // if (this.props.isShipmentDetailsLoading && this.props.isShipCommentsLoading) {
      //   return(
      //     <div align="center">
      //     <section className="panel">
      //       <header className="panel-heading">
      //         {'Shipment Details'}
      //       </header>
      //       </section>
      //       <Loader 
      //         type="Plane"
      //         color="#00BFFF"
      //         height="50"	
      //         width="50"
      //       /> 
      //     </div>
      //   )
      // }


      let commentsTable;
      let serviceIssuesTable;
      let milestoneAlertsTable

      let jobsLegsTable;
      if (this.props.isJobsLegsLoading){
        jobsLegsTable=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Shipment Legs'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>      
        }
      else if (!this.props.isJobsLegsLoading){
        jobsLegsTable=
        <section className="panel">
        <header className="panel-heading">
          {'Shipment Legs'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5'}}>
          <Table>
            <BootstrapTable 
            keyField='UniqueID' 
            data={ this.props.JobsLegsData } 
            columns={ columns_jobsLegs } 
            />
          </Table>
        </div>
      </section>
      }

      let jobsRefNumTable;
      if (this.props.isJobsRefNumLoading){
        jobsRefNumTable=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Reference Numbers'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>      
        }
      else if (!this.props.isJobsRefNumLoading){
        jobsRefNumTable=
        <section className="panel">
        <header className="panel-heading">
          {'Reference Numbers'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5'}}>
          <Table>
            <BootstrapTable 
            keyField='UniqueID' 
            data={ this.props.JobsRefNumData } 
            columns={ columns_jobsRefNum } 
            />
          </Table>
        </div>
      </section>
      }


      if (this.props.isServiceIssueLoading){
      serviceIssuesTable=
      <div align="center">
      <section className="panel">
        <header className="panel-heading">
          {'Service Issues'}
        </header>
        </section>
        <Loader 
          type="Plane"
          color="#00BFFF"
          height="50"	
          width="50"
        /> 
      </div>      
      }

      else if (!this.props.isServiceIssueLoading){
        serviceIssuesTable=
        <section className="panel">
          <header className="panel-heading" style={{ backgroundColor: '#FFF'}}>
            {'Service Issues'}
          </header>

          <div style={{marginLeft: "auto", backgroundColor: '#FFF', marginRight: "auto", }}>
            <Select 
              options={ selectIssue } 
              clearable
              separator
              placeholder={ 'Add Service Issue...'}
              onChange={(values) => this.dropDownValues(values)}
              // onChange={

              //   window.alert("Confirm save.")
                
              //   // this.onChangeDropdownServiceIssue( row )
              // }
            />
          </div>

          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
          <Table>
            <BootstrapTable 
            keyField='CommentDate' 
            data={ this.props.serviceIssueData } 
            columns={ serviceIssuesColumns } 
            // expandRow={ expandRow }
            // rowEvents={ rowEvents }
            />
          </Table>
          </div>
          </section>
      }



      if (this.props.isShipCommentsLoading){
        commentsTable=
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Shipment Comments'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>

        milestoneAlertsTable = 
        <div align="center">
        <section className="panel">
          <header className="panel-heading">
            {'Milestone Alerts'}
          </header>
          </section>
          <Loader 
            type="Plane"
            color="#00BFFF"
            height="50"	
            width="50"
          /> 
        </div>        
      }

      else if (!this.props.isShipCommentsLoading){
        commentsTable=
          <section className="panel">
            <header className="panel-heading">
              {'Shipment Comments'}
            </header>
            <div style={{marginLeft: "auto",  marginRight: "auto"}}>
            <Button variant="success" size="sm" block   onClick={this.clickActionButton} >
              Add Comment
            </Button>
          </div>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
            <Table>
              <BootstrapTable 
              keyField='CommentDate' 
              data={ this.props.shipCommentsData } 
              columns={ shipCommentsColumns } 
              // expandRow={ expandRow }
              // rowEvents={ rowEvents }
              />
            </Table>
            </div>
            </section>

          milestoneAlertsTable=
          <div>


          <section className="panel">
            <header className="panel-heading">
              {'Active Milestone Alerts'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
            <Table>
              <BootstrapTable 
              keyField='CommentDate' 
              // data={ this.props.detailsMileStoneAlertsData } 
              data={ this.props.detailsMileStoneAlertsData.filter(x => x.AlertStatus === 'Active') }
              columns={ milestoneAlertsColumns } 
              // expandRow={ expandRow }
              // rowEvents={ rowEvents }
              />
            </Table>
            </div>
            </section>

            <section className="panel">
            <header className="panel-heading">
              {'Historic Milestone Alerts'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'default'}}>
            <Table>
              <BootstrapTable 
              keyField='CommentDate' 
              // data={ this.props.detailsMileStoneAlertsData } 
              data={ this.props.detailsMileStoneAlertsData.filter(x => x.AlertStatus === 'Inactive') }
              columns={ historicMilestoneAlertsColumns } 
              // expandRow={ expandRow }
              // rowEvents={ rowEvents }
              />
            </Table>
            </div>
            </section>

            </div>
      }



      let modal;
      //render modal only if show is true, otherwise do not render
      if (this.props.shipTrackModalShow === true) {

        modal = 

        <ActionModalContainer />
            
      } else {
        modal = <div></div>;
      }



      let shipDetailsRender;
      //Sales View
      if(this.props.currentUserEmail === 'salesvizdashdemo@gmail.com'){
        shipDetailsRender = 
        <div>
        <div className="row" style={{padding: '0px'}}>  
        <div className="col-md-4"  >
          <div className="sm-st clearfix" style={{height: '100%'}}>
            <div className="row">  
              <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                { subscribeButton }
              </div>
            </div>
          </div>
          </div>
          <div className="col-md-8"  >
            <div className="sm-st clearfix" style={{height: '100%'}}>
              <div className="row">  
                <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>

                </div>
              </div>
            </div>
          </div>
        </div>
          { detailsTable }
          </div>
          }

      //Customer View
          else if(this.props.userSignInData.GroupName ==='Google'){
            shipDetailsRender = 
            <div>
            <div className="row" style={{padding: '0px'}}>  
            <div className="col-md-4"  >
              <div className="sm-st clearfix" style={{height: '100%'}}>
                <div className="row">  
                  <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    { subscribeButton }
                  </div>
                </div>
              </div>
              </div>
              <div className="col-md-8"  >
                <div className="sm-st clearfix" style={{height: '100%'}}>
                  <div className="row">  
                    <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                      <Button variant="primary" size="lg" block  style={{marginTop: '5px'}} onClick={this.clickActionButton} >
                        Shipment Action
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              { detailsTable }
              {/* {jobsLegsTable} */}
              {jobsRefNumTable}
              { commentsTable }
              { modal }
              </div>
          }

      //Omni View
          else if( this.props.userSignInData.GroupName ==='OMNI'  || this.props.userSignInData.GroupName ==='Demo' ){

            shipDetailsRender = 
            <div>
            <div className="row" style={{padding: '0px'}}>  
            <div className="col-md-4"  >
              <div className="sm-st clearfix" style={{height: '100%'}}>
                <div className="row">  
                  <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                    { subscribeButton }
                  </div>
                </div>
              </div>
              </div>
              <div className="col-md-8"  >
                <div className="sm-st clearfix" style={{height: '100%'}}>
                  <div className="row">  
                    <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                      <Button variant="primary" size="lg" block  style={{marginTop: '5px'}} onClick={this.clickActionButton} >
                        Shipment Action
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              { detailsTable }
              {jobsLegsTable}
              {jobsRefNumTable}
              { milestoneAlertsTable }
              { commentsTable }

              {(appSettings.demoEnvironment === true  ) ? <div>{ serviceIssuesTable }</div> : <div></div>   }
              { modal }
              </div>
          }


      return(
        <div>
            {shipDetailsRender}
        </div>

      );
    }
  }
  
  export default ShipmentDetails;
  
