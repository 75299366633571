
import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import ShipmentTrackerParamContainer from '../../../components/ComponentsGoogle/ShipmentTrackerURLParam/ShipmentTrackerParamContainer';
import FilterMainGoogle from '../../../components/ComponentsGoogle/FilterMainGoogle/FilterMainGoogle';
import {appSettings} from '../../../app/appSettings'
// import CustomTableContainer from '../../components/CustomTable/CustomTableContainer'; 
// import CustomTable from '../../components/CustomTable/CustomTable';


class shipmentTrackerParamsView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }

        //Mobile View
        handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
        };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
            }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }    


    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(

            <div>

            <FilterMainGoogle isMobile={isMobile}/>


            <AnimatedView>

                <ShipmentTrackerParamContainer />


            </AnimatedView>

            </div>



        );
    }
}

export default shipmentTrackerParamsView;

