import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import {appSettings} from '../../../../app/appSettings'
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import {
    buttonFormatter,
    hawbButtonFormatter
  } from '../../../../resources/logic/formatting';


  class GBShipToStateReporting extends PureComponent {
    constructor( props )
    {
      super( props );
    }






    render() {

        const { ExportCSVButton } = CSVExport;

        
        const TransitTimeSummaryColumns = [
            {
            dataField: 'R_State',
            text: 'State',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },
            sort: true
          }, {
            dataField: 'Avg_Final_Mile_Scan_To_Final_Delivery_days',
            text: 'Avg First Scan to Delivery (Days)',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },

            sort: true
          }, {
            dataField: 'ShipCount',
            text: 'Package Count',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },

            sort: true
          }
          ]

          const TransitTimeDetailsColumns = [
            {

            dataField: 'HAWB',
            text: 'Tracking No',
            align: 'center',
            headerStyle: (colum, colIndex) => {
                return { width: '50%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" },
            formatter: hawbButtonFormatter,
            events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {   
    
                // var paramMC = row.Barcode.trim() 
                var paramTrackingNo = row.HAWB.trim()
    
                    this.props.history.push({
                    pathname: 'hawbdetails',
                    search: 'trackingno=\'' + paramTrackingNo +'\''
                    }); 
                ; }
                }
        
            },

            {
            dataField: 'Final_Mile_Scan_To_Final_Delivery_days',
            text: 'First Scan to Delivery (Days)',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '50%' , textAlign: 'center' };
            },

            sort: true
          },{            
          dataField: 'DeliveryDT',
          text: 'DeliveryDT',
          align: 'center',
          headerStyle: (colum, colIndex) => {
            return { width: '50%' , textAlign: 'center' };
          },
          sort: true
        }


          ]






      const expandToDetailRow = {
        renderer: row => (
          <div style={{cursor: 'default', marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'State: ' + row.R_State}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="HAWB"
              data = { this.props.TransitTimeDetails.filter(x => x.R_State == row.R_State)  }
              // data={ uniqueByCarrier(this.props.kpiDetailData.filter(x => x.kpiID == row.kpiID)) }
              // data={ this.props.kpiDetailData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.kpiDetailData }
              columns={ TransitTimeDetailsColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps } />
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }
    
    


      let tableTransitTime;
      if (this.props.isHawbReportingDataLoading) {
        tableTransitTime=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.TransitTimeSummary) !== 'undefined' && this.props.TransitTimeSummary != null) {
        
        tableTransitTime =
        <ToolkitProvider
        keyField="R_State"
        // data={ this.props.kpiOverviewData }
        data={ this.props.TransitTimeSummary }
        columns={ TransitTimeSummaryColumns }
        exportCSV
      >
        {
          props => (
            <div>
              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
              <hr />
              <BootstrapTable { ...props.baseProps } 
              // expandRow={ expandToDetailRow } 
              />
            </div>
          )
        }
      </ToolkitProvider>

      } else {
        tableTransitTime = <div></div>;
      }





      return(

        <section className="panel">

        <header className="panel-heading">
          {'Transit Time'}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>

          { tableTransitTime }

        </div>

      </section>


      );

    }
  }
  

  export default withRouter(GBShipToStateReporting);
  
