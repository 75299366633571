
import React, {
    PureComponent
}                         from 'react';


import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';


import ShipmentTrackerFilteredContainer from '../../../components/ComponentsGoogle/ShipmentTracker/ShipmentTrackerFilteredContainer';
// import CustomTableContainer from '../../components/CustomTable/CustomTableContainer'; 
// import CustomTable from '../../components/CustomTable/CustomTable';


class shipmentTrackerFilteredView extends PureComponent {



    render() {

        return(
            <AnimatedView>

                <ShipmentTrackerFilteredContainer />


            </AnimatedView>



        );
    }
}

export default shipmentTrackerFilteredView;

