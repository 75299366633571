import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
// import {
//     Table,
//     TableBody
//   }                         from '../../../components/ComponentsGeneral/table';
  import {//valueDeliveryDayFormatter, 
    buttonFormatter, 
    mawbButtonFormatterMobile,
    // modalButtonFormatter, 
    gbFlagFormatter, 
    // shipmentAlertIconFormatter
    // // hotShipmentIcon
  } from '../../../resources/logic/formatting';
  import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
  import paginationFactory from 'react-bootstrap-table2-paginator';
  import BootstrapTable from 'react-bootstrap-table-next';
  // import filterFactory, { numberFilter } from 'react-bootstrap-table2-filter';
  import ReactExport from "react-export-excel";
  import * as moment from 'moment'

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  var currentDateTime = moment().format("_YYYY-MM-DDTHH-mm-ss")
  
  class GBShipmentListComponent extends PureComponent {
    constructor( props )
    {
      super( props );
    }

    render() {

        // const { ExportCSVButton } = CSVExport;

        const mawbColumns = [{
            dataField: 'MAWB',
            text: 'MAWB',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '11%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" }
          }, {
          //   dataField: 'HAWB',
          //   text: 'HAWB',
          //   align: 'center',
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '12%' , textAlign: 'center' };
          //   },
          //   sort: true
          // // }, {

          // //   dataField: 'AirlineCarrier',
          // //   text: 'Carrier',
          // //   align: 'center',
          // //   sort: true,
          // //   headerStyle: (colum, colIndex) => {
          // //     return { width: '8%' , textAlign: 'center' };
          // //   },
          // //   // formatter: shipmentAlertIconFormatter
          // },
          // {
            dataField: 'FlightNo',
            text: 'Flight No',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '9%' , textAlign: 'center' };
            },

          },
          {
            dataField: 'ShipToAirport',
            text: 'Ship To Airport',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '8%' , textAlign: 'center' };
            },

          },
          {
            dataField: 'MinManifestDate',
            text: 'Inbd Manifest Date (Min)',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },
          {
            dataField: 'FlightDepartDT',
            text: 'Flight Depart',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },
          {
            dataField: 'FreightRecovery',
            text: 'Freight Recovery',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },
          {
            dataField: 'FreightHandOff',
            text: 'Freight HandOff',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },

          {
            dataField: 'ManifestToRecovery',
            text: 'Inbd Manifest To Recovery (Days)',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },

          {
            dataField: 'RecoveryToHandoff',
            text: 'Recovery To Handoff (Hrs)',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },

          {
            dataField: 'icons',
            text: 'Flags',
            align: 'center',
            sort: false,
            headerStyle: (colum, colIndex) => {
              return { width: '8%' , textAlign: 'center' };
            },
            formatter: gbFlagFormatter
          },



          {


            dataField: 'buttonDetails',
            text: '',
            align: 'center',
            formatter: buttonFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '10%', textAlign: 'center' };
              },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {   
                var paramMawb = null
                var paramHawb = null

                try{
                  paramMawb = row.MAWB.trim()
                }
                catch(e){
                  paramMawb = null
                }
                try{
                  paramHawb = row.HAWB.trim()
                }
                catch(e){
                  paramHawb = null
                }

                  this.props.history.push({
                  pathname: 'shipdetails',
                  search: 'mawb=\'' + paramMawb +'\'' + '&' +
                  'hawb=\'' + paramHawb +'\''
                  }); 

                ; }
              }
          }
          ]

          const mobileMawbColumns = [
            {

              dataField: 'MAWB',
              text: 'MAWB',
              align: 'center',
              headerStyle: (colum, colIndex) => {
                return { width: '60%' , textAlign: 'center' };
              },
              sort: true,
              style: { overflowWrap: "break-word" },
              formatter: mawbButtonFormatterMobile,
              events: {
                onClick: (e, column, columnIndex, row, rowIndex) => {   

                  var paramMawb =  row.MAWB.trim() 
  
                    this.props.history.push({
                    pathname: 'shipdetails',
                    search: 'mawb=\'' + paramMawb +'\''
                    }); 
  
  
                  ; }
                }
      
            },
            

          // {
          //   dataField: 'HAWB',
          //   text: 'HAWB',
          //   align: 'center',
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '40%' , textAlign: 'center' };
          //   },
          //   sort: true
          // // }, {
          // //   dataField: 'buttonDetails',
          // //   text: '',
          // //   align: 'center',
          // //   formatter: buttonFormatter,
          // //   headerStyle: (colum, colIndex) => {
          // //     return { width: '40%', textAlign: 'center' };
          // //     },
          // //   events: {
          // //     onClick: (e, column, columnIndex, row, rowIndex) => {   

          // //       var paramMawb =  row.MAWB.trim() 

          // //         this.props.history.push({
          // //         pathname: 'shipdetails',
          // //         search: 'mawb=\'' + paramMawb +'\''
          // //         }); 


          // //       ; }
          // //     }
          // }
          ]





          const mcColumns = [{
            dataField: 'SearchInput',
            text: 'MC',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '11%' , textAlign: 'center' };
            },
            sort: true
          }, {
          //   dataField: 'HAWB',
          //   text: 'HAWB',
          //   align: 'center',
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '9%' , textAlign: 'center' };
          //   },
          //   sort: true
          // },
          // {
            dataField: 'FlightNo',
            text: 'Flight No',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
            // formatter: shipmentAlertIconFormatter
          },
          {
            dataField: 'ShipToAirport',
            text: 'Ship To Airport',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
            // formatter: shipmentAlertIconFormatter
          },
          {
            dataField: 'MinManifestDate',
            text: 'Inbd Manifest Date (Min)',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            // formatter: shipmentTagFormatter
          },
          {
            dataField: 'FlightDepartDT',
            text: 'Flight Depart',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            // formatter: shipmentTagFormatter
          },
          {
            dataField: 'FreightRecovery',
            text: 'Freight Recovery',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            // formatter: shipmentTagFormatter
          },
          {
            dataField: 'FreightHandOff',
            text: 'Freight HandOff',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },

          {
            dataField: 'icons',
            text: 'Flags',
            align: 'center',
            sort: false,
            headerStyle: (colum, colIndex) => {
              return { width: '8%' , textAlign: 'center' };
            },
            formatter: gbFlagFormatter
          },


          {



            dataField: 'buttonDetails',
            text: '',
            align: 'center',
            formatter: buttonFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '10%', textAlign: 'center' };
              },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {   

                var paramMawb = row.MAWB.trim() 
                var paramMC = row.SearchInput.trim() 

                  this.props.history.push({
                  pathname: 'mcdetails',
                  search: 'mawb=\'' + paramMawb +'\'' + '&' +
                  'mc=\'' + paramMC +'\''
                  }); 

                ; }
              }
          }
          ]

          const mobileMcColumns = [{
            dataField: 'SearchInput',
            text: 'MC',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '60%' , textAlign: 'center' };
            },
            sort: true
          }, 
          {
            dataField: 'buttonDetails',
            text: '',
            align: 'center',
            formatter: buttonFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '40%', textAlign: 'center' };
              },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {   

                var paramMawb = row.MAWB.trim() 
                var paramMC = row.SearchInput.trim() 

                  this.props.history.push({
                  pathname: 'mcdetails',
                  search: 'mawb=\'' + paramMawb +'\'' + '&' +
                  'mc=\'' + paramMC +'\''
                  }); 


                ; }
              }
          }
          ]



          const hawbColumns = [{
            dataField: 'SearchInput',
            text: 'Tracking No',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '30%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" }
          }, {
            dataField: 'MAWB',
            text: 'MAWB',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            sort: true,
            style: { overflowWrap: "break-word" }
          }, {

          //   dataField: 'AirlineCarrier',
          //   text: 'Carrier',
          //   align: 'center',
          //   sort: true,
          //   headerStyle: (colum, colIndex) => {
          //     return { width: '8%' , textAlign: 'center' };
          //   },
          //   // formatter: shipmentAlertIconFormatter
          },
          {
            dataField: 'FlightNo',
            text: 'Flight No',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
            // formatter: shipmentAlertIconFormatter
          },
          {
            dataField: 'ShipToAirport',
            text: 'Ship To Airport',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '10%' , textAlign: 'center' };
            },
            // formatter: shipmentAlertIconFormatter
          },
          {
            dataField: 'MinManifestDate',
            text: 'Inbd Manifest Date',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            // formatter: shipmentTagFormatter
          },
          {
            dataField: 'FlightDepartDT',
            text: 'Flight Depart',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            // formatter: shipmentTagFormatter
          },
          {
            dataField: 'FreightRecovery',
            text: 'Freight Recovery',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },
            // formatter: shipmentTagFormatter
          },
          {
            dataField: 'FreightHandOff',
            text: 'Freight HandOff',
            align: 'center',
            sort: true,
            headerStyle: (colum, colIndex) => {
              return { width: '15%' , textAlign: 'center' };
            },

          },

          {
            dataField: 'icons',
            text: 'Flags',
            align: 'center',
            sort: false,
            headerStyle: (colum, colIndex) => {
              return { width: '8%' , textAlign: 'center' };
            },
            formatter: gbFlagFormatter
          },
          
          {



            dataField: 'buttonDetails',
            text: '',
            align: 'center',
            formatter: buttonFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '10%', textAlign: 'center' };
              },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {   

                var paramMC = row.ContainerID.trim() 
                var paramTrackingNo = row.SearchInput.trim()
    
                  this.props.history.push({
                  pathname: 'hawbdetails',
                  search: 'mc=\'' + paramMC +'\'' + '&' +
                  'trackingno=\'' + paramTrackingNo +'\''
                  }); 

                ; }
              }
          }
          ]

          const mobileHawbColumns = [{
            dataField: 'SearchInput',
            text: 'Tracking No',
            align: 'center',
            headerStyle: (colum, colIndex) => {
              return { width: '60%' , textAlign: 'center' };
            },
            sort: true
          }, 
          {
            dataField: 'buttonDetails',
            text: '',
            align: 'center',
            formatter: buttonFormatter,
            headerStyle: (colum, colIndex) => {
              return { width: '40%', textAlign: 'center' };
              },
            events: {
              onClick: (e, column, columnIndex, row, rowIndex) => {   

                var paramMC = row.ContainerID.trim() 
                var paramTrackingNo = row.SearchInput.trim()
    
                  this.props.history.push({
                  pathname: 'hawbdetails',
                  search: 'mc=\'' + paramMC +'\'' + '&' +
                  'trackingno=\'' + paramTrackingNo +'\''
                  }); 


                ; }
              }
          }
          ]

        let bootStrapTable;
        if (this.props.isMawbDataLoading) {
          bootStrapTable=
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 

        }  
        else if (typeof(this.props.MawbDetailData) !== 'undefined' && this.props.MawbDetailData != null) {

          var MAWBdata = this.props.MawbDetailData.filter(x => x.ResultType === 'MAWB' || x.ResultType == 'AFHAWB' || x.ResultType == null )
          var MCdata = this.props.MawbDetailData.filter(x => x.ResultType === 'MC'  )
          var DetailHAWB = this.props.MawbDetailData.filter(x => x.ResultType === 'DETAILHAWB'  )


                bootStrapTable =
                <div>
                  <ToolkitProvider
                    keyField="UniqueID"
                    // data={ this.props.MawbDetailData }
                    // data={ this.props.MawbDetailData.filter(x => x.ResultType !== 'AFHAWB' && x.ResultType !== 'MC' && x.ResultType !== 'DETAILHAWB' && x.ResultType !== 'NOTFOUND'  ) }
                    data={ MAWBdata }
                    columns= {(this.props.isMobile == true  ) ? mobileMawbColumns  : mawbColumns }
                    exportCSV
                    >
                    {
                      props => (
                        <div style={{  display: (MAWBdata.length > 0  ) ? ''  : 'none'}}>
                          {(this.props.isMobile == true  ) ? <div></div>  : 

                          <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'mawb_'+ currentDateTime}>
                            <ExcelSheet data={MAWBdata} name="mawb">
                                <ExcelColumn label="MAWB" value="MAWB"/>
                                <ExcelColumn label="FlightNo" value="FlightNo"/>
                                <ExcelColumn label="ShipToAirport" value="ShipToAirport"/>
                                <ExcelColumn label="InbdManifestDate_Min" value="MinManifestDate"/>
                                <ExcelColumn label="FlightDepartDT" value="FlightDepartDT"/>
                                <ExcelColumn label="FreightRecovery" value="FreightRecovery"/>
                                <ExcelColumn label="FreightHandOff" value="FreightHandOff"/>
                                <ExcelColumn label="InbdManifestToRecovery_Days" value="ManifestToRecovery"/>
                                <ExcelColumn label="RecoveryToHandoff_Hrs" value="RecoveryToHandoff"/>
                            </ExcelSheet>
                          </ExcelFile>
                          
                          
                          // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                          }
                          <hr />
                            <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                        </div>
                      )
                    }
                  </ToolkitProvider>

                  <ToolkitProvider
                  keyField="UniqueID"
                  // data={ this.props.MawbDetailData }
                  // data={ this.props.MawbDetailData.filter(x => x.ResultType !== 'AFHAWB' && x.ResultType !== 'MC' && x.ResultType !== 'DETAILHAWB' && x.ResultType !== 'NOTFOUND'  ) }
                  data={ MCdata }
                  columns= {(this.props.isMobile == true  ) ? mobileMcColumns  : mcColumns }
                  exportCSV
                  >
                  {
                    props => (
                      <div style={{  display: (MCdata.length > 0  ) ? ''  : 'none'}}>
                        {(this.props.isMobile == true  ) ? <div></div>  : 
                        <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'mc_'+ currentDateTime}>
                            <ExcelSheet data={MCdata} name="mc">
                                <ExcelColumn label="MC" value="SearchInput"/>
                                <ExcelColumn label="FlightNo" value="FlightNo"/>
                                <ExcelColumn label="ShipToAirport" value="ShipToAirport"/>
                                <ExcelColumn label="InbdManifestDate_Min" value="MinManifestDate"/>
                                <ExcelColumn label="FlightDepartDT" value="FlightDepartDT"/>
                                <ExcelColumn label="FreightRecovery" value="FreightRecovery"/>
                                <ExcelColumn label="FreightHandOff" value="FreightHandOff"/>
                            </ExcelSheet>
                        </ExcelFile>
                        // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                        }
                        <hr />
                          <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                      </div>
                    )
                  }
                  </ToolkitProvider>

                  <ToolkitProvider
                  keyField="UniqueID"
                  // data={ this.props.MawbDetailData }
                  // data={ this.props.MawbDetailData.filter(x => x.ResultType !== 'AFHAWB' && x.ResultType !== 'MC' && x.ResultType !== 'DETAILHAWB' && x.ResultType !== 'NOTFOUND'  ) }
                  data={ DetailHAWB }
                  columns= {(this.props.isMobile == true  ) ? mobileHawbColumns  : hawbColumns }
                  exportCSV
                  >
                  {
                    props => (
                      <div style={{  display: (DetailHAWB.length > 0  ) ? ''  : 'none'}}>
                        {(this.props.isMobile == true  ) ? <div></div>  : 
                        <ExcelFile element={<button style={{'margin': '15px'}}>Export Excel</button>} filename={'package_'+ currentDateTime}>
                            <ExcelSheet data={DetailHAWB} name="package">
                              <ExcelColumn label="TrackingNo" value="SearchInput"/>
                              <ExcelColumn label="MAWB" value="MAWB"/>
                              <ExcelColumn label="FlightNo" value="FlightNo"/>
                              <ExcelColumn label="ShipToAirport" value="ShipToAirport"/>
                              <ExcelColumn label="InbdManifestDate" value="MinManifestDate"/>
                              <ExcelColumn label="FlightDepartDT" value="FlightDepartDT"/>
                              <ExcelColumn label="FreightRecovery" value="FreightRecovery"/>
                              <ExcelColumn label="FreightHandOff" value="FreightHandOff"/>
                            </ExcelSheet>
                        </ExcelFile>
                        // <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton> 
                        }
                        <hr />
                          <BootstrapTable { ...props.baseProps } pagination={ paginationFactory() }  />
                      </div>
                    )
                  }
                  </ToolkitProvider>

                  </div>



        }
        else {
          bootStrapTable =
          <div></div>
        }

      return(
        
        


        <section className="panel">
        <header className="panel-heading">
          { `Shipment List: ${this.props.MawbDetailData.length} rows`}
        </header>
        <div className="panel-body table-responsive"
        style={{backgroundColor: '#f5f5f5',  cursor: 'default', overflowX:'hidden'}}>

          { bootStrapTable }

        </div>

        </section>


      );

    }
  }
  

  export default withRouter(GBShipmentListComponent);
  
