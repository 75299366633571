////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DatePicker from "react-datepicker"; //https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select"; //https://sanusart.github.io/react-dropdown-select/
import * as moment from 'moment'

////local imports

// import {//valueDeliveryDayFormatter, 
//     // buttonApproveAccount, 
//     // buttonRejectAccount, 
//   // hotShipmentIcon
// } from '../../resources/logic/formatting';
import Button from 'react-bootstrap/Button'

import {KPIValueFormatter, 
  buttonFormatter,
  PercentFormatter,
  // serviceDropDownFormatter
  // milestoneAlertDetailsSeverityFormatter,
  // milestoneCustomerAlertFormatter
} from '../../../resources/logic/formatting';


import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


import {
  ResponsiveContainer,
} from 'recharts';
import { getUserAccounts } from '../../../redux/actions/googleActions';






const calStartValue = new Date();
calStartValue.setDate( calStartValue.getDate() - 31 );


  class ServiceIssues extends PureComponent {




    constructor( props )
    {
      super( props );

      this.state = {
        startDate: calStartValue,
        // startDate: moment().format('YYYY-MM-DD'),
        endDate: new Date(),
        // endDate: moment().format('YYYY-MM-DD'),
        dropDownValueForwarder: '',
        dropDownValueMode: ''
        // dropDownServiceIssue: '',
      };
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
      this.handleDropdownChangeForwarder = this.handleDropdownChangeForwarder.bind(this);
      this.handleDropdownChangeMode = this.handleDropdownChangeMode.bind(this);
      // this.handleDropdownServiceIssue = this.handleDropdownServiceIssue.bind(this);

    }

    handleChangeStartDate(date) {
      this.setState({
        startDate: date
      });
    }
  
    handleChangeEndDate(date) {
      this.setState({
        endDate: date
      });
    }

    handleDropdownChangeForwarder(value) {
      this.setState({ 
        dropDownValueForwarder: value
      });
    }

    handleDropdownChangeMode(value) {
      this.setState({ 
        dropDownValueMode: value
      });
    }

    onChangeDropdownServiceIssue( value ) {

      window.alert("Confirm save.")
    }

    clickApplyFilter( e ) {
      var forwarderFilter = null;
      var modeFilter = null;
      if (typeof(this.state.dropDownValueForwarder[0]) !== 'undefined' && this.state.dropDownValueForwarder[0] != null)
      {
        forwarderFilter = this.state.dropDownValueForwarder[0].value
      }

      if (typeof(this.state.dropDownValueMode[0]) !== 'undefined' && this.state.dropDownValueMode[0] != null)
      {
        modeFilter = "'" + this.state.dropDownValueMode[0].value + "'"
        
      }

      this.props.getKpiOverviewData( this.props.userSignInData.DBName, this.props.currentUserEmail, "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'", "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'", forwarderFilter, modeFilter )
      this.props.getAlertKpiTrendData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null, null, modeFilter, forwarderFilter, null, null, null, null, null, null)


  
    }

    detailsClick( row ) {

      var paramjobnumber = row.JobNumber
      var paramjobid = row.JobID
      
      var paramhousebill = row.HouseBill
      // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row, paramhousebill);

      // this.props.getShipmentTrackerDataByJobID(this.props.currentUserEmail, paramhousebill);

      this.props.history.push({
      pathname: 'shipmentdetails/',
      search: 'housebill=' + paramhousebill
      // state: {
      //   details:  row
      //   }
      })
    }

      // <Button variant="dark" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.detailsClick(row, e)}>
      // Click for Shipment Details
      // </Button>





      // getUser() {
      //   return this.props.currentUserEmail
      // }

      // postIssue(email, jobid, value) {

      //   this.props.postServiceIssue(email, jobid, value )

      // }

      




    render() {
      const { ExportCSVButton } = CSVExport;

      const selectMode = [
        { label: "Air", value: "AIR" },
        { label: "Ocean", value: "OCEAN" }
      ];

      const selectForwarder = [
        { label: "Omni", value: "OMNI" }
      ];
      
      const userEmail = this.props.currentUserEmail

      const userDB = this.props.userSignInData.DBName

      const postIssue = this.props.postServiceIssueFromView

      const getOpenServiceIssues = this.props.getOpenServiceIssues

      /////////
      const uniqueBy = prop => list => {
        const uniques = {}
        return list.reduce(
            (result, item) => {
                if (uniques[item[prop]]) return result
                uniques[item[prop]] = item
                return [...result, item]
            },
            [],
        )
      }
      
      const uniqueByCarrier = uniqueBy('Carrier')

      const uniqueByKpiEvent = uniqueBy('KPI')



      // const average = list => list.reduce((prev, curr) => prev + curr) / list.length;

      // const list = [0, 10, 20, 30]
      // average(list) // 15




      //////////////////////
      function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            if (!map.has(key)) {
                map.set(key, [item]);
            } else {
                map.get(key).push(item);
            }
        });
        return map;
    }
    



    function serviceDropDownFormatter(cell, row){

      // var user = this.getUser()
      const selectIssue = [
          { label: "Carrier", value: "1" },
          { label: "Vendor", value: "2" },
          { label: "Document", value: "3" },
          { label: "Weather Delay", value: "4" },
          { label: "Labor Dispute", value: "5" }
        ];

       function dropDownValues(values) {

          if (typeof(values[0]) !== 'undefined' && values[0] != null){
            if (window.confirm('Confirm service issue update.')) {
            // this.postIssue(userEmail, row.JobID, values[0].value )
            postIssue( userDB, userEmail, row.JobID, values[0].value )

            getOpenServiceIssues( userDB, userEmail, null, null )

            }

          }
        }

    return <Select 
          options={ selectIssue } 
          clearable
          separator
          placeholder={ 'Service Issue...'}
          onChange={(values) => dropDownValues(values)}
          // onChange={

          //   window.alert("Confirm save.")
            
          //   // this.onChangeDropdownServiceIssue( row )
          // }
        />
    }



      function carrierCalc( detailData ) {

        Object.values(detailData.reduce(function(r, e) {
          var key = e.kpiID + '|' + e.Carrier;
          if (!r[key]) r[key] = { Carrier: e.Carrier, kpiID: e.kpiID, KPI: e.KPI ,Value: e.Value, Length: 1,  CountMetKPI: e.MetKPI, CalcAvg: e.Value, CalcPerf: e.MetKPI * 100};  
          else {
            r[key].Value += e.Value;
            r[key].Length += 1;
            r[key].CalcAvg = Math.round(r[key].Value/r[key].Length, 1);
            r[key].CountMetKPI += e.MetKPI;
            r[key].CalcPerf = Math.round(r[key].CountMetKPI/r[key].Length * 100, 1);
  
            // r[key].instances += e.instances
          }
          return r;
        },
        {})
        )


      }



      function countCalc( detailData ) {

        Object.values(detailData.reduce(function(r, e) {
          var key = e.kpiID + '|' + e.Carrier;
          if (!r[key]) r[key] = { Carrier: e.Carrier, kpiID: e.kpiID, KPI: e.KPI ,Value: e.Value, Length: 1,  CountMetKPI: e.MetKPI, CalcAvg: e.Value, CalcPerf: e.MetKPI * 100};  
          else {
            r[key].Value += e.Value;
            r[key].Length += 1;
            r[key].CalcAvg = Math.round(r[key].Value/r[key].Length, 1);
            r[key].CountMetKPI += e.MetKPI;
            r[key].CalcPerf = Math.round(r[key].CountMetKPI/r[key].Length * 100, 1);
  
            // r[key].instances += e.instances
          }
          return r;
        },
        {})
        )
      }


      // const users = [
      //   { id: 11, name: 'Adam', age: 23, group: 'editor' },
      //   { id: 47, name: 'John', age: 28, group: 'admin' },
      //   { id: 85, name: 'William', age: 34, group: 'editor' },
      //   { id: 97, name: 'Oliver', age: 28, group: 'admin' }
      // ];




      
      // const groupByAge = users.reduce((acc, it) => {
      //   acc[it.age] = acc[it.age] + 1 || 1;
      //   return acc;
      // }, {});
      
      // groupByAge is {23: 1, 28: 2, 34: 1}


      function groupByKPI( dataset ) {
        dataset.reduce((acc, it) => {
            acc[it.KPI] = acc[it.KPI] + 1 || 1;
            return acc;
        }, {});
      }

    //   const groupByAge = users.reduce((acc, it) => {
    //     acc[it.age] = acc[it.age] + 1 || 1;
    //     return acc;
    //   }, {});
      




      const expandCarrierRow = {
        renderer: row => (
          <div style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Service Issue Shipments: ' + row.key}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="Carrier"
              data = { this.props.kpiCarrierCalc.filter(x => x.kpiID == row.kpiID)  }
              // data={ uniqueByCarrier(this.props.openServiceIssueData.filter(x => x.kpiID == row.kpiID)) }
              // data={ this.props.openServiceIssueData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.openServiceIssueData }
              columns={ carrierExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps } expandRow={ expandRow } defaultSorted={defaultSortedCarrierBy}/>
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }


    //   const expandLaneToKPI= {
    //     renderer: row => (
    //       <div style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} >
    
    //       <div
    //         className="row">
      
    //         <div className="col-md-12">
              
    //           <header className="panel-heading">
    //           {'Lane: ' + row.Lane}
    //         </header>
    //         <div className="panel-body table-responsive"
    //         style={{backgroundColor: '#f5f5f5'}}>
      
    //         <ToolkitProvider
    //           keyField="UniqueID"
    //           // data = { this.props.openServiceIssueData.filter(x => x.Lane == row.Lane)  }
    //           data={ uniqueByKpiEvent(this.props.openServiceIssueData.filter(x => x.Lane == row.Lane)) }
    //           // data={ this.props.openServiceIssueData.filter(x => x.kpiID === row.kpiID) }
    //           // data={ this.props.openServiceIssueData }
    //           columns={ kpiLaneKpiEventExpandColumns }

    //           exportCSV
    //         >
    //           {
    //             props => (
    //               <div>
    //                 <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
    //                 <hr />
    //                 <BootstrapTable { ...props.baseProps }  defaultSorted={defaultSortedDetailsBy} expandRow={ expandLaneRow } />
    //               </div>
    //             )
    //           }
    //         </ToolkitProvider>
      
    //         </div>
                    
    //           </div>
    //           </div>

    //           <br/>
    //           </div>
      
    //     ),
    //     onlyOneExpanding: true
    //   }

      const expandLaneRow= {
        renderer: row => (
          <div style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Lane: ' + row.Lane}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="UniqueID"
              data = { this.props.openServiceIssueData.filter(x => x.Lane == row.Lane && x.kpiID == row.kpiID)  }
              // data={ uniqueByCarrier(this.props.openServiceIssueData.filter(x => x.kpiID == row.kpiID)) }
              // data={ this.props.openServiceIssueData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.openServiceIssueData }
              columns={ kpiDetailExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps }  defaultSorted={defaultSortedDetailsBy}/>
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }




      const expandRow = {
        renderer: row => (
          <div style={{cursor: 'default', marginLeft: '10px', marginRight: '10px'}} >
    

          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Service Issue Shipments: ' + row.key}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#FFF'}}>
      
            <ToolkitProvider
              keyField="UniqueID"
              data={ clean.filter(x => x.ServiceIssue == row.key  ) }

            //   this.props.kpiCarrierCalc.filter(x => x.kpiID == row.kpiID)
              // data={ this.props.openServiceIssueData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.openServiceIssueData }
              columns={ kpiDetailExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>


                    <hr />
                    <BootstrapTable { ...props.baseProps } defaultSorted={defaultSortedDetailsBy}/>
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }




//need to have unique id key
        
      const serviceIssueOverviewColumns = [
        {
        dataField: 'key',
        text: 'Service Issue',
        // align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        // sort: true
      }, {
        dataField: 'value',
        text: 'Shipment Count',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        //   formatter: KPIValueFormatter
        // sort: true
      }, {
      }
      ]



      const defaultSortedCarrierBy = [{
        dataField: 'CalcPerf',
        order: 'asc'  // or desc
    }];


      const carrierExpandColumns = [
        {

        dataField: 'Carrier',
        text: 'Carrier',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '75%' , textAlign: 'center' };
        }
        },
        {
        dataField: 'CalcAvg',
        text: 'Average Value',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
          // formatter: KPIValueFormatter
        },
        {
          dataField: 'CalcPerf',
          text: 'Performance Percent',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '25%' , textAlign: 'center' };
          },
            formatter: PercentFormatter
          }
      ]



      const defaultSortedDetailsBy = [{
        dataField: 'Value',
        order: 'desc'  // or desc
    }];

    

    const kpiLaneKpiEventExpandColumns = [{
 
      dataField: 'KPI',
      text: 'Service Issue',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '28%' , textAlign: 'center' };
      },
      // sort: true
    }
    // ,{
    //   dataField: 'Value',
    //   text: 'Value',
    //   align: 'center',
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: '8%' , textAlign: 'center' };
    //   },
    //   // sort: true
    // }
  
    ]













      // function serviceDropDownFormatter(cell, row){

      //   // var user = this.getUser()

      //   const selectIssue = [
      //       { label: "Carrier", value: "1" },
      //       { label: "Vendor", value: "2" },
      //       { label: "Document", value: "3" },
      //       { label: "Weather Delay", value: "4" },
      //       { label: "Labor Dispute", value: "5" }
      //     ];

      //    function dropDownValues(values) {

      //       if (typeof(values[0]) !== 'undefined' || values[0] != null){
      //         if (window.confirm('Confirm service issue update.')) {
   
      //         this.postIssue(userEmail, row.JobID, values[0].value )
      //         // this.props.postServiceIssue(userEmail, row.JobID, values[0].value )
      //         }

              
      //       }
      //     }

      // return <Select 
      //       options={ selectIssue } 
      //       clearable
      //       separator
      //       placeholder={ 'Service Issue...'}
      //       onChange={(values) => dropDownValues(values)}
      //       // onChange={

      //       //   window.alert("Confirm save.")
              
      //       //   // this.onChangeDropdownServiceIssue( row )
      //       // }
      //     />
      // }


























            //   onChange: (e, column, columnIndex, row, rowIndex) => {   
      //     this.handleDropdownServiceIssue( row )
      //     // // todo: trigger details data load here  
      //     // // var paramvalue = this.state.statedata.details.JobNumber;
      //     // var paramjobid = row.JobID

      //     // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row);
      //     // this.props.history.push({
      //     // pathname: 'shipmenttrackerdetails'
      //     // // state: {
      //     // //   details:  row
      //     // //   }
      //     // }); 
      //   }
      //   }

      const kpiDetailExpandColumns = [{
        
        dataField: 'ServiceIssue',
        text: 'Service Issue',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'DaysLate',
        text: 'Days Late',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'HouseBill',
        text: 'House Bill',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '15%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Mode',
        text: 'Mode',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },
      {
        dataField: 'CurrentClassification',
        text: 'Current Classification',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '20%' , textAlign: 'center' };
        },
        // sort: true
    },
    {
      dataField: 'x',
      text: 'Select Issue',
      align: 'center',
      formatter: serviceDropDownFormatter,
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '20%' , textAlign: 'center' };
      },
      // events: {
      //   // onChange={this.handleDropdownServiceIssue}
      //   onChange: (e, column, columnIndex, row, rowIndex) => {   
      //     this.handleDropdownServiceIssue( row )
      //     // // todo: trigger details data load here  
      //     // // var paramvalue = this.state.statedata.details.JobNumber;
      //     // var paramjobid = row.JobID

      //     // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row);
      //     // this.props.history.push({
      //     // pathname: 'shipmenttrackerdetails'
      //     // // state: {
      //     // //   details:  row
      //     // //   }
      //     // }); 
      //   }
      //   }
      // sort: true 
      },{
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   
            this.detailsClick( row )
            // // todo: trigger details data load here  
            // // var paramvalue = this.state.statedata.details.JobNumber;
            // var paramjobid = row.JobID

            // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row);
            // this.props.history.push({
            // pathname: 'shipmenttrackerdetails'
            // // state: {
            // //   details:  row
            // //   }
            // }); 
          }
          }
      }



      ]





              // var carrierValues = [];

      // carrierValues = Object.values(this.props.openServiceIssueData.reduce(function(r, e) {
      //   var key = e.kpiID + '|' + e.Carrier;
      //   if (!r[key]) r[key] = { Carrier: e.Carrier, kpiID: e.kpiID, KPI: e.KPI ,Value: e.Value, Length: 1,  CountMetKPI: e.MetKPI, CalcAvg: e.Value, CalcPerf: e.MetKPI * 100};  
      //   else {
      //     r[key].Value += e.Value;
      //     r[key].Length += 1;
      //     r[key].CalcAvg = Math.round(r[key].Value/r[key].Length, 1);
      //     r[key].CountMetKPI += e.MetKPI;
      //     r[key].CalcPerf = Math.round(r[key].CountMetKPI/r[key].Length * 100, 1);

      //     // r[key].instances += e.instances
      //   }
      //   return r;
      // },
      // {})
      // )

// ******************  if(this.props.userSignInData.GroupName === 'OMNI')




      let lateShipTable;

      let bootStrapTableServiceIssues;

      if (this.props.isOpenServiceIssueLoading) {
        lateShipTable=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.openServiceIssueData) !== 'undefined' && this.props.openServiceIssueData != null) {
        


        var inputDataset = this.props.openServiceIssueData //.filter(x => x.MetKPI == 0  && x.kpiID > 1)


        var clean = inputDataset.filter((arr, index, self) =>
            index === self.findIndex((t) => (t.ServiceIssue === arr.ServiceIssue && t.DaysLate === arr.DaysLate && t.Mode === arr.Mode && t.HouseBill === arr.HouseBill && t.CurrentClassification === arr.CurrentClassification)))


        var occurences = clean.reduce(function (r, row) {
            r[row.ServiceIssue] = ++r[row.ServiceIssue] || 1;
            return r;
        }, {});
        
        var groupByServiceIssue = Object.keys(occurences).map(function (key) {
            return { key: key, value: occurences[key] };
        });
        



        bootStrapTableServiceIssues =
        <ToolkitProvider
        keyField="UniqueID"
        data={ groupByServiceIssue }
        // data={ this.props.kpiOverviewData.filter(x => x.kpiID < 5 && x.kpiID > 1) }
        columns={ serviceIssueOverviewColumns }
        exportCSV
      >
        {
          props => (
            <div>
              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
              <hr />
              <BootstrapTable { ...props.baseProps } expandRow={ expandRow } />
            </div>
          )
        }
      </ToolkitProvider>


      lateShipTable = 
      <ToolkitProvider
        keyField="UniqueID"
        data={ this.props.openServiceIssueData }

      //   this.props.kpiCarrierCalc.filter(x => x.kpiID == row.kpiID)
        // data={ this.props.openServiceIssueData.filter(x => x.kpiID === row.kpiID) }
        // data={ this.props.openServiceIssueData }
        columns={ kpiDetailExpandColumns }

        exportCSV
      >
        {
          props => (
            <div>
              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>


              <hr />
              <BootstrapTable { ...props.baseProps } defaultSorted={defaultSortedDetailsBy}/>
            </div>
          )
        }
      </ToolkitProvider>


      } else {
        lateShipTable = <div></div>;
      }





      let milestoneTrend;
      if (this.props.isAlertKpiTrendLoading) {
        milestoneTrend=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Service Issue Trend'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        
      }
      else if (typeof(this.props.alertKpiTrendData) !== 'undefined' && this.props.alertKpiTrendData != null) {

        


        milestoneTrend=
        <section className="panel">
        <header className="panel-heading">
          {'Service Issue Trend'}
        </header>
        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
              <LineChart //width={730} height={250} 
                  data={ this.props.alertKpiTrendData }
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                
                  <Legend />
                  <Line type="monotone" dataKey="Missed KPI" stroke="#8b0000" strokeWidth={2}/>
              </LineChart>
        </ResponsiveContainer>
        </div>
        </div>
            </section>
      }



      let filter;
      filter = 
              <section className="panel">
                    <header className="panel-heading">
                      {'Filters'}
                    </header>

                    <div //className="panel-body table-responsive"
                        style={{backgroundColor: '#f5f5f5'}}
                        >

              <div className="row" style={{padding: '15px'}}>  

              <div className="col-md-4"  >
                <div className="sm-st clearfix" style={{height: '100%'}}>
                  <div className="row">  
                    <div style={{marginLeft: "auto",  marginRight: "auto"}}>  
                        <header >
                          {'Start Date'}
                        </header>
                          <DatePicker
                              placeholderText="Select Start Date"
                              selected={this.state.startDate}
                              selectsStart
                              startDate={this.state.startDate}
                              endDate={this.state.endDate}
                              onChange={this.handleChangeStartDate}
                          />
                    </div>
                    <div style={{marginLeft: "auto",  marginRight: "auto"}}>  
                    <header >
                      {'End Date'}
                    </header>
                      <DatePicker
                          placeholderText="Select End Date"
                          selected={this.state.endDate}
                          selectsEnd
                          startDate={this.state.startDate}
                          endDate={this.state.endDate}
                          onChange={this.handleChangeEndDate}
                          minDate={this.state.startDate}
                          // disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4"  >
                <div className="sm-st clearfix" style={{height: '100%'}}>
                  <div className="row">  
                  <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                          <header>
                            {'Mode'}
                          </header>
                        <Select 
                          options={ selectMode } 
                          clearable
                          separator
                          placeholder={ 'Select Mode...'}
                          onChange={this.handleDropdownChangeMode}
                        />
                        </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4"  >
                <div className="sm-st clearfix" style={{height: '100%'}}>
                  <div className="row">  
                  <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
                        <header>
                            {'Forwarder'}
                          </header>
                        <Select 
                          options={ selectForwarder } 
                          clearable
                          separator
                          placeholder={ 'Select Forwarder...'}
                          onChange={this.handleDropdownChangeForwarder}
                        />
                        </div>
                  </div>
                </div>
              </div>

              </div>
                      

                    <div style={{paddingBottom: '25px', paddingLeft: '15px', paddingRight: '15px'}}>

                    <Button variant="success" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.clickApplyFilter(e)}>
                    Apply Filter
                      </Button>


                    </div>

                      </div>

                  </section>        


      return(
        <div>

          <section className="panel">

            <header className="panel-heading">
              {'Service Issues'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#FFF', cursor: 'pointer'}}>

              { lateShipTable }

            </div>

          </section>

          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>



          </div>
      );
    }
  }

  export default withRouter(ServiceIssues);
  

























