import React                        from 'react';
import {
  Route,
  Switch,
  Redirect
}                                   from 'react-router-dom';

import PropTypes                    from 'prop-types';
import {appSettings}                from '../app/appSettings';
import {languageTranslation}        from '../app/languageSettings'

//General Views
import contentHomeView              from '../views/ViewsGeneral/contentHomeView/contentHomeView';

//User and Misc Views
import pageNotFound                 from '../views/ViewsGeneral/pageNotFound/pageNotFound';
import PrivateRoute                 from '../components/ComponentsUser/PrivateRoute';
import UserChangePassword           from '../components/ComponentsUser/UserChangePassword';
import myProfileView                from '../views/ViewsUser/myProfile/myProfileView';
import userManagementView           from '../views/ViewsUser/userManagement/userManagementView';
import userSignUpView               from '../views/ViewsUser/userSignUp/userSignUpView';
import signInAuth                   from '../views/ViewsUser/signInAuth/signInAuth';

//ZU Views
import zuDashHomeView               from '../views/ViewsZU/zuDashHomeView/zuDashHomeView';
import zuReportingView              from '../views/ViewsZU/zuReportingView/zuReportingView';
import zuShipmentDetailView         from '../views/ViewsZU/zuShipmentDetailView/zuShipmentDetailView';
import zuShipmentListView           from '../views/ViewsZU/zuShipmentListView/zuShipmentListView';
import zuDataExportView             from '../views/ViewsZU/zuDataExportView/zuDataExportView';
import zuDtlHawbDetailView          from '../views/ViewsZU/zuDtlHawbDetailView/zuDtlHawbDetailView';
import zuHazMatView                 from '../views/ViewsZU/zuHazMatView/zuHazMatView';
import zuMCDetailView               from '../views/ViewsZU/zuMCDetailView/zuMCDetailView';
import zuEvolveBrokerageView        from '../views/ViewsZU/zuEvolveBrokerageView/zuEvolveBrokerageView';
// import zuBrokerMilestoneUpdateView from '../views/ViewsZU/zuBrokerMilestoneUpdateView/zuBrokerMilestoneUpdateView';


//GB Views
import gbDashHomeView               from '../views/ViewsGB/gbDashHomeView/gbDashHomeView';
import gbEvolveDashHomeView         from '../views/ViewsGB/gbDashHomeView/gbEvolveDashHomeView';
import gbShipmentListView           from '../views/ViewsGB/gbShipmentListView/gbShipmentListView';
import gbReportingView              from '../views/ViewsGB/gbReportingView/gbReportingView';
import gbMetricsView                from '../views/ViewsGB/gbMetricsView/gbMetricsView';
import gbDtlHawbDetailView          from '../views/ViewsGB/gbDtlHawbDetailView/gbDtlHawbDetailView';
import gbHazMatView                 from '../views/ViewsGB/gbHazMatView/gbHazMatView';
import gbMCDetailView               from '../views/ViewsGB/gbMCDetailView/gbMCDetailView';
import gbShipmentDetailView         from '../views/ViewsGB/gbShipmentDetailView/gbShipmentDetailView';
import gbDataExportView             from '../views/ViewsGB/gbDataExportView/gbDataExportView';

//Google Views
// import HomeConnected                from '../views/ViewsGoogle/home/Home';
import dashHome                     from '../views/ViewsGoogle/dashboardHome/dashHome';
import {salesDashHome}              from '../views/ViewsGoogle/dashboardHome/dashHome';
import {googleDashHome}             from '../views/ViewsGoogle/dashboardHome/dashHome';
import reportingView                from '../views/ViewsGoogle/reporting/reportingView';
import kpiView                      from '../views/ViewsGoogle/kpiView/kpiView';
import shipmentTrackerView          from '../views/ViewsGoogle/shipmentTrackerView/shipmentTrackerView';
import statsCardsView               from '../views/ViewsGoogle/statsCards/statsCardsView';
import ShipmentTrackerDetails       from '../views/ViewsGoogle/shipmentTrackerDetails/shipmentTrackDetails';
import shipmentTrackerParamsView    from '../views/ViewsGoogle/shipmentTrackerParamsView/shipmentTrackerParamsView';
import shipmentTrackerFilteredView  from '../views/ViewsGoogle/shipmentTrackerFilteredView/shipmentTrackerFilteredView';
import milestoneAlertsView          from '../views/ViewsGoogle/milestoneAlertsView/milestoneAlertsView';
import acknowledgeActionView        from '../views/ViewsGoogle/acknowledgeActionView/acknowledgeActionView';
import dataExportView               from '../views/ViewsGoogle/dataExport/dataEportView';
import serviceIssuesView            from '../views/ViewsGoogle/serviceIssueView/serviceIssueView';
import mapShipTrackerView           from '../views/ViewsGoogle/mapShipTracker/mapShipTrackerView';

//UPS Views
import upsLabelCreationView         from '../views/ViewsUPS/upsLabelCreationView/upsLabelCreationView';


// let languageSetting = localStorage.getItem('PageConfig.UserSettings.Language')

const routePathFunc = ( admin, group, businessUnit, navSelection, languageSelection, filter  ) => {






////////////////1: Create Route from import component:
//navRoutes:

// General Routes
let contenthome =               <PrivateRoute exact path="/home" component={contentHomeView} />

//User and Misc Routes
let usermanagement =            <PrivateRoute exact path="/home/usermanagement" component={userManagementView} />
let myprofile =                 <PrivateRoute path="/home/myprofile" component={myProfileView} />
let signup =                    <PrivateRoute exact path="/home/signup" component={userSignUpView} />
let userchangepassword =        <PrivateRoute path="/home/userchangepassword" component={UserChangePassword} />

//ZU Routes
let zuDashHome =                <PrivateRoute exact path="/home/zulily" component={zuDashHomeView} />
let zuReporting =               <PrivateRoute exact path="/home/zulily/reporting" component={zuReportingView} />
let zuShipDetail =              <PrivateRoute exact path="/home/zulily/shipdetails" component={zuShipmentDetailView} />
// let zuDashHome =                <PrivateRoute exact path="/home/zulily/:startdt?/:enddt?/:datetype?/:notdeparted?/:portexit?/:portentry?/:finalcarrier?" component={zuDashHomeView} />
// let zuReporting =               <PrivateRoute exact path="/home/zulily/reporting/:startdt?/:enddt?/:datetype?/:notdeparted?/:portexit?/:portentry?/:finalcarrier?" component={zuReportingView} />
let zuShipList =                <PrivateRoute exact path="/home/zulily/shiplist" component={zuShipmentListView} />
let zuDataExport =              <PrivateRoute exact path="/home/zulily/dataexport" component={zuDataExportView} />
let zuDtlHawbDetails =          <PrivateRoute exact path="/home/zulily/hawbdetails" component={zuDtlHawbDetailView} />
let zuHazMat =                  <PrivateRoute exact path="/home/zulily/hazmat" component={zuHazMatView} />
let zuMCDetails =               <PrivateRoute exact path="/home/zulily/mcdetails" component={zuMCDetailView} />
let zuEvolveBrokerage =         <PrivateRoute exact path="/home/zulily/evolvebrokerage" component={zuEvolveBrokerageView} />
// let zuBrokerMilestoneUpdate =   <PrivateRoute exact path="/home/zulily/milestoneentry" component={zuBrokerMilestoneUpdateView} />

//Demo Routes
let demoDashHome =                <PrivateRoute exact path="/home/demo" component={zuDashHomeView} />
let demoReporting =               <PrivateRoute exact path="/home/demo/reporting" component={zuReportingView} />
let demoShipDetail =              <PrivateRoute exact path="/home/demo/shipdetails" component={zuShipmentDetailView} />
// let demoDashHome =                <PrivateRoute exact path="/home/demo/:startdt?/:enddt?/:datetype?/:notdeparted?/:portexit?/:portentry?/:finalcarrier?" component={zuDashHomeView} />
// let demoReporting =               <PrivateRoute exact path="/home/demo/reporting/:startdt?/:enddt?/:datetype?/:notdeparted?/:portexit?/:portentry?/:finalcarrier?" component={zuReportingView} />
let demoShipList =                <PrivateRoute exact path="/home/demo/shiplist" component={zuShipmentListView} />
let demoDataExport =              <PrivateRoute exact path="/home/demo/dataexport" component={zuDataExportView} />
let demoDtlHawbDetails =          <PrivateRoute exact path="/home/demo/hawbdetails" component={zuDtlHawbDetailView} />
let demoHazMat =                  <PrivateRoute exact path="/home/demo/hazmat" component={zuHazMatView} />
let demoMCDetails =               <PrivateRoute exact path="/home/demo/mcdetails" component={zuMCDetailView} />
let demoEvolveBrokerage =         <PrivateRoute exact path="/home/demo/evolvebrokerage" component={zuEvolveBrokerageView} />
// let demoBrokerMilestoneUpdate =   <PrivateRoute exact path="/home/demo/milestoneentry" component={zuBrokerMilestoneUpdateView} />



//GB Routes 
let gbDashHome =                <PrivateRoute exact path="/home/gaobo" component={gbDashHomeView} />
let gbEvolveDashHome =          <PrivateRoute exact path="/home/gaobo" component={gbEvolveDashHomeView} />

let gbShipList =                <PrivateRoute exact path="/home/gaobo/shiplist" component={gbShipmentListView} />
let gbReporting =               <PrivateRoute exact path="/home/gaobo/reporting" component={gbReportingView} />
let gbMetrics =                 <PrivateRoute exact path="/home/gaobo/metrics" component={gbMetricsView} />
let gbDtlHawbDetails =          <PrivateRoute exact path="/home/gaobo/hawbdetails" component={gbDtlHawbDetailView} />
let gbHazMat =                  <PrivateRoute exact path="/home/gaobo/hazmat" component={gbHazMatView} />
let gbMCDetails =               <PrivateRoute exact path="/home/gaobo/mcdetails" component={gbMCDetailView} />
let gbShipDetail =              <PrivateRoute exact path="/home/gaobo/shipdetails" component={gbShipmentDetailView} />
let gbDataExport =              <PrivateRoute exact path="/home/gaobo/dataexport" component={gbDataExportView} />

//Google Routes
let omniHome =                  <PrivateRoute exact path="/home/google" component={dashHome} />
let googleHome =                <PrivateRoute exact path="/home/google" component={googleDashHome} />
let salesHome =                 <PrivateRoute exact path="/home/google" component={salesDashHome} />
let milestoneAlerts =           <PrivateRoute exact path="/home/google/milestonealerts" component={milestoneAlertsView} />
let kpi =                       <PrivateRoute exact path="/home/google/kpi" component={kpiView} />
let reporting =                 <PrivateRoute exact path="/home/google/reporting/:startdt?/:enddt?/:mode?/:network?/:measure?" component={reportingView} />
let statscards =                <PrivateRoute exact path="/home/google/statscards" component={statsCardsView} />
let shipmentdetails =           <PrivateRoute exact path="/home/google/shipmentdetails/:housebill?" component={ShipmentTrackerDetails} />
let shipmenttracker =           <PrivateRoute exact path="/home/google/shipmenttracker" component={shipmentTrackerView} />
let shipmenttrackerfiltered =   <PrivateRoute exact path="/home/google/shipmenttrackerfiltered" component={shipmentTrackerFilteredView} />
let shipmenttrackerparams =     <PrivateRoute exact path="/home/google/shipmenttrackerparams/:search?" component={shipmentTrackerParamsView} />
let mapshiptracker =            <PrivateRoute exact path="/home/google/mapshiptracker" component={mapShipTrackerView} />
let dataexportview =            <PrivateRoute exact path="/home/google/dataexportview" component={dataExportView} />
let acknowledgeAction =         <PrivateRoute exact path="/home/google/acknowledgeaction" component={acknowledgeActionView} />
let serviceissues =             <PrivateRoute exact path="/home/google/serviceissues" component={serviceIssuesView} />

//UPS Routes
let upsLabelCreation =              <PrivateRoute exact path="/home/ups/labelcreation" component={upsLabelCreationView} />



////////////////2: Create Menu Link for routes with clickable links:
//navLinks:

//User Links
let userManagementLink = {
  name: languageTranslation( 'UserManagement', languageSelection ), //'User Management',
  linkTo: '/home/usermanagement',
  faIconName: 'fa-users'
}
// myProfileLink = ( language ) => {
//   return {
//   name: languageTranslation( 'MyProfile', language ), //'My Profile',
//   linkTo: '/home/myprofile',
//   faIconName: 'fa-user'
//   }
// }
let myProfileLink = {
  name: languageTranslation( 'MyProfile', languageSelection ), //'My Profile',
  linkTo: '/home/myprofile',
  faIconName: 'fa-user'
} 

//ZU Links
let zuHomeLink = {
  name: appSettings.customer.Customer2 + ' Dashboard',
  linkTo: '/home/zulily?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-eye'
}
let zuShipListLink = {
  name: 'Shipment List',
  linkTo: '/home/zulily/shiplist?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-truck'
}
let zuEvolveBrokerageLink = {
  name: 'Evolve Brokerage',
  linkTo: '/home/zulily/evolvebrokerage?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-plane'
}

// let zuBrokerMilestoneUpdateLink = {
//   name: 'Milestone Entry',
//   linkTo: '/home/zulily/milestoneentry?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=intransit&filterValue=null&shiptocountry=null',
//   faIconName: 'fa-edit'
// }


let zuReportingLink = {
  name: 'Reporting',
  linkTo: '/home/zulily/reporting?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&shiptocountry=null',
  faIconName: 'fa-area-chart'
}
let zuDataExportLink = {
  name: 'Data Export',
  linkTo: '/home/zulily/dataexport?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null&shiptocountry=null',
  faIconName: 'fa-floppy-o'
}
let zuHazMatLink = {
  name: 'Hazmat',
  linkTo: '/home/zulily/hazmat?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-exclamation-triangle'
}



//Demo Links
let demoHomeLink = {
  name: appSettings.customer.Customer2 + ' Dashboard',
  linkTo: '/home/demo?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-eye'
}
let demoShipListLink = {
  name: 'Shipment List',
  linkTo: '/home/demo/shiplist?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-truck'
}
let demoEvolveBrokerageLink = {
  name: 'Evolve Brokerage',
  linkTo: '/home/demo/evolvebrokerage?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-plane'
}


let demoReportingLink = {
  name: 'Reporting',
  linkTo: '/home/demo/reporting?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&shiptocountry=null',
  faIconName: 'fa-area-chart'
}
let demoDataExportLink = {
  name: 'Data Export',
  linkTo: '/home/demo/dataexport?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null&shiptocountry=null',
  faIconName: 'fa-floppy-o'
}
let demoHazMatLink = {
  name: 'Hazmat',
  linkTo: '/home/demo/hazmat?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=intransit&filterValue=null&shiptocountry=null',
  faIconName: 'fa-exclamation-triangle'
}



//GB Links
let gbHomeLink = {
  name: languageTranslation( 'D2CDashboard', languageSelection ) , 
  linkTo: '/home/gaobo?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null&customercode=null&shipmenttype=null',
  faIconName: 'fa-eye'
}
let gbShipListLink = {
  name: languageTranslation( 'ShipmentList', languageSelection ), //'Shipment List',
  linkTo: '/home/gaobo/shiplist?startdt=null&enddt=null&datetype=null&notdeparted=2&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=null&filterValue=null&customercode=null&shipmenttype=null',
  faIconName: 'fa-truck'
}
let gbReportingLink = {
  name: languageTranslation( 'Reporting', languageSelection ), //'Reporting',
  linkTo: '/home/gaobo/reporting?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&customercode=null&shipmenttype=null',
  faIconName: 'fa-area-chart'
}
let gbMetricsLink = {
  name: languageTranslation( 'Metrics', languageSelection ), //'Metrics',
  linkTo: '/home/gaobo/metrics?startdt=null&enddt=null&datetype=null&notdeparted=2&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=null&filterValue=null&customercode=null&shipmenttype=null',
  faIconName: 'fa-tachometer'
}
let gbHazMatLink = {
  name: 'Hazmat',
  linkTo: '/home/gaobo/hazmat?startdt=null&enddt=null&datetype=null&notdeparted=2&portexit=null&portentry=null&finalcarrier=null&filter=null&filterValue=null&customercode=null&shipmenttype=null',
  faIconName: 'fa-exclamation-triangle'
}
let gbDataExportLink = {
  name: languageTranslation( 'DataExport', languageSelection ), //'Data Export',
  linkTo: '/home/gaobo/dataexport?startdt=null&enddt=null&datetype=null&notdeparted=null&portexit=null&portentry=null&finalcarrier=null&mawb=null&mc=null&hawb=null&filter=null&filterValue=null&customercode=null&shipmenttype=null',
  faIconName: 'fa-floppy-o'
}



//Google Links
let homeLink = {
  name: appSettings.customer.Customer1 + ' Dashboard',
  linkTo: '/home/google?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null',
  faIconName: 'fa-eye'
}
let milestoneAlertsLink = {
  name: 'Milestone Alerts',
  linkTo: '/home/google/milestonealerts?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null',
  faIconName: 'fa-bell'
}
let kpiLink = {
  name: 'KPI',
  linkTo: '/home/google/kpi?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null&view=kpi',
  faIconName: 'fa-tachometer'
}
let networkReportingLink = {
  name: 'Network Reporting',
  linkTo: '/home/google/reporting?startdt=null&enddt=null&mode=null&network=1&measure=1',
  faIconName: 'fa-area-chart'
}
let statCardsLink = {
  name: 'Status Cards',
  linkTo: '/home/google/statscards?startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null&statCardInput=null',
  faIconName: 'fa-check-square-o'
}
let shipTrackerLink = {
  name: 'Shipment List',
  linkTo: '/home/google/shipmenttracker?startdt=null&enddt=null&datetype=null&intransit=O&mode=null&carrier=null&filter=null&filterValue=null',
  faIconName: 'fa-truck'
}
let mapViewLink = {
  name: 'Map View',
  linkTo: '/home/google/mapshiptracker?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null',
  // faIconName: 'fa-map-o'
  faIconName: 'fa-globe'
}
let dataExportLink = {
  name: 'Data Export',
  linkTo: '/home/google/dataexportview?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null',
  faIconName: 'fa-floppy-o'
}
let acknowledgeActionLink = {
  name: 'Acknowledge Actions',
  linkTo: '/home/google/acknowledgeaction?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null',
  faIconName: 'fa-tasks'
}
let serviceIssuesLink = {
  name: 'Service Issues',
  linkTo: '/home/google/serviceissues?startdt=null&enddt=null&datetype=null&intransit=null&mode=null&carrier=null&filter=null&filterValue=null',
  faIconName: 'fa-exclamation-circle'
}

//UPS Links
let upsLabelCreationLink = {
  name: languageTranslation( 'UPSLabelCreation', languageSelection ), //'UPS Label Creation',
  linkTo: '/home/ups/labelcreation',
  faIconName: 'fa-tag'
}




////////////////3: Add Route and Link to permissions matrix:

let routePath = [

//General Routes
  {"navRoute":contenthome, "navLink":null,                          "product":["Demo", "Omni", "D2C"], "customer":["General"], "admin":[true, false], "group": ["Demo", "OMNI", "Google", "Zulily", "Gao Bo"], "businessUnit": ["Test", "Operations", "Sales"] },

//User Routes
  {"navRoute":usermanagement, "navLink":userManagementLink,         "product":["Demo", "Omni", "D2C"], "customer":["User"], "admin":[true],        "group": ["Demo", "OMNI", "Google", "Zulily", "Gao Bo"], "businessUnit": ["Test", "Operations", "Sales"] },
  {"navRoute":myprofile, "navLink":myProfileLink,                   "product":["Demo", "Omni", "D2C"], "customer":["User"], "admin":[true, false], "group": ["Demo", "OMNI", "Google", "Zulily", "Gao Bo"], "businessUnit": ["Test", "Operations", "Sales"] },

  {"navRoute":signup, "navLink":null,                               "product":["Demo", "Omni", "D2C"], "customer":["User"], "admin":[true, false], "group": ["Demo", "OMNI", "Google", "Zulily", "Gao Bo"], "businessUnit": ["Test", "Operations", "Sales"] },
  {"navRoute":userchangepassword, "navLink":null,                   "product":["Demo", "Omni", "D2C"], "customer":["User"], "admin":[true, false], "group": ["Demo", "OMNI", "Google", "Zulily", "Gao Bo"], "businessUnit": ["Test", "Operations", "Sales"] },

//ZU Routes
  {"navRoute":zuDashHome, "navLink":zuHomeLink,                     "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuShipList, "navLink":zuShipListLink,                 "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuEvolveBrokerage, "navLink":zuEvolveBrokerageLink,   "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  // {"navRoute":zuBrokerMilestoneUpdate, "navLink":zuBrokerMilestoneUpdateLink, "product":["Demo", "Omni", "D2C"], "customer":["Zulily"], "admin":[true], "group": ["Demo", "OMNI"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuReporting, "navLink":zuReportingLink,               "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuShipDetail, "navLink":null,                         "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuDataExport, "navLink":zuDataExportLink,             "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI"],                     "businessUnit": [] },
  {"navRoute":zuDtlHawbDetails, "navLink":null,                     "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuMCDetails, "navLink":null,                          "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":zuHazMat, "navLink":zuHazMatLink,                     "product":["Demo", "Omni", "D2C"], "customer":["Zulily", "Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "Zulily"],           "businessUnit": ["Test", "Operations"] },


  //Demo Routes
  {"navRoute":demoDashHome, "navLink":demoHomeLink,                     "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoShipList, "navLink":demoShipListLink,                 "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoEvolveBrokerage, "navLink":demoEvolveBrokerageLink,   "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  // {"navRoute":demoBrokerMilestoneUpdate, "navLink":demoBrokerMilestoneUpdateLink, "product":["Demo", "Omni", "D2C"], "customer":["demolily"], "admin":[true], "group": ["Demo", "OMNI"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoReporting, "navLink":demoReportingLink,               "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoShipDetail, "navLink":null,                         "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoDataExport, "navLink":demoDataExportLink,             "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI"],                     "businessUnit": [] },
  {"navRoute":demoDtlHawbDetails, "navLink":null,                     "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoMCDetails, "navLink":null,                          "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":demoHazMat, "navLink":demoHazMatLink,                     "product":["Demo", "Omni", "D2C"], "customer":["Demo"], "admin":[true, false], "group": ["Demo", "OMNI", "demolily"],           "businessUnit": ["Test", "Operations"] },



//GB Routes
  {"navRoute":gbDashHome,   "navLink":gbHomeLink,                     "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "Gao Bo"],                     "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbEvolveDashHome,   "navLink":gbHomeLink,               "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["OMNI"],                     "businessUnit": ["Test", "Operations"] },
  

  {"navRoute":gbShipList, "navLink":gbShipListLink,                   "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbReporting, "navLink":gbReportingLink,                 "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbDtlHawbDetails, "navLink":null,                       "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbMCDetails, "navLink":null,                            "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbShipDetail, "navLink":null,                           "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  // {"navRoute":gbHazMat, "navLink":gbHazMatLink,                       "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbMetrics, "navLink":gbMetricsLink,                     "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":gbDataExport, "navLink":gbDataExportLink,               "product":["Demo", "D2C"], "customer":["Gao Bo"], "admin":[true, false], "group": ["Demo", "OMNI", "Gao Bo"],           "businessUnit": ["Test", "Operations"] },



//Google Routes
  {"navRoute":omniHome, "navLink":homeLink,                         "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":googleHome, "navLink":homeLink,                       "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Google"],                 "businessUnit": ["Test", "Operations"] }, 
  {"navRoute":salesHome, "navLink":homeLink,                        "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Sales"] },

  {"navRoute":shipmenttracker, "navLink":shipTrackerLink,           "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Test", "Operations", "Sales"] },
  {"navRoute":mapshiptracker, "navLink":mapViewLink,                "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": [],                         "businessUnit": ["Test", "Operations"] },
  {"navRoute":statscards, "navLink":statCardsLink,                  "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Test", "Operations"] },
  {"navRoute":acknowledgeAction, "navLink":acknowledgeActionLink,   "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": [],                         "businessUnit": ["Test", "Operations"] },
  {"navRoute":serviceissues, "navLink":serviceIssuesLink,           "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI"],           "businessUnit": ["Test"] },
  {"navRoute":milestoneAlerts, "navLink":milestoneAlertsLink,       "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":kpi, "navLink":kpiLink,                               "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":reporting, "navLink":networkReportingLink,            "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI"],           "businessUnit": ["Test", "Operations"] },
  {"navRoute":dataexportview, "navLink":dataExportLink,             "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Test", "Operations"] },

  {"navRoute":shipmentdetails, "navLink":null,                      "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Test", "Operations", "Sales"] },
  {"navRoute":shipmenttrackerfiltered, "navLink":null,              "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Test", "Operations"] },
  {"navRoute":shipmenttrackerparams, "navLink":null,                "product":["Omni", "D2C", "Demo"], "customer":["Google"], "admin":[true, false], "group": ["Demo", "OMNI", "Google"], "businessUnit": ["Test", "Operations", "Sales"] },

  //UPS Routes
  {"navRoute":upsLabelCreation, "navLink":upsLabelCreationLink,     "product":["Demo", "D2C"], "customer":["User"], "admin":[true],        "group": ["OMNI"], "businessUnit": ["Test", "Operations"] },


,
]


  if (filter == 'route'){
    let filteredRoute = routePath.filter(x => x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.product.includes(appSettings.product) )
    return filteredRoute
  }

  else if (filter == 'menuLink'){
    let filteredMenuLink = routePath.filter(x => x.customer.includes(navSelection) && x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.navLink != null && x.product.includes(appSettings.product))
    return filteredMenuLink
  }

  else if (filter == 'userLink'){
    let filteredUserLink = routePath.filter(x => x.customer.includes("User") && x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.navLink != null)
    return filteredUserLink
  }



}




//This section produces necessary arrays to build menus based on user properties
    
const navRoutes = (  admin, group, businessUnit, languageSelection  ) => {
  // let filteredRoute = routePath.filter(x => x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.product.includes(appSettings.product) )
  let filteredRoute = routePathFunc(  admin, group, businessUnit, null,languageSelection, 'route'  )

  return (
    filteredRoute.map((route, index) => (
        route.navRoute
    ))
    );
}

export const navMenu = (  admin, group, businessUnit  ) => {
  try{
    let navMenu = localStorage.getItem('PageConfig.CustomerName');
    if(navMenu != null && navMenu != undefined){
      return JSON.parse(navMenu)
    }
  }
  catch(e){
    console.log('error getting navMenu: ', e)
  }

}


const navLinks = ( admin, group, businessUnit, navSelection, languageSelection  ) => {

  // let filteredZuLink = routePath.filter(x => x.product.includes("Zulily") && x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.navLink != null)
  // let zuLinks = filteredZuLink.map((route, index) => (route.navLink))

  // let filteredMenuLink = routePath.filter(x => x.customer.includes(navSelection) && x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.navLink != null && x.product.includes(appSettings.product))
  let filteredMenuLink = routePathFunc(  admin, group, businessUnit, navSelection , languageSelection, 'menuLink'  )


  let menuLinks = filteredMenuLink.map((route, index) => (route.navLink))

  // let filteredUserLink = routePath.filter(x => x.customer.includes("User") && x.admin.includes(admin) && x.group.includes(group) && x.businessUnit.includes(businessUnit) && x.navLink != null)
  let filteredUserLink  = routePathFunc(  admin, group, businessUnit, navSelection, languageSelection, 'userLink'  ) 


  let userLinks = filteredUserLink.map((route, index) => (route.navLink))



  let userDash;
    userDash = {
      id: 3,
      group: languageTranslation( 'User', languageSelection ), //'User',  //TODO: this does not display
      menus: userLinks
    }


  let navMenu;
  navMenu   = {
    id: 2,
    // group: appSettings.customer.Customer2,
    group: navSelection,
    menus: menuLinks
  }
  

  let productsMenu;
  productsMenu = [
    navMenu,
    userDash
    ]

  let sidemenu = productsMenu.filter(x => x != undefined )

  return (sidemenu)

}






export const MainRoutes = ({     
  admin,              
  group,        
  businessUnit,
  languageSelection
     }) => (


      <Switch>
        <PrivateRoute exact path="/auth" component={signInAuth} />
        {navRoutes(admin, group, businessUnit, languageSelection)}
        <Route path="*" component={pageNotFound} />
      </Switch>





);    


MainRoutes.propTypes = {
  pendingApproval:        PropTypes.bool,     //if user account is pending approval
  admin:                  PropTypes.bool,     //if user has admin rights
  group:                  PropTypes.string,   //this is organizaton (Omni, google)
  businessUnit:           PropTypes.string,   //this is role in organization (operations, sales)
  languageSelection:      PropTypes.string 
};


export const NavigationLinks  = ({    
  pendingApproval,     
  admin,              
  group,        
  businessUnit,       
  navSelection,
  languageSelection
     }) => (

      navLinks(admin, group, businessUnit, navSelection, languageSelection)     
);    

NavigationLinks.propTypes = {
  pendingApproval:        PropTypes.bool,     //if user account is pending approval
  admin:                  PropTypes.bool,     //if user has admin rights
  group:                  PropTypes.string,   //this is organizaton (Omni, google)
  businessUnit:           PropTypes.string,   //this is role in organization (operations, sales)
  navSelection:           PropTypes.string,    //this is current navigation menu selection for different customers
  languageSelection:      PropTypes.string 
};






