import * as types from '../constants/zuActionTypes';
import { appSettings } from '../../app/appSettings'
// import { TRUE } from 'node-sass';
// import reducers from './index';

const initialState = {
    isOTDtoMCDataLoading: true,
    OTDtoMCData: [],

    isOTDtoFlightDepartDataLoading: true,
    OTDtoFlightDepartData: [],

    isOTDtoFlightArrivalDataLoading: true,
    OTDtoFlightArrivalData: [],

    isAgingChartDataLoading: true,
    AgingChartData: [],

    isStatCardDataLoading: true,
    StatCardData: [],

    isOTDtoThreeAvgsDataLoading: true,
    OTDtoThreeAvgsData: [],

    isMawbDataLoading: true,
    MawbDetailData: [],

    isMCDataLoading: true,
    MCDetailData: [],

    isHawbDataLoading: true,
    HawbDetailData: [],

    isHawbMCDataLoading: true,
    HawbMCDetailData: [],

    isDimWeightChartDataLoading: true,
    DimWeightChartData: [],

    isJobEventsDataLoading: true,
    JobEventsData: [],

    isIBCDtlDataLoading: true,
    IBCDtlData: [],

    isHazMatDataLoading: true,
    HazMatData: [],

    isPendingSailingDataLoading: true,
    PendingSailingData: [],

    isDemurrageDataLoading: true,
    DemurrageData: [],

    isUpsMiDataLoading: true,
    UpsMiData: [],

    isDailyShipPortDataLoading: true,
    DailyShipPortData: [],

    isAvgCustClearDataLoading: true,
    AvgCustClearData: [],

    isUpsNNScoreLoading: true,
    UpsNNScore: null,

    isEvolveBrokerageDataLoading: true,
    EvolveBrokerageData: [],

    isEvolveBrokerageStatCardLoading: true,
    EvolveBrokerageStatCard: [],

    isZuMbolEmailFilenameDataLoading: false,
    ZuMbolEmailFilenameData: [],

    isZuMbolEmailUrlLoading: false,
    ZuMbolEmailUrlData: []

};

function checkStatusCode(res) {
    if (res === "200") { return true }
    else { return false }
}

function actionOnError(proc) {
    console.log('DB connection error')

    // if (appSettings.devEnvironment === true) {
    //         window.alert("Please reload and try again.")
    // } 
    // else {
    //         // window.alert("Please reload and try again.")
    // }
}



export const ZuChartsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state;


        case types.OTD_TO_MC_DATA_GET:
            return Object.assign({}, state, {
                isOTDtoMCDataLoading: true
            });
        case types.OTD_TO_MC_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newOTDtoMCData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isOTDtoMCDataLoading: false,
                OTDtoMCData: newOTDtoMCData.data
            });


        case types.OTD_TO_FLIGHT_DEPART_DATA_GET:
            return Object.assign({}, state, {
                isOTDtoFlightDepartDataLoading: true
            });
        case types.OTD_TO_FLIGHT_DEPART_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newOTDtoFDData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isOTDtoFlightDepartDataLoading: false,
                OTDtoFlightDepartData: newOTDtoFDData.data
            });


        case types.OTD_TO_FLIGHT_ARRIVAL_DATA_GET:
            return Object.assign({}, state, {
                isOTDtoFlightArrivalDataLoading: true
            });
        case types.OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newOTDtoFAData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isOTDtoFlightArrivalDataLoading: false,
                OTDtoFlightArrivalData: newOTDtoFAData.data
            });

        case types.AGING_CHART_DATA_GET:
            return Object.assign({}, state, {
                isAgingChartDataLoading: true
            });
        case types.AGING_CHART_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newAgingData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isAgingChartDataLoading: false,
                AgingChartData: newAgingData.data
            });

        case types.DIM_WEIGHT_CHART_DATA_GET:
            return Object.assign({}, state, {
                isDimWeightChartDataLoading: true
            });
        case types.DIM_WEIGHT_CHART_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newDimWeightData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isDimWeightChartDataLoading: false,
                DimWeightChartData: newDimWeightData.data
            });

        case types.STAT_CARD_DATA_GET:
            return Object.assign({}, state, {
                isStatCardDataLoading: true
            });
        case types.STAT_CARD_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newStatCardData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isStatCardDataLoading: false,
                StatCardData: newStatCardData.data
            });

        case types.OTD_TO_THREE_AVGS_DATA_GET:
            return Object.assign({}, state, {
                isOTDtoThreeAvgsDataLoading: true
            });
        case types.OTD_TO_THREE_AVGS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newOTDtoThreeAvgsData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isOTDtoThreeAvgsDataLoading: false,
                OTDtoThreeAvgsData: newOTDtoThreeAvgsData.data
            });

        case types.MAWB_DETAILS_DATA_GET:
            return Object.assign({}, state, {
                isMawbDataLoading: true
            });
        case types.MAWB_DETAILS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newMawbData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isMawbDataLoading: false,
                MawbDetailData: newMawbData.data
            });

        case types.MC_DETAILS_DATA_GET:
            return Object.assign({}, state, {
                isMCDataLoading: true
            });
        case types.MC_DETAILS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newMCData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isMCDataLoading: false,
                MCDetailData: newMCData.data
            });

        case types.HAWB_DETAILS_DATA_GET:
            return Object.assign({}, state, {
                isHawbDataLoading: true
            });
        case types.HAWB_DETAILS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newHawbData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHawbDataLoading: false,
                HawbDetailData: newHawbData.data
            });

        case types.HAWB_MC_DETAILS_DATA_GET:
            return Object.assign({}, state, {
                isHawbMCDataLoading: true
            });
        case types.HAWB_MC_DETAILS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newHawbMCData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHawbMCDataLoading: false,
                HawbMCDetailData: newHawbMCData.data
            });

        case types.JOB_EVENTS_DATA_GET:
            return Object.assign({}, state, {
                isJobEventsDataLoading: true
            });
        case types.JOB_EVENTS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newJobEventsData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isJobEventsDataLoading: false,
                JobEventsData: newJobEventsData.data
            });

        case types.IBC_DTL_DATA_GET:
            return Object.assign({}, state, {
                isIBCDtlDataLoading: true
            });
        case types.IBC_DTL_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newIBCDtlData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isIBCDtlDataLoading: false,
                IBCDtlData: newIBCDtlData.data
            });

        case types.HAZ_MAT_DATA_GET:
            return Object.assign({}, state, {
                isHazMatDataLoading: true
            });
        case types.HAZ_MAT_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newHazMatData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isHazMatDataLoading: false,
                HazMatData: newHazMatData.data
            });

        case types.PENDING_SAILING_CHART_DATA_GET:
            return Object.assign({}, state, {
                isPendingSailingDataLoading: true
            });
        case types.PENDING_SAILING_CHART_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newPendingSailingData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isPendingSailingDataLoading: false,
                PendingSailingData: newPendingSailingData.data
            });

        case types.DEMURRAGE_CHART_DATA_GET:
            return Object.assign({}, state, {
                isDemurrageDataLoading: true
            });
        case types.DEMURRAGE_CHART_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newDemurrageData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isDemurrageDataLoading: false,
                DemurrageData: newDemurrageData.data
            });

        case types.UPS_MI_DATA_GET:
            return Object.assign({}, state, {
                isUpsMiDataLoading: true
            });
        case types.UPS_MI_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newUpsMiData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isUpsMiDataLoading: false,
                UpsMiData: newUpsMiData.data
            });

        case types.DAILY_SHIP_PORTS_DATA_GET:
            return Object.assign({}, state, {
                isDailyShipPortDataLoading: true
            });
        case types.DAILY_SHIP_PORTS_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newShipPortData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isDailyShipPortDataLoading: false,
                DailyShipPortData: newShipPortData.data
            });

        case types.AVG_CUST_CLEAR_DATA_GET:
            return Object.assign({}, state, {
                isAvgCustClearDataLoading: true
            });
        case types.AVG_CUST_CLEAR_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newCustClearData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isAvgCustClearDataLoading: false,
                AvgCustClearData: newCustClearData.data
            });

        case types.DS_NEURAL_NET_SCORE_GET:
            return Object.assign({}, state, {
                isUpsNNScoreLoading: true
            });
        case types.DS_NEURAL_NET_SCORE_COMPLETE:
            try {
                if (!checkStatusCode(action.payload.statusCode)) {
                    actionOnError()

                    return Object.assign({}, state, {
                        isUpsNNScoreLoading: false,
                        UpsNNScore: null
                    });

                    // return state;

                }
                var newUpsNNScoreData = JSON.parse(action.payload.body);

                var bodyUpsNNScoreData = newUpsNNScoreData["data"]["body"]

                var scoreNNData = JSON.parse(bodyUpsNNScoreData);

                return Object.assign({}, state, {
                    isUpsNNScoreLoading: false,
                    UpsNNScore: scoreNNData["score"]
                });
            }
            catch (e) {
                return Object.assign({}, state, {
                    isUpsNNScoreLoading: false,
                    UpsNNScore: null
                });
            }

        case types.EVOLVE_BROKERAGE_REPORT_DATA_GET:
            return Object.assign({}, state, {
                isEvolveBrokerageDataLoading: true
            });
        case types.EVOLVE_BROKERAGE_REPORT_DATA_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newEvolveBrokerageData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isEvolveBrokerageDataLoading: false,
                EvolveBrokerageData: newEvolveBrokerageData.data
            });

        case types.EVOLVE_BROKERAGE_STAT_CARDS_GET:
            return Object.assign({}, state, {
                isEvolveBrokerageStatCardLoading: true
            });
        case types.EVOLVE_BROKERAGE_STAT_CARDS_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newEvolveBrokerageStatCard = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isEvolveBrokerageStatCardLoading: false,
                EvolveBrokerageStatCard: newEvolveBrokerageStatCard.data
            });



        case types.ZU_MBOL_EMAIL_FILENAME_GET:
            return Object.assign({}, state, {
                isZuMbolEmailFilenameDataLoading: true
            });
        case types.ZU_MBOL_EMAIL_FILENAME_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);
            return Object.assign({}, state, {
                isZuMbolEmailFilenameDataLoading: false,
                ZuMbolEmailFilenameData: newData.data
            });


        case types.ZU_MBOL_EMAIL_URL_GET:
            return Object.assign({}, state, {
                isZuMbolEmailUrlLoading: true
            });
        case types.ZU_MBOL_EMAIL_URL_COMPLETE:
            if (!checkStatusCode(action.payload.statusCode)) {
                actionOnError()
                return state;
            }
            var newData = JSON.parse(action.payload.body);

            // initiate download url
            try {
                window.location.href = newData.url;
            }
            catch (e) { }
            return Object.assign({}, state, {
                isZuMbolEmailUrlLoading: false,
                ZuMbolEmailUrlData: newData.url /////specific to filename url
            });

        // case types.OTD_TO_MC_DATA_ERROR:   
        //     actionOnError();
        // case types.OTD_TO_FLIGHT_DEPART_DATA_ERROR:   
        //     actionOnError();
        // case types.OTD_TO_FLIGHT_ARRIVAL_DATA_ERROR:   
        //     actionOnError();
        // case types.STAT_CARD_DATA_ERROR:   
        //     actionOnError();
        // case types.OTD_TO_THREE_AVGS_DATA_ERROR:   
        //     actionOnError();
        // case types.MAWB_DETAILS_DATA_ERROR:   
        //     actionOnError();
        // case types.MC_DETAILS_DATA_ERROR:   
        //     actionOnError();




        default:
            return state;
    }
};

export default ZuChartsReducer;
