import React, { PureComponent } from 'react';
import PropTypes  from 'prop-types';
import Loader from 'react-loader-spinner'
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';


const eventsColumns = [{
    dataField: 'DateTime',
    text: 'Date',
    sort: false,
    align: 'center',
    headerStyle: (colum, colIndex) => {
        return { width: '25%' , textAlign: 'center' };
      }
  }, {
    dataField: 'UTC_DT',
    text: 'UTC Date',
    sort: false,
    align: 'center',
    headerStyle: (colum, colIndex) => {
        return { width: '25%' , textAlign: 'center' };
      }
  }, {
    dataField: 'Description',
    text: 'Event',
    sort: false,
    align: 'left',
    headerStyle: (colum, colIndex) => {
        return { width: '75%' , textAlign: 'left' };
      }
  }
]

const mobileEventsColumns = [{
    dataField: 'DateTime',
    text: 'Date',
    sort: false,
    align: 'center',
    headerStyle: (colum, colIndex) => {
        return { width: '40%' , textAlign: 'center' };
      }
  }, {
    dataField: 'UTC_DT',
    text: 'UTC Date',
    sort: false,
    align: 'center',
    headerStyle: (colum, colIndex) => {
        return { width: '40%' , textAlign: 'center' };
      }
  }, {
    dataField: 'Description',
    text: 'Event',
    sort: false,
    align: 'left',
    headerStyle: (colum, colIndex) => {
        return { width: '60%' , textAlign: 'left' };
      }
  }
]


const OmniEventsZU = ({
    isMobile,
    isDataLoading,
    userGroupName,
    inputData
    }) => (

        <div>
            {(userGroupName === 'OMNI' || userGroupName === 'Demo') ? 

            <div>
            {(() => {
                switch (isDataLoading) {
                case true:   return     (
                    <div align="center">
                    <section className="panel">
                    <header className="panel-heading">
                        {'Airfreight Events'}
                    </header>
                    </section>
                    <Loader 
                        type="Plane"
                        color="#00BFFF"
                        height="50"	
                        width="50"
                    /> 
                    </div>

                );
                case false: return (
                    <div>
                    {(typeof(inputData) !== 'undefined' && inputData != null) ? 
                                    <section className="panel">
                                    <div className="row" style={{marginBottom: '0px', overflowX: 'hidden!important'}}>
                                    <div className="col-md-12">
                                    <header className="panel-heading">
                                    {'Airfreight Events'}
                                    </header>
                                    <div className="panel-body table-responsive" style={{backgroundColor: '#f5f5f5', overflowX: 'hidden!important', overflowX: 'hidden!important'}}>
                                    <ToolkitProvider
                                        keyField="ID"
                                        data={ inputData }
                                        // columns={ eventsColumns }
                                        columns= {(isMobile == true  ) ? mobileEventsColumns  : eventsColumns }
                                        >
                                        {
                                        props => (
                                            <div>
                                            <BootstrapTable { ...props.baseProps }   />
                                            </div>
                                        )
                                        }
                                    </ToolkitProvider>
                                    </div>
                                    </div>
                                </div>
                                </section> 
                    : 
                    <div></div> }
                    </div>
                );
                default: return <div></div>;
                }
            })()}
            </div>

        : 
        <div></div> }
        </div>


  

);

OmniEventsZU.propTypes = {
    isMobile:               PropTypes.bool,
    isDataLoading:          PropTypes.bool.isRequired,
    userGroupName:          PropTypes.string.isRequired,
    inputData:              PropTypes.array.isRequired

};

OmniEventsZU.defaultProps = {
    isDataLoading: true,
    userGroupName: '',
    inputData: []
  };


export default OmniEventsZU;

