import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import {withRouter} from 'react-router-dom';
import ReChartLine from '../../../ComponentsGeneral/ReCharts/ReChartLine'
import {appSettings} from '../../../../app/appSettings'
import * as moment from 'moment'

  const lineKeyArray = [{'lineKey': 'Avg Manifest to Flight Depart', 'stroke': '#8884d8'},
                        {'lineKey': 'Avg Manifest to Flight Arrival', 'stroke': '#82ca9d'}
                        // {'lineKey': 'AvgOTDtoMC', 'stroke': '#82ca8d'}
                    ]

  class ChartReportingZU extends PureComponent {
    constructor( props )
    {
      super( props );
    }



    onClick = (line, lineKey) => {

      var dataKey = "'" + line.dataKey + "'"

      var dateValue = moment(line.payload.Date).format('YYYY-MM-DD')

      var endDate = moment(dateValue, "YYYY-MM-DD").add(1, 'days');

      var endDateValue =  moment(endDate).format('YYYY-MM-DD') 




      this.props.zuGetMawbDetails( appSettings.zuDB, this.props.currentUserEmail, "'" + dateValue + "'", "'" + endDateValue + "'", null, null, null, null, null, null, null, null, null, dataKey, null, null );




      // this.props.history.push({
      //   // pathname: 'zulily/shiplist',
      //   pathname: 'shiplist',
      //   search: 'startdt=' + dateValue + '&' +
      //   'enddt=' + null + '&' +
      //   'datetype=' + null + '&' +
      //   'notdeparted=' + null + '&' +
      //   'portexit=' + null + '&' +
      //   'portentry=' + null + '&' +
      //   'finalcarrier=' + null  + '&' +
      //   'mawb=' + null  + '&' +
      //   'mc=' + null  + '&' +
      //   'hawb=' + null  + '&' +
      //   'filter=' + dataKey

      //   }); 




      // if(this.props.location.pathname === '/home/zulily/reporting') {
      //   this.props.history.push({
      //     // pathname: 'zulily/shiplist',
      //     // pathname: 'shiplist',
      //     search: 'startdt=' + dateValue + '&' +
      //     'enddt=' + null + '&' +
      //     'datetype=' + null + '&' +
      //     'notdeparted=' + null + '&' +
      //     'portexit=' + null + '&' +
      //     'portentry=' + null + '&' +
      //     'finalcarrier=' + null  + '&' +
      //     'mawb=' + null  + '&' +
      //     'mc=' + null  + '&' +
      //     'hawb=' + null  + '&' +
      //     'filter=' + dataKey
  
      //     }); 
   
      // }

      // else //if(this.props.location.pathname === '/home/google/') 
      // {
      //   this.props.history.push({
      //     // pathname: 'zulily/shiplist',
      //     pathname: 'shiplist',
      //     search: 'startdt=' + dateValue + '&' +
      //     'enddt=' + null + '&' +
      //     'datetype=' + null + '&' +
      //     'notdeparted=' + null + '&' +
      //     'portexit=' + null + '&' +
      //     'portentry=' + null + '&' +
      //     'finalcarrier=' + null  + '&' +
      //     'mawb=' + null  + '&' +
      //     'mc=' + null  + '&' +
      //     'hawb=' + null  + '&' +
      //     'filter=' + dataKey
  
      //     }); 
      // }


      

      // this.props.getShipmentTrackerData( this.props.currentUserEmail, null, null, "'" + data.label + "'")

      // this.props.history.push({
      //   pathname: 'home/shipmenttrackerfiltered'
      //   }); 

      // if(x === 'kpi') {
      // this.props.history.push({
      //   pathname: 'google/kpi'
      //   }); 

      // }
      // else if(x === 'milestone'){
      // this.props.history.push({
      //   pathname: 'google/milestonealerts'
      //   }); 

      // }


    }



    render() {

        let otdToAvg;
        if (this.props.isOTDtoThreeAvgsDataLoading) {
          otdToAvg=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Transit Metrics'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        }  
        else if (typeof(this.props.OTDtoThreeAvgsData) !== 'undefined' && this.props.OTDtoThreeAvgsData != null) {
            otdToAvg =

                <section className="panel">
                <header className="panel-heading">
                {'Transit Metrics'}
                </header>
                    <ReChartLine 
                        data={this.props.OTDtoThreeAvgsData} 
                        dataKey='Date'
                        lineKeys= { lineKeyArray }
                        onClick={{ onClick: (line) => this.onClick(line) }}


                    />

                </section>
        }


      return(
        <div>
            {otdToAvg}
        </div>
      );

    }
  }
  

  export default withRouter(ChartReportingZU);
  
