////npm imports
import React, {
    PureComponent
  }                         from 'react';
import Loader from 'react-loader-spinner'
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import {withRouter} from 'react-router-dom';
import ToolkitProvider, { CSVExport } from 'react-bootstrap-table2-toolkit';
import DatePicker from "react-datepicker"; //https://reactdatepicker.com/
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-dropdown-select"; //https://sanusart.github.io/react-dropdown-select/
import * as moment from 'moment'

////local imports

// import {//valueDeliveryDayFormatter, 
//     // buttonApproveAccount, 
//     // buttonRejectAccount, 
//   // hotShipmentIcon
// } from '../../resources/logic/formatting';
import Button from 'react-bootstrap/Button'

import {KPIValueFormatter, 
  buttonFormatter,
  PercentFormatter
  // milestoneAlertDetailsSeverityFormatter,
  // milestoneCustomerAlertFormatter
} from '../../../resources/logic/formatting';


import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';


import {
  ResponsiveContainer,
} from 'recharts';



const calStartValue = new Date();
calStartValue.setDate( calStartValue.getDate() - 31 );


  class KPI extends PureComponent {




    constructor( props )
    {
      super( props );

      this.state = {
        startDate: calStartValue,
        // startDate: moment().format('YYYY-MM-DD'),
        endDate: new Date(),
        // endDate: moment().format('YYYY-MM-DD'),
        dropDownValueForwarder: '',
        dropDownValueMode: ''
      };
      this.handleChangeStartDate = this.handleChangeStartDate.bind(this);
      this.handleChangeEndDate = this.handleChangeEndDate.bind(this);
      this.handleDropdownChangeForwarder = this.handleDropdownChangeForwarder.bind(this);
      this.handleDropdownChangeMode = this.handleDropdownChangeMode.bind(this);
    }

    handleChangeStartDate(date) {
      this.setState({
        startDate: date
      });
    }
  
    handleChangeEndDate(date) {
      this.setState({
        endDate: date
      });
    }

    handleDropdownChangeForwarder(value) {
      this.setState({ 
        dropDownValueForwarder: value
      });
    }

    handleDropdownChangeMode(value) {
      this.setState({ 
        dropDownValueMode: value
      });
    }

    clickApplyFilter( e ) {
      var forwarderFilter = null;
      var modeFilter = null;
      if (typeof(this.state.dropDownValueForwarder[0]) !== 'undefined' && this.state.dropDownValueForwarder[0] != null)
      {

        forwarderFilter = this.state.dropDownValueForwarder[0].value
      }

      if (typeof(this.state.dropDownValueMode[0]) !== 'undefined' && this.state.dropDownValueMode[0] != null)
      {
        modeFilter = "'" + this.state.dropDownValueMode[0].value + "'"
        
      }



      this.props.history.push({
        search: 'startdt=' + "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'" + '&' +
        'enddt=' + "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'" + '&' +
        'datetype=null'  + '&' +
        'intransit=null'  + '&' +
        'mode=' + modeFilter + '&' +
        'carrier=null'  + '&' +
        'filter=null'  + '&' +
        'filterValue=null'  + '&' +
        'view=kpi'
        }); 
      




      this.props.getKpiOverviewData( this.props.userSignInData.DBName, this.props.currentUserEmail, "'" + moment(this.state.startDate).format('YYYY-MM-DD') + "'", "'" + moment(this.state.endDate).format('YYYY-MM-DD') + "'", forwarderFilter, modeFilter )
      
      this.props.getAlertKpiTrendData( this.props.userSignInData.DBName, this.props.currentUserEmail, null, null, null, null, null, modeFilter, forwarderFilter, null, null, null, null, null, null )
      
      
    }

    detailsClick( row ) {
      var paramjobnumber = row.JobNumber
      var paramjobid = row.JobID
      
 
      var paramhousebill = row.HouseBill
      // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row, paramhousebill);

      // this.props.getShipmentTrackerDataByJobID(this.props.currentUserEmail, paramhousebill);

      this.props.history.push({
      pathname: 'shipmentdetails/',
      search: 'housebill=' + paramhousebill
      // state: {
      //   details:  row
      //   }
      })
    }

      // <Button variant="dark" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.detailsClick(row, e)}>
      // Click for Shipment Details
      // </Button>


















    render() {



      const { ExportCSVButton } = CSVExport;

      const selectMode = [
        { label: "Air", value: "AIR" },
        { label: "Ocean", value: "OCEAN" }
      ];

      const selectForwarder = [
        { label: "Omni", value: "OMNI" }
      ];
      


      /////////
      const uniqueBy = prop => list => {
        const uniques = {}
        return list.reduce(
            (result, item) => {
                if (uniques[item[prop]]) return result
                uniques[item[prop]] = item
                return [...result, item]
            },
            [],
        )
      }
      
      const uniqueByCarrier = uniqueBy('Carrier')

      const uniqueByKpiEvent = uniqueBy('KPI')



      // const average = list => list.reduce((prev, curr) => prev + curr) / list.length;

      // const list = [0, 10, 20, 30]
      // average(list) // 15




      //////////////////////
      function groupBy(list, keyGetter) {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            if (!map.has(key)) {
                map.set(key, [item]);
            } else {
                map.get(key).push(item);
            }
        });
        return map;
    }
    


      // var carrierValues = [];

      // carrierValues = Object.values(this.props.kpiDetailData.reduce(function(r, e) {
      //   var key = e.kpiID + '|' + e.Carrier;
      //   if (!r[key]) r[key] = { Carrier: e.Carrier, kpiID: e.kpiID, KPI: e.KPI ,Value: e.Value, Length: 1,  CountMetKPI: e.MetKPI, CalcAvg: e.Value, CalcPerf: e.MetKPI * 100};  
      //   else {
      //     r[key].Value += e.Value;
      //     r[key].Length += 1;
      //     r[key].CalcAvg = Math.round(r[key].Value/r[key].Length, 1);
      //     r[key].CountMetKPI += e.MetKPI;
      //     r[key].CalcPerf = Math.round(r[key].CountMetKPI/r[key].Length * 100, 1);

      //     // r[key].instances += e.instances
      //   }
      //   return r;
      // },
      // {})
      // )


      function carrierCalc( detailData ) {

        Object.values(detailData.reduce(function(r, e) {
          var key = e.kpiID + '|' + e.Carrier;
          if (!r[key]) r[key] = { Carrier: e.Carrier, kpiID: e.kpiID, KPI: e.KPI ,Value: e.Value, Length: 1,  CountMetKPI: e.MetKPI, CalcAvg: e.Value, CalcPerf: e.MetKPI * 100};  
          else {
            r[key].Value += e.Value;
            r[key].Length += 1;
            r[key].CalcAvg = Math.round(r[key].Value/r[key].Length, 1);
            r[key].CountMetKPI += e.MetKPI;
            r[key].CalcPerf = Math.round(r[key].CountMetKPI/r[key].Length * 100, 1);
  
            // r[key].instances += e.instances
          }
          return r;
        },
        {})
        )


      }



      const expandCarrierRow = {
        renderer: row => (
          <div style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'KPI Shipments: ' + row.KPI}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="Carrier"
              data = { this.props.kpiCarrierCalc.filter(x => x.kpiID == row.kpiID)  }
              // data={ uniqueByCarrier(this.props.kpiDetailData.filter(x => x.kpiID == row.kpiID)) }
              // data={ this.props.kpiDetailData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.kpiDetailData }
              columns={ carrierExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps } expandRow={ expandRow } defaultSorted={defaultSortedCarrierBy}/>
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }


      const expandLaneToKPI= {
        renderer: row => (
          <div style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Lane: ' + row.Lane}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="UniqueID"
              // data = { this.props.kpiDetailData.filter(x => x.Lane == row.Lane)  }
              data={ uniqueByKpiEvent(this.props.kpiDetailData.filter(x => x.Lane == row.Lane)) }
              // data={ this.props.kpiDetailData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.kpiDetailData }
              columns={ kpiLaneKpiEventExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps }  defaultSorted={defaultSortedDetailsBy} expandRow={ expandLaneRow } />
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }

      const expandLaneRow= {
        renderer: row => (
          <div style={{cursor: 'pointer', marginLeft: '10px', marginRight: '10px'}} >
    
          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'Lane: ' + row.Lane}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="UniqueID"
              data = { this.props.kpiDetailData.filter(x => x.Lane == row.Lane && x.kpiID == row.kpiID)  }
              // data={ uniqueByCarrier(this.props.kpiDetailData.filter(x => x.kpiID == row.kpiID)) }
              // data={ this.props.kpiDetailData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.kpiDetailData }
              columns={ kpiDetailExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps }  defaultSorted={defaultSortedDetailsBy}/>
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }




      const expandRow = {
        renderer: row => (
          <div style={{cursor: 'default', marginLeft: '10px', marginRight: '10px'}} >
    

          <div
            className="row">
      
            <div className="col-md-12">
              
              <header className="panel-heading">
              {'KPI Shipments: ' + row.KPI}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}>
      
            <ToolkitProvider
              keyField="UniqueID"
              data={ this.props.kpiDetailData.filter(x => x.kpiID == row.kpiID && x.Carrier ===row.Carrier) }
              // data={ this.props.kpiDetailData.filter(x => x.kpiID === row.kpiID) }
              // data={ this.props.kpiDetailData }
              columns={ kpiDetailExpandColumns }

              exportCSV
            >
              {
                props => (
                  <div>
                    <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                    <hr />
                    <BootstrapTable { ...props.baseProps } defaultSorted={defaultSortedDetailsBy}/>
                  </div>
                )
              }
            </ToolkitProvider>
      
            </div>
                    
              </div>
              </div>

              <br/>
              </div>
      
        ),
        onlyOneExpanding: true
      }

    // const filterRow = this.props.kpiOverviewData.filter(x => x.JobID === 50)




    const expandServiceIssues = {
      renderer: row => (
        <div style={{cursor: 'default', marginLeft: '10px', marginRight: '10px'}} >
        <div
          className="row">
    
          <div className="col-md-12">
            
            <header className="panel-heading">
            {'Service Issue: ' + row.CurrentClassification}
          </header>
          <div className="panel-body table-responsive"
          style={{backgroundColor: '#f5f5f5'}}>
    
          <ToolkitProvider
            keyField="UniqueID"
            data={ this.props.serviceIssueKpiData.filter(x => x.ServiceIssue == row.ServiceIssue && x.CurrentClassification ===row.CurrentClassification) }

            columns={ serviceIssueExpandColumns }

            exportCSV
          >
            {
              props => (
                <div>
                  <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
                  <hr />
                  <BootstrapTable { ...props.baseProps } defaultSorted={defaultSortedDetailsBy}/>
                </div>
              )
            }
          </ToolkitProvider>
    
          </div>
                  
            </div>
            </div>

            <br/>
            </div>
    
      ),
      onlyOneExpanding: true
    }

//need to have unique id key
        
      const kpiOverviewColumns = [
        {
        dataField: 'KPI',
        text: 'KPI',
        // align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        // sort: true
      }, {
        dataField: 'Value',
        text: 'Value',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
          formatter: KPIValueFormatter
        // sort: true
      }, {
      }
      ]





      const laneColumns = [
        {
        dataField: 'Lane',
        text: 'Lane',
        // align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
        sort: true
      }, {
        dataField: 'OTPU',
        text: 'On Time Pickup',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
          formatter: PercentFormatter,
        sort: true
      }, {
        dataField: 'OTDel',
        text: 'On Time Delivery',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
          formatter: PercentFormatter,
        sort: true
      }, {
        dataField: 'TransitSLA',
        text: 'Transit SLA',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
          formatter: PercentFormatter,
        sort: true
      }, {
        dataField: 'AvgTransitTimeAir',
        text: 'Average Transit Time Air',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
          // formatter: KPIValueFormatter
        sort: true
      }, {
        dataField: 'AvgTransitTimeOcean',
        text: 'Average Transit Time Ocean',
        align: 'center',
        headerStyle: (colum, colIndex) => {
          return { width: '50%' , textAlign: 'center' };
        },
          // formatter: KPIValueFormatter
        sort: true
      }

      
      ]

      const defaultSortedCarrierBy = [{
        dataField: 'CalcPerf',
        order: 'asc'  // or desc
    }];

      const carrierExpandColumns = [
        {

        dataField: 'Carrier',
        text: 'Carrier',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '75%' , textAlign: 'center' };
        }
        },
        {
        dataField: 'CalcAvg',
        text: 'Average Value',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '25%' , textAlign: 'center' };
        },
          // formatter: KPIValueFormatter
        },
        {
          dataField: 'CalcPerf',
          text: 'Performance Percent',
          align: 'center',
          sort: true,
          headerStyle: (colum, colIndex) => {
            return { width: '25%' , textAlign: 'center' };
          },
            formatter: PercentFormatter
          }
      ]



      const defaultSortedDetailsBy = [{
        dataField: 'Value',
        order: 'desc'  // or desc
    }];

    

    const kpiLaneKpiEventExpandColumns = [{
 
      dataField: 'KPI',
      text: 'KPI Event',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '28%' , textAlign: 'center' };
      },
      // sort: true
    }
    // ,{
    //   dataField: 'Value',
    //   text: 'Value',
    //   align: 'center',
    //   sort: true,
    //   headerStyle: (colum, colIndex) => {
    //     return { width: '8%' , textAlign: 'center' };
    //   },
    //   // sort: true
    // }
  
    ]





      const kpiDetailExpandColumns = [{
        dataField: 'Carrier',
        text: 'Carrier',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '32%' , textAlign: 'center' };
        },
        // formatter: milestoneAlertDetailsSeverityFormatter
        // sort: true
      },{
        dataField: 'KPI',
        text: 'KPI Event',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '28%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Value',
        text: 'Value',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '8%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'HouseBill',
        text: 'House Bill',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '12%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Mode',
        text: 'Mode',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '11%' , textAlign: 'center' };
        },
        // sort: true
      },
      {
        dataField: 'Lane',
        text: 'Lane',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '8%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   
            this.detailsClick( row )
            // // todo: trigger details data load here  
            // // var paramvalue = this.state.statedata.details.JobNumber;
            // var paramjobid = row.JobID

            // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row);
            // this.props.history.push({
            // pathname: 'shipmenttrackerdetails'
            // // state: {
            // //   details:  row
            // //   }
            // }); 
          }
          }
      }

      ]



      const serviceIssueCalcColumns = [{
 
        dataField: 'ServiceIssue',
        text: 'Service Issue',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '33%' , textAlign: 'center' };
        },
        // sort: true
      },{

      dataField: 'CurrentClassification',
      text: 'Classification',
      align: 'center',
      sort: true,
      headerStyle: (colum, colIndex) => {
        return { width: '33%' , textAlign: 'center' };
      },
      // sort: true
    },{

      dataField: 'CalcPercentage',
      text: 'Percentage',
      align: 'center',
      headerStyle: (colum, colIndex) => {
        return { width: '33%' , textAlign: 'center' };
      },
        formatter: PercentFormatter,
      sort: true
    }

      ]





      const serviceIssueExpandColumns = [{
      //   dataField: 'Carrier',
      //   text: 'Carrier',
      //   align: 'center',
      //   sort: true,
      //   headerStyle: (colum, colIndex) => {
      //     return { width: '32%' , textAlign: 'center' };
      //   },
      //   // formatter: milestoneAlertDetailsSeverityFormatter
      //   // sort: true
      // },{
        dataField: 'ServiceIssue',
        text: 'Service Issue',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '30%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'CurrentClassification',
        text: 'Current Classification',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '30%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'DaysLate',
        text: 'Days Late',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'HouseBill',
        text: 'House Bill',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },{
        dataField: 'Mode',
        text: 'Mode',
        align: 'center',
        sort: true,
        headerStyle: (colum, colIndex) => {
          return { width: '10%' , textAlign: 'center' };
        },
        // sort: true
      },
      {

        dataField: 'buttonDetails',
        text: '',
        align: 'center',
        formatter: buttonFormatter,
        headerStyle: (colum, colIndex) => {
          return { width: '10%', textAlign: 'center' };
          },
        events: {
          onClick: (e, column, columnIndex, row, rowIndex) => {   
            this.detailsClick( row )
            // // todo: trigger details data load here  
            // // var paramvalue = this.state.statedata.details.JobNumber;
            // var paramjobid = row.JobID

            // this.props.openShipDetails(this.props.currentUserEmail, paramjobid, row);
            // this.props.history.push({
            // pathname: 'shipmenttrackerdetails'
            // // state: {
            // //   details:  row
            // //   }
            // }); 
          }
          }
      }
      ]

// ******************  if(this.props.userSignInData.GroupName === 'OMNI')





      let bootStrapTableKpiOverview;
      if (this.props.isKPIOverviewLoading) {
        bootStrapTableKpiOverview=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.kpiOverviewData) !== 'undefined' && this.props.kpiOverviewData != null) {
        
        bootStrapTableKpiOverview =
        <ToolkitProvider
        keyField="kpiID"
        // data={ this.props.kpiOverviewData }
        data={ this.props.kpiOverviewData.filter(x => x.kpiID < 7) }
        columns={ kpiOverviewColumns }
        exportCSV
      >
        {
          props => (
            <div>
              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
              <hr />
              <BootstrapTable { ...props.baseProps } expandRow={ expandCarrierRow } />
            </div>
          )
        }
      </ToolkitProvider>

      } else {
        bootStrapTableKpiOverview = <div></div>;
      }


      let serviceIssuesTable;
      if (this.props.isServiceIssueKpiDataLoading) {
        serviceIssuesTable=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.serviceIssueKpiCalc) !== 'undefined' && this.props.serviceIssueKpiCalc != null) {
      serviceIssuesTable = 
      <ToolkitProvider
      keyField="CurrentClassification"
      // data={ this.props.kpiOverviewData }
      data={ this.props.serviceIssueKpiCalc }
      columns={ serviceIssueCalcColumns }
      exportCSV
    >
      {
        props => (
          <div>
            <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
            <hr />
            <BootstrapTable { ...props.baseProps } expandRow={ expandServiceIssues } />
          </div>
        )
      }
    </ToolkitProvider>

    } else {
      bootStrapTableLanePerformance = <div></div>;
    }



      let bootStrapTableLanePerformance;
      if (this.props.isKPILaneLoading) {
        bootStrapTableLanePerformance=
          <div align="center">
          <section className="panel">
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
      }
      else if (typeof(this.props.laneCalc) !== 'undefined' && this.props.laneCalc != null) {
        

        bootStrapTableLanePerformance =
        <ToolkitProvider
        keyField="Lane"
        // data={ this.props.kpiOverviewData }
        data={ this.props.laneCalc }
        columns={ laneColumns }
        exportCSV
      >
        {
          props => (
            <div>
              <ExportCSVButton { ...props.csvProps } style={{'marginTop': '15px', 'marginLeft': '15px'}} >Export CSV</ExportCSVButton>
              <hr />
              <BootstrapTable { ...props.baseProps } expandRow={ expandLaneToKPI } />
            </div>
          )
        }
      </ToolkitProvider>

      } else {
        bootStrapTableLanePerformance = <div></div>;
      }




      let milestoneTrend;
      if (this.props.isAlertKpiTrendLoading) {
        milestoneTrend=
          <div align="center">
          <section className="panel">
            <header className="panel-heading">
              {'Missed KPI Trend'}
            </header>
            </section>
            <Loader 
              type="Plane"
              color="#00BFFF"
              height="50"	
              width="50"
            /> 
          </div>
        
      }
      else if (typeof(this.props.alertKpiTrendData) !== 'undefined' && this.props.alertKpiTrendData != null) {
        milestoneTrend=
        <section className="panel">
        <header className="panel-heading">
          {'Missed KPI Trend'}
        </header>
        <div style={{height: 400, background: "#FFF"}}>
        <div style={{ width: '100%', height: 400 }}>
        <ResponsiveContainer>
              <LineChart //width={730} height={250} 
                  data={ this.props.alertKpiTrendData }
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="Date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="Missed KPI" stroke="#82ca9d" strokeWidth={2}/>
              </LineChart>
        </ResponsiveContainer>
        </div>
        </div>
            </section>
      }


      return(
        <div>


        <section className="panel">
        <header className="panel-heading">
          {'Filters'}
        </header>

        <div //className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5'}}
            >

<div className="row" style={{padding: '15px'}}>  

  <div className="col-md-4"  >
    <div className="sm-st clearfix" style={{height: '100%'}}>
      <div className="row">  
        <div style={{marginLeft: "auto",  marginRight: "auto"}}>  
            <header >
              {'Start Date'}
            </header>
              <DatePicker
                  placeholderText="Select Start Date"
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.handleChangeStartDate}
              />
        </div>
        <div style={{marginLeft: "auto",  marginRight: "auto"}}>  
        <header >
          {'End Date'}
        </header>
          <DatePicker
              placeholderText="Select End Date"
              selected={this.state.endDate}
              selectsEnd
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              onChange={this.handleChangeEndDate}
              minDate={this.state.startDate}
              // disabled={true}
          />
        </div>
      </div>
    </div>
  </div>

  <div className="col-md-4"  >
    <div className="sm-st clearfix" style={{height: '100%'}}>
      <div className="row">  
      <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
              <header>
                {'Mode'}
              </header>
            <Select 
              options={ selectMode } 
              clearable
              separator
              placeholder={ 'Select Mode...'}
              onChange={this.handleDropdownChangeMode}
            />
            </div>
      </div>
    </div>
  </div>

  <div className="col-md-4"  >
    <div className="sm-st clearfix" style={{height: '100%'}}>
      <div className="row">  
      <div style={{backgroundColor: '#FFFFFF', marginLeft: "auto",  marginRight: "auto"}}>
            <header>
                {'Forwarder'}
              </header>
            <Select 
              options={ selectForwarder } 
              clearable
              separator
              placeholder={ 'Select Forwarder...'}
              onChange={this.handleDropdownChangeForwarder}
            />
            </div>
      </div>
    </div>
  </div>

  </div>
          

        <div style={{paddingBottom: '25px', paddingLeft: '15px', paddingRight: '15px'}}>

        <Button variant="success" size="sm" block  style={{marginTop: '5px'}} onClick={(e) => this.clickApplyFilter(e)}>
        Apply Filter
          </Button>


        </div>

          </div>

      </section>

       { milestoneTrend }


          <section className="panel">

            <header className="panel-heading">
              {'KPI'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>

              { bootStrapTableKpiOverview }

            </div>

          </section>


          <section className="panel">

            <header className="panel-heading">
              {'Service Issues'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>

              { serviceIssuesTable }

            </div>

            </section>



          <section className="panel">

            <header className="panel-heading">
              {'Lane Performance'}
            </header>
            <div className="panel-body table-responsive"
            style={{backgroundColor: '#f5f5f5', cursor: 'pointer'}}>

              { bootStrapTableLanePerformance }

            </div>

            </section>
          </div>
      );
    }
  }

  export default withRouter(KPI);
  







