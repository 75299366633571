import * as types from '../constants/gbActionTypes';
import {appSettings} from '../../app/appSettings'

const initialState = {
        isMawbDataLoading: true,
        MawbDetailData: [],

        isOTDtoMCDataLoading: true,
        OTDtoMCData: [],

        isOTDtoFlightDepartDataLoading: true,
        OTDtoFlightDepartData: [],

        isOTDtoFlightArrivalDataLoading: true,
        OTDtoFlightArrivalData: [],

        isAgingChartDataLoading: true,
        AgingChartData: [],

        isStatCardDataLoading: true,
        StatCardData: [],

        isOTDtoThreeAvgsDataLoading: true,
        OTDtoThreeAvgsData: [],

        isMCDataLoading: true,
        MCDetailData: [],

        isHawbDataLoading: true,
        HawbDetailData: [],

        isHawbMCDataLoading: true,
        HawbMCDetailData: [],

        isDimWeightChartDataLoading: true,
        DimWeightChartData: [],

        isJobEventsDataLoading: true,
        JobEventsData: [],

        isIBCDtlDataLoading: true,
        IBCDtlData: [],

        isHazMatDataLoading: true,
        HazMatData: [],

        isPendingSailingDataLoading: true,
        PendingSailingData: [],

        isDemurrageDataLoading: true,
        DemurrageData: [],

        isUpsMiDataLoading: true,
        UpsMiData: [],

        isDailyShipPortDataLoading: true,
        DailyShipPortData: [],

        isAvgCustClearDataLoading: true,
        AvgCustClearData: [],

        isChartTransMetricsLoading: true,
        ChartTransMetricsData: [],

        isChartOriginFinalMileToDeliveryLoading: true,
        ChartOriginFinalMileToDeliveryData: [],

        isHawbReportingDataLoading: true,
        // HawbReportingData: [],
        TransitTimeSummary: [],
        TransitTimeDetails: [],

        isDayOfWeekMetricDataLoading: true,
        DayOfWeekSummary: [],
        DayOfWeekDetails: [],

        isDataExportLoading: false,
        DataExport: [],

        isChartDataExportLoading: false,
        ChartDataExport: [],

        isChartDetailDataExportLoading: false,
        ChartDetailDataExport: [],


        isFirstScanToDeliveryMetricLoading: false,
        FirstScanToDeliveryMetric: [],
        isFlightDepartToDeliveryMetricLoading: false,
        FlightDepartToDeliveryMetric: [],
        isFirstScanToRecoveryMetricLoading: false,
        FirstScanToRecoveryMetric: [],

        isChartManifestToDeliveryLoading: false,
        ChartManifestToDeliveryData: [],

        isDataReportsExportLoading: false,
        DataReportsExport: [],

        isManifestToDeliveryMetricLoading: false,
        ManifestToDeliveryMetric: [],

        isChartInWhsToFirstScanLoading: false,
        ChartInWhsToFirstScanData: [],

        isChartInWhsToDeliveryLoading: false,
        ChartInWhsToDeliveryData: [],

        isDailyScanCountChartLoading: false,
        DailyScanCountChartData: [],


        isChartInWhsToFlightDepartLoading: false,
        ChartInWhsToFlightDepartData: [],

        isChartInWhsToFreightRecoveryLoading: false,
        ChartInWhsToFreightRecoveryData: [],

        isChartFlightDepartToFirstScanLoading: false,
        ChartFlightDepartToFirstScanData: [],

        isBulkHawbDetailsLoading: false,
        // BulkHawbDetailsData: []

        bulkMAWBData:  [],
        bulkMicomData: [],
        bulkPackageData: [],
        bulkUpsData: []
};

function checkStatusCode( res )
{
        if(res === "200"){ return true }
        else { return false }
}

function actionOnError( proc )
{
        console.log('DB connection error')
}



export const GbDataReducer = ( state = initialState, action ) =>
{
	switch( action.type )
	{
        case types.RESET_REDUX_STATE:
            state = initialState;
            return state;

             

        case types.GB_MAWB_DETAILS_DATA_GET:   
        return Object.assign({}, state, {
            isMawbDataLoading: true
        });       
        case types.GB_MAWB_DETAILS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newMawbData = JSON.parse(action.payload.body);

        return Object.assign({}, state, {
            isMawbDataLoading: false,
            MawbDetailData: newMawbData.data
        });   





                   
        case types.GB_OTD_TO_MC_DATA_GET:   
        return Object.assign({}, state, {
            isOTDtoMCDataLoading: true
        });       
        case types.GB_OTD_TO_MC_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newOTDtoMCData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isOTDtoMCDataLoading: false,
            OTDtoMCData: newOTDtoMCData.data
        });


        case types.GB_OTD_TO_FLIGHT_DEPART_DATA_GET:   
        return Object.assign({}, state, {
            isOTDtoFlightDepartDataLoading: true
        });       
        case types.GB_OTD_TO_FLIGHT_DEPART_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newOTDtoFDData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isOTDtoFlightDepartDataLoading: false,
            OTDtoFlightDepartData: newOTDtoFDData.data
        });


        case types.GB_OTD_TO_FLIGHT_ARRIVAL_DATA_GET:   
        return Object.assign({}, state, {
            isOTDtoFlightArrivalDataLoading: true
        });       
        case types.GB_OTD_TO_FLIGHT_ARRIVAL_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newOTDtoFAData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isOTDtoFlightArrivalDataLoading: false,
            OTDtoFlightArrivalData: newOTDtoFAData.data
        });       
        
        case types.GB_AGING_CHART_DATA_GET:   
        return Object.assign({}, state, {
            isAgingChartDataLoading: true
        });       
        case types.GB_AGING_CHART_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newAgingData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isAgingChartDataLoading: false,
            AgingChartData: newAgingData.data
        });     

        case types.GB_DIM_WEIGHT_CHART_DATA_GET:   
        return Object.assign({}, state, {
            isDimWeightChartDataLoading: true
        });       
        case types.GB_DIM_WEIGHT_CHART_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newDimWeightData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isDimWeightChartDataLoading: false,
            DimWeightChartData: newDimWeightData.data
        });     

        case types.GB_STAT_CARD_DATA_GET:   
        return Object.assign({}, state, {
            isStatCardDataLoading: true
        });       
        case types.GB_STAT_CARD_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newStatCardData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isStatCardDataLoading: false,
            StatCardData: newStatCardData.data
        });      
        
        case types.GB_OTD_TO_THREE_AVGS_DATA_GET:   
        return Object.assign({}, state, {
            isOTDtoThreeAvgsDataLoading: true
        });       
        case types.GB_OTD_TO_THREE_AVGS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newOTDtoThreeAvgsData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isOTDtoThreeAvgsDataLoading: false,
            OTDtoThreeAvgsData: newOTDtoThreeAvgsData.data
        });   


        case types.GB_CHART_TRANS_METRICS_GET:   
        return Object.assign({}, state, {
            isChartTransMetricsLoading: true
        });       
        case types.GB_CHART_TRANS_METRICS_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartTransData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartTransMetricsLoading: false,
            ChartTransMetricsData: newChartTransData.data
        });   


        case types.GB_MAWB_DETAILS_DATA_GET:   
        return Object.assign({}, state, {
            isMawbDataLoading: true
        });       
        case types.GB_MAWB_DETAILS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newMawbData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isMawbDataLoading: false,
            MawbDetailData: newMawbData.data
        });   

        case types.GB_MC_DETAILS_DATA_GET:   
        return Object.assign({}, state, {
            isMCDataLoading: true
        });       
        case types.GB_MC_DETAILS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newMCData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isMCDataLoading: false,
            MCDetailData: newMCData.data
        });   

        case types.GB_HAWB_DETAILS_DATA_GET:   
        return Object.assign({}, state, {
            isHawbDataLoading: true
        });       
        case types.GB_HAWB_DETAILS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newHawbData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isHawbDataLoading: false,
            HawbDetailData: newHawbData.data
        });  
        
        case types.GB_HAWB_MC_DETAILS_DATA_GET:   
        return Object.assign({}, state, {
            isHawbMCDataLoading: true
        });       
        case types.GB_HAWB_MC_DETAILS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newHawbMCData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isHawbMCDataLoading: false,
            HawbMCDetailData: newHawbMCData.data
        });   

        case types.GB_JOB_EVENTS_DATA_GET:   
        return Object.assign({}, state, {
            isJobEventsDataLoading: true
        });       
        case types.GB_JOB_EVENTS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newJobEventsData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isJobEventsDataLoading: false,
            JobEventsData: newJobEventsData.data
        });   

        case types.GB_IBC_DTL_DATA_GET:   
        return Object.assign({}, state, {
            isIBCDtlDataLoading: true
        });       
        case types.GB_IBC_DTL_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newIBCDtlData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isIBCDtlDataLoading: false,
            IBCDtlData: newIBCDtlData.data
        });   

        case types.GB_HAZ_MAT_DATA_GET:   
        return Object.assign({}, state, {
            isHazMatDataLoading: true
        });       
        case types.GB_HAZ_MAT_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newHazMatData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isHazMatDataLoading: false,
            HazMatData: newHazMatData.data
        });   

        case types.GB_PENDING_SAILING_CHART_DATA_GET:   
        return Object.assign({}, state, {
            isPendingSailingDataLoading: true
        });       
        case types.GB_PENDING_SAILING_CHART_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newPendingSailingData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isPendingSailingDataLoading: false,
            PendingSailingData: newPendingSailingData.data
        });   

        case types.GB_DEMURRAGE_CHART_DATA_GET:   
        return Object.assign({}, state, {
            isDemurrageDataLoading: true
        });       
        case types.GB_DEMURRAGE_CHART_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newDemurrageData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isDemurrageDataLoading: false,
            DemurrageData: newDemurrageData.data
        });   

        case types.GB_UPS_MI_DATA_GET:   
        return Object.assign({}, state, {
            isUpsMiDataLoading: true
        });       
        case types.GB_UPS_MI_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newUpsMiData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isUpsMiDataLoading: false,
            UpsMiData: newUpsMiData.data
        });   

        case types.GB_MISSING_UPS_EVENTS_GET:   
        return Object.assign({}, state, {
            isUpsMiDataLoading: true
        });       
        case types.GB_MISSING_UPS_EVENTS_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newUpsMiData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isUpsMiDataLoading: false,
            UpsMiData: newUpsMiData.data
        });   



        case types.GB_DAILY_SHIP_PORTS_DATA_GET:   
        return Object.assign({}, state, {
            isDailyShipPortDataLoading: true
        });       
        case types.GB_DAILY_SHIP_PORTS_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newShipPortData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isDailyShipPortDataLoading: false,
            DailyShipPortData: newShipPortData.data
        });   

        case types.GB_AVG_CUST_CLEAR_DATA_GET:   
        return Object.assign({}, state, {
            isAvgCustClearDataLoading: true
        });       
        case types.GB_AVG_CUST_CLEAR_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newCustClearData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isAvgCustClearDataLoading: false,
            AvgCustClearData: newCustClearData.data
        });   

        case types.GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_GET:   
        return Object.assign({}, state, {
            isChartOriginFinalMileToDeliveryLoading: true
        });       
        case types.GB_CHART_ORIGIN_FINAL_MILE_TO_DELIVERY_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartOriginFinalMileToDeliveryData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartOriginFinalMileToDeliveryLoading: false,
            ChartOriginFinalMileToDeliveryData: newChartOriginFinalMileToDeliveryData.data
        });      

        case types.GB_HAWB_REPORTING_DATA_GET:   
        return Object.assign({}, state, {
            isHawbReportingDataLoading: true
        });       
        case types.GB_HAWB_REPORTING_DATA_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newHawbReportingData = JSON.parse(action.payload.body);
        // console.log('newHawbReportingData', newHawbReportingData)

        var parseTransitTimeDetails = newHawbReportingData.data[0].TransitTimeDetails
        var parseTransitTimeSummary = newHawbReportingData.data[0].TransitTimeSummary

        var jsonTransitTimeSummary = JSON.parse(parseTransitTimeSummary)
        var jsonTransitTimeDetails = JSON.parse(parseTransitTimeDetails)

        return Object.assign({}, state, {
            isHawbReportingDataLoading: false,
            // HawbReportingData: newHawbReportingData.data,
            TransitTimeSummary: jsonTransitTimeSummary,
            TransitTimeDetails: jsonTransitTimeDetails
        });      


        case types.GB_DAY_OF_WEEK_METRICS_GET:   
        return Object.assign({}, state, {
            isDayOfWeekMetricDataLoading: true
        });       
        case types.GB_DAY_OF_WEEK_METRICS_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newDayOfWeekData = JSON.parse(action.payload.body);
        // console.log('newDayOfWeekData', newDayOfWeekData)

        var parseDayOfWeekDetails = newDayOfWeekData.data[0].DayOfWeekDetails
        var parseDayOfWeekSummary = newDayOfWeekData.data[0].DayOfWeekSummary

        var jsonDayOfWeekSummary = JSON.parse(parseDayOfWeekSummary)
        var jsonDayOfWeekDetails = JSON.parse(parseDayOfWeekDetails)

        return Object.assign({}, state, {
            isDayOfWeekMetricDataLoading: false,
            DayOfWeekSummary: jsonDayOfWeekSummary,
            DayOfWeekDetails: jsonDayOfWeekDetails
        });    

        case types.GB_DATA_EXPORT_GET:   
        return Object.assign({}, state, {
            isDataExportLoading: true
        });       
        case types.GB_DATA_EXPORT_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newDataExport = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isDataExportLoading: false,
            DataExport: newDataExport.data
        });   

        case types.GB_DATA_REPORTS_EXPORT_GET:   
        return Object.assign({}, state, {
            isDataReportsExportLoading: true
        });       
        case types.GB_DATA_REPORTS_EXPORT_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newDataReportsExport = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isDataReportsExportLoading: false,
            DataReportsExport: newDataReportsExport.data
        });           

        case types.GB_RESET_DATA_EXPORT:   
        return Object.assign({}, state, {
            isDataExportLoading: false,
            DataExport:[],
            isDataReportsExportLoading: false,
            DataReportsExport: []
            // isChartDataExportLoading: false,
            // ChartDataExport: [],
            // isChartDetailDataExportLoading: false,
            // ChartDetailDataExport: []
        });   



        case types.GB_CHART_DATA_EXPORT_GET:   
        return Object.assign({}, state, {
            isChartDataExportLoading: true
        });       
        case types.GB_CHART_DATA_EXPORT_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDataExport = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartDataExportLoading: false,
            ChartDataExport: newChartDataExport.data
        });   



        case types.GB_CHART_DETAIL_DATA_EXPORT_GET:   
        return Object.assign({}, state, {
            isChartDetailDataExportLoading: true
        });       
        case types.GB_CHART_DETAIL_DATA_EXPORT_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDetailDataExport = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartDetailDataExportLoading: false,
            ChartDetailDataExport: newChartDetailDataExport.data
        });   

        case types.GB_FIRST_SCAN_TO_DELIVERY_METRIC_GET:   
        return Object.assign({}, state, {
            isFirstScanToDeliveryMetricLoading: true
        });       
        case types.GB_FIRST_SCAN_TO_DELIVERY_METRIC_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDetailDataExport1 = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isFirstScanToDeliveryMetricLoading: false,
            FirstScanToDeliveryMetric: newChartDetailDataExport1.data
        });   

        case types.GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_GET:   
        return Object.assign({}, state, {
            isFlightDepartToDeliveryMetricLoading: true
        });       
        case types.GB_FLIGHT_DEPART_TO_DELIVERY_METRIC_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDetailDataExport2 = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isFlightDepartToDeliveryMetricLoading: false,
            FlightDepartToDeliveryMetric: newChartDetailDataExport2.data
        }); 

        case types.GB_FIRST_SCAN_TO_RECOVERY_METRIC_GET:   
        return Object.assign({}, state, {
            isFirstScanToRecoveryMetricLoading: true
        });       
        case types.GB_FIRST_SCAN_TO_RECOVERY_METRIC_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDetailDataExport3 = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isFirstScanToRecoveryMetricLoading: false,
            FirstScanToRecoveryMetric: newChartDetailDataExport3.data
        }); 
        
        case types.GB_MANIFEST_TO_DELIVERY_METRIC_GET:   
        return Object.assign({}, state, {
            isManifestToDeliveryMetricLoading: true
        });       
        case types.GB_MANIFEST_TO_DELIVERY_METRIC_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDetailDataExport4 = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isManifestToDeliveryMetricLoading: false,
            ManifestToDeliveryMetric: newChartDetailDataExport4.data
        }); 

        case types.GB_CHART_MANIFEST_TO_DELIVERY_GET:   
        return Object.assign({}, state, {
            isChartManifestToDeliveryLoading: true
        });       
        case types.GB_CHART_MANIFEST_TO_DELIVERY_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartManifestDeliveryData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartManifestToDeliveryLoading: false,
            ChartManifestToDeliveryData: newChartManifestDeliveryData.data
        });      



        case types.GB_CHART_INWHS_TO_DELIVERY_GET:   
        return Object.assign({}, state, {
            isChartInWhsToDeliveryLoading: true
        });       
        case types.GB_CHART_INWHS_TO_DELIVERY_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartInWhsToDeliveryData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartInWhsToDeliveryLoading: false,
            ChartInWhsToDeliveryData: newChartInWhsToDeliveryData.data
        });      

        case types.GB_CHART_INWHS_TO_FIRST_SCAN_GET:   
        return Object.assign({}, state, {
            isChartInWhsToFirstScanLoading: true
        });       
        case types.GB_CHART_INWHS_TO_FIRST_SCAN_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartInWhsToFirstScanData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartInWhsToFirstScanLoading: false,
            ChartInWhsToFirstScanData: newChartInWhsToFirstScanData.data
        });      

        case types.GB_CHART_DAILY_SCAN_COUNT_GET:   
        return Object.assign({}, state, {
            isDailyScanCountChartLoading: true
        });       
        case types.GB_CHART_DAILY_SCAN_COUNT_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartDailyScan = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isDailyScanCountChartLoading: false,
            DailyScanCountChartData: newChartDailyScan.data
        });   

        case types.GB_CHART_INWHS_TO_FLIGHT_DEPART_GET:   
        return Object.assign({}, state, {
            isChartInWhsToFlightDepartLoading: true
        });       
        case types.GB_CHART_INWHS_TO_FLIGHT_DEPART_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartInWhsToFlightDepartData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartInWhsToFlightDepartLoading: false,
            ChartInWhsToFlightDepartData: newChartInWhsToFlightDepartData.data
        });     

        case types.GB_CHART_INWHS_TO_FREIGHT_RECOVERY_GET:   
        return Object.assign({}, state, {
            isChartInWhsToFreightRecoveryLoading: true
        });       
        case types.GB_CHART_INWHS_TO_FREIGHT_RECOVERY_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartInWhsToFreightRecoveryData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartInWhsToFreightRecoveryLoading: false,
            ChartInWhsToFreightRecoveryData: newChartInWhsToFreightRecoveryData.data
        });  

        case types.GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_GET:   
        return Object.assign({}, state, {
            isChartFlightDepartToFirstScanLoading: true
        });       
        case types.GB_CHART_FLIGHT_DEPART_TO_FIRST_SCAN_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }
        var newChartFlightDepartToFirstScanData = JSON.parse(action.payload.body);
        return Object.assign({}, state, {
            isChartFlightDepartToFirstScanLoading: false,
            ChartFlightDepartToFirstScanData: newChartFlightDepartToFirstScanData.data
        });  

        case types.GB_BULK_HAWB_DETAILS_GET:   
        return Object.assign({}, state, {
            isBulkHawbDetailsLoading: true
        });       
        case types.GB_BULK_HAWB_DETAILS_COMPLETE:   
        if(!checkStatusCode(action.payload.statusCode)) {
            actionOnError()
            return state;
        }

        var newBulkHawbDetailsData = JSON.parse(action.payload.body);
        // console.log('newBulkHawbDetailsData', newBulkHawbDetailsData)
        var newBulkHawbDetailsDataData = newBulkHawbDetailsData.data[0].BulkData
        // console.log('newBulkHawbDetailsDataData', newBulkHawbDetailsDataData)
        var parseBulkData = JSON.parse(newBulkHawbDetailsDataData)
        // console.log('parseBulkMAWBData', parseBulkData)


        var bulkMAWB = parseBulkData[0].MAWB
        // console.log('bulkMAWB', bulkMAWB)
        var bulkMicom = parseBulkData[0].MicomEvents
        // console.log('bulkMicom', bulkMicom)
        var bulkPackage = parseBulkData[0].PackageDetails
        // console.log('bulkPackage', bulkPackage)
        var bulkUps = parseBulkData[0].UpsEvents
        // console.log('BulkChartReportColumns', BulkChartReportColumns)



        // var jsonBulkData = parseBulkMAWBData.MAWB[0]
        // console.log('jsonBulkData', jsonBulkData)

        // const items = parseBulkMAWBData.MAWB
        // const replacer = (key, value) => value === null ? '' : value // specify how you want to handle null values here
        // const header = Object.keys(items[0])
        // const csv = [
        // header.join(','), // header row first
        // ...items.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
        // ].join('\r\n')

        // console.log('csv', csv)



        return Object.assign({}, state, {
            isBulkHawbDetailsLoading: false,
            // BulkHawbDetailsData: newBulkHawbDetailsData.data
            // BulkHawbDetailsData: parseBulkData

            bulkMAWBData:  bulkMAWB,
            bulkMicomData: bulkMicom,
            bulkPackageData: bulkPackage,
            bulkUpsData: bulkUps

        });  






        // /////////////save MAWB
        // var prodData = jsonBulkData["ProductName"]
        // var newArrayBulkMAWB = []
        // try{

        //         for (let key in prodData) {
        //                 newArrayProdName.push({label: prodData[key]["ProductName"], value: prodData[key]["ProductName"] , id: prodData[key]["ProductID"]})
        //         }
        //         saveArrayAsStringToLocalStorage("PageConfig.ProductName", newArrayProdName)
        //         }
        // catch(e){
        // console.log('error', e)
        // }




        default:
                return state;
	}
};

export default GbDataReducer;
