import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import GBFilterMain         from '../../../components/ComponentsGB/GBFilterMain/GBFilterMain';
import GBChartOTDtoFlightDepartContainer from '../../../components/ComponentsGB/GBChartOTDtoFlightDepart/GBChartOTDtoFlightDepartContainer';
import GBChartOTDtoEstArrivalContainer from '../../../components/ComponentsGB/GBChartOTDtoEstArrival/GBChartOTDtoEstArrivalContainer';
// import GBChartAgingContainer from '../../../components/ComponentsGB/GBChartAging/GBChartAgingContainer';
// import GBChartDimWeightContainer from '../../../components/ComponentsGB/GBChartDimWeight/GBChartDimWeightContainer';
// import GBChartCustomsClearTimeContainer from '../../../components/ComponentsGB/GBChartCustomsClearTime/GBChartCustomsClearTimeContainer';
import GBChartOriginFinalMileToDeliveryContainer from '../../../components/ComponentsGB/GBChartOriginFinalMileToDelivery/GBChartOriginFinalMileToDeliveryContainer';
import GBStatsCardRowContainer  from '../../../components/ComponentsGB/GBStatsCardRow/GBStatsCardRowContainer';
import GBChartManifestToDeliveryContainer from '../../../components/ComponentsGB/GBChartManifestToDelivery/GBChartManifestToDeliveryContainer'

import GBChartInWhsToDeliveryContainer from '../../../components/ComponentsGB/GBChartInWhsToDelivery/GBChartInWhsToDeliveryContainer'
import GBChartInWhsToFirstScanContainer from '../../../components/ComponentsGB/GBChartInWhsToFirstScan/GBChartInWhsToFirstScanContainer'

import GBChartDailyScanCountContainer from '../../../components/ComponentsGB/GBChartDailyScanCount/GBChartDailyScanCountContainer'

import GBChartInWhsToFlightDepartContainer from '../../../components/ComponentsGB/GBChartInWhsToFlightDepart/GBChartInWhsToFlightDepartContainer'
import GBChartInWhsToFreightRecoveryContainer from '../../../components/ComponentsGB/GBChartInWhsToFreightRecovery/GBChartInWhsToFreightRecoveryContainer'
import GBChartFlightDepartToFirstScanContainer from '../../../components/ComponentsGB/GBChartFlightDepartToFirstScan/GBChartFlightDepartToFirstScanContainer'


import {appSettings} from '../../../app/appSettings'

export default class gbDashHomeView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,
            languageSelection: 'English'

          };
      }

        componentDidMount() {

            this.getLocalStorageLanguageSelection()

        }

        getLocalStorageLanguageSelection() {
            let languageSelectionValue;
            try{
              languageSelectionValue = localStorage.getItem('PageConfig.UserSettings.Language');
              this.setState({
                languageSelection: languageSelectionValue
              })
            }
            catch(e){
              this.setState({
                languageSelection: "English"
              })
            }
      
          }


        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;




        return(
            <div >
            <GBFilterMain isMobile={isMobile} languageSelection={this.state.languageSelection}/>

            <AnimatedView>
            <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 
            
            <GBStatsCardRowContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>



            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                    <GBChartOTDtoFlightDepartContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
                </div>
                <div className="col-md-6">
                    <GBChartOTDtoEstArrivalContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>
                </div>
            </div>

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                    <GBChartOriginFinalMileToDeliveryContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
                </div>
                <div className="col-md-6">
                    <GBChartManifestToDeliveryContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
                </div>

            </div>

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                    <GBChartInWhsToFirstScanContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
                </div>
                <div className="col-md-6">
                    <GBChartInWhsToDeliveryContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
                </div>

            </div>



            <GBChartDailyScanCountContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  


            </div>
            </AnimatedView>
            </div>




                


        );
    }
}



// //Post Update
// <div >
//             <GBFilterMain isMobile={isMobile} languageSelection={this.state.languageSelection}/>

//             <AnimatedView>
//             <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 
            
//             <GBStatsCardRowContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>



//             <div
//                 className="row"
//                 style={{marginBottom: '5px'}}>
//                 <div className="col-md-6">
//                     <GBChartInWhsToFlightDepartContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
//                 </div>
//                 <div className="col-md-6">
//                     <GBChartInWhsToFreightRecoveryContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>
//                 </div>
//             </div>

//             <div
//                 className="row"
//                 style={{marginBottom: '5px'}}>
//                 <div className="col-md-6">
//                     <GBChartInWhsToFirstScanContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
//                 </div>
//                 <div className="col-md-6">
//                     <GBChartInWhsToDeliveryContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>  
//                 </div>

//             </div>

//             <div
//                 className="row"
//                 style={{marginBottom: '5px'}}>
//                 <div className="col-md-6">
//                     <GBChartFlightDepartToFirstScanContainer isMobile={isMobile} languageSelection={this.state.languageSelection} />  
//                 </div>
//                 <div className="col-md-6">
//                     <GBChartOriginFinalMileToDeliveryContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>
//                 </div>
//             </div>

//             <GBChartDailyScanCountContainer isMobile={isMobile} languageSelection={this.state.languageSelection}/>


            
//             </div>
//             </AnimatedView>
//             </div>
