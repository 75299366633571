import React, {
    PureComponent
}                         from 'react';
import AnimatedView       from '../../../components/ComponentsGeneral/animatedView/AnimatedView';
import FilterMainZU         from '../../../components/ComponentsZU/FilterMainZU/FilterMainZU';
// import ChartOTDtoMCContainer    from '../../../components/ComponentsZU/ChartOTDtoMC/ChartOTDtoMCContainer';
import ChartOTDtoFlightDepartContainer from '../../../components/ComponentsZU/ChartOTDtoFlightDepart/ChartOTDtoFlightDepartContainer';
import ChartOTDtoEstArrivalContainer from '../../../components/ComponentsZU/ChartOTDtoEstArrival/ChartOTDtoEstArrivalContainer';
import ChartAgingContainer from '../../../components/ComponentsZU/ChartAging/ChartAgingContainer';
import ChartDimWeightChartContainer from '../../../components/ComponentsZU/ChartDimWeightChart/ChartDimWeightChartContainer';
import ChartCustomsClearTimeContainer from '../../../components/ComponentsZU/ChartCustomsClearTime/ChartCustomsClearTimeContainer';

import StatsCardRowZUContainer  from '../../../components/ComponentsZU/StatsCardRowZU/StatsCardRowZUContainer';
import {appSettings} from '../../../app/appSettings'

export default class zuDashHomeView extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {

            width: window.innerWidth,

          };
      }


        //Mobile View
        handleWindowSizeChange = () => {
            this.setState({ width: window.innerWidth });
            };
        
        // check width for mobile device
        componentWillMount() {
            window.addEventListener('resize', this.handleWindowSizeChange);
          }
        // make sure to remove the listener
        // when the component is not mounted anymore
        componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
        }      

    render() {
        const { width } = this.state;
        const isMobile = width <= appSettings.mobileWidth;

        return(
            <div>
            <FilterMainZU isMobile={isMobile}/>

            <AnimatedView>
            <div style={{  fontSize: (isMobile == true  ) ? '0.6rem'  : ''}}> 
            
            <StatsCardRowZUContainer isMobile={isMobile}/>

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                    <ChartOTDtoFlightDepartContainer isMobile={isMobile}/>  
                </div>
                <div className="col-md-6">
                    <ChartOTDtoEstArrivalContainer isMobile={isMobile}/>
                </div>
            </div>

            <div
                className="row"
                style={{marginBottom: '5px'}}>
                <div className="col-md-6">
                    <ChartAgingContainer isMobile={isMobile}/>  
                </div>
                <div className="col-md-6">
                    <ChartDimWeightChartContainer isMobile={isMobile}/>  
                </div>
            </div>
            
            {/* <ChartCustomsClearTimeContainer isMobile={isMobile}/>   */}

            
            </div>
            </AnimatedView>
            </div>




                


        );
    }
}
