import React from 'react';
import {appSettings} from '../../../app/appSettings'

const Footer = () => (
    <div className="footer-main">
        Copyright <span className="copyright">&copy;</span> {appSettings.customer.Footer_CopyWrite}
    </div>
);

export default Footer;
