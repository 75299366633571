import {connect} from 'react-redux';
import React, {Component} from 'react';
import GBShipToStateReporting from './GBShipToStateReporting'
import * as actions from '../../../../redux/actions/gbActions';
import {appSettings} from '../../../../app/appSettings'
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';



const mapStateToProps = store =>
({

    currentUserEmail:                   store.userServicesReducer.currentUserEmail,
    userSignInData:                     store.userServicesReducer.userSignInData,

    isHawbReportingDataLoading:         store.gbDataReducer.isHawbReportingDataLoading,
    // HawbReportingData:                  store.gbDataReducer.HawbReportingData,

    TransitTimeSummary:                 store.gbDataReducer.TransitTimeSummary,
    TransitTimeDetails:                 store.gbDataReducer.TransitTimeDetails,


});

const mapDispatchToProps = dispatch =>
({

    gbGetHawbReportingData:( _db, _email,  _mawb, _mc, _hawb, _trackingno, _startDT, _endDT, _dateType, _filter, _filterValue, _notDeparted,_inTransit ,_portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype) =>
    {
        dispatch( actions.gbGetHawbReportingData( _db, _email,  _mawb, _mc, _hawb, _trackingno, _startDT, _endDT, _dateType, _filter, _filterValue, _notDeparted,_inTransit ,_portExit, _portEntry, _finalMileCarrier, _brokerageServ, _customercode, _shipmenttype) )
    }

});


class GBShipToStateReportingContainer extends Component
{
	constructor(props)
	{
		super(props);
		
	}

    componentDidMount() {
        this.handleUrlParam();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.search !== this.props.location.search) {
            this.handleUrlParam();
        }
    }
    
    handleUrlParam = () => {
    var paramStartDT = null
    var paramEndDT = null
    var paramDateType = null
    var paramNotDeparted = null
    var paramPortExit = null
    var paramPortEntry = null
    var paramFinalCarrier = null
    var paramCustomerCode = null
    var paramShipmentType = null

    const values = queryString.parse(this.props.location.search)

    if(typeof(values.startdt) !== 'undefined' && values.startdt != null ){
        paramStartDT = values.startdt
    }
    if(typeof(values.enddt) !== 'undefined' && values.enddt != null ){
        paramEndDT = values.enddt
    }
    if(typeof(values.datetype) !== 'undefined' && values.datetype != null ){
        paramDateType = values.datetype
    }
    if(typeof(values.notdeparted) !== 'undefined' && values.notdeparted != null ){
        paramNotDeparted = values.notdeparted
    }
    if(typeof(values.portexit) !== 'undefined' && values.portexit != null ){
        paramPortExit = values.portexit
    }
    if(typeof(values.portentry) !== 'undefined' && values.portentry != null ){
        paramPortEntry = values.portentry
    }
    if(typeof(values.finalcarrier) !== 'undefined' && values.finalcarrier != null ){
        paramFinalCarrier = values.finalcarrier
    }
    if(typeof(values.customercode) !== 'undefined' && values.customercode != null ){
        paramCustomerCode = values.customercode
    }
    if(typeof(values.shipmenttype) !== 'undefined' && values.shipmenttype != null ){
        paramShipmentType = values.shipmenttype
    }

    this.props.gbGetHawbReportingData( appSettings.gbDB, this.props.currentUserEmail, null, null, null, null,   paramStartDT, paramEndDT, paramDateType, 'reporting', null, paramNotDeparted, null, paramPortExit, paramPortEntry, paramFinalCarrier, null, paramCustomerCode, paramShipmentType );
    
    }

	
	
    render()
    {

    	return (

            <GBShipToStateReporting 

                routeCustomer='gaobo'

                currentUserEmail={this.props.currentUserEmail}
                userSignInData={this.props.userSignInData}
                isMobile={this.props.isMobile}

                isHawbReportingDataLoading={this.props.isHawbReportingDataLoading}
                // HawbReportingData={this.props.HawbReportingData}


                TransitTimeSummary={this.props.TransitTimeSummary}
                TransitTimeDetails={this.props.TransitTimeDetails}


            />

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(GBShipToStateReportingContainer));



